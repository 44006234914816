<template>
	<v-row fluid fill-height class="d-flex flex-column align-start appbackground" style="height: 100% !important; overflow: none !important" no-gutters>
		<v-col class="align-center" cols="12">
            <v-row dense >
                <v-col  class="pa-0 ma-0 d-flexalign-start d-flex flex-column" :cols="computedJobCols" >
                    <div style="width: 100% !important" class="pa-0 pb-0 align-center white--text d-flex grey lighten-1">
                        <v-btn color="white" class="mt-1" text @click="$router.go(-1)">
                            <v-icon>icons8-back</v-icon> Back
                        </v-btn>
                    </div>
                <!-- v-if="['SA'].includes(GET_currentUser.userLevel)" -->
                    <!-- <pre>{{ GET_FIREBASE_currentUser }}</pre> -->
                    <div style="width: 100% !important" class="pa-0 pb-0 pl-3 pr-1 pt-2  align-center d-flex page-title">
                        <div class="primary--text pr-1">JOB </div>       
                        <div class="secondary--text"> DETAILS</div>
                        <!-- //! TODO - ADD BUTTONS - UNALLOCATE, EDIT, SAVE ETC  -->
                        <!-- //! TODO - ADD LINK TO RECURRING RULE IF REQUIRED  -->
                        <!-- <div v-if="form.swappStatus.status !== 'notStarted'">
                            <v-tooltip right color="appbackground">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" size="22" @click="summaryDialog = !summaryDialog" position="right" class="grey--text ml-2 pb-1">icons8-clock</v-icon>
                                </template>
                                <span v-if="summaryDialog === false" class="ma-0 pa-0 darkgrey--text">
                                    <div>View SWAPP Summary</div>
                                </span>
                            </v-tooltip>
                            <v-tooltip v-model="summaryDialog" right color="white">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" size="0" @click="summaryDialog = !summaryDialog" position="right" class="appbackground--text ml-2 pb-1">icons8-clock</v-icon>
                                </template>
                                <span class="ma-0 pa-0 grey--text">
                                    <div class="text-h6 secondary--text">SWAPP Summary</div>
                                    <div class="font-weight-bold">SWAPP'd Start Time: <span class="font-weight-regular">{{ $moment(form.swappStatus.swappStartTime, 'X').format('HH:mm') }}</span></div>
                                    <div class="font-weight-bold">SWAPP'd End Time: <span class="font-weight-regular">{{ $moment(form.swappStatus.swappEndTime, 'X').format('HH:mm') }}</span></div>
                                    <div class="font-weight-bold">SWAPP Status: <span class="font-weight-regular">{{ form.swappStatus.status}}</span></div>
                                </span>
                            </v-tooltip>
                        </div> -->
                        <v-spacer />
                        <v-btn title="Duplicate Job" v-if="!itemNew && $vuetify.breakpoint.mdAndUp" :disabled="item.deleted == true && ['SA', 'DA'].includes(GET_currentUser.userLevel)" color="secondary" class="mb-1 mt-0" icon text @click="duplicateJob()"><v-icon>icons8-duplicate</v-icon></v-btn>
                        <v-btn title="Delete Job" v-if="!itemNew && item.deleted == false && $vuetify.breakpoint.mdAndUp && ['SA', 'DA'].includes(GET_currentUser.userLevel)" color="error" class="mb-1 mt-0" icon text @click="openDeleteDialog()"><v-icon>icons8-trash</v-icon></v-btn>
                    </div>
                    <div>
                        <!-- && ['SA'].includes(GET_currentUser.userLevel) -->
                        <v-btn 
                            v-if="item.jobType === 'Recurring Job' && $vuetify.breakpoint.mdAndUp" 
                            color="secondary" 
                            class="mb-1 mt-0 mr-1 pl-1" 
                            text 
                            @click="MIX_go('/recurringJob/' + item.jobRecurringRuleId)"
                            >
                            <v-icon>mdi-chevron-left</v-icon>
                            Go to Recurring Job
                        </v-btn>
                    </div>
                    <!-- <pre>{{ item }}</pre> -->
                    <!-- <pre>{{ progressSchema }}</pre> -->
                    <div class="pl-3 pr-1" style="width: 100% !important">
                        <job-details
                            :datadown="item"
                            v-on:save="createItem" 
                            v-on:delete="deleteItem" 
                            v-on:update="updateItem" 
                            :userOpen="userOpen"
                            :refreshItems="refreshItems"
                            :audit="audit"
                        />
                    </div>
                </v-col>
                <v-col :cols='computedStaffCols' class="pa-0 ma-0 d-flex pt-2 flex-column success lightgrey" v-if="$vuetify.breakpoint.mdAndUp && ['SA'].includes(GET_currentUser.userLevel)">
                    <!-- STAFF DETAILS -->
                    <div v-if="!itemNew" style="width: 100% !important" class="pa-0 pb-0 align-center pl-3 d-flex page-title">
                        <div class="secondary--text pr-1">STAFF </div>       
                        <div class="primary--text"> ALLOCATION</div>
                    </div>
                    <!-- <pre>{{ item }}</pre> -->
                    <div v-if="!itemNew" class="px-3" style="width: 100% !important">
                        <staff-list
                            v-if="item.jobAssignedStatus == 'ALLOCATED'"
                            :job="true"
                            :userOpen="userOpen"
                            v-on:openItem="openStaffMember"
                            :user="item.jobAssignedUserId"
                        />
                        <staff-list
                            :job="true"
                            :userOpen="userOpen"
                            v-on:openItem="openStaffMember"
                        />
                    </div>

                </v-col>
                <v-col cols="8" v-if="userOpen === true && $vuetify.breakpoint.mdAndUp" class="pa-0 ma-0">
                    <!-- && audit === false -->

                    <div class="" style="width: 100% !important; height: 100% !important; overflow: none !important">
                        <job-staff-details
                            :job="item"
                            :staff="staff"
                            v-on:closePanel="userOpen = false"
                            v-on:update="updateItem"
                            v-on:save="createItem"
                            :refreshItems="refreshItems"
                            
                        />
                    </div>
                </v-col>
            </v-row>

            <v-dialog v-model="deleteDialog" max-width="412" :key="dialogKey">
                <v-card>
                    <v-toolbar color="secondary" elevation="0">
                        <app-text category="text-medium" class="white--text ml-2">Delete Job?</app-text>

                        <v-spacer />
                        
                        <v-btn @click="closeDeleteDialog()" icon dark>
                            <v-icon class="icons8-multiply" color="white" />
                        </v-btn>
                    </v-toolbar>

                    <div class="pa-3"> 
                        <app-text category="" class="">Are you sure you want to delete this Job? This action is irreversible.</app-text>
                    </div>


                    <div class="d-flex align-center pa-3">
                        <appbutton @click.native="closeDeleteDialog()" label="Close" />
                        <v-spacer />
                        <appbutton @click.native="deleteItem(item)" type="primary" label="Delete Job" />
                    </div>
                </v-card>
            </v-dialog>
            
        </v-col>
    </v-row>
</template>

<script>
	import { mapGetters } from "vuex";
    import jobDetails from "@/views/jobs/jobDetails.vue"
    import staffList from "@/views/staffDirectory/staffList.vue"
    import jobStaffDetails from "@/views/jobs/jobStaffDetails.vue"
	import schema from "@/schema.js";
	export default {
		// * COMPONENTS
		components: {
			"job-details": jobDetails,
			"staff-list": staffList,
			"job-staff-details": jobStaffDetails,
		},
		// * PROPS
		props: {
			datadown: {
				type: Object,
				default: function () {
					return {};
				},
			},
		},
		// * DATA
		data: () => ({
			key: "jobs",
			tab: "details",
			schema: null,
            progressSchema: null,
			// Item
			item: {},
			itemNew: false,
			editMode: false,

			// Form items
			accountTypes: [],
			addressTypes: [],
			industries: [],

            routeId: '',

			// Tags
			computedOrgTags: [],
            userOpen: false,

			// Validation
			rules: {},

			// Toggles
			addAddressToggle: false,
			addManualAddress: false,
			addOtherAddressToggle: false,
			addLeadStatusToggle: false,
			addOrgInfoToggle: false,
			addSocialInfoToggle: false,

            refreshItems: 0,

            audit: false,

            deleteDialog: false,
            dialogKey: 0
		}),
		// * COMPUTED
		computed: {
			...mapGetters({
                GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
			}),
			computedFullName() {
				return this.item.contactFirstName + " " + this.item.contactLastName;
			},
            computedStaffCols() {
                var cols;
                switch (false) {
                    case this.userOpen:
                        cols = 9;
                        break;
                    default:
                        cols = 2
                }
                return cols
            },
            computedJobCols() {
                var cols;
                switch (false) {
                    case this.userOpen: 
                        cols = 3;
                        break;
                    default: 
                        cols = 2
                }
                if (this.$vuetify.breakpoint.smAndDown) { 
                    cols = 12
                }
                if (this.GET_FIREBASE_currentUser.userLevel === 'SU') {
                    cols = 12
                }
                return cols
            },
		},
		// * METHODS
		methods: {
            closeDeleteDialog() {
                var t = this; 

                t.deleteDialog = false
            },
            openDeleteDialog() {
                var t = this;

                t.deleteDialog = true
            },
			// * VALIDATION RULES
			// TODO - RG - I think this needs to be moved to contactDetails.vue
			validateItem() {
				let t = this;
				t.rules = {
					// GENERAL DETAILS
					orgName: [(v) => !!v || "Organisation Name Required"],
				};
				t.$nextTick(() => {
					if (t.$refs.form.validate()) {
						this.createItem();
						this.editMode = false;
					} else {
						this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
					}
				});
			},
            async createProgress(jobId, type, status) {
                let t = this;
                let progress = {...t.progressSchema}
                
                progress.linkedId = jobId
                progress.type = type
                progress.status = status
                progress.totalItems = 1
                progress.count = 1
                progress.success = 1
                progress.createdDateTime = t.$moment().toISOString()
                progress.createdUserId = t.GET_FIREBASE_currentUser.entityId

                // console.log('progress = ' + JSON.stringify(progress, null, 2))

                await this.REDIS_create('progress', progress);

            },  
			// * CREATE ITEM
			async createItem(item) {
				let t = this;
                var dateTimeNow = t.$moment().toISOString()

                item.createdDateTime = dateTimeNow;
                item.createdUserId = t.GET_FIREBASE_currentUser.entityId;
                
                let result = await this.REDIS_create(t.key, item);
                
				if (result.success) {
                    // console.log('result = ' + JSON.stringify(result, null, 2))
					t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
					t.readItem(result.data.entityId);
                    t.itemNew = false
                    t.refreshItems++
                    t.MIX_go('/job/' + result.data.entityId)
				}
			},
            // * UPDATE ITEM 
            async updateItem(item) {
				let t = this;
                var dateTimeNow = t.$moment().toISOString()

                item.modifiedDateTime = dateTimeNow;
                item.modifiedUserId = t.GET_FIREBASE_currentUser.entityId;

                // console.log('updating item = ' + JSON.stringify(item, null, 2))
                
                let result = await this.REDIS_update(t.key, item.entityId, item);
                
				if (result.success) {
                    t.createProgress(result.data.jobSiteId, 'Edit Job', 'Completed')
					t.MIX_alertBox({ color: "success", timeout: 2000, message: "Updated", show: true });
					t.readItem(item.entityId);
                    t.refreshItems++
				}
            },
			// * READ ITEM
			async readItem(itemId) {
				let t = this;
				let result = await t.REDIS_read(t.key, itemId);
				if (result.success) {
					this.item = result.data;
					t.itemNew = false;
					t.loading = false;
				}
			},
			// * DESTROY ITEM
			async destroyItem() {
				let result = await this.MIX_destroyItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// * DELETE ITEM
			async deleteItem(item) {
                // console.log('deleting job = ')
                item.deletedUserId = this.GET_FIREBASE_currentUser.entityId 
				let result = await this.REDIS_delete(this.key, item.entityId, item);
                // console.log('result = ' + JSON.stringify(result, null, 2))
				if (result.success) {
                    this.createProgress(item.jobSiteId, 'Delete Job', 'Completed')
					this.MIX_alertBox({ color: "error", timeout: 2000, message: "Deleted", show: true });
					this.readItem(item.entityId);
                    this.refreshItems++
                    this.closeDeleteDialog()
				}
			},
			// * RESTORE ITEM
			async restoreItem() {
				let result = await this.MIX_restoreItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// Close Item
			closeItem() {
				this.$emit("closeitem");
			},
			cancelItem() {
				this.readItem(this.item.id);
			},
            openStaffMember(item) {
                this.staff = item
                this.userOpen = true
            },
            duplicateJob() {
                var t = this; 

                var duplicate = t.item

                duplicate.entityId = t.MIX_generateId()

                t.createItem(duplicate)
            }

		},
		// * WATCH
		watch: {
			computedOrgTags(val) {
				var t = this;
				var splitVal = [];
				splitVal = t.item.orgTags.split(",");

				return splitVal;
			},
		},
		// * CREATED
		async created() {
			let t = this;
			t.loading = true;
			t.schema = schema.job;
            t.progressSchema = schema.progress
		},
		// * MOUNTED
		async mounted() {
            var t = this
		    this.routeId = this.$route.params.id;
            console.log(this.routeId)
            if (this.routeId === "new") {
				t.itemNew = true;
				t.editMode = true;
				t.item = { ...t.schema };
				t.item.entityId = t.MIX_generateId();
            } 
            else if (this.routeId === "audit") {
				t.itemNew = true;
				t.editMode = true;
				t.item = { ...t.schema };
				t.item.entityId = t.MIX_generateId();
                t.audit = true
                t.item.jobServiceId = 'cEjeAFJSMAdEfI6Gt3dJ'
                t.item.swappStatus = 'COMPLETED'
                
            } 
            else if (this.routeId !== "new" && this.routeId !== "" && this.routeId !== undefined) {
				this.readItem(this.routeId);
            } 
        },
	};
</script>

<style scoped>
	.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
	.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
	.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
		height: 22px;
	}
</style>
