<template>
    <v-row fluid fill-height class=" appbackground" style="width: calc(100vw - 56px) !important; position: fixed; height: 90vh !important; overflow-y: none !important" no-gutters>
    <!-- <v-container fluid fill-height class="d-flex flex-column align-start appbackground pa-0 ma-0"> -->
		<v-col class="align-center mt-1" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">
            
			<div class="d-flex pa-3 align-center mb-1">
				<div class="d-flex align-center">
					<div><v-icon class="primary--text" x-large>icons8-task-completed</v-icon></div>
					<div class="text-left primary--text pl-1 text-h4 font-weight-thin">Hotlist Tasks</div>
				</div>
				<v-spacer></v-spacer>
				<div class="ml-1">
                    <v-btn depressed block class="primary darken-2 white--text" @click="templateDialog = true" >
                        <v-icon>icons8-task-planning</v-icon>
                        <span v-if="!listShrunk" class="ml-3">Schedule Template</span>
                    </v-btn>
                </div>
				<div class="ml-1">
                    <v-btn depressed block class="primary darken-2 white--text" @click="taskDialog = true" >
                        <v-icon>icons8-task-completed</v-icon>
                        <span v-if="!listShrunk" class="ml-3">New Task</span>
                    </v-btn>
                </div>
			</div>
            
            <TaskList :listshrunk="listShrunk" :listexpanded="listExpanded" v-on:openItem="openItem" v-on:deleteItem="deleteItem" :refreshitems="refreshItems"/>
            
		
        </v-col>

        <v-dialog v-model="templateDialog" max-width="812" :key="templateDialogKey">
            <v-card>
                <v-toolbar color="secondary" elevation="0">
                    <app-text category="text-medium" class="white--text ml-2">Schedule Template</app-text>

                    <v-spacer />
                    
                    <v-btn @click="templateDialog = false" icon dark>
                        <v-icon class="icons8-multiply" color="white" />
                    </v-btn>
                </v-toolbar>
                <div class="grey lighten-4 pa-4">
                    <v-form ref="form" lazy-validation>
                        <v-row dense class="">
                            <v-col cols="12" class="py-0 font-weight-bold caption grey--text">
                                Please select a Task Template to schedule:
                            </v-col>
                            <v-col cols="12">
                                <v-select 
                                    class="" 
                                    hide-details="auto" 
                                    label="Task Template" 
                                    filled 
                                    v-model="selectedTaskTemplate"  
                                    outlined
                                    dense
                                    :items="taskTemplates" 
                                    background-color="white" 
                                    :rules="rules.required"
                                    color="primary"
                                    :menu-props="{ top: false, offsetY: true }"
                                >
                                    <template v-slot:selection="{ item, index }">
                                        {{ item.taskTemplateTitle }}
                                    </template>
                                    <template v-slot:item="{ item, index }">
                                        {{ item.taskTemplateTitle }}
                                    </template>
                                </v-select>
                            </v-col>
                            <!-- <pre>{{ selectedTaskTemplate }}</pre> -->
                            <v-col cols="12" xs="12" v-if="selectedTaskTemplate.taskTemplateDescription">
                                <div class="font-weight-bold body-2 grey--text">{{ selectedTaskTemplate.taskTemplateDescription }}</div>
                            </v-col>
                        </v-row>
                        <v-row dense v-if="selectedTaskTemplate.entityId">
                            <v-col v-if="selectedTaskTemplate.taskTemplateCategory == 'Client'" cols="12" class="py-0">
                                <v-select 
                                    class="" 
                                    hide-details="auto" 
                                    label="Clients" 
                                    filled 
                                    v-model="selectedItem"  
                                    outlined
                                    dense
                                    :items="clients" 
                                    background-color="white" 
                                    :rules="rules.required"
                                    color="primary"
                                    :menu-props="{ top: false, offsetY: true }"
                                    item-value="entityId"
                                    item-text="clientName"
                                >
                                </v-select>
                            </v-col>
                            <v-col v-if="selectedTaskTemplate.taskTemplateCategory == 'Site'" cols="12" class="py-0">
                                <v-select 
                                    class="" 
                                    hide-details="auto" 
                                    label="Sites" 
                                    filled 
                                    v-model="selectedItem"  
                                    outlined
                                    dense
                                    :items="sites" 
                                    background-color="white" 
                                    :rules="rules.required"
                                    color="primary"
                                    :menu-props="{ top: false, offsetY: true }"
                                    item-value="entityId"
                                    item-text="siteName"
                                >
                                </v-select>
                            </v-col>
                            <v-col v-if="selectedTaskTemplate.taskTemplateCategory == 'User'" cols="12" class="py-0 ">
                                <v-select 
                                    class="" 
                                    hide-details="auto" 
                                    label="Users" 
                                    filled 
                                    v-model="selectedItem"  
                                    outlined
                                    dense
                                    :items="users" 
                                    background-color="white" 
                                    :rules="rules.required"
                                    color="primary"
                                    :menu-props="{ top: false, offsetY: true }"
                                    item-value="entityId"
                                    item-text="userName"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row dense v-if="selectedItem">
                            <v-col cols="12" class="py-0 font-weight-bold caption grey--text">
                                Please select when you would like the Task Template to be activated:
                            </v-col>
                            <v-col cols="12">
                                <div class="d-flex">
                                    <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field label="Activation Date" v-model="computedDate1" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('taskDueDateTime')"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                                    </v-menu>
                                </div>

                            </v-col>
                        </v-row>
                        <v-row dense v-if="selectedDate">
                            <div class="font-weight-bold caption grey--text pl-1">Below is the list of Tasks that will be added, along with details of to who and when</div>
                            <v-col cols="12">
                                <TaskTemplateList :refreshitems="refreshItems" :datadown="selectedTaskTemplate" :popup="true" :selectedDate="selectedDate"/>
                            </v-col>
                        </v-row>
                    </v-form>
                    <!-- <pre> {{ task }} </pre> -->
                </div>
                <div class="d-flex grey lighten-1 pa-4">
                    <v-btn text class="primary white--text" @click="templateDialog = false" >Close</v-btn>
                    <v-spacer />
                    <!-- <app-button @click.native="saveItem" type="primary" label="Submit" /> -->
                    <v-btn text class="secondary white--text" @click="scheduleTaskList" :disabled="selectedDate == null" >Save</v-btn>
                </div>

            </v-card>
        </v-dialog>

        <v-dialog v-model="taskDialog" max-width="612" :key="taskDialogKey">
            <v-card>
                <v-toolbar color="secondary" elevation="0">
                    <app-text category="text-medium" class="white--text ml-2">Create New Task</app-text>

                    <v-spacer />
                    
                    <v-btn @click="taskDialog = false" icon dark>
                        <v-icon class="icons8-multiply" color="white" />
                    </v-btn>
                </v-toolbar>
                <div class="grey lighten-4 pa-4">
                    <v-form ref="form" lazy-validation>
                        <v-row dense class="">
                            <v-col cols="12" class="py-0 font-weight-bold grey--text text-h6">
                                Task Details
                            </v-col>
                            <v-col cols="12" xs="12">
                                <v-textarea 
                                    label="Task" 
                                    v-model="task.taskTitle" 
                                    required 
                                    dense 
                                    outlined
                                    hide-details="auto" 
                                    background-color="white" 
                                    color="primary"
                                    rows="3"
                                    :rules="rules.required"
                                    :disabled="!itemNew && GET_FIREBASE_currentUser.userCategory !== 'DIRECTOR'"
                                    class=""
                                    clearable 
                                    @click:clear="clearItem('taskDescription')">
                                </v-textarea> 
                            </v-col>
                        </v-row>
                        <v-row dense class="">
                            <v-col cols="6" xs="6">
                                <v-select 
                                    class="" 
                                    hide-details="auto" 
                                    label="Assigned Admin" 
                                    filled 
                                    v-model="task.taskAllocatedUserId"  
                                    outlined
                                    dense
                                    :items="managers" 
                                    background-color="white" 
                                    :rules="rules.required"
                                    color="primary"
                                    :menu-props="{ top: false, offsetY: true }"
                                    item-text="userName"
                                    item-value="entityId"
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="6" xs="6">
                                <v-select 
                                    class="" 
                                    hide-details="auto" 
                                    label="Priority" 
                                    filled 
                                    v-model="task.taskPriority"  
                                    outlined
                                    dense
                                    :items="priorities" 
                                    :rules="rules.required"
                                    :disabled="!itemNew && GET_FIREBASE_currentUser.userCategory !== 'DIRECTOR'"
                                    :menu-props="{ top: false, offsetY: true }" 
                                    background-color="white" 
                                    color="primary"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row dense class="">
                            <v-col cols="6" xs="6">
                                <v-select 
                                    class="" 
                                    hide-details="auto" 
                                    label="Type" 
                                    filled 
                                    v-model="task.taskType"  
                                    outlined
                                    dense
                                    :items="types" 
                                    background-color="white" 
                                    :disabled="!itemNew && GET_FIREBASE_currentUser.userCategory !== 'DIRECTOR'"
                                    :rules="rules.required"
                                    color="primary"
                                    :menu-props="{ top: false, offsetY: true }"
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="6" xs="6">
                                <v-select 
                                    v-if="task.taskType == 'Client'"
                                    class="" 
                                    hide-details="auto" 
                                    label="Client" 
                                    filled 
                                    v-model="task.taskClientId"  
                                    outlined
                                    dense
                                    :items="clients" 
                                    :rules="rules.required"
                                    :disabled="!itemNew && GET_FIREBASE_currentUser.userCategory !== 'DIRECTOR'"
                                    :menu-props="{ top: false, offsetY: true }" 
                                    background-color="white" 
                                    color="primary"
                                    item-text="clientName"
                                    item-value="entityId"
                                >
                                </v-select>
                                <v-select 
                                    v-if="task.taskType == 'Site'"
                                    class="" 
                                    hide-details="auto" 
                                    label="Site" 
                                    filled 
                                    v-model="task.taskSiteId"  
                                    outlined
                                    dense
                                    :items="sites" 
                                    :rules="rules.required"
                                    :disabled="!itemNew && GET_FIREBASE_currentUser.userCategory !== 'DIRECTOR'"
                                    :menu-props="{ top: false, offsetY: true }" 
                                    background-color="white" 
                                    color="primary"
                                    item-text="siteName"
                                    item-value="entityId"
                                >
                                </v-select>
                                <v-select 
                                    v-if="task.taskType == 'User'"
                                    class="" 
                                    hide-details="auto" 
                                    label="User" 
                                    filled 
                                    v-model="task.taskUserId"  
                                    outlined
                                    dense
                                    :items="users" 
                                    :rules="rules.required"
                                    :disabled="!itemNew && GET_FIREBASE_currentUser.userCategory !== 'DIRECTOR'"
                                    :menu-props="{ top: false, offsetY: true }" 
                                    background-color="white" 
                                    color="primary"
                                    item-text="userName"
                                    item-value="entityId"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row dense class="">
                            <v-col cols="6" xs="6">
                                <v-select 
                                    class="" 
                                    hide-details="auto" 
                                    label="Category" 
                                    filled 
                                    v-model="task.taskCategory"  
                                    outlined
                                    dense
                                    :items="categories" 
                                    background-color="white" 
                                    :rules="rules.required"
                                    :disabled="!itemNew && GET_FIREBASE_currentUser.userCategory !== 'DIRECTOR'"
                                    color="primary"
                                    :menu-props="{ top: false, offsetY: true }"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row dense class="pt-2 align-start">
                            <v-col cols="12" class="py-0 d-flex font-weight-bold grey--text text-h6">
                                Schedule Details
                            </v-col>
                            <v-col cols="6" xs="6">
                                <div class="d-flex">
                                    <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field label="Due Date" v-model="computedDate2" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date2" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu2 = false"></v-date-picker>
                                    </v-menu>
                                </div>
                                <!-- <div v-if="showInfo" class="font-weight-bold caption grey--text">This is the number of days after the Task Template is activated that this Task will need to be Completed.</div> -->
                            </v-col>
                            <!-- <v-col cols="6">
                                <div class="d-flex align-center">
                                    <v-checkbox 
                                        v-model="task.taskNotification"
                                        label="Send Notification?" 
                                        hide-details="auto" 
                                        color="primary" 
                                        class="ma-0 ma-0"></v-checkbox>
                                </div>
                                <div v-if="showInfo" class="font-weight-bold caption grey--text">Do you want the User to receive a Notification when this Task is assigned to them?</div>
                            </v-col> -->
                        </v-row>
                        <v-row dense class="">
                            <v-col cols="12" class="py-0 d-flex font-weight-bold grey--text text-h6">
                                Notes
                            </v-col>
                            <v-col cols="12">
                                <VueEditor id="simpleEditor" style="max-height: 370px; min-height: 200px; overflow-y: scroll" v-model="task.taskNotesHTML" :editorToolbar="customToolbar" class="white"></VueEditor>
                            </v-col>
                        </v-row>
                    </v-form>
                    <!-- <pre> {{ task }} </pre> -->
                </div>
                <div class="d-flex grey lighten-1 pa-4">
                    <v-btn text class="primary white--text" @click="taskDialog = false" >Close</v-btn>
                    <v-spacer />
                    <!-- <app-button @click.native="saveItem" type="primary" label="Submit" /> -->
                    <v-btn text class="secondary white--text" @click="validateItem" >Save</v-btn>
                </div>

            </v-card>
        </v-dialog>



    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import TaskTemplate from '@/views/taskTemplates/taskTemplate.vue';
import TaskList from '@/views/hotlistTasks/hotlistTaskCardList.vue'
import TaskTemplateList from "@/views/taskTemplates/taskTemplateTaskList.vue";
import schema from '@/schema.js';
import { VueEditor } from 'vue2-editor';
export default {
    name: 'Services',
    components: {
        'TaskTemplate': TaskTemplate,
        'TaskList': TaskList,
        'TaskTemplateList': TaskTemplateList,
        VueEditor,
    },
    data: () => ({
        editMode: false,
        orgtype: "",
        orgIcon: "",
        search: '',
        show: false,
        tab: '',
        orgs: [],
        orgDialog: false,
        refreshItems: 0,
        activeItem: {},
        extendWidth: '',
        routeId: '',
		// Item Columns
		itemsCols: { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12,},
		itemCols: { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 },
		taskCols: {	cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2,},
		listExpanded: true,
		listShrunk: false,

        templateDialog: false,
        templateDialogKey: 0,
        taskDialog: false,
        taskDialogKey: 0,
        task: {},
        managers: [],
        users: [],
        sites: [],
        clients: [],
        schema: {},
        showInfo: false,

        taskTemplates: [],
        selectedTaskTemplate: {},

        priorities: ['Urgent', 'High', 'Medium', 'Low'],
        types: ['Client', 'Site', 'User'],
        categories: ['Email', 'Call', 'Appointment', 'Notes', 'Follow up','Document', 'Diary'],
        rules: {},

        date1: '',
        dateMenu1: false,
        date2: '',
        dateMenu2: false,

        selectedDate: null,
        selectedItem: null,

        customToolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'align': ''},{ 'align': 'center'},{ 'align': 'justify'}, { 'align': 'right' }],
            [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],  
            // [{ 'color': [] }, { 'background': [] }],
            ['link'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            // [{ 'indent': '-1'}, { 'indent': '+1' }],
        ],

        itemNew: true,
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
        computedDate1() {
            return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YYYY");
        },

        computedDate2() {
            return this.MIX_formatDateTime(this.date2, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
    },
    methods: {
		refreshCols() {
			switch (true) {
				// Expanded List with No Task Bar
				case this.listExpanded && !this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
					this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// Expanded List with Task Bar
				case this.listExpanded && !this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
					this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				// List with Product Page (No Task Bar)
				case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// List with Product Page and Task Bar
				case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 4
					this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				// Shrunk List with Product Page (No Task Bar)
				case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }; // 2
					this.itemCols = { cols: 12, xs: 9, sm: 9, md: 9, lg: 9, xl: 9 }; // 10
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// Shrunk List with Product Page and Task Bar)
				case !this.listExpanded && this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				default:
					this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 12
					this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
			}
		},
		openItem(item) {
            this.taskDialog = true;
            this.itemNew = false;
            this.task = item;
            this.date2 = this.$moment(item.taskDueDateTime).format('YYYY-MM-DD');
            this.selectedDate = this.$moment(item.taskDueDateTime).format('X')
		},
        async deleteItem(item) {
            var deleteData = {}

            deleteData.deletedUserId = this.GET_FIREBASE_currentUser.entityId
            deleteData.deletedDateTime = this.$moment().format('X')

            console.log('deleteData: ', JSON.stringify(deleteData, null, 2))
            await this.REDIS_delete('hotlist-task', item.entityId, deleteData)
			this.MIX_alertBox({ color: "success", timeout: 2000, message: "Task Successfully Deleted", show: true });
            this.refreshItems++
        },
		closeItem() {
			this.listExpanded = true;
			this.listShrunk = false;
		},
        initialiseSchema() {
            var t = this; 
            t.task = {...t.schema}
            t.task.taskAllocatedUserId = t.GET_FIREBASE_currentUser.entityId;
        
        },
        validateItem() {
            let t = this; 

            t.rules = {
				required: [(v) => !!v || "Required"],
            }; 
            t.$nextTick(() => {
                if (t.$refs.form.validate()) {
                    if (this.itemNew) {
					    this.saveTask();
                    } else {
                        this.updateItem();
                    }
                }
            
            })
        },
        async saveTask() {
            var t = this; 

            var dateTimeNow = t.$moment().toISOString()

            t.task.createdDateTime = dateTimeNow;
            t.task.createdUserId = t.GET_FIREBASE_currentUser.entityId;

            t.task.taskDueDateTime = t.$moment(t.selectedDate, 'X').toISOString()
            if (this.$moment(t.task.taskDueDateTime).isBefore(this.$moment(), 'day')) {
                t.task.taskStatus = 'OVERDUE'
            } else {
                t.task.taskStatus = 'TODO'
            }
            t.task.taskAddDate = t.$moment().startOf('day').toISOString()


            console.log('task = ' + JSON.stringify(t.task, null, 2))
            let result = await this.REDIS_create('hotlist-task', t.task)

            if (result.success) {
                // if alloctaed user !== createdUserId then send notification to allocated user
                if (t.task.taskAllocatedUserId !== t.task.createdUserId) {
                    t.sendAllocatedNotif(t.task.taskAllocatedUserId, result.data.entityId)
                    // console.log('send notification to new user')
                }
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                t.refreshItems++;
                t.taskDialog = false;
                t.taskDialogKey++
                t.initialiseSchema()
                t.selectedDate = null
            } else {
				t.MIX_alertBox({ color: "error", timeout: 2000, message: "Error Saving Task", show: true });
            }
        },
        // * CREATE ITEM
        async updateItem() {
			let t = this;
            var item = {...t.task}

            // need to check if the alllocated user has been changed 

            var oldItem = await this.REDIS_read('hotlist-task', item.entityId)
            oldItem = oldItem.data

            if (oldItem.taskAllocatedUserId !== item.taskAllocatedUserId && item.taskAllocatedUserId !== item.modifiedUserId) {
                // send notification to new user
                t.sendAllocatedNotif(item.taskAllocatedUserId, item.entityId)
                // console.log('send notification to new user')
                
            }

            // console.log('old Item = ' + JSON.stringify(oldItem, null, 2))

            var dateTimeNow = t.$moment().toISOString()
            item.modifiedDateTime = dateTimeNow;
            item.modifiedUserId = t.GET_FIREBASE_currentUser.entityId;

            item.taskDueDateTime = t.$moment(t.selectedDate, 'X').toISOString()
            if (this.$moment(item.taskDueDateTime).isBefore(this.$moment(), 'day')) {
                item.taskStatus = 'OVERDUE'
            } else {
                item.taskStatus = 'TODO'
            }
            if (item.taskAddDate == null) {
                item.taskAddDate = t.$moment().startOf('day').toISOString()
            }
            
            let result = await this.REDIS_update('hotlist-task', item.entityId, item);
            
			if (result.success) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Updated", show: true });
                t.taskDialog = false
                t.itemNew = true
                t.initialiseSchema()
                t.refreshItems++
				t.$emit("refreshitems");
                t.selectedDate = null
			}
        },
        async sendAllocatedNotif(userId, taskId) {
            var t = this; 

            var notif = {}
            notif.notifUserId = userId
            notif.notifTaskId = taskId



            this.REDIS_sendHotlistTaskNotification(notif)
			// this.MIX_alertBox({ color: "success", timeout: 2000, message: "Invite Sent", show: true });
        },
        async scheduleTaskList() {
            var t = this; 

            let taskTemplate = t.selectedTaskTemplate;

            // get all templateTasks 
            var searchQuery = `@deleted:{false} @taskTemplateId:{${taskTemplate.entityId}}`;
            let itemsResult = await this.REDIS_searchFor('template-task', 0, 999, 'taskTitle','ASC', searchQuery)

            // console.log('scheduleTaskList itemsResult = ', JSON.stringify(itemsResult, null, 2))

            if (itemsResult.success) {
                var items = itemsResult.data.documents;

                for (var i=0; i<items.length; i++) {
                    var item = {...t.schema}

                    item.taskTitle = items[i].taskTitle;
                    item.taskAllocatedUserId = items[i].taskUserId;
                    item.taskPriority = items[i].taskPriority;
                    item.taskNotesHTML = items[i].taskNotesHTML;
                    item.taskType = taskTemplate.taskTemplateCategory;
                    item.taskCategory = items[i].taskCategory;
    
                    if (items[i].taskWhen == 'Before Start Date') {
                        item.taskAddDate = this.$moment(this.selectedDate, 'X').subtract(items[i].taskDays, 'days').toISOString();
                    } else {
                        item.taskAddDate = this.$moment(this.selectedDate, 'X').add(items[i].taskDays, 'days').toISOString();
                    }
                    item.taskDueDateTime = this.$moment(item.taskAddDate).add(items[i].taskDueNumber, 'days').toISOString();


                    if (taskTemplate.taskTemplateCategory == 'Client') {
                        item.taskClientId = this.selectedItem;
                    } else if (taskTemplate.taskTemplateCategory == 'Site') {
                        item.taskSiteId = this.selectedItem;
                    } else if (taskTemplate.taskTemplateCategory == 'User') {
                        item.taskUserId = this.selectedItem;
                    }


                    if (this.$moment(item.taskDueDateTime).isBefore(this.$moment(), 'day')) {
                        item.taskStatus = 'OVERDUE'
                    } else {
                        item.taskStatus = 'TODO'
                    }
                    
                    var dateTimeNow = t.$moment().toISOString()

                    item.createdDateTime = dateTimeNow;
                    item.createdUserId = t.GET_FIREBASE_currentUser.entityId;

                    // console.log('scheduleTaskList item = ', JSON.stringify(item, null, 2))

                    var redisItem = await this.REDIS_create('hotlist-task', item)

                    // if task.taskAddDate is before today then send notification to allocated user
                    if (this.$moment(item.taskAddDate).isBefore(this.$moment(), 'day')) {
                        t.sendAllocatedNotif(item.taskAllocatedUserId, redisItem.data.entityId)
                    }
                }
                
                this.createProgress(taskTemplate.taskTemplateTitle, 'Scheduled Task Template' , 'Completed', items.length)
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                t.date1 = ''
                t.date2 = ''
                t.selectedDate = null
                t.selectedItem = null
                t.templateDialog = false;
                t.templateDialogKey++
                t.refreshItems++
            }

        },
        async createProgress(jobId, type, status, number) {
            let t = this;
            let progress = {}
            
            progress.linkedId = jobId
            progress.type = type
            progress.status = status
            progress.totalItems = number
            progress.count = number
            progress.success = number
            progress.error = 0
            progress.createdDateTime = t.$moment().toISOString()
            progress.createdUserId = t.GET_FIREBASE_currentUser.entityId
            console.log('progress = ' + JSON.stringify(progress, null, 2))
            await this.REDIS_create('progress', progress);
        },  
    },
	watch: {
		listExpanded() { this.refreshCols() },
		listShrunk() { this.refreshCols() },
        dateMenu1: {
            handler: async function () {
                this.selectedDate = this.MIX_formatDateTime(this.date1, 'YYYY-MM-DD', 'X');
            },
            deep: true,
        },
        dateMenu2: {
            handler: async function () {
                this.selectedDate = this.MIX_formatDateTime(this.date2, 'YYYY-MM-DD', 'X');
            },
            deep: true,
        },
        taskDialog: {
            handler: async function () {
                if (!this.taskDialog) {
                    this.selectedDate = null
                    this.date1 = ''
                    this.date2 = ''
                    this.initialiseSchema()
                    this.itemNew = true
                }
            },
            deep: true,
        },
        templateDialog: {
            handler: async function () {
                if (!this.templateDialog) {
                    this.selectedDate = null
                    this.date1 = ''
                    this.date2 = ''
                }
            },
            deep: true,
        },
        
        
		// GET_taskBar() { this.refreshCols() }
	},
    async mounted() {
        var t = this;
		// Dynamic Route for New or Existing Item
		this.routeId = this.$route.params.id;
		if (this.routeId === "new") {
			this.openItem({})
		} else if (this.routeId !== "new" && this.routeId !== "" && this.routeId !== undefined) {
			this.openItem({ id: this.routeId })
		} 
        let managers = await t.REDIS_searchFor('user', '0', '999', 'userName', 'ASC', `@deleted:{false} @userLevel:{SA | DA} -@userStatus:{ARCHIVED}`);
        if (managers.success) { t.managers = managers.data.documents; }

        t.managers = t.managers.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
            };
        })
        let taskTemplates = await t.REDIS_searchFor('task-template', '0', '999', 'taskTemplateTitle', 'ASC', `@deleted:{false}`);
        if (taskTemplates.success) { t.taskTemplates = taskTemplates.data.documents; }

        t.taskTemplates = t.taskTemplates.map((x) => {
            return {
                entityId: x.entityId,
                taskTemplateTitle: x.taskTemplateTitle,
                taskTemplateDescription: x.taskTemplateDescription,
                taskTemplateCategory: x.taskTemplateCategory
            };
        })


        let users = await t.REDIS_searchFor('user', '0', '999', 'userName', 'ASC', '@deleted:{false}');
        if (users.success) { t.users = users.data.documents; }

        t.users = t.users.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
            };
        })


        let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false} -@clientStatus:{ARCHIVE}');
        if (clients.success) { t.clients = clients.data.documents; }

        t.clients = t.clients.map((x) => {
            return {
                entityId: x.entityId,
                clientName: x.clientName,
            };
        })

        let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
        if (sites.success) { t.sites = sites.data.documents; }

        t.sites = t.sites.map((x) => {
            return {
                entityId: x.entityId,
                siteName: x.siteName,
                siteManagerUserId: x.siteManagerUserId,
            };
        })
        t.schema = schema.hotlistTask;
        t.initialiseSchema()

    }
};
</script>