const absence = {
    entityId: "",
    absenceType: "",
    absenceDuration: "",
    absenceClientId: "",
    absenceUserId: "",
    absenceDate: "",
    absenceJobId: "",

	// Standard Fields
	createdDateTime: null,
	createdUserId: null,
	modifiedDateTime: null,
	modifiedUserId: null,
	deleted: false,
	deletedDateTime: null,
	deletedUserId: null,
}

const leave = {
    entityId: "",
    leaveAssignedUserId: "",
    leaveStartDateTime: "",
    leaveEndDateTime: "",
    leaveType: "",
    leaveAllDay: true,
    leaveNotes: "",

	// Standard Fields
	createdDateTime: null,
	createdUserId: null,
	modifiedDateTime: null,
	modifiedUserId: null,
	deleted: false,
	deletedDateTime: null,
	deletedUserId: null,
}

const instance = {
    entityId: "",

    instanceAssignedUserId: "",
    instanceStartDateTime: "",
    instanceEndDateTime: "",
    instanceAllDay: false,
    instanceType: "",
    instanceNotes: "",
    instanceLeaveId: "",

	// Standard Fields
	createdDateTime: null,
	createdUserId: null,
	modifiedDateTime: null,
	modifiedUserId: null,
	deleted: false,
	deletedDateTime: null,
	deletedUserId: null,
}


const area = {
    entityId: "",
    areaName: "",
    areaDescription: "",

	// Standard Fields
	// Standard Fields
	createdDateTime: null,
	createdUserId: null,
	modifiedDateTime: null,
	modifiedUserId: null,
	deleted: false,
	deletedDateTime: null,
	deletedUserId: null,
}

const client = { 
    entityId: "",
    clientName: "",
    clientEmail: "",

    clientContactName: "",
    clientContactEmail: "",
    clientContactMobile: "",
    clientContactPosition: "",
    clientContactTelephone: "",

    clientAddressLine1: "",
    clientAddressLine2: "",
    clientAddressLine3: "",
    clientAddressCounty: "",
    clientAddressPostcode: "",
    clientAddressTown: "",

    clientEmergencyContactName: "",
    clientEmergencyContactEmail: "",
    clientEmergencyContactMobile: "",
    clientEmergencyContactPosition: "",
    clientEmergencyContactTelephone: "",

    clientManagerUserId: "",

    clientNotes: "",
    clientStartDateTime: "",
    clientEndDateTime: "",

    clientStatus: "",
    clientTelephone: "",
    clientType: "",
    clientContractedHours: 0,


	// Standard Fields
	createdDateTime: null,
	createdUserId: null,
	modifiedDateTime: null,
	modifiedUserId: null,
	deleted: false,
	deletedDateTime: null,
	deletedUserId: null,
}

const course = {
    entityId: "",
    courseName: "",
    courseDescription: "",
    courseLengthHours: 0,
    courseValidityYears: 0,
    courseStarterPack: false,
    courseUserCategory: [],

	// Standard Fields
	createdDateTime: null,
	createdUserId: null,
	modifiedDateTime: null,
	modifiedUserId: null,
	deleted: false,
	deletedDateTime: null,
	deletedUserId: null,

}

const job = {
    entityId: "",
    jobAssignedUserId: "",
    jobAssignedStatus: "UNALLOCATED",
    jobClientId: "",
    jobStartDateTime: "",
    jobEndDateTime: "",
    jobDuration: 0,
    jobType: "Job",
    jobPublishedStatus: "UNPUBLISHED",
    jobRecurringRuleId: "",
    jobServiceId: "",
    jobSiteId: "",

    swappStatus: "notStarted",
    swappStartTime: null,
    swappEndTime: null,


	// Standard Fields
	createdDateTime: null,
	createdUserId: null,
	modifiedDateTime: null,
	modifiedUserId: null,
	deleted: false,
	deletedDateTime: null,
	deletedUserId: null,
}

const location = {
    entityId: "",
    locationName: "",
    locationDescription: "",
    locationSiteId: "",


	// Standard Fields
	createdDateTime: null,
	createdUserId: null,
	modifiedDateTime: null,
	modifiedUserId: null,
	deleted: false,
	deletedDateTime: null,
	deletedUserId: null,

}

const recurringJob = {
    entityId: "",
    recurringJobAssignedUserId: "",
    recurringJobAssignedStatus: "",
    recurringJobClientId: "",
    recurringJobStartDateTime: "",
    recurringJobEndDateTime: "",
    recurringJobType: "",
    recurringJobPublishedStatus: "",
    recurringJobServiceId: "",
    recurringJobSiteId: "",
    recurringJobNotes: "",

    recurringRuleToText: "",
    recurringRuleInterval: "1",
    recurringRuleDays: [],
    recurringRuleFrequency: "",
    recurringRuleStartDate: "",
    recurringRuleEndDate: "",

    recurringJobEndsNextDay: false,

	// Standard Fields
	createdDateTime: null,
	createdUserId: null,
	modifiedDateTime: null,
	modifiedUserId: null,
	deleted: false,
	deletedDateTime: null,
	deletedUserId: null,
}

const service = {
    entityId: "",

    serviceName: "",
    serviceDescription: "",

	// Standard Fields
	createdDateTime: null,
	createdUserId: null,
	modifiedDateTime: null,
	modifiedUserId: null,
	deleted: false,
	deletedDateTime: null,
	deletedUserId: null,

}

const site = {
    entityId: "",
    siteName: "",
    siteSector: "",
    siteNotes: "",
    siteTelephone: "",
    siteStartDateTime: 0,
    siteAreaId: "",

    siteOffline: false,

    siteClientId: "",
    siteDBSCheck: false,
    siteLocationIds: [],
    sitePPERequired: false,

    siteAddressLine1: "",
    siteAddressLine2: "",
    siteAddressLine3: "",
    siteAddressCounty: "",
    siteAddressPostcode: "",
    siteAddressTown: "",

    siteManagerUserId: "",

    siteContactName: "",
    siteContactEmail: "",
    siteContactMobile: "",
    siteContactPosition: "",
    siteContactTelephone: "",

    siteEmergencyContactName: "",
    siteEmergencyContactEmail: "",
    siteEmergencyContactMobile: "",
    siteEmergencyContactPosition: "",
    siteEmergencyContactTelephone: "",

    siteContractedHours: 0,

	// Standard Fields
	createdDateTime: null,
	createdUserId: null,
	modifiedDateTime: null,
	modifiedUserId: null,
	deleted: false,
	deletedDateTime: null,
	deletedUserId: null,

}

const swappLog = {
    entityId: "",
    swappSiteId: "",
    swappLocationId: "",
    swappMethod: "",
    swappStatus: "",
    swappUserId: "",


	// Standard Fields
	createdDateTime: null,
	createdUserId: null,
	modifiedDateTime: null,
	modifiedUserId: null,
	deleted: false,
	deletedDateTime: null,
	deletedUserId: null,
}

const training = {
    entityId: "",
    assessmentDate: "",
    courseId: "",
    expiryDate: "",
    trainingHours: "",
    trainingName: "",
    trainingState: "",
    trainingValidity: "",


	// Standard Fields
	createdDateTime: null,
	createdUserId: null,
	modifiedDateTime: null,
	modifiedUserId: null,
	deleted: false,
	deletedDateTime: null,
	deletedUserId: null,
}

const clientUser = {
    entityId: "",

    userName: "",
    userEmail: "",
    userPin: "",
    userOrgId: "",
    userSiteId: [],
    userLevel: '',

    allowTaskNotifications: false,
    allowCommunicationNotifications: false,
    
	// Standard Fields
	createdDateTime: null,
	createdUserId: null,
	modifiedDateTime: null,
	modifiedUserId: null,
	deleted: false,
	deletedDateTime: null,
	deletedUserId: null,
}

const firebaseUser = {
    id: '', 

    areasCovered: [],

    contractHours: '',
    financialAccess: false, 
    authId: '',
    NOKName: '',
    NOKRelationship: '',
    NOKTellNo: '',
    lastClient: {
        clientId: '',
        clientName: ''
    },
    lastLocation: {
        locationId: '',
        locationName: ''
    },
    lastSite: {
        siteId: '',
        siteName: ''
    },

    privacyPolicyConsent: false,

    profilePicFileURL: '',

    services: [],

    swappStatus: 0,
    swappMethod: '',

    trainingCurrent: [],

    userAddress: {
        userAddressLine1: "",
        userAddressLine2: "",
        userAddressLine3: "",
        userCounty: "",
        userPostcode: "",
        userTown: "",
    },

    userAreas: [],

    userCategory: '',

    userDOB: '',

    userEmail: '',
    userGender: '',
    userJobTitle: '',
    userLevel: '',
    userName: '',
    userRole: '',
    userStartDate: "",
    userStatus: "",
    userTelephone: "",
    userType: "",

    offlineStaffMember: false,
    offlineStaffMemberReason: "",
    primaryLineManager: "",
    secondaryLineManager: "",

    createdUserData: {
        userEmail: '',
        userId: '',
        userName: '',
    },
    modifiedUserData: {
        userEmail: '',
        userId: '',
        userName: '',
    },
    delete: '',
    deletedUserData: {
        userEmail: '',
        userId: '',
        userName: '',
    },
}

const user = {
    entityId: "",

    contractHours: 0,
    financialAccess: false,
    authId: "",
    NOKName: "",
    NOKRelationship: "",
    NOKTellNo: "",
    lastClientId: "",
    lastLocationId: "",
    lastSiteId: "",

    privacyPolicyConsent: false,
    profilePicFileURL: "",
    services: [],
    swappStatus: "",
    swappMethod: "",

    userAddressLine1: "",
    userAddressLine2: "",
    userAddressLine3: "",
    userAddressCounty: "",
    userAddressPostcode: "",
    userAddressTown: "",

    userAreas: [],
    userCategory: "",
    userDOB: "",
    userEmail: "",
    userGender: "",
    userJobTitle: "",
    userLevel: "",
    userName: "",
    userRole: "USER",
    userStartDate: "",
    userStatus: "PENDING",
    userTelephone: "",
    userType: "STAFF",
    auditTarget: 0,

    offlineStaffMember: false,
    offlineStaffMemberReason: "",
    primaryLineManagerId: "",
    secondaryLineManagerId: "",

	// Standard Fields
	createdDateTime: null,
	createdUserId: null,
	modifiedDateTime: null,
	modifiedUserId: null,
	deleted: false,
	deletedDateTime: null,
	deletedUserId: null,
}
const userTraining = {
    entityId: "",
    assessmentDate: "",
    courseId: "",
    expiryDate: "",
    trainingHours: "",
    trainingName: "",
    trainingState: "",
    trainingStatus: "",
    trainingValidity: "",
    trainingUserId: "",


	// Standard Fields
	createdDateTime: null,
	createdUserId: null,
	modifiedDateTime: null,
	modifiedUserId: null,
	deleted: false,
	deletedDateTime: null,
	deletedUserId: null,
}

const targets = {
    entityId: "",

    targetStartDateTime: "",
    targetMonthTarget: false,
    targetValue: 0,
    targetOutcome: "",
    targetName: "",


	// Standard Fields
	createdDateTime: null,
	createdUserId: null,
	modifiedDateTime: null,
	modifiedUserId: null,
	deleted: false,
	deletedDateTime: null,
	deletedUserId: null,
}
const taskTemplate = {
    entityId: "",

    taskTemplateTitle: "",
    taskTemplateDescription: "",
    taskTemplateCategory: "",

	// Standard Fields
	createdDateTime: null,
	createdUserId: null,
	modifiedDateTime: null,
	modifiedUserId: null,
	deleted: false,
	deletedDateTime: null,
	deletedUserId: null,
}

const templateTask = {

    entityId: "",

    taskTitle: "",

    taskDays: "",
    taskNotesHTML: "",
    taskWhen: "Before Start Date",
    taskDueNumber: "",

    taskTemplateId: "",
    taskUserId: "",

    taskPriority: "",
    taskCategory: "",
    taskType: "",

    // Standard Fields
    createdDateTime: null,
    createdUserId: null,
    modifiedDateTime: null,
    modifiedUserId: null,
    deleted: false,
    deletedDateTime: null,
    deletedUserId: null,

}

const hotlistTask = {

    entityId: "",

    taskTitle: "",

    taskDueDateTime: "",
    taskAddDate: "",

    taskNotesHTML: "",
    
    taskTemplateId: "",
    taskAllocatedUserId: "",
    taskUserId: "",
    taskSiteId: "",
    taskClientId: "",
    taskStatus: "",


    taskPriority: "",
    taskCategory: "",
    taskType: "",


    // Standard Fields
    createdDateTime: null,
    createdUserId: null,
    modifiedDateTime: null,
    modifiedUserId: null,
    deleted: false,
    deletedDateTime: null,
    deletedUserId: null,

}

const task = {
    entityId: "",

    taskTitle: "",
    taskRead: false,
    taskReadUserId: "",
    taskReadDateTime: "",

    taskCompleted: false,
    taskCompletedUserId: "",
    taskCompletedDateTime: "",

    // taskDueDateTime: "",

    taskOrgId: "",
    taskUserId: "",
    taskSiteId: "",
    taskLocationId: "",
    taskManagerId: "",

    taskComments: '',

    // Standard Fields
    createdDateTime: null,
    createdUserId: null,
    modifiedDateTime: null,
    modifiedUserId: null,
    deleted: false,
    deletedDateTime: null,
    deletedUserId: null,
}

const communication = {
    entityId: "",

    communicationTitle: "",
    communicationDescription: "",

    communicationRead: false,
    communicationReadUserId: "",
    communicationReadDateTime: "",

    communicationCompleted: false,
    communicationCompletedUserId: "",
    communicationCompletedDateTime: "",

    communicationClientId: "",
    communicationSiteId: "",

    communicationType: "",

    // Standard Fields
    createdDateTime: null,
    createdUserId: null,
    modifiedDateTime: null,
    modifiedUserId: null,
    deleted: false,
    deletedDateTime: null,
    deletedUserId: null,
}

const ticketReply = {
    entityId: "",

    ticketReply: "",
    ticketReplyLinkId: "",

    // Standard Fields
    createdDateTime: null,
    createdUserId: null,
    modifiedDateTime: null,
    modifiedUserId: null,
    deleted: false,
    deletedDateTime: null,
    deletedUserId: null,
}
const ticket = {
    entityId: "",

    ticketSubject: "",
    ticketMessage: "",

    ticketType: "",

    ticketStatus: "",

    ticketOrgId: '',
    ticketSiteId: '',
    ticketLocationId: '',
    ticketOwnerId: '',


    // Standard Fields
    createdDateTime: null,
    createdUserId: null,
    modifiedDateTime: null,
    modifiedUserId: null,
    deleted: false,
    deletedDateTime: null,
    deletedUserId: null,

}
const progress = {
    entityId: "",

    type: "",
    linkedId: "",
    totalItems: 0,
    count: 0,
    success: 0,
    error: 0,
    status: "Not Started",
    report: '',
    firebaseAuthDeleted: false, 
    authDeletionMessage: null,
    createdDateTime: null,
    createdUserId: null,
}

module.exports = {
    absence,
    leave,
    instance,
    area,
    client,
    course,
    job,
    location,
    recurringJob,
    service,
    site,
    swappLog,
    training,
    user,
    userTraining,
    targets,
    clientUser,
    firebaseUser,
    taskTemplate,
    templateTask,
    hotlistTask,
    task,
    communication,
    ticketReply,
    ticket,
    progress,
}