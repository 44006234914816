<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 110px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <v-row class="apptext--text" dense style="z-index: 0 !important">

            <v-overlay :value="loading">
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>

            <v-row class="apptext--text" dense style="z-index: 0 !important">
                <v-col v-if="!editMode && !loading" cols="12" class="d-flex pa-2 align-center">
                    <!-- //* VIEW MODE -->
                    <v-row style="width: 100% !important" no-gutters>
                        <!-- //* GENERAL DETAILS VIEW -->
                        <v-col cols="12" sm="12" class="my-2 px-1">
                            <!-- VIEW VERSION -->
                            <v-row class="white rounded pa-3 fill-height" no-gutters>
                                <v-col cols="12" sm="12">
                                    <div class="d-flex align-center">
                                        <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Details</span>
                                        <v-spacer />
                                        <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                    </div>
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" class="" :style="$vuetify.breakpoint.mdAndUp ? 'height: 100% !important' : ''">
                                    <v-simple-table dense>
                                        <template v-slot:default>
                                            <tbody>
                                                <tr>
                                                    <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Client Name:</td>
                                                    <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ selectedClient.clientName }}</td>
                                                </tr>
                                                <tr>
                                                    <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Site Name:</td>
                                                    <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ selectedSite.siteName }}</td>
                                                </tr>
                                                <tr>
                                                    <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Allocated User:</td>
                                                    <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ selectedUser.userName }}</td>
                                                </tr>
                                                <tr>
                                                    <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Service:</td>
                                                    <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ selectedService.serviceName }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- //* SCHEDULE VIEW -->
                        <v-col cols="12" sm="12" class="my-2 px-1">
                            <!-- VIEW VERSION -->
                            <v-row class="white rounded pa-3 fill-height" no-gutters>
                                <v-col cols="12" sm="12">
                                    <div class="d-flex align-center">
                                        <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Schedule</span>
                                        <v-spacer />
                                        <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                    </div>
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" class="" :style="$vuetify.breakpoint.mdAndUp ? 'height: 100% !important' : ''">
                                    <div class="font-weight-medium body-1 grey--text text-capitalize pb-2">{{ item.recurringRuleToText }}</div>
                                    <v-simple-table dense>
                                        <template v-slot:default>
                                            <tbody>
                                                <tr>
                                                    <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Start Time:</td>
                                                    <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ startTimeHours }}:{{ startTimeMins }}</td>
                                                </tr>
                                                <tr>
                                                    <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">End Time:</td>
                                                    <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ endTimeHours }}:{{ endTimeMins }}</td>
                                                </tr>
                                                <tr>
                                                    <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Start Date:</td>
                                                    <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ computedDate1 }}</td>
                                                </tr>
                                                <tr>
                                                    <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">End Date:</td>
                                                    <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ computedDate2 }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- <pre>{{ item }}</pre> -->

                <v-col v-if="editMode && !loading" cols="12" class="d-flex pa-1 pt-4 align-center">
                    <!-- // * FORM  -->
                    <v-form ref="form" >     
                        <!-- <pre>Form: {{ item }}</pre>                                    -->
                        <v-row class="mb-2 ma-0 pa-0 pl-1 pr-1" no-gutters>                       
                            <!-- // * DATE -->
                            <v-col class="pr-1 pl-0 py-0 pt-3"  cols="6">
                                <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :rules="rules.startDate" :disabled="!itemNew" label="Start Date" v-model="computedDate1" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on"  @click:clear="clearItem('contentAvailableFrom')"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                                </v-menu>
                            </v-col>                      
                            <!-- // * DATE -->
                            <v-col class="pa-1 py-0 pt-3"  cols="6">
                                <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :rules="rules.endDate" :disabled="!itemNew" label="End Date" v-model="computedDate2" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" @click:clear="clearItem('contentAvailableFrom')"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date2" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu2 = false"></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- //* START TIME -->
                            <v-col class="pr-0 pl-0 py-0 pt-1" cols="6">
                                <div class="caption">Start Time</div>
                                <div class="d-flex">
                                    <v-col class="pa-0 ma-0" cols="6">
                                        <v-select 
                                            class="" 
                                            :disabled="!editMode" 
                                            hide-details="auto" 
                                            label="Hours" 
                                            :rules="rules.startDate"
                                            filled 
                                            v-model="startTimeHours" 
                                            required 
                                            outlined 
                                            dense 
                                            :items="hoursWith0" 
                                            background-color="white" 
                                            color="primary" 
                                            :menu-props="{ top: false, offsetY: true }" 
                                            :attach="true" 
                                        ></v-select>
                                    </v-col>
                                    <v-col class="pa-0 ma-0" cols="6">
                                        <v-select 
                                            class="px-1" 
                                            :disabled="!editMode" 
                                            hide-details="auto" 
                                            label="Mins" 
                                            :rules="rules.startDate"
                                            filled 
                                            outlined 
                                            v-model="startTimeMins" 
                                            required 
                                            dense 
                                            :items="mins" 
                                            background-color="white" 
                                            color="primary" 
                                            :menu-props="{ top: false, offsetY: true }" 
                                            :attach="true" >
                                        </v-select>
                                    </v-col>
                                </div>
                            </v-col>

                            <!-- //* DURATION  -->
                            <v-col class="pr-0 pl-1 py-0 pt-1" cols="6">
                                <div class="caption">End Time</div>
                                <div class="d-flex">
                                    <v-col class="pa-0 ma-0" cols="6">
                                        <v-select 
                                            class="" 
                                            :disabled="!editMode" 
                                            hide-details="auto" 
                                            label="Hours" 
                                            :rules="rules.endDate"
                                            filled 
                                            v-model="endTimeHours" 
                                            required 
                                            outlined 
                                            dense 
                                            :items="hoursWith0" 
                                            background-color="white" 
                                            color="primary" 
                                            :menu-props="{ top: false, offsetY: true }"
                                            :attach="true" >
                                        </v-select>
                                    </v-col>
                                    <v-col class="pa-0 ma-0" cols="6">
                                        <v-select 
                                            class="px-1" 
                                            :disabled="!editMode" 
                                            hide-details="auto" 
                                            label="Mins" 
                                            :rules="rules.endDate"
                                            filled 
                                            outlined 
                                            v-model="endTimeMins" 
                                            required 
                                            dense 
                                            :items="mins" 
                                            background-color="white" 
                                            color="primary" 
                                            :menu-props="{ top: false, offsetY: true }" 
                                            :attach="true" >
                                        </v-select>
                                    </v-col>
                                </div>
                                <div class="d-flex justify-end align-center caption grey--text text--darken-1 pr-0">
                                    Ending Next Day?
                                    <v-checkbox v-model="item.recurringJobEndsNextDay" label="" hide-details="auto" class="ma-0 pa-0"></v-checkbox>
                                </div>
                            </v-col>
                            <v-col class="pr-1 pl-0 py-0 pt-4" cols="12">
                                <v-select class="" hide-details="auto" label="Frequency" :rules="rules.required" filled :disabled="!itemNew" outlined v-model="item.recurringRuleFrequency" required dense :items="frequency" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" ></v-select>
                            </v-col>

                            <v-col v-if="item.recurringRuleFrequency == 'DAILY'" cols="12" xs="12" class="d-flex flex-row align-end mt-2">
                                <div class="pl-1 caption pb-2">Daily Option</div>
                                <v-spacer />
                                <!-- //! IF MON-FRI, ADD DAYS TO recurringRuleDays -->
                                <v-radio-group v-if="itemNew" v-model="frequencyOption" hide-details="auto" row class="mb-0 pb-2 mt-0">
                                    <v-radio label="Mon-Fri" value="weekday" ></v-radio>
                                    <v-radio label="Everyday" value="everyday" ></v-radio>
                                </v-radio-group>
                            </v-col>

                            <v-col v-if="item.recurringRuleFrequency == 'WEEKLY'" class="py-0 pr-1 mt-2" cols="12">
                                <v-text-field class="" label="Instance" v-model="item.recurringRuleInterval" :rules="rules.required" required filled hide-details="auto" dense type="number" outlined background-color="white" color="primary" :disabled="!itemNew" > </v-text-field>
                            </v-col>
                            <v-col v-if="item.recurringRuleFrequency == 'WEEKLY' || item.recurringRuleFrequency == 'DAILY'" class="py-0 pr-1" :class="item.recurringRuleFrequency == 'DAILY' ? 'mt-0' : 'mt-2 '" cols="12">
                                <v-select :disabled="!itemNew" v-model="item.recurringRuleDays" :rules="rules.required" :items="days" chips label="Days" multiple class="" hide-details="auto" filled outlined required dense background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" ></v-select>
                            </v-col>


                            <v-col v-if="!itemNew" class="py-0 pr-1 mt-2" cols="12">
                                <v-autocomplete label="Client" :disabled="!editMode" v-model="selectedClient" :rules="rules.clientName" required :items="clients" :menu-props="{ top: false, offsetY: true }" :attach="true" @change="getClientSites()" auto-select-first item-text="clientName" return-object dense outlined hide-details="auto" background-color="white" color="primary" class=""> </v-autocomplete> 
                            </v-col>

                            <!-- // * SITE -->
                            <v-col v-if="!itemNew" class="py-0 pr-1 mt-2" cols="12">
                                <v-autocomplete label="Site" :disabled="!editMode" v-model="selectedSite" :rules="rules.site" required :items="computedSites" :menu-props="{ top: false, offsetY: true }" :attach="true" auto-select-first item-text="siteName" return-object dense no-data-text="Please Select Client" outlined hide-details="auto" background-color="white" color="primary" class=""> </v-autocomplete>
                            </v-col>
                            <!-- // * SERVICES -->
                            <v-col v-if="!itemNew" class="py-0 pr-1 mt-2" cols="12">
                                <v-autocomplete label="Services" :disabled="!editMode" v-model="selectedService" :rules="rules.service" required :items="services" :menu-props="{ top: false, offsetY: true }" :attach="true" item-text="serviceName" return-object dense outlined hide-details="auto" background-color="white" color="primary" class=""> </v-autocomplete>
                            </v-col>
                            <v-col v-if="!itemNew" class="py-0 pr-1 mt-2" cols="12">
                                <v-autocomplete label="Allocated Staff" clearable @click:clear="clearSelectedUser()" :disabled="!editMode" v-model="selectedUser" :rules="rules.assignedUser" required :items="users" :menu-props="{ top: false, offsetY: true }" :attach="true" item-text="userName" return-object dense outlined hide-details="auto" background-color="white" color="primary" class=""> </v-autocomplete>
                            </v-col>

                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12" class="pr-2 pl-1 py-0 pt-1">
                                <!-- //! TODO - VALIDATE AND CREATE SCHEDULE  -->
                                <v-btn v-if="itemNew" block depressed  class="secondary white--text" @click="validateItem">Create Schedule</v-btn>
                                <v-btn v-else-if="!itemNew && editMode" block depressed  class="secondary white--text" @click="editDialog = true">Save Changes</v-btn>
                            </v-col>
                        </v-row>
                        
                    </v-form>
                
                    <!-- <pre>{{ item }}</pre> -->
                </v-col>
                
            </v-row>
        </v-row>



        <v-dialog persistent v-model="editDialog" width="500px" height="400px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 secondary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mr-3 mb-1">
                        <v-icon class="white--text">icons8-future</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Save Changes to Recurring Job?</strong>
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="editDialog = !editDialog" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 pa-0">               
                    
                    <v-row class="pa-0 pt-4 ma-0 justify-center d-flex" fill-height>
                        <v-col cols="12" class="align-center pt-0">
                            <v-menu v-model="dateMenu3" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field label="Edit From" v-model="computedDate3" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('contentAvailableFrom')"></v-text-field>
                                </template>
                                <v-date-picker v-model="date3" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu3 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="pa-0" fill-height cols="12" style="width: 100% !important">
                            <div class="font-weight-bold body-2 pa-3 text-h7 grey--text">
                                This will edit Jobs relating to this Recurring Job from {{ computedDate3 }}
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-0">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="editDialog = !editDialog"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                            <v-btn class="primary white--text font-weight-bold ml-2" depressed @click="saveChanges"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
                        </v-col>
                    </v-col>
                </v-card-actions>
            </div>
        </v-dialog>

        <v-dialog persistent v-model="progressDialog" width="500px" height="400px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 secondary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mr-3 mb-1">
                        <v-icon class="white--text">icons8-future</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Edit Jobs</strong>
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeProgressDialog" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 px-0 pa-3 d-flex flex-column align-center">    

                    <div class=" pl-3 grey--text font-weight-bold text--darken-2 d-flex align-center">
                        The Jobs linked to this Recurring Job are being Edited. Please wait...
                    </div>
                    <!-- <v-progress-linear v-model="progress" height="25" class="mt-5">
                        <strong>{{ Math.ceil(progress) }}%</strong>
                    </v-progress-linear> -->
                    <v-progress-circular v-if="progressData.count <= 0" :size="100" :width="7" indeterminate color="primary"></v-progress-circular>
                    <table width="100%" style="border: 1px solid lightgrey" class="mt-5" v-if="progressData.count > 0">
                        <tr>
                            <th width="80" style="border-bottom: 1px solid lightgrey">Total</th>
                            <th width="80" style="border-bottom: 1px solid lightgrey">Count</th>
                            <th width="80" style="border-bottom: 1px solid lightgrey" class="success--text">Success</th>
                            <th width="80" style="border-bottom: 1px solid lightgrey" class="error--text">Errors</th>
                            <th style="border-bottom: 1px solid lightgrey">Status</th>
                        </tr>
                        <tr>
                            <td align="center">{{ progressData.totalItems }}</td>
                            <td align="center">{{ progressData.count }}</td>
                            <td align="center" class="success--text">{{ progressData.success }}</td>
                            <td align="center" class="error--text">{{ progressData.error }}</td>
                            <td align="center">{{ progressData.status }}</td>
                        </tr>
                    </table>

                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-0">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn class="grey darken-1 white--text font-weight-bold" :disabled="progressData.status == 'In Progress'" depressed @click="closeProgressDialog"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Close</span> </v-btn>
                            <!-- <v-btn class="primary white--text font-weight-bold ml-2" depressed :disabled="progressData.status == 'In Progress'" @click="archiveRule()"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn> -->
                        </v-col>
                    </v-col>
                </v-card-actions>
            </div>
        </v-dialog>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
// import schema from '@/schema.js';
import moment from 'moment'
import { RRule } from 'rrule'
import { mapGetters } from "vuex";

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        editmode: {
            Type: Boolean,
            Default: false,
        },
        users: {
            type: Array,
            default: () => ({}),
        },
        clients: {
            type: Array,
            default: () => ({}),
        },
        services: {
            type: Array,
            default: () => ({}),
        },
        sites: {
            type: Array,
            default: () => ({}),
        },
    },
    // * DATA
    data: () => ({
        key: 'recurring-job',
        itemNew: true,
        schema: {},
        org: {},
        editMode: false,

        // new job object
        item: {},
        loading: false,
        rules: {},

        selectedClient: {
            clientName: '',
            entityId: '',
            clientManager: '',
            clientTelephone: '',
            clientEmail: '',
            clientType: '',
        },
        selectedSite: {
            siteName: '',
            entityId: '',
            siteAddress: '',
        },
        selectedService: {
            serviceName: '',
            entityId: '',
        },
        selectedUser: {
            userName: '',
            entityId: '',
        },

        startTimeHours: '', 
        startTimeMins: '',

        endTimeHours: '',
        endTimeMins: '',



        panel: [0],


        frequency: ['DAILY', 'WEEKLY', 'MONTHLY'],
        days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        recurringInstance: "1",
        frequencyOption: '',


        date1: moment().format('YYYY-MM-DD'),
        dateMenu1: false,
        date2: moment().add(1, 'years').format('YYYY-MM-DD'),
        dateMenu2: false,
        date3: moment().format('YYYY-MM-DD'),
        dateMenu3: false,

        editDialog: false,
        computedSites: [],

        
        progressDialog: false,
        progress: 0,
        progressData: {
            id: "",
            totalItems: 0,
            count: 0,
            success: 0,
            error: 0,
            status: "Not Started",
            report: "",
        },
		progressStatus: "Not Started",
		checkProgressInterval: null,
    }),
    computed: { 

        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
        computedDate1() {
            return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
        computedDate2() {
            return this.MIX_formatDateTime(this.date2, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
        computedDate3() {
            return this.MIX_formatDateTime(this.date3, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
        hours() {
            var hours = []
            for (var i = 0; i < 24; i++) {
                hours.push (i.toString())
            }
            return hours
        },  

        hoursWith0() {
            var hours = []
            for (var i = 0; i < 24; i++) {
                if (i < 10) {
                    hours.push ('0' + i).toString()
                } else {
                    hours.push (i.toString())
                }
            }
            return hours
        },

        mins() {
            var mins = []
            for (var i = 0; i < 60; i++) {
                if (i < 10) {
                    mins.push ('0' + i).toString()
                } else {
                    mins.push (i.toString())
                }
                i = i + 4;
            }
            return mins
        }, 
 
    },
    methods: {
        getClientSites() {
            var id = this.selectedClient.entityId

            var sites = this.sites 

            sites = sites.filter(function (item) {
                return item.siteClientId == id
            })
            
            this.computedSites = sites
        },
        validateItem() {
            var t = this;

            var recurringJobStartDateTime = t.$moment(this.date1, 'YYYY-MM-DD').add(this.startTimeHours, 'hours').add(this.startTimeMins, 'minutes').format('HH:mm DD-MMM-YYYY')
            var recurringJobEndDateTime = t.$moment(this.date1, 'YYYY-MM-DD').add(this.endTimeHours, 'hours').add(this.endTimeMins, 'minutes').format('HH:mm DD-MMM-YYYY')
            
            t.rules = {
                required: [(v) => !!v || 'Required'],
                startDate: [
                    (v) => !!v || 'Required',
                    // if startdate is after enddate, throw error
                    // (v) => !t.item.recurringJobEndsNextDay && t.$moment(recurringJobStartDateTime).isBefore(recurringJobEndDateTime) || 'Start Date must be before End Date'
                ],
                endDate: [
                    (v) => !!v || 'Required',
                    // if startdate is after enddate, throw error
                    // (v) => !t.item.recurringJobEndsNextDay && t.$moment(recurringJobStartDateTime).isBefore(recurringJobEndDateTime) || 'End Date must be after Start Date'
                ],
            };
            t.$nextTick(() => {
                if (t.$refs.form.validate()) {
                    if (this.itemNew) {
                        t.createSchedule()
                    } else {
                        t.updateItem()
                    }
                    // t.saveLeave()
                } else {
					this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
                }
            });
        },
        createSchedule() {
            var t = this; 

            t.item.createdUserId = t.GET_FIREBASE_currentUser.entityId
            t.item.recurringRuleStartDate = t.$moment(this.date1, 'YYYY-MM-DD').toISOString()
            t.item.recurringRuleEndDate = t.$moment(this.date2, 'YYYY-MM-DD').toISOString()

            t.item.recurringRuleInterval = Number(t.item.recurringRuleInterval)

            t.item.recurringJobStartDateTime = t.$moment(this.date1, 'YYYY-MM-DD').add(this.startTimeHours, 'hours').add(this.startTimeMins, 'minutes').toISOString()
            if (t.item.recurringJobEndsNextDay) {
                t.item.recurringJobEndDateTime = t.$moment(this.date1, 'YYYY-MM-DD').add(this.endTimeHours, 'hours').add(this.endTimeMins, 'minutes').add(1, 'days').toISOString()
            } else {
                t.item.recurringJobEndDateTime = t.$moment(this.date1, 'YYYY-MM-DD').add(this.endTimeHours, 'hours').add(this.endTimeMins, 'minutes').toISOString()
            }
            // t.item.recurringJobEndDateTime = t.$moment(this.date1, 'YYYY-MM-DD').add(this.endTimeHours, 'hours').add(this.endTimeMins, 'minutes').toISOString()

            console.log('startDateTime = ' + t.item.recurringJobStartDateTime)
            console.log('endDateTime = ' + t.item.recurringJobEndDateTime)

            const days = [];
            let frequency;

            for (var i=0; i < this.item.recurringRuleDays.length; i++) {
                if (this.item.recurringRuleDays[i] === 'Mon') {
                    days.push( RRule.MO )
                } else if (this.item.recurringRuleDays[i] === 'Tue') {
                    days.push( RRule.TU )
                } else if (this.item.recurringRuleDays[i] === 'Wed') {
                    days.push( RRule.WE )
                } else if (this.item.recurringRuleDays[i] === 'Thu') {
                    days.push( RRule.TH )
                } else if (this.item.recurringRuleDays[i] === 'Fri') {
                    days.push( RRule.FR )
                } else if (this.item.recurringRuleDays[i] === 'Sat') {
                    days.push( RRule.SA )
                } else if (this.item.recurringRuleDays[i] === 'Sun') {
                    days.push( RRule.SU )
                }
            }


            switch (t.item.recurringRuleFrequency.toUpperCase()) {
                case 'WEEKLY':
                    frequency = RRule.WEEKLY;
                    break;
                case 'MONTHLY':
                    frequency = RRule.MONTHLY;
                    this.item.recurringRuleInterval = 1
                    this.item.recurringRuleDays = []
                    break;
                case 'YEARLY':
                    frequency = RRule.YEARLY;
                    break;
                case 'MINUTELY':
                    frequency = RRule.MINUTELY;
                    break;
                case 'DAILY':
                    frequency = RRule.DAILY;
                    this.item.recurringRuleInterval = 1
                    break;
                case 'SECONDLY':
                    frequency = RRule.SECONDLY;
                    break;
                case 'HOURLY':
                    frequency = RRule.HOURLY;
                    break;
                default:
                    //console.log("Couldn't match any frequency")
                    return;
            }

            var rule = new RRule({
                freq: frequency,
                dtstart: new Date(t.item.recurringRuleStartDate),
                until: new Date(t.item.recurringRuleEndDate),
                interval: t.item.recurringRuleInterval !== null ? t.item.recurringRuleInterval : undefined,
                byweekday: days.length !== 0 ? days : undefined
            })

            t.item.recurringRuleToText = rule.toText()

            this.$emit('ruleCreated', this.item)

        },
        async saveChanges() {
            var t = this; 

            this.editDialog = false
            this.loading = true

            var date = moment(this.computedDate3).format('X')
            t.item.searchQuery = `@deleted:{false} @jobStartDateTime:[${date} +inf]`

            t.item.modifiedUserId = t.GET_FIREBASE_currentUser.entityId


            t.item.recurringJobStartDateTime = t.$moment(this.date1, 'YYYY-MM-DD').add(this.startTimeHours, 'hours').add(this.startTimeMins, 'minutes').toISOString()
            // t.item.recurringJobEndDateTime = t.$moment(this.date1, 'YYYY-MM-DD').add(this.endTimeHours, 'hours').add(this.endTimeMins, 'minutes').toISOString()

            if (t.item.recurringJobEndsNextDay) {
                t.item.recurringJobEndDateTime = t.$moment(this.date1, 'YYYY-MM-DD').add(this.endTimeHours, 'hours').add(this.endTimeMins, 'minutes').add(1, 'days').toISOString()
            } else {
                t.item.recurringJobEndDateTime = t.$moment(this.date1, 'YYYY-MM-DD').add(this.endTimeHours, 'hours').add(this.endTimeMins, 'minutes').toISOString()
            }

            if (t.selectedUser.entityId !== '' && t.selectedUser.entityId !== null) {
                t.item.recurringJobAssignedUserId = t.selectedUser.entityId
                t.item.recurringJobAssignedStatus = 'ALLOCATED'
            } else {
                t.item.recurringJobAssignedStatus = 'UNALLOCATED'
                t.item.recurringJobAssignedUserId = ''
            }
            t.item.recurringJobClientId = this.selectedClient.entityId
            t.item.recurringJobServiceId = this.selectedService.entityId
            t.item.recurringJobSiteId = this.selectedSite.entityId

            t.item.progressId = t.MIX_generateId()

            
            let result = await this.REDIS_updateRecurring(t.key, t.item.entityId, t.item);

            console.log('result = ' + JSON.stringify(result, null, 2))
            
			if (result.success) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Updated " + result.data.updatedJobsCount + " Jobs", show: true });
                this.checkProgress(t.item.progressId)
                this.loading = false
                this.progressDialog = true
                this.$emit('refreshitems', t.item.entityId)
			} else if (result.status == 204) {
                t.MIX_alertBox({ color: "success", timeout: 2000, message: "Updated", show: true });
                this.loading = false
                this.$emit('refreshitems', t.item.entityId)
            }
        },  
        // Check Progress of Import
        checkProgress(progressId) {
            let t = this;
            this.checkProgressInterval = setInterval(async () => {
                try {
                    let progressResult = await t.REDIS_read("progress", progressId);
                    if (progressResult.status !== "404") {
                        console.log('progressResult = ' + JSON.stringify(progressResult, null, 2))
                        t.progressData = progressResult.data;
                        t.progressStatus = progressResult.data.status;
                        if (progressResult.data.count !== 0 && progressResult.data.totalItems !== 0) {
                            t.progress = (progressResult.data.count / progressResult.data.totalItems) * 100;
                        } else {
                            t.progress = 100;
                        }
                        console.log('progress = ' + t.progress)
                        if (progressResult.data.status === "Completed") {
                            clearInterval(t.checkProgressInterval);
                        }
                    } else if (progressResult.status === "404") {
                        clearInterval(t.checkProgressInterval);
                    }
                } catch (error) {
                    console.error(error);
                    clearInterval(t.checkProgressInterval);
                }
            }, 1000);
        },
        closeProgressDialog() {
            var t = this;
            t.progressDialog = false
            this.$emit('refreshitems', t.item.entityId)
            
        },
        initialise() {
            this.item = { ...this.datadown };
            
            this.startTimeHours = '', 
            this.startTimeMins = '',

            this.endTimeHours = '',
            this.endTimeMins = ''

            this.date1 = this.$moment().format('YYYY-MM-DD'),
            this.date2 = this.$moment().add(1, 'years').format('YYYY-MM-DD')

            if (this.item.recurringJobAssignedStatus !== '') {
                this.itemNew = false;
                this.editMode = false;
            } else {
                this.itemNew = true;
                this.editMode = true;
            }

            if (this.item.recurringJobStartDateTime && this.item.recurringJobStartDateTime !== '') {
                this.date1 = this.$moment(this.item.recurringJobStartDateTime).format('YYYY-MM-DD')
                this.startTimeHours = this.$moment(this.item.recurringJobStartDateTime).format("HH")
                this.startTimeMins = this.$moment(this.item.recurringJobStartDateTime).format("mm")
            }
            if (this.item.recurringRuleEndDate && this.item.recurringRuleEndDate !== '') {
                this.date2 = this.$moment(this.item.recurringRuleEndDate).format('YYYY-MM-DD')
                this.endTimeHours = this.$moment(this.item.recurringJobEndDateTime).format("HH")
                this.endTimeMins = this.$moment(this.item.recurringJobEndDateTime).format("mm")
            }
            

            if (this.item.recurringJobAssignedUserId !== '') {
                this.selectedUser = this.users.filter((item) => { return item.entityId === this.item.recurringJobAssignedUserId })
                this.selectedUser = this.selectedUser[0]
            }
            if (this.item.recurringJobClientId !== '') {
                this.selectedClient = this.clients.filter((item) => { return item.entityId === this.item.recurringJobClientId })
                this.selectedClient = this.selectedClient[0]
            }
            if (this.item.recurringJobServiceId !== '') {
                this.selectedService = this.services.filter((item) => { return item.entityId === this.item.recurringJobServiceId })
                this.selectedService = this.selectedService[0]
            }
            if (this.item.recurringJobSiteId !== '') {
                this.selectedSite = this.sites.filter((item) => { return item.entityId === this.item.recurringJobSiteId })
                this.selectedSite = this.selectedSite[0]
            }
            this.loading = false
        },
        updateItem() {
            this.$emit('update', this.item);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        clearSelectedUser() {
            setTimeout(() => {
                this.selectedUser = {
                    userName: '',
                    entityId: '',
                }
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {
            this.$emit('save', this.item);
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
    },
    watch: {
        frequencyOption: {
            handler: function () {
                if (this.frequencyOption == 'everyday') {
                    this.item.recurringRuleDays = this.days
                } else if (this.frequencyOption == 'weekday') {
                    this.item.recurringRuleDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri']
                }
            },
            deep: true
        },
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        users: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        services: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        sites: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        users: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        editmode: {
            handler: function () {
                this.editMode = this.editmode;
            },
            deep: true,
        }

    },
    // * CREATED
    async created() {
        let t = this;
        t.loading = true;
        t.initialise();
    },
    mounted() {},
};
</script>

<style scoped>

.v-expansion-panel-content__wrap {
    padding: 0 0px 0px !important;
}
.v-expansion-panel::before {
    box-shadow: none;
}
.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
    padding: 0 0px 0px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    flex: 1 1 auto;
    max-width: 100%;
}
</style>