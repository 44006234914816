<template>
    <div class="" 
    style="padding: 0px !important; margin: 0px !important; width: 100% !important" 
    :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row style="height: 60px" fill-height class="apptext--text mt-0 grey darken-1" dense>
            <v-col cols="12" class="pr-5">   
                <v-row height="160px" fill-height dense>
                    <!-- //! TODO - ACTUALLY SHOW SWAPP STATUS AND ACTUALLY SWAPP IN/OUT  -->
                    <!-- <v-col cols="8" class="d-flex align-center justify-start grey darken-1 py-4">
                    </v-col>
                    <v-col cols="2" class="d-flex align-center justify-start grey darken-1 py-4">
                        <v-text-field 
                            label="SWAPP Status" 
                            v-model="swappStatus" 
                            outlined 
                            dense 
                            disabled
                            hide-details="auto" 
                            background-color="white" 
                            color="primary" 
                        >
                        </v-text-field>
                                    
                    </v-col>
                    <v-col cols="2" class="d-flex align-center justify-start grey darken-1 pr-4 py-4">
                        <v-btn text class="secondary white--text mr-4" v-if="!editmode" @click="swappUserOut()">SWAPP OUT</v-btn>
                    </v-col> -->
                </v-row>
            </v-col>
        </v-row>
        <v-row class="apptext--text" dense style="z-index: 0 !important">
            <v-col cols="12" class="d-flex pa-2 align-center">
                <v-row style="width: 100% !important" class="px-2" no-gutters>
                    <!-- //! TODO - ADD FILTER, AND FILTER BY USER  -->
                    <swapp-list :datadown="datadown" />
                </v-row>
            </v-col>
        </v-row>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
// import schema from '@/schema.js';
import swappList from '@/views/swapp/swappList.vue'

export default {
	components: {
		"swapp-list": swappList,
    },
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        // activeitem: {
        //     Type: Object,
        //     Default: null,
        // },
    },
    // * DATA
    data: () => ({
        key: 'action',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,
        
        swappStatus: 'IN',

        // new address object
        item: {},
        loading: false,
        rules: {},

    }),
    computed: {  
    },
    methods: {
        initialise() {
            this.item = { ...this.datadown };
            console.log('item', JSON.stringify(this.item, null, 2));
            // let iconResult = this.icons.find(icon => icon.icon === this.item.packageIcon);
            // if (iconResult !== undefined) {
            //     this.selectedPackageIcon = iconResult;
            // }
            // if (this.item.packageName !== '') {
            //     this.itemNew = false;
            //     this.editmode = false;
            // } else {
            //     this.itemNew = true;
            //     this.editmode = true;
            // }
        },
       
        updateItem() {
            this.$emit('update', this.item);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {
            this.$emit('save', this.item);
            this.editmode = false;
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        // activeitem: {
        //     handler: function () {
        //         let t = this;
        //         t.item = { ...t.activeitem };
        //     }, deep: true
        // },
        selectedPackageIcon: {
            handler: function () {
                this.item.packageIcon = this.selectedPackageIcon.icon;
            }, deep: true
        },

    },
    // * CREATED
    async created() {
        let t = this;
        // t.schema = schema.package;
        // t.loading = true
        t.initialise();


        // let packageCategories = await t.MIX_redisReadSet('set:packageCategory', false);
        // if (packageCategories.code === 1) {
        //     t.packageCategories = packageCategories.data;
        // }
        // let packageStatus = await t.MIX_redisReadSet('set:packageStatus', false);
        // if (packageStatus.code === 1) {
        //     t.packageStatus = packageStatus.data;
        // }
        // let billingCycles = await t.MIX_redisReadSet('set:billingCycles', false);
        // if (billingCycles.code === 1) {
        //     t.billingCycles = billingCycles.data;
        // }
        // t.loading = false
    },
    mounted() {},
};
</script>