<template>
    <div :style="$vuetify.breakpoint.mdAndUp ? 'height: 93.5vh !important; width: 100% !important;' : 'height: 82vh !important; width: 100% !important'">
        
        <v-card tile flat class="grey lighten-2 pa-0 ma-0 d-flex flex-column" style="height: 100% !important">

            <v-card-title class="secondary" elevation="0">
                <app-text category="text-medium" class="white--text align-center ml-2">
                    <span class="d-flex align-center" v-if="!itemNew">{{ ticket.ticketType }}: <span class="font-weight-light d-inline-block text-truncate" style="max-width: 200px;">{{ ticket.ticketSubject }}</span></span>
                    <span v-else>New Message</span>
                </app-text>
                <v-spacer />
                
                <v-btn @click="closeCommunications" icon dark>
                    <v-icon class="icons8-multiply" color="white" />
                </v-btn>
            </v-card-title>
            <v-card-title class="grey lighten-3 pa-3" elevation="0">
                <v-row dense>
                    <v-col cols="12" xs="12" md="3" lg="3" xl="3" >
                        <v-select 
                            class="" 
                            hide-details="auto" 
                            label="" 
                            filled 
                            v-model="ticket.ticketStatus"  
                            outlined
                            dense
                            :items="ticketStatuses" 
                            :disabled="GET_FIREBASE_currentUser.userLevel !== 'SA'"
                            background-color="grey lighten-5" 
                            color="primary"
                            :menu-props="{ top: false, offsetY: true }" 
                            :attach="true" 
                            item-text="text"
                            item-value="text"
                            @change="updateTicket"
                        >
                        
                            <template v-slot:item="data">
                                <v-icon class="pr-2">{{ data.item.icon }}</v-icon>
                                <span classs="grey--text">{{ data.item.text }}</span>
                            </template>
                            <template v-slot:selection="data">
                                <v-icon class="pr-2">{{ data.item.icon }}</v-icon>
                                {{ data.item.text }}
                            </template>
                        </v-select>
                    </v-col>
                    <v-icon class="px-2">icons8-verified-account</v-icon>
                    <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
                    <v-col cols="10" xs="10" md="3" lg="3" xl="3" class="d-flex">

                        <v-select 
                            class="" 
                            hide-details="auto" 
                            label="" 
                            filled 
                            v-model="ticket.ticketOwnerId"  
                            outlined
                            dense
                            :items="siteManagers" 
                            :disabled="GET_FIREBASE_currentUser.userLevel !== 'SA'"
                            background-color="grey lighten-5" 
                            color="primary"
                            :menu-props="{ top: false, offsetY: true }" 
                            :attach="true" 
                            item-text="userName"
                            item-value="entityId"
                            @change="updateTicket"
                        >
                        </v-select>
                    </v-col>
                </v-row>
                <!-- <pre>Ticket: {{ ticket }}</pre> -->
            </v-card-title>
            <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>

            <!-- <v-row class="apptext--text" dense style="width: 100% !important"> -->
                <div 
                class="pa-3" 
                :style="
                $vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 210px) !important; overflow-y: scroll; width: 100% !important' 
                : 'height: calc(100vh - 370px) !important; overflow-y: scroll; width: 100% !important'" 
                id="ticketReplyContainer" 
                ref="messages">
                    
                    <!-- <pre>Ticket: {{ ticket }}</pre> -->
                    <!-- <pre>TicketReply: {{ ticketReply }}</pre> -->
                    <!-- <pre>itemNew: {{ itemNew }}</pre> -->
                    <!-- <pre>GET_FIREBASE_currentUser: {{ GET_FIREBASE_currentUser }}</pre> -->
                    <div v-if="!itemNew && !loading" class="d-flex flex-row ">
                        <div>
                            <div class="caption grey--text">{{ $moment(ticket.createdDateTime).format('DD-MMM-YYYY HH:mm A')}}</div>
                            <v-chip @click="showDateTime()" style="height: auto; white-space: normal" color="white" class="pa-4 mb-2 rightChip">
                                <div class="d-flex flex-column">
                                    <div class="body-1">{{ ticket.ticketMessage }}</div>
                                    <div class="caption grey--text text--darken-1">{{ checkUser(ticket.createdUserId) }} - {{ computedTimeAgo(ticket.createdDateTime) }}</div>
                                </div>
                            </v-chip>
                        </div>
                    </div>
                    <div v-for="(reply, index) in ticketReplies" :key="index">
                        <!-- <pre>Reply: {{ reply }}</pre> -->
                        <div v-if="reply.createdUserId === GET_FIREBASE_currentUser.entityId && !loading" class="d-flex flex-row-reverse mt-2">
                            <div>
                                <div v-if="showTime" class="caption grey--text">{{ $moment(reply.createdDateTime).format('DD-MMM-YYYY HH:mm A')}}</div>
                                <v-chip @click="showDateTime()" style="height: auto; white-space: normal" class="pa-4 mb-2 secondary rightChip">
                                    <div class="d-flex flex-column">
                                        <div class="body-1">{{ reply.ticketReply }}</div>
                                        <div class="caption white--text">{{ checkUser(reply.createdUserId) }} - {{ computedTimeAgo(reply.createdDateTime) }}</div>
                                    </div>
                                </v-chip>
                            </div>
                        </div>
                        <div v-else-if="reply.createdUserId === ticket.createdUserId && !loading" class="d-flex flex-row mt-2">
                            <div>
                                <div v-if="showTime" class="caption grey--text">{{ $moment(reply.createdDateTime).format('DD-MMM-YYYY HH:mm A')}}</div>
                                <v-chip @click="showDateTime()" color="white" style="height: auto; white-space: normal" class="pa-4 mb-2 leftChip">
                                    <div class="d-flex flex-column">
                                        <div class="body-1">{{ reply.ticketReply }}</div>
                                        <div class="caption grey--text text--darken-1">{{ checkUser(reply.createdUserId) }} - {{ computedTimeAgo(reply.createdDateTime) }}</div>
                                    </div>
                                </v-chip>
                            </div>
                        </div>
                        <div v-else-if="!loading" class="d-flex flex-row">
                            <div>
                                <div v-if="showTime" class="caption grey--text">{{ $moment(reply.createdDateTime).format('DD-MMM-YYYY HH:mm A')}}</div>
                                <v-chip @click="showDateTime()" color="white" style="height: auto; white-space: normal" class="pa-4 mb-2 leftChip">
                                    <div class="d-flex flex-column">
                                        <div class="body-1">{{ reply.ticketReply }}</div>
                                        <div class="caption grey--text text--darken-1">{{ checkUser(reply.createdUserId) }} - {{ computedTimeAgo(reply.createdDateTime) }}</div>
                                    </div>
                                </v-chip>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="showScrollButton" class="d-flex justify-end" style="position: absolute; bottom: 90px !important; right: 10px !important">
                    <v-btn icon class="primary--text white" x-large @click="scrollToBottom" >
                        <v-icon class="">icons8-expand-arrow</v-icon>
                    </v-btn>

                </div>
                <div v-if="showScrollButton" class="d-flex justify-end" style="position: absolute; bottom: 126px !important; right: 10px !important">
                    <v-chip size="10" class="primary white--text">{{ numberDiff }}</v-chip>
                </div>
            <!-- </v-row> -->
            <v-spacer />

            <!-- <v-row dense class="pl-1 pr-0" style="position: absolute; bottom: 10px; width: 100% !important" >
                <div style="width: 100% !important; height: 200px !important">
                    <v-card tile flat>
                        <v-card-text class="pa-0">
                            <v-form ref="ticketReply" class="pt-3" lazy-validation>
                                <v-col cols="12" class="pb-0 pt-1">
                                    <v-textarea
                                        color="primary"
                                        background-color="white"
                                        hide-details="auto"
                                        class="rounded"
                                        :rules="rules.ticketReplyMessage"
                                        v-model="ticketReply.message"
                                        counter
                                        outlined
                                        dense
                                        clearable
                                        light
                                        rows=4
                                    ></v-textarea>
                                </v-col>
                            </v-form>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end pt-0 pr-3">
                            <v-btn class="primary" @click="submitTicketReply()" depressed><v-icon>oomph-sent</v-icon>Submit</v-btn>
                        </v-card-actions>
                    </v-card>
                </div>
            </v-row> -->

            <v-card-actions class="grey lighten-3">
                <v-row dense>
                    <v-col cols="12">
                        <v-form ref="ticketReply" lazy-validation>
                            <v-col v-if="ticket.ticketStatus !== 'Closed' && ticket.ticketStatus !== 'Solved' && ticket.ticketStatus !== 'Archived'" cols="12" class="pa-0 py-1 d-flex align-end">
                                <div v-if="itemNew" class="flex-grow-1">
                                    <v-textarea
                                        hide-details="auto"
                                        :rules="rules.ticketReplyMessage"
                                        v-model="ticket.ticketTitle"
                                        label="Write your response here"
                                        filled
                                        dense
                                        rounded
                                        clearable
                                        light
                                        auto-grow
                                        rows="1"
                                    ></v-textarea>
                                </div>
                                <div v-if="!itemNew" class="flex-grow-1">
                                    <v-textarea
                                        hide-details="auto"
                                        :rules="rules.ticketReplyMessage"
                                        v-model="ticketReply.ticketReply"
                                        label="Write your response here"
                                        filled
                                        dense
                                        rounded
                                        clearable
                                        light
                                        auto-grow
                                        rows="1"
                                    ></v-textarea>
                                </div>
                                <!-- <v-spacer /> -->

                                <div class="pa-0 pb-1" >
                                    <v-btn icon class="primary--text" x-large @click="saveReply" >
                                        <v-icon class="">icons8-email-send</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col cols="12" class="d-flex align-center justify-center" v-else>
                                <div class="font-weight-bold grey--text py-2">
                                    This Ticket is {{ ticket.ticketStatus }}, if you require further assistance, please create a new ticket.
                                </div>
                            </v-col>
                        </v-form>
                    </v-col>
                </v-row>
            </v-card-actions>



        </v-card>

    </div>
</template>

<script>
import schema from "@/schema.js"
import { mapGetters } from "vuex"
export default {
    components: {
    },
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        client: {
            type: Object,
            default: () => ({}),
        },
        itemNew: {
            type: Boolean,
            default: false,
        },
        ticketId: {
            type: String,
            default: '',
        }
    },
    data: () => ({
        type: '',
        status: '',
        key: 'ticket',
        schema: {},
        service: {},
        toggleDeletedItems: false,
        showImages: false,
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,

        sortBy: '@ticketTitle',
        sortByItems: [
            { text: 'Communication Title', value: '@ticketTitle' },
            { text: 'Communication Description', value: '@ticketDescription' },
            { text: 'Created Date', value: '@createdDateTime' },
            { text: 'Modified Date', value: '@modifiedDateTime' },
        ],
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['ticketTitle'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        items: [],
        // computedItems: [],
        clientUsers: [],
        users: [],
        siteManagers: [],
        loading: false,


        ticketDialog: false, 
        ticketDialogKey: 0,
        ticket: {},


        ticketReplies: [],
        ticketReply: {},

        rules: {},
        showTime: false,

        ticketStatuses: [ 
            {
                text: 'Open',
                icon: 'icons8-open-message',
            }, {
                text: 'Closed',
                icon: 'icons8-cancel',
            }, {
                text: 'Pending',
                icon: 'icons8-clock',
            }, {
                text: 'Solved',
                icon: 'icons8-checkmark-yes',
            }, {
                text: 'Archived',
                icon: 'icons8-archive',
            },
            //  {
            //     text: 'On Hold',
            //     icon: 'On icons8-pause-button',
            // }, 
        ],
        itemsInterval: null,

        firstLoad: true,
        showScrollButton: false,
        numberDiff: 0,
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
    },
    methods: {
        async updateTicket() {
            var t = this;

            var dateTimeNow = t.$moment().toISOString();

            var item = {...t.ticket}

            item.modifiedDateTime = dateTimeNow;
            item.modifiedUserId = t.GET_FIREBASE_currentUser.entityId;
            
            await this.REDIS_update(t.key, item.entityId, item);
			this.MIX_alertBox({ color: "success", timeout: 2000, message: "Ticket Updated", show: true });

            this.$emit('refreshItems')
        },
        showDateTime() {
            this.showTime = !this.showTime
        },
        computedTimeAgo(value) {
            var t = this;

            var value = value

            var weekAgo = t.$moment().subtract(7, 'days').toISOString()

            if (value > weekAgo) {
                value = t.$moment(value).fromNow()
            } else {
                value = t.$moment(value).format('DD-MMM-YYYY HH:mm A')
            }

            return value
        },
        scrollToBottom() {
            console.log('scrollToBottom')
            const container = document.getElementById('ticketReplyContainer');
            // container.scrollIntoView()
            container.scrollTop = container.scrollHeight;

            this.showScrollButton = false

            // this.$vuetify.goTo(container.scrollHeight, {
            //     duration: 500,
            //     easing: 'easeInOutQuad',
            // })
        },
        closeCommunications() {
            this.$emit('closeitem')
        },
        initialiseTicketReplySchema() {
            var t = this;
            t.ticketReply = {...t.ticketReplySchema}
            t.ticketReply.ticketReplyLinkId = t.ticketId;
        },
        addNewCommunication() {
            var t = this; 

            t.ticketDialog = true 
            t.ticketDialogKey++

            t.$emit('openCommunication', 'new')
        },
        // * CREATE ITEM
        async saveReply() {
            var t = this;

            var dateTimeNow = t.$moment().toISOString();

            var item = {...t.ticketReply}

            item.createdDateTime = dateTimeNow;
            item.createdUserId = t.GET_FIREBASE_currentUser.entityId;
            
            let result = await this.REDIS_create('ticketReply', item);
            
			if (result.success) {
                console.log('result = ' + JSON.stringify(result, null, 2))
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Sent", show: true });
                t.refreshItems++
                t.updateTicket()
                // t.readItem(result.data.data.entityId)
                t.getItems(t.ticketId)
                t.initialiseTicketReplySchema()
			}

        },
        async readItem(entityId) {
            var t = this;
            var item = await this.REDIS_read(t.key, entityId);
            t.ticket = item.data;
        },
        getUser(userId) {
            var t = this;
            var user = []
            var userName = ''

            user = t.users.filter((item) => { return item.entityId == userId })

            if (user.length !== 0) { userName = user[0].userName }

            return userName
        },
        getClientUser(userId) {
            var t = this;
            var user = []
            var userName = ''
            console.log('getClientUser = ' + userId)
            user = t.clientUsers.filter((item) => { return item.entityId == userId })

            if (user.length !== 0) { userName = user[0].userName }

            return userName
        },
        checkUser(userId) {
            var t = this; 
            var user = []

            user = t.getUser(userId)

            if (user == '') {
                user = t.getClientUser(userId)
            }

            return user
        },
        async loadMore() {
            this.itemsFrom = this.itemsFrom + this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo + this.datatableOptions.itemsPerPage;
            this.getItems();
        },
        async goBack() {
            this.itemsFrom = this.itemsFrom - this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo - this.datatableOptions.itemsPerPage;
            this.getItems()
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        refreshItems: function () {
            this.getItems();
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        // * GET ITEMS
        async getItems(ticketId) {
            var t = this
            // t.loading = true

            console.log('getItems')

            var searchQuery = `@deleted:{false} @ticketReplyLinkId:{${ticketId}}`
            let itemsResult = await this.REDIS_searchFor('ticketReply', this.itemsFrom, 999, 'createdDateTime','ASC', searchQuery )
            if (itemsResult.success) {
                if (this.itemsTotal !== null && itemsResult.data.total > this.itemsTotal) {
                    // more items than before
                    t.showScrollButton = true
                    t.numberDiff = itemsResult.data.total - this.itemsTotal
                }
                this.itemsTotal = itemsResult.data.total;
                this.ticketReplies = itemsResult.data.documents
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                if (this.firstLoad) {
                    setTimeout(() => {
                        t.scrollToBottom()
                        t.firstLoad = false
                    }, 0);
                }
                t.loading = false
            }
        },
    },
    watch: {
        refreshitems() {
            this.getItems();
        },
        ticketId: {
            handler: function () {
                console.log('ticketId: ' + this.ticketId)
                var t = this;
                t.loading = true
                clearInterval(this.itemsInterval)
                t.itemsTotal = null
                var ticketId = t.ticketId
                t.initialiseTicketReplySchema()
                console.log('ticketId: ' + ticketId)
                t.readItem(ticketId)
                t.getItems(ticketId)
                this.itemsInterval = setInterval(function () {
                    t.getItems(ticketId)
                }, 10000);
            }, deep: true
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
        ticketDialog: {
            handler: function () {
                if (this.ticketDialog == false) {
                    this.ticketDialogKey++
                }
            }, deep: true
        },
    },
    async created() {
        var t = this;
        t.loading = true
        t.schema = schema.ticket;
        t.ticketReplySchema = schema.ticketReply;
        var ticketId = t.ticketId
        // console.log('ticketId: ' + ticketId)

        t.readItem(ticketId)
        t.initialiseTicketReplySchema()

        let users = await t.REDIS_searchFor('user', '0', '999', 'userName', 'ASC', '@deleted:{false}');
        if (users.success) { t.users = users.data.documents; }

        t.users = t.users.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
            };
        })

        let siteManagers = await t.REDIS_searchFor('user', '0', '999', 'userName', 'ASC', `@deleted:{false} @userCategory:{'COMMERCIALMANAGER' | 'SENIORMANAGER'| 'DIRECTOR'} -@userStatus:{ARCHIVED}`);
        if (siteManagers.success) { t.siteManagers = siteManagers.data.documents; }

        t.siteManagers = t.siteManagers.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
            };
        })


        var clientUsers = await t.REDIS_searchFor('clientUser', '0', '9999', 'userName', 'ASC', `@deleted:{false} @userOrgId:${t.ticket.ticketOrgId}`);
        if (clientUsers.success) { t.clientUsers = clientUsers.data.documents; }

        t.clientUsers = t.clientUsers.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
            };
        })

        
        t.getItems(ticketId)
        this.itemsInterval = setInterval(function () {
            t.getItems(t.ticketId)
        }, 10000);
    },
    beforeDestroy() {
        clearInterval(this.itemsInterval)
    },
};
</script>

<style >
    .rightChip {
        border-radius: 16px, 16px, 16px, 0px !important;
    }
</style>


<style lang="scss">
html,
body,
#app,
.v-application--wrap,
.v-content,
.v-content__wrap {
    height: 100%;
}

.drop-in {
    box-shadow: 0 0 10px rgba(0, 0, 255, 0.3);
}
</style>

<style scoped>
.list {
    width: 100% !important;
    padding: 12px !important;
    margin: 0px !important;
}
.item {
    width: 100%;
    /* padding: 20px; */
    margin-top: 10px;
    background-color: white !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fullwidth {
    width: 100% !important;
}
</style>
