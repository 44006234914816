<template>
    <div class="" 
    style="padding: 0px !important; margin: 0px !important; width: 100% !important" 
    :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: 100vh !important; overflow: scroll !important '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>
                <div v-if="(item.authId == null || item.authId == '') && item.userStatus == 'APPROVED' && $vuetify.breakpoint.smAndDown" class="ml-1">
                    <v-btn class="ml-2 white--text secondary font-weight-medium" @click="openInviteDialog()" depressed>
                        <v-icon>icons8-email-send</v-icon>
                        send invite
                    </v-btn>
                </div>

                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="secondary white--text mr-2" v-if="editmode && !itemNew && item.userStatus !== 'ARCHIVED'" @click="archiveDialog = true"><v-icon class="mr-2">icons8-xbox-a</v-icon>ARCHIVE</v-btn>
                    <v-btn text class="primary white--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
                    <v-btn text class="primary white--text" v-if="editmode" @click="validateItem()">
                        <v-icon class="mr-2">icons8-save</v-icon>
                        <span v-if="itemNew">Save</span>
                        <span v-if="!itemNew">Update</span>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        
        <v-row class="apptext--text" dense style="z-index: 0 !important">
            <v-col v-if="!editmode && !loading" cols="12" class="d-flex pa-2 align-center">


                <!-- //* VIEW MODE -->
                <v-row style="width: 100% !important" no-gutters>
                    <!-- //* GENERAL DETAILS VIEW -->
                    <v-col cols="12" sm="12" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Details</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" class="" :style="$vuetify.breakpoint.mdAndUp ? 'height: 100% !important' : ''">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Full Name:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.userName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Job Title:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.userJobTitle }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Email:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.userEmail }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Job Telephone:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.userTelephone }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Contract Hours:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.contractHours }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">User Category:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.userCategory }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Audit Target:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.auditTarget }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Gender:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ titleCase(item.userGender) }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>

                            <v-col cols="12" sm="12" md="6" class="" :style="$vuetify.breakpoint.mdAndUp ? 'height: 100% !important' : ''">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Date of Birth:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2"><span v-if="item.userDOB !== 'Invalid date' && item.userDOB !== null && item.userDOB !== ''">{{ $moment(item.userDOB).format('DD-MMM-YYYY') }}</span></td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Start Date:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2"><span v-if="item.userStartDate !== 'Invalid date' && item.userStartDate !== null && item.userStartDate !== ''">{{ $moment(item.userStartDate).format('DD-MMM-YYYY') }}</span></td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Primary Line Manager:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ getUser(item.primaryLineManagerId) }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Secondary Line Manager:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ getUser(item.secondaryLineManagerId) }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Areas Covered:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2"><v-chip v-for="(area, index) in selectedAreas" :key="index">{{ area.areaName }}</v-chip></td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Offline Staff Member:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ titleCase(item.offlineStaffMember) }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="my-2 px-2">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Address</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr class="d-flex white">
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Address:</td>
                                                <td width="60%" valign="top" style="height: 100% !important" class="apptext--text font-weight-normal body-1 white pl-2">
                                                    <!-- <a :href="`https://www.google.com/maps/search/?api=1&query=${item.orgName},${item.userAddressLine1},${item.orgAddressLine2},${item.orgAddressLine3},${item.orgTown},${item.orgCounty},${item.orgPostcode}`" target="_blank"> -->
                                                        <span v-if="item.userAddressLine1"
                                                            >{{ item.userAddressLine1 }}<span v-if="item.userAddressLine2">, {{ item.userAddressLine2 }}</span
                                                            ><br
                                                        /></span>
                                                        <span v-if="item.userAddressLine3">{{ item.userAddressLine3 }}<br /></span>
                                                        <span v-if="item.userAddressTown"
                                                            >{{ item.userAddressTown }}<span v-if="item.userAddressCounty">, {{ item.userAddressCounty }}</span
                                                            ><br
                                                        /></span>
                                                        <span v-if="item.userAddressPostcode">{{ item.userAddressPostcode }}<br /></span>
                                                    <!-- </a> -->
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="my-2 px-2">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Next Of Kin</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Name:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.NOKName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Relationship:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.NOKRelationship }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Telephone Number:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.NOKTellNo }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="my-2 px-2">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Configuration</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">User Type:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ titleCase(item.userType) }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">User Role:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ titleCase(item.userRole) }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">User Status:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ titleCase(item.userStatus) }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="editmode && !loading" cols="12" class="d-flex pa-4 pt-4 align-center">
                <v-form ref="form" lazy-validation>
                    <v-row style="width: 100% !important" no-gutters>
                        <!-- DETAILS EDIT -->
                        <!-- <v-col cols="12">
                            <pre class="caption">{{ item }}</pre>
                        </v-col> -->
                        <!-- <v-col cols="6">
                            <pre class="caption">{{ selectedPackageIcon }}</pre>
                        </v-col> -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field 
                                        label="Full Name" 
                                        v-model="item.userName" 
                                        :rules="rules.userName"
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        @click:clear="clearItem('userName')">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field 
                                        label="Job Title" 
                                        v-model="item.userJobTitle" 
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        @click:clear="clearItem('serviceDescription')">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field 
                                        label="Email" 
                                        v-model="item.userEmail" 
                                        :rules="rules.userEmail"
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        @click:clear="clearItem('serviceDescription')">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field 
                                        label="Telephone" 
                                        v-model="item.userTelephone" 
                                        :rules="rules.userTelephone"
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        @click:clear="clearItem('serviceDescription')">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field 
                                        label="Contract Hours" 
                                        v-model="item.contractHours" 
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        type="number"
                                        :oninput="item.contractHours < 0 ? item.contractHours = 0 : ''"
                                        min=0
                                        @click:clear="clearItem('serviceDescription')">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-autocomplete 
                                        label="User Category" 
                                        v-model="item.userCategory" 
                                        :rules="rules.userCategory" 
                                        required 
                                        :items="userCategories" 
                                        :menu-props="{ top: false, offsetY: true }" 
                                        :attach="true"
                                        auto-select-first 
                                        dense 
                                        item-text="text"
                                        item-value="id"
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        class="">
                                    </v-autocomplete> 
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field 
                                        label="Audit Target" 
                                        v-model="item.auditTarget" 
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        min=0
                                        :oninput="item.auditTarget < 0 ? item.auditTarget = 0 : ''"
                                        type="number"
                                        @click:clear="clearItem(' ')">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-autocomplete 
                                        label="Gender" 
                                        v-model="item.userGender" 
                                        :rules="rules.userGender" 
                                        required 
                                        :items="genders" 
                                        :menu-props="{ top: false, offsetY: true }" 
                                        :attach="true"
                                        auto-select-first 
                                        item-text="text"
                                        item-value="id"
                                        dense 
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        class="">
                                        <!-- <template v-slot:item="data"> {{ data.item.clientName }} </template>
                                        <template v-slot:selection="data"> {{ data.item.clientName }} </template>    -->
                                    </v-autocomplete> 
                                </v-col>
                                <!-- //! TODO - CHANGE TO NEW DATE PICKER  -->
                                <v-col cols="12" xs="12" sm="6">
                                    <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field label="Date of Birth" v-model="computedDate1" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('userDOB')"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <!-- //! TODO - CHANGE TO NEW DATE PICKER  -->
                                <v-col cols="12" xs="12" sm="6">
                                    <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field label="Start Date" v-model="computedDate2" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('userStartDate')"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date2" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-select 
                                        class="" 
                                        hide-details="auto" 
                                        label="Primary Line Manager" 
                                        filled 
                                        v-model="selectedPrimaryLineManager"  
                                        outlined
                                        dense
                                        clearable
                                        :items="managers" 
                                        background-color="white" 
                                        @click:clear="clearItem('primaryLineManagerId')"
                                        color="primary"
                                        :menu-props="{ top: false, offsetY: true }" 
                                        :attach="true"
                                        item-text="userName" 
                                        return-object 
                                    ></v-select>
                                </v-col>
                                <!-- <pre>{{ selectedSecondaryLineManager }}</pre> -->
                                <v-col cols="12" xs="12" sm="6">
                                    <v-select 
                                        class="" 
                                        hide-details="auto" 
                                        label="Secondary Line Manager" 
                                        filled 
                                        v-model="selectedSecondaryLineManager"  
                                        outlined
                                        dense
                                        clearable
                                        @click:clear="clearItem('secondaryLineManagerId')"
                                        :items="managers" 
                                        background-color="white" 
                                        color="primary"
                                        :menu-props="{ top: false, offsetY: true }" 
                                        :attach="true"
                                        item-text="userName" 
                                        return-object
                                    ></v-select>
                                </v-col>
                                <!-- <pre>{{ selectedAreas }}</pre> -->
                                <v-col cols="12" xs="12" sm="12">
                                    <!-- <v-select 
                                        class="" 
                                        hide-details="auto" 
                                        label="Secondary Line Manager" 
                                        filled 
                                        v-model="selectedAreas"  
                                        outlined
                                        dense
                                        clearable
                                        :items="managers" 
                                        background-color="white" 
                                        color="primary"
                                        :menu-props="{ top: false, offsetY: true }" 
                                        :attach="true"
                                        item-text="areaName" 

                                        item-value="entityId" 
                                    ></v-select> -->

                                    <v-autocomplete 
                                        label="Areas Covered" 
                                        v-model="selectedAreas" 
                                        :rules="rules.userAreas" 
                                        required 
                                        multiple
                                        :items="areas" 
                                        :menu-props="{ top: false, offsetY: true }" 
                                        :attach="true"
                                        item-text="areaName" 
                                        auto-select-first 
                                        return-object 
                                        dense 
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        class="">
                                    </v-autocomplete> 
                                </v-col>
                                <v-col cols="12" xs="12" sm="4" class="d-flex align-center">
                                    <v-checkbox :true-value="true" :false-value="false" v-model="item.offlineStaffMember"  label="Offline Staff Member" hide-details="auto" class="ml-3 ma-0 pa-0"></v-checkbox>
                                </v-col>
                                <v-col v-if="item.offlineStaffMember" cols="12" xs="12" sm="8">
                                    <v-text-field 
                                        label="Offline Staff Member Reason" 
                                        v-model="item.offlineStaffMemberReason" 
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        @click:clear="clearItem('offlineStaffMemberReason')">
                                    </v-text-field>
                                </v-col>
                                <!-- <pre>offlineStaffMember: {{ item.offlineStaffMember }}</pre> -->
                            </v-row>

                            <v-row dense>

                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Address</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field 
                                        label="Address Line 1" 
                                        v-model="item.userAddressLine1" 
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        @click:clear="clearItem('userAddressLine1')">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field 
                                        label="Address Line 2" 
                                        v-model="item.userAddressLine2" 
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        @click:clear="clearItem('userAddressLine2')">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field 
                                        label="Address Line 3" 
                                        v-model="item.userAddressLine3" 
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        @click:clear="clearItem('userAddressLine3')">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field 
                                        label="Town" 
                                        v-model="item.userAddressTown" 
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        @click:clear="clearItem('userAddressTown')">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field 
                                        label="County"  
                                        v-model="item.userAddressCounty" 
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        @click:clear="clearItem('userAddressCounty')">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field 
                                        label="Postcode" 
                                        v-model="item.userAddressPostcode" 
                                        :rules="rules.userAddressPostcode"
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        @click:clear="clearItem('userAddressPostcode')">
                                    </v-text-field>
                                </v-col>
                            
                            </v-row>
                            <v-row dense>

                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Next Of Kin</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" sm="4">
                                    <v-text-field 
                                        label="Name"  
                                        v-model="item.NOKName" 
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        @click:clear="clearItem('NOKName')">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="4">
                                    <v-text-field 
                                        label="Relationship" 
                                        v-model="item.NOKRelationship" 
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        @click:clear="clearItem('NOKRelationship')">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="4">
                                    <v-text-field 
                                        label="Telephone Number" 
                                        v-model="item.NOKTellNo" 
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        @click:clear="clearItem('NOKTellNo')">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row dense class="pb-4">

                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Configuration</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" sm="4">
                                    <v-select 
                                        class="" 
                                        hide-details="auto" 
                                        label="User Type" 
                                        :rules="rules.userType" 
                                        filled 
                                        v-model="item.userType" 
                                        required 
                                        outlined
                                        dense
                                        :items="userTypes" 
                                        background-color="white" 
                                        color="primary"
                                        :menu-props="{ top: true, offsetY: true }" 
                                        :attach="true"
                                        item-text="text"
                                        item-value="id"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" sm="4">
                                    <v-select 
                                        class="" 
                                        hide-details="auto" 
                                        label="User Role" 
                                        :rules="rules.userRole" 
                                        filled 
                                        v-model="item.userRole" 
                                        required 
                                        outlined
                                        dense
                                        :items="userRoles" 
                                        background-color="white" 
                                        color="primary"
                                        :menu-props="{ top: true, offsetY: true }" 
                                        :attach="true"
                                        item-text="text"
                                        item-value="id"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" sm="4">
                                    <v-select 
                                        class="" 
                                        hide-details="auto" 
                                        label="User Status" 
                                        :rules="rules.userStatus" 
                                        filled 
                                        v-model="item.userStatus" 
                                        required 
                                        outlined
                                        dense
                                        :items="userStatuses" 
                                        background-color="white" 
                                        color="primary"
                                        :menu-props="{ top: true, offsetY: true }" 
                                        :attach="true"
                                        item-text="text"
                                        item-value="id"
                                        @change="statusChanged = true"
                                    ></v-select>
                                </v-col>
                                <!-- <pre>Status changed: {{ statusChanged }}</pre> -->
                            </v-row>



                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>

        <v-dialog persistent v-model="archiveDialog" width="500px" height="400px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 secondary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mr-3 mb-1">
                        <v-icon class="white--text">icons8-xbox-a</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Archiving User</strong>
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="archiveDialog = !archiveDialog" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 pa-0">               
                    
                    <v-row class="pa-0 pt-4 ma-0 justify-center d-flex" fill-height>
                        <v-col cols="12" class="align-center pt-0">
                            <v-menu v-model="dateMenu3" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field label="Archive From" v-model="computedDate3" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('contentAvailableFrom')"></v-text-field>
                                </template>
                                <v-date-picker v-model="date3" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu3 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="pa-0" fill-height cols="12" style="width: 100% !important">
                            <div class="font-weight-bold body-2 pa-3 text-h7 grey--text">
                                This will unallocate any Jobs relating to this User from {{ computedDate3 }}
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-0">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="archiveDialog = !archiveDialog"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                            <v-btn class="primary white--text font-weight-bold ml-2" depressed @click="archiveUser"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
                        </v-col>
                    </v-col>
                </v-card-actions>
            </div>
        </v-dialog>
        
        <v-dialog persistent v-model="progressDialog" width="500px" height="400px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 secondary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mr-3 mb-1">
                        <v-icon class="white--text">icons8-future</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Unallocate Jobs</strong>
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeProgressDialog" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 px-0 pa-3 d-flex flex-column align-center">    

                    <v-progress-circular v-if="progressData.status == 'In Progress' || progressData.status == 'Not Started'" indeterminate :size="60" :width="7"></v-progress-circular>
                    <div v-if="progressData.status == 'In Progress' || progressData.status == 'Not Started'" class=" grey--text font-weight-bold text--darken-2 d-flex align-center animate__animated animate__flash animate__infinite animate__slow">The Jobs linked to this User are being archived. Please wait...</div>
                    <div v-if="progressData.status == 'Completed'" class=" grey--text font-weight-bold text--darken-2 d-flex align-center animate__animated animate__flash animate__infinite animate__slow">The Jobs linked to this User have been successfully archived.</div>
                    <div v-if="progressData.firebaseAuthDeleted && progressData.firebaseAuthDeleted == true" class=" grey--text font-weight-bold text--darken-2 d-flex align-center justify-center animate__animated animate__flash animate__infinite animate__slow px-8">This Users Authentication has successfully been deleted. If this Users account is re-activated, the user must re-register to the app.</div>

                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-0">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn class="grey darken-1 white--text font-weight-bold" :disabled="progressData.status == 'In Progress'" depressed @click="closeProgressDialog"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Close</span> </v-btn>
                            <!-- <v-btn class="primary white--text font-weight-bold ml-2" depressed :disabled="progressData.status == 'In Progress'" @click="archiveRule()"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn> -->
                        </v-col>
                    </v-col>
                </v-card-actions>
            </div>
        </v-dialog>

        <v-dialog persistent v-model="statusDialog" width="500px" height="400px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 secondary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mr-3 mb-1">
                        <v-icon class="white--text">icons8-future</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Status Changed</strong>
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeStatusDialog" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 px-0 pa-3 d-flex flex-column align-center">    

                    <div  class=" grey--text font-weight-bold text--darken-2 d-flex align-center justify-center animate__animated animate__flash animate__infinite animate__slow px-3">
                        This Users Status has been changed. Please note that this User will be required to Register again to the App.
                    </div>

                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-0">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="closeStatusDialog"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Close</span> </v-btn>
                            <v-btn class="primary white--text font-weight-bold ml-2" depressed @click="saveItem()"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
                        </v-col>
                    </v-col>
                </v-card-actions>
            </div>
        </v-dialog>
        <v-dialog persistent v-model="inviteDialog" width="500px" height="400px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 secondary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mr-3 mb-1">
                        <v-icon class="white--text">icons8-email-send</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Send Invitation to Register</strong>
                        </span>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 pa-3 body-2 font-weight-bold grey--text">    
                    This will send an invitation to the below email for the user to register with the system. Once they have registered, they will be able to login to the system.

                    <div class="text-center grey--text text--darken-2 py-2">{{ item.userEmail }}</div>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-0">
                    <v-col cols="12" class="d-flex">
                        <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="closeInviteDialog()"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                        <v-spacer />
                        <v-btn class="primary white--text font-weight-bold ml-2" depressed @click="sendInvite"> <v-icon class="mr-2">icons8-save</v-icon><span>Confirm</span> </v-btn>
                    </v-col>
                </v-card-actions>
            </div>
        </v-dialog>
    </div>  
</template>

<script>
import moment from 'moment';
// TODO - RG - Add Paging to the results
// import schema from '@/schema.js';
import { mapGetters } from 'vuex';

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        // activeitem: {
        //     Type: Object,
        //     Default: null,
        // },
    },
    // * DATA
    data: () => ({
        key: 'action',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,
        // dropdowns
        packageTypes: [],
        billingCycles: [],
        packageStatus: [],
        selectedPackageIcon: {
            name: '',
            icon: ''
        },  
        months: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],

        userCategories: [
            {   
                text: 'Mobile Cleaners',
                id: 'MOBILECLEANER'
            }, {
                text: 'Director', 
                id: 'DIRECTOR'
            }, {
                text: 'Commercial Manager', 
                id: 'COMMERCIALMANAGER'
            }, {
                text: 'Office Staff', 
                id: 'OFFICESTAFF'
            }, {
                text: 'Security Staff', 
                id: 'SECURITYSTAFF'
            }, {
                text: 'Cleaner', 
                id: 'CLEANER'
            }, {
                text: 'Supervisor',
                id: 'SUPERVISOR'
            },{
                text: 'Senior Manager',
                id: 'SENIORMANAGER'
            },
        ],

        genders: [
            {   
                text: 'Male',
                id: 'MALE'
            }, {
                text: 'Female', 
                id: 'FEMALE'
            },{
                text: 'Prefer Not To Say', 
                id: 'PREFERNOTTOSAY'
            },
        ],

        userTypes: [   
            {   
                text: 'Developer',
                id: 'DEVELOPER'
            }, {
                text: 'Staff', 
                id: 'STAFF'
            },{
                text: 'Client', 
                id: 'CLIENT'
            }
        ],

        userRoles: [   
            {   
                text: 'Admin',
                id: 'ADMIN'
            }, {
                text: 'User', 
                id: 'USER'
            },
            // {
            //     text: 'Manager', 
            //     id: 'MANAGER'
            // }
        ],

        userStatuses: [
            {   
                text: 'Archived',
                id: 'ARCHIVED',
                disabled: true
            }, {
                text: 'Pending', 
                id: 'PENDING',
                disabled: false
            }, {
                text: 'Approved', 
                id: 'APPROVED',
                disabled: false
            }, {
                text: 'Suspended', 
                id: 'SUSPENDED',
                disabled: false
            }
        ],

        // new address object
        item: {},
        loading: false,
        rules: {},
        selectedPrimaryLineManager: '',
        selectedSecondaryLineManager: '',


        managers: [],

        selectedAreas: [],
        areas: [],

        date1: '',
        dateMenu1: false,
        date2: '',
        dateMenu2: false,

        users: [],

        rules: [],

        archiveDialog: false,
        date3: moment().format('YYYY-MM-DD'),
        dateMenu3: false,

        progressDialog: false,
        progress: 0,
        progressData: {
            id: "",
            totalItems: 0,
            count: 0,
            success: 0,
            error: 0,
            status: "Not Started",
            report: "",
        },
		progressStatus: "Not Started",
		checkProgressInterval: null,

        statusChanged: false,
        statusDialog: false,

        // invite 
        inviteDialog: false,
    }),
    computed: {  
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser'
        }),
        computedDate1() {
            return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
        computedDate2() {
            return this.MIX_formatDateTime(this.date2, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
        computedDate3() {
            return this.MIX_formatDateTime(this.date3, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
    },
    methods: {
        openInviteDialog() {
            var t = this; 
            t.inviteDialog = true;
        },
        closeInviteDialog() {
            var t = this; 
            t.inviteDialog = false;
        },
        async sendInvite() {
            var t = this;
            this.REDIS_sendInviteLink(t.item.entityId)
            t.closeInviteDialog()
			this.MIX_alertBox({ color: "success", timeout: 2000, message: "Invite Sent", show: true });
        },
        validateItem() {
			let t = this;
			t.rules = {
				userName: [(v) => !!v || "User Name Required"],
				userEmail: [
                    value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    },
                ],
				userTelephone: [
                    value => {
                        const pattern = /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/

                        return pattern.test(value) || 'Invalid Telephone Number.'
                    },
                ],
                // userAddressPostcode: [
                //     value => {
                //         const pattern = /^[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}$/i
                //         return pattern.test(value) || 'Invalid Postcode.'
                //     },
                // ]
			};
			t.$nextTick(() => {
				if (t.$refs.form.validate()) {
                    if (t.editmode == true && t.itemNew == true) {
					    this.createItem();
                    } else if (t.editmode == true && t.itemNew == false) {
                        this.checkStatus();
                    }
					this.editMode = false;
				} else {
					this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
				}
			});
        },
        showStatusChangedDialog() {
            var t = this; 

            t.statusDialog = true
        },
        closeStatusDialog() {
            var t = this; 

            t.statusDialog = false
        },
        checkStatus() {
            var t = this; 

            if (t.statusChanged == true && t.item.userStatus == 'APPROVED') {
                t.showStatusChangedDialog()
            } else {
                t.saveItem()
            }
        },
        initialise() {
            var t = this
            this.item = { ...this.datadown };
            
            if (this.item.userName !== '') {
                this.itemNew = false;
                this.editmode = false;
            } else {
                this.itemNew = true;
                this.editmode = true;
            }
            if (this.item.userAreas !== '[]' && this.item.userAreas !== null) {
                this.selectedAreas = []
                console.log(JSON.stringify(this.item.userAreas, null, 2))
                this.item.userAreas.forEach((area) => {
                    var selectedArea = this.areas.filter(function(item) {
                        return item.entityId === area
                    })
                    this.selectedAreas.push(selectedArea[0])

                })
            } else {
                this.selectedAreas = []
            }

            if (this.item.primaryLineManagerId !== '' && this.item.primaryLineManagerId !== null) {
                this.selectedPrimaryLineManager = this.managers.filter((item) => {
                    return item.entityId === this.item.primaryLineManagerId
                })
                this.selectedPrimaryLineManager = this.selectedPrimaryLineManager[0]
            }

            if (this.item.secondaryLineManagerId !== '' && this.item.secondaryLineManagerId !== null) {
                this.secondaryLineManagerId = this.managers.filter((item) => {
                    return item.entityId === this.item.secondaryLineManagerId
                })
                this.secondaryLineManagerId = this.secondaryLineManagerId[0]
            }
            console.log('this.item.userDOB = ' + this.item.userDOB)
            if (this.item.userDOB !== '' && this.item.userDOB !== null && this.item.userDOB !== 'Invalid date') {
                this.date1 = this.$moment(this.item.userDOB).format('YYYY-MM-DD');
            } else {
                this.date1 = ''
            }
            
            if (this.item.userStartDate !== '' && this.item.userStartDate !== null) {
                this.date2 = this.$moment(this.item.userStartDate).format('YYYY-MM-DD');
            } else {
                this.date2 = ''
            }

            t.loading = false
            
        },
        updateItem() {
            this.$emit('update', this.item);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {
            this.item.contractHours = parseInt(this.item.contractHours)
            this.item.auditTarget = parseInt(this.item.auditTarget)
            this.item.userLevel = this.item.userType.substring(0,1) + this.item.userRole.substring(0,1)
            this.$emit('update', this.item);
            this.editmode = false;
            this.closeStatusDialog()
        },
        async createItem() {
            this.item.contractHours = parseInt(this.item.contractHours)
            this.item.auditTarget = parseInt(this.item.auditTarget)
            this.item.userLevel = this.item.userType.substring(0,1) + this.item.userRole.substring(0,1)

            this.$emit('save', this.item);
            this.editmode = false;
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        titleCase(str) {
            console.log('titleCase = ' + JSON.stringify(str, null, 2))
            var lower = ''
            var string = ''
            if (str !== null) {
                lower = str.toLowerCase()
                string = str.charAt(0).toUpperCase() + lower.slice(1)
            }
            return string
        },
        getUser(userId) {
            var t = this;
            var user = []
            var userName = ''

            user = t.users.filter((item) => { return item.entityId == userId })

            if (user.length !== 0) { userName = user[0].userName }

            return userName
        },
        async archiveUser() {
            var t = this;

            t.item.userStatus = 'ARCHIVED'

            let data = {}

            data.userId = t.item.entityId 
            data.archiveFromDate = t.$moment(t.computedDate3, 'DD-MMM-YYYY').format('X')
            data.modifiedUserId = t.GET_FIREBASE_currentUser.entityId
            data.progressId = t.MIX_generateId()

            let result = await t.REDIS_archiveUser(data)

            // debugger
            if (result.success) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Client and Jobs Archived", show: true });
                this.checkProgress(data.progressId)
                this.progressDialog = true
            }
            
            t.archiveDialog = false
            t.updateItem()

        },

        // Check Progress of Import
        checkProgress(progressId) {
            let t = this;
            this.checkProgressInterval = setInterval(async () => {
                try {
                    let progressResult = await t.REDIS_read("progress", progressId);
                    if (progressResult.status !== "404") {
                        console.log('progressResult = ' + JSON.stringify(progressResult, null, 2))
                        t.progressData = progressResult.data;
                        t.progressStatus = progressResult.data.status;
                        if (progressResult.data.count !== 0 && progressResult.data.totalItems !== 0) {
                            t.progress = (progressResult.data.count / progressResult.data.totalItems) * 100;
                        } else {
                            t.progress = 100;
                        }
                        if (progressResult.data.status === "Completed") {
                            clearInterval(t.checkProgressInterval);
                        }
                    } else if (progressResult.status === "404") {
                        clearInterval(t.checkProgressInterval);
                    }
                } catch (error) {
                    console.error(error);
                    clearInterval(t.checkProgressInterval);
                }
            }, 1000);
        },


        closeProgressDialog() {
            var t = this;
            t.progressDialog = false
        },
    },
    watch: {
        // Selected Tags
        selectedAreas: {
            handler: function () {
                this.item.userAreas = [];
                
                if (this.selectedAreas.length > 0) {
                    for (let i = 0; i < this.selectedAreas.length; i++) {
                        var item = this.selectedAreas[i].entityId
                        
                        const index = this.item.userAreas.indexOf(item);
                        
                        if (index == -1) {
                            this.item.userAreas.push(item);
                        }
                        
                    }
                }
                
            },
            deep: true,
        },
        selectedPrimaryLineManager: {
            handler: function () {
                this.item.primaryLineManagerId = this.selectedPrimaryLineManager.entityId
            }, 
            deep: true
        },
        selectedSecondaryLineManager: {
            handler: function () {
                this.item.secondaryLineManagerId = this.selectedSecondaryLineManager.entityId
            }, deep: true
        },
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        dateMenu1: {
            handler: async function () {
                this.item.userDOB = this.MIX_formatDateTime(this.date1, 'YYYY-MM-DD');
            },
            deep: true,
        },
        dateMenu2: {
            handler: async function () {
                this.item.userStartDate = this.MIX_formatDateTime(this.date2, 'YYYY-MM-DD');
            },
            deep: true,
        },

    },
    // * CREATED
    async created() {
        let t = this;
        // t.schema = schema.package;
        t.loading = true


        let areas = await t.REDIS_searchFor('area', '0', '9999', 'areaName', 'ASC', '@deleted:{false}');
        if (areas.success) { t.areas = areas.data.documents; }

        t.areas = t.areas.map((x) => {
            return {
                entityId: x.entityId,
                areaName: x.areaName,
            };
        })

        let managers = await t.REDIS_searchFor('user', '0', '9999', 'userName', 'ASC', `@deleted:{false} @userCategory:{'COMMERCIALMANAGER' | 'DIRECTOR' | SENIORMANAGER }`)
        if (managers.success) { t.managers = managers.data.documents; }

        t.managers = t.managers.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
            };
        })

        let users = await t.REDIS_searchFor('user', '0', '999', 'userStartDate', 'ASC', '@deleted:{false}');
        if (users.success) { t.users = users.data.documents; }

        t.users = t.users.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
            };
        })



        t.initialise();
    },
    mounted() {},
};
</script>