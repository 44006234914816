<template>
	<v-row class="pa-0 ma-0" fill-height>
        <v-row no-gutters class="pb-3 px-3 pt-3 primary darken-2">
            <v-col class="font-weight-bold white--text text-h6 d-flex align-center" v-if="JSON.stringify(item) !== '{}'">
                <div class="mx-3 mb-1"><v-icon class="white--text">icons8-vacuum-cleaner</v-icon></div>
				<div>
					<span class="font-weight-light"><strong>Service: </strong>{{ item.serviceName }}</span>
				</div>
				<div class="ml-2 font-weight-light" v-if="item.serviceName === ''">New</div>
				<v-spacer></v-spacer>
				<div>
					<v-btn class="ml-2 white--text" plain @click="closeItem" depressed>
						<v-icon>icons8-multiply</v-icon>
					</v-btn>
				</div>
            </v-col>
        </v-row>
		<v-col class="pa-0" fill-height cols="auto" style="width: 100% !important">
			<v-tabs background-color="white" v-model="tab" show-arrows>
				<v-tab href="#details">Details</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab" fill-height>
				<v-tab-item value="details" class="grey lighten-4">
					<service-details 
                        :datadown="item" 
                        :itemnew="itemNew"
                        v-on:update="updateItem" 
                        v-on:save="createItem" 
                    />
				</v-tab-item>
			</v-tabs-items>
		</v-col>
	</v-row>
</template>

<script>
	import { mapGetters } from "vuex";
	import serviceDetails from "@/views/services/serviceDetails.vue";
	import schema from "@/schema.js";
	export default {
		// * COMPONENTS
		components: {
			"service-details": serviceDetails,
		},
		// * PROPS
		props: {
			datadown: {
				type: Object,
				default: function () {
					return {};
				},
			},
		},
		// * DATA
		data: () => ({
			key: "service",
			tab: "details",
			schema: null,
			// Item
			item: {},
			itemNew: false,
			editMode: false,

			// Form items
			accountTypes: [],
			addressTypes: [],
			industries: [],

			// Tags
			computedOrgTags: [],

			// Validation
			rules: {},

			// Toggles
			addAddressToggle: false,
			addManualAddress: false,
			addOtherAddressToggle: false,
			addLeadStatusToggle: false,
			addOrgInfoToggle: false,
			addSocialInfoToggle: false,
		}),
		// * COMPUTED
		computed: {
            ...mapGetters({
                GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
            }),
			computedFullName() {
				return this.item.contactFirstName + " " + this.item.contactLastName;
			},
		},
		// * METHODS
		methods: {
			// * INITIALISE ITEM
			initialiseItem() {
				let t = this;
				if (JSON.stringify(t.datadown) !== "{}") {
					this.readItem(t.datadown.entityId);
				} else {
					t.itemNew = true;
					t.editMode = true;
					t.item = { ...t.schema };
					t.item.entityId = t.MIX_generateId();
				}
			},
			// * VALIDATION RULES
			// TODO - RG - I think this needs to be moved to contactDetails.vue
			validateItem() {
				let t = this;
				t.rules = {
					orgName: [(v) => !!v || "Organisation Name Required"],
				};
				t.$nextTick(() => {
					if (t.$refs.form.validate()) {
						this.createItem();
						this.editMode = false;
					} else {
						this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
					}
				});
			},
            // * UPDATE ITEM 
            async updateItem(item) {
				let t = this;
                var dateTimeNow = t.$moment().toISOString()

                item.modifiedDateTime = dateTimeNow;
                item.modifiedUserId = t.GET_FIREBASE_currentUser.entityId;
                
                let result = await this.REDIS_update(t.key, item.entityId, item);
                
				if (result.success) {
					t.MIX_alertBox({ color: "success", timeout: 2000, message: "Updated", show: true });
					t.readItem(item.entityId);
					t.$emit("refreshitems");
				}

            },
			// * CREATE ITEM
			async createItem(item) {
				let t = this;
                var dateTimeNow = t.$moment().toISOString()

                item.createdDateTime = dateTimeNow;
                item.createdUserId = t.GET_FIREBASE_currentUser.entityId;

                //console.log('item = ' + JSON.stringify(item, null, 2))
                
                let result = await this.REDIS_create(t.key, item);
                
				if (result.success) {
					t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
					t.readItem(result.data.entityId);
					t.$emit("refreshitems");
				}
			},
			// * READ ITEM
			async readItem(itemId) {
				let t = this;
				let result = await t.REDIS_read(t.key, itemId);
				if (result.success) {
					this.item = result.data;
					t.itemNew = false;
					t.loading = false;
				}
			},
			// * DESTROY ITEM
			async destroyItem() {
				let result = await this.MIX_destroyItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// * DELETE ITEM
			async deleteItem() {
				let result = await this.MIX_deleteItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// * RESTORE ITEM
			async restoreItem() {
				let result = await this.MIX_restoreItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// Close Item
			closeItem() {
				this.$emit("closeitem");
			},
			cancelItem() {
				this.readItem(this.item.id);
			}
		},
		// * WATCH
		watch: {
			// Datadown Changes
			datadown() {
				this.initialiseItem();
			},
			computedOrgTags(val) {
				var t = this;
				var splitVal = [];
				splitVal = t.item.orgTags.split(",");

				// //console.log(JSON.stringify(val, null, 2));
				return splitVal;
			},
		},
		// * CREATED
		async created() {
			let t = this;
			t.loading = true;
			t.schema = schema.service;
			t.initialiseItem();
		},
		// * MOUNTED
		async mounted() {},
	};
</script>

<style scoped>
	.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
	.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
	.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
		height: 22px;
	}
</style>
