const qr_code_reader_store = {
    namespaced: true, //to be case sencitive
    state: {
        result: '',
        error: '',
    },
    mutations:{
        MUT_setResult(state,data){
            state.result = data;
        },
        MUT_setError(state,data){
            state.error = data;
        }
    },
    getters:{
        GET_getResult(state){
            return state.result;
         },
        GET_getError(state){
            return state.error;
        }
    },
    actions: {
        ACT_result({commit}, payload){
            commit('MUT_setResult', payload);
        },
        ACT_error({commit}, payload){
            commit('MUT_setError', payload);
        }
    }
};
export default qr_code_reader_store;