<template>
    <!-- <div> -->
        <v-chart class="chart" :option="option" />
        <!-- <pre>{{ option.series[1] }}</pre> -->
    <!-- </div> -->
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { GridComponent } from "echarts/components";
import { TitleComponent, TooltipComponent, LegendComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
use([GridComponent, CanvasRenderer, BarChart, TitleComponent, TooltipComponent, LegendComponent]);

export default {
    name: "BarChart",
    components: {
        VChart,
    },
    provide: {
        [THEME_KEY]: "light",
    },
    props: {
        seriesdata: {
            type: Array,
            default: () => [],
        },
        color: {
            type: String,
        },
        seriestext: {
            type: String,
        },
    },
    data: () => ({
        colors: [],


        projecteddata: [
            {
                "name": "previousMonth",
                "value": 50
            },
            {
                "name": "previousMonth",
                "value": 40
            },
            {
                "name": "previousMonth",
                "value": 70
            },
        ]
    }),
    computed: {
        option() {
            return {
                legend: {},
                tooltip: {},
                xAxis: { 
                    type: 'category',
                    data: ['Last Month', 'This Month', 'Next Month'],
                    axisLabel: {
                        // textStyle: {
                        //     color: "#ffffff",
                        // }
                    },
                    splitLine: {
                        show: false
                    }

                },
                yAxis: {
                    axisLine: false,
                    // show: false,
                    splitLine: {
                        show: false
                    }
                },
                series: [
                    {
                        // label: {
                        //     show: true,
                        //     formatter: "{b}",
                        //     position: ['5', '30%'],
                        //     color: "#ffffff",
                        // },
                        radius: "100%",
                        data: this.seriesdata.map((x) => x.value),
                        type: "bar",
                        label: {
                            show: true,
                            formatter: (c) => c.value > 0 ? c.value : '',
                            position: 'inside',
                            // textStyle: {
                            //     color: "#ffffff",
                            // }
                        },
                        // showBackground: true,
                        // color: this.color,
                        // backgroundStyle: {
                        //     color: "#c5c5c5",
                        // },
                    },
                    {
                        // label: {
                        //     show: true,
                        //     formatter: "{b}",
                        //     position: ['5', '30%'],
                        //     color: "#ffffff",
                        // },
                        radius: "100%",
                        data: this.projecteddata.map((x) => x.value),
                        type: "bar",
                        label: {
                            show: true,
                            position: 'inside',     
                            // textStyle: {
                            //     color: "#ffffff",
                            // }
                        },
                        // showBackground: true,
                        color: "#c5c5c5",
                        // backgroundStyle: {
                        //     color: "#c5c5c5",
                        // },
                    },
                ],
            };

            // return {
            //     tooltip: {
            //         trigger: "item",
            //         formatter: "{a} <br/>{b} : {c} ({d}%)",
            //     },
            //     // legend: {
            //     //     orient: "vertical",
            //     //     left: "left",
            //     //     data: ["Direct", "Email", "Ad Networks", "Video Ads", "Search Engines"],
            //     // },
            //     xAxis: {
            //         type: "category",
            //         data: this.seriesdata.map((x) => x.name)
            //     },
            //     yAxis: {
            //         type: "value",
            //     },
            //     series: [
            //         {
            //             name: this.seriestext,
            //             type: "bar",
            //             radius: ["40%", "70%"],
            //             color: this.colors,
            //             data: this.seriesdata.map((x) => x.value),
            //             emphasis: {
            //                 itemStyle: {
            //                     shadowBlur: 10,
            //                     shadowOffsetX: 0,
            //                     shadowColor: "rgba(0, 0, 0, 0.5)",
            //                 },
            //             },
            //         },
            //     ],
            // };
        },
    },
    methods: {
        LightenColor(color, percent) {
            var num = parseInt(color, 16),
                amt = Math.round(2.55 * percent),
                R = (num >> 16) + amt,
                B = ((num >> 8) & 0x00ff) + amt,
                G = (num & 0x0000ff) + amt;
            return (0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 + (G < 255 ? (G < 1 ? 0 : G) : 255)).toString(16).slice(1);
        },
    },
    created() {
        this.colors = [];
        let color = this.color.slice(1, 10);
        let seriesLength = this.seriesdata.length;
        for (let i = 0; i < seriesLength; i++) {
            let newColour = this.LightenColor(color, i * 10);
            this.colors.push("#" + newColour);
        }
    },
};
</script>
<style scoped>
.chart {
    width: 70% !important;
    height: 300px !important;

    /* margin-bottom: -40px;
    margin-top: -50px; */
    /* height: 100vh; */
}
</style>
