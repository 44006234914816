import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import store from '@/store.js';
// import firebaseStore from '@/firebase/auth/firebase_auth_store.js';
import axios from 'axios'


import Home from '@/views/home/home.vue'


import Login from '@/views/auth/login.vue'
import Reset from '@/views/auth/reset.vue'
import Register from '@/views/auth/register.vue'
import Services from "@/views/services/services.vue";
import Areas from "@/views/areas/areas.vue";
import Clients from "@/views/clients/clients.vue";
import Courses from "@/views/courses/courses.vue";
import Jobs from "@/views/jobs/jobs.vue";
import MobileJobs from "@/views/jobs/mobileJobs.vue";
import Rota from "@/views/jobs/mobileJobs.vue";
import SwappedJobs from "@/views/jobs/swappedJobs.vue";
import Job from "@/views/jobs/job.vue";
import BulkJobs from "@/views/bulkJobs/bulkJobs.vue";
import RecurringJobs from "@/views/recurringJobs/recurringJobs.vue";
import RecurringJob from "@/views/recurringJobs/recurringJob.vue";
import StaffDirectory from "@/views/staffDirectory/staffDirectory.vue";
import Sites from "@/views/sites/sites.vue";
import Reports from "@/views/reports/reports.vue";
import Dashboard from "@/views/dashboard/dashboard.vue";
import Swapp from "@/views/swapp/swapp.vue";
import TaskTemplates from "@/views/taskTemplates/taskTemplates.vue";
import Tasks from "@/views/hotlistTasks/hotlistTasks.vue";
import HotlistTasks from "@/views/hotlistTasks/hotlistTaskCardList.vue";
import SiteTasks from "@/views/siteTasks/siteTasks.vue";
import Communications from "@/views/communications/communications.vue";
import Progress from "@/views/progress/progresses.vue";
import NotAuthorised from "@/views/auth/notauthorised.vue";

Vue.use(VueRouter)


const routes = [
    { path: '/', name: 'Login', component: Login, meta: { requiresAuth: false, hideNavbar: true, hideAppBar: true } },
    { path: '/home', name: 'Home', component: Home, meta: { requiresAuth: true }},
    { path: '/notauthorised', name: 'NotAuthorised', component: NotAuthorised, meta: { requiresAuth: false, hideNavbar: false, hideAppBar: true } },
    { path: '/register', name: 'Register', component: Register, meta: { requiresAuth: false, hideNavbar: true, hideAppBar: true } },
    { path: '/reset', name: 'Reset', component: Reset, meta: { requiresAuth: false, hideNavbar: true, hideAppBar: true } },
    { 
        path: '/services', 
        name: 'Services', 
        component: Services, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/siteTasks', 
        name: 'SiteTasks', 
        component: SiteTasks, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/areas', 
        name: 'Areas', 
        component: Areas, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/dashboard', 
        name: 'Dashboard', 
        component: Dashboard, 
        meta: { 
            hideNavbar: true, 
            // requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/clients/:id', 
        name: 'Clients', 
        component: Clients, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/courses', 
        name: 'Courses', 
        component: Courses, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/recurringJobs', 
        name: 'Recurring Jobs', 
        component: RecurringJobs, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/recurringJob/:id', 
        name: 'Recurring Job', 
        component: RecurringJob, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/bulkJobs', 
        name: 'Batch Jobs', 
        component: BulkJobs, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/swappedJobs', 
        name: 'Swapped Jobs', 
        component: SwappedJobs, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/jobs', 
        name: 'Jobs', 
        component: Jobs, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/rota', 
        name: 'Rota', 
        component: MobileJobs, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/mobileJobs', 
        name: 'Mobile Jobs', 
        component: MobileJobs, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/job/:id', 
        name: 'Job', 
        component: Job, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/audit', 
        name: 'Audit', 
        component: Job, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/reports', 
        name: 'Reports', 
        component: Reports, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/sites/:id', 
        name: 'Sites', 
        component: Sites, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/staffDirectory/:id', 
        name: 'Staff Directory', 
        component: StaffDirectory, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/swapp/:direction?', 
        name: 'Swapp', 
        component: Swapp, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/taskTemplates', 
        name: 'TaskTemplates', 
        component: TaskTemplates, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/tasks', 
        name: 'Tasks', 
        component: Tasks, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/hotlistTasks', 
        name: 'HotlistTasks', 
        component: HotlistTasks, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/communications/:id', 
        name: 'Communications', 
        component: Communications, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
    { 
        path: '/progress', 
        name: 'Progress', 
        component: Progress, 
        meta: { 
            hideNavbar: false, 
            requiresAuth: true,
            // userLevel: ['SA', 'CU']
        } 
    },
]


const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
})


router.beforeEach(async (to, from, next) => {
    try {
		console.log((to.meta.requiresAuth ? 'requiresAuth' : 'noAuth') + ' ' + to.path )
		
        // Does route require Auth
        if (to.meta.requiresAuth) {
			// Yes - Route Requires Auth
			// Update Service Worker
			navigator.serviceWorker.register("/service-worker.js").then((reg) => {
				reg.update();
			});
			// Get User Profile
			let userProfile = store.getters.GET_currentUser;
            // console.log('userProfile = ' + JSON.stringify(userProfile, null, 2))

            // // get updated user profile
            // var newUserProfile = await axios.get(`https://arservices-server-production-standard-4e36qy4jrq-nw.a.run.app/api/v1/user/${userProfile.entityId}`)
            // newUserProfile = newUserProfile.data.data
            // store.commit('SET_currentUser', newUserProfile)
            // // console.log('newUserProfile = ' + JSON.stringify(newUserProfile, null, 2))

            // get updated user profile
            var newUserProfile = await axios.get(`https://arservices-server-production-standard-4e36qy4jrq-nw.a.run.app/api/v1/user/${userProfile.entityId}`)
            newUserProfile = newUserProfile.data.data
            store.commit('SET_currentUser', newUserProfile)
            // this.MIX_FIREBASE_currentUser(newUserProfile)
            // store.dispatch('ACT_FIREBASE_currentUser', newUserProfile)
            // console.log('newUserProfile = ' + JSON.stringify(newUserProfile, null, 2))
            
			// If User Profile is null then user needs to login
			if (newUserProfile === null) {
				// Redirect to Login
				next('/');
			} else {
                var userAuthenticated = false;

                // Check User Level and Status
                if (newUserProfile.userStatus == 'APPROVED') {
                    userAuthenticated = true;
                }


                // console.log('to.meta = ' + JSON.stringify(to.meta, null, 2))
				if (to.meta !== undefined) {
					// console.log('to.meta.item = ' + JSON.stringify(to.meta, null, 2))

					if (to.meta.requiresAuth == true && userAuthenticated == false) {
						console.log('Unauthorised Access');
						next('/notauthorised');
					} else if (to.meta.requiresAuth == true && userAuthenticated == true || to.meta.requiresAuth == false) {
						console.log('Authorised Access');
						next();
					}
				} else {
					// Move to Next Route
					next();
				}
			}
		} else {
			// No - Route Does Not Require Auth
			// Move to Next Route
			next();
		}



    } catch (error) {
        console.log(error);
    }

});

export default router
