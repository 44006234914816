<template>
    <v-container fluid fill-height class="d-flex pa-0 ma-0 primary">
        <v-col cols="3" v-if="$vuetify.breakpoint.mdAndUp" class="primary d-flex align-center">
            <v-img contain class="mb-8" max-height="150" :src="require('@/assets/A&R_Services_FINAL_LOGO.svg')"></v-img>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="9" :class="$vuetify.breakpoint.smAndDown ? 'pa-0 white justify-start' : 'white justify-center'" class=" d-flex flex-column align-self-stretch pb-0">
            <div v-if="$vuetify.breakpoint.smAndDown" class="primary ma-0 pa-0 py-8">
                <v-img contain class="" max-height="100" :src="require('@/assets/A&R_Services_FINAL_LOGO.svg')"></v-img>
            </div>
            <v-card width="550" class="pa-8 rounded-sm elevation-0 align-self-center">
                <v-card-title class="d-flex justify-start">
                    <span class="headline py-4">Register account</span>
                </v-card-title>
                <v-card-text class="pt-6">
                    <v-form ref="form" lazy-validation>
                        <v-text-field outlined class="primary--text" v-model.trim="form.userName" label="Full Name" append-icon="oomph-verified-account" :rules="nameRules" required></v-text-field>
                        <v-text-field outlined class="primary--text" v-model="form.userEmail" label="Email" type="email" append-icon="oomph-secured-letter" :rules="emailRules" required></v-text-field>
                        <v-text-field
                            outlined
                            class="primary--text"
                            v-model="form.userTelephone"
                            @input="acceptNumber"
                            label="Telephone"
                            type="number"
                            append-icon="oomph-phone"
                            :rules="telephoneNumberRules"
                            required
                        ></v-text-field>
                        <v-text-field
                            outlined
                            class="primary--text"
                            v-model="password"
                            label="Password"
                            :append-icon="showPassword ? 'oomph-unavailable' : 'oomph-lock'"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            :rules="passwordRules"
                            autocomplete="off"
                            required
                        ></v-text-field>
                        <v-text-field
                            outlined
                            class="primary--text"
                            v-model="confirmPassword"
                            label="Confirm Password"
                            :append-icon="showConfirmPassword ? 'oomph-unavailable' : 'oomph-lock'"
                            :type="showConfirmPassword ? 'text' : 'password'"
                            @click:append="showConfirmPassword = !showConfirmPassword"
                            :rules="[password === confirmPassword || 'Password must match']"
                            autocomplete="off"
                            required
                        ></v-text-field>
                    </v-form>
                    <!-- <Alert border="left" :message="alertMessage" :value="value" :type="type" /> -->
                </v-card-text>
                <v-card-actions class="pa-4 pt-0">
                    <v-btn
                        block
                        x-large
                        class="primary white--text mb-8 elevation-0"
                        @click="register()"
                        :disabled="form.userName === '' || form.userEmail === '' || form.password === '' || confirmPassword === '' || loading"
                        :loading="loading"
                        >Register</v-btn
                    >
                </v-card-actions>
                <div class="body-1 float-right pr-4">Already have an account? <span class="font-weight-bold secondary--text" style="cursor: pointer" @click="$router.push('/')">Login</span></div>
            </v-card>
        </v-col>
        <v-col cols="12" v-if="!$vuetify.breakpoint.mobile" class="py-0 white d-flex align-self-stretch justify-end">
            <v-row >
                <v-col cols="3" class="primary"></v-col>
                <v-col cols="9" class="white d-flex justify-end py-4">
                    <span class="d-flex align-self-end">Powered by</span>
                    <v-img contain class="ml-2 mb-1 d-flex align-self-end" max-height="50" max-width="100" :src="require('@/assets/vindico.png')"></v-img>
                </v-col>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from 'firebase/auth';
import schema from "@/schema.js";

export default {
    data: () => ({
        alert: false,
        alertMessage: '',
        emailRules: [(v) => !!v || 'Email is required', (v) => /.+@.+/.test(v) || 'Email must be valid'],
		schema: null,
        
        form: {
            id: "",
            authId: '',
            userName: "",
            userAddress: {
                userAddressLine1: '',
                userAddressLine2: '',
                userAddressLine3: '',
                userTown: '',
                userCounty: '',
                userPostcode: ''
            },
            userTelephone: "",
            userEmail: "",
            userLevel: "CU",
            userType: "Cleaner",
            userRole: "User",
            userStatus: "PENDING",
            userJobTitle: "",
            privacyPolicyConsent: false,
            swappStatus: 0, //default 0
            swappMethod: null, //default null
            lastLocation: {
                locationId: '',
                locationName: ''
            },
            lastSite: {
                siteId: '',
                siteName: ''
            },
            profilePicFileURL: null, 
            trainingCurrent: [],
            createdDateTime: "",
            createdUserData: {
                userEmail: '',
                userId: '',
                userName: ''
            },
            modifiedDateTime: '',
            modifiedUserData: {
                userEmail: '',
                userId: '',
                userName: ''
            }
        },
        loading: false,
        nameRules: [(v) => !!v || 'Name is required'],
        passwordRules: [(v) => !!v || 'Password is required'],
        showPassword: false,
        showConfirmPassword: false,
        telephoneNumberRules: [(v) => !!v || 'Telephone is required', (v) => (v && v.length <= 14 && v.length > 2) || 'Telephone must be less than 14 characters and more than 2'],
        type: 'error',
        password: "",
        confirmPassword: "",
        user: {
            id: '',
            email: '',
            userName: '',
            photoURL: '',
            created: '',
            updated: '',
            deleted: '0'
        },
        value: false,
    }),
    methods: {
        acceptNumber() {
            return this.form.userTelephone.replace(/\D/g, '').match(/(\d{0,5})(\d{0,7})/);
        },
        async checkRedisUserExists(id) {
            var t = this; 

            return new Promise(async (resolve, reject) => {
                let result = await t.REDIS_exists('user', id);

                return resolve(result)

            })
        },
        async checkUserExists(email) {
            var t = this;
            
            return new Promise(async (resolve, reject) => {
                console.log('checkUserExists', email)
                t.$firebase.db.collection("users").where('userEmail', '==', email).get().then(function(querySnapshot) {
                    if (querySnapshot.docs.length > 0) {
                        var userInLocalStorage = {}
                        userInLocalStorage.true = true;
                        userInLocalStorage.id = querySnapshot.docs[0].id;
                        return resolve(userInLocalStorage);
                    } else {
                        var userInLocalStorage = {}
                        userInLocalStorage.true = false;
                        return resolve(userInLocalStorage);
                    }
                })
                .catch(function(error) {
                    console.log("Error getting documents: ", error);
                    return reject(error)
                });
            })
        },
        async register() {
            var t = this;
            if (this.$refs.form.validate()) {
                t.loading = true;
                const auth = getAuth();
                // * check if account already created for email
                var userExists = await this.checkUserExists(this.form.userEmail);

                console.log('userExists', JSON.stringify(userExists, null, 2))

                if (userExists.true === true) {
                    t.$firebase.auth.createUserWithEmailAndPassword(t.form.userEmail, t.password).then(async (user) => {
                        console.log('created auth')
                        console.log('user', JSON.stringify(user, null, 2))

                        // * update the existing account with auth fields
                        var fields = {
                            id: userExists.id,
                            authId: user.user.uid,
                            modifiedDateTime: t.$moment().format(),
                            modifiedUserData: {
                                userId: userExists.id
                            },
                            userTelephone: t.form.userTelephone,
                        }


                        t.$firebase.db.collection('users').doc(userExists.id).update(fields).then(() => {
                            t.$store.commit("SET_currentUser", user);
                        })


                        // * update redis
                        let result = await t.REDIS_read('user', userExists.id);
                        
                        var redisUser = result.data 

                        redisUser.authId = user.user.uid;
                        redisUser.userTelephone = t.form.userTelephone;
                        redisUser.modifiedDateTime = t.$moment().format();
                        redisUser.modifiedUserId = userExists.id
                        redisUser.createdUserId = userExists.id

                        redisUser.createdUserData = null

                        await t.REDIS_update('user', userExists.id, redisUser);

                        t.MIX_FIREBASE_currentUser(result.data)
                        this.$store.commit("SET_currentUser", result.data);
                        
                        // * send verification email to user
                        // t.sendVerificationEmail(auth);
                        user.user.sendEmailVerification()

                        // * redirect user to homepage
                        t.$router.push('/home');
                    })
                    .catch((err) => {
                        // ! firebase registering error
                        console.log('firebase registering error', JSON.stringify(err, null, 2))
						t.MIX_alertBox({ color: "error", timeout: 2000, message: err.message, show: true });
                        t.value = true;
                        t.loading = false;
                    });
                } else {
                    // * create user 
                    // t.$firebase.auth.createUserWithEmailAndPassword(t.form.userEmail, t.password).then(async (userCredential) => {
                    //     var userForm = await t.setForm(userCredential.user.uid)
                    //     console.log('userForm', JSON.stringify(userForm, null, 2))

                    //     // * add to the users collection
                    //     await t.MIX_createDocument('users', userForm);


					// 	t.MIX_alertBox({ color: "success", timeout: 2000, message: "Your Account was Created", show: true });

                    //     var redisUser = await t.setRedisForm(userForm)
                    //     console.log('redisUser', JSON.stringify(redisUser, null, 2))

                    //     await t.REDIS_createWithId('user', redisUser, redisUser.entityId);

                    //     // * assign this user to the current logged in user
                    //     t.MIX_FIREBASE_currentUser(redisUser)
                    //     t.$store.commit("SET_currentUser", redisUser);

                    //     // * send verification email to user
                    //     userCredential.user.sendEmailVerification()

                    //     // * redirect user to homepage
                    //     t.$router.push('/home');
                    // })
                    // .catch((err) => {
                    //     // ! firebase registering error
						t.MIX_alertBox({ color: "error", timeout: 8000, message: 'This email address is not recognised, please double check the email used or get in touch with A&R Admins.', show: true });
                    //     t.value = true;
                        t.loading = false;
                    // });
                }
            }
        },
        setRedisForm(firebaseUser) {
            var t = this;

            var user = t.redisSchema

            user.entityId = firebaseUser.id; // * assign firebase auth user id to firebase user in collection
            user.authId = firebaseUser.authId;
            user.userLevel = firebaseUser.userLevel
            user.userType = firebaseUser.userType,
            user.userEmail = firebaseUser.userEmail,
            user.userName = firebaseUser.userName,
            user.userTelephone = firebaseUser.userTelephone,
            // user.userRole = firebaseUser.userRole
            // user.userStatus = firebaseUser.userStatus
            user.createdDateTime = firebaseUser.createdDateTime;
            user.createdUserId = firebaseUser.createdUserData.userId;

            return user

        },
        sendVerificationEmail(currUser) {
            var t = this;
            console.log('currUser = ' + JSON.stringify(currUser, null, 2))
            currUser.sendEmailVerification().then(function() {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "An account verification email has been sent to you.", show: true });
            })
            .catch(function(error) {
                // ! verification email error
                // console.log("send verification email error: " + error);
				t.MIX_alertBox({ color: "error", timeout: 2000, message: error, show: true });
            });
        },
        setForm(uid) {
            var t = this; 

            var user = t.firebaseSchema

            user.id = uid; // * assign firebase auth user id to firebase user in collection
            user.authId = uid;
            user.userLevel = 'CU'
            user.userType = 'Cleaner', 
            user.userEmail = t.form.userEmail,
            user.userName = t.form.userName,
            user.userTelephone = t.form.userTelephone,
            user.userRole = 'User'
            user.userStatus = 'Pending'
            user.createdDateTime = t.$moment().format("x");
            user.createdUserData.userEmail = t.form.userEmail;
            user.createdUserData.userName = t.form.userName;
            user.createdUserData.userId = uid;

            return user
        },
        setUserDetails(uid){
            var t = this;
            console.log('down here = ' + JSON.stringify(uid, null, 2))
            t.form.id = uid; // * assign firebase auth user id to firebase user in collection
            t.form.createdDateTime = t.$moment().format("x");
            t.form.createdUserData.userEmail = t.form.userEmail;
            t.form.createdUserData.userName = t.form.userName;
            t.form.createdUserData.userId = uid;
        },
        setFirebaseUser(uid) {
            var t = this;
            t.form.id = uid;
            t.form.created = t.$moment().format('x');
        },
    },
    created() {
        var t = this;
        t.firebaseSchema = schema.firebaseUser
        t.redisSchema = schema.user
    }
};
</script>