<template>
    <v-row fluid fill-height class="d-flex flex-column align-start" style="height: 100% !important" no-gutters>
    <!-- <v-container fluid fill-height class="d-flex flex-column align-start appbackground pa-0 ma-0"> -->
		<v-col class="align-center pa-3 mt-1" cols="12">
            
            <v-row class="align-end" style="height: 85.5vh !important">
                <v-col class="fill-height pa-0 pt-3 " cols="12" style="height: 100% !important">
                    <v-card color="secondary" flat class="fill-height" >
                        <v-row class="align-start px-4 pb-0">
                            <v-col cols="8" class="pb-0">
                                <div v-if="$vuetify.breakpoint.xlOnly" class="white--text text-h5 align-start font-weight-regular">SALES MONTHLY TARGETS</div>
                                <div v-else class="white--text pt-0 text-h6 align-end font-weight-regular">SALES MONTHLY TARGETS</div>
                            </v-col>
                            <v-col cols="4" class="d-flex justify-end pb-0">
                                <v-icon v-if="$vuetify.breakpoint.xlOnly" class="white--text" size="60">icons8-data-sheet</v-icon>
                                <v-icon v-else class="white--text" size="40">icons8-data-sheet</v-icon>
                            </v-col>
                        </v-row>
                        <v-row style="height: 90% !important" class="align-start px-4 pt-0">
                            <v-col :cols="monthTargets.length > 6 ? 6 : 12" class="pt-0">
                                <v-simple-table>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td class="pl-0 secondary text-h6 font-weight-bold white--text">Month</td>
                                                <td class="secondary text-h6 font-weight-bold white--text">Target</td>
                                                <td class="secondary text-h6 font-weight-bold white--text">Won</td>
                                                <td class="secondary text-h6 font-weight-bold white--text">Lost</td>
                                                <td class="secondary text-h6 font-weight-bold white--text">Net</td>
                                            </tr>
                                            <tr v-for="month in monthTargets.slice(0,6)" :key="month.month">
                                                <td class="pl-0 secondary text-h6 font-weight-normal white--text">
                                                    {{ $moment(month.targetStartDateTime).format('MMM') }}
                                                </td>
                                                <td class="secondary text-h6 font-weight-normal white--text">
                                                    {{ month.targetValue }}
                                                </td>
                                                <td class="secondary text-h7 font-weight-normal white--text">
                                                    <!-- {{ month.monthWonItems }} -->
                                                    <div v-for="won in month.monthWonItems" :key="won.targetName">
                                                        
                                                        {{ won.targetName }} ({{ won.targetValue }})
                                                    </div>
                                                </td>
                                                <td class="secondary text-h7 font-weight-normal white--text">
                                                    <!-- {{ month.monthLossItems }} -->
                                                    <div v-for="won in month.monthLossItems" :key="won.targetName">
                                                        {{ won.targetName }} ({{ won.targetValue }})
                                                    </div>
                                                </td>
                                                <td class="secondary text-h6 font-weight-normal white--text">
                                                    {{ month.net}}
                                                </td>
                                            </tr>
                                            
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                            <v-divider v-if="monthTargets.length > 6" vertical class="white"/>
                            <v-col v-if="monthTargets.length > 6" cols="6" class="pt-0">
                                <v-simple-table >
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td class="pl-0 secondary text-h6 font-weight-bold white--text">Month</td>
                                                <td class="secondary text-h6 font-weight-bold white--text">Target</td>
                                                <td class="secondary text-h6 font-weight-bold white--text">Won</td>
                                                <td class="secondary text-h6 font-weight-bold white--text">Lost</td>
                                                <td class="secondary text-h6 font-weight-bold white--text">Net</td>
                                            </tr>
                                            <tr v-for="month in monthTargets.slice(6,12)" :key="month.month">
                                                <td class="pl-0 secondary text-h6 font-weight-normal white--text">
                                                    {{ $moment(month.targetStartDateTime).format('MMM') }}
                                                </td>
                                                <td class="secondary text-h6 font-weight-normal white--text">
                                                    {{ month.targetValue }}
                                                </td>
                                                <td class="secondary text-h7 font-weight-normal white--text">
                                                    <div v-for="won in month.monthWonItems" :key="won.targetName">
                                                        {{ won.targetName }} ({{ won.targetValue }})
                                                    </div>
                                                </td>
                                                <td class="secondary text-h7 font-weight-normal white--text">
                                                    <div v-for="won in month.monthLossItems" :key="won.targetName">
                                                        {{ won.targetName }} ({{ won.targetValue }})
                                                    </div>
                                                </td>
                                                <td class="secondary text-h6 font-weight-normal white--text">
                                                    {{ month.net}}
                                                </td>
                                            </tr>
                                            
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                                            
                        </v-row>    

                    </v-card>
                </v-col>
                
            </v-row>
		
        </v-col>

    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
    name: 'Services',
    components: {
    },
    data: () => ({
        today: '',
        todayTime: '',

        dashboardData: [],
        model: '',
        months: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
        monthTargets: [],

        interval: null,
        timeInterval : null,
    }),
    computed: {
        ...mapGetters({
            // GET_FIREBASE_userAuth: 'GET_FIREBASE_userAuth',
        }),
    },
    methods: {

        getNow: function() {
            this.today = moment().tz('Europe/London').format('ddd DD MMM YYYY')
            this.todayTime = moment().tz('Europe/London').format('HH:mm')
        },

        async getTargets() {
            var t = this;

            var yearStartDateTime = this.$moment().startOf('year').format('X')
            var yearEndDateTime = this.$moment().endOf('year').format('X')
            // console.log(yearStartDateTime + ' + ' + yearEndDateTime)

            let itemsResult = await this.REDIS_searchFor('targets', 0, 9999, 'targetStartDateTime','ASC', `@deleted:{false} @targetStartDateTime:[${yearStartDateTime} ${yearEndDateTime}]`)

            if (itemsResult.success) {
                var items = itemsResult.data.documents
                // console.log('items  = ' + JSON.stringify(items, null, 2))

                var months = []

                for (var i = 0; i < items.length; i++) {
                    var item = items[i]

                    if (item.targetMonthTarget == true) {
                        months.push(item)
                    }

                }
                // console.log('months  = ' + JSON.stringify(months, null, 2))

                months.forEach((month) => {

                    
                    console.log('month  = ' + JSON.stringify(month, null, 2))

                    var net = 0

                    var monthWonItems = items.filter(function (item) {
                        return (item.targetStartDateTime == month.targetStartDateTime) && (item.targetOutcome == 'Win')
                    })
                    // console.log('monthWonItems  = ' + JSON.stringify(monthWonItems, null, 2))
                    
                    for (var i = 0; i < monthWonItems.length; i++ ) {
                        var monthItem = monthWonItems[i]
                        net = net + monthItem.targetValue
                    }


                    var monthLossItems = items.filter(function (item) {
                        return (item.targetStartDateTime == month.targetStartDateTime) && (item.targetOutcome == 'Loss')
                    })
                    // console.log('monthLossItems  = ' + JSON.stringify(monthLossItems, null, 2))

                    for (var i = 0; i < monthLossItems.length; i++ ) {
                        var monthLossItem = monthLossItems[i]
                        net = net - monthLossItem.targetValue
                    }

                    month.monthWonItems = []
                    month.monthWonItems = monthWonItems
                    month.monthLossItems = []
                    month.monthLossItems = monthLossItems
                    month.net = net
                })

                // console.log('monthTargets = ' + JSON.stringify(months, null, 2))

                t.monthTargets = months
            }
        }


        
    },
	watch: {
	},
    mounted() {
        this.getNow()
        this.getTargets()
		
        this.timeInterval = setInterval(() => { 
            this.getNow(); 
        }, 1000)
        // this.interval = setInterval(() => { 
        //     // this.getTargets()
        // }, 5000)
    },

    beforeDestroy() {
        // clearInterval(this.interval)
        clearInterval(this.timeInterval)
    },
};
</script>

<style lang="scss" scoped>
.v-window {
    &-x-transition,
    &-x-reverse-transition,
    &-y-transition,
    &-y-reverse-transition {
        &-enter-active,
        &-leave-active {
        transition: 1.1s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
        }
    }
}

tr{
  height: 107px !important;
}
</style>