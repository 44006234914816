<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: hidden !important' : 'height: calc(100vh - 120px) !important; '">
        <v-row style="height: 68px !important" class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <v-spacer />
            </v-col>
        </v-row>
        <v-row class="apptext--text pa-5 pt-3" dense style="z-index: 0 !important">
            <TaskList :listshrunk="false" :refreshitems="refreshItems" :user="true" :userId="item.entityId"/>
        </v-row>
    </div>  
</template>

<script>
import TaskList from '@/views/hotlistTasks/hotlistTaskList.vue'

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
    },
    components: {
        'TaskList': TaskList
    },
    // * DATA
    data: () => ({

        item: {},

        refreshItems: 0,

    }),
    methods: {
        initialise() {
            this.item = { ...this.datadown };
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },

    },
    // * CREATED
    async created() {
        let t = this;

        t.initialise();

    },
    mounted() {},
};
</script>