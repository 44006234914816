<template>
    <v-row fluid fill-height class=" appbackground" style="width: calc(100vw - 56px) !important; position: fixed; height: 90vh !important; overflow-y: scroll !important" no-gutters>
    <!-- <v-container fluid fill-height class="d-flex flex-column align-start appbackground pa-0 ma-0"> -->
		<v-col class="align-center pa-3 mt-1 mb-12 pb-12" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">
            
			<div class="d-flex align-center mb-1">
				<div class="d-flex align-center">
					<div><v-icon class="primary--text" x-large>icons8-list</v-icon></div>
					<div class="text-left primary--text pl-1 text-h4 font-weight-thin">Site Tasks</div>
				</div>
				<v-spacer></v-spacer>
				<div class="ml-1">
                    <v-btn depressed block class="primary darken-2 white--text" @click="addNewTask" >
                        <v-icon>icons8-list</v-icon>
                        <span v-if="$vuetify.breakpoint.mdAndUp" class="ml-3">New</span>
                    </v-btn>
                </div>
			</div>

            <div v-if="$vuetify.breakpoint.mdAndUp" class="text-h8 pa-0 pr-2 grey--text font-weight-medium">Below are all Tasks relating to the Sites you manage </div>
            <TaskList :listshrunk="listShrunk" :managerTasks="true" :listexpanded="listExpanded" v-on:openItem="openItem" :refreshitems="refreshItems"/>
        </v-col>

		<!-- Service Component -->
		<transition v-if="activeItem" name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
			<v-col style="border-left: #239eaf solid 1px !important; height: 100% !important" v-if="!listExpanded" class="grey lighten-3 pa-0 ma-0" cols="12" :xs="itemCols.xs" :sm="itemCols.sm" :md="itemCols.md" :lg="itemCols.lg" :xl="itemCols.xl">
                <TaskTemplate v-on:refreshItems="refreshItems" :listshrunk="listShrunk"  :datadown="activeItem" v-on:closeitem="closeItem" :orgtype="orgtype"/>
			</v-col>
		</transition>

        <v-dialog v-model="taskDialog" max-width="612" :key="taskDialogKey">
            <v-card>
                <v-toolbar color="secondary" elevation="0">
                    <app-text category="text-medium" class="white--text ml-2">Create New Task</app-text>

                    <v-spacer />
                    
                    <v-btn @click="taskDialog = false" icon dark>
                        <v-icon class="icons8-multiply" color="white" />
                    </v-btn>
                </v-toolbar>
                <div class="grey lighten-4">
                    <v-form ref="form" lazy-validation>
                        <v-row dense class="pa-4">
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <v-select 
                                    hide-details="auto" 
                                    label="Task Site" 
                                    filled 
                                    v-model="task.taskSiteId"  
                                    @change="getSiteLocations()" 
                                    :rules="rules.taskSiteId"
                                    outlined 
                                    dense 
                                    clearable 
                                    :items="sites" 
                                    background-color="white" 
                                    color="primary" 
                                    :menu-props="{ top: false, offsetY: true }"  
                                    item-text="siteName" 
                                    item-value="entityId"
                                    hint="Please Select the Site this Task will be assigned to."
                                    persistent-hint
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <v-select 
                                    hide-details="auto" 
                                    label="Task Location" 
                                    filled 
                                    v-model="task.taskLocationId"  
                                    :rules="rules.taskLocationId"
                                    outlined 
                                    dense 
                                    clearable 
                                    :disabled="task.taskSiteId == null || task.taskSiteId == ''"
                                    :items="siteLocations" 
                                    background-color="white" 
                                    color="primary" 
                                    :menu-props="{ top: false, offsetY: true }"  
                                    item-text="locationName" 
                                    item-value="entityId"
                                    hint="Please Select the Location this Task will be assigned to."
                                    persistent-hint
                                >
                                </v-select>
                            </v-col>

                            <v-col cols="12" xs="12">
                                <v-textarea 
                                    label="Task" 
                                    v-model="task.taskTitle"
                                    :rules="rules.taskTitle" 
                                    required 
                                    dense 
                                    outlined
                                    hide-details="auto" 
                                    background-color="white" 
                                    color="primary"
                                    rows="5"
                                    class=""
                                    clearable 
                                    @click:clear="clearItem('taskDescription')">
                                </v-textarea> 
                            </v-col>
                        </v-row>
                    </v-form>
                    <!-- <pre>{{ task }}</pre> -->
                </div>
                <div class="d-flex grey lighten-1 pa-4">
                    <v-btn @click="taskDialog = false" class="elevation-0">Close</v-btn>
                    <v-spacer />
                    <v-btn @click="validateItem()" class="primary elevation-0">Submit</v-btn>
                </div>

            </v-card>
        </v-dialog>

    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import TaskTemplate from '@/views/taskTemplates/taskTemplate.vue';
import TaskList from '@/views/tasks/taskList.vue'
import schema from "@/schema.js"
export default {
    name: 'Services',
    components: {
        'TaskTemplate': TaskTemplate,
        'TaskList': TaskList
    },
    data: () => ({
        editMode: false,
        orgtype: "",
        orgIcon: "",
        search: '',
        show: false,
        tab: '',
        orgs: [],
        orgDialog: false,
        refreshItems: 0,
        activeItem: {},
        extendWidth: '',
        routeId: '',
		// Item Columns
		itemsCols: { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12,},
		itemCols: { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 },
		taskCols: {	cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2,},
		listExpanded: true,
		listShrunk: true,

        schema: {},
        rules: {},
        sites: {},
        siteLocations: {},
        taskDialog: false,
        taskDialogKey: 0,
        task: {}
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
    },
    methods: {
		refreshCols() {
			switch (true) {
				// Expanded List with No Task Bar
				case this.listExpanded && !this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
					this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// Expanded List with Task Bar
				case this.listExpanded && !this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
					this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				// List with Product Page (No Task Bar)
				case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// List with Product Page and Task Bar
				case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 4
					this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				// Shrunk List with Product Page (No Task Bar)
				case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }; // 2
					this.itemCols = { cols: 12, xs: 9, sm: 9, md: 9, lg: 9, xl: 9 }; // 10
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// Shrunk List with Product Page and Task Bar)
				case !this.listExpanded && this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				default:
					this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 12
					this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
			}
		},
		openItem(item) {
			this.activeItem = item;
			this.listExpanded = false;
		},
		closeItem() {
			this.listExpanded = true;
			this.listShrunk = false;
		},
        addNewTask() {
            var t = this;

            t.taskDialog = true;
            t.taskDialogKey++
        },
        async initialiseSchema() {
            var t = this; 
            t.task = {...t.schema}
        },
        async getSiteLocations() {
            var t = this;
            t.siteLocations = [];
            
            let locations = await t.REDIS_searchFor('location', '0', '9999', 'locationName', 'ASC', `@deleted:{false} @locationSiteId:{${t.task.taskSiteId}}`);
            if (locations.success) { t.siteLocations = locations.data.documents; }

            t.siteLocations = t.siteLocations.map((x) => {
                return {
                    entityId: x.entityId,
                    locationName: x.locationName,
                };
            })
        },
        validateItem() {
            var t = this; 

            t.rules = {
                taskTitle: [
                    v => !!v || 'Task Title is required'
                ],
                taskLocationId: [
                    v => !!v || 'Task Location is required',
                ],
                taskSiteId: [
                    v => !!v || 'Task Site is required',
                ],
            }
            this.$nextTick(() => {
                if (this.$refs.form.validate()) {
                    this.saveItem()
                } else {
                    this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Please fill in all required fields', show: true })
                }
            })
        },
        // * CREATE ITEM
        async saveItem() {
            var t = this;

            var dateTimeNow = t.$moment().toISOString();

            var item = {...t.task}

            var siteItem = await this.REDIS_read('site', item.taskSiteId);
            if (siteItem.success) {
                var siteManager = siteItem.data.siteManagerUserId;
                item.taskManagerId = siteManager;
                item.taskOrgId = siteItem.data.siteClientId;
            }


            item.createdDateTime = dateTimeNow;
            item.createdUserId = t.GET_FIREBASE_currentUser.entityId;
            item.modifiedDateTime = dateTimeNow;
            item.modifiedUserId = t.GET_FIREBASE_currentUser.entityId;

            console.log("item = ", JSON.stringify(item, null, 2));
            let result = await this.REDIS_create('task', item);

            console.log("result = ", JSON.stringify(result, null, 2))
            
			if (result.success) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                t.taskDialog = false
                t.initialiseSchema()
                // t.getItems()
                // t.openTicket(result.data.entityId)
                t.refreshItems++
			}

        },
    },
	watch: {
		listExpanded() { this.refreshCols() },
		listShrunk() { this.refreshCols() },
		// GET_taskBar() { this.refreshCols() }
	},
    async mounted() {
        var t = this;
		// Dynamic Route for New or Existing Item
		this.routeId = this.$route.params.id;
		if (this.routeId === "new") {
			this.openItem({})
		} else if (this.routeId !== "new" && this.routeId !== "" && this.routeId !== undefined) {
			this.openItem({ id: this.routeId })
		} 

        let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
        if (sites.success) { t.sites = sites.data.documents; }

        t.sites = t.sites.map((x) => {
            return {
                entityId: x.entityId,
                siteName: x.siteName,
                siteClientId: x.siteClientId
            };
        })
        t.schema = schema.task;
        console.log("schema = ", t.schema)
        t.initialiseSchema()
    }
};
</script>