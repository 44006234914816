<template>
    <div class="" style="width: 100% !important">
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field
                    label="Search Communications "
                    v-model="searchQuery"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    color="primary"
                    clearable
                    prepend-inner-icon="icons8-search"
                    @click:clear="clearSearchQuery()"
                ></v-text-field>
            </div>
            <v-btn v-if="$vuetify.breakpoint.lgAndUp" depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="refreshItems">
                <v-icon>icons8-available-updates</v-icon>
            </v-btn>
        </div>
        <div style="height: 65vh !important; overflow-y: scroll !important" class="">
            <v-row class="pa-3 pl-0  mt-1">
                <v-col class="pa-0 ma-0" cols="12">
                    <v-timeline
                        align-top
                        dense
                        v-for="(day, i) in computedDays"
                        :key="i"
                        class="ml-0 pl-0"
                    >
                        <v-timeline-item
                            hide-dot
                            class="pb-4 mb-0 pl-0"
                            v-if="JSON.stringify(computedDayItems(day)) !== '[]'"
                        >
                            <v-row class="font-weight-bold text-h6 grey--text">{{ day }}</v-row>
                        </v-timeline-item>

                        <v-timeline-item
                            v-for="(item, index) in computedDayItems(day)"
                            :key="index"
                            :color="item.color"
                            class="pl-0 ml-0"
                            small
                            fill-dot
                            >
                            <v-row class="pt-1 pr-3 d-flex flex-column">
                                <div class="font-weight-bold grey--text body-2">{{ $moment(item.createdDateTime).format('HH:mm') }}</div>
                                <div><communicationCard :item="item" /></div>
                                <!-- <pre>{{ item }}</pre> -->
                            </v-row>
                        </v-timeline-item>
                    </v-timeline>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import communicationCard from '@/views/communications/communicationCard.vue'
export default {
    components: {
        'communicationCard': communicationCard
    },
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        site: {
            type: Object,
            default: () => ({}),
        },
        refreshitems: {
            type: Number,
            default: 0,
        }
    },
    data: () => ({
        type: '',
        status: '',
        key: 'communication',
        service: {},
        toggleDeletedItems: false,
        showImages: false,
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,

        sortBy: '@communicationTitle',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['communicationTitle'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        items: [],
        computedItems: [],
        clients: [],
        users: [],
        loading: false
    }),
    computed: {
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                searchQuery = '@deleted:{false}';
            } else {
                searchQuery = `${searchQuery}*  @deleted:{false}`;
            }

            searchQuery = `${searchQuery} @communicationSiteId:{${this.site.entityId}}`;
            
            return searchQuery;
        },
        // computedItems() {
        //     var items = this.items;

        //     items.forEach((item) => {
        //         item.communicationClientName = this.getClient(item.communicationClientId)
        //         item.communicationCreatedUserName = this.getUser(item.createdUserId)
        //         item.communicationCompletedUserName = this.getUser(item.communicationCompletedUserId)
        //         item.communicationReadUserName = this.getUser(item.communicationReadUserId)

        //         if (item.communicationType == 'client') {
        //             item.color = 'primary'
        //         } else {
        //             item.color = 'secondary'
        //         }
        //     })
        //     return items;
        // },
        computedDays() {
            var t = this;

            const days = []

            console.log('items', JSON.stringify(this.items. null, 2))

            this.items = this.items.sort((a, b) => {
                return new Date(b.createdDateTime) - new Date(a.createdDateTime);
            });

            this.items.filter((item) => {
                var day = []
                day = t.$moment(item.createdDateTime).format('ddd DD MMM, YYYY')
                if (day !== 'Invalid date') {
                    return days.push(day)
                }
            })

            let filteredDays = [...new Set(days)]

            return filteredDays;
        }
    },
    methods: {
        computedDayItems(day) {
            var t = this; 

            var items = this.items;

            items = items.filter((item) => {
                var itemDay = t.$moment(item.createdDateTime).format('ddd DD MMM, YYYY')
                if (itemDay == day) {
                    return item
                }
            })

            items.forEach((item) => {
                item.communicationClientName = this.getClient(item.communicationClientId)
                item.communicationCreatedUserName = this.getUser(item.createdUserId)
                item.communicationCompletedUserName = this.getUser(item.communicationCompletedUserId)
                item.communicationReadUserName = this.getUser(item.communicationReadUserId)

                if (item.communicationType == 'client') {
                    item.color = 'primary'
                } else {
                    item.color = 'secondary'
                }
            })


            return items
        },
        async computeItems() {
            var t = this;
            var items = this.items;

            for (let i=0; i++; i<items.length) {
                var item = items[i]
                item.communicationClientName = await this.getClient(item.communicationClientId)
                item.communicationCreatedUserName = await this.getUser(item.createdUserId)
                item.communicationCompletedUserName = await this.getUser(item.communicationCompletedUserId)
                item.communicationReadUserName = await this.getUser(item.communicationReadUserId)

                if (item.communicationType == 'client') {
                    item.color = 'primary'
                } else {
                    item.color = 'secondary'
                }
            }
            t.computedItems = items;
        },
        getClient(clientId) {
            var t = this;
            var client = []
            var clientName = ''

            client = t.clients.filter((item) => { return item.entityId == clientId })

            if (client.length !== 0) { clientName = client[0].clientName }
            return clientName
        },
        getUser(userId) {
            var t = this;
            var user = []
            var userName = ''

            user = t.users.filter((item) => { return item.entityId == userId })

            if (user.length !== 0) { userName = user[0].userName }

            return userName
        },
        async loadMore() {
            this.itemsFrom = this.itemsFrom + this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo + this.datatableOptions.itemsPerPage;
            this.getItems();
        },
        async goBack() {
            this.itemsFrom = this.itemsFrom - this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo - this.datatableOptions.itemsPerPage;
            this.getItems()
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        refreshItems: function () {
            this.getItems();
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        // * GET ITEMS
        async getItems() {
            var t = this
            t.loading = true
            let itemsResult = await this.REDIS_searchFor('communication', this.itemsFrom, this.datatableOptions.itemsPerPage, 'communicationTitle','ASC', this.computedSearchQuery )
            console.log('itemsResult: ' + JSON.stringify(itemsResult));
            if (itemsResult.success) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.documents
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                t.loading = false
            }
        },
    },
    watch: {
        client: {
            handler: function () {
                this.getItems()
            },
            deep: true,
        },
        refreshitems() {
            this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        computedFields() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        var t = this;

        let users = await t.REDIS_searchFor('user', '0', '999', 'userStartDate', 'ASC', '@deleted:{false}');
        if (users.success) { t.users = users.data.documents; }

        t.users = t.users.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
            };
        })


        let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false} -@clientStatus:{ARCHIVE}');
        if (clients.success) { t.clients = clients.data.documents; }

        t.clients = t.clients.map((x) => {
            return {
                entityId: x.entityId,
                clientName: x.clientName,
            };
        })
        t.getItems()
        // t.computeItems()
    },
};
</script>