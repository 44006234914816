var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100% !important"}},[_c('div',{staticClass:"mt-2 d-flex align-start"},[_c('div',{staticClass:"flex-grow-1"},[_c('v-text-field',{attrs:{"label":"Search Location History","outlined":"","dense":"","hide-details":"auto","background-color":"white","color":"primary","clearable":"","prepend-inner-icon":"icons8-search"},on:{"click:clear":function($event){return _vm.clearSearchQuery()}},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-btn',{staticClass:"ml-2 white grey--text text--darken-2",staticStyle:{"border":"1px solid grey !important","height":"40px !important"},attrs:{"depressed":""},on:{"click":_vm.refreshItems}},[_c('v-icon',[_vm._v("icons8-available-updates")])],1)],1),_c('v-row',{staticClass:"pa-3 mt-1"},[_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"item-class":_vm.itemRowBackground,"sortable":"","dense":"","headers":_vm.computedHeaders,"items":_vm.items,"server-items-length":_vm.itemsTotal,"options":_vm.datatableOptions,"footer-props":{
                    'items-per-page-options': [10, 20, 30, 40, 50]
                }},on:{"update:options":function($event){_vm.datatableOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"100% !important"}},[_c('div',{staticClass:"py-5 pl-3 body-2"},[_vm._v(" Showing "),_c('strong',[_vm._v(_vm._s(_vm.itemsFrom + 1))]),(_vm.itemsTo !== _vm.itemsTotal)?_c('span',[_vm._v(" to "),_c('strong',[_vm._v(_vm._s(_vm.itemsTo))])]):_vm._e(),_vm._v(" of "),_c('strong',[_vm._v(_vm._s(_vm.itemsTotal))])]),_c('v-spacer'),_c('v-spacer'),_c('v-btn-toggle',{staticClass:"ma-2",attrs:{"multiple":""},model:{value:(_vm.toggleFeatures),callback:function ($$v) {_vm.toggleFeatures=$$v},expression:"toggleFeatures"}})],1)]},proxy:true},{key:"item.swappLocationId",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getLocation(item.swappLocationId)))])]}},{key:"item.swappSiteId",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getSite(item.swappSiteId)))])]}},{key:"item.createdDateTime",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.createdDateTime).format("DD-MMM-YY HH:mm")))])]}},{key:"item.swappMethod",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.MIX_capitalizeText(item.swappMethod)))])]}},{key:"item.swappStatus",fn:function(ref){
                var item = ref.item;
return [(item.swappStatus === '1')?_c('v-chip',{staticClass:"success",attrs:{"small":""}},[_vm._v("IN")]):_c('v-chip',{staticClass:"lightgrey",attrs:{"small":""}},[_vm._v("OUT")])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [(item.deleted === '1' && _vm.toggleFeatures.includes('destroy'))?_c('v-icon',{staticClass:"error--text pa-1",on:{"click":function($event){return _vm.destroyItem(item.id)}}},[_vm._v("icons8-delete-trash")]):_vm._e(),(item.deleted === false && _vm.toggleFeatures.includes('delete'))?_c('v-icon',{staticClass:"grey--text text--darken-2 ml-5",on:{"click":function($event){return _vm.deleteItem(item.entityId)}}},[_vm._v("icons8-trash-can")]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }