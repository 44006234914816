<template>
	<v-card flat color="white" class="mb-6">
        <v-col cols="12" class="align-start pa-0">
		    <v-row class="px-4 py-2 pl-5">
		    	<v-col cols="7" class="d-flex ma-0 pa-0 pl-1 align-start">
                    <div class="font-weight-regular text-start primary--text">
                        {{ job.jobClientName }}
                    </div>
                </v-col>
                <v-col cols="5" class="d-flex align-start justify-end pr-1 ma-0 pa-0">
                    <div class="font-weight-regular body-1 grey--text text-right">
                        <v-icon size="18" class="pb-1">icons8-clock</v-icon> 
		    			{{ $moment(job.jobStartDateTime).format('HH:mm') }} - {{ $moment(job.jobEndDateTime).format('HH:mm') }}
                    </div>
                </v-col>
                
                <v-col cols="12" class="d-flex align-end pa-0 px-1">
                    <div>
		        	    <div class="caption text-start grey--text">{{ job.jobSiteName }}</div> 
		        	    <div class="caption text-start grey--text">{{ job.jobServiceName }}</div> 
                    </div>
                    <v-spacer />
                    <div>
                        <!-- <pre>{{ job.jobSiteId }}</pre>
                        <pre>{{ GET_FIREBASE_currentUser.lastSiteId }}</pre> -->
                        <div v-if="job.swappStatus !== 'notStarted' && job.swappStatus !== null">
                            <div class="caption text-end pt-1 grey--text">Started: {{ $moment(job.swappStartTime).format('HH:mm') }}</div> 
                            <div v-if="job.swappStatus === 'finished'" class="caption text-end grey--text">Finished: {{ $moment(job.swappEndTime).format('HH:mm') }}</div> 
                        </div>
                        <div class="align-end">
                            <v-btn v-if="reveal === false && job.swappStatus === 'notStarted' || job.swappStatus == null && reveal == false" :disabled="job.jobSiteId !== GET_FIREBASE_currentUser.lastSiteId" class="elevation-0 primary" width="120" @click="openExtension('start', job.jobStartDateTime, job.jobEndDateTime)">Start Job</v-btn>
                            <v-btn v-else-if="reveal === false && job.swappStatus === 'started'" class="elevation-0 secondary" width="120" @click="openExtension('end', job.jobStartDateTime, job.jobEndDateTime)">End Job</v-btn>
                            <v-btn v-if="reveal === false && job.swappStatus === 'started'" class="elevation-0 primary ml-1" width="120" @click="addNotes(job)">Add Notes</v-btn>
                        </div>
                    </div>
		    	</v-col>
		    </v-row>
            <!-- <pre>{{ job }}</pre> -->
            <v-card v-if="reveal === true" class="swappExpansion mt-3 grey lighten-4 elevation-0" style="height: 100% !important; width: 100% !important">
                <v-row v-if="job.swappStatus === 'notStarted' || job.swappStatus == null">
                    <v-col v-if="isWithinStartTime === false" cols="12" class="ma-0 d-flex align-center px-7 pt-2">
                        <div v-if="startInPast === true" class="body-2 font-weight-bold grey--text">The Start time for this Job has elapsed, do you still want to start this Job?</div>
                        <div v-else-if="startInFuture === true" class="body-2  font-weight-bold grey--text">This Job is not meant to start until {{ job.startTime }}, do you still want to start this Job?</div>
                    </v-col>
                    <v-col v-else-if="isWithinStartTime === true" cols="12" class="ma-0 d-flex align-center px-5 pt-2">
                        <div class="body-2 darkgrey--text">Do you want to start this Job?</div>
                    </v-col>
                    <v-col cols="12" class="px-5 pt-0 pb-2">
                        <div class="d-flex">
                            <v-btn class="elevation-0 grey white--text" width="120" @click="reveal = false">Cancel</v-btn>
                            <v-spacer />
                            <v-btn class="elevation-0 primary" width="120" @click="startJob(job)">Start Job</v-btn>
                        </div>
                    </v-col>
                </v-row>
                <v-row v-else-if="job.swappStatus === 'started'">
                    <v-col v-if="isWithinEndTime === false" cols="12" class="ma-0 d-flex align-center px-7 pt-2">
                        <div v-if="endInPast === true" class="body-2  font-weight-bold grey--text"><span class="error--text">Warning: </span>The End time for this Job has elapsed.</div>


                        <div v-if="endInFuture === true" class="body-2  font-weight-bold grey--text">This Job is not meant to end until {{ job.endTime }}, do you still want to end this Job?</div>
                    </v-col>


                    <v-col v-else-if="isWithinEndTime === true" cols="12" class="ma-0 d-flex align-center px-6 pt-2">
                        <div class="body-2  font-weight-bold grey--text">Do you want to end this Job?</div>
                    </v-col>

                    <v-col cols="12" class="px-6 pt-0 pb-2">
                        <div class="d-flex">
                            <v-btn class="elevation-0 grey white--text" width="120" @click="reveal = false">Cancel</v-btn>
                            <v-spacer />
                            <v-btn class="elevation-0 secondary" width="120" @click="endJob(job)">End Job</v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>    
	</v-card>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'; // Map the Getters from the Vuex Store
    import moment from 'moment'
	export default {
        data: () => ({
            reveal: false,
            publishedDialog: false,
            
            //* CHECKING JOB START TIME
            isWithinStartTime: false,
            startInPast: false,
            startInFuture: false,
            
            //* CHECKING JOB END TIME
            isWithinEndTime: false,
            endInPast: false,
            endInFuture: false,
            
            jobSwappStatus: {
                status: '',
                startStatus: '',
                swappStartTime: '',
                swappEndTime: '',
            }
        }),
		props: {
            job: { type: Object, default: '-'},
            swappLocationId: { type: String, default: '-'},
		},
        computed: {
            ...mapGetters({
                GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
            }),
            returnPublishMethod(){
                if(this.job.publishedStatus === 'UNPUBLISHED'){
                    return 'Publish';
                } else {
                    return 'Unpublish';
                }
            },
        },
        methods: {
            ...mapActions({'ACT_openItem': 'ACT_openJob'}),
            async addNotes() {
                var tasks = await this.checkCompletedCommunications()

                this.$emit('addNotes', tasks)
            },
            openExtension(status, startTime, endTime) {
                var t = this;

                console.log('opening extension')
                console.log('status', status)
                console.log('startTime', startTime)
                console.log('endTime', endTime)

                if (status === 'start') {
                    t.isStartTime(startTime)
                } else if (status === 'end') {
                    t.isEndTime(endTime)
                }
                t.reveal = true
            },

            //* CHECKS IF WITHIN 30 MINS OF START TIME
            isStartTime(startTime) {
                var t = this;
                var startTime = moment(startTime).format('X')
                console.log('startTime', startTime)
                
                var minusThirty = moment().subtract(30, 'minutes').format('X')
                console.log('minusThirty', minusThirty)
                var plusThirty = moment().add(30, 'minutes').format('X')
                console.log('plusThirty', plusThirty)

                if ((startTime >= minusThirty) && (startTime <= plusThirty )) {
                    t.isWithinStartTime = true
                } else {
                    t.isWithinStartTime = false
                    if (startTime < minusThirty) {
                        t.startInPast = true
                    } else if (startTime > plusThirty) {
                        t.startInFuture = true
                    }
                }

                console.log('isWithinStartTime', t.isWithinStartTime)
                console.log('startInPast', t.startInPast)


            },

            //* CHECKS IF WITHIN 30 MINS OF END TIME
            isEndTime(endTime) {
                var t = this;
                console.log('endTime', endTime)
                var endTime = moment(endTime).format('X')

                var minusThirty = moment().subtract(15, 'minutes').format('X')
                var plusThirty = moment().add(15, 'minutes').format('X')

                if ((endTime >= minusThirty) && (endTime <= plusThirty)) {
                    t.isWithinEndTime = true
                } else {
                    t.isWithinEndTime = false
                    if (endTime < minusThirty) {
                        t.endInPast = true
                    } else if ( endTime > plusThirty) {
                        t.endInFuture = true
                    }
                }
            },

            async checkReadCommunications() {
                var t = this; 
                var siteId = t.job.jobSiteId
                var locationId = t.swappLocationId
                var tasks = {
                    readyToStart: false,
                    data: []
                }

                let itemsResult = await this.REDIS_searchFor('task', 0, 9999, 'taskTitle','ASC', `@deleted:{false} @taskCompleted:{false} @taskSiteId:{${siteId}} @taskLocationId:{${locationId}}` )
                console.log('itemsResult: ' + JSON.stringify(itemsResult));
                if (itemsResult.success && itemsResult.data.total > 0) {

                    tasks.readyToStart = false
                    tasks.data = itemsResult.data.documents
                    return tasks
                } else {
                    tasks.readyToStart = true
                    return tasks
                }
            },

            async checkCompletedCommunications() {
                var t = this; 
                var siteId = t.job.jobSiteId
                var locationId = t.swappLocationId
                var tasks = {
                    readyToEnd: false,
                    data: []
                }

                let itemsResult = await this.REDIS_searchFor('task', 0, 9999, 'taskTitle','ASC', `@deleted:{false} @taskCompleted:{false} @taskRead:{true} @taskSiteId:{${siteId}} @taskLocationId:{${locationId}}` )
                console.log('itemsResult: ' + JSON.stringify(itemsResult));
                if (itemsResult.success && itemsResult.data.total > 0) {

                    tasks.readyToEnd = false
                    tasks.data = itemsResult.data.documents
                    return tasks
                } else {
                    tasks.readyToEnd = true
                    return tasks
                }
            },

            //* STARTS JOB - UPDATES JOB STATUS
            async startJob(job) {
                var t = this;
                var job = job;
                console.log('job', job)

                var tasks = await t.checkReadCommunications()

                console.log('tasks', JSON.stringify(tasks, null, 2))

                if (tasks.readyToStart) {

                    job.swappStatus = 'started';
                    job.swappStartTime = moment().toISOString();

                    job.modifiedUserId = t.GET_FIREBASE_currentUser.entityId
                    var dateTimeNow = this.$moment().toISOString()
                    job.modifiedDateTime = dateTimeNow;
                    console.log('job', JSON.stringify(job, null, 2))
                    let result = await this.REDIS_update('jobs', job.entityId, job);
                    
                    if (result.success) {
                        t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                    } else {
                        t.MIX_alertBox({ color: "error", timeout: 2000, message: "Error Saving Item", show: true });
                    }
                    t.reveal = false
                } else {
                    this.$emit('openCommunications', 'start', tasks.data, job);
                }

            },

            //* ENDS JOB - UPDATES JOB STATUS
            async endJob(job) {
                var t = this;
                var job = job

                var tasks = await t.checkCompletedCommunications()

                console.log('tasks', JSON.stringify(tasks, null, 2))

                if (tasks.readyToEnd) {

                    job.swappStatus = 'finished';
                    job.swappEndTime = moment().toISOString();

                    if (t.isWithinEndTime === false) {
                        if (t.endInPast === true) {
                            console.log("late")
                            // t.sendLateNotification()
                        } else if (t.endInFuture === true) {
                            console.log("early")
                            // t.sendEarlyNotification()
                        }
                        //! NEED TO SEND EARLY/LATE SWAPP OUT NOTIFICATION
                    }


                    
                    var jobStart = job.swappStartTime
                    var endTime = job.swappEndTime

                    var jobDuration = endTime - jobStart

                    var jobDiff = moment(endTime, 'X').diff(moment(jobStart, 'X'))
                    var actualJobDuration = moment.duration(jobDiff);
                    actualJobDuration = Math.floor(actualJobDuration.asHours()) + moment.utc(jobDiff).format(":mm")
                    
                    jobDuration = moment(jobDuration, 'X').format('HH:mm')


                    // CHECKING IF JOB DURATION IS AS EXPECTED DURATION
                    var minusThirtyDuration = moment(t.duration, 'HH:mm').subtract(15, 'minutes').format('X')
                    var plusThirtyDuration = moment(t.duration, 'HH:mm').add(15, 'minutes').format('X')


                    if (actualJobDuration < minusThirtyDuration) {
                        //! run too little notif
                        console.log("too quick")
                        // t.sendDurationNotification()
                    } else if (actualJobDuration > plusThirtyDuration) {
                        //! run too much notif
                        console.log("too slow")
                        // t.sendDurationNotification()
                    }
                    job.modifiedUserId = t.GET_FIREBASE_currentUser.entityId
                    var dateTimeNow = this.$moment().toISOString()
                    job.modifiedDateTime = dateTimeNow;
                    console.log('job', JSON.stringify(job, null, 2))
                    let result = await this.REDIS_update('jobs', job.entityId, job);
                    
                    if (result.success) {
                        t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                    } else {
                        t.MIX_alertBox({ color: "error", timeout: 2000, message: "Error Saving Item", show: true });
                    }
                    t.reveal = false
                }
                else {
                    this.$emit('openCommunications', 'end', tasks.data, job);
                }
            },
            async sendDurationNotification() {
                var id = this.id
                let t = this;
                let dynamicData = {};
                let jobId = id;
                let job = {}
                let site = {}
                
                var itemResult = await t.MIX_readDocumentById('jobs', jobId)
                job = itemResult.data;
                var siteDetails = await t.MIX_readDocumentById('sites', job.siteData.id)
                site = siteDetails.data;

                var jobStart = t.swappStartTime
                var endTime = t.jobSwappStatus.swappEndTime

                var jobDiff = moment(endTime, 'X').diff(moment(jobStart, 'X'))
                var actualJobDuration = moment.duration(jobDiff);
                actualJobDuration = Math.floor(actualJobDuration.asHours()) + moment.utc(jobDiff).format(":mm")
                
                dynamicData.recipientName = site.siteCommercialManager.userName
                dynamicData.siteName = job.siteData.siteName
                dynamicData.userEmail = site.siteCommercialManager.userEmail
                dynamicData.allocatedDurationHours = moment(t.duration, 'HH:mm').format('HH')
                dynamicData.allocatedDurationMins = moment(t.duration, 'HH:mm').format('mm')
                dynamicData.completedDurationHours = moment(actualJobDuration, 'HH:mm').format('HH')
                dynamicData.completedDurationMins = moment(actualJobDuration, 'HH:mm').format('mm')
                dynamicData.notificationType = 'Duration Notification'
                dynamicData.jobId = jobId
                dynamicData.templateId ='d-86acf4fd22424fbb8a998273c7260fcd'

                // console.log("dynamicData", JSON.stringify(dynamicData, null, 2))
                // console.log("duration = " + JSON.stringify(t.duration, null, 2))

                var createDocumentResult = await t.MIX_createDocument('notifications', dynamicData); // Create Document Within Firestore based on Form
                if (createDocumentResult.code === 1) {
                }

            },

            async sendLateNotification() {
                let t = this;
                let dynamicData = {};

                let jobId = this.id;
                let job = {}
                let site = {}
                
				var itemResult = await t.MIX_readDocumentById('jobs', jobId)
				job = itemResult.data;

                var siteDetails = await t.MIX_readDocumentById('sites', job.siteData.id)
                site = siteDetails.data;
                
                dynamicData.recipientName = site.siteCommercialManager.userName
                dynamicData.siteName = job.siteData.siteName
                dynamicData.notificationType = 'SWAPP Out Notification'
                dynamicData.userEmail = site.siteCommercialManager.userEmail
                dynamicData.allocatedEndTime = job.frequency.endTime
                dynamicData.endTime = moment(t.jobSwappStatus.swappEndTime, 'X').format('HH:mm')
                dynamicData.jobId = jobId
                dynamicData.templateId ='d-f955fd67a2b642eda73c4962ba17ee1d'
        
                var createDocumentResult = await t.MIX_createDocument('notifications', dynamicData); // Create Document Within Firestore based on Form
                if (createDocumentResult.code === 1) {
                }

            },

        }
	};
</script>

<style scoped>
.v-sheet.v-card.swappExpansion {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
</style>
