const mixin = {
    data: () => ({
        apiUrl: process.env.VUE_APP_API_HOST,
        dashboardUrl: process.env.VUE_APP_DASHBOARD_HOST,
        bulkUrl: process.env.VUE_APP_BULK_HOST,
    }),
    computed: {

    },
    methods: {
        // CREATE JSON RECORD IN REDIS
        REDIS_create(collection, data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/`;
                        // console.log('url = ' + JSON.stringify(url, null, 2))
                        let result = await this.$axios.post(url, data)
                        // console.log('result = ' + JSON.stringify(result, null, 2))
                        if (result.status === 201) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // CREATE JSON RECORD IN REDIS
        REDIS_createWithId(collection, data, id) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/createwithid/${id}`;
                        // console.log('url = ' + JSON.stringify(url, null, 2))
                        let result = await this.$axios.post(url, data)
                        // console.log('result = ' + JSON.stringify(result, null, 2))
                        if (result.status === 201) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // READ JSON RECORD FROM REDIS
        REDIS_read(collection, entityId) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/${entityId}`;
                        // console.log('url = ' + JSON.stringify(url, null, 2))

                        let result = await this.$axios.get(url)
                        // console.log('result = ' + JSON.stringify(result, null, 2))

                        if (result.status === 200) {
                            resolve(result.data);
                        } else if (result.status === 204) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // READ JSON RECORD FROM REDIS
        REDIS_exists(collection, entityId) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/exists/${entityId}`;
                        // console.log('url = ' + JSON.stringify(url, null, 2))

                        let result = await this.$axios.get(url)
                        // console.log('result = ' + JSON.stringify(result, null, 2))
                        
                        if (result.status === 200) {
                            resolve(result.data);
                        } else if (result.status === 204) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // UPDATE JSON RECORD IN REDIS
        REDIS_update(collection, entityId, data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/${entityId}`;
                        let result = await this.$axios.put(url, data)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // DELETE JSON RECORD IN REDIS BY MARKING AS DELETED
        REDIS_delete(collection, entityId, data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/delete/${entityId}`;
                        let result = await this.$axios.put(url, data)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // RESTORE JSON RECORD IN REDIS BY REMOVING MARK AS DELETED
        REDIS_restore(collection, entityId, data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/${entityId}`;
                        let result = await this.$axios.put(url, data)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // DESTROY JSON RECORD IN REDIS BY REMOVING FROM REDIS
        REDIS_destroy(collection, entityId) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/${entityId}`;
                        let result = await this.$axios.delete(url)
                        if (result.status === 202) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            })
        },
        // EXPIRE JSON RECORD IN REDIS BY SETTING TTL
        REDIS_expire(collection, entityId, expirySeconds) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/expire/${entityId}?seconds=${expirySeconds}`;
                        let result = await this.$axios.get(url)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // RETURN ALL JSON RECORDS IN REDIS
        REDIS_searchAll(collection, offset, count, sortBy, sortOrder) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/search/all?offset=${offset}&count=${count}&sortby=${sortBy}&sortorder=${sortOrder}`;
                        let result = await this.$axios.get(url)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },

        // RETURN ALL JSON RECORDS IN REDIS THAT MATCH QUERY
        REDIS_searchFor(collection, offset, count, sortBy, sortOrder, searchQuery) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        // console.log( { collection, offset, count, sortBy, sortOrder, searchQuery } );
                        const url = `${this.apiUrl}${collection}/search/for?offset=${offset}&count=${count}&sortby=${sortBy}&sortorder=${sortOrder}&query=${searchQuery}`;
                        // console.log(url)
                        let result = await this.$axios.get(url);
                        
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // RETURN ALL JSON RECORDS IN REDIS THAT MATCH QUERY
        REDIS_count(collection, searchQuery) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        // console.log( { collection, offset, count, sortBy, sortOrder, searchQuery } );
                        const url = `${this.apiUrl}${collection}/count/for?query=${searchQuery}`;
                        // console.log(url)
                        let result = await this.$axios.get(url);
                        
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // * RECURRING FUNCTIONS
        // CREATE JSON RECORD IN REDIS
        REDIS_createRecurring(collection, data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.bulkUrl}${collection}/`;
                        // console.log('url = ' + JSON.stringify(url, null, 2))
                        let result = await this.$axios.post(url, data)
                        // console.log('result = ' + JSON.stringify(result, null, 2))
                        if (result.status === 201) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        REDIS_updateRecurring(collection, entityId, data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.bulkUrl}${collection}/${entityId}`;
                        let result = await this.$axios.put(url, data)
                        // console.log('result = ' + JSON.stringify(result, null, 2))

                        if (result.status === 201) {
                            resolve(result.data);
                        } else if (result.status === 204) {
                            resolve(result);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },

        //* BULK FUNCTIONS

        REDIS_applyInBulk(data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try { 
                        const url = `${this.bulkUrl}jobs/apply-in-bulk`
                        let result = await this.$axios.post(url, data)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error)
                        }
                    } catch (error) {
                        reject(error)
                    }
                })()
            })
        },

        // * REPORTS
        // RETURN ALL CLIENT HOURS - COUNTED
        REDIS_getClientHours(date1, date2) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.bulkUrl}client/client-hours?date1=${date1}&date2=${date2}`;
                        let result = await this.$axios.get(url);
                        
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        REDIS_getLeaveReport(date1, date2) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.bulkUrl}leave/leave-report?date1=${date1}&date2=${date2}`;
                        let result = await this.$axios.get(url);
                        
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },

        REDIS_getAbsenceReport(date1, date2) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.bulkUrl}absence/absence-report?date1=${date1}&date2=${date2}`;
                        let result = await this.$axios.get(url);
                        
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },

        REDIS_getUserTraining(date1, date2) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.bulkUrl}user/training-report?date1=${date1}&date2=${date2}`;
                        let result = await this.$axios.get(url);
                        
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });

        },
        // RETURN ALL SITE HOURS - COUNTED
        REDIS_getSiteHours(date1, date2) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.bulkUrl}site/site-hours?date1=${date1}&date2=${date2}`;
                        let result = await this.$axios.get(url);
                        
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // RETURN ALL SITE HOURS - COUNTED
        REDIS_getUserHours(date1, date2) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.bulkUrl}user/user-hours?date1=${date1}&date2=${date2}`;
                        let result = await this.$axios.get(url);
                        
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        //* EMAILS
        // START BACKGROUND TASK FOR FULL REPORT
        REDIS_getFullReport(data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.bulkUrl}jobs/full-report`;
                        // console.log(url)
                        // console.log(data)
                        let result = await this.$axios.post(url, data)
                        // console.log(result)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // CHECK STATUS OF FULL REPORT BACKGROUND TASK
        REDIS_getFullReportStatus(entityId) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.bulkUrl}jobs/full-report-status/${entityId}`;
                        // console.log('url = ' + JSON.stringify(url, null, 2))
                        let result = await this.$axios.get(url)
                        // console.log('result = ' + JSON.stringify(result, null, 2))
                        if (result.status === 200) {
                            resolve(result.data);
                        } else if (result.status === 204) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },

        // NOTIFICATIONS
        REDIS_sendInviteLink(data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}user/invite-link?emailedUserId=${data}`;
                        // console.log(url)
                        // console.log(data)
                        let result = await this.$axios.get(url)
                        // console.log(result)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        REDIS_sendHotlistTaskNotification(data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}hotlist-task/send-allocation-email?taskId=${data.notifTaskId}&userId=${data.notifUserId}`;
                        // console.log(url)
                        // console.log(data)
                        let result = await this.$axios.get(url)
                        // console.log(result)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },

        // * ARCHIVE FUNCTIONS
        REDIS_archiveClient(data) {
            return new Promise((resolve, reject) => {
                ( async () => {
                    // try {
                        const url = `${this.bulkUrl}jobs/archive-client`;
                        let result = await this.$axios.post(url, data)
                        // console.log('result = ' + JSON.stringify(result, null, 2))
                        if (result.status === 201) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    // } catch (error) {
                    //     reject(error);
                    // }
                })();
            });
        },
        REDIS_archiveSite(data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    // try {
                        const url = `${this.bulkUrl}jobs/archive-site`;
                        let result = await this.$axios.post(url, data)
                        // console.log('result = ' + JSON.stringify(result, null, 2))
                        if (result.status === 201) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    // } catch (error) {
                    //     reject(error);
                    // }
                })();
            });
        },
        REDIS_archiveUser(data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    // try {
                        const url = `${this.bulkUrl}jobs/archive-user`;
                        let result = await this.$axios.post(url, data)
                        // console.log('result = ' + JSON.stringify(result, null, 2))
                        if (result.status === 201) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    // } catch (error) {
                    //     reject(error);
                    // }
                })();
            });
        },
        // START BACKGROUND TASK FOR FULL REPORT
        REDIS_archiveRecurringJob(data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    // try {
                        const url = `${this.bulkUrl}recurring-job/archive-recurring-job`;
                        let result = await this.$axios.post(url, data)
                        // console.log('result = ' + JSON.stringify(result, null, 2))
                        if (result.status === 201) {
                            resolve(result.data);
                        } else {
                            resolve(result.error);
                        }
                    // } catch (error) {
                    //     reject(error);
                    // }
                })();
            });
        },

        // * DASHBOARD MIXINS 
        REDIS_projectedHours() {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.dashboardUrl}jobs/count-for-3-months`;
                        let result = await this.$axios.get(url)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            })
        },

        REDIS_countAbsenceHours(searchQuery) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.dashboardUrl}absence/count-hours?query=${searchQuery}`;
                        // console.log(url)
                        let result = await this.$axios.get(url)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            })
        },

        REDIS_countJobHours() {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.dashboardUrl}jobs/count-for-week`;
                        let result = await this.$axios.get(url)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            })
        },

        REDIS_clientAllocation(period) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.dashboardUrl}jobs/client-allocation?period=${period}`;
                        let result = await this.$axios.get(url)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            })
        },
        REDIS_clientContractedAllocatedStats(period) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.dashboardUrl}client/contracted-allocated-hours?period=${period}`;
                        let result = await this.$axios.get(url)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            })
        },

        REDIS_siteAllocation(period) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.dashboardUrl}jobs/site-allocation?period=${period}`;
                        let result = await this.$axios.get(url)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            })
        },
        REDIS_getStaffAudits() {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        var auditId = 'cEjeAFJSMAdEfI6Gt3dJ'
                        const url = `${this.dashboardUrl}jobs/audit-targets?auditId=${auditId}`;
                        // console.log(url)
                        let result = await this.$axios.get(url)
                        
                        if (result.status === 200) {
                            resolve(result.data);
                        } else if (result.status === 204) {
                            resolve(result.data);
                        } else if (result.status === 404) {
                            reject(result.error);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },


        REDIS_getStaffTraining() {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}user/training-programme`;
                        let result = await this.$axios.get(url)
                        
                        if (result.status === 200) {
                            resolve(result.data);
                        } else if (result.status === 204) {
                            resolve(result.data);
                        } else if (result.status === 404) {
                            reject(result.error);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },

    }
}

export default {
    install(Vue) {
        Vue.mixin(mixin);
    },
};
