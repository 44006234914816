<template>
	<v-card flat outlined  class="pa-0 ma-0">
        <v-col cols="12" class="align-start">
		    <div class="">
		    	<div class="font-weight-bold body-2 text-start primary--text">
		    		<span v-if="item.communicationType == 'client'">{{ item.communicationClientName }}  </span>
                    <span v-else>A&R Services</span>
		    	</div>
		    	<div class="font-weight-regular body-2 text-start grey--text">
		    		{{ item.communicationTitle }}  
		    	</div>
		    	<div class="font-weight-regular body-2 text-start grey--text">
		    		{{ item.communicationDescription }}  
		    	</div>
		    	<div v-if="item.communicationType === 'client'" class="font-weight-regular body-2 text-start grey--text">
                    <div class="d-flex align-center">
                        <v-icon :class="item.communicationRead ? 'success--text' : '' ">icons8-checkmark-yes</v-icon>
                        <div class="font-weight-regular body-2 pl-1 text-start grey--text">
                            Read By: {{ item.communicationReadUserName }} 
                            <span v-if="item.communicationRead">on {{ $moment(item.communicationReadDateTime).format('HH:mm DD-MM-YYYY') }}</span>
                        </div>
                    </div>
                    <div class="d-flex align-center">
                        <v-icon :class="item.communicationCompleted ? 'success--text' : '' ">icons8-checkmark-yes</v-icon>
                        <div class="font-weight-regular body-2 pl-1 text-start grey--text">
                            Completed By: {{ item.communicationCompletedUserName }}  
                            <span v-if="item.communicationCompleted">on {{ $moment(item.communicationCompletedDateTime).format('HH:mm DD-MM-YYYY') }}</span>
                        </div>
                    </div>
		    	</div>
		    	<div v-else-if="item.communicationType === 'staff'" class="font-weight-regular body-2 text-start grey--text">
		    		Submitted By: {{ item.communicationCreatedUserName }} at {{ $moment(item.createdDateTime).format('HH:mm') }}
		    	</div>
		    </div>
        </v-col>
	</v-card>
</template>

<script>
	export default {
        data: () => ({
        }),
		props: {
            item: { type: Object, default: '-'},
		},
        computed: {
        },
        methods: {
            
        }
	};
</script>

<style scoped>
</style>
