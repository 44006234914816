<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 110px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start  pb-0">
                <v-row dense>

                    <v-col  v-if="userOpen === false && !itemNew" class="pl-1 pr-2 d-flex align-end" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <!-- // CANCEL  -->
                        <v-btn v-if="item.jobPublishedStatus !== 'CANCELLED' && ['SA'].includes(GET_currentUser.userLevel)" class="error elevation-0 white--text"  :disabled="item.deleted == true" @click="openActionDialog('Cancel')" > <v-icon>icons8-cancel</v-icon> Cancel </v-btn>
                        <!-- // UNCANCEL  -->
                        <v-btn v-else-if="item.jobPublishedStatus === 'CANCELLED' && ['SA'].includes(GET_currentUser.userLevel)" class="error elevation-0 white--text" :disabled="item.deleted == true" @click="openActionDialog('Uncancel')" > <v-icon>icons8-process</v-icon> Uncancel </v-btn>
                        <v-spacer />
                        <!-- // UNALLOCATE -->
                        <v-btn v-if="userOpen === false && item.jobAssignedStatus === 'ALLOCATED' && ['SA'].includes(GET_currentUser.userLevel)" class="elevation-0 grey white--text" :disabled="item.deleted == true" @click.native="openActionDialog('Unallocate')" > <v-icon>icons8-denied-2</v-icon> Unallocate </v-btn>
                    </v-col> 
                    <v-col  v-if="$vuetify.breakpoint.smAndDown && userOpen === false && !itemNew" class="pl-1 pr-2 d-flex align-end" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <!-- // publish  -->
                        <v-btn v-if="item.jobPublishedStatus !== 'CANCELLED' && ['SA'].includes(GET_currentUser.userLevel) && item.jobPublishedStatus !== 'PUBLISHED'" class="elevation-0 mt-1" :disabled="item.deleted == true" @click="openActionDialog('Publish')" color="secondary" medium>
                             <v-icon>icons8-checkmark-yes</v-icon> <span class="white--text">PUBLISH</span>
                        </v-btn>
                        <!-- // unpublish  -->
                        <v-btn v-if="item.jobPublishedStatus !== 'CANCELLED' && ['SA'].includes(GET_currentUser.userLevel) && item.jobPublishedStatus !== 'UNPUBLISHED'" :disabled="item.deleted == true" class="elevation-0 mt-1" @click="openActionDialog('Draft')" color="secondary" medium> <v-icon>icons8-in-progress</v-icon> Draft </v-btn>
                    </v-col> 
                </v-row>
            </v-col>
        </v-row>
        <v-row class="apptext--text" dense style="z-index: 0 !important">
            <!-- <pre>GET_currentUser: {{ GET_FIREBASE_currentUser }}</pre> -->

            <v-expansion-panels v-model="panel" accordion flat class="elevation-0" multiple>
                <v-expansion-panel class="pa-0 ma-0 appbackground elevation-0">
                    <v-expansion-panel-header class="pl-1 pr-5 py-1 ma-0">
                        <!-- // * CLIENT INFORMATION -->
			        	<h3 class="grey--text text--darken-1 ">INFORMATION</h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                        <!-- // * FORM  -->
                        <v-form ref="form" >                                        
                            <v-row class="mb-2 ma-0 pa-0 pr-1" no-gutters>
                                <v-col class="pa-1 py-0 mt-0" cols="12">
                                    <v-autocomplete 
                                        label="Client" 
                                        v-model="selectedClient" 
                                        @change="getClientSites()" 
                                        :rules="rules.required" 
                                        required 
                                        :items="clients" 
                                        :menu-props="{ top: false, offsetY: true }" 
                                        :attach="true"
                                        auto-select-first 
                                        item-text="clientName" 
                                        return-object 
                                        dense 
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        :disabled="!['SA'].includes(GET_currentUser.userLevel) || item.deleted == true"
                                        class="">
                                    </v-autocomplete> 
                                </v-col>

                                <!-- // * SITE -->
                                <v-col class="pa-1 py-0 mt-1" cols="12">
                                    <v-autocomplete 
                                        label="Site" 
                                        v-model="selectedSite" 
                                        :rules="rules.required" 
                                        required 
                                        :items="computedSites" 
                                        :menu-props="{ top: false, offsetY: true }" 
                                        :attach="true"
                                        auto-select-first 
                                        item-text="siteName" 
                                        return-object 
                                        dense 
                                        no-data-text="Please Select Client"
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        :disabled="!['SA'].includes(GET_currentUser.userLevel) || item.deleted == true"
                                        class="">
                                    </v-autocomplete>
                                    
                                </v-col>
                                <!-- // * SERVICES -->
                                   
                                <v-col v-if="audit === false" class="pa-1 py-0 mt-1" cols="12">
                                    <v-autocomplete 
                                        label="Services" 
                                        v-model="selectedService" 
                                        :rules="rules.required" 
                                        required 
                                        :items="services" 
                                        :menu-props="{ top: false, offsetY: true }" 
                                        :attach="true"
                                        item-text="serviceName" 
                                        return-object 
                                        dense 
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        :disabled="!['SA'].includes(GET_currentUser.userLevel) || item.deleted == true"
                                        class="">
                                    </v-autocomplete>
                                    
                                </v-col>


                                <!-- // * ALLOCATED STAFF MEMBER -->
                                   
                                <v-col v-if="item.assignedStatus === 'ALLOCATED' || $vuetify.breakpoint.mdAndDown && ['SA'].includes(GET_currentUser.userLevel) && audit !== true" class="pa-1 py-0 mt-1" cols="12">
                                    <v-autocomplete 
                                        label="Allocated Staff" 
                                        v-model="selectedUser" 
                                        :rules="rules.assignedUser" 
                                        required 
                                        :items="users" 
                                        :menu-props="{ top: false, offsetY: true }" 
                                        :attach="true"
                                        item-text="userName" 
                                        return-object 
                                        dense 
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        :disabled="!['SA'].includes(GET_currentUser.userLevel) || item.deleted == true"
                                        class="">
                                    </v-autocomplete>
                                </v-col>

                                <v-col v-if="audit === true" class="pa-1 py-0 mt-1" cols="12">
                                    <v-autocomplete 
                                        label="Audited By" 
                                        v-model="selectedUser" 
                                        :rules="rules.assignedUser" 
                                        required 
                                        :items="managers" 
                                        :menu-props="{ top: false, offsetY: true }" 
                                        :attach="true"
                                        item-text="userName" 
                                        return-object 
                                        dense 
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        :disabled="!['SA'].includes(GET_currentUser.userLevel) || item.deleted == true"
                                        class="">
                                    </v-autocomplete>
                                </v-col>


                                <!-- // * DATE -->
                                <v-col class="pa-1 py-0 pt-1"  cols="12">
                                    <div class="caption">Start</div>
                                    <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field 
                                                label="Start Date" 
                                                v-model="computedDate1" 
                                                outlined 
                                                dense 
                                                hide-details="auto" 
                                                background-color="white" 
                                                color="primary" 
                                                v-bind="attrs" 
                                                v-on="on" 
                                                clearable 
                                                @click:clear="clearItem('contentAvailableFrom')"
                                                :disabled="!['SA'].includes(GET_currentUser.userLevel) || item.deleted == true"
                                                :rules="rules.required" 
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                                    </v-menu>
			            		</v-col>

                                <!-- //* START TIME -->
                                <v-col class="pa-1 py-0 pr-0 pt-1" cols="12">
                                    <div class="d-flex">
                                        <v-col class="pa-0 ma-0" cols="6">
                                            <v-select 
                                                class="" 
                                                hide-details="auto" 
                                                label="Hours"  
                                                :rules="rules.startDate"
                                                filled 
                                                v-model="startTimeHours" 
                                                required 
                                                outlined
                                                dense
                                                :items="hoursWith0" 
                                                background-color="white" 
                                                color="primary"
                                                :menu-props="{ top: false, offsetY: true }" 
                                                :disabled="!['SA'].includes(GET_currentUser.userLevel) || item.deleted == true"
                                                :attach="true"
                                            ></v-select>
                                        </v-col>
                                        <v-col class="pa-0 ma-0" cols="6">
                                            <v-select 
                                                class="px-1" 
                                                hide-details="auto" 
                                                label="Mins" 
                                                :rules="rules.startDate"
                                                filled 
                                                outlined
                                                v-model="startTimeMins" 
                                                required 
                                                dense
                                                :items="mins" 
                                                background-color="white" 
                                                color="primary"
                                                :menu-props="{ top: false, offsetY: true }" 
                                                :disabled="!['SA'].includes(GET_currentUser.userLevel)  || item.deleted == true"
                                                :attach="true"
                                            ></v-select>
                                        </v-col>
                                    </div>
                                </v-col>
                                <!-- // * DATE -->
                                <v-col class="pa-1 py-0 pt-1"  cols="12">
                                    <div class="caption">End</div>
                                    <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field 
                                                label="End Date" 
                                                v-model="computedDate2" 
                                                outlined 
                                                dense 
                                                hide-details="auto" 
                                                background-color="white" 
                                                color="primary" 
                                                v-bind="attrs" 
                                                v-on="on" 
                                                clearable 
                                                @click:clear="clearItem('contentAvailableFrom')"
                                                :disabled="!['SA'].includes(GET_currentUser.userLevel) || item.deleted == true"
                                                :rules="rules.required" 
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date2" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu2 = false"></v-date-picker>
                                    </v-menu>
			            		</v-col>

                                <!-- //* DURATION  -->
                                <v-col class="pa-1 py-0 pr-0 pt-1" cols="12">
                                    <div class="d-flex">
                                        <v-col class="pa-0 ma-0" cols="6">
                                            <v-select 
                                                class="" 
                                                hide-details="auto" 
                                                label="Hours" 
                                                :rules="rules.endDate"
                                                filled 
                                                v-model="endTimeHours" 
                                                required 
                                                outlined
                                                dense
                                                :items="hoursWith0" 
                                                background-color="white" 
                                                color="primary"
                                                :menu-props="{ top: false, offsetY: true }" 
                                                :disabled="!['SA'].includes(GET_currentUser.userLevel) || item.deleted == true"
                                                :attach="true"
                                            ></v-select>
                                        </v-col>
                                        <v-col class="pa-0 ma-0" cols="6">
                                            <v-select 
                                                class="px-1" 
                                                hide-details="auto" 
                                                label="Mins" 
                                                :rules="rules.endDate"
                                                filled 
                                                outlined
                                                v-model="endTimeMins" 
                                                required 
                                                dense
                                                :items="mins" 
                                                background-color="white" 
                                                color="primary"
                                                :menu-props="{ top: false, offsetY: true }" 
                                                :disabled="!['SA'].includes(GET_currentUser.userLevel) || item.deleted == true"
                                                :attach="true"
                                            ></v-select>
                                        </v-col>
                                    </div>
                                </v-col>


                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel> 
                
                <v-expansion-panel v-if="!itemNew" class="pa-0 ma-0 appbackground elevation-0">
                    <v-expansion-panel-header class="pl-1 pr-5 py-1 ma-0">
                        <!-- // * CLIENT INFORMATION -->
				    	<h3 class="grey--text text--darken-1">CLIENT/SITE INFORMATION</h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form ref="form">       
                                  
                            <v-row class="mb-2 ma-0 pa-0 pr-1" no-gutters>
                                <v-col class="pa-1 py-0 mt-0" cols="12">
                                    <v-text-field 
                                        label="Client" 
                                        v-model="selectedClient.clientName" 
                                        required 
                                        dense 
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        :disabled="!['SA'].includes(GET_currentUser.userLevel) || item.deleted == true"
                                        class="">
                                    </v-text-field> 
                                </v-col>
                                <v-col class="pa-1 py-0 mt-1" cols="12">
                                    <v-text-field 
                                        label="Client Manager" 
                                        v-model="selectedClient.clientManager" 
                                        required 
                                        dense 
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        :disabled="!['SA'].includes(GET_currentUser.userLevel) || item.deleted == true"
                                        class="">
                                    </v-text-field> 
                                </v-col>
                                <v-col class="pa-1 py-0 mt-1" cols="12">
                                    <v-text-field 
                                        label="Address" 
                                        v-model="selectedSite.siteAddress" 
                                        required 
                                        dense 
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        :disabled="!['SA'].includes(GET_currentUser.userLevel) || item.deleted == true"
                                        class="">
                                    </v-text-field> 
                                </v-col>
                                <v-col v-if="['SA'].includes(GET_currentUser.userLevel)" class="pa-1 py-0 mt-1" cols="12">
                                    <v-text-field 
                                        label="Telephone" 
                                        v-model="selectedClient.clientTelephone" 
                                        required 
                                        dense 
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        :disabled="item.deleted == true"
                                        class="">
                                    </v-text-field> 
                                </v-col>
                                <v-col v-if="['SA'].includes(GET_currentUser.userLevel)" class="pa-1 py-0 mt-1" cols="12">
                                    <v-text-field 
                                        label="Email" 
                                        v-model="selectedClient.clientEmail" 
                                        required 
                                        dense 
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        :disabled="item.deleted == true"
                                        class="">
                                    </v-text-field> 
                                </v-col>
                                <v-col v-if="['SA'].includes(GET_currentUser.userLevel)" class="pa-1 py-0 mt-1" cols="12">
                                    <v-text-field 
                                        label="Client Type" 
                                        v-model="selectedClient.clientType" 
                                        required 
                                        dense 
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        :disabled="item.deleted == true"
                                        class="">
                                    </v-text-field> 
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="!itemNew" class="pa-0 ma-0 appbackground elevation-0">
                    <v-expansion-panel-header class="pl-1 pr-5 py-1 ma-0">
                        <!-- // * CLIENT INFORMATION -->
				    	<h3 class="grey--text text--darken-1">JOB NOTES</h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form ref="form">       
                                  
                            <v-row class="mb-2 ma-0 pa-0 pr-1" no-gutters>
                                <v-col class="pa-1 py-0 mt-0" cols="12">
                                    <v-textarea 
                                        label="Job Notes" 
                                        v-model="item.jobNotes" 
                                        required 
                                        dense 
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        rows="3"
                                        :disabled="!['SA'].includes(GET_currentUser.userLevel) || item.deleted == true"
                                        class="">
                                    </v-textarea> 
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <div style="width: 100% !important" class="pr-2 pl-1">
                <!-- // SAVE  -->
                <v-btn v-if="userOpen === false && ['SA'].includes(GET_currentUser.userLevel)" :disabled="item.deleted == true" block depressed class="secondary white--text pr-2"  @click.native="validateItem()" ><v-icon>icons8-save</v-icon> save </v-btn>
                <!-- // UPDATE  -->
                <!-- <v-btn v-else-if="userOpen === false && !itemNew && ['SA'].includes(GET_currentUser.userLevel)" block depressed class="secondary white--text pr-2" @click.native="updateItem()" ><v-icon>icons8-save</v-icon> save </v-btn> -->
            </div>

            <div v-if="!itemNew" style="width: 100% !important" class="pa-0 pb-0 pt-2  align-center">
                <div v-if="item.modifiedDateTime !== '' && item.modifiedDateTime !== null" class="pl-1 font-weight-medium caption grey--text text-darken-2">
                    Modified By: {{ getUser(item.modifiedUserId) }}, at {{ $moment(item.modifiedDateTime).format('HH:mm DD-MMM-YYYY') }}
                </div>
                <div v-if="item.createdDateTime !== '' && item.createdDateTime !== null" class="pl-1 font-weight-medium caption grey--text text-darken-2">
                    Created By: {{ getUser(item.createdUserId) }}, at {{ $moment(item.createdDateTime).format('HH:mm DD-MMM-YYYY') }}
                </div>
            </div>

            <v-dialog v-model="actionDialog" max-width="412" :key="dialogKey">
                <v-card>
                    <v-toolbar color="secondary" elevation="0">
                        <app-text category="text-medium" class="white--text ml-2">{{ action }} Job?</app-text>

                        <v-spacer />
                        
                        <v-btn @click="cancelActionDialog()" icon dark>
                            <v-icon class="icons8-multiply" color="white" />
                        </v-btn>
                    </v-toolbar>

                    <div v-if="action === 'Cancel'" class="pa-3"> 
                        <div class="d-flex align-center">
                            <app-text category="" class="pr-2 font-weight-bold">Do you still want to bill the Client for this Job?</app-text>
                            <v-checkbox v-model="billClient" />
                        </div>
                        <app-text v-if="billClient" category="" class="">The Client will still be billed for this Job.</app-text>
                        <app-text v-else category="" class="">The Client will not be billed for this Job.</app-text>

                        <div class="d-flex align-center">
                            <app-text category="" class="pr-2 font-weight-bold">Do you still want to pay the Staff Member for this Job?</app-text>
                            <v-checkbox v-model="payUser" />
                        </div>
                        <app-text v-if="payUser" category="" class="">The Staff Member will still be paid for this job.</app-text>
                        <app-text v-else category="" class="">The Staff Member will not be paid for this job.</app-text>
                    </div>

                    <div v-if="action === 'Uncancel'" class="pa-3"> 
                        <app-text category="" class="">This will remove the Jobs 'Cancelled' Status, but the Job will need to be published before it appears on the Assigned Staff Members Rota.</app-text>
                    </div>

                    <div v-if="action === 'Publish'" class="pa-3"> 
                        <app-text category="" class="">This will publish the Job to the Assigned Staff Members Rota.</app-text>
                    </div>

                    <div v-if="action === 'Draft'" class="pa-3"> 
                        <app-text category="" class="">This will remove the Job from the Assigned Staff Members Rota.</app-text>
                    </div>

                    <div v-if="action === 'Delete'" class="pa-3"> 
                        <app-text category="" class="">Are you sure you want to delete this Job? This action is irreversible.</app-text>
                    </div>

                    <div v-if="action === 'Undelete'" class="pa-3"> 
                        <app-text category="" class="">This will remove the Jobs 'Cancelled' Status, but the Job will need to be published before it appears on the Assigned Staff Members Rota.</app-text>
                    </div>

                    <div v-if="action === 'Unallocate'" class="pa-3"> 
                        <app-text category="" class="">This will remove the Assigned Staff Member from the Job and change the Job status to Unallocated.</app-text>
                    </div>



                    <div class="d-flex align-center pa-3">
                        <appbutton @click.native="cancelActionDialog()" label="Close" />
                        <v-spacer />
                        <appbutton v-if="action === 'Cancel'" @click.native="cancelJob()" type="primary" label="Cancel Job" />
                        <appbutton v-if="action === 'Uncancel'" @click.native="uncancelJob()" type="primary" label="Uncancel Job" />
                        <appbutton v-if="action === 'Delete'" @click.native="deleteItem()" type="primary" label="Delete Job" />
                        <appbutton v-if="action === 'Unallocate'" @click.native="unallocate()" type="primary" label="Unallocate Job" />
                        <appbutton v-if="action === 'Publish'" @click.native="publish()" type="primary" label="Publish Job" />
                        <appbutton v-if="action === 'Draft'" @click.native="draft()" type="primary" label="Draft Job" />
                    </div>
                </v-card>
            </v-dialog>
                    
        </v-row>
    </div>  
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        audit: {
            Type: Boolean,
            Default: false,
        },
        userOpen: {
            type: Boolean,
            default: false
        },
        refreshItems: {
            type: Number, 
            Default: 0
        }
    },
    // * DATA
    data: () => ({
        key: 'job',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,

        // new job object
        item: {},
        loading: false,
        rules: {},

        selectedClient: null, 
        selectedSite: null,
        selectedService: null, 
        selectedUser: null,

        startTimeHours: '', 
        startTimeMins: '',

        endTimeHours: '',
        endTimeMins: '',


        // dropdowns
        clients: [],
        sites: [],
        computedSites: [],
        services: [],
        users: [],

        panel: [0],

        date1: '',
        dateMenu1: false,
        date2: '',
        dateMenu2: false,
        jobStartDate: '',
        jobEndDate: '',

        action: '',
        actionDialog: false,
        dialogKey: 0,


        billClient: false,
        payUser: false,

    }),
    computed: { 
        ... mapGetters ({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
            GET_FIREBASE_userAuth: 'GET_FIREBASE_userAuth',
        }),
        hours() {
            var hours = []
            for (var i = 0; i < 24; i++) {
                hours.push (i.toString())
            }
            return hours
        },  
        computedDate1() {
            return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
        computedDate2() {
            return this.MIX_formatDateTime(this.date2, "YYYY-MM-DD", "DD-MMM-YYYY");
        },

        hoursWith0() {
            var hours = []
            for (var i = 0; i < 24; i++) {
                if (i < 10) {
                    hours.push ('0' + i).toString()
                } else {
                    hours.push (i.toString())
                }
            }
            return hours
        },

        mins() {
            var mins = []
            for (var i = 0; i < 60; i++) {
                if (i < 10) {
                    mins.push ('0' + i).toString()
                } else {
                    mins.push (i.toString())
                }
                i = i + 4;
            }
            return mins
        }, 
        managers() {
            var users = this.users 


            var managers = users.filter(function(item) {
                return item.userCategory === 'COMMERCIALMANAGER' || item.userCategory === 'DIRECTOR' || item.userCategory === 'SENIORMANAGER';
            })

            return managers 
        }
 
    },
    methods: {
        getUser(userId) {
            var t = this;
            var user = []
            var userName = ''
            user = t.users.filter((item) => { return item.entityId == userId })
            if (user.length !== 0) { userName = user[0].userName }
            return userName
        },
        unallocate() {
            var t = this;
            t.cancelActionDialog();
            t.item.jobAssignedStatus = "UNALLOCATED";
            this.item.jobPublishedStatus = 'UNPUBLISHED'
            this.item.jobAssignedUserId = '';
            
            this.updateItem();
        },

        draft() {
            var t = this;
            t.cancelActionDialog();
            this.item.jobPublishedStatus = 'UNPUBLISHED';
            this.updateItem();
        },

        publish() {
            var t = this;
            t.cancelActionDialog();
            this.item.jobPublishedStatus = 'PUBLISHED';
            this.updateItem();
        },

        async openActionDialog(action) {
            var t = this;
            
            t.action = action;
            t.actionDialog = true;
        },
        cancelActionDialog() {
            var t = this;
            t.actionDialog = false;
            t.action = '';
        },
        async initialise() {
            var t = this
            this.item = { ...this.datadown };

            let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false} -@clientStatus:{ARCHIVE}');
            if (clients.success) { t.clients = clients.data.documents; }

            t.clients = t.clients.map((x) => {
                return {
                    entityId: x.entityId,
                    clientName: x.clientName,
                    clientManager: x.clientManagerUserId,
                    clientAddress: x.clientAddressLine1 + ', ' + x.clientAddressPostcode,
                    clientTelephone: x.clientContactTelephone,
                    clientEmail: x.clientContactEmail,
                    clientType: x.clientType
                };
            })

            
            let users = await t.REDIS_searchFor('user', '0', '9999', 'userName', 'ASC', '@deleted:{false} @userStatus:{APPROVED}');
            if (users.success) { t.users = users.data.documents; }

            t.users = t.users.map((x) => {
                return {
                    entityId: x.entityId,
                    userName: x.userName,
                    userCategory: x.userCategory
                };
            })
            // console.log('users = ' + JSON.stringify(t.users, null, 2))

            let services = await t.REDIS_searchFor('service', '0', '9999', 'serviceName', 'ASC', '@deleted:{false}');
            if (services.success) { t.services = services.data.documents; }

            t.services = t.services.map((x) => {
                return {
                    entityId: x.entityId,
                    serviceName: x.serviceName,
                };
            })

            let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
            if (sites.success) { t.sites = sites.data.documents; }

            t.sites = t.sites.map((x) => {
                return {
                    entityId: x.entityId,
                    siteName: x.siteName,
                    siteAddress: x.siteAddressLine1 + ', ' + x.siteAddressPostcode,
                    siteClientId: x.siteClientId
                };
            })

            if (this.item.jobStartDateTime && this.item.jobStartDateTime !== '') {
                this.startTimeHours = this.$moment(this.item.jobStartDateTime).tz("Europe/London").format('HH')
                this.startTimeMins = this.$moment(this.item.jobStartDateTime).format('mm')
                this.date1 = this.$moment(this.item.jobStartDateTime).format('YYYY-MM-DD')
                this.jobStartDate = this.$moment(this.date1).format('X')
            }

            if (this.item.jobEndDateTime && this.item.jobEndDateTime !== '') {
                this.endTimeHours = this.$moment(this.item.jobEndDateTime).format('HH')
                this.endTimeMins = this.$moment(this.item.jobEndDateTime).format('mm')
                this.date2 = this.$moment(this.item.jobEndDateTime).format('YYYY-MM-DD')
                this.jobEndDate = this.$moment(this.date2).format('X')
            }

            if (this.item.jobClientId && this.item.jobClientId !== '') {
                this.selectedClient = this.clients.filter((item) => {
                    return item.entityId === this.item.jobClientId
                })
                if (this.selectedClient.length > 0) {
                    this.selectedClient = this.selectedClient[0]
                }
                this.getClientSites()
            }

            if (this.item.jobSiteId && this.item.jobSiteId !== '') {
                this.selectedSite = this.sites.filter((item) => {
                    return item.entityId === this.item.jobSiteId
                })

                if (this.selectedSite.length > 0) {
                    this.selectedSite = this.selectedSite[0]
                }
            }

            if (this.item.jobServiceId && this.item.jobServiceId !== '') {
                this.selectedService = this.services.filter((item) => {
                    return item.entityId === this.item.jobServiceId
                })

                if (this.selectedService.length !== 0 ) {
                    this.selectedService = this.selectedService[0]
                }
            }

            if (this.item.jobAssignedStatus == 'ALLOCATED') {
                console.log(this.item.jobAssignedUserId)
                this.selectedUser = this.users.filter((item) => {
                    return item.entityId === this.item.jobAssignedUserId
                })
                if (this.selectedUser,length !== 0 ) {
                    this.selectedUser = this.selectedUser[0]
                }

            }


            if (this.item.jobClientId !== '') {
                this.itemNew = false;
                this.editmode = false;
            } else {
                this.itemNew = true;
                this.editmode = true;
            }
        },
        deleteItem() {
            this.$emit('delete', this.item)
            this.deleteDialog = false
        },
        updateItem() {

            const startdate = this.$moment(this.jobStartDate, 'X').toDate()
            const year = startdate.getFullYear();
            const month = startdate.getMonth();
            const date = startdate.getDate();

            const startDateTime = this.$dayjs().set('year', year).set('month', month).set('date', date).set('hour', this.startTimeHours).set('minute', this.startTimeMins);
            this.item.jobStartDateTime = startDateTime.toDate()

            const endDateTime = this.$dayjs().set('year', year).set('month', month).set('date', date).set('hour', this.endTimeHours).set('minute', this.endTimeMins);
            this.item.jobEndDateTime = endDateTime.toDate()

            this.item.jobDuration = (Date.parse(endDateTime) - Date.parse(startDateTime)) / (1000 * 60 * 60);
            

            if ( this.item.jobAssignedUserId !== '') {
                this.item.jobAssignedStatus = 'ALLOCATED'
            } else {
                this.item.jobAssignedStatus = 'UNALLOCATED'
            }
            console.log('update = ' + JSON.stringify(this.item, null, 2))
            this.cancelActionDialog();
            this.$emit('update', this.item);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        validateItem() {
            var t = this;

            // var jobStartDateTime = this.$moment(this.jobStartDate, 'X').add(this.startTimeHours, 'hours').add(this.startTimeMins, 'minutes').format('HH:mm DD-MMM-YYYY')
            // var jobEndDateTime = this.$moment(this.jobEndDate, 'X').add(this.endTimeHours, 'hours').add(this.endTimeMins, 'minutes').format('HH:mm DD-MMM-YYYY')
            
            t.rules = {
                required: [(v) => !!v || 'Required'],
                startDate: [
                    (v) => !!v || 'Required',
                    // if startdate is after enddate, throw error
                    // (v) => t.$moment(jobStartDateTime).isBefore(jobEndDateTime) || 'Start Date must be before End Date'
                ],
                endDate: [
                    (v) => !!v || 'Required'
                ],
            };
            t.$nextTick(() => {
                if (t.$refs.form.validate()) {
                    if (this.itemNew) {
                        t.saveItem()
                    } else {
                        t.updateItem()
                    }
                    // t.saveLeave()
                } else {
					this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
                }
            });
        },
        // * CREATE ITEM
        async saveItem() {
        
            
            const startdate = this.$moment(this.jobStartDate, 'X').toDate()
            const year = startdate.getFullYear();
            const month = startdate.getMonth();
            const date = startdate.getDate();

            const startDateTime = this.$dayjs().set('year', year).set('month', month).set('date', date).set('hour', this.startTimeHours).set('minute', this.startTimeMins);
            this.item.jobStartDateTime = startDateTime.toDate()

            const endDateTime = this.$dayjs().set('year', year).set('month', month).set('date', date).set('hour', this.endTimeHours).set('minute', this.endTimeMins);
            this.item.jobEndDateTime = endDateTime.toDate()

            this.item.jobDuration = (Date.parse(endDateTime) - Date.parse(startDateTime)) / (1000 * 60 * 60);


            // var datePart = this.$dayjs(this.jobStartDate, 'X')
            // var combined = datePart.hour(this.startTimeHours).minute(this.startTimeMins).format()

            // this.item.jobStartDateTime = this.$moment(this.jobStartDate, 'X').add(this.startTimeHours, 'hours').add(this.startTimeMins, 'minutes').toDate();
            // this.item.jobEndDateTime = this.$moment(this.jobEndDate, 'X').add(this.endTimeHours, 'hours').add(this.endTimeMins, 'minutes').toDate();
            
            // this.item.jobDuration = (Date.parse(this.item.jobEndDateTime) - Date.parse(this.item.jobStartDateTime)) / (1000 * 60 * 60);

            console.log('item save = ' + JSON.stringify(this.item, null, 2))

            if ( this.item.jobAssignedUserId !== '') {
                this.item.jobAssignedStatus = 'ALLOCATED'
            } else {
                this.item.jobAssignedStatus = 'UNALLOCATED'
            }
            this.$emit('save', this.item);
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        cancelJob() {
            var t = this;

            t.item.jobPublishedStatus = 'CANCELLED'
            t.updateItem()
        },
        uncancelJob() {
            var t = this; 

            t.item.jobPublishedStatus = 'UNPUBLISHED'
            t.updateItem()
        },
        getClientSites() {
            var id = this.selectedClient.entityId

            var sites = this.sites 

            sites = sites.filter(function (item) {
                return item.siteClientId == id
            })
            
            this.computedSites = sites
        }
    },
    watch: {
        dateMenu1: {
            handler: async function () {
                this.jobStartDate = this.MIX_formatDateTime(this.date1, 'YYYY-MM-DD', 'X');
            },
            deep: true,
        },
        dateMenu2: {
            handler: async function () {
                this.jobEndDate = this.MIX_formatDateTime(this.date2, 'YYYY-MM-DD', 'X');
            },
            deep: true,
        },
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        selectedPackageIcon: {
            handler: function () {
                this.item.packageIcon = this.selectedPackageIcon.icon;
            }, deep: true
        },
        selectedClient: {
            handler: function () {
                this.item.jobClientId = this.selectedClient.entityId;
            }, deep: true
        },
        selectedSite: {
            handler: function () {
                this.item.jobSiteId = this.selectedSite.entityId;
            }, deep: true
        },
        selectedService: {
            handler: function () {
                this.item.jobServiceId = this.selectedService.entityId;
            }, deep: true
        },
        selectedUser: {
            handler: function () {
                this.item.jobAssignedUserId = this.selectedUser.entityId;
            }, deep: true
        },
        refreshItems: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        }
        

    },
    // * CREATED
    async created() {
        let t = this;
        t.initialise();
    },
    mounted() {},
};
</script>

<style scoped>

.v-expansion-panel-content__wrap {
    padding: 0 0px 0px !important;
}
.v-expansion-panel::before {
    box-shadow: none;
}
.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
    padding: 0 0px 0px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    flex: 1 1 auto;
    max-width: 100%;
}
</style>