<template>
    <v-row fluid fill-height class="d-flex flex-column align-start appbackground" style="height: 100% !important" no-gutters>
    <!-- <v-container fluid fill-height class="d-flex flex-column align-start appbackground pa-0 ma-0"> -->
		<v-col v-if="!userOpen" class="align-center pa-3 mt-1" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">
            
			<div class="d-flex align-center mb-1">
				<div class="d-flex align-center">
					<div><v-icon class="primary--text" x-large>icons8-course-assign</v-icon></div>
					<div class="text-left primary--text pl-1 text-h4 font-weight-thin">Courses</div>
				</div>
				<v-spacer></v-spacer>
				<div class="ml-1">
                    <v-btn depressed block class="primary darken-2 white--text" @click="openItem({})" >
                        <v-icon>icons8-course-assign</v-icon>
                        <span v-if="!listShrunk" class="ml-3">New</span>
                    </v-btn>
                </div>
				<div v-if="$vuetify.breakpoint.lgAndUp">
					<v-btn @click="exportCSV" class="primary--text ml-2" depressed v-if="!listShrunk">
						<v-icon color="primary">icons8-export-csv</v-icon>
					</v-btn>
				</div>
			</div>
            
            <CourseList :listshrunk="listShrunk" :listexpanded="listExpanded" v-on:openItem="openItem" :refreshitems="refreshItems"/>
            
		
        </v-col>
		<!-- Service Component -->
		<transition v-if="activeItem" name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
			<v-col style="border-left: #239eaf solid 1px !important; height: 100% !important" v-if="!listExpanded" class="grey lighten-3 pa-0 ma-0" cols="12" :xs="itemCols.xs" :sm="itemCols.sm" :md="itemCols.md" :lg="itemCols.lg" :xl="itemCols.xl">
                <Course v-on:refreshItems="refreshItems" :listshrunk="listShrunk"  :datadown="activeItem" v-on:closeitem="closeItem" :orgtype="orgtype"/>
			</v-col>
		</transition>

    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import Course from '@/views/courses/course.vue';
import CourseList from '@/views/courses/courseList.vue'
export default {
    name: 'Services',
    components: {
        'Course': Course,
        'CourseList': CourseList
    },
    data: () => ({
        editMode: false,
        orgtype: "",
        orgIcon: "",
        search: '',
        show: false,
        tab: '',
        orgs: [],
        orgDialog: false,
        refreshItems: 0,
        activeItem: {},
        extendWidth: '',
        routeId: '',
		// Item Columns
		itemsCols: { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12,},
		itemCols: { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 },
		taskCols: {	cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2,},
		listExpanded: true,
		listShrunk: false,
        userOpen: false
    }),
    computed: {
        ...mapGetters({
            // GET_FIREBASE_userAuth: 'GET_FIREBASE_userAuth',
        }),
    },
    methods: {
		refreshCols() {
			switch (true) {
				// Expanded List with No Task Bar
				case this.listExpanded && !this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
					this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// Expanded List with Task Bar
				case this.listExpanded && !this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
					this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				// List with Product Page (No Task Bar)
				case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// List with Product Page and Task Bar
				case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 4
					this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				// Shrunk List with Product Page (No Task Bar)
				case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }; // 2
					this.itemCols = { cols: 12, xs: 9, sm: 9, md: 9, lg: 9, xl: 9 }; // 10
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// Shrunk List with Product Page and Task Bar)
				case !this.listExpanded && this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				default:
					this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 12
					this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
			}
		},
		openItem(item) {
			this.activeItem = item;
			this.listExpanded = false;
            if (this.$vuetify.breakpoint.smAndDown) {
                this.userOpen = true;
            }
		},
		closeItem() {
			this.listExpanded = true;
			this.listShrunk = false;
            if (this.$vuetify.breakpoint.smAndDown) {
                this.userOpen = false
            }
		},
        async exportCSV() {
            var t = this;
            // t.exportingCSV = true
            
            let itemsResult = await this.REDIS_searchFor('course', 0, 999, 'courseName','ASC', "@deleted:{false}" )
            if (itemsResult.success) {
                console.log('itemsResult: ' + JSON.stringify(itemsResult, null, 2))
            
                var data = itemsResult.data.documents.map(e => {
                    return {
                        'Name' : e.courseName,
                        'Description' : e.courseDescription,
                        'Length (Hours)' : e.courseLengthHours,
                        'Validity (Years)' : e.courseValidityYears,
                        'Starter Pack' : e.courseStarterPack
                    }
                })

                console.log('data  = ' + JSON.stringify(data, null, 2))

                let csv = t.$papa.unparse(data)
                t.$papa.download(csv, 'Courses Report')
                // t.exportingCSV = false
            }
        }
    },
	watch: {
		listExpanded() { this.refreshCols() },
		listShrunk() { this.refreshCols() },
		// GET_taskBar() { this.refreshCols() }
	},
    mounted() {
		// Dynamic Route for New or Existing Item
		this.routeId = this.$route.params.id;
		if (this.routeId === "new") {
			this.openItem({})
		} else if (this.routeId !== "new" && this.routeId !== "" && this.routeId !== undefined) {
			this.openItem({ id: this.routeId })
		} 
    }
};
</script>