<template>
    <v-row fluid fill-height class=" appbackground" :style="$vuetify.breakpoint.mdAndUp ? 'width: calc(100vw - 56px) !important; position: fixed; height: 100vh !important; overflow-y: scroll !important' : 'width: 100% !important; position: fixed; height: 100vh !important; overflow-y: scroll !important'" no-gutters>
		<v-col v-if="!userOpen" class="align-center pa-3 mt-1 mb-12 pb-12" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">
            
			<div class="d-flex align-start mb-1">
				<div class="">
                    <div class="d-flex align-center">
                        <div><v-icon class="primary--text" x-large>icons8-people</v-icon></div>
                        <div class="text-left primary--text pl-1 text-h4 font-weight-thin">Staff Directory</div>
                    </div>
                    <div v-if="$vuetify.breakpoint.mdAndUp" class="text-h8 pa-0 pr-2">View and Edit exisiting Staff Members information, as well as Add new users. A Staff Members Training and Location History can be found here, as well as their Services and Calendar.</div>
                                    
				</div>
				<v-spacer></v-spacer>

                <div v-if="$vuetify.breakpoint.lgAndUp && listExpanded">
					<v-card flat width="100" class="mr-2" height="100">
						<v-card-actions class="justify-center text-center caption grey--text">TOTAL</v-card-actions>
						<v-card-title class="justify-center py-1">
							<app-text class="stats-grey grey--text font-weight-black" style="font-size: 30px">
                                <number ref="numberExample" :to="computedStats.total" :duration="1.0" />
                            </app-text>
                        </v-card-title>
					</v-card>
				</div>

                <div v-if="$vuetify.breakpoint.lgAndUp && listExpanded">
					<v-card flat width="100" class="mr-2" height="100">
						<v-card-actions class="justify-center text-center caption success--text">VALID</v-card-actions>
						<v-card-title class="justify-center py-1">
							<app-text class="stats-grey success--text font-weight-black" style="font-size: 30px">
                                <number ref="numberExample" :to="computedStats.valid" :duration="1.0" />
                            </app-text>
                        </v-card-title>
					</v-card>
				</div>

                <div v-if="$vuetify.breakpoint.lgAndUp && listExpanded">
					<v-card flat width="100" class="mr-2" height="100">
						<v-card-actions class="justify-center text-center caption warning--text">REQUIRED</v-card-actions>
						<v-card-title class="justify-center py-1">
							<app-text class="stats-grey warning--text font-weight-black" style="font-size: 30px">
                                <number ref="numberExample" :to="computedStats.required" :duration="1.0" />
                            </app-text>
                        </v-card-title>
					</v-card>
				</div>

                <div v-if="$vuetify.breakpoint.lgAndUp && listExpanded">
					<v-card flat width="100" class="mr-2" height="100">
						<v-card-actions class="justify-center text-center caption error--text">DUE (3M)</v-card-actions>
						<v-card-title class="justify-center py-1">
							<app-text class="stats-grey error--text font-weight-black" style="font-size: 30px">
                                <number ref="numberExample" :to="computedStats.due" :duration="1.0" />
                            </app-text>
                        </v-card-title>
					</v-card>
				</div>
                <div v-if="$vuetify.breakpoint.lgAndUp && listExpanded">
					<v-card flat width="100" class="" height="100">
						<v-card-actions class="justify-center text-center caption grey--text">NONE</v-card-actions>
						<v-card-title class="justify-center py-1">
							<app-text class="stats-grey grey--text font-weight-black" style="font-size: 30px">
                                <number ref="numberExample" :to="computedStats.none" :duration="1.0" />
                            </app-text>
                        </v-card-title>
					</v-card>
				</div>

			</div>
            <!-- <div v-if="$vuetify.breakpoint.mdAndUp && !listExpanded" class="text-h8 pa-0 pr-2">View and Edit exisiting Staff Members information, as well as Add new users. A Staff Members Training and Location History can be found here, as well as their Services and Calendar.</div> -->
            <!-- <pre>listExpanded: {{listExpanded}}</pre>
            <pre>listShrunk: {{listShrunk}}</pre> -->
            
            <!-- <StaffList2 :listshrunk="listShrunk" :listexpanded="listExpanded" v-on:openItem="openItem" v-on:openJob="openItem" :refreshitems="refreshItems"/> -->
            <StaffList :listshrunk="listShrunk" :listexpanded="listExpanded" v-on:openItem="openItem" v-on:openJob="openItem" :refreshitems="refreshItems"/>
            
		
        </v-col>
        
		<transition v-if="activeItem" name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
			<v-col style="border-left: #239eaf solid 1px !important; height: 100% !important" v-if="!listExpanded" class="grey lighten-3 pa-0 ma-0" cols="12" :xs="itemCols.xs" :sm="itemCols.sm" :md="itemCols.md" :lg="itemCols.lg" :xl="itemCols.xl">
                <Staff v-on:refreshItems="refreshItems" :listshrunk="listShrunk"  :datadown="activeItem" v-on:closeitem="closeItem" :orgtype="orgtype"/>
			</v-col>
		</transition>

    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import Staff from '@/views/staffDirectory/staff.vue';
import StaffList from '@/views/staffDirectory/staffList.vue'
import StaffList2 from '@/views/staffDirectory/staffList2.vue'
export default {
    name: 'Services',
    components: {
        'Staff': Staff,
        'StaffList': StaffList,
        'StaffList2': StaffList2
    },
    data: () => ({
        editMode: false,
        orgtype: "",
        orgIcon: "",
        search: '',
        show: false,
        tab: '',
        orgs: [],
        orgDialog: false,
        refreshItems: 0,
        activeItem: {},
        extendWidth: '',
        routeId: '',
        openJobView: false,
        key: 'user',
		// Item Columns
		itemsCols: { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12,},
		itemCols: { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 },
		taskCols: {	cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2,},
		listExpanded: true,
		listShrunk: false,

        computedStats: {
            total: 0,
            valid: 0,
            required: 0, 
            due: 0,
            none: 0
        },
        userOpen: false
    }),
    computed: {
        ...mapGetters({
            // GET_FIREBASE_userAuth: 'GET_FIREBASE_userAuth',
        }),
    },
    methods: {
		refreshCols() {
			switch (true) {
				// Expanded List with No Task Bar
				case this.listExpanded && !this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
					this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// Expanded List with Task Bar
				case this.listExpanded && !this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
					this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				// List with Product Page (No Task Bar)
				case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// List with Product Page and Task Bar
				case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 4
					this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				// Shrunk List with Product Page (No Task Bar)
				case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }; // 2
					this.itemCols = { cols: 12, xs: 9, sm: 9, md: 9, lg: 9, xl: 9 }; // 10
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// Shrunk List with Product Page and Task Bar)
				case !this.listExpanded && this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				default:
					this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 12
					this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
			}
		},

        async getStats() {
            var t = this;

            let total = await this.REDIS_count(t.key, `@deleted:{false} @userStatus:{'APPROVED'|'PENDING'}`)
            if (total.success) {
                t.computedStats.total = total.total
            }

            let result = await t.REDIS_getStaffTraining() 

			if (result.success) {
				var items = result.data; 
                t.computedStats.due = items.dueTraining
                t.computedStats.valid = items.validTraining
                t.computedStats.required = items.requiredTraining
                t.computedStats.none = items.noTraining
            }

        },

		openItem(item) {
            console.log('item = ' + JSON.stringify(item, null, 2))
			this.activeItem = item;
			this.listExpanded = false;
            if (this.$vuetify.breakpoint.smAndDown) {
                this.userOpen = true;
            }
		},
		closeItem() {
			this.listExpanded = true;
			this.listShrunk = false;
            if (this.$vuetify.breakpoint.smAndDown) {
                this.userOpen = false
            }
		},
    },
	watch: {
		listExpanded() { this.refreshCols() },
		listShrunk() { this.refreshCols() },
		// GET_taskBar() { this.refreshCols() }
	},
    mounted() {
		// Dynamic Route for New or Existing Item
		this.routeId = this.$route.params.id;
		if (this.routeId === "new") {
			this.openItem({})
		}
        else if (this.routeId !== "new" && this.routeId !== "" && this.routeId !== undefined && this.routeId !== ':id') {
			this.openItem({ entityId: this.routeId })
		} 
        this.getStats()
    }
};
</script>