<template>
	<v-card flat outlined :style="cardStyle" class="pa-0 ma-0"  >
        <v-col cols="12" class="align-start">
		    <div class="">
                <!-- <pre>{{ task }}</pre> -->
		    	<div class="font-weight-bold body-2 text-start primary--text">
		    		{{ task.taskTitle }}  
		    	</div>
		    	<div class="font-weight-bold body-2 text-start grey--text">
		    		Client: <span class="font-weight-regular">{{ task.taskClientName }}</span>
		    	</div>
		    	<div class="font-weight-bold body-2 text-start grey--text">
		    		Site: <span class="font-weight-regular">{{ task.taskSiteName }}  </span>
		    	</div>
		    	<div class="font-weight-bold body-2 text-start grey--text">
		    		Created: <span class="font-weight-regular">{{ $moment(task.createdDateTime).format('HH:ss, DD MMM YYYY ') }}  </span>
		    	</div>
		    	<div class="d-flex font-weight-bold body-2 text-start align-center grey--text">
                    Read: 
                    <span v-if="task.taskRead == true" >
                        {{ $moment(task.taskReadDateTime).format('HH:ss, DD MMM YYYY ') }}  
                    </span>
                    <span v-else class="pl-2 font-weight-regular">
                        ---
                    </span>
		    	</div>
		    </div>
        </v-col>
	</v-card>
</template>

<script>
    import { mapActions } from 'vuex'; // Map the Getters from the Vuex Store
	export default {
        data: () => ({
            reveal: false,
            publishedDialog: false
        }),
		props: {
            task: { type: Object, default: '-'},
		},
        computed: {
            cardStyle() {
                console.log('cardStyle')
                var today = this.$moment().format('X');
                console.log('today = ' + today)
                console.log('taskAddDate = ' + this.task.taskAddDate)
                if (this.task.taskStatus == 'OVERDUE') {
                    return 'border-left: 8px solid #E10203'
                } 
                else if (this.task.taskStatus == 'COMPLETED') {
                    return 'border-left: 8px solid #21942a'
                } 
                else if (this.task.taskAddDate > today) {
                    return 'background: repeating-linear-gradient(45deg, #ffffff, #ffffff 6px, #f8f8f8 2px, #f8f8f8 8px );'
                }
                // else if (this.task.taskStatus == 'INPROGRESS') {
                //     return 'yellow lighten-5';
                // } 
                // else if (this.task.taskStatus == 'NEW') {
                //     return 'blue lighten-5';
                // }
            },
            dueStyle() {
                if (this.task.taskDueDateTime == 'OVERDUE') {
                    return 'red--text'
                } 
                else if (this.task.taskDueDateTime == 'COMPLETED') {
                    return 'green--text'
                }
            }
        },
        methods: {
            ...mapActions({'ACT_openItem': 'ACT_openJob'}),
        }
	};
</script>

<style scoped>
</style>
