<template>
	<v-card flat outlined  class="pa-2 ma-0" :style="getStyle(job.jobPublishedStatus, job.jobAssignedStatus)" >
        <v-col cols="12" class="align-start">
		    <v-row class="">
                <!-- <pre>{{ getStyle(job.jobPublishedStatus, job.jobAssignedStatus) }}</pre> -->
		    	<v-col cols="9" class="d-flex ma-0 pa-0 pl-1 align-start">
		    		<div class="font-weight-regular text-start text-truncate primary--text" style="max-width: 150px !important">
		    			{{ job.jobClientName }}  
		    		</div>
                </v-col>
                <v-col cols="3" class="d-flex ma-0 pa-0">
                <!-- {{ job.id }} -->
                    <v-spacer />
                    <div v-if="swappJob" class="grey--text text-start body-2">
                        {{ job.startTime }} - {{ job.endTime }}
                    </div>
                    <div class="font-weight-regular text-end body-2 grey--text"> 
                        <!-- v-if="['SA'].includes(GET_currentUser.userLevel)" -->
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-on="on" v-bind="attrs" v-if="job.jobPublishedStatus === 'PUBLISHED'" icon color="transparent" width="18" height="18" class="elevation-0 success--text" size="20">icons8-checkmark-yes</v-icon>
                                <v-icon v-on="on" v-bind="attrs" v-if="job.jobPublishedStatus === 'UNPUBLISHED' && job.jobAssignedStatus === 'ALLOCATED'" icon color="transparent" width="18" height="18" class="elevation-0 grey--text" size="20">icons8-in-progress</v-icon>
                            </template>
                            <span v-if="job.jobPublishedStatus === 'PUBLISHED'">Published Job</span>
                            <span v-if="job.jobPublishedStatus === 'UNPUBLISHED'">Unpublished Job</span>
                        </v-tooltip>
                    </div>
                    <!-- <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" v-if="job.jobAssignedStatus === 'UNALLOCATED'" size="20" class="grey--text icons8-filled-circle"></v-icon>
                        </template>
                        <span>Unallocated Job</span>
                    </v-tooltip>  -->
                    
                    <div v-if="job.jobType === 'Recurring Job' && ['SA'].includes(GET_currentUser.userLevel) && $vuetify.breakpoint.mdAndUp" class="justify-end text-end body-2 pr-0 grey--text">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
		    		            <v-icon v-bind="attrs" v-on="on" @click="openRecurring(job.jobRecurringRuleId)" size="20" position="right" class="grey--text">icons8-future</v-icon>
                            </template>
                            <span>Recurring Job</span>
                        </v-tooltip>
                    </div>
                    <div v-else-if="job.jobType === 'Recurring Job'" class="justify-end text-end body-2 pr-0 grey--text">
		    		    <v-icon size="20" position="right" class="grey--text">icons8-future</v-icon>
                    </div>
		    	</v-col>
		    </v-row>
            <v-row>
                <v-col v-if="job.jobSiteName" cols="12" class="ma-0 pa-0 pl-1">
		    		<div class="caption text-start grey--text">{{ job.jobSiteName }}</div> 
                </v-col>
                <v-col v-if="job.jobServiceName" cols="12" class="ma-0 pa-0 pl-1">
                    <v-row class="d-flex pa-3">
                        <div class="caption text-start grey--text">{{ job.jobServiceName }}</div> 
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="d-flex align-end">
                <v-col cols="12" class="d-flex ma-0 pa-0 pl-1 align-end">
		    		<div v-if="$vuetify.breakpoint.xlOnly" class="font-weight-regular text-start caption">
		    			{{ $moment(job.jobStartDateTime).format('DD-MMM-YYYY HH:mm') }} - {{ $moment(job.jobEndDateTime).format('HH:mm') }}
		    		</div>
                    <v-spacer />
                    <div v-if="showDate && $vuetify.breakpoint.xlOnly" class="caption text-end grey--text">{{ job.jobStartDateTime }}</div>
                    <div v-if="$vuetify.breakpoint.xlOnly && job.jobAssignedStatus === 'UNALLOCATED'" class="pa-0 ma-0">
                        <v-btn icon color="transparent" width="18" height="18" class="elevation-0 grey--text" @click="openItem(job.entityId)"><v-icon size="24">icons8-forward</v-icon></v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row class="d-flex">
                <v-col cols="12" class="d-flex ma-0 pa-0 pl-1 align-end">
                    <div>
		    		    <div class="d-flex" v-if="job.jobAssignedStatus === 'ALLOCATED' && $vuetify.breakpoint.xlOnly">
                            <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" size="18" class="subtitle success--text icons8-filled-circle"></v-icon>
                                </template>
                                <span>Allocated Job</span>
                            </v-tooltip> -->
                            <div class="caption text-start grey--text">{{ job.jobAssignedUserName }}</div>
                        </div>
                    </div>
                        <v-spacer />
                    <div v-if="$vuetify.breakpoint.xlOnly && job.jobAssignedStatus !== 'UNALLOCATED'" class="pa-0 ma-0">
                        <v-btn icon color="transparent" width="18" height="18" class="elevation-0 grey--text" @click="openItem(job.entityId)"><v-icon size="24">icons8-forward</v-icon></v-btn>
                    </div>
		    	</v-col>
            </v-row>
            <v-row class="d-flex">
		    	<v-col v-if="$vuetify.breakpoint.lgAndDown && swappJob === false" cols="12" class="d-flex ma-0 pa-0 pl-1 justify-start align-end">
		    		<div>
                        <div class="font-weight-regular text-start caption">
		    			    {{ $moment(job.jobStartDateTime).format('HH:mm') }} - {{ $moment(job.jobEndDateTime).format('HH:mm') }}
                        </div>
                        <div v-if="job.jobAssignedStatus === 'ALLOCATED' && swappJob === false" class="d-flex pt-1">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" size="18" class="subtitle success--text icons8-filled-circle"></v-icon>
                                </template>
                                <span>Allocated Job</span>
                            </v-tooltip>
                            <div class="pl-1 caption text-start grey--text">{{ job.jobAssignedUserName }}</div>
                        </div>
		    		</div>
                    <v-spacer />
                    <div v-if="jobsPage === false" class="font-weight-regular text-end body-2 pr-1 pt-1 grey--text">
                        {{ $moment(job.jobStartDateTime).format('HH:mm') }} - {{ $moment(job.jobEndDateTime).format('HH:mm') }}
                    </div>
                    <div v-else class="pa-0 ma-0">
                        <v-btn icon color="transparent" width="18" height="18" class="elevation-0 grey--text" @click="openItem(job.entityId)"><v-icon size="24">icons8-forward</v-icon></v-btn>
                    </div>
		    	</v-col>
            </v-row>
        </v-col>    
        <v-dialog v-model="publishedDialog" max-width="412">
            <v-card>
                <v-toolbar color="secondary" elevation="0">
                    <app-text category="text-medium" class="white--text ml-2">{{ this.returnPublishMethod }} Job?</app-text>
                    <v-spacer />
                    
                    <v-btn @click="publishedDialog = false" icon dark>
                        <v-icon class="icons8-multiply" color="white" />
                    </v-btn>
                </v-toolbar>
                <div class="pa-3" v-if="job.publishedStatus === 'PUBLISHED'">
                    This Job is Published to {{ job.assignedStaff.userName }}'s Rota, are you sure you want to {{ this.returnPublishMethod }}?
                </div>
                <div class="pa-3" v-if="job.publishedStatus === 'UNPUBLISHED'">
                    This Job is Unpublished, are you sure you want to Publish to {{ job.assignedStaff.userName }}'s Rota?
                </div>

                <div class="d-flex align-center pa-3">
                    <appbutton @click.native="publishedDialog = false" label="Close" />
                    <v-spacer />
                    <appbutton v-if="this.returnPublishMethod === 'Publish'" @click.native="publishJob()" type="primary" label="Publish Job" />
                    <appbutton v-if="this.returnPublishMethod === 'Unpublish'" @click.native="unpublishJob()" type="primary" label="Unpublish Job" />
                </div>
            </v-card>
        </v-dialog>
	</v-card>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'; // Map the Getters from the Vuex Store
	export default {
        data: () => ({
            reveal: false,
            publishedDialog: false
        }),
		props: {
            job: { type: Object, default: '-'},
            showDate: { type: Boolean, default: false},
            swappJob: { type: Boolean, default: false},
            jobsPage: { type: Boolean, default: false}
		},
        computed: {
            returnPublishMethod(){
                if(this.job.publishedStatus === 'UNPUBLISHED'){
                    return 'Publish';
                } else {
                    return 'Unpublish';
                }
            },
        },
        methods: {
            ...mapActions({'ACT_openItem': 'ACT_openJob'}),
            getStyle(publishedStatus, allocatedStatus) {
                var result = ''
                switch (true) {
                    case publishedStatus === 'CANCELLED':
                        result = 'border-left: 8px solid #E10203'
                    break;
                    case publishedStatus === 'UNPUBLISHED' && allocatedStatus === 'UNALLOCATED' :
                        result = 'background: repeating-linear-gradient(45deg, #ffffff, #ffffff 6px, #f8f8f8 2px, #f8f8f8 8px ); border-left: 8px solid grey'
                    break;
                    case publishedStatus === 'UNPUBLISHED' && allocatedStatus === 'ALLOCATED' :
                        result = 'background: repeating-linear-gradient(45deg, #ffffff, #ffffff 6px, #f8f8f8 2px, #f8f8f8 8px ); border-left: 8px solid #00942A'
                    break;
                    case allocatedStatus === 'UNALLOCATED':
                        result = 'border-left: 8px solid grey'
                    break;
                    case allocatedStatus === 'ALLOCATED':
                        result = 'border-left: 8px solid #00942A'
                    break;
                    default: 
                    result = 'border: 0px solid white'; 
                    break;
                }
                return result
            },
            changePublished() {
                var t = this;
                if ($vuetify.breakpoint.mdAndUp) {
                    t.publishedDialog = true
                }
            },
            publishJob() {
                var t = this;
                this.job.publishedStatus = 'PUBLISHED';
                this.saveJob();
            },
            unpublishJob() {
                var t = this;
                this.job.publishedStatus = 'UNPUBLISHED';
                this.saveJob();
            },
            // SAVE ITEM
            saveJob: async function() {
                var t = this;
                // var updateDocumentResult = await t.MIX_updateDocumentFieldsById("jobs", t.job.id, { publishedStatus: t.job.publishedStatus });
                // if (updateDocumentResult.code === 1) {
                //     t.MIX_alert(1, `Job was Updated`, updateDocumentResult.data, null);
                // } else {
                //     t.MIX_alert(-1, 'ERROR - Could not update Job', null, updateDocumentResult.error);
                // }
                t.publishedDialog = false
            },
            openItem(item) {
                //console.log('opening item = ' + JSON.stringify(item, null, 2))
                this.MIX_go('/job/' + item)
            },
            openRecurring(item) {
                this.MIX_go('/recurringJob/' + item)
            }
        }
	};
</script>

<style scoped>
.draftCard {
    background: repeating-linear-gradient(
        45deg,
        #ffffff,
        #ffffff 6px,
        #f8f8f8 2px,
        #f8f8f8 8px
    );
}
.cancelCard {
    border-left-width: 8px;
    border-left-color: #E10203
    /* color: #E10203 */
}
.unallocatedCard {
    border-left-width: 8px;
    border-left-color: #9e9e9e
    /* color: #E10203 */
}
</style>
