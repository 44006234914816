<template>
    <div class="" style="width: 100% !important; overflow-x: none !important">
        <v-row dense style="background-color: #c1c1c1" class="d-flex pa-4 pr-3 py-5 ma-0 align-end">
            <div style="width: 100% !important" class="d-flex">
                <div class="flex-grow-1">
                    <v-text-field
                        label="Search Tasks"
                        v-model="searchQuery"
                        outlined
                        dense
                        hide-details="auto"
                        background-color="white"
                        color="primary"
                        clearable
                        prepend-inner-icon="icons8-search"
                        @click:clear="clearSearchQuery()"
                    ></v-text-field>
                </div>
                <div class="flex-grow-1 pl-3">
                    <!-- <div class="white--text caption">Filter by Staff Member:</div> -->
                    <v-select 
                        class="" 
                        hide-details="auto" 
                        label="Admin" 
                        v-model="filter.manager"
                        filled 
                        outlined
                        dense
                        clearable
                        background-color="white" 
                        color="primary"
                        :menu-props="{ top: false, offsetY: true }" 
                        :attach="true"
                        item-text="userName" 
                        item-value="entityId" 
                        :items="managers"
                    ></v-select>
                </div>
                <div class="flex-grow-1 pl-3">
                    <!-- <div class="white--text caption">Filter by Due Date:</div> -->
                    <v-select 
                        class="" 
                        hide-details="auto" 
                        label="Clients" 
                        v-model="filter.client"
                        filled 
                        outlined
                        dense
                        clearable
                        background-color="white" 
                        color="primary"
                        :menu-props="{ top: false, offsetY: true }" 
                        :attach="true"
                        item-text="clientName" 
                        item-value="entityId" 
                        :items="clients"
                    ></v-select>
                </div>
                <div class="flex-grow-1 pl-3">
                    <v-select 
                        class="" 
                        hide-details="auto" 
                        label="Sites" 
                        v-model="filter.site"
                        filled 
                        outlined
                        dense
                        clearable
                        background-color="white" 
                        color="primary"
                        :menu-props="{ top: false, offsetY: true }" 
                        :attach="true"
                        item-text="siteName" 
                        item-value="entityId" 
                        :items="sites"
                    ></v-select>
                </div>
            </div>
            <div style="width: 100% !important" class="d-flex align-end ">
                <div class="flex-grow-1">
                    <v-select 
                        class="" 
                        hide-details="auto" 
                        label="Users" 
                        v-model="filter.user"
                        filled 
                        outlined
                        dense
                        clearable
                        background-color="white" 
                        color="primary"
                        :menu-props="{ top: false, offsetY: true }" 
                        :attach="true"
                        item-text="userName" 
                        item-value="entityId" 
                        :items="users"
                    ></v-select>
                </div>
                <div class="flex-grow-1 pl-3">
                    <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field label="End Date" v-model="computedDate1" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearFilter('dueDate')"></v-text-field>
                        </template>
                        <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                    </v-menu>
                </div>
                <div class="pl-3">

                    <div class="caption font-weight-bold grey--text text--darken-1">PRIORITY</div>
                    <v-btn-toggle v-model="filter.priorityStatus" multiple color="white" class="" background-color="white" dense>
                        <v-btn value="Urgent" :class="filter.priorityStatus.includes('Urgent') ? 'error white--text' : 'error--text'" small>
                            <v-icon :class="filter.priorityStatus.includes('Urgent') ? 'white--text' : 'error--text'" class="text-h5 icons8-high-importance"></v-icon>
                            <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Urgent</span>
                        </v-btn>
                        <v-btn value="High" :class="filter.priorityStatus.includes('High') ? 'error white--text' : 'error--text'" small>
                            <v-icon :class="filter.priorityStatus.includes('High') ? 'white--text' : 'error--text'" class="text-h5 icons8-filled-circle"></v-icon>
                            <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">High</span>
                        </v-btn>
                        <v-btn value="Medium" :class="filter.priorityStatus.includes('Medium') ? 'warning white--text' : 'warning--text'" small>
                            <v-icon :class="filter.priorityStatus.includes('Medium') ? 'white--text' : 'warning--text'" class="text-h5 icons8-filled-circle"></v-icon>
                            <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Medium</span>
                        </v-btn>
                        <v-btn value="Low" :class="filter.priorityStatus.includes('Low') ? 'grey white--text' : 'grey--text'" small>
                            <v-icon :class="filter.priorityStatus.includes('Low') ? 'white--text' : 'grey--text'" class="text-h5 icons8-inactive-state"></v-icon>
                            <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Low</span>
                        </v-btn>
                    </v-btn-toggle>
                </div>
                <div class="pl-3 pb-1">
                    <v-switch v-model="showAll" color="primary" label="Show All" hide-details="auto" inset></v-switch>
                </div>


                <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="exportCSV">
                    <v-icon>icons8-export-csv</v-icon>
                </v-btn>

            </div>
        </v-row>
        
        <v-row class="pa-0">
            <v-col cols="12" class="pt-0">
                <v-card class="notscrolly d-flex transparent elevation-0 pa-0 ma-0" height="600" width="100%" style="overflow-x: none !important;">
                    <v-col cols="12" class="d-flex font-weight-bold pl-1 white--text">
                        <v-row>
                            <v-card class="d-flex transparent elevation-0 pa-0 ma-0 pl-4 pr-1 white--text" style="width: calc(100vw - 55px) !important">
                                <v-col v-for="(stage, index) in stages" :key="index" class="pa-0 text-center d-flex flex-column pl-2 ">
                                    <v-card class="borderBottom" :color="stage.stageOutcome === 'success' ? 'success' : stage.stageOutcome === 'failure' ? 'error' : ''" fixed flat>
                                        <v-col cols="12">
                                            <v-row class="justify-start pa-0">
                                                <v-col cols="12" class="d-flex justify-start align-start">
                                                    <div style="max-width: 220px !important">
                                                        <v-card-title 
                                                            :class="stage.stageOutcome === 'success' ? 'white--text' : stage.stageOutcome === 'failure' ? 'white--text' : 'secondary--text'" 
                                                            class="body-1 pa-0 ma-0 font-weight-medium justify-start text-truncate" 
                                                            style="max-width: 200px !important">
                                                            {{ stage.stageName }}
                                                        </v-card-title>
                                                        <v-card-title 
                                                            :class="stage.stageOutcome === 'success' ? 'white--text' : stage.stageOutcome === 'failure' ? 'white--text' : 'grey--text'" 
                                                            class="text-h5 font-weight-light pa-0 ma-0 justify-start "
                                                            style="max-width: 200px !important">
                                                            {{ computedItems(stage).length }}
                                                        </v-card-title>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-card>
                                    <v-col class="pa-0 ma-0 mt-2">
                                        <div style="height: 550px !important; overflow: scroll !important">
                                            <!-- <div v-if="computedItems(stage).length === 0" class="grey--text font-weight-medium">
                                                NO ITEMS
                                            </div> -->
                                            <drop-list 
                                                class="list"
                                                :items="computedItems(stage)"
                                                @insert="onInsert($event, stage)"
                                            >
                                                <template v-slot:item="{item}">
                                                    <drag 
                                                        @dragstart="dragS" 
                                                        @cut="remove(computedItems(stage), item)" 
                                                        :data="item" 
                                                        :key="item.entityId">

                                                            <task-card 
                                                                class="mb-2" 
                                                                style="" 
                                                                :task="item"
                                                                :key="item.entityId"
                                                                v-on:openItem="openItem"
                                                                v-on:deleteItem="deleteItem"
                                                            >
                                                            </task-card>
                                                    </drag>
                                                </template>
                                                <template v-slot:feedback="{data}">
                                                    <task-card 
                                                        class="mb-2" 
                                                        style="" 
                                                        :task="data"
                                                        :key="data.entityId"
                                                        >
                                                    </task-card>
                                                </template>

                                            </drop-list>
                                        </div>
                                    </v-col>
                                </v-col>
                            </v-card>
                        </v-row>
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import taskCard from '@/views/hotlistTasks/hotlistTaskCard.vue'
import { Drag, DropList } from "vue-easy-dnd";
import { mapGetters } from 'vuex';
export default {
    components: {
        'task-card': taskCard,
        Drag,
        DropList
    },
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        type: '',
        status: '',
        key: 'taskTemplate',
        service: {},
        toggleDeletedItems: false,
        showImages: false,
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'Name', value: 'taskTemplateTitle', sortable: false, hidden: false, expanded: false },
            { text: 'Description', value: 'taskTemplateDescription', sortable: false, hidden: false, expanded: false },

            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false },
        ],
        sortBy: '@taskTemplateTitle',
        datatableOptions: {
            page: 1,
            itemsPerPage: 100,
            sortBy: ['taskTemplateTitle'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        loading: false,


        stages: [
            {
                stageName: 'TODO',
                stageValue: '1',
                stageOutcome: 'noValue',
                id: 'r8D6As3eKcscVzqlhumA'
            },
            {
                stageName: 'IN PROGRESS',
                stageValue: '2',
                stageOutcome: 'noValue',
                id: '1'
            },
            {
                stageName: 'COMPLETED',
                stageValue: '4',
                stageOutcome: 'success',
                id: '3'
            },
            {
                stageName: 'OVERDUE',
                stageValue: '5',
                stageOutcome: 'failure',
                id: '4'
            }
        ],
        filter: {
            priorityStatus: [],
            manager: '',
            client: '',
            site: '',
            user: '',
            dueDate: '',

        },

        showAll: false,

        date1: '',
        dateMenu1: false,

        items: [],
        activeDrag: [],

        clients: [],
        managers: [],
        users: [],
        sites: [],

    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
        computedDate1() {
            return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined || searchQuery.length < 2) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '*';
                } else {
                    searchQuery = '@deleted:{false}';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}* @deleted:{false}`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}* @deleted:{false}`;
                    }
                }
            }

            if (!this.showAll) {
                var monthAgo = this.$moment().subtract(1, 'months').format('X')
                var today = this.$moment().format('X')
                searchQuery = `${searchQuery} @taskDueDateTime:[${monthAgo} +inf] @taskAddDate:[-inf ${today}]`
            } 
            // else {
            //     var threeMonthsAgo = this.$moment().subtract(3, 'months').format('X')
            //     searchQuery = `${searchQuery} @taskDueDateTime:[${threeMonthsAgo} +inf]`
            // }

            if (this.filter.priorityStatus.length > 0) {
                searchQuery = `${searchQuery} @taskPriority:{${this.filter.priorityStatus.join(' | ')}}`;
            }
            if (this.filter.manager !== '' && this.filter.manager !== null && this.filter.manager !== undefined) {
                searchQuery = `${searchQuery} @taskAllocatedUserId:{${this.filter.manager}}`;
            }
            if (this.filter.client !== '' && this.filter.client !== null && this.filter.client !== undefined) {
                searchQuery = `${searchQuery} @taskClientId:{${this.filter.client}}`;
            }
            if (this.filter.site !== '' && this.filter.site !== null && this.filter.site !== undefined) {
                searchQuery = `${searchQuery} @taskSiteId:{${this.filter.site}}`;
            }
            if (this.filter.user !== '' && this.filter.user !== null && this.filter.user !== undefined) {
                searchQuery = `${searchQuery} @taskUserId:{${this.filter.user}}`;
            }
            if (this.filter.dueDate !== "" && this.filter.dueDate !== null && this.filter.dueDate !== undefined) {
                searchQuery = `${searchQuery} @taskDueDateTime:[-inf ${this.filter.dueDate}]`
            }
            console.log(searchQuery);

            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            return headers;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedType() {
            const types = [];
            this.items.filter((item) => {
                return types.push(item.actionType);
            });
            return types;
        },
        computedStatus() {
            const status = [];
            this.items.filter((item) => {
                return status.push(item.actionStatus);
            });
            return status;
        },
    },
    methods: {
        async exportCSV() {
            var t = this;
            // t.exportingCSV = true
            
            let itemsResult = await this.REDIS_searchFor('hotlist-task', 0, 999, 'taskDueDateTime', 'ASC', this.computedSearchQuery);
            if (itemsResult.success) {
                console.log('itemsResult: ' + JSON.stringify(itemsResult, null, 2))
            
                var data = itemsResult.data.documents.map(e => {
                    return {

                        // 'Staff Name': t.getUser(e.instanceAssignedUserId),
                        // 'Leave Start': e.instanceAllDay ? t.$moment(e.instanceStartDateTime).format('DD-MM-YYYY') : t.$moment(e.instanceStartDateTime).format('DD-MM-YYYY HH:mm'),
                        // 'Leave End': e.instanceAllDay ? t.$moment(e.instanceEndDateTime).format('DD-MM-YYYY') : t.$moment(e.instanceEndDateTime).format('DD-MM-YYYY HH:mm'),
                        // 'Leave Type': e.instanceType,
                        // 'Leave Notes': e.instanceNotes,




                        'Task': e.taskTitle,

                        'Task Category': e.taskCategory,

                        'Task Created Date': t.$moment(e.createdDateTime).format('DD-MMM-YYYY'),
                        'Task Created User': t.getUser(e.createdUserId),
                        'Task Add Date': t.$moment(e.taskAddDate).format('DD-MMM-YYYY'),
                        'Task Due Date': t.$moment(e.taskDueDateTime).format('DD-MMM-YYYY'),

                        'Task Notes': t.stripHTML(e.taskNotesHTML),
                        
                        'Task Allocated Admin': t.getUser(e.taskAllocatedUserId),
                        'Task Type': e.taskType,
                        'Task Related Item': e.taskType == 'Client' ? t.getClient(e.taskClientId) : e.taskType == 'Site' ? t.getSite(e.taskSiteId) : t.getUser(e.taskUserId),
                        // 'Task Related Site': t.getSite(e.taskSiteId),
                        // 'Task Related Client': t.getClient(e.taskClientId),
                        'Task Status': e.taskStatus,


                        'Task Priority': e.taskPriority,
                        'Task Modified Date': t.$moment(e.modifiedDateTime).format('DD-MMM-YYYY'),
                        'Task Modified User': t.getUser(e.modifiedUserId),

                    }
                })

                // console.log('data  = ' + JSON.stringify(data, null, 2))

                let csv = t.$papa.unparse(data)
                t.$papa.download(csv, 'Hotlist-Task-Export')
                // t.exportingCSV = false
            }
        },
        stripHTML(htmlString)  {
            let doc = new DOMParser().parseFromString(htmlString, 'text/html');
            let text = doc.body.textContent || "";
            return text;
        },
        // Clear Item Property
        clearFilter(property) {
            setTimeout(() => {
                this.filter[property] = '';
            }, 1);
        },
		dragS(e) {
            console.log('dragS = ' + JSON.stringify(e.data, null, 2))
    	  this.activeDrag = e.data;
    	},
    	onDrop(slotId) {
		  	this.activeDrag = {};
    	},
        computedItems(stage) {
            var t = this;
            var items = this.items 

            items = items.filter((item) => item.taskStatus === stage.stageName);

            for (let i = 0; i < items.length; i++) {
                var item = items[i];
               
                item.taskClientName = t.getClient(item.taskClientId)
                item.taskSiteName = t.getSite(item.taskSiteId)
                item.taskUserName = t.getUser(item.taskUserId)
                item.taskAssignedUserName = t.getUser(item.taskAllocatedUserId)

            }

            return items
        },
        getClient(clientId) {
            var t = this;
            var client = []
            var clientName = ''

            client = t.clients.filter((item) => { return item.entityId == clientId })

            if (client.length !== 0) { 
                clientName = client[0].clientName 
                return clientName
            }
        },
        getUser(userId) {
            var t = this;
            var user = []
            var userName = ''

            user = t.users.filter((item) => { return item.entityId == userId })

            if (user.length !== 0) { userName = user[0].userName }

            return userName
        },
        getSite(siteId) {
            var t = this;
            var site = []
            var siteName = ''

            site = t.sites.filter((item) => { return item.entityId == siteId })

            if (site.length !== 0) { siteName = site[0].siteName }

            return siteName
        },
        async onInsert(event, stage) {
            var t = this
            console.log('event = ' + JSON.stringify(event, null, 2))
            console.log('stage = ' + JSON.stringify(stage, null, 2))
            
            if (event.data.length > 0) {
                event.data.forEach((e, idx) => {
                    this[listName].splice(event.index + idx, 0, e);
                });
            } else {
                console.log('got here')
                this.computedItems(stage).splice(event.index, 0, event.data);
                event.data.taskStatus = stage.stageName;

                var dateTimeNow = t.$moment().toISOString()

                event.data.modifiedDateTime = dateTimeNow;
                event.data.modifiedUserId = t.GET_FIREBASE_currentUser.entityId;

                let result = await this.REDIS_update('hotlist-task', event.data.entityId, event.data)

                if (result.success) {
				    t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                }

            }

            this.activeDrag = [];
        },
        async loadMore() {
            this.itemsFrom = this.itemsFrom + this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo + this.datatableOptions.itemsPerPage;
            this.getItems();
        },
        async goBack() {
            this.itemsFrom = this.itemsFrom - this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo - this.datatableOptions.itemsPerPage;
            this.getItems()
        },
        async editItem(id) {
            // Get the item from the database
            let itemResult = await this.MIX_readItem(id, 'service');
            if (itemResult.code === 1) {
                this.$emit('edit', itemResult.data);
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Editing Component', show: true });
            }
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        refreshItems: function () {
            this.getItems();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        deleteItem(item) {
            this.$emit('deleteItem', JSON.parse(JSON.stringify(item)));
        },
        // * GET ITEMS
        async getItems() {
            var t = this
            t.loading = true
            let itemsResult = await this.REDIS_searchFor('hotlist-task', this.itemsFrom, this.datatableOptions.itemsPerPage, 'taskDueDateTime','ASC', this.computedSearchQuery )
            console.log('itemsResult: ' + JSON.stringify(itemsResult));
            if (itemsResult.success) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.documents
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                t.loading = false
            }
        },
    },
    watch: {
        dateMenu1: {
            handler: async function () {
                this.filter.dueDate = this.MIX_formatDateTime(this.date1, 'YYYY-MM-DD', 'X');
            },
            deep: true,
        },
        datadown: {
            handler: function () {
                this.service = { ...this.datadown };
            },
            deep: true,
        },
        refreshitems() {
            console.log('refreshitems')
            this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        computedFields() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        var t = this;
        this.service = { ...this.datadown };
        this.filter.manager = this.GET_FIREBASE_currentUser.entityId;
        let users = await t.REDIS_searchFor('user', '0', '999', 'userName', 'ASC', '*');
        if (users.success) { t.users = users.data.documents; }

        t.users = t.users.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
                userCategory: x.userCategory
            };
        })

        let managers = await t.REDIS_searchFor('user', '0', '999', 'userName', 'ASC', `@deleted:{false} @userLevel:{SA} -@userStatus:{ARCHIVED}`);
        if (managers.success) { t.managers = managers.data.documents; }

        t.managers = t.managers.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
            };
        })

        let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '*');
        if (clients.success) { t.clients = clients.data.documents; }

        t.clients = t.clients.map((x) => {
            return {
                entityId: x.entityId,
                clientName: x.clientName,
            };
        })

        let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '*');
        if (sites.success) { t.sites = sites.data.documents; }

        t.sites = t.sites.map((x) => {
            return {
                entityId: x.entityId,
                siteName: x.siteName,
                siteManagerUserId: x.siteManagerUserId,
            };
        })
        this.getItems();
    },
};
</script>

<style scoped>
    .list {
        min-height: 560px;
    }
    .borderBottom {
        border-bottom: 5px solid #65348a !important;
    }

</style>