<template>
    <div class="" style="width: 100% !important">
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field
                    label="Search Sites"
                    v-model="searchQuery"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    color="primary"
                    clearable
                    prepend-inner-icon="icons8-search"
                    @click:clear="clearSearchQuery()"
                ></v-text-field>
            </div>
            <v-btn v-if="$vuetify.breakpoint.mdAndUp" depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="refreshItems">
                <v-icon>icons8-available-updates</v-icon>
            </v-btn>
            <div v-if="$vuetify.breakpoint.mdAndUp" class="ml-2">
                <v-menu
                    offset-y
                    class="white"
                    style="z-index: 99999 !important; width: 200px !important; height: 200px !important"
                    :close-on-content-click="false"
                    origin="top right"
                    left
                    transition="scale-transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"
                            ><v-icon>icons8-table-properties</v-icon></v-btn
                        >
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox
                                v-model="headers[index].hidden"
                                dense
                                hide-details="auto"
                                v-for="(item, index) in headers"
                                :key="index"
                                :label="item.text"
                                :true-value="false"
                                :false-value="true"
                            ></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>
        <div class="d-flex">

            <div v-if="$vuetify.breakpoint.mdAndUp">
                <div class="caption font-weight-bold grey--text text--darken-1">STATUS</div>
                <v-btn-toggle v-model="filter.siteStatus" color="white" class="mr-2" active-class="white" mandatory background-color="lightgrey" dense>
                   
                    <v-btn value="ACTIVE" :class="filter.siteStatus.includes('ACTIVE') ? 'success white--text' : 'success--text'" small>
                        <v-icon :class="filter.siteStatus.includes('ACTIVE') ? 'white--text' : 'success--text'" class="text-h5 icons8-checkmark-yes"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">ACTIVE</span>
                    </v-btn>
                    <v-btn value="ARCHIVE" :class="filter.siteStatus.includes('ARCHIVE') ? 'warning white--text' : 'warning--text'" small>
                        <v-icon :class="filter.siteStatus.includes('ARCHIVE') ? 'white--text' : 'warning--text'" class="text-h5 icons8-private"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">ARCHIVED</span>
                    </v-btn>
                </v-btn-toggle>
            </div>

        </div>
        <v-row class="pa-3 pt-5">
            <!-- TABLE BASED LIST -->
            <v-data-table
                :item-class="itemRowBackground"
                sortable
                dense
                class=""
                style="width: 100%"
                :headers="computedHeaders"
                :items="computedItems"
                :server-items-length="itemsTotal"
                :options.sync="datatableOptions"
                v-if="$vuetify.breakpoint.mdAndUp"
                :loading="loading"
                :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50]
                }"
            >
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-spacer />
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn title="Delete" value="delete" small>
                                <v-icon class="grey--text text--darken-3">icons8-trash-can</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </template>

                <template v-slot:[`item.siteManagerUserId`]="{ item }">
                    <div class="font-weight-bold primary--text">{{ getUser(item.siteManagerUserId) }}</div>
                </template>


                <template v-slot:[`item.deleted`]="{ item }">
                    <div v-if="item.deleted == true">
                        <v-icon class="text-h5 warning--text icons8-private"></v-icon>
                    </div>
                    <div v-if="item.deleted == false">
                        <v-icon class="text-h5 success--text icons8-checkmark-yes"></v-icon>
                    </div>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                    <v-icon class="error--text pa-1" v-if="item.deleted === '1' && toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">icons8-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.entityId)" v-if="item.deleted === false && toggleFeatures.includes('delete')">icons8-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === true && toggleFeatures.includes('undelete')">icons8-trash-restore</v-icon>
                    <v-icon class="primary--text ml-5" @click="openItem(item)">icons8-forward</v-icon>
                </template>
            </v-data-table>

            <v-col class="tabledata pa-0 ma-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-row v-if="$vuetify.breakpoint.smAndDown" class="d-flex flex-column align-center " no-gutters>
                    <div v-if="itemsFrom > 0">
                        
                        <v-btn block @click="goBack" class="elevation-0 darkgrey lighten-3 white--text  mb-2 font-weight-bold">
                        + GO BACK 
                        </v-btn>
                    </div>
                    <v-col cols="12" xs="12" class="my-1 pa-1 white tablecard" v-for="item in computedItems" :key="item.id" @click="openItem(item)">
                        <v-row class="d-flex align-center justify-start pa-3 fill-height" no-gutters>
                            <v-col cols="10" class="d-flex flex-column pt-1">
                                <div class="font-weight-regular primary--text noselect">{{ getClient(item.siteClientId) }}</div>
                                <div class="caption grey--text noselect">{{ item.siteName }}, {{ item.siteArea }}</div>
                                <div class="caption grey--text noselect">Site Contact Name: {{ item.siteContactName }}</div>
                                <div v-if="item.siteContactTelephone" class="caption grey--text noselect">Site Contact Telephone: {{ item.siteContactTelephone }}</div>
                            </v-col>
                            <v-col cols="2" class="text-right"><v-icon @click="openItem(item)">mdi-chevron-right</v-icon></v-col>
                        </v-row>
                    </v-col>
                    <v-row v-if="loading === true" class="align-center pt-5 justify-center">
                        <v-progress-circular :size="60" :width="7" indeterminate color="primary" ></v-progress-circular>
                    </v-row>
                    <!-- <pre>computedItems: {{ computedItems }}</pre> -->
                    <div v-else-if="computedItems.length == 0" class="font-weight-bold grey--text text--darken-1 body-1">
                        NO JOBS
                    </div>
                    <div v-else-if="computedItems.length !== itemsTotal && computedItems.length == datatableOptions.itemsPerPage">
                        
                        <v-btn block @click="loadMore" class="elevation-0 darkgrey lighten-3 white--text font-weight-bold">
                        + SHOW MORE 
                        </v-btn>
                    </div>
                    <div v-else-if="computedItems.length <= itemsTotal && computedItems.length > 0" class="font-weight-bold grey--text text--darken-1 body-1">
                        SHOWING ALL JOBS
                    </div>
                </v-row>
            </v-col>

            <!-- <pre>{{ toggleFeatures }}</pre> -->
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'; // Map the Getters from the Vuex Store
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        client: {
            type: Object,
            default: () => ({}),
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        type: '',
        status: '',
        key: 'site',
        index: 'idx:sites',
        service: {},
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'ID', value: 'entityId', sortable: true, hidden: true, expanded: false },
            { text: 'Site Status', value: 'deleted', sortable: true, hidden: false, expanded: false },
            { text: 'Site Name', value: 'siteName', sortable: true, hidden: false, expanded: false },
            { text: 'Site Sector', value: 'siteSector', sortable: true, hidden: false, expanded: false },
            { text: 'Site Notes', value: 'siteNotes', sortable: true, hidden: true, expanded: false },
            { text: 'Site Telephone', value: 'siteTelephone', sortable: true, hidden: false, expanded: false },
            { text: 'Site Start Date', value: 'siteStartDateTime', sortable: true, hidden: true, expanded: false },
            { text: 'Site Area', value: 'siteAreaId', sortable: true, hidden: true, expanded: false },
            { text: 'Site Offline', value: 'siteOffline', sortable: true, hidden: true, expanded: false },
            { text: 'Site Client', value: 'siteClientId', sortable: true, hidden: true, expanded: false },
            { text: 'Site DBS', value: 'siteDBSCheck', sortable: true, hidden: true, expanded: false },
            { text: 'Site Locations', value: 'siteLocationsIds', sortable: true, hidden: true, expanded: false },
            { text: 'Site PPE Required', value: 'sitePPERequired', sortable: true, hidden: true, expanded: false },
            { text: 'Address Line 1', value: 'siteAddressLine1', sortable: true, hidden: true, expanded: false },
            { text: 'Address Line 2', value: 'siteAddressLine2', sortable: true, hidden: true, expanded: false },
            { text: 'Address Line 3', value: 'siteAddressLine3', sortable: true, hidden: true, expanded: false },
            { text: 'Address Town', value: 'siteAddressTown', sortable: true, hidden: true, expanded: false },
            { text: 'Address County', value: 'siteAddressCounty', sortable: true, hidden: true, expanded: false },
            { text: 'Address Postcode', value: 'siteAddressPostcode', sortable: true, hidden: true, expanded: false },
            { text: 'Site Manager', value: 'siteManagerUserId', sortable: true, hidden: false, expanded: false },
            { text: 'Site Contact Name', value: 'siteContactName', sortable: true, hidden: true, expanded: false },
            { text: 'Site Contact Email', value: 'siteContactEmail', sortable: true, hidden: true, expanded: false },
            { text: 'Site Contact Mobile', value: 'siteContactMobile', sortable: true, hidden: true, expanded: false },
            { text: 'Site Contact Position', value: 'siteContactPosition', sortable: true, hidden: true, expanded: false },
            { text: 'Site Contact Telephone', value: 'siteContactTelephone', sortable: true, hidden: true, expanded: false },
            { text: 'Site Contracted Hours', value: 'siteContractedHours', sortable: true, hidden: false, expanded: false },


            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false },
        ],
        // Fields to Retrieve from Database
        // fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        sortBy: '@siteName',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['siteName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        // productCategories: ['Chair', 'Table', 'Sofa', 'Bed', 'Wardrobe', 'Cabinet', 'Dresser', 'Other'],
        filter: {
            siteStatus: ['ACTIVE'],
        },
        loading: false,

        clients: [],
        users: [],
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.filter.siteStatus == 'ARCHIVE') {
                    searchQuery = '@deleted:{true}';
                } else if (this.filter.siteStatus == 'ACTIVE') {
                    searchQuery = '@deleted:{false}';
                } else {
                    searchQuery = '*';
                }
            } else {
                if (this.filter.siteStatus == 'ARCHIVE') {
                    searchQuery = `${searchQuery}* @deleted:{true}`;
                } else if (this.filter.siteStatus == 'ACTIVE') {
                    searchQuery = `${searchQuery}* @deleted:{false}`;
                } else {
                    searchQuery = `${searchQuery}*`;
                }
            }

            if (this.client.entityId) {
                searchQuery = `${searchQuery} @siteClientId:{${this.client.entityId}}`
            }
            
            console.log('searchQuery = ' + searchQuery);

            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (this.listshrunk) {
                headers = headers.filter((header) => header.expanded === false || header.expanded === true); // Return Headers based on Expanded State
            } else {
                headers = headers.filter((header) => header.expanded === false); // Return Headers based on Expanded State
            }
            return headers;
        },
        computedItems() {
            var items = this.items;
            if (this.type) {
                items = this.items.filter((item) => item.actionType === this.type);
            }
            if (this.status) {
                items = this.items.filter((item) => item.actionStatus === this.status);
            }
            return items;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            // if (!computedFields.includes('@actionId')) {
            //     computedFields.push('@actionId');
            // }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedType() {
            const types = [];
            this.items.filter((item) => {
                return types.push(item.actionType);
            });
            return types;
        },
        computedStatus() {
            const status = [];
            this.items.filter((item) => {
                return status.push(item.actionStatus);
            });
            return status;
        },
    },
    methods: {
        getUser(userId) {
            var t = this;
            var user = []
            var userName = ''

            user = t.users.filter((item) => { return item.entityId == userId })

            if (user.length !== 0) { userName = user[0].userName }

            return userName
        },
        async loadMore() {
            this.itemsFrom = this.itemsFrom + this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo + this.datatableOptions.itemsPerPage;
            this.getItems();
        },
        async goBack() {
            this.itemsFrom = this.itemsFrom - this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo - this.datatableOptions.itemsPerPage;
            this.getItems()
        },
        async editItem(id) {
            // Get the item from the database
            let itemResult = await this.MIX_readItem(id, 'service');
            if (itemResult.code === 1) {
                this.$emit('edit', itemResult.data);
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Editing Component', show: true });
            }
        },
        getClient(clientId) {
            var t = this;
            var client = []
            var clientName = ''

            client = t.clients.filter((item) => { return item.entityId == clientId })

            if (client.length !== 0) { clientName = client[0].clientName }
            return clientName
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        refreshItems: function () {
            this.getItems();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        async createProgress(jobId, type, status) {
            let t = this;
            let progress = {}
            
            progress.linkedId = jobId
            progress.type = type
            progress.status = status
            progress.totalItems = 1
            progress.count = 1
            progress.success = 1
            progress.error = 0
            progress.createdDateTime = t.$moment().toISOString()
            progress.createdUserId = t.GET_FIREBASE_currentUser.entityId
            console.log('progress = ' + JSON.stringify(progress, null, 2))
            await this.REDIS_create('progress', progress);
        },  
		// * DELETE ITEM
		async deleteItem(itemId) {
            var deleteData = {}

            deleteData.deletedUserId = this.GET_FIREBASE_currentUser.entityId
            deleteData.deletedDateTime = this.$moment().format('X')

            console.log('deleteData: ', JSON.stringify(deleteData, null, 2))

			let result = await this.REDIS_delete(this.key, itemId, deleteData);
            console.log('result: ', JSON.stringify(result, null, 2))
			if (result.success) {
                this.getItems()
                this.createProgress(result.data.entityId, 'Delete Site', 'Completed')
			}
		},
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.success) {
                // this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        // * GET ITEMS
        async getItems() {
            var t = this
            t.loading = true
            let itemsResult = await this.REDIS_searchFor(this.key, this.itemsFrom, this.datatableOptions.itemsPerPage, this.datatableOptions.sortBy,'ASC', this.computedSearchQuery)
            //console.log('itemsResult: ' + JSON.stringify(itemsResult));
            if (itemsResult.success) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.documents
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                t.loading = false
            }
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.service = { ...this.datadown };
            },
            deep: true,
        },
        refreshitems() {
            this.getItems();
        },
        searchQuery() {
            this.itemsFrom = 0;
            this.itemsTo = 9;
            this.datatableOptions.page = 1;
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        computedFields() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        var t = this;
        this.service = { ...this.datadown };
        let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false} -@clientStatus:{ARCHIVE}');
        if (clients.success) { t.clients = clients.data.documents; }

        t.clients = t.clients.map((x) => {
            return {
                entityId: x.entityId,
                clientName: x.clientName,
            };
        })
        let users = await t.REDIS_searchFor('user', '0', '999', 'userStartDate', 'ASC', '@deleted:{false}');
        if (users.success) { t.users = users.data.documents; }

        t.users = t.users.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
            };
        })
        this.getItems();
    },
};
</script>