import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

var firebaseConfig = {
    apiKey: "AIzaSyAvs4-60m22WdiWya6WriY7AWocg77cw_0",
    authDomain: "arcms-71bdd.firebaseapp.com",
    projectId: "arcms-71bdd",
    storageBucket: "arcms-71bdd.appspot.com",
    messagingSenderId: "503733093761",
    appId: "1:503733093761:web:e58892c6989d31aa8e1480",
    measurementId: "G-NMCQVCB50Z"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const currentUser = auth.currentUser;
const fv = firebase.firestore.FieldValue;

export {
  db,
  storage,
  auth,
  currentUser,
  fv
};