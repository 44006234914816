<template>
	<v-row class="pa-0 ma-0" fill-height>
        <v-row no-gutters class="pb-3 px-3 pt-3 primary darken-2">
            <v-col class="font-weight-bold white--text text-h6 d-flex align-center" v-if="JSON.stringify(item) !== '{}'">
                <div class="mx-3 mb-1"><v-icon class="white--text">icons8-people</v-icon></div>
				<div>
					<span class="font-weight-light"><strong>Staff: </strong>{{ item.userName }}</span>
				</div>
				<div class="ml-2 font-weight-light" v-if="item.userName === ''">New</div>
				<v-spacer></v-spacer>
                <div v-if="(item.authId == null || item.authId == '') && item.userStatus == 'APPROVED' && $vuetify.breakpoint.mdAndUp">
                    <v-btn class="ml-2 white--text secondary font-weight-medium" @click="openInviteDialog()" depressed>
                        <v-icon>icons8-email-send</v-icon>
                        send invite
                    </v-btn>
                </div>
				<div>
					<v-btn class="ml-2 white--text" plain @click="closeItem" depressed>
						<v-icon>icons8-multiply</v-icon>
					</v-btn>
				</div>
            </v-col>
        </v-row>


		<v-col class="pa-0" fill-height cols="auto" style="width: 100% !important">
			<v-tabs background-color="white" v-model="tab" show-arrows>
				<v-tab href="#details">Details</v-tab>
				<v-tab href="#training">Training</v-tab>
				<v-tab href="#locationHistory">Location History</v-tab>
				<!-- <v-tab href="#services">Services</v-tab> -->
				<v-tab href="#calendar">Calendar</v-tab>
				<v-tab href="#absence">Absence</v-tab>
				<v-tab href="#jobs">Jobs</v-tab>
				<v-tab href="#hotlistTasks">Hotlist Tasks</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab" fill-height>
				<!-- Details Tab -->
				<v-tab-item value="details" class="grey lighten-4">
					<staff-details :datadown="item" v-on:save="createStaff" v-on:update="saveStaff" />
				</v-tab-item>
				<v-tab-item value="training" class="grey lighten-4">
					<staff-training :datadown="item" v-on:save="createItem" />
				</v-tab-item>
				<v-tab-item value="locationHistory" class="grey lighten-4">
					<staff-location :datadown="item" v-on:save="createItem" />
				</v-tab-item>
				<v-tab-item value="services" class="grey lighten-4">
					<staff-services :datadown="item" v-on:save="createItem" />
				</v-tab-item>
				<v-tab-item value="calendar" class="grey lighten-4">
					<staff-calendar :datadown="item" v-on:save="createItem" />
				</v-tab-item>
				<v-tab-item value="absence" class="grey lighten-4">
					<staff-absence :datadown="item" v-on:save="createItem" />
				</v-tab-item>
				<v-tab-item value="jobs" class="grey lighten-4">
					<staff-jobs :datadown="item" v-on:save="createItem" />
				</v-tab-item>
				<v-tab-item value="hotlistTasks" class="grey lighten-4">
					<staff-hotlist-tasks :datadown="item" v-on:save="createItem" />
				</v-tab-item>
			</v-tabs-items>
		</v-col>


        <v-dialog persistent v-model="inviteDialog" width="500px" height="400px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 secondary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mr-3 mb-1">
                        <v-icon class="white--text">icons8-email-send</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Send Invitation to Register</strong>
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeInviteDialog" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 pa-3 body-2 font-weight-bold grey--text">    
                    This will send an invitation to the below email for the user to register with the system. Once they have registered, they will be able to login to the system.

                    <div class="text-center grey--text text--darken-2 py-2">{{ item.userEmail }}</div>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-0">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="closeInviteDialog()"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                            <v-btn class="primary white--text font-weight-bold ml-2" depressed @click="sendInvite"> <v-icon class="mr-2">icons8-save</v-icon><span>Confirm</span> </v-btn>
                        </v-col>
                    </v-col>
                </v-card-actions>
            </div>
        </v-dialog>
	</v-row>
</template>

<script>
	import { mapGetters } from "vuex";
	import staffDetails from "@/views/staffDirectory/staffDetails.vue";
	import staffTraining from "@/views/staffDirectory/staffTraining.vue";
	import staffLocation from "@/views/staffDirectory/staffLocation.vue";
	import staffServices from "@/views/staffDirectory/staffServices.vue";
	import staffCalendar from "@/views/staffDirectory/staffCalendar.vue";
	import staffJobs from "@/views/staffDirectory/staffJobs.vue";
	import staffAbsence from "@/views/staffDirectory/staffAbsence.vue";
	import staffHotlistTasks from "@/views/staffDirectory/staffHotlistTasks.vue";
	import schema from "@/schema.js";
    const firebase = require('@/firebase/firebase-config.js'); // Import Firebase Config for Firebase Object Access
	export default {
		// * COMPONENTS
		components: {
			"staff-details": staffDetails,
			"staff-training": staffTraining,
			"staff-location": staffLocation,
			"staff-services": staffServices,
			"staff-calendar": staffCalendar,
			"staff-jobs": staffJobs,
			"staff-absence": staffAbsence,
			"staff-hotlist-tasks": staffHotlistTasks,
		},
		// * PROPS
		props: {
			datadown: {
				type: Object,
				default: function () {
					return {};
				},
			},
			listshrunk: {
				type: Boolean,
				default: false,
			},
		},
		// * DATA
		data: () => ({
			key: "user",
			tab: "details",
			schema: null,
            firebaseSchema: null, 
			// Item
			item: {},
			itemNew: false,
			editMode: false,

			// Form items
			accountTypes: [],
			addressTypes: [],
			industries: [],


			// Validation
			rules: {},

			// Toggles
			addAddressToggle: false,
			addManualAddress: false,
			addOtherAddressToggle: false,
			addLeadStatusToggle: false,
			addOrgInfoToggle: false,
			addSocialInfoToggle: false,


            // invite 
            inviteDialog: false,
		}),
		// * COMPUTED
		computed: {
			...mapGetters({
				GET_taskBar: "GET_taskBar",
                GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
			}),
			computedFullName() {
				return this.item.contactFirstName + " " + this.item.contactLastName;
			},
		},
		// * METHODS
		methods: {
            openInviteDialog() {
                var t = this; 

                t.inviteDialog = true;
            },
            closeInviteDialog() {
                var t = this; 

                t.inviteDialog = false;
            },
            async sendInvite() {
                var t = this;

                this.REDIS_sendInviteLink(t.item.entityId)
                t.closeInviteDialog()
				this.MIX_alertBox({ color: "success", timeout: 2000, message: "Invite Sent", show: true });
            },
			// * INITIALISE ITEM
			initialiseItem() {
				let t = this;
				if (JSON.stringify(t.datadown) !== "{}") {
					this.readItem(t.datadown.entityId);
				} else {
					t.itemNew = true;
					t.editMode = true;
					t.item = { ...t.schema };
					t.item.entityId = t.MIX_generateId();
				}
			},
			// * VALIDATION RULES
			// TODO - RG - I think this needs to be moved to contactDetails.vue
			validateItem() {
				let t = this;
				t.rules = {
					// GENERAL DETAILS
					orgName: [(v) => !!v || "Organisation Name Required"],
				};
				t.$nextTick(() => {
					if (t.$refs.form.validate()) {
						this.createItem();
						this.editMode = false;
					} else {
						this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
					}
				});
			},
            async checkUserInLocalStorage(email) {
                var t = this; 

                return new Promise(function(resolve, reject) {
                    t.$firebase.db
                        .collection('users')
                        .where('userEmail', '==', email)
                        .get()
                        .then(function(querySnapshot) {
                            var userUsed = false
                            if (querySnapshot.docs.length === 0) {
                                userUsed = false
                            } else {
                                userUsed = true
                            }
                            return resolve(userUsed)
                        })
                        .catch(function(error) {
                            return reject(error)
                        })
                })
            },
            async formatItem(item) {
                var t = this; 
                var userItem = {...item}
                
                var staffMember = {...t.firebaseSchema}

                staffMember.id = userItem.entityId
                staffMember.areasCovered = userItem.userAreas
                staffMember.contractHours = userItem.contractHours
                staffMember.financialAccess = userItem.financialAccess
                staffMember.authId = userItem.authId
                staffMember.NOKName = userItem.NOKName
                staffMember.NOKRelationship = userItem.NOKRelationship
                staffMember.NOKTellNo = userItem.NOKTellNo
                staffMember.lastClient.clientId = userItem.lastClientId
                staffMember.lastClient.clientName = ''
                staffMember.lastLocation.locationId = userItem.lastLocationId
                staffMember.lastLocation.locationName = ''
                staffMember.lastSite.siteId = userItem.lastSiteId
                staffMember.lastSite.siteName = ''

                staffMember.privacyPolicyConsent = userItem.privacyPolicyConsent
                staffMember.profilePicFileURL = userItem.profilePicFileURL
                staffMember.services = userItem.services
                staffMember.swappStatus = userItem.swappStatus
                staffMember.swappMethod = userItem.swappMethod
                staffMember.trainingCurrent = userItem.trainingCurrent

                staffMember.userAddress.userAddressLine1 = userItem.userAddressLine1
                staffMember.userAddress.userAddressLine2 = userItem.userAddressLine2
                staffMember.userAddress.userAddressLine3 = userItem.userAddressLine3
                staffMember.userAddress.userCounty = userItem.userAddressCounty
                staffMember.userAddress.userPostcode = userItem.userAddressPostcode
                staffMember.userAddress.userTown = userItem.userAddressTown

                staffMember.userAreas = userItem.userAreas
                staffMember.userCategory = userItem.userCategory
                staffMember.userDOB = userItem.userDOB
                staffMember.userEmail = userItem.userEmail
                staffMember.userGender = userItem.userGender
                staffMember.userJobTitle = userItem.userJobTitle
                staffMember.userLevel = userItem.userLevel
                staffMember.userRole = userItem.userRole

                staffMember.userName = userItem.userName
                staffMember.userStartDate = userItem.userStartDate
                staffMember.userStatus = userItem.userStatus
                staffMember.userTelephone = userItem.userTelephone
                staffMember.userType = userItem.userType
                staffMember.offlineStaffMember = userItem.offlineStaffMember
                staffMember.offlineStaffMemberReason = userItem.offlineStaffMemberReason
                staffMember.primaryLineManager = userItem.primaryLineManagerId
                staffMember.secondaryLineManager = userItem.secondaryLineManagerId

                staffMember.createdUserData.userId = userItem.createdUserId 
                staffMember.createdUserData.userName = ''
                staffMember.createdUserData.userEmail = ''
                staffMember.createdDateTime = userItem.createdDateTime 

                staffMember.deletedUserData.userId = userItem.deletedUserId 
                staffMember.deletedUserData.userName = ''
                staffMember.deletedUserData.userEmail = ''
                staffMember.delete = userItem.deleted

                staffMember.modifiedUserData.userId = userItem.modifiedUserId 
                staffMember.modifiedUserData.userName = ''
                staffMember.modifiedUserData.userEmail = ''
                staffMember.modifiedDateTime = userItem.modifiedDateTime 
                
                return staffMember
            },
            async saveStaff(item) {
                var t = this; 

                console.log('saveStaff', JSON.stringify(item, null, 2))

                item.modifiedUserId = t.GET_FIREBASE_currentUser.entityId

                let result = await this.REDIS_update(t.key, item.entityId, item);
                if (result.success) {

                    var staffMember = await t.formatItem(item)

                    var updateDocumentResult = await this.MIX_updateDocument('users', staffMember);
                    if (updateDocumentResult.code === 1) {
					    t.MIX_alertBox({ color: "success", timeout: 2000, message: "User Saved", show: true });
					    this.readItem(item.entityId);

                    } else {
					    t.MIX_alertBox({ color: "error", timeout: 2000, message: "Error Saving User: " + updateDocumentResult.error, show: true });
                    }
                }
            },
            async createStaff(item) {
                var t = this; 

                item.createdUserId = t.GET_FIREBASE_currentUser.entityId

				var userExists = await this.checkUserInLocalStorage(item.userEmail);

                // if user doesnt exist, create redis user
				if (userExists !== true) {
                    let result = await this.REDIS_create(t.key, item)

                    if (result.success) {
                        var staffMember = await t.formatItem(result.data)

                        var createDocumentResult = await t.MIX_createDocument('users', staffMember); // Create Document Within Firestore based on Form
                        if (createDocumentResult.code === 1) {
					        t.MIX_alertBox({ color: "success", timeout: 2000, message: "User Created", show: true });
                        } else {
					        t.MIX_alertBox({ color: "error", timeout: 2000, message: "Error Creating User: " + createDocumentResult.error, show: true });
                        }
                    } else {
					    t.MIX_alertBox({ color: "error", timeout: 2000, message: "Error Creating User: " + result.error, show: true });
                    }
                } else {
					t.MIX_alertBox({ color: "error", timeout: 2000, message: "This User Already Exists", show: true });
				}
            },
			// * CREATE ITEM
			async createItem(item) {
				let t = this;
                
                item.createdUserId = t.GET_FIREBASE_currentUser.entityId;
                let result = await this.REDIS_create(t.key, item);
				if (result.success) {
					t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
					t.readItem(item.entityId);
					t.$emit("refreshitems");
				}
			},
			// * READ ITEM
			async readItem(itemId) {
				let t = this;
				let result = await t.REDIS_read(t.key, itemId);
				if (result.success) {
					this.item = result.data;
					t.itemNew = false;
					t.loading = false;
				}
			},
			// * DESTROY ITEM
			async destroyItem() {
				let result = await this.MIX_destroyItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// * DELETE ITEM
			async deleteItem() {
				let result = await this.MIX_deleteItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// * RESTORE ITEM
			async restoreItem() {
				let result = await this.MIX_restoreItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// Close Item
			closeItem() {
				this.$emit("closeitem");
			},
			cancelItem() {
				this.readItem(this.item.id);
			}
		},
		// * WATCH
		watch: {
			// Datadown Changes
			datadown() {
				this.initialiseItem();
			},
		},
		// * CREATED
		async created() {
			let t = this;
			t.loading = true;
			t.schema = schema.user;
            t.firebaseSchema = schema.firebaseUser
			t.initialiseItem();

		},
		// * MOUNTED
		async mounted() {},
	};
</script>

<style scoped>
	.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
	.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
	.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
		height: 22px;
	}
</style>
