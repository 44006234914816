<template>
    <v-chart class="chart" :option="option" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
use([CanvasRenderer, PieChart, TitleComponent, TooltipComponent, LegendComponent]);

export default {
    name: "PieChart",
    components: {
        VChart,
    },
    provide: {
        [THEME_KEY]: "light",
    },
    props: {
        seriesdata: {
            type: Array,
            default: () => [],
        },
        xaxisdata: {
            type: Array,
            default: () => [],
        },
        color: {
            type: String,
        },
        seriestext: {
            type: String,
        },
    },
    data: () => ({
        colors: ['#308CCB', '#4f4f4f', '#ffffff'],
    }),
    computed: {
        option() {
            return {
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b} : {c} ({d}%)",
                },
                legend: {
                    orient: 'vertical', 
                    left: 'left',
                    formatter: function (value) {
                        // console.log('value = ' + JSON.stringify(value, null, 2))
                        
                        const twoWords = value.match(/[A-Z][a-z]+|[a-z]+/g);
                        const firstWordUppercased = twoWords[0].charAt(0).toUpperCase() + twoWords[0].slice(1);
                        const result = firstWordUppercased + " " + twoWords[1];

                        return result;
                    },
                    textStyle: {
                        color: '#ffffff',
                        fontSize: 16,
                    },
                },
                series: [
                    {
                        name: this.seriestext,
                        type: "pie",
                        radius: "100%",
                        color: ['#308CCB', '#ffffff' ,'#4f4f4f'],
                        data: this.seriesdata,
                        label: {
                            position: 'inner',
                            formatter: (c) => c.value > 0 ? c.value : '',
                            fontSize: 24,
                            
                        },
                        labelLine: {
                            show: false
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 0,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            };
        },
    },
    methods: {
        LightenColor(color, percent) {
            var num = parseInt(color, 16),
                amt = Math.round(2.55 * percent),
                R = (num >> 16) + amt,
                B = ((num >> 8) & 0x00ff) + amt,
                G = (num & 0x0000ff) + amt;
            return (0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 + (G < 255 ? (G < 1 ? 0 : G) : 255)).toString(16).slice(1);
        },
    },
    created() {
        this.colors = [];
        // let color = this.color.slice(1, 10)
        // let seriesLength = this.seriesdata.length
        // for (let i = 0; i < seriesLength; i++) {
        //     let newColour = this.LightenColor(color, i*5)
        //     this.colors.push('#'+newColour);
        // }
    },
};
</script>
<style scoped>
.chart {
    width: 100% !important;
    height: 320px !important;
    margin-top: 20px;
    margin-left: 20px;
    /* height: 100vh; */
}
</style>