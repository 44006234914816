<template>
    <div class="" 
    style="padding: 0px !important; margin: 0px !important; width: 100% !important" 
    :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>

                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <!-- //! TODO - ACTUALLY ADD SERVICE  -->
                    <v-btn text class="primary white--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">icons8-duplicate</v-icon>Add</v-btn>
                    <!-- <v-btn text class="primary white--text" v-if="editmode" @click="saveItem()"><v-icon class="mr-2">oomph-save</v-icon>Save</v-btn> -->
                </div>
            </v-col>
        </v-row>
        
        <v-row class="apptext--text" dense style="z-index: 0 !important">
            <v-col cols="12" class="d-flex pa-2 align-center">
                <v-row style="width: 100% !important" class="px-2" no-gutters>
                    <!-- //! TODO - ADD FILTER, AND FILTER BY USER  -->
                    <services-list />
                </v-row>
            </v-col>
        </v-row>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
// import schema from '@/schema.js';
import servicesList from '@/views/services/serviceList.vue'

export default {
	components: {
		"services-list": servicesList,
    },
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        // activeitem: {
        //     Type: Object,
        //     Default: null,
        // },
    },
    // * DATA
    data: () => ({
        key: 'action',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,
        // dropdowns
        packageTypes: [],
        billingCycles: [],
        packageStatus: [],
        selectedPackageIcon: {
            name: '',
            icon: ''
        },  
        months: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],

        // rules
        packageCustomerDescriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],
        packageInternalDescriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],

        icons: [
            {
                name: 'On Demand',
                icon: 'oomph-video-playlist'
            },
            {
                name: 'Training',
                icon: 'oomph-literature'
            },
            {
                name: 'Exercises',
                icon: 'oomph-dumbbell'
            },
            {
                name: 'Activities',
                icon: 'oomph-list'
            },
            {
                name: 'Assessment',
                icon: 'oomph-clipboard-list'
            },
            {
                name: 'Wellbeing',
                icon: 'oomph-lotus'
            }
        ],

        // new address object
        item: {},
        loading: false,
        rules: {},

    }),
    computed: {  
    },
    methods: {
        initialise() {
            this.item = { ...this.datadown };
            //console.log('item', JSON.stringify(this.item, null, 2));
            let iconResult = this.icons.find(icon => icon.icon === this.item.packageIcon);
            if (iconResult !== undefined) {
                this.selectedPackageIcon = iconResult;
            }
            if (this.item.packageName !== '') {
                this.itemNew = false;
                this.editmode = false;
            } else {
                this.itemNew = true;
                this.editmode = true;
            }
        },
        updateItem() {
            this.$emit('update', this.item);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {
            this.$emit('save', this.item);
            this.editmode = false;
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        // activeitem: {
        //     handler: function () {
        //         let t = this;
        //         t.item = { ...t.activeitem };
        //     }, deep: true
        // },
        selectedPackageIcon: {
            handler: function () {
                this.item.packageIcon = this.selectedPackageIcon.icon;
            }, deep: true
        },

    },
    // * CREATED
    async created() {
        let t = this;
        // t.schema = schema.package;
        // t.loading = true
        // t.initialise();


        // let packageCategories = await t.MIX_redisReadSet('set:packageCategory', false);
        // if (packageCategories.code === 1) {
        //     t.packageCategories = packageCategories.data;
        // }
        // let packageStatus = await t.MIX_redisReadSet('set:packageStatus', false);
        // if (packageStatus.code === 1) {
        //     t.packageStatus = packageStatus.data;
        // }
        // let billingCycles = await t.MIX_redisReadSet('set:billingCycles', false);
        // if (billingCycles.code === 1) {
        //     t.billingCycles = billingCycles.data;
        // }
        // t.loading = false
    },
    mounted() {},
};
</script>