<template>
    <v-row fluid fill-height class="d-flex flex-column align-start" style="height: 100% !important" no-gutters>
    <!-- <v-container fluid fill-height class="d-flex flex-column align-start appbackground pa-0 ma-0"> -->
		<v-col class="align-center pa-4 ma-0" cols="12">

            <v-row class="fill-height d-flex align-center " style="height: 43vh !important"> 
                <v-col class="fill-height pa-0 pt-3 pr-0 " cols="5">
                    <v-card color="secondary" flat class="fill-height" style="width: 36.5vw !important;">
                        <v-row class="align-start px-4 pb-0">
                            <v-col cols="8" class="pb-0">
                                <div v-if="$vuetify.breakpoint.xlOnly" class="white--text text-h5 align-start font-weight-regular">TRAINING PROGRAMME</div>
                                <div v-else class="white--text pt-0 text-h6 align-end font-weight-regular">TRAINING PROGRAMME</div>
                            </v-col>
                        </v-row>
                        <v-row class="pr-3 pl-1 pt-0 d-flex justify-start align-center">
                            <pie-chart :seriesdata="userTraining" :color="$vuetify.theme.themes.light.primary" seriestext="Training" />
                        </v-row>    
                    </v-card>
                </v-col>

                <v-col class="fill-height pt-0 pa-0" cols="1">
                    <v-card color="transparent" class="d-flex fill-height pb-3 flex-column justify-end align-end" flat style="height: 100% !important">
                        <v-row class="pl-1 pr-3 pt-3 pb-3"> 
                            <v-card color="secondary" class="mb-2" flat style="width: 11.9vw !important; min-height: 48% !important">
                                <div style="height: 100% !important" class="d-flex flex-column justify-space-between pt-4 px-3 py-4">
                                    <div v-if="$vuetify.breakpoint.xlOnly" class="text-end font-weight-bold text-h7 white--text">THIS WEEK</div>
                                    <div class="align-center text-center justify-center" v-if="statsLoading">
                                        <v-progress-circular :width="10" :size="80" indeterminate color="white"></v-progress-circular>
                                        <!-- <div class="mt-2 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading...</div> -->
                                    </div>
                                    <div v-if="!statsLoading" class="white--text text-h1 text-center font-weight-bold"><number ref="numberExample" :to="stats.holidayWeek" :duration="2" /></div>

                                    <div :class="$vuetify.breakpoint.xlOnly ? 'text-h6' : 'body-1'" class="white--text text-h5 text-center font-weight-regular">HOLIDAY </div>
                                </div>
                            </v-card>



                            
                            <v-spacer />
                            <v-card color="secondary" class="mb-2" flat style="width: 11.9vw !important; min-height: 50% !important">
                                <div style="height: 100% !important" class="d-flex flex-column justify-space-between pt-4 px-3 py-4">
                                    <div v-if="$vuetify.breakpoint.xlOnly" class="text-end font-weight-bold text-h7 white--text">NEXT WEEK</div>
                                    <div class="align-center text-center justify-center" v-if="statsLoading">
                                        <v-progress-circular :width="10" :size="80" indeterminate color="white"></v-progress-circular>
                                        <!-- <div class="mt-2 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading...</div> -->
                                    </div>
                                    <div v-if="!statsLoading" class="white--text text-h1 text-center font-weight-bold"><number ref="numberExample" :to="stats.holidayNextWeek" :duration="2" /></div> 
                                    <div :class="$vuetify.breakpoint.xlOnly ? 'text-h6' : 'body-1'" class="white--text text-h5 text-center font-weight-regular">HOLIDAY </div>
                                </div>
                            </v-card>

                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="6" class="fill-height pl-3 pa-0 pt-3">
                    <v-card color="primary" flat style="width: 50vw !important; height: 100% !important" class="pb-0 fill-height align-end">
                        <v-row class="align-center px-4 ">
                            <v-col cols="10">
                                <div :class="$vuetify.breakpoint.xlOnly ? 'text-h5' : 'text-h6'" class="white--text align-end font-weight-regular">CLIENT ALLOCATION</div>
                                <div :class="$vuetify.breakpoint.xlOnly ? 'text-h6' : 'body-1'" class="white--text align-start font-weight-bold">WEEKLY</div>
                            </v-col>
                            <v-col cols="2" class="d-flex pt-0 justify-end">
                                <v-icon class="white--text" :size="$vuetify.breakpoint.xlOnly ? '60' : '40'">icons8-business-building</v-icon>
                            </v-col>
                        </v-row>
                        <v-row v-if="clientLoading" style="height: 30vh !important" class="pr-3 pl-1 pt-0 d-flex justify-center align-center">
                            <div class="ml-3 align-center justify-center" v-if="clientLoading">
                                <v-progress-circular :width="10" :size="80" indeterminate color="white"></v-progress-circular>
                                <div class="mt-2 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading...</div>
                            </div>
                        </v-row>

                        <div v-if="!clientLoading">
                            <v-row class="align-center pb-2">
                                <v-col cols="6" class="py-1 px-0 pl-7 ma-0">
                                    <div :class="$vuetify.breakpoint.xlOnly ? 'text-h6' : 'body-1'" class="white--text font-weight-bold">Client</div>
                                </v-col>
                                <v-col cols="3" class="py-1 px-0 pr-7 ma-0">
                                    <div :class="$vuetify.breakpoint.xlOnly ? 'text-h6' : 'body-1'" class="white--text font-weight-bold text-end">Allocated Hours</div>
                                </v-col>
                                <v-col cols="3" class="py-1 px-0 pr-7 ma-0">
                                    <div :class="$vuetify.breakpoint.xlOnly ? 'text-h6' : 'body-1'" class="white--text font-weight-bold text-end">Contracted Hours</div>
                                </v-col>
                            </v-row>
                        </div>
                        <div v-if="!clientLoading">
                            <div v-for="(client, index) in clientWeekly" :key="index">
                                <v-row class="align-center">
                                    <!-- <pre>{{ client }}</pre> -->
                                    <v-col cols="6" class="py-1 px-0 pl-7 ma-0">
                                        <div v-if="$vuetify.breakpoint.xlOnly" class="white--text text-h6">{{ client['Client Name'] }}</div>
                                        <div v-else class="white--text body-1">{{ client['Client Name'] }}</div>
                                    </v-col>
                                    <v-col cols="3" class="py-1 align-end px-0 pr-7 ma-0">
                                        <div v-if="$vuetify.breakpoint.xlOnly" class="white--text text-end text-h6">{{ client['Allocated Hours'] }}</div>
                                        <div v-else class="white--text text-end body-1">{{ client['Allocated Hours'] }}</div>
                                    </v-col>
                                    <v-col cols="3" class="py-1 align-end px-0 pr-7 ma-0">
                                        <div v-if="$vuetify.breakpoint.xlOnly" class="white--text text-end text-h6">{{ client['Contracted Hours'] }}</div>
                                        <div v-else class="white--text text-end body-1">{{ client['Contracted Hours'] }}</div>
                                    </v-col>
                                </v-row>
                                <v-divider class="mx-4 my-2 lightgrey" />
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>



            <v-row class="fill-height d-flex align-center " style="height: 42.5vh !important"> 
                <v-col class="fill-height pa-0 pr-3 pt-3" cols="3">
                    <v-card color="transparent" class="d-flex fill-height pb-3 flex-column justify-end" flat style="width: 25vw !important; height: 100% !important">
                        <v-row class="pl-3 pr-3 "> 
                            <v-card color="secondary" class="mb-2" flat style="width: 11.6vw !important; min-height: 50% !important">
                                <div style="height: 100% !important" class="d-flex flex-column justify-space-between pt-4 px-3 py-4">
                                    <div v-if="$vuetify.breakpoint.xlOnly" class="text-end font-weight-bold text-h7 white--text">THIS WEEK</div>
                                    <div class="align-center text-center justify-center" v-if="statsLoading">
                                        <v-progress-circular :width="10" :size="80" indeterminate color="white"></v-progress-circular>
                                        <!-- <div class="mt-2 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading...</div> -->
                                    </div>
                                    <div v-if="!statsLoading" class="white--text text-h1 text-center font-weight-bold"><number ref="numberExample" :to="stats.sicknessWeek" :duration="2" /></div> 
                                    <div :class="$vuetify.breakpoint.xlOnly ? 'text-h6' : 'body-1'" class="white--text text-h5 text-center font-weight-regular">SICKNESS </div>
                                </div>
                            </v-card>

                            <v-spacer />

                            <v-card color="secondary" class="mb-2" flat style="width: 11.6vw !important; min-height: 50% !important">
                                <div style="height: 100% !important" class="d-flex flex-column justify-space-between pt-4 px-3 py-4">
                                    <div v-if="$vuetify.breakpoint.xlOnly" class="text-end font-weight-bold text-h7 white--text">NEXT WEEK</div>
                                    <div class="align-center text-center justify-center" v-if="statsLoading">
                                        <v-progress-circular :width="10" :size="80" indeterminate color="white"></v-progress-circular>
                                        <!-- <div class="mt-2 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading...</div> -->
                                    </div>
                                    <div v-if="!statsLoading" class="white--text text-h1 text-center font-weight-bold"><number ref="numberExample" :to="stats.sicknessNextWeek" :duration="2" /></div> 
                                    <div :class="$vuetify.breakpoint.xlOnly ? 'text-h6' : 'body-1'" class="white--text text-h5 text-center font-weight-regular">SICKNESS </div>
                                </div>
                            </v-card>

                        </v-row>

                    
                        <v-card color="secondary" flat style="width: 25vw !important; min-height: 50% !important" class="mt-3 justify-end">
                            <v-row class="align-center pt-2 px-4">
                                <v-col cols="8">
                                    <div v-if="$vuetify.breakpoint.xlOnly" class="white--text text-h5 align-end font-weight-regular">CLIENTS</div><div v-else class="white--text text-h6 align-end font-weight-regular">CLIENTS</div>
                                    <div v-if="$vuetify.breakpoint.xlOnly" class="white--text text-h6 align-start font-weight-bold">TOTAL HOURS</div><div v-else class="white--text body-1 align-start font-weight-bold">TOTAL HOURS</div>
                                </v-col>
                                <v-col cols="4" class="d-flex justify-end">
                                    <v-icon v-if="$vuetify.breakpoint.xlOnly" class="white--text" size="60">icons8-business-building</v-icon>
                                    <v-icon v-else class="white--text" size="40">icons8-business-building</v-icon>
                                </v-col>
                            </v-row>
                            <v-row v-if="clientHoursLoading" style="height: 6vh !important" class="pr-3 pl-1 pt-0 d-flex justify-center align-center">
                                <div class="ml-3 align-center justify-center" v-if="clientHoursLoading">
                                    <v-progress-circular :width="10" :size="60" indeterminate color="white"></v-progress-circular>
                                    <div class="mt-2 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading...</div>
                                </div>
                            </v-row>
                            <v-row v-if="$vuetify.breakpoint.xlOnly && !clientHoursLoading" class="pt-3 px-7">
                                <div>
                                    <div class="white--text text-start text-h3 align-end font-weight-bold"><number ref="numberExample" :to="clientStats.contracted" :duration="0.5" /></div>
                                    <div class="white--text text-start text-h6 align-start font-weight-bold">CONTRACTED</div>
                                </div>
                                <v-spacer />
                                <div>
                                    <div class="white--text text-h3 text-end font-weight-bold"><number ref="numberExample" :to="clientStats.allocated" :duration="0.5" /></div>
                                    <div class="white--text text-h6 text-end font-weight-bold">ALLOCATED</div>
                                </div>
                            </v-row>
                            <v-row v-else-if="!clientHoursLoading" class="pt-2 px-7">
                                <div>
                                    <div class="white--text text-start text-h4 align-end font-weight-bold"><number ref="numberExample" :to="clientStats.contracted" :duration="2" /></div>
                                    <div class="white--text text-start text-h7 align-start font-weight-bold">CONTRACTED</div>
                                </div>
                                <v-spacer />
                                <div>
                                    <div class="white--text text-h4 text-end font-weight-bold"><number ref="numberExample" :to="clientStats.allocated" :duration="2" /></div>
                                    <div class="white--text text-h7 text-end font-weight-bold">ALLOCATED</div>
                                </div>
                            </v-row>
                        </v-card>

                    </v-card>
                </v-col>
                <v-col class="fill-height pt-3 pa-0  justify-center" cols="3">
                    <v-card color="primary" flat style="width: 25vw !important;" class="fill-height align-end">
                        <v-row class="align-start px-4">
                            <v-col cols="8">
                                <div v-if="$vuetify.breakpoint.xlOnly" class="white--text text-h5 align-end font-weight-regular">JOB HOURS</div><div v-else class="white--text text-h6 align-end font-weight-regular">JOB HOURS</div>
                                <div v-if="$vuetify.breakpoint.xlOnly" class="white--text text-h6 align-start font-weight-bold">WEEKLY</div><div v-else class="white--text body-1 align-start font-weight-bold">WEEKLY</div>
                            </v-col>
                            <v-col cols="4" class="d-flex justify-end">
                                <v-icon v-if="$vuetify.breakpoint.xlOnly" class="white--text" size="60">icons8-clock</v-icon>
                                <v-icon v-else class="white--text" size="40">icons8-clock</v-icon>
                            </v-col>
                        </v-row>
                        <v-row v-if="jobHoursLoading" style="height: 30vh !important" class="pr-3 pl-1 pt-0 d-flex justify-center align-center">
                            <div class="ml-3 align-center justify-center" v-if="jobHoursLoading">
                                <v-progress-circular :width="10" :size="80" indeterminate color="white"></v-progress-circular>
                                <div class="mt-2 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading...</div>
                            </div>
                        </v-row>

                        <v-card-text v-if="!jobHoursLoading" class="d-flex align-start justify-start flex-column" style="width: 100% !important">
                                <!-- <apexchart class="pa-0 ma-0" type="bar" :width="barWidth" :options="barOptions" :series="barSeries" ></apexchart> -->
                            <gauge-chart :seriesdata="gaugeData" :color="$vuetify.theme.themes.light.primary" seriestext="Downloads" />
                        </v-card-text>
                        <v-row v-if="$vuetify.breakpoint.xlOnly && !jobHoursLoading" class="px-6 align-center justify-center">
                            <div class="pt-8">
                                <div class="secondary--text text-start text-h3 align-end font-weight-bold">
                                    <number ref="numberExample" :to="jobStats.allocatedTotalHours" :duration="0.5" />
                                </div>
                                <div class="secondary--text text-start text-h6 align-start font-weight-bold">
                                    ALLOCATED
                                </div>
                            </div>
                            <v-spacer />
                            <div class="pt-8">
                                <div class="white--text text-h3 text-end font-weight-bold">
                                    <number ref="numberExample" :to="jobStats.unallocatedTotalHours" :duration="0.5" />
                                </div>
                                <div class="white--text text-h6 text-end font-weight-bold">
                                    UNALLOCATED
                                </div>
                            </div>
                        </v-row>
                        <v-row v-else-if="!jobHoursLoading" class="px-6 align-center justify-center">
                            <div class="pt-6">
                                <div class="secondary--text text-start text-h4 align-end font-weight-bold">
                                    <number ref="numberExample" :to="jobStats.allocatedTotalHours" :duration="0.5" />
                                </div>
                                <div class="secondary--text text-start text-h6 align-start font-weight-bold">
                                    ALLOCATED
                                </div>
                            </div>
                            <v-spacer />
                            <div class="pt-6">
                                <div class="white--text text-h4 text-end font-weight-bold">
                                    <number ref="numberExample" :to="jobStats.unallocatedTotalHours" :duration="0.5" />
                                </div>
                                <div class="white--text text-h6 text-end font-weight-bold">
                                    UNALLOCATED
                                </div>
                            </div>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="6" class="fill-height pa-0  pt-3 pl-3">
                    <v-card color="primary" flat style="width: 50vw !important; height: 100% !important" class="pb-0 fill-height align-end">
                        <v-row class="align-center px-4">
                            <v-col cols="10">
                                <div v-if="$vuetify.breakpoint.xlOnly" class="white--text text-h5 align-end font-weight-regular">SITE ALLOCATION</div><div v-else class="white--text text-h6 align-end font-weight-regular">SITE ALLOCATION</div>
                                <div v-if="$vuetify.breakpoint.xlOnly" class="white--text text-h6 align-start font-weight-bold">WEEKLY</div><div v-else class="white--text body-1 align-start font-weight-bold">WEEKLY</div>
                            </v-col>
                            <v-col cols="2" class="d-flex pt-0 justify-end">
                                <v-icon v-if="$vuetify.breakpoint.xlOnly" class="white--text" size="60">icons8-business-building</v-icon>
                                <v-icon v-else class="white--text" size="40">icons8-business-building</v-icon>
                            </v-col>
                        </v-row>

                        <v-row v-if="siteLoading" style="height: 30vh !important" class="pr-3 pl-1 pt-0 d-flex justify-center align-center">
                            <div class="ml-3 align-center justify-center" v-if="siteLoading">
                                <v-progress-circular :width="10" :size="80" indeterminate color="white"></v-progress-circular>
                                <div class="mt-2 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading...</div>
                            </div>
                        </v-row>

                        <div v-if="!siteLoading">
                            <v-row class="align-center pb-2">
                                <v-col cols="6" class="py-1 px-0 pl-7 ma-0">
                                    <div v-if="$vuetify.breakpoint.xlOnly" class="white--text font-weight-bold text-h6">Sites</div>
                                    <div v-else class="white--text font-weight-bold body-1">Sites</div>
                                </v-col>
                                <v-col cols="3" class="py-1 px-0 pr-7 ma-0">
                                    <div v-if="$vuetify.breakpoint.xlOnly" class="white--text font-weight-bold text-end text-h6">Allocated Hours</div>
                                    <div v-else class="white--text font-weight-bold text-end body-1">Allocated Hours</div>
                                </v-col>
                                <v-col cols="3" class="py-1 px-0 pr-7 ma-0">
                                    <div v-if="$vuetify.breakpoint.xlOnly" class="white--text font-weight-bold text-end text-h6">Contracted Hours</div>
                                    <div v-else class="white--text font-weight-bold text-end body-1">Contracted Hours</div>
                                </v-col>
                            </v-row>
                        </div>
                        <div v-if="!siteLoading">
                            <div v-for="(site, index) in sitesWeekly" :key="index">
                                <v-row class="align-center">
                                    <!-- <pre>{{ client }}</pre> -->
                                    <v-col cols="6" class="py-1 px-0 pl-7 ma-0">
                                        <div v-if="$vuetify.breakpoint.xlOnly" class="white--text text-h6">{{ site['Site Name'] }}</div>
                                        <div v-else class="white--text body-1">{{ site['Site Name'] }}</div>
                                    </v-col>
                                    <v-col cols="3" class="py-1 align-end px-0 pr-7 ma-0">
                                        <div v-if="$vuetify.breakpoint.xlOnly" class="white--text text-end text-h6">{{ site['Allocated Hours'] }}</div>
                                        <div v-else class="white--text text-end body-1">{{ site['Allocated Hours'] }}</div>
                                    </v-col>
                                    <v-col cols="3" class="py-1 align-end px-0 pr-7 ma-0">
                                        <div v-if="$vuetify.breakpoint.xlOnly" class="white--text text-end text-h6">{{ site['Contracted Hours'] }}</div>
                                        <div v-else class="white--text text-end body-1">{{ site['Contracted Hours'] }}</div>
                                    </v-col>
                                </v-row>
                                <v-divider class="mx-4 my-2 lightgrey" />
                            </div>
                        </div>
                    </v-card>
                </v-col>
                
            </v-row>
		
        </v-col>

    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import BarChart from "@/components/echarts/BarChart.vue";
import GaugeChart from "@/components/echarts/GaugeChart.vue";
import PieChart from "@/components/echarts/PieChart.vue";
export default {
    name: 'Services',
    components: {
        BarChart: BarChart,
        GaugeChart: GaugeChart,
        PieChart: PieChart,
    },
    data: () => ({
        today: '',
        todayTime: '',

        dashboardData: [],
        model: '',


        hoursProjected: [],

        jobStats: {
            totalHours: 0,
            allocatedTotalHours: 0,
            unallocatedTotalHours: 0
        },
        stats: {
            holidayWeek: 0,
            holidayNextWeek: 0,
            sicknessWeek: 0,
            sicknessNextWeek: 0,
            allocated: 0,
            unallocated: 0
        },

        clientStats: {
            contracted: 0, 
            allocated: 0
        },

        gaugeData: 0,

        clientWeekly: [],
        sitesWeekly: [],

        interval: null,
        timeInterval: null,

        userTraining: [],

        hoursProjectedLoading: true,
        statsLoading: true,
        clientLoading: true,
        clientHoursLoading: true,
        jobHoursLoading: true,
        siteLoading: true
    }),
    computed: {
        ...mapGetters({
            // GET_FIREBASE_userAuth: 'GET_FIREBASE_userAuth',
        }),
    },
    methods: {

        getNow: function() {
            this.today = moment().tz('Europe/London').format('ddd DD MMM YYYY')
            this.todayTime = moment().tz('Europe/London').format('HH:mm')
        },

        async getHoursProjected() {
            var t = this
            this.hoursProjectedLoading = true
			let result = await t.REDIS_projectedHours();
			if (result.success) {
				var items = result.data;
                
                let arrResult = Object.keys(items).map(k => ({
                    name: k,
                    value: items[k].totalHours
                }));

                // console.log('hours projected = ' + JSON.stringify(arrResult, null, 2))

                t.hoursProjected = arrResult
                t.hoursProjectedLoading = false
			}
        },

        async getTraining() {
            var t = this; 

            let result = await t.REDIS_getStaffTraining() 

			if (result.success) {
				var items = result.data; 
                // console.log('items = ' + JSON.stringify(items, null, 2))
                let arrResult = Object.keys(items).map(k => ({
                    name: k,
                    value: items[k]
                }));
                t.userTraining = arrResult
                
            }
        },

        async getClientAllocation() {
            var t = this
            var period = 'week'
			let result = await t.REDIS_clientAllocation(period);

			if (result.success) {
				var items = result.data;
                // console.log('client allocation = ' + JSON.stringify(items, null, 2))

                // t.clientStats.contracted = items.statistics.contractedHoursTotal
                // t.clientStats.allocated = items.statistics.allocatedHoursTotal
                // items.sort((a, b) => a['Contracted Hours'].localeCompare(b['Contracted Hours']))
                t.clientWeekly = items
			}
            this.clientLoading = false
        },

        // gets client contracted v allocated stats
        async getClientStats() {
            var t = this;
            var period = 'week'
            let result = await t.REDIS_clientContractedAllocatedStats(period)
            
            if (result.success) {
                var items = result.data 

                // console.log('client stats = ' + JSON.stringify(items, null, 2))
                t.clientStats.contracted = items.contractedHoursTotal
                t.clientStats.allocated = items.allocatedHoursTotal
            }
            t.clientHoursLoading = false
        },

        

        async getStats() {
            var t = this
            var startOfWeek = t.$moment().startOf('week').add(1, 'days').format('X')
            var endOfWeek = t.$moment().endOf('week').add(1, 'days').format('X')
            // console.log('start of week = ' + startOfWeek)
            // console.log('end of week = ' + endOfWeek)

            var startOfNextWeek = t.$moment().startOf('week').add(8, 'days').format('X')
            var endOfNextWeek = t.$moment().endOf('week').add(8, 'days').format('X')
            // console.log('start of next week = ' + startOfNextWeek)
            // console.log('end of next week = ' + endOfNextWeek)


            let holidayWeek = await this.REDIS_countAbsenceHours(`@deleted:{false} @absenceDate:[${startOfWeek} ${endOfWeek}] -@absenceType:{'Sickness' | 'Unauthorised Absense'}`)
            if (holidayWeek.success) {
                // console.log('holiday week = ' + JSON.stringify(holidayWeek, null, 2))
                t.stats.holidayWeek = holidayWeek.data
            }

            let holidayNextWeek = await this.REDIS_countAbsenceHours(`@deleted:{false} @absenceDate:[${startOfNextWeek} ${endOfNextWeek}] -@absenceType:{'Sickness' | 'Unauthorised Absense'}`)
            if (holidayNextWeek.success) {
                t.stats.holidayNextWeek = holidayNextWeek.data
            }

            let sicknessWeek = await this.REDIS_countAbsenceHours(`@deleted:{false} @absenceDate:[${startOfWeek} ${endOfWeek}] @absenceType:{'Sickness' | 'Unauthorised Absense'}`)
            if (sicknessWeek.success) {
                // console.log('sickness week = ' + JSON.stringify(sicknessWeek, null, 2))
                t.stats.sicknessWeek = sicknessWeek.data
            }

            let sicknessNextWeek = await this.REDIS_countAbsenceHours(`@deleted:{false} @absenceDate:[${startOfNextWeek} ${endOfNextWeek}] @absenceType:{'Sickness' | 'Unauthorised Absense'}`)
            if (sicknessNextWeek.success) {
                t.stats.sicknessNextWeek = sicknessNextWeek.data
            }
            this.statsLoading = false

        },

        
        async getClientHours() {
            var t = this
			let result = await t.REDIS_projectedHours();
			if (result.success) {
				var items = result.data;
			}
        },

        async getJobHours() {
            var t = this
			let result = await t.REDIS_countJobHours();
			if (result.success) {
				var items = result.data;

                // console.log('job stats = ' + JSON.stringify(items, null, 2))

                var perc = ( items.allocatedTotalHours / items.totalHours ) * 100

                // console.log('perc = ' + perc)

                t.gaugeData = perc

                // console.log('gaugeData = ' + JSON.stringify(t.gaugeData, null, 2))
                
                t.jobStats.totalHours = items.totalHours
                t.jobStats.allocatedTotalHours = items.allocatedTotalHours
                t.jobStats.unallocatedTotalHours = items.unallocatedTotalHours
			}
            this.jobHoursLoading = false
        },

        async getSiteAllocation() {
            var t = this
            var period = 'week'
			let result = await t.REDIS_siteAllocation(period);

			if (result.success) {
				var items = result.data;

                t.sitesWeekly = items
			}
            t.siteLoading = false
        },
    },
	watch: {
	},
    mounted() {
        this.getNow()

        // this.getHoursProjected()
        this.getClientAllocation()
        this.getStats()
        this.getClientStats()
        // this.getStaffSickness()
        // this.getClientHours()
        this.getJobHours()
        this.getSiteAllocation()
        this.getTraining()

		
        this.timeInterval = setInterval(() => { 
            this.getNow(); 
        }, 1000)
        this.interval = setInterval(() => { 
            console.log('refreshing hours data ' + this.$moment().format('HH:mm:ss'))
            // this.getHoursProjected()
            this.getClientAllocation()
            this.getStats()
            this.getClientStats()
            // this.getStaffSickness()
            // this.getClientHours()
            this.getJobHours()
            this.getSiteAllocation()
            this.getTraining()
        }, 60000)
        console.log('interval = ' + this.interval)

    },
    beforeDestroy() {
        clearInterval(this.interval)
        clearInterval(this.timeInterval)
    },
};
</script>

<style scoped>
    .v-card {
        border-radius: 10px !important
    }
</style>