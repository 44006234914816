// import { createApp } from 'vue'
import Vue from 'vue';
import App from './App.vue'
import router from '@/router.js';
import store from '@/store.js';
import axios from 'axios'
Vue.prototype.$axios = axios;
Vue.config.productionTip = false

import { doc, getDoc } from "firebase/firestore";
// import './assets/main.css'

// * VEUTIFY AND GLOBAL STYLES / ICONS / ANIMATIONS
import vuetify from '@/vuetify.js';
import "@/assets/css/styles.min.css"; // Import Icons8 Font Library CSS file
// import '../node_modules/animate.css/animate.min.css'; // import animate css

// * GLOBAL MIXIN
import mixin from './mixin.js';
Vue.use(mixin);

// Redis Mixin
import redis_mixin from '@/redis_mixin.js'
Vue.use(redis_mixin)

// * Vue Number Animation
import VueNumber from 'vue-number-animation'
Vue.use(VueNumber);


// * CSV Import Component
import VuePapaParse from 'vue-papa-parse'
Vue.use(VuePapaParse)

// scan qr code component
import qr_code_reader from '@/components/qr-code-reader/qr_code_reader_component.vue';
Vue.component('qrCodeReader', qr_code_reader);

//generate qr code mixin
import qr_code_reader_mixins from '@/components/qr-code-reader/qr_code_reader_mixin.js';
Vue.use(qr_code_reader_mixins);

// generate qr code component dialog
import qr_code_generator_dialog from '@/components/qr-code-generator/qr_code_generator_dialog_component.vue';
Vue.component('qrCodeGeneratorDialog', qr_code_generator_dialog);

// generate qr code component card
import qr_code_generator_card from '@/components/qr-code-generator/qr_code_generator_card_component.vue';
Vue.component('qrCodeGeneratorCard', qr_code_generator_card);

//generate qr code mixin
import qr_code_generator_mixins from '@/components/qr-code-generator/qr_code_generator_mixin.js';
Vue.use(qr_code_generator_mixins);


// * GLOBAL COMPONENTS
import AlertBox from "./components/alert/AlertBox.vue";
Vue.component("AlertBox", AlertBox);
import appbutton from './components/base/app_button.vue';
Vue.component('appbutton', appbutton)
import appcopyright from './components/base/app_copyright.vue';
Vue.component('appcopyright', appcopyright)
import app_text from './components/base/app_text.vue';
Vue.component('app-text', app_text)


// * FIREBASE INTEGRATION
require('./firebase/auth/firebase_import.js') // Require Firebase Components and Mixins
const firebase = require("@/firebase/firebase-config.js"); // Import Firebase Config for Firebase Object Access
Vue.prototype.$firebase = firebase; // Prototype Firebase for use throughout Project $firebase
import firebase_mixin from '@/firebase/firebase_mixin.js'; // Import Firebase Mixin for Global CRUD Functions
Vue.use(firebase_mixin); // User Firebase Mixin within Vue
import firebase_component from '@/firebase/firebase_component.vue';
Vue.component('firebase', firebase_component);


// * MOMENTJS TIMEZONE INTEGRATION
import moment from 'moment-timezone';
moment().tz("Europe/London").format();

import './registerServiceWorker'
Vue.prototype.$moment = moment;

import dayjs from 'dayjs'
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(timezone)
dayjs.tz.setDefault("Europe/London");
Vue.prototype.$dayjs = dayjs;

// Vue.prototype.$firebase = firebase;
// Vue.prototype.$moment = moment;


// function parseJwt (token) {
//     var base64Url = token.split('.')[1];
//     var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//     var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
//         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//     }).join(''));

//     return JSON.parse(jsonPayload);
// };

// firebase.auth.onAuthStateChanged(async (user) => {
//     if (user) {
//         await store.dispatch("firebase_auth_store/ACT_FIREBASE_userAuth", user);

// 		if (user !== null) {
// 			user.getIdToken(true).then(async (idToken) => {
//                 var token = parseJwt(idToken)
//                 //console.log('token = ' + JSON.stringify(token, null, 2))
// 				//var token = JSON.parse(new Buffer(idToken.split('.')[1], 'base64').toString('utf8'));
// 				// console.log("token" + JSON.stringify(token, null, 2))
// 				await store.dispatch("firebase_auth_store/ACT_FIREBASE_userAccessToken", token);
// 			})
// 		}
//         await store.dispatch("firebase_auth_store/ACT_FIREBASE_userAuth", user.multiFactor.user);
// // 
//         const docRef = doc(firebase.db, "users", user.multiFactor.user.uid);
//         const docSnap = await getDoc(docRef);
//         if (docSnap.exists()) {
//             // await store.dispatch("firebase_auth_store/ACT_FIREBASE_userAuth", docSnap.status);
//         }
//     }
// });


new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app');
