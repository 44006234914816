var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100% !important"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"item-class":_vm.itemRowBackground,"sortable":"","dense":"","headers":_vm.computedHeaders,"items":_vm.items,"server-items-length":_vm.itemsTotal,"options":_vm.datatableOptions,"loading":_vm.loading,"loading-text":"Loading Staff Members... Please wait"},on:{"update:options":function($event){_vm.datatableOptions=$event}},scopedSlots:_vm._u([(!_vm.userOpen)?{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"100% !important"}},[_c('div',{staticClass:"py-5 pl-3 body-2"},[_vm._v(" Showing "),_c('strong',[_vm._v(_vm._s(_vm.itemsFrom + 1))]),(_vm.itemsTo !== _vm.itemsTotal)?_c('span',[_vm._v(" to "),_c('strong',[_vm._v(_vm._s(_vm.itemsTo))])]):_vm._e(),_vm._v(" of "),_c('strong',[_vm._v(_vm._s(_vm.itemsTotal))])])])]},proxy:true}:null,{key:"item.userName",fn:function(ref){
var item = ref.item;
return [_c('span',[(item.delete !== undefined || item.userStatus === 'ARCHIVED')?_c('v-icon',{staticClass:" mb-1 error--text icons8-xbox-a"}):_vm._e(),_vm._v(" "+_vm._s(item.userName)+" ")],1)]}},{key:"item.registered",fn:function(ref){
var item = ref.item;
return [(item.authId !== null && item.authId !== '')?_c('v-icon',{staticClass:"iconbackground text-h5 success--text icons8-verified-account"}):_c('v-icon',{staticClass:"iconbackground text-h5 grey--text icons8-customer"})]}},{key:"item.swappStatus",fn:function(ref){
var item = ref.item;
return [(item.swappStatus === '1')?_c('span',{staticClass:"d-flex subtitle align-center grey--text font-weight-bold text--darken-2"},[_c('v-icon',{staticClass:"iconbackground subtitle secondary--text icons8-filled-circle"}),_vm._v(" IN ")],1):(item.swappStatus === '0')?_c('span',{staticClass:"d-flex subtitle align-center grey--text font-weight-bold"},[_c('v-icon',{staticClass:"iconbackground subtitle grey--text text--lighten-2 icons8-filled-circle"}),_vm._v(" OUT ")],1):(item.swappStatus === '2')?_c('span',{staticClass:"d-flex subtitle align-center"},[_c('v-icon',{staticClass:"iconbackground subtitle warning--text icons8-filled-circle"}),_vm._v(" SICK ")],1):(item.swappStatus === '3')?_c('span',{staticClass:"d-flex subtitle align-center"},[_c('v-icon',{staticClass:"iconbackground subtitle primary--text icons8-filled-circle"}),_vm._v(" LEAVE ")],1):_vm._e()]}},{key:"item.userStatus",fn:function(ref){
var item = ref.item;
return [(item.userStatus === 'PENDING')?_c('span',[_c('v-icon',{staticClass:"iconbackground text-h4 secondary--text icons8-inactive-state"})],1):(item.userStatus === 'REJECTED')?_c('span',[_c('v-icon',{staticClass:"iconbackground text-h4 warning--text icons8-cancel"})],1):(item.userStatus === 'APPROVED')?_c('span',[_c('v-icon',{staticClass:"iconbackground text-h4 success--text icons8-checkmark-yes"})],1):(item.userStatus === 'SUSPENDED')?_c('span',[_c('v-icon',{staticClass:"iconbackground text-h4 error--text icons8-private"})],1):(item.userStatus === 'ARCHIVED')?_c('span',[_c('v-icon',{staticClass:"iconbackground text-h4 grey--text icons8-xbox-a"})],1):_vm._e()]}},{key:"item.userTrainingStatus",fn:function(ref){
var item = ref.item;
return [(item.userTrainingStatus === 'ACTIVE')?_c('v-icon',{staticClass:"iconbackground text-h5 success--text icons8-course-assign"}):(item.userTrainingStatus === 'NONE')?_c('v-icon',{staticClass:"grey--text text-h5 icons8-course"}):(item.userTrainingStatus === 'REQUIRED')?_c('v-icon',{staticClass:"warning--text text-h5 icons8-course"}):_c('v-icon',{staticClass:"error--text text-h5 icons8-course"})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"primary--text ml-5",on:{"click":function($event){return _vm.openItem(item)}}},[_vm._v("icons8-forward")])]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }