<template>
    <v-row fluid fill-height class="d-flex flex-column align-start appbackground" :style="$vuetify.breakpoint.mdAndUp ? 'width: calc(100vw - 56px) !important; position: fixed; height: 100vh !important; overflow-y: scroll !important' : 'width: 100% !important; position: fixed; height: 100vh !important; overflow-y: scroll !important'" no-gutters>
    <!-- <v-container fluid fill-height class="d-flex flex-column align-start appbackground pa-0 ma-0"> -->
		<v-col v-if="!userOpen" class="align-center pa-3 mt-1 mb-12 pb-12" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">
            
			<div class="d-flex align-center mb-1">
				<div class="d-flex align-center">
					<div><v-icon class="primary--text" x-large>icons8-communication</v-icon></div>
					<div class="text-left primary--text pl-1 text-h4 font-weight-thin">Communications</div>
				</div>
				<v-spacer></v-spacer>
				<div class="ml-1">
                    <v-btn depressed block class="primary darken-2 white--text" @click="addNewTicket" >
                        <v-icon>icons8-communication</v-icon>
                        <span v-if="!listShrunk && $vuetify.breakpoint.mdAndUp" class="ml-3">New</span>
                    </v-btn>
                </div>
			</div>
            
            <CommunicationsList :listshrunk="listShrunk" :listexpanded="listExpanded" v-on:openItem="openItem" :refreshitems="refreshItems"/>
            
		
        </v-col>
		<transition v-if="activeItem" name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
			<v-col :style="$vuetify.breakpoint.mdAndUp ? 'border-left: #65348a solid 1px !important; height: 100% !important' : ''" v-if="!listExpanded" class="grey lighten-3 pa-0 ma-0" cols="12" :xs="itemCols.xs" :sm="itemCols.sm" :md="itemCols.md" :lg="itemCols.lg" :xl="itemCols.xl">
                <Ticket v-on:refreshItems="refreshitems" :refreshItems="refreshItems" :listshrunk="listShrunk"  :ticketId="activeItem" v-on:closeitem="closeItem" :orgtype="orgtype"/>
			</v-col>
		</transition>

        
        <v-dialog v-model="ticketDialog" max-width="612" :key="ticketDialogKey">
            <v-card>
                <v-toolbar color="secondary" elevation="0">
                    <app-text category="text-medium" class="white--text ml-2">Create New Communication</app-text>

                    <v-spacer />
                    
                    <v-btn @click="ticketDialog = false" icon dark>
                        <v-icon class="icons8-multiply" color="white" />
                    </v-btn>
                </v-toolbar>
                <div class="grey lighten-4">
                    <v-form ref="form" lazy-validation>
                        <v-row dense class="pa-4">
                            <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5" >
                                <v-select 
                                    class="" 
                                    hide-details="auto" 
                                    label="Communication Type" 
                                    filled 
                                    v-model="ticket.ticketType" 
                                    :rules="rules.ticketType"
                                    outlined 
                                    dense 
                                    clearable 
                                    :items="ticketTypes" 
                                    background-color="white" 
                                    color="primary" 
                                    :menu-props="{ top: false, offsetY: true }" 
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="7" lg="7" xl="7" >
                                <v-select 
                                    hide-details="auto" 
                                    label="Communication Site" 
                                    filled 
                                    v-model="ticket.ticketSiteId"  
                                    :rules="rules.ticketSiteId"
                                    :disabled="GET_FIREBASE_currentUser.userLevel !== 'SA' && GET_FIREBASE_currentUser.userLevel !== 'SM'"
                                    outlined 
                                    dense 
                                    clearable 
                                    :items="sites" 
                                    background-color="white" 
                                    color="primary" 
                                    :menu-props="{ top: false, offsetY: true }"  
                                    item-text="siteName" 
                                    item-value="entityId"
                                    hint="Please Select the Site this Communication will be assigned to."
                                    persistent-hint
                                >
                                </v-select>
                            </v-col>
                            <!-- <pre>{{ GET_FIREBASE_currentUser }}</pre> -->
                            <v-col cols="12" xs="12" class="">
                                <v-text-field 
                                    label="Communication Subject" 
                                    v-model="ticket.ticketSubject" 
                                    :rules="rules.ticketSubject"
                                    outlined 
                                    dense 
                                    hide-details="auto" 
                                    background-color="white" 
                                    color="primary" 
                                    clearable 
                                    @click:clear="clearItem('ticketTitle')">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12">
                                <v-textarea 
                                    label="Communication Message" 
                                    v-model="ticket.ticketMessage" 
                                    :rules="rules.ticketMessage"
                                    required 
                                    dense 
                                    outlined
                                    hide-details="auto" 
                                    background-color="white" 
                                    color="primary"
                                    rows="5"
                                    class=""
                                    clearable 
                                    @click:clear="clearItem('ticketDescription')">
                                </v-textarea> 
                            </v-col>
                            <v-col cols="12" v-if="ticket.ticketType && ticket.ticketType !== 'Complaint'">
                                <!-- // alert to make sure user is aware communications will be sent to the cleaner and staff  -->
                                <v-checkbox 
                                    v-model="confirmation" 
                                    color="error" 
                                    label="All Communications on this platform are visible to all Users. Please confirm you have read this message by ticking this box. (This does not apply to Complaints)" 
                                    hide-details="auto" 
                                    class="pa-0 ma-0 error--text font-weight-bold" 
                                />
                            </v-col>

                        </v-row>
                        <!-- <pre> {{ ticket }} </pre> -->
                    </v-form>
                </div>
                <div class="d-flex grey lighten-1 pa-4">
                    <v-btn @click="ticketDialog = false" class="elevation-0">Close</v-btn>
                    <v-spacer />
                    <v-btn :disabled="ticket.ticketType != 'Complaint' ? !confirmation : false" @click="validateItem()" class="primary elevation-0">Submit</v-btn>
                </div>

            </v-card>
        </v-dialog>
		

    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import CommunicationsList from '@/views/communications/communicationsList.vue';
import Ticket from '@/views/communications/ticket.vue';
import schema from "@/schema.js"
export default {
    name: 'Services',
    components: {
        'CommunicationsList': CommunicationsList,
        'Ticket': Ticket,
    },
    data: () => ({
        editMode: false,
        orgtype: "",
        orgIcon: "",
        search: '',
        show: false,
        tab: '',
        orgs: [],
        orgDialog: false,
        refreshItems: 0,
        activeItem: {},
        extendWidth: '',
        routeId: '',
		// Item Columns
		itemsCols: { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12,},
		itemCols: { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 },
		taskCols: {	cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2,},
		listExpanded: true,
		listShrunk: false,
        userOpen: false,



        schema: {},
        rules: {},
        sites: {},
        ticketDialog: false, 
        ticketDialogKey: 0,
        ticket: {},
        confirmation: false,
        ticketTypes: ['Complaint', 'Security Issue', 'General Enquiry', 'Maintenance', 'Stock Request', 'Other'],
    }),
    computed: {
        ...mapGetters({
                GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
    },
    methods: {
		refreshCols() {
			switch (true) {
				// Expanded List with No Task Bar
				case this.listExpanded && !this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
					this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// Expanded List with Task Bar
				case this.listExpanded && !this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
					this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				// List with Product Page (No Task Bar)
				case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// List with Product Page and Task Bar
				case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 4
					this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				// Shrunk List with Product Page (No Task Bar)
				case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }; // 2
					this.itemCols = { cols: 12, xs: 9, sm: 9, md: 9, lg: 9, xl: 9 }; // 10
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// Shrunk List with Product Page and Task Bar)
				case !this.listExpanded && this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				default:
					this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 12
					this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
			}
		},
		openItem(item) {
            console.log("openItem", item);
			this.activeItem = item;
			this.listExpanded = false;
            if (this.$vuetify.breakpoint.smAndDown) {
                this.userOpen = true;
            }
		},
		closeItem() {
			this.listExpanded = true;
			this.listShrunk = false;
            if (this.$vuetify.breakpoint.smAndDown) {
                this.userOpen = false
            }
		},
        addNewTicket() {
            var t = this; 

            t.ticketDialog = true 
            t.ticketDialogKey++

            // t.$emit('openTicket', 'new')
        },
        refreshitems() {
            this.refreshItems++
        },
        async initialiseSchema() {
            var t = this; 
            t.ticket = {...t.schema}
            t.ticket.ticketStatus = 'Open';
            if (t.GET_FIREBASE_currentUser.userLevel !== 'SA' && t.GET_FIREBASE_currentUser.userLevel !== 'SM') {
                t.ticket.ticketSiteId = t.GET_FIREBASE_currentUser.lastSiteId;
            }
        },
        validateItem() {
            var t = this; 

            t.rules = {
                ticketSubject: [
                    v => !!v || 'Ticket Subject is required'
                ],
                ticketMessage: [
                    v => !!v || 'Ticket Message is required',
                ],
                ticketType: [
                    v => !!v || 'Ticket Type is required',
                ],
                ticketSiteId: [
                    v => !!v || 'Ticket Site is required',
                ],
            }
            this.$nextTick(() => {
                if (this.$refs.form.validate()) {
                    this.saveItem()
                } else {
                    this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Please fill in all required fields', show: true })
                }
            })
        },
        // * CREATE ITEM
        async saveItem() {
            var t = this;

            var dateTimeNow = t.$moment().toISOString();

            var item = {...t.ticket}

            var siteItem = await this.REDIS_read('site', item.ticketSiteId);
            if (siteItem.success) {
                var site = siteItem.data;
                item.ticketOwnerId = site.siteManagerUserId;
                item.ticketOrgId = site.siteClientId
            }


            item.createdDateTime = dateTimeNow;
            item.createdUserId = t.GET_FIREBASE_currentUser.entityId;
            item.modifiedDateTime = dateTimeNow;
            item.modifiedUserId = t.GET_FIREBASE_currentUser.entityId;

            console.log("item = ", JSON.stringify(item, null, 2));
            let result = await this.REDIS_create('ticket', item);
            
			if (result.success) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                t.ticketDialog = false
                t.initialiseSchema()
                // t.getItems()
                // t.openTicket(result.data.entityId)
                t.refreshItems++
			}

        },
    },
	watch: {
		listExpanded() { this.refreshCols() },
		listShrunk() { this.refreshCols() },
		// GET_taskBar() { this.refreshCols() }
	},
    async mounted() {
        var t = this;
        let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
        if (sites.success) { t.sites = sites.data.documents; }

        t.sites = t.sites.map((x) => {
            return {
                entityId: x.entityId,
                siteName: x.siteName,
                siteClientId: x.siteClientId
            };
        })

        console.log("sites = ", JSON.stringify(t.sites, null, 2));
        if (t.GET_FIREBASE_currentUser.userLevel !== 'SA' && t.GET_FIREBASE_currentUser.userLevel !== 'SM') {
            t.ticket.ticketSiteId = t.GET_FIREBASE_currentUser.lastSiteId;
        }

		// Dynamic Route for New or Existing Item
		this.routeId = this.$route.params.id;
		if (this.routeId === "new") {
			this.openItem({})
		} else if (this.routeId !== "new" && this.routeId !== "" && this.routeId !== undefined && this.routeId !== ':id') {
			this.openItem(this.routeId)
		} 
        t.schema = schema.ticket;
        console.log("schema = ", t.schema)
        t.initialiseSchema()
    }
};
</script>