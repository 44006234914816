<template>
    <div class="" style="width: 100% !important">
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field
                    label="Search Tasks"
                    v-model="searchQuery"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    color="primary"
                    clearable
                    prepend-inner-icon="icons8-search"
                    @click:clear="clearSearchQuery()"
                ></v-text-field>
            </div>
            <div v-if="user == false" class="flex-grow-1 pl-3">
                <!-- <div class="white--text caption">Filter by Staff Member:</div> -->
                <v-select 
                    class="" 
                    hide-details="auto" 
                    label="Admin" 
                    v-model="filter.manager"
                    filled 
                    outlined
                    dense
                    clearable
                    @click:clear="clearFilter('manager')"
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="userName" 
                    item-value="entityId" 
                    :items="managers"
                ></v-select>
            </div>
            <div class="flex-grow-1 pl-3">
                <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field label="Due Date" v-model="computedDate1" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearFilter('dueDate')"></v-text-field>
                    </template>
                    <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                </v-menu>
            </div>

            <v-btn v-if="$vuetify.breakpoint.mdAndUp" depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="refreshItems">
                <v-icon>icons8-available-updates</v-icon>
            </v-btn>
            <div v-if="$vuetify.breakpoint.mdAndUp" class="ml-2">
                <v-menu
                    offset-y
                    class="white"
                    style="z-index: 99999 !important; width: 200px !important; height: 200px !important"
                    :close-on-content-click="false"
                    origin="top right"
                    left
                    transition="scale-transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"
                            ><v-icon>icons8-table-properties</v-icon></v-btn
                        >
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox
                                v-model="headers[index].hidden"
                                dense
                                hide-details="auto"
                                v-for="(item, index) in headers"
                                :key="index"
                                :label="item.text"
                                :true-value="false"
                                :false-value="true"
                            ></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>
        <div v-if="client == false && site == false && user == false" class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-select 
                    class="" 
                    hide-details="auto" 
                    label="Clients" 
                    v-model="filter.client"
                    filled 
                    outlined
                    dense
                    clearable
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="clientName" 
                    item-value="entityId" 
                    :items="clients"
                ></v-select>
            </div>
            <div class="flex-grow-1 pl-3">
                <v-select 
                    class="" 
                    hide-details="auto" 
                    label="Sites" 
                    v-model="filter.site"
                    filled 
                    outlined
                    dense
                    clearable
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="siteName" 
                    item-value="entityId" 
                    :items="sites"
                ></v-select>
            </div>
            <div class="flex-grow-1 pl-3">
                <v-select 
                    class="" 
                    hide-details="auto" 
                    label="Users" 
                    v-model="filter.user"
                    filled 
                    outlined
                    dense
                    clearable
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="userName" 
                    item-value="entityId" 
                    :items="users"
                ></v-select>
            </div>
        </div>

        <div class="d-flex">
            <div v-if="$vuetify.breakpoint.mdAndUp" class="py-2">
                <div class="caption font-weight-bold grey--text text--darken-1">PRIORITY</div>
                <v-btn-toggle v-model="filter.priorityStatus" multiple color="white" class="" background-color="white" dense>
                    <v-btn value="Urgent" :class="filter.priorityStatus.includes('Urgent') ? 'error white--text' : 'error--text'" small>
                        <v-icon :class="filter.priorityStatus.includes('Urgent') ? 'white--text' : 'error--text'" class="text-h5 icons8-high-importance"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Urgent</span>
                    </v-btn>
                    <v-btn value="High" :class="filter.priorityStatus.includes('High') ? 'error white--text' : 'error--text'" small>
                        <v-icon :class="filter.priorityStatus.includes('High') ? 'white--text' : 'error--text'" class="text-h5 icons8-filled-circle"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">High</span>
                    </v-btn>
                    <v-btn value="Medium" :class="filter.priorityStatus.includes('Medium') ? 'warning white--text' : 'warning--text'" small>
                        <v-icon :class="filter.priorityStatus.includes('Medium') ? 'white--text' : 'warning--text'" class="text-h5 icons8-filled-circle"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Medium</span>
                    </v-btn>
                    <v-btn value="Low" :class="filter.priorityStatus.includes('Low') ? 'grey white--text' : 'grey--text'" small>
                        <v-icon :class="filter.priorityStatus.includes('Low') ? 'white--text' : 'grey--text'" class="text-h5 icons8-inactive-state"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Low</span>
                    </v-btn>
                </v-btn-toggle>
            </div>
            <div v-if="$vuetify.breakpoint.mdAndUp" class="py-2 pl-3">
                <div class="caption font-weight-bold grey--text text--darken-1">TYPE</div>
                <v-btn-toggle v-model="filter.category" multiple color="white" class="" background-color="white" dense>
                    <v-btn value="Email" :class="filter.category.includes('Email') ? 'grey white--text' : 'grey--text'" small>
                        <v-icon :class="filter.category.includes('Email') ? 'white--text' : 'grey--text'" class="text-h5 icons8-mail"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Email</span>
                    </v-btn>
                    <v-btn value="Call" :class="filter.category.includes('Call') ? 'grey white--text' : 'grey--text'" small>
                        <v-icon :class="filter.category.includes('Call') ? 'white--text' : 'grey--text'" class="text-h5 icons8-call"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Call</span>
                    </v-btn>
                    <v-btn value="Appointment" :class="filter.category.includes('Appointment') ? 'grey white--text' : 'grey--text'" small>
                        <v-icon :class="filter.category.includes('Appointment') ? 'white--text' : 'grey--text'" class="text-h5 icons8-appointment"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Appointment</span>
                    </v-btn>
                    <v-btn value="Notes" :class="filter.category.includes('Notes') ? 'grey white--text' : 'grey--text'" small>
                        <v-icon :class="filter.category.includes('Notes') ? 'white--text' : 'grey--text'" class="text-h5 icons8-notes"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Notes</span>
                    </v-btn>
                    <v-btn value="Follow up" :class="filter.category.includes('Follow up') ? 'grey white--text' : 'grey--text'" small>
                        <v-icon :class="filter.category.includes('Follow up') ? 'white--text' : 'grey--text'" class="text-h5 icons8-meeting-room"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Follow up</span>
                    </v-btn>
                    <v-btn value="Document" :class="filter.category.includes('Document') ? 'grey white--text' : 'grey--text'" small>
                        <v-icon :class="filter.category.includes('Document') ? 'white--text' : 'grey--text'" class="text-h5 icons8-document"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Document</span>
                    </v-btn>
                    <v-btn value="Diary" :class="filter.category.includes('Diary') ? 'grey white--text' : 'grey--text'" small>
                        <v-icon :class="filter.category.includes('Diary') ? 'white--text' : 'grey--text'" class="text-h5 icons8-diary"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Diary</span>
                    </v-btn>
                </v-btn-toggle>
            </div>
        </div>
        
        <v-row class="pa-3 pt-5">
            <!-- TABLE BASED LIST -->
            <v-data-table
                :item-class="itemRowBackground"
                sortable
                dense
                class=""
                style="width: 100%"
                :headers="computedHeaders"
                :items="computedItems"
                :server-items-length="itemsTotal"
                :options.sync="datatableOptions"
                v-if="$vuetify.breakpoint.mdAndUp"
                :loading="loading"
                :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50]
                }"
            >
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                    </div>
                </template>

                
                <template v-slot:[`item.taskPriority`]="{ item }">
                    <div class="d-flex align-center justify-center">
                        <v-icon 
                        class="text-h5 icons8-filled-circle" 
                        :class="
                            item.taskPriority == 'Urgent' || item.taskPriority == 'High' ? 'error--text' : 
                            item.taskPriority == 'Medium' ? 'warning--text' : 'info--text'" ></v-icon>
                    </div>
                </template>
                <template v-slot:[`item.taskCategory`]="{ item }">
                    <div class="d-flex align-center justify-center">
                        <v-icon 
                        class="text-h5" 
                        :class="
                            item.taskCategory == 'Email' ? 'icons8-mail' : 
                            item.taskCategory == 'Call' ? 'icons8-call' : 
                            item.taskCategory == 'Appointment' ? 'icons8-appointment' : 
                            item.taskCategory == 'Notes' ? 'icons8-notes' : 
                            item.taskCategory == 'Follow up' ? 'icons8-meeting-room' : 
                            item.taskCategory == 'Document' ? 'icons8-document' : 
                            item.taskCategory == 'Diary' ? 'icons8-diary' : 
                            ''" ></v-icon>
                    </div>
                </template>
                <template v-slot:[`item.taskAllocatedUserId`]="{ item }">
                    <div class="d-flex">
                        {{ getUser(item.taskAllocatedUserId) }}
                    </div>
                </template>
                <template v-slot:[`item.taskDueDateTime`]="{ item }">
                    <div class="">
                        <div class="caption grey--text noselect">{{ $moment(item.taskDueDateTime).format("DD-MMM-YYYY") }}</div>
                    </div>
                </template>
                <template v-slot:[`item.taskNotesHTML`]="{ item }">
                    <div v-html="item.taskNotesHTML">
                    </div>
                </template>


                <template v-slot:[`item.deleted`]="{ item }">
                    <div v-if="item.deleted == true">
                        <v-icon class="text-h5 warning--text icons8-private"></v-icon>
                    </div>
                    <div v-if="item.deleted == false">
                        <v-icon class="text-h5 success--text icons8-checkmark-yes"></v-icon>
                    </div>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                    <v-icon class="error--text pa-1" v-if="item.deleted === '1' && toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">icons8-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.entityId)" v-if="item.deleted === false && toggleFeatures.includes('delete')">icons8-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === true && toggleFeatures.includes('undelete')">icons8-trash-restore</v-icon>
                    <v-icon class="primary--text ml-5" @click="openItem(item)">icons8-forward</v-icon>
                </template>
                
            </v-data-table>

            <v-col class="tabledata pa-0 ma-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-row v-if="$vuetify.breakpoint.smAndDown" class="d-flex flex-column align-center " no-gutters>
                    <div v-if="itemsFrom > 0">
                        
                        <v-btn block @click="goBack" class="elevation-0 darkgrey lighten-3 white--text  mb-2 font-weight-bold">
                        + GO BACK 
                        </v-btn>
                    </div>
                    <v-col cols="12" xs="12" class="my-1 pa-1 white tablecard" v-for="item in computedItems" :key="item.id" @click="openItem(item)">
                        <v-row class="d-flex align-center justify-start pa-3 fill-height" no-gutters>
                            <v-col cols="10" class="d-flex flex-column pt-1">
                                <div class="font-weight-regular primary--text noselect">{{ getClient(item.taskClientId) }}</div>
                                <div class="caption grey--text noselect">{{ item.taskName }}, {{ item.taskArea }}</div>
                                <div class="caption grey--text noselect">Site Contact Name: {{ item.taskContactName }}</div>
                                <div v-if="item.taskContactTelephone" class="caption grey--text noselect">Site Contact Telephone: {{ item.taskContactTelephone }}</div>
                            </v-col>
                            <v-col cols="2" class="text-right"><v-icon @click="openItem(item)">mdi-chevron-right</v-icon></v-col>
                        </v-row>
                    </v-col>
                    <v-row v-if="loading === true" class="align-center pt-5 justify-center">
                        <v-progress-circular :size="60" :width="7" indeterminate color="primary" ></v-progress-circular>
                    </v-row>
                    <!-- <pre>computedItems: {{ computedItems }}</pre> -->
                    <div v-else-if="computedItems.length == 0" class="font-weight-bold grey--text text--darken-1 body-1">
                        NO JOBS
                    </div>
                    <div v-else-if="computedItems.length !== itemsTotal && computedItems.length == datatableOptions.itemsPerPage">
                        
                        <v-btn block @click="loadMore" class="elevation-0 darkgrey lighten-3 white--text font-weight-bold">
                        + SHOW MORE 
                        </v-btn>
                    </div>
                    <div v-else-if="computedItems.length <= itemsTotal && computedItems.length > 0" class="font-weight-bold grey--text text--darken-1 body-1">
                        SHOWING ALL JOBS
                    </div>
                </v-row>
            </v-col>

            <!-- <pre>{{ toggleFeatures }}</pre> -->
        </v-row>

        <v-dialog v-model="completeTaskDialog" max-width="612" persistent height="900" :key="completeTaskDialogKey">
            <v-card>
                <v-toolbar color="secondary" elevation="0">
                    <div class="white--text text-h6">Complete Task</div>
                </v-toolbar>
                <div class="grey lighten-4 grey--text  font-weight-bold pa-4">
                    Are you sure you wish to complete this Task? This will be visible to the Client.
                </div>
                <div class="d-flex grey align-end justify-end lighten-1 pa-4">
                    <v-btn class="elevation-0 secondary" width="120" @click="completeTaskDialog = !completeTaskDialog">Cancel</v-btn>
                    <v-spacer />
                    <!-- <v-btn v-if="!addCommunications" class="elevation-0 primary" width="120" @click="addCommunications = !addCommunications">Next</v-btn> -->
                    <v-btn class="elevation-0 primary" width="120" @click="completeTask()">Save</v-btn>
                </div>

            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'; // Map the Getters from the Vuex Store
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
        siteId: {
            type: String,
            default: '',
        },
        site: {
            type: Boolean,
            default: false,
        },
        userId: {
            type: String,
            default: '',
        },
        user: {
            type: Boolean,
            default: false,
        },
        clientId: {
            type: String,
            default: '',
        },
        client: {
            type: Boolean,
            default: false,
        },

    },
    data: () => ({
        type: '',
        status: '',
        key: 'hotlist-task',
        index: 'idx:tasks',
        service: {},
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'ID', value: 'entityId', sortable: false, hidden: true, expanded: false },
            { text: 'Priority', value: 'taskPriority', sortable: false, hidden: false, expanded: false },
            { text: 'Category', value: 'taskCategory', sortable: false, hidden: false, expanded: false },
            { text: 'Task', value: 'taskTitle', sortable: false, hidden: false, expanded: false },
            { text: 'Due Date', value: 'taskDueDateTime', sortable: false, hidden: false, expanded: false },
            { text: 'Add Date', value: 'taskAddDate', sortable: false, hidden: false, expanded: true },
            { text: 'Allocated Admin', value: 'taskAllocatedUserId', sortable: false, hidden: false, expanded: false },
            { text: 'Client', value: 'taskClientId', sortable: false, hidden: false, expanded: true },
            { text: 'Site', value: 'taskSiteId', sortable: false, hidden: false, expanded: true },
            { text: 'User', value: 'taskUserId', sortable: false, hidden: false, expanded: true },
            { text: 'Status', value: 'taskStatus', sortable: false, hidden: false, expanded: false },
            { text: 'Type', value: 'taskType', sortable: false, hidden: false, expanded: true },
            { text: 'Notes', value: 'taskNotesHTML', sortable: false, hidden: false, expanded: false },


            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            // { text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false },
        ],
        // Fields to Retrieve from Database
        // fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        sortBy: '@taskTitle',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['createdDateTime'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        // productCategories: ['Chair', 'Table', 'Sofa', 'Bed', 'Wardrobe', 'Cabinet', 'Dresser', 'Other'],
        filter: {
            priorityStatus: [],
            manager: '',
            client: '',
            site: '',
            user: '',
            dueDate: '',
            category: [],
        },
        loading: false,

        clients: [],
        users: [],
        sites: [],
        managers: [],
        locations: [],

        completeTaskDialog: false,
        completeTaskDialogKey: 0,
        task: {},

        date1: '',
        dateMenu1: false,
        date2: '',
        dateMenu2: false,
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',                                     
        }),
        computedDate1() {
            return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YYYY");
        },

        computedDate2() {
            return this.MIX_formatDateTime(this.date2, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

           
            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '*';
                } else {
                    searchQuery = '@deleted:{false}';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:{false}`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:{false}`;
                    }
                }
            }

            if (this.client == true) {
                searchQuery = `${searchQuery} @taskClientId:{${this.clientId}}`;
                searchQuery = `${searchQuery} @taskAddDate:[${today} +inf]`;
            }
            if (this.site == true) {
                searchQuery = `${searchQuery} @taskSiteId:{${this.siteId}}`;
                searchQuery = `${searchQuery} @taskAddDate:[${today} +inf]`;
            }
            var today = this.$moment().format('X');
            if (this.user == true) {
                searchQuery = `${searchQuery} @taskUserId:{${this.userId}}`;
                searchQuery = `${searchQuery} @taskAddDate:[${today} +inf]`;
            }
            if (this.filter.manager !== '') {
                searchQuery = `${searchQuery} @taskAllocatedUserId:{${this.filter.manager}}`;
                searchQuery = `${searchQuery} @taskAddDate:[${today} +inf]`;
            }
            if (this.filter.priorityStatus.length > 0) {
                searchQuery = `${searchQuery} @taskPriority:{${this.filter.priorityStatus.join('|')}}`;
            }
            if (this.filter.category.length > 0) {
                
                searchQuery = `${searchQuery} @taskCategory:{${this.filter.category.join('|')}}`;
            }
            if (this.date1 !== '') {
                var date = this.$moment(this.date1, 'YYYY-MM-DD').format('X');
                searchQuery = `${searchQuery} @taskDueDateTime:[-inf ${date}]`;
            }
            console.log('searchQuery = ' + searchQuery);

            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (this.listshrunk) {
                headers = headers.filter((header) => header.expanded === false || header.expanded === true); // Return Headers based on Expanded State
            } else {
                headers = headers.filter((header) => header.expanded === false); // Return Headers based on Expanded State
            }
            return headers;
        },
        computedItems() {
            var items = this.items;
            // if (this.type) {
            //     items = this.items.filter((item) => item.actionType === this.type);
            // }
            // if (this.status) {
            //     items = this.items.filter((item) => item.actionStatus === this.status);
            // }
            return items;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            // if (!computedFields.includes('@actionId')) {
            //     computedFields.push('@actionId');
            // }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedType() {
            const types = [];
            this.items.filter((item) => {
                return types.push(item.actionType);
            });
            return types;
        },
        computedStatus() {
            const status = [];
            this.items.filter((item) => {
                return status.push(item.actionStatus);
            });
            return status;
        },
    },
    methods: {
        // Clear Item Property
        clearFilter(property) {
            setTimeout(() => {
                this.filter[property] = '';
            }, 1);
        },
        async loadMore() {
            this.itemsFrom = this.itemsFrom + this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo + this.datatableOptions.itemsPerPage;
            this.getItems();
        },
        async goBack() {
            this.itemsFrom = this.itemsFrom - this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo - this.datatableOptions.itemsPerPage;
            this.getItems()
        },
        getClient(clientId) {
            var t = this;
            var client = []
            var clientName = ''

            client = t.clients.filter((item) => { return item.entityId == clientId })

            if (client.length !== 0) { clientName = client[0].clientName }
            return clientName
        },
        getUser(userId) {
            var t = this;
            var user = []
            var userName = ''

            user = t.users.filter((item) => { return item.entityId == userId })

            if (user.length !== 0) { userName = user[0].userName }

            return userName
        },
        getSite(siteId) {
            var t = this;
            var site = []
            var siteName = ''

            site = t.sites.filter((item) => { return item.entityId == siteId })

            if (site.length !== 0) { siteName = site[0].siteName } 
            else { siteName = 'Site Deleted'}

            return siteName
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        refreshItems: function () {
            this.getItems();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
		// * DELETE ITEM
		async deleteItem(itemId) {
            var deleteData = {}

            deleteData.deletedUserId = this.GET_FIREBASE_currentUser.entityId
            deleteData.deletedDateTime = this.$moment().format('X')

            // console.log('deleteData: ', JSON.stringify(deleteData, null, 2))

			let result = await this.REDIS_delete(this.key, itemId, deleteData);
            // console.log('result: ', JSON.stringify(result, null, 2))
			if (result.success) {
                this.getItems()
			}
		},
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.success) {
                // this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        // * GET ITEMS
        async getItems() {
            var t = this
            t.loading = true
            let itemsResult = await this.REDIS_searchFor(this.key, this.itemsFrom, this.datatableOptions.itemsPerPage, 'taskDueDateTime','ASC', this.computedSearchQuery)
            // console.log('itemsResult: ' + JSON.stringify(itemsResult));
            if (itemsResult.success) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.documents
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                t.loading = false
            }
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.service = { ...this.datadown };
            },
            deep: true,
        },
        dateMenu1: {
            handler: async function () {
                this.filter.dueDate = this.MIX_formatDateTime(this.date1, 'YYYY-MM-DD', 'X');
            },
            deep: true,
        },
        dateMenu2: {
            handler: async function () {
                this.filter.createdTo = this.$moment(this.date2, 'YYYY-MM-DD').endOf('day').format('X');
            },
            deep: true,
        },
        refreshitems() {
            this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        computedFields() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        var t = this;
        this.service = { ...this.datadown };
        let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false} -@clientStatus:{ARCHIVE}');
        if (clients.success) { t.clients = clients.data.documents; }

        t.clients = t.clients.map((x) => {
            return {
                entityId: x.entityId,
                clientName: x.clientName,
            };
        })

        let users = await t.REDIS_searchFor('user', '0', '999', 'userStartDate', 'ASC',`@deleted:{false} -@userStatus:{ARCHIVED}`);
        if (users.success) { t.users = users.data.documents; }

        t.users = t.users.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
            };
        })

        let managers = await t.REDIS_searchFor('user', '0', '9999', 'userName', 'ASC', `@deleted:{false} @userLevel:{SA} -@userStatus:{ARCHIVED}`);
        if (managers.success) { t.managers = managers.data.documents; }

        t.managers = t.managers.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
            };
        })

        var sites = []
        if (this.managerTasks && this.GET_FIREBASE_currentUser.userCategory !== 'DIRECTOR' && this.GET_FIREBASE_currentUser.userLevel !== 'DA') {
            sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', `@deleted:{false} @siteManagerUserId:{${t.GET_FIREBASE_currentUser.entityId}}` );

        } else {
            sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
        }
        if (sites.success) { t.sites = sites.data.documents; }

        t.sites = t.sites.map((x) => {
            return {
                entityId: x.entityId,
                siteName: x.siteName,
            };
        })
        this.getItems();
    },
};
</script>