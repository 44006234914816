

<template>
	<v-app style="overflow-x: hidden !important">

		<qrCodeGeneratorDialog></qrCodeGeneratorDialog>
        <!-- // TODO ADD 'updateExists' AND UNCOMMENT -->
		<!-- <v-banner height="48px" app sticky class="secondary d-flex align-center noprint" v-if="updateExists" style="width: 100% !important; position: fixed !important; z-index: 1000 !important;">
			<v-row no-gutters class="pa-2 ma-0 d-flex justify-center align-center">
				<v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="pa-0 ma-0 d-flex align-center justify-center">
					<div class="px-4 white--text">App Update Available</div>
					<div class="px-4">
						<v-btn small @click="refreshApp" dark class="animate__animated animate__pulse primary animate__infinite"><span class="white--text">Update now</span></v-btn>
					</div>
				</v-col>
			</v-row>
		</v-banner> -->

		<!-- // * MOBILE APP BAR -->
        <v-app-bar app class="app-bar-background noprint" style="z-index: 100 !important" clipped-left v-if="!$route.meta.hideNavbar && $vuetify.breakpoint.smAndDown">
            <v-btn v-if="GET_FIREBASE_currentUser.userLevel !== 'SU'" icon class="white--text" @click.native="openMobileMenu()">
                <v-icon class="text-h5">icons8-menu</v-icon>
            </v-btn>
            <v-spacer />
            <v-img @click="MIX_go('/home')" height="40" width="100" position="center" contain :src="require('@/assets/A&R_Services_FINAL_LOGO.svg')" />
            <v-spacer />
            <mobile-nav-bar :LATEST_VERSION="LATEST_VERSION" :showInstallBanner="showInstallBanner" v-on:logout="logout" v-on:install="install" />
        </v-app-bar>

        <!-- //* MOBILE HORIZONTAL NAVIGATION -->
        <v-app-bar app class="pa-0 secondary elevation-0 ma-0" :key="mobileAppBarKey" clipped-left v-if="!$route.meta.hideNavbar && $vuetify.breakpoint.smAndDown && showPageElements === true" height="80" style="z-index: 99 !important; margin-top: 56px !important">

            <v-tabs style="width: 100% !important" fixed-tabs show-arrows icons-and-text class="elevation-0 pa-0 ma-0" v-model="menutab" background-color="secondary" dark color="white" active-class="secondary" hide-slider>
                <v-tab v-for="(item, index2) in menu" :key="index2" :disabled="item.swappStatus !== 'all' && item.swappStatus != GET_FIREBASE_currentUser.swappStatus" @click="goTo(item.path)" class="pt-2 white--text">
                    <!-- <pre>{{ GET_FIREBASE_currentUser.swappStatus }}</pre>
                    <pre>{{ item.swappStatus }}</pre> -->
                    <span class="caption font-weight-bold">{{ item.name }}</span>
                    <v-icon class="navicons white--text">{{ item.icon }}</v-icon>
                </v-tab> 
            </v-tabs>

            <!-- <v-tabs fixed-tabs show-arrows icons-and-text class="elevation-0 pa-0 ma-0"  v-model="menutab" background-color="secondary" dark color="white" active-class="secondary" hide-slider>
                <v-tab v-for="(item, index) in menu" :key="index" @click="goTo(item.path)" class="pt-2 white--text">
                    <span class="caption font-weight-bold">{{ item.name }}</span>
                    <v-icon class="navicons white--text">{{ item.icon }}</v-icon>
                </v-tab> 
            </v-tabs> -->
        </v-app-bar>

		<!-- // * APP BAR DESKTOP -->
		<v-app-bar app class="app-bar-background noprint elevation-0" style="z-index: 100 !important" clipped-left v-if="!$route.meta.hideNavbar && $vuetify.breakpoint.mdAndUp">
    		<v-img @click="MIX_go('/home')" height="40" position="left" contain class="ml-0" :src="require('@/assets/A&R_Services_FINAL_LOGO.svg')" />
    		<v-spacer />
            <desktop-nav-bar :LATEST_VERSION="LATEST_VERSION" :showInstallBanner="showInstallBanner" v-on:logout="logout" v-on:install="install" />
        </v-app-bar>

        <!--// * VERTICAL NAVIGATION -->
		<v-navigation-drawer :touchless="true" permanent expand-on-hover class="secondary noprint" style="z-index: 101 !important;" app clipped v-if="$vuetify.breakpoint.mdAndUp && showPageElements === true && !$route.meta.hideNavbar">
			<menu-list />
		</v-navigation-drawer>

        <v-dialog v-model="mobileBurgerMenu" style="z-index: 999999999 !important;" fullscreen transition="slide-x-transition">
            <v-card class="secondary backgreen">
                <v-card-title class="primary white--text pl-2 pr-0 py-2" style="height: 55px !important;">
                    <v-btn icon class="white--text" @click.native="openMobileMenu()">
                        <v-icon class="text-h5">icons8-menu</v-icon>
                    </v-btn>
                    <div class="ml-3">Menu</div>
                    <v-spacer></v-spacer>
                    <div class="text-right">
                        <appbutton icon="icons8-multiply" type="primary" iconclass="white--text" @click.native="mobileBurgerMenu = false" />
                    </div>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="secondary noprint">
                    <menu-list v-on:closeMenu="mobileBurgerMenu = false"/>
                </v-card-text>
            </v-card>
        </v-dialog>


		<v-main class="appbackground" :style="!$route.meta.hideNavbar && $vuetify.breakpoint.mdAndUp && $route.path !== '/' && $route.path !== '/register' && $route.path !== '/reset' && $route.path !== '/dashboard' ? 'padding-left: 56px !important' : ''">
			<v-container style="max-width: 'calc(100vw - 55px) !important; overflow: scroll !important'" class="ma-0 pa-0 d-flex align-start fill-height" fill-height fluid>
				<router-view />
			</v-container>
		</v-main>
        <AlertBox />

    </v-app>
</template>


<script >
import mobileNavBar from './components/navigation/mobileNavBar.vue';
import { mapState, mapGetters, mapActions } from "vuex";
import desktopNavBar from './components/navigation/desktopNavBar.vue';
import menuList from './components/navigation/menuList.vue';
import { version } from "../package.json"

export default {
    name: 'App',
    components: {
        'mobile-nav-bar': mobileNavBar,
        'desktop-nav-bar': desktopNavBar,
        'menu-list': menuList,
    },
    data() {
        return {
			LATEST_VERSION: version,

			// page elements
			showPageElements: false, // hide menu etc. when there's no user logged in
            showInstallBanner: false,
            mobileBurgerMenu: false, //mobile menu
            menutab: [],
            menuIn: [
				{ name: "Rota", icon: "icons8-clock", path: `/rota` },
                { name: "Swapp", icon: "icons8-qr-code", path: `/swapp/in` }
            ],
            menuOut: [
                { name: "Jobs", icon: "icons8-broom", path: `/swappedJobs` },
                { name: "Swapp", icon: "icons8-qr-code", path: `/swapp/out` },
            ],
            menu: [
                { name: "Rota", icon: "icons8-event-accepted-tentatively", path: `rota`, swappStatus: 'all' },
                { name: "Jobs", icon: "icons8-broom", path: `jobs`, swappStatus: 1},
                { name: "Swapp", icon: "icons8-qr-code", path: `swapp`, swappStatus: 'all' },
            ],
            

            currentUser: {},

            mobileAppBarKey: 0,


        }
    },
    computed: {
        ...mapState({
            page: (state) => state.page,
        }),
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
        containerStyle() {
            if (!this.$route.meta.hideNavbar) {
                return {
                    position: 'fixed !important',
                    scrolling: 'none !important',
                    maxWidth: 'calc(100vw - 55px) !important',
                };
            } else {
                return '';
            }
        },
    },
    watch: {
        GET_FIREBASE_currentUser: {
            handler: function (val) {
                // console.log('user changed')
                this.mobileAppBarKey++
            },
            immediate: true,
        },
    },
	methods: {
		logout() {
			this.$firebase.auth.signOut().then(() => {
				this.showPageElements = false;
				this.$router.push("/").catch((err) => {
					// // console.log("Route error: " + err);
				});
				this.$store.commit("SET_currentUser", null);
			});
		},
        openMobileMenu(){
            this.mobileBurgerMenu = true;
        },
        install() {
            this.showInstallBanner = false;
            installEvent.prompt();
            installEvent.userChoice.then(() => {
                installEvent = null;
            });
        },
        goTo(path) {
            var t = this;
            // console.log(path);

            var swappStatus = t.GET_FIREBASE_currentUser.swappStatus;
            // console.log(swappStatus);

            switch (true) {
                case (path == 'swapp'): 
                    if (swappStatus == '0' || swappStatus == 0) {
                        t.$router.push('/swapp/in');
                    } else if (swappStatus == '1' || swappStatus == 1) {
                        t.$router.push('/swapp/out');
                    }
                    break;
                case (path == 'jobs'): 
                    if (swappStatus == '0' || swappStatus == 0) {
                        t.$router.push('/rota');
                    } else if (swappStatus == '1' || swappStatus == 1) {
                        t.$router.push('/swappedJobs');
                    }
                    break;
                case (path == 'rota'): 
                    t.$router.push('/rota');
                    break;
            }
        }
	},
	async created() {
		var t = this;
		this.$firebase.auth.onAuthStateChanged((user) => {
			if (user != null) {
				t.$firebase.db
					.collection("users")
					.where("authId", "==", user.uid)
					.get()
					.then(function(querySnapshot) {
						if (querySnapshot.docs.length === 0) {
							t.showPageElements = false;
						} else {
							querySnapshot.forEach(function(doc) {
								var document = doc.data();
								t.currentUser = document;
                                // console.log('currentUser = ' + JSON.stringify(t.currentUser, null, 2));
							});
							t.showPageElements = true;
						}
					});
			} else {
				this.showPageElements = false;
			}
		});
	},
	mounted() {
	},
}
</script>
<style scoped>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}
</style>

<style lang="scss">

html, body {
    touch-action: pan-x pan-y !important;
    ::-webkit-scrollbar {
        display: none !important;
    }
    // overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
}

</style>
<style scoped lang="sass">
	@import '~vuetify/src/styles/styles.sass'

	@media #{map-get($display-breakpoints, 'md-and-up')}
	  .contentstart
	      margin-top: 20px !important

	@media #{map-get($display-breakpoints, 'xs-only')}
	  .contentstart
	      margin-top: 50px !important
	  .bottomnav
	      width: 100% !important
	  .submenu
	      z-index: 100 !important
	      position: fixed !important
	      top: 131px !important
	      width: 100% !important
	  .copyright
	      width: calc(100% - 25px) !important
	      bottom:0px !important
</style>
<style>


    .tablecard {
        border-radius: 5px !important;
        /* width: 100% !important;
        height: 100px !important; */
    }
    .v-banner__wrapper {
        padding: 0px !important;
        margin: 0px !important;
    }
    .v-banner__content {
        padding: 0px !important;
        margin: 0px !important;

    }
	.app-bar-background {
		background: rgb(48, 141, 204);
		background: linear-gradient(90deg, rgba(48, 141, 204, 1) 0%, rgba(81, 35, 121, 1) 100%);
	}
</style>

