<template>
    <div 
    class="" 
    style="padding: 0px !important; margin: 0px !important; width: 65vw !important" 
    :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 110px) !important; overflow: none !important' : 'height: calc(100vh - 120px) !important; '">

        <v-row 
            class="apptext--text pa-2 " 
            style="width: 100% !important; z-index: 0 !important" 
            dense 
        >


            <v-col class="" cols="12" xs="12" sm="12" md="12">
                <v-row style="width: 100% !important" no-gutters>
                <!-- <v-card-title>TEST</v-card-title> -->
                    <v-col cols="12" class="ma-0 pa-0">
                        <v-row class="pa-0 ma-0  align-start">
                            <v-col cols="12" class="pb-1 pt-1">
                                <v-row class="justify-start align-end pt-0">
                                    <div class="d-flex flex-column">
                                        <div><h3 class="grey--text text--darken-1 pa-3 pb-0 pl-0">ACTIONS</h3></div>
                                        <div class="d-flex">
                                            <!-- <pre>StaffItem = {{ staffItem.entityId }}</pre>
                                            <pre>JobItem = {{ jobItem.jobAssignedUserId }}</pre>
                                            <pre>jobAssignedStatus = {{ jobItem.jobAssignedStatus }}</pre>
                                            <pre>jobPublishedStatus = {{ jobItem.jobPublishedStatus }}</pre> -->
                                            <div v-if="jobItem.jobAssignedStatus === 'UNALLOCATED' " class="pb-1 pr-1 d-flex align-center justify-center">
                                                <v-btn @click="openActionDialog('Allocate')" :disabled="jobItem.deleted == true" class="elevation-0 mt-1" width=100% color="success" medium>
                                                    <span class="white--text">ALLOCATE</span>
                                                </v-btn>
                                            </div>
                                            <div v-if="jobItem.jobAssignedStatus === 'ALLOCATED' && jobItem.jobAssignedUserId === staffItem.entityId " class="pb-1 pr-1 d-flex align-center justify-center">
                                                <v-btn @click="openActionDialog('Unallocate')" :disabled="jobItem.deleted == true" class="elevation-0 mt-1" width=100% color="darkgrey" medium>
                                                    <span class="white--text">UNALLOCATE</span>
                                                </v-btn>
                                            </div>
                                            <div v-if="jobItem.jobAssignedStatus === 'ALLOCATED' && jobItem.jobAssignedUserId === staffItem.entityId && jobItem.jobPublishedStatus === 'UNPUBLISHED'" class="pb-1 pr-1 d-flex align-center justify-center">
                                                <v-btn @click="openActionDialog('Publish')" :disabled="jobItem.deleted == true" class="elevation-0 mt-1" width=100% color="secondary" medium>
                                                    <span class="white--text">PUBLISH</span>
                                                </v-btn>
                                            </div>
                                            
                                            <div v-if="jobItem.jobAssignedStatus === 'ALLOCATED' && jobItem.jobAssignedUserId === staffItem.entityId && jobItem.jobPublishedStatus === 'PUBLISHED'" class="pb-1 pr-1 d-flex align-center justify-center">
                                                <v-btn @click="openActionDialog('Draft')" :disabled="jobItem.deleted == true" class="elevation-0 mt-1" width=100% color="secondary" medium>
                                                    <span class="white--text">DRAFT</span>
                                                </v-btn>
                                            </div>
                                            <div v-if="jobItem.jobAssignedStatus === 'ALLOCATED' && jobItem.jobAssignedUserId !== staffItem.entityId " class="pb-1 pr-1 d-flex align-center justify-center">
                                                <v-btn @click="openActionDialog('Add User to Job')" :disabled="jobItem.deleted == true" class="elevation-0 mt-1" width=100% color="secondary" medium>
                                                    <span class="white--text">ADD USER TO JOB</span>
                                                </v-btn>
                                            </div>
                                        </div>
                                    </div>
                                    <v-spacer />
                                    <!-- <pre>{{ jobItem }}</pre> -->


                                    <!-- // * HOURS ALLOCATED -->
                                    <div class="mr-2">
                                        <v-card flat height="75" class="align-centre" style="border-radius: 5px !important;">
                                            <v-card-actions class="justify-center text-center caption grey--text pt-1">HOURS ALLOCATED</v-card-actions>
                                            <v-card-title class="justify-center pa-0">

                                                <app-text v-if="computedAllocatedHours === 0" category="stats-secondary">{{ computedAllocatedHours }}</app-text>
                                                <app-text v-else-if="computedAllocatedHours > staff.contractHours" category="stats-error">{{ parseFloat(computedAllocatedHours.toFixed(2)) }}</app-text>
                                                <app-text v-else-if="computedAllocatedHours <= staff.contractHours" category="stats-success">{{ parseFloat(computedAllocatedHours.toFixed(2)) }}</app-text>
                                                <app-text v-else category="stats-secondary"><number ref="numberExample" :to="computedAllocatedHours()" :duration="0.0" /></app-text>
                                                <!-- <app-text category="stats-error"><number>0</number></app-text> -->
                                            </v-card-title>
                                            <!-- <v-card-actions class="justify-center text-center body-2 darkgrey--text">THIS WEEK</v-card-actions> -->
                                        </v-card>
                                    </div>
                                    <!-- // * CONTRACTED HOURS -->
                                    <div class="mr-1">
                                        <v-card flat height="75" class="align-centre" style="border-radius: 5px !important;">
                                            <v-card-actions class="justify-center text-center caption grey--text pt-1">CONTRACTED HOURS</v-card-actions>
                                            <v-card-title class="justify-center pa-0">
                                                <app-text category="stats-secondary">{{ staff.contractHours }}</app-text>
                                                <!-- <app-text category="stats-error"><number>0</number></app-text> -->
                                            </v-card-title>
                                            <!-- <v-card-actions class="justify-center text-center body-2 darkgrey--text">WEEKLY</v-card-actions> -->
                                        </v-card>
                                    </div>
                                    <!-- <h4>Hours allocated: {{ getUserHours() }}</h4>
                                    <h4>Contracted hours: {{ staff.contractHours}} </h4> -->
                                </v-row>
                                <!-- <v-row class="pt-2 pb-0 align-end justify-end">
                                    <div class="d-flex flex-row ">
                                        <div v-if="$moment(this.currentWeekStartDate).format('DD-MMM-YYYY') !== $moment(item.date).startOf('week').add(1, 'days').format('DD-MMM-YYYY')">
                                            <v-btn @click="computedStartDate" class="elevation-0 mr-2" width="155" color="primary" medium>
                                                <span class="white--text">JOB WEEK</span>
                                            </v-btn>
                                        </div>
                                        <div>
                                            <v-btn class="elevation-0 mr-2" @click="priorWeek" color="primary" medium>
                                                <v-icon class="icons8-prev"></v-icon>
                                                <span v-if="$moment(this.currentWeekStartDate).format('DD-MMM-YYYY') === $moment(item.date).startOf('week').add(1, 'days').format('DD-MMM-YYYY')" class="white--text">{{ $t(`buttons.previousWeek`) }}</span>
                                            </v-btn>
                                        </div>
                                        <div>
                                            <v-btn class="elevation-0 mr-1" @click="nextWeek" color="primary" medium>
                                                <v-icon class="icons8-right-button"></v-icon>
                                                <span v-if="$moment(this.currentWeekStartDate).format('DD-MMM-YYYY') === $moment(item.date).startOf('week').add(1, 'days').format('DD-MMM-YYYY')" class="white--text">{{ $t(`buttons.nextWeek`) }}</span>
                                            </v-btn>
                                        </div>
                                    </div>
                                </v-row> -->
                            </v-col>
                            <v-col cols=12 class="ma-0 pa-0">
                                <v-divider class="my-3 mx-1" />
                            <!-- <div v-for="item in eventDetails" :key="item.id">
                                <pre>{{ item.startDateTime }}</pre>
                                <pre>{{ item.endDateTime }}</pre>
                            </div>
                            <pre>{{ eventDetails }}</pre> -->
                            </v-col>
                            <v-col cols="12" class="pa-0 px-1 ma-0 align-end" xs="12" sm="12" md="12">
                                <v-row>
                                    <v-col cols="12" class="pr-0">
                                        <div class="d-flex flex-column">
                                            <div><h3 class="grey--text text--darken-1 pa-3 pb-0 pl-0">KEY</h3></div>
                                            <div class="d-flex align-center">
                                                <div class="d-flex justify-start align-center">
                                                    <v-icon v-if="job.jobAssignedStatus === 'ALLOCATED'" color="success" medium class="icons8-filled-circle font-weight-bold my-1"/>
                                                    <h5 v-if="job.jobAssignedStatus === 'ALLOCATED'" class="darkgrey--text pt-1">JOB IN VIEW</h5>
                                                </div>
                                                <div class="d-flex justify-center align-center">
                                                    <v-icon v-if="job.jobAssignedStatus === 'UNALLOCATED'" color="darkgrey" medium class="icons8-filled-circle font-weight-bold my-1"/>
                                                    <h5 v-if="job.jobAssignedStatus === 'UNALLOCATED'" class="darkgrey--text pt-1">JOB IN VIEW</h5>
                                                </div>
                                                <div class="d-flex justify-start align-center">
                                                    <v-icon color="success darken-3" medium class="icons8-filled-circle font-weight-bold my-1"/>
                                                    <h5 class="darkgrey--text pt-1">ALLOCATED JOBS</h5>
                                                </div>
                                                <div class="d-flex justify-start align-center">
                                                    <v-icon color="orange" medium class="icons8-filled-circle font-weight-bold my-1" />
                                                    <h5 class="darkgrey--text pt-1">HOLIDAY</h5>
                                                </div>
                                                <div class="d-flex justify-start align-center">
                                                    <v-icon color="pink" medium class="icons8-filled-circle font-weight-bold my-1"/>
                                                    <h5 class="darkgrey--text pt-1">ILLNESS</h5>
                                                </div>
                                            </div>
                                        </div>
                                            
                                    </v-col>
                                    <v-col cols="12" class="pt-0">
                                        <v-sheet style="height: 60vh !important" class="" rounded width=100%>
                                            <v-calendar
                                                ref="calendar"  
                                                :key="calendarComponentKey"
                                                v-model="value"
                                                interval-height="55" 
                                                event-overlap-mode="column" 
                                                interval-width="50" 
                                                :events="computedEvents" 
                                                :event-color="getEventColor" 
                                                :now="$moment(job.date).format('YYYY-MM-DD hh:mm:ss')" 
                                                :weekdays="weekday" 
                                                color="grey white--text" 
                                                :start="$moment(currentWeekStartDate).format('YYYY-MM-DD hh:mm:ss')" 
                                                type="week">
                                                <template v-slot:event="{ event }">
                                                    <div v-if="event.client" class="font-weight-bold">{{ event.client }} </div>
                                                    <div v-if="event.site">{{ event.site }}</div>
                                                    <div v-if="event.type !== 'Job' && event.type !== 'Recurring Job'" class="font-weight-bold">{{ event.type }}</div>
                                                    <div v-if="event.allDay === false" class="text-wrap">{{ $moment(event.start).format('HH:mm') }} - {{ $moment(event.end).format('HH:mm') }}</div>
                                                </template>
                                            </v-calendar>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>


        <v-dialog v-model="actionDialog" max-width="412" :key="dialogKey">
            <v-card>
                <v-toolbar color="secondary" elevation="0">
                    <app-text category="text-medium" class="white--text ml-2">{{ action }} Job?</app-text>

                    <v-spacer />
                    
                    <v-btn @click="cancelActionDialog()" icon dark>
                        <v-icon class="icons8-multiply" color="white" />
                    </v-btn>
                </v-toolbar>

                <div v-if="action === 'Cancel'" class="pa-3"> 
                    <div class="d-flex align-center">
                        <app-text v-if="action === 'Cancel'" category="" class="pr-2 font-weight-bold">Do you still want to bill the Client for this Job?</app-text>
                        <v-checkbox v-model="billClient" />
                    </div>
                    <app-text v-if="billClient" category="" class="">The Client will still be billed for this Job.</app-text>
                    <app-text v-else category="" class="">The Client will not be billed for this Job.</app-text>
                </div>

                <div v-if="action === 'Uncancel'" class="pa-3"> 
                    <app-text category="" class="">This will remove the Jobs 'Cancelled' Status, but the Job will need to be published before it appears on the Assigned Staff Members Rota.</app-text>
                </div>

                <div v-if="action === 'Unallocate'" class="pa-3"> 
                    <app-text category="" class="">This will remove the Assigned Staff Member from the Job and change the Job status to Unallocated.</app-text>
                </div>

                <div v-if="action === 'Allocate'" class="pa-3"> 
                    <div v-if="leaveClash === true" class="d-flex">
                        <v-icon size="40" class="error--text pb-4">icons8-high-importance</v-icon>
                        <app-text category="" class="font-weight-bold pb-4 pl-2">This Staff Member is scheduled to be on Leave, are you sure you want to Allocate this Job?</app-text>
                    </div>
                    <div v-if="jobClash === true" class="d-flex">
                        <v-icon size="40" class="error--text pb-4">icons8-high-importance</v-icon>
                        <app-text category="" class="font-weight-bold pl-2 pb-4">There is a Job clash, are you sure you want to allocate this Job? </app-text>
                    </div> 
                    <app-text category="" class="">This will allocate the Job to the Staff Member in view, but the Job will need to be Published before it will appear in the Staff Members Rota.</app-text>
                </div>

                <div v-if="action === 'Publish'" class="pa-3"> 
                    <app-text category="" class="">This will publish the Job to the Assigned Staff Members Rota.</app-text>
                </div>

                <div v-if="action === 'Draft'" class="pa-3"> 
                    <app-text category="" class="">This will remove the Job from the Assigned Staff Members Rota.</app-text>
                </div>

                <div v-if="action === 'Add User to Job'" class="pa-3"> 
                    <app-text category="" class="">This will add this Staff Member to the Job in View. You will still need to publish this Job to the Staff Members Rota.</app-text>
                </div>

                <div class="d-flex align-center pa-3">
                    <appbutton @click.native="cancelActionDialog()" label="Close" />
                    <v-spacer />
                    <appbutton v-if="action === 'Cancel'" @click.native="cancelJob()" type="primary" label="Cancel Job" />
                    <!-- <appbutton v-if="action === 'Edit'" @click.native="saveJob()" type="primary" label="Save Job" /> -->
                    <appbutton v-if="action === 'Uncancel'" @click.native="uncancelJob()" type="primary" label="Uncancel Job" />
                    <appbutton v-if="action === 'Unallocate'" @click.native="unallocate()" type="primary" label="Unallocate Job" />
                    <appbutton v-if="action === 'Allocate'" @click.native="allocate()" type="primary" label="Allocate Job" />
                    <appbutton v-if="action === 'Publish'" @click.native="publish()" type="primary" label="Publish Job" />
                    <appbutton v-if="action === 'Draft'" @click.native="draft()" type="primary" label="Draft Job" />
                    <appbutton v-if="action === 'Add User to Job'" @click.native="allocateAdditionalUser()" type="primary" label="Add User to Job" />
                </div>
            </v-card>
        </v-dialog>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
import schema from '@/schema.js';
import moment from 'moment';

export default {
    props: {
        job: {
            Type: String,
            Default: '',
        },
        staff: {
            Type: String,
            Default: '',
        },
        audit: {
            Type: Boolean,
            Default: false,
        },
        refreshItems: {
            type: Number, 
            default: 0
        }
    },
    // * DATA
    data: () => ({
        key: 'job',
        itemNew: true,
        jobSchema: {},
        staffSchema: {},
        org: {},
        editmode: false,

        // new job object
        item: {},
        loading: false,
        rules: {},

        staffItem: {},
        jobItem: {},

        startTimeHours: '', 
        startTimeMins: '',

        durationHours: '',
        durationMins: '',


        // dropdowns
        clients: [],
        sites: [],
        services: [],
        users: [],

        panel: [0],


        value: '',
        weekday: [1, 2, 3, 4, 5, 6, 0],
        currentWeekStartDate: moment().startOf('week').add(1, 'days'),
        calendarComponentKey: 0,

        searchQuery: '',

        action: '',
        actionDialog: false,
        dialogKey: 0,
        billClient: false,
        jobClash: false,
        leaveClash: false,

        jobs: [],
        events: [],

    }),
    computed: { 
        hours() {
            var hours = []
            for (var i = 0; i < 24; i++) {
                hours.push (i.toString())
            }
            return hours
        },  

        hoursWith0() {
            var hours = []
            for (var i = 0; i < 24; i++) {
                if (i < 10) {
                    hours.push ('0' + i).toString()
                } else {
                    hours.push (i.toString())
                }
            }
            return hours
        },

        mins() {
            var mins = []
            for (var i = 0; i < 60; i++) {
                if (i < 10) {
                    mins.push ('0' + i).toString()
                } else {
                    mins.push (i.toString())
                }
                i = i + 4;
            }
            return mins
        }, 
        computedAllocatedHours() {
            var t = this;

            var allocatedHours = 0 

            var events = t.jobs 

            events.forEach( function (item) {
                if (item.jobAssignedStatus == 'ALLOCATED') {
                    allocatedHours = allocatedHours + item.jobDuration
                }
            })

            return allocatedHours
        },

        computedEvents() {
            var t = this; 

            var events = [] 
            events = this.events 
            console.log('events = ' + JSON.stringify(events, null, 2))

            events.forEach(function(item) {
                if (item.leaveAllDay) {
                    item.start = moment(item.leaveStartDateTime).format('YYYY-MM-DD')
                    item.end = moment(item.leaveEndDateTime).format('YYYY-MM-DD')
                } else {
                    item.start = moment(item.leaveStartDateTime).format('YYYY-MM-DD HH:mm')
                    item.end = moment(item.leaveEndDateTime).format('YYYY-MM-DD HH:mm')
                }
            })


            events = events.map(e => {
                return {
                    entityId: e.entityId,
                    start: e.start,
                    end: e.end,
                    type: e.leaveType,
                    allDay: e.leaveAllDay,
                }
            })



            var jobs = [] 

            jobs = this.jobs 


            jobs = jobs.map(e => {
                return {
                    entityId: e.entityId,
                    start: moment(e.jobStartDateTime).format('YYYY-MM-DD HH:mm'),
                    end: moment(e.jobEndDateTime).format('YYYY-MM-DD HH:mm'),
                    type: e.jobType,
                    jobAssignedStatus: e.jobAssignedStatus,
                    jobPublishedStatus: e.jobPublishedStatus,
                    allDay: false,
                    client: this.getClient(e.jobClientId),
                    site: this.getSite(e.jobSiteId)
                }
            })

            events.forEach(function(item) {
                jobs.push(item)
            })


            return jobs
        },


        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            searchQuery = `@deleted:{false}`;

            searchQuery = `${searchQuery} @jobPublishedStatus:{ PUBLISHED | UNPUBLISHED}`

            searchQuery = `${searchQuery} @jobAssignedUserId:{${this.staffItem.entityId}}`

            var weekStart = moment(this.currentWeekStartDate).format('X')
            var weekEnd = moment(this.currentWeekStartDate).endOf('week').add(1, 'days').format('X')

            //console.log('weekstart = ' + weekStart)
            //console.log('weekEnd = ' + weekEnd)
            searchQuery = `${searchQuery} @jobStartDateTime:[${weekStart} +inf]`

            searchQuery = `${searchQuery} @jobStartDateTime:[-inf ${weekEnd}]`
            
            //console.log(searchQuery);

            return searchQuery;
        },
        computedLeaveSearchQuery() {
            let searchQuery = this.searchQuery;
            searchQuery = '@deleted:{false}';

            searchQuery = `${searchQuery} @leaveAssignedUserId:{${this.staffItem.entityId}}`
            var startOfWeek = this.$moment(this.currentWeekStartDate).format('X')
            searchQuery = `${searchQuery} @leaveStartDateTime:[${startOfWeek} +inf]`
            var endOfWeek = this.$moment(this.currentWeekStartDate).add(7, 'days').format('X')
            searchQuery = `${searchQuery} @leaveEndDateTime:[-inf ${endOfWeek}]`
            
            // console.log(searchQuery);

            return searchQuery;
        },

 
    },
    methods: {
        getClient(clientId) {
            var t = this;
            var client = []
            var clientName = ''

            client = t.clients.filter((item) => { return item.entityId == clientId })

            if (client.length !== 0) { clientName = client[0].clientName }
            return clientName
        },
        getSite(siteId) {
            var t = this;
            var site = []
            var siteName = ''

            site = t.sites.filter((item) => { return item.entityId == siteId })

            if (site.length !== 0) { siteName = site[0].siteName }

            return siteName
        },
        async checkClash() {
            var t = this;
            
            var job = t.jobItem 

            var jobClash = false 
            var leaveClash = false

            var jobStart = this.$moment(job.jobStartDateTime).format('X')
            var jobEnd = this.$moment(job.jobEndDateTime).format('X')

            let jobsResult = await this.REDIS_searchFor(
                'jobs', 
                0, 
                9999, 
                'jobStartDateTime', 
                'ASC', 
                `@deleted:{false} @jobAssignedUserId:{${t.staffItem.entityId}} @jobStartDateTime:[${jobStart} ${jobEnd}] | @jobEndDateTime:[${jobStart} ${jobEnd}]`)

            if (jobsResult.success) {
                var jobsClashes = jobsResult.data.documents
                if (jobsClashes.length > 0) {
                    jobClash = true
                }
            }
            let leaveResult = await this.REDIS_searchFor(
                'leave', 
                0, 
                9999, 
                'leaveStartDateTime', 
                'ASC', 
                `@deleted:{false} @leaveAssignedUserId:{${t.staffItem.entityId}} @leaveStartDateTime:[${jobStart} ${jobEnd}] | @leaveEndDateTime:[${jobStart} ${jobEnd}]`)

            if (leaveResult.success) {
                var leaveClashes = leaveResult.data.documents
                if (leaveClashes.length > 0) {
                    leaveClash = true
                }
            }

            var dayStart = this.$moment(job.jobStartDateTime).startOf('day').format('X')
            // console.log(dayStart)
            var dayEnd = this.$moment(job.jobEndDateTime).endOf('day').format('X')
            // console.log(dayEnd)


            let leaveAllDayResult = await this.REDIS_searchFor(
                'leave', 
                0, 
                9999, 
                'leaveStartDateTime', 
                'ASC', 
                `@deleted:{false} @leaveAssignedUserId:{${t.staffItem.entityId}} @leaveStartDateTime:[${dayStart} ${dayEnd}]`)

            // console.log('leaveAllDayResult = ' + JSON.stringify(leaveAllDayResult, null, 2))

            if (leaveAllDayResult.success) {
                var leaveClashes = leaveAllDayResult.data.documents
                // console.log('leaveClashes = ' + JSON.stringify(leaveClashes, null, 2))
                if (leaveClashes.length > 0) {
                    leaveClash = true
                }
            }


            t.jobClash = jobClash
            t.leaveClash = leaveClash

            t.actionDialog = true
        },

        async openActionDialog(action) {
            var t = this;
            // //console.log("here open action dialog")
            t.action = action;
            // t.actionDialog = true;
            if (action === 'Allocate' || action === 'Edit') {
                t.checkClash()
            } else {
                t.actionDialog = true;
            }
            // if (action === 'Edit') {
            //     await this.initializeEndTime()
            // }
        },
        cancelActionDialog() {
            var t = this;
            t.actionDialog = false;
            t.action = '';
        },


        cancelJob() {
            var t = this;
            this.cancelActionDialog();
            t.jobItem.jobPublishedStatus = "CANCELLED";
            // if (t.billClient) {
            //     t.form.billStatus = true
            // } else {
            //     t.form.billStatus = false
            // }
            this.updateItem();
            // this.openStaff(t.userData.id, 'View', false)
        },
        uncancelJob() {
            var t = this;
            this.cancelActionDialog();
            t.jobItem.publishedStatus = "UNPUBLISHED";

            this.updateItem();
            // this.openStaff(t.userData.id, 'View', false)
        },
        unallocate() {
            var t = this;
            t.cancelActionDialog();
            t.jobItem.jobAssignedStatus = "UNALLOCATED";
            this.jobItem.jobPublishedStatus = 'UNPUBLISHED'
            this.jobItem.jobAssignedUserId = '';
            
            this.updateItem();
            // this.openStaff(t.userData.id, 'View', false)
        },

        draft() {
            var t = this;
            t.cancelActionDialog();
            this.jobItem.jobPublishedStatus = 'UNPUBLISHED';
            this.updateItem();
        },

        publish() {
            var t = this;
            t.cancelActionDialog();
            this.jobItem.jobPublishedStatus = 'PUBLISHED';
            this.updateItem();
        },

        allocate() {
            var t = this;
            t.cancelActionDialog();
            
            this.jobItem.jobAssignedStatus = "ALLOCATED";
            this.jobItem.jobAssignedUserId = t.staffItem.entityId;
            this.jobItem.jobPublishedStatus = 'UNPUBLISHED'
            
            this.updateItem();
            // this.openStaff(t.userData.id, 'View', false)
        },
        allocateAdditionalUser() {
            var t = this;

            t.cancelActionDialog();
            
            this.jobItem.jobAssignedUserId = t.staffItem.entityId;

            t.saveItem();
            // this.openStaff(t.userData.id, 'View', false)
        },





    

        nowY (timeToY) {
            return this.cal ? timeToY(this.cal.times.now) + 'px' : '-10px'
        },
        cal () {
            return this.ready ? this.$refs.calendar : null
        },
        getEventColor(event) {
            if (event.entityId === this.jobItem.entityId) {
                if (event.jobAssignedStatus === 'ALLOCATED') {
                    return 'success '
                }
                else if (event.jobAssignedStatus === 'UNALLOCATED') {
                    return 'darkgrey'
                }
            } 
            if (event.type === "Holidays") {
                return 'orange'
            } else if (event.type === 'Job' || event.type === 'Recurring Job') {
                return 'success darken-2'
            } else if (event.type !== '' && event.type !== 'UNKNOWN') {
                return 'pink'
            } else {
                return 'grey'
            }
        },
        initialise() {
            this.jobItem = { ...this.job };

            this.currentWeekStartDate = moment(this.jobItem.jobStartDateTime).startOf('week').add(1, 'days')
            this.staffItem = { ...this.staff };
            //console.log('job', JSON.stringify(this.jobItem, null, 2));
            //console.log('staff', JSON.stringify(this.staffItem, null, 2));
        },
        updateItem() {
            this.$emit('update', this.jobItem);
            this.getItems()
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {
            this.$emit('save', this.jobItem);
            this.editmode = false;
            this.getItems()
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // * GET ITEMS
        async getItems() {
            let itemsResult = await this.REDIS_searchFor('jobs', 0, 9999, 'jobStartDateTime','ASC', this.computedSearchQuery )
            if (itemsResult.success) {
                this.jobs = itemsResult.data.documents;
                if (this.jobItem.jobAssignedStatus === 'UNALLOCATED') {
                    this.jobs.push(this.jobItem)
                }
            }

            let eventsResult = await this.REDIS_searchFor('leave', 0, 9999, 'leaveStartDateTime','ASC', this.computedLeaveSearchQuery )
            if (eventsResult.success) {
                this.events = eventsResult.data.documents;
            }

        },
        getSite(siteId) {
            var t = this;
            var site = []
            var siteName = ''            

            site = t.sites.filter((item) => { return item.entityId == siteId })

            if (site.length !== 0) { siteName = site[0].siteName }

            return siteName
        },
    },
    watch: {
        job: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        staff: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        // activeitem: {
        //     handler: function () {
        //         let t = this;
        //         t.item = { ...t.activeitem };
        //     }, deep: true
        // },
        selectedPackageIcon: {
            handler: function () {
                this.item.packageIcon = this.selectedPackageIcon.icon;
            }, deep: true
        },
        refreshItems: {
            handler: function () {
                this.getItems()
            },
            deep: true
        }

    },
    // * CREATED
    async created() {
        let t = this;
        t.jobSchema = schema.job;
        t.staffSchema = schema.user;
        
        this.jobItem = t.jobSchema
        this.staffItem = t.staffSchema
        
        let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false} -@clientStatus:{ARCHIVE}');
        if (clients.success) { t.clients = clients.data.documents; }
        t.clients = t.clients.map((x) => {
            return {
                entityId: x.entityId,
                clientName: x.clientName,
            };
        })
        
        let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
        if (sites.success) { t.sites = sites.data.documents; }

        t.sites = t.sites.map((x) => {
            return {
                entityId: x.entityId,
                siteName: x.siteName,
            };
        })
        t.initialise();

        t.getItems()


        // t.loading = false
    },
    mounted() {},
};
</script>

<style scoped>

.v-expansion-panel-content__wrap {
    padding: 0 0px 0px !important;
}
.v-expansion-panel::before {
    box-shadow: none;
}
.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
    padding: 0 0px 0px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    flex: 1 1 auto;
    max-width: 100%;
}
</style>