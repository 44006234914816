<template>
    <v-row v-if="loading" class="d-flex justify-center align-center" height=100% no-gutters>
        <v-col cols="12" class="text-center mt-12 pt-12">
            <v-progress-circular size="100" indeterminate color="primary"></v-progress-circular>
        </v-col>
    </v-row>
    <!-- dont allow swapp if outstandingjobs -->
    <v-row class="mt-7 align-center" height="100%" no-gutters v-else-if="notCompletedJobs">
        <!-- not finished jobs tut tut -->
        <v-col cols="12" class="text-center">
            <h3 class="font-weight-medium">You have not ended your Jobs</h3>
        </v-col>
        <v-col cols="12" class="text-center mt-8">
            <v-icon class="error--text" size="150">icons8-high-importance</v-icon>
        </v-col>
        <!-- go to jobs button -->
        <v-col  cols="12" class="mt-8 pr-1 text-center">
            <!-- <appbutton class="pr-2" sizeoverride="medium" width="200" label="VIEW JOBS" type="grey" @click.native="MIX_go('/')" /> -->
            <v-btn class="elevation-0 mt-1" color="grey" medium>
                <span @click="MIX_go('/swappedJobs')" class="white--text">RETURN TO JOBS</span>
            </v-btn>
        </v-col>
    </v-row>
    <!-- display qr code reader -->
    <v-row justify="space-around" no-gutters v-else-if="showSwappMessage === false && swappDifficulties === false">
        <!-- qr code reader component -->
        <v-col cols="12" class="my-0 py-0">
            <qrCodeReader/>
        </v-col>
        <!-- instructions -->
        <v-row class="text-left mx-2">
            <v-col cols="12" class="">
                <app-text category="page-title" class="primary--text text-uppercase">SWAPP <span class="secondary--text">{{ $route.params.direction }}</span></app-text>
            </v-col>
            <v-col cols="12" class="py-0">
                <app-text category="tab-text">1. Allow your camera when prompted</app-text>
            </v-col>
            <v-col cols="12" class="py-0">
                <app-text category="tab-text">2. Point your camera at the poster</app-text>
            </v-col>
            <v-col cols="12" class="py-0">
                <app-text category="tab-text">3. Make sure the QR Code is visible in the box above.</app-text>
            </v-col>
            <v-col cols="12" class="mt-8 d-flex justify-center">
                <v-btn class="elevation-0 mt-1" color="secondary" medium>
                    <span @click="swappDifficulties = true" class="white--text">Can't swapp {{ $route.params.direction }}?</span>
                </v-btn>
            </v-col>
            <!-- here: {{locationDetails}}
            {{GET_QR_Result}} -->
        </v-row>
    </v-row>
    <!-- display qr code result -->
    <v-row class="mt-10 align-center" height=100% no-gutters v-else-if="showSwappMessage === true && swappDifficulties === false">
        <v-col cols="12" class="text-center">
            <h3 class="font-weight-medium">You have been SWAPPed <span class="text-uppercase">{{ $route.params.direction }}</span></h3>
        </v-col>
        <v-col cols="12" class="text-center">
            <v-icon class="primary--text" size="150">icons8-checkmark-yes</v-icon>
        </v-col>
        <v-col cols="12" class="text-center">
            <h3 class="font-weight-medium text-uppercase">{{ $route.params.direction }}</h3>
        </v-col>
        <v-col v-if="locationDetails != null && locationDetails != undefined" cols="12" class="text-center">
            <h3 class="text-uppercase">{{ locationDetails.locationName }}</h3>
        </v-col>
        <v-col cols="12" class="text-center">
        </v-col>
        <!-- go home button -->
        <v-col v-if="$route.params.direction == 'in'" cols="12" class="mt-8 pr-1 text-center">
            <!-- <appbutton class="pr-2" sizeoverride="medium" width="200" label="VIEW JOBS" type="grey" @click.native="MIX_go('/')" /> -->
            <v-btn class="elevation-0 mt-1" color="grey" medium>
                <span @click="MIX_go('/swappedJobs')" class="white--text">VIEW JOBS</span>
            </v-btn>
        </v-col>
        <!-- <v-col v-if="$route.params.direction == 'in'" cols="12" class="pr-1 text-center">
            <v-btn class="elevation-0 mt-1" color="grey" medium>
                <span @click="MIX_go('/swappedJobs')" class="white--text">VIEW TASKS</span>
            </v-btn>
        </v-col> -->
        <v-col v-if="$route.params.direction == 'in'" cols="12" class="pr-1 text-center">
            <!-- <appbutton class="pr-2" sizeoverride="medium" width="200" label="VIEW JOBS" type="grey" @click.native="MIX_go('/')" /> -->
            <v-btn class="elevation-0 mt-1" color="grey" medium>
                <span @click="MIX_go('/communications/:id')" class="white--text">VIEW COMMUNICATIONS</span>
            </v-btn>
        </v-col>
        <v-col v-if="$route.params.direction == 'in'" cols="12" class="pl-1 text-center">
            <v-btn class="elevation-0 mt-1" color="secondary" medium>
                <span @click="MIX_go('/home')" class="white--text">HOME</span>
            </v-btn>
        </v-col>
        <v-col v-else-if="$route.params.direction == 'out'" cols="12" class="mt-8 d-flex justify-center">
            <v-btn class="elevation-0 mt-1" width="120" color="secondary" medium>
                <span @click="MIX_go('/home')" class="white--text">HOME</span>
            </v-btn>
        </v-col>
    </v-row>
    <v-row class="mt-7 align-center" height="100%" no-gutters v-else-if="swappDifficulties">
        <v-col cols="12" class="text-center">
            <v-btn class="elevation-0" color="secondary" medium>
                <span @click="swappQR()" class="white--text">SWAPP {{ $route.params.direction }} with qr code</span>
            </v-btn>
        </v-col>
        <v-col cols="12" class="text-left pa-3 pt-6">
			<v-card flat class="pa-4 mb-3 grey lighten-4">
				<div class="text-h7 secondary--text font-weight-bold">
                    Having Trouble SWAPPING 
                    <span class="text-uppercase">{{ $route.params.direction }}</span>
                    <span v-if="$route.params.direction == 'out'"> 
                    from {{ getSite(user.lastSiteId) }}</span>?
                </div>
				<div class="body-2 grey--text text--darken-1">
					<p>If you're having trouble SWAPPING <span class="text-capitalize">{{ $route.params.direction }} </span> using the QR Code, select the reason from the list below.</p>
				</div>
                <v-col class="pa-0" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                     <v-select label="Reason?" v-model="swappReason" required :items="swappReasons" item-text="text" item-value="value" dense background-color="grey lighten-4" :menu-props="{ top: false, offsetY: true}" filled hide-details="auto" class="mb-2">
                        <template v-slot:item="data">
                            {{ data.item }}
                        </template>
                        <template v-slot:selection="data">
                            {{ data.item }}
                        </template>                                    
                    </v-select>
                </v-col>
                <v-col v-if="swappReason" class="pa-0" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <v-autocomplete 
                    label="Site" 
                    v-model="swappSite" 
                    @change="getLocations()" 
                    required 
                    :items="sites" 
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    auto-select-first 
                    item-text="siteName" 
                    return-object 
                    dense 
                    filled
                    hide-details="auto" 
                    background-color="grey lighten-4" 
                    color="primary"
                    class="mb-2">
                        <template v-slot:item="data">
                            {{ data.item.siteName }}
                        </template>
                        <template v-slot:selection="data">
                            {{ data.item.siteName }}
                        </template>                                    
                    </v-autocomplete>
                </v-col>
                <v-col v-if="swappSite" class="pa-0" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <v-select :menu-props="{ top: false, offsetY: true }" label="Location?" v-model="swappLocation" required :items="locations" item-text="text" item-value="value" no-data-text="No Location found for this Site" dense background-color="grey lighten-4" filled hide-details="auto" class="mb-2">
                        <template v-slot:item="data">
                            {{ data.item.locationName }}
                        </template>
                        <template v-slot:selection="data">
                            {{ data.item.locationName }}
                        </template>                                    
                    </v-select>
                </v-col>
                <v-col v-if="swappLocation && $route.params.direction == 'in'" cols="12" class="py-0 pt-3">
                    <div class="text-h7 font-weight-bold">{{ swappLocation.locationName }}, {{ swappSite.siteName }}</div>
                    <v-row class="py-2">
                        <v-col cols="2" class="d-flex align-center justify-center">
                            <v-icon size="35" class="subtitle pl-4 darkgrey--text icons8-skyscrapers"></v-icon>
                        </v-col>
                        <v-col cols="10">
                            <div class="text-h7">{{ swappSite.siteAddressLine1  }}</div>
                            <div class="text-h7">{{ swappSite.siteAddressTown  }}</div>
                        </v-col>
                    </v-row>
                    <div class="text-h7 font-weight-bold">Site Contact</div>
                    <v-row class="py-2">
                        <v-col cols="2" class="d-flex align-center justify-center">
                            <v-icon size="35" class="subtitle pl-4 darkgrey--text icons8-customer"></v-icon>
                        </v-col>
                        <v-col cols="10">
                            <div class="text-h7">{{ swappSite.siteContactName  }}</div>
                            <div class="text-h7">{{ swappSite.siteContactMobile  }}</div>
                        </v-col>
                    </v-row>
                    <!-- <app-text category="tab-text">{{ swappSite }}</app-text> -->
                </v-col>
                <v-btn v-if="swappLocation && $route.params.direction == 'out'" class="elevation-0 mt-2" width="100%" color="secondary" medium>
                    <span @click="swapp()" class="white--text">SWAPP {{ $route.params.direction }} from this site</span>
                </v-btn>
                <v-btn v-if="swappLocation && $route.params.direction == 'in'" class="elevation-0 mt-2" width="100%" color="secondary" medium>
                    <span @click="swapp()" class="white--text">SWAPP {{ $route.params.direction }} to this site</span>
                </v-btn>
			</v-card>
            <!-- <pre>{{ user }}</pre>
            <pre>{{ form }}</pre> -->
        </v-col>

    </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import schema from "@/schema.js";

export default {
    name: "Swapp",
    data: () => ({
        locationDetails: [], // store the qr code location result
        form: {}, // store the curr user details - firebase
        user: {}, // store the current user details - redis
        showSwappMessage: false, // show qr code reader/result 
        swappDifficulties: false,  // show manual swapp in/out option
        swappReason: '',
        swappReasons: [
            'Already Left Site',
            'Cannot find QR Code',
            'Camera not working',
            'Unable to scan QR Code'
        ],
        swappSite: '',
        swappLocation: '',
        sitesData: [],
        locations: [],
        sites: [],
        fullSiteList: [],
        schema: null,

        loading: false,
        notCompletedJobs: false
    }),
    computed: {
        ...mapGetters({
            GET_QR_Result: 'qr_code_reader_store/GET_getResult', // impoert qr code result from store
            GET_currentUser: 'GET_currentUser',
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
        //check route direction value and return 
        returnSwappValue(){
            if(this.$route.params.direction === 'in'){
                return 1;
            } else {
                return 0;
            }
        },
    },
    // * WATCH
    watch: {
        // Watch for a change in the QR CODE RESULT
        GET_QR_Result: {
            handler() {
                this.getLocation(); // trigger get location details based on qr code result
            }, deep: true
        },
        // watch for a change in the location result
        locationDetails: {
            handler() {
                // this.swappLocation = this.locationDetails
                this.swapp(); // trigger update swapp status when a location is returned
            },
            deep: true
        }
    },
    methods: {
        getSite(siteId) {
            var t = this;
            var site = []
            var siteName = ''

            site = t.fullSiteList.filter((item) => { return item.entityId == siteId })

            if (site.length !== 0) { siteName = site[0].siteName }

            return siteName
        },
        swappQR() {
            var t = this;

            t.swappDifficulties = false
            t.swappReason = '',
            t.swappSite = '',
            t.swappLocation = ''

        },
        async getLocation(){
            var t = this
            console.log('GET_QR_Result : ' + JSON.stringify(this.GET_QR_Result, null, 2))
            if (this.GET_QR_Result !== null  || this.GET_QR_Result !== '' || this.GET_QR_Result != undefined) {
                let locationResult = await t.REDIS_read('location', t.GET_QR_Result);
                console.log('locationResult : ' + JSON.stringify(locationResult, null, 2))
                if (locationResult.success) {
                    this.locationDetails = locationResult.data;
                    this.swappLocation = locationResult.data;
                } else {
                    // console.log('locationResult : ' + locationResult.message)
                }
            } 
        },
        async createSwappLog(method) {
            var t = this; 
            console.log('user =' + JSON.stringify(this.GET_FIREBASE_currentUser, null, 2))
            console.log('swappLocation =' + JSON.stringify(this.swappLocation, null, 2))

            var swappLog = t.schema
            var dateTimeNow = this.$moment().toISOString()

            swappLog.swappSiteId = this.swappLocation.locationSiteId;
            swappLog.swappLocationId = this.swappLocation.entityId;
            swappLog.swappUserId = this.GET_FIREBASE_currentUser.entityId
            swappLog.swappStatus = this.returnSwappValue; // set Swapp status
            swappLog.swappMethod = method

            swappLog.createdDateTime = dateTimeNow;
            swappLog.createdUserId = this.GET_FIREBASE_currentUser.entityId
            console.log('swappLog =' + JSON.stringify(swappLog, null, 2))


            let result = await this.REDIS_create('swapp-log', swappLog)

			if (result.success) {
                console.log('swapp log created =' + JSON.stringify(result, null, 2))
			} else {
                console.log('issue creating swappLog =' + JSON.stringify(result, null, 2))
			}

        },
        async swapp() {
            var t = this;

            if (this.swappDifficulties) {
                t.createSwappLog('FORM'); // create a swapp log
                this.user.swappMethod = 'FORM';
                this.form.swappMethod = 'FORM'; 
            }
            else {
                t.createSwappLog('QRPHONE'); // create a swapp log
                this.user.swappMethod = 'QRPHONE';
                this.form.swappMethod = 'QRPHONE'; 
            }

            //update the swapp status in the user document - redis                
            this.user.lastSiteId = this.swappLocation.locationSiteId;
            this.user.swappStatus = this.returnSwappValue; // set Swapp status
            this.user.lastLocationId = this.swappLocation.entityId;


            var dateTimeNow = this.$moment().format('x')
            this.user.modifiedDateTime = dateTimeNow;
            this.user.modifiedUserId = t.user.entityId;

            let result = await this.REDIS_update('user', this.user.entityId, this.user);
            
			if (result.success) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Updated", show: true });
                t.MIX_FIREBASE_currentUser(this.user)
			}

            // update the swapp status in the user document - firebase
            this.form.lastLocation.locationId = this.swappLocation.entityId;
            this.form.lastLocation.locationName = this.swappLocation.locationName;
            this.form.lastSite.siteId = this.swappLocation.locationSiteId;
            this.form.lastSite.siteName = this.swappSite.siteName;
            this.form.swappStatus = this.returnSwappValue; // set Swapp status
            
            // update the swapp status in the user document
            var updateDocumentResult = await this.MIX_updateDocument('users', this.form)
            if (updateDocumentResult.code === 1) {
			    t.MIX_alertBox({ color: "success", timeout: 2000, message: "Updated", show: true });
                this.swappDifficulties = false
                this.showSwappMessage = true; // show success message
            } else {
			    t.MIX_alertBox({ color: "error", timeout: 2000, message: "ERROR - Could not update Status", show: true });
                this.showSwappMessage = false; // show error message
            }
        },
        async getUser() {
            var t = this; 

            var itemId = this.GET_FIREBASE_currentUser.entityId

            console.log('itmId = ' + itemId)

			let result = await t.REDIS_read('user', itemId);
			if (result.success) {
				this.user = result.data;
			}

            t.$firebase.db.collection('users').where("id", "==", itemId)
                .onSnapshot(function(querySnapshot) {
                    t.form = [];
                    querySnapshot.forEach(function(doc) {
                        var document = doc.data()
                        document.id = doc.id;
                        t.form.push(document);
                    });
                    t.form = t.form[0]
            });

            

            return
        },
        //GET SITES METHOD
        getSites: async function () {
            var t = this;

            // get user jobs for this week 
            // var startOfWeek = t.$moment().startOf('week').format('X')
            var userSites = await this.getUserSiteList()
            
            let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', `@deleted:{false}`);
            if (sites.success) { t.fullSiteList = sites.data.documents; }

            t.fullSiteList = t.fullSiteList.map((x) => {
                return {
                    entityId: x.entityId,
                    siteName: x.siteName,
                    siteAddress: x.siteAddressLine1 + ', ' + x.siteAddressTown,
                    siteContactName: x.siteContactName,
                    siteContactEmail: x.siteContactEmail
                };
            })

            // filter sites based on user jobs if cleaner
            if (t.user.userLevel !== 'SA' && t.user.userLevel !== 'DA') {
                t.sites = t.fullSiteList.filter((item) => {
                    return userSites.includes(item.entityId) || item.entityId == t.user.lastSiteId
                })
            } else {
                t.sites = t.fullSiteList
            }
            return
            // console.log('sites = ' + JSON.stringify(this.sites, null, 2))

        },
        //GET USER SITES METHOD
        getUserSiteList: async function () {
            var t = this; 

            var startOfWeek = t.$moment().startOf('week').format('X')
            var endOfWeek = t.$moment().endOf('week').format('X')
            
            let userJobs = []
            let userJobsSearch = await t.REDIS_searchFor('jobs', '0', '9999', 'jobStartDateTime', 'ASC', `@deleted:{false} @jobPublishedStatus:{PUBLISHED} @jobStartDateTime:[${startOfWeek} ${endOfWeek}] @jobAssignedUserId:{${this.GET_FIREBASE_currentUser.entityId}}`);
            if (userJobsSearch.success) { userJobs = userJobsSearch.data.documents; }

            // get siteid from all jobs and put into array
            let siteIds = userJobs.map((x) => {
                return x.jobSiteId
            })

            return siteIds

        },
        // GET LOCATIONS METHOD
        getLocations: async function () {
            var t = this;

            console.log('swappSite = ' + JSON.stringify(this.swappSite, null, 2))
            
            if (this.swappSite) {
                let locations = await t.REDIS_searchFor('location', '0', '9999', 'locationName', 'ASC', `@deleted:{false} @locationSiteId:{${this.swappSite.entityId}}`);
                if (locations.success) { t.locations = locations.data.documents; }

                t.locations = t.locations.map((x) => {
                    return {
                        entityId: x.entityId,
                        locationName: x.locationName,
                        locationSiteId: x.locationSiteId,
                    };
                })
            }
            return

        },
        async checkUserJobs() {
            var t = this; 


            var startOfDay = t.$moment().startOf('day').format('X')
            var endOfDay = t.$moment().endOf('day').format('X')
            console.log('startOfDay = ' + startOfDay)
            console.log('endOfDay = ' + endOfDay)

            let jobs = await t.REDIS_searchFor('jobs', '0', '9999', 'jobStartDateTime', 'ASC', `@deleted:{false} @jobStartDateTime:[${startOfDay} ${endOfDay}] @jobAssignedUserId:{${this.GET_FIREBASE_currentUser.entityId}} @swappStatus:{started}`);
            if (jobs.success && jobs.data.total > 0) { 
                // jobs = jobs.data.documents; 
                // console.log('jobs = ' + JSON.stringify(jobs, null, 2))
                t.notCompletedJobs = true
            }
            return
        }
    },
    async mounted() {
        this.loading = true
        await this.getUser()
        await this.getSites()
        await this.checkUserJobs()
        // this.getLocations()
        this.MIX_setQrCode(''); // prevents cached values for qr codes

        this.schema = schema.swappLog
        this.loading = false
    }
}
</script>