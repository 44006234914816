<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>

                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="secondary white--text mr-2" v-if="editmode && !itemNew && item.clientStatus !== 'ARCHIVE'" @click="archiveDialog = true"><v-icon class="mr-2">icons8-xbox-a</v-icon>Archive</v-btn>
                    <v-btn text class="primary white--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
                    <v-btn text class="primary white--text" v-if="editmode" @click="validateItem()"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn>
                    <!-- <v-btn text class="primary white--text" v-if="editmode && !itemNew" @click="updateItem()"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn> -->
                </div>
            </v-col>
        </v-row>
        <v-row class="apptext--text" dense style="z-index: 0 !important">
            <v-col v-if="!editmode && !loading" cols="12" class="d-flex pa-2 align-center">

                <!-- //* VIEW MODE -->
                <v-row style="width: 100% !important" no-gutters>
                    <!-- //* GENERAL DETAILS VIEW -->
                    <v-col cols="12" sm="12" md="6" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Client Details</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">icons8-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Name:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.clientName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Telephone:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.clientTelephone }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Start Date:</td>
                                                <!-- <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ $moment(item.clientStartDateTime).format('DD-MMM-YYYY') }}</td> -->
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{  MIX_formatDateTime(item.clientStartDateTime, '', 'DD-MMM-YYYY') }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Type:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.clientType }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Status:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.clientStatus }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Contract Hours:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.clientContractedHours }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Client Address</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">icons8-map-marker</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Address:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">
                                                    <a :href="`https://www.google.com/maps/search/?api=1&query=${item.clientAddressLine1},${item.clientAddressLine2},${item.clientAddressLine3},${item.clientAddressTown},${item.clientAddressCounty},${item.clientAddressPostcode}`" target="_blank">
                                                        <span v-if="item.clientAddressLine1"
                                                            >{{ item.clientAddressLine1 }}<span v-if="item.clientAddressLine2">, {{ item.clientAddressLine2 }}</span
                                                            ><br
                                                        /></span>
                                                        <span v-if="item.clientAddressLine3">{{ item.clientAddressLine3 }}<br /></span>
                                                        <span v-if="item.clientAddressTown"
                                                            >{{ item.clientAddressTown }}<span v-if="item.clientAddressCounty">, {{ item.clientAddressCounty }}</span
                                                            ><br
                                                        /></span>
                                                        <span v-if="item.clientAddressPostcode">{{ item.clientAddressPostcode }}<br /></span>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Client Contact</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">icons8-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Name:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.clientContactName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Position:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.clientContactTelephone }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Telephone:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.clientContactTelephone }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Mobile:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.clientContactMobile }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Email:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.clientContactEmail }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Client Emergency Contact</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">icons8-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Name:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.clientEmergencyContactName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Position:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.clientEmergencyContactTelephone }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Telephone:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.clientEmergencyContactTelephone }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Mobile:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.clientEmergencyContactMobile }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Email:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.clientEmergencyContactEmail }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Client Notes</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">icons8-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Notes:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.clientNotes }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="editmode && !loading" cols="12" class="d-flex pa-4 pt-4 align-center">
                <v-form ref="form" lazy-validation>
                    <v-row style="width: 100% !important" no-gutters>
                        <!-- DETAILS EDIT -->
                        <!-- <v-col cols="6">
                            <pre class="caption">{{ item }}</pre>
                        </v-col> -->
                        <!-- <v-col cols="6">
                            <pre class="caption">{{ selectedPackageIcon }}</pre>
                        </v-col> -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Client Name" :rules="rules.required" v-model="item.clientName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('clientName')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Client Telephone" v-model="item.clientTelephone" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('clientTelephone')"></v-text-field>
                                </v-col>
                                <!-- // TODO CHANGE TO DATEPICKER -->
                                <v-col cols="12" xs="12" sm="6">
                                    <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field label="Client Start Date" v-model="computedDate2" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('clientStartDateTime')"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date2" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu2 = false"></v-date-picker>
                                    </v-menu>
                                    <!-- <v-text-field label="Client Start Date" v-model="item.clientStartDateTime" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceDescription')"></v-text-field> -->
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-select 
                                        class="" 
                                        hide-details="auto" 
                                        label="Client Type" 
                                        :rules="rules.clientType" 
                                        filled 
                                        v-model="item.clientType" 
                                        required 
                                        outlined
                                        dense
                                        @click:clear="clearItem('clientType')"
                                        :items="clientTypes" 
                                        background-color="white" 
                                        color="primary"
                                        :menu-props="{ top: false, offsetY: true }" 
                                        :attach="true"
                                        item-text="text"
                                        item-value="id"
                                        :item-disabled="checkIsItemDisabled"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-select 
                                        class="" 
                                        hide-details="auto" 
                                        label="Client Status" 
                                        :rules="rules.clientStatus" 
                                        filled 
                                        v-model="item.clientStatus" 
                                        required 
                                        outlined
                                        dense
                                        @click:clear="clearItem('clientStatus')"
                                        :items="clientStatus" 
                                        background-color="white" 
                                        color="primary"
                                        :menu-props="{ top: false, offsetY: true }" 
                                        :attach="true"
                                        item-text="text"
                                        item-value="id"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field 
                                    label="Client Contract Hours" 
                                    v-model="item.clientContractedHours" 
                                    type="number" 
                                    :oninput="item.clientContractedHours < 0 ? item.clientContractedHours = 0 : ''"
                                    outlined 
                                    dense 
                                    hide-details="auto" 
                                    background-color="white" 
                                    color="primary" 
                                    clearable 
                                    @click:clear="clearItem('clientContractedHours')">
                                    </v-text-field>
                                </v-col>
                                
                            </v-row>


                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Address</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Client Address Line 1" v-model="item.clientAddressLine1" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceName')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Client Address Line 2" v-model="item.clientAddressLine2" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceDescription')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Client Address Line 3" v-model="item.clientAddressLine3" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceDescription')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Client Town" v-model="item.clientAddressTown" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceDescription')"></v-text-field>
                                </v-col>    
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Client County" v-model="item.clientAddressCounty" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceDescription')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Client Postcode" v-model="item.clientAddressPostcode" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceDescription')"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Contact Details</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Client Contact Name" v-model="item.clientContactName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceName')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Client Contact Position" v-model="item.clientContactPosition" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceDescription')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Client Contact Telephone" v-model="item.clientContactTelephone" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceDescription')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Client Contact Mobile" v-model="item.clientContactMobile" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceDescription')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Client Contact Email" v-model="item.clientContactEmail" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceDescription')"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Emergency Contact Details</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Client Emergency Contact Name" v-model="item.clientEmergencyContactName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceName')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Client Emergency Contact Position" v-model="item.clientEmergencyContactPosition" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceDescription')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Client Emergency Contact Telephone" v-model="item.clientEmergencyContactTelephone" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceDescription')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Client Emergency Contact Mobile" v-model="item.clientEmergencyContactMobile" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceDescription')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Client Emergency Contact Email" v-model="item.clientEmergencyContactEmail" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceDescription')"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Notes</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <!-- // TODO CHANGE TO TEXT AREA  -->
                                <v-col cols="12" xs="12">
                                    <v-textarea label="" v-model="item.clientNotes" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceName')"></v-textarea>
                                </v-col>
                            </v-row>



                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>

        <v-dialog persistent v-model="archiveDialog" width="500px" height="400px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 secondary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mr-3 mb-1">
                        <v-icon class="white--text">icons8-xbox-a</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Archiving Client</strong>
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="archiveDialog = !archiveDialog" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 pa-0">               
                    
                    <v-row class="pa-0 pt-4 ma-0 justify-center d-flex" fill-height>
                        <v-col cols="12" class="align-center pt-0">
                            <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field label="Archive From" v-model="computedDate1" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('contentAvailableFrom')"></v-text-field>
                                </template>
                                <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="pa-0" fill-height cols="12" style="width: 100% !important">
                            <div class="font-weight-bold body-2 pa-3 text-h7 grey--text">
                                This will archive any Jobs relating to this Client from {{ computedDate1 }}
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-0">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="archiveDialog = !archiveDialog"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                            <v-btn class="primary white--text font-weight-bold ml-2" depressed @click="archiveClient"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
                        </v-col>
                    </v-col>
                </v-card-actions>
            </div>
        </v-dialog>
        <v-dialog persistent v-model="progressDialog" width="500px" height="400px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 secondary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mr-3 mb-1">
                        <v-icon class="white--text">icons8-future</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Archive Jobs</strong>
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeProgressDialog" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 px-0 pa-3 d-flex flex-column align-center">    

                    <v-progress-circular v-if="progressData.status == 'In Progress' || progressData.status == 'Not Started'" indeterminate :size="60" :width="7"></v-progress-circular>
                    <div v-if="progressData.status == 'In Progress' || progressData.status == 'Not Started'" class=" grey--text font-weight-bold text--darken-2 d-flex align-center animate__animated animate__flash animate__infinite animate__slow">The Jobs linked to this client are being archived. Please wait...</div>
                    <div v-if="progressData.status == 'Completed'" class=" grey--text font-weight-bold text--darken-2 d-flex align-center animate__animated animate__flash animate__infinite animate__slow">The Jobs linked to this client have been successfully archived.</div>

                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-0">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn class="grey darken-1 white--text font-weight-bold" :disabled="progressData.status == 'In Progress'" depressed @click="closeProgressDialog"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Close</span> </v-btn>
                        </v-col>
                    </v-col>
                </v-card-actions>
            </div>
        </v-dialog>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
import schema from '@/schema.js';
import moment from 'moment';
import { mapGetters } from "vuex";

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        // activeitem: {
        //     Type: Object,
        //     Default: null,
        // },
    },
    // * DATA
    data: () => ({
        key: 'action',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,

        // dropdowns 
        clientTypes: [   
            {   
                text: 'Domestic',
                id: 'DOMESTIC'
            }, {
                text: 'Education', 
                id: 'EDUCATION'
            },{
                text: 'Client', 
                id: 'CLIENT'
            },{
                text: 'Commercial',
                id: 'COMMERCIAL',
            }
        ],
        clientStatus: [   
            {   
                text: 'Active',
                id: 'ACTIVE', 
                disabled: false
            }, {
                text: 'Inactive', 
                id: 'INACTIVE', 
                disabled: false
            },{
                text: 'Lead', 
                id: 'LEAD', 
                disabled: false
            },{
                text: 'Archive', 
                id: 'ARCHIVE', 
                disabled: true
            }
        ],


        // new address object
        item: {},
        loading: false,
        rules: {},

        archiveDialog: false,


        date1: moment().format("YYYY-MM-DD"),
        dateMenu1: false,
        date2: '',
        dateMenu2: false,

        progressDialog: false,
        progress: 0,
        progressData: {
            id: "",
            totalItems: 0,
            count: 0,
            success: 0,
            error: 0,
            status: "Not Started",
            report: "",
        },
		progressStatus: "Not Started",
		checkProgressInterval: null,

    }),
    computed: {  
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
        computedDate1() {
            return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
        computedDate2() {
            return this.MIX_formatDateTime(this.date2, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
    },
    methods: {
        checkIsItemDisabled(item) {
            return (item.disabled === false)
        },
        initialise() {
            this.item = { ...this.datadown };
            //console.log('item', JSON.stringify(this.item, null, 2));
            if (this.item.clientName !== '') {
                this.itemNew = false;
                this.editmode = false;
            } else {
                this.itemNew = true;
                this.editmode = true;
            }
        },
        validateItem() {
			let t = this;
			t.rules = {
				required: [(v) => !!v || "Required"],
			};
			t.$nextTick(() => {
				if (t.$refs.form.validate()) {
                    if (t.itemNew) {
					    this.saveItem();
                    } else {
                        this.updateItem();
                    }
					this.editMode = false;
				} else {
					this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
				}
			});
        },
        updateItem() {
            this.item.clientContractedHours = Number(this.item.clientContractedHours)
            this.$emit('update', this.item);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {
            this.item.clientContractedHours = Number(this.item.clientContractedHours)
            this.$emit('save', this.item);
            this.editmode = false;
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        async archiveClient() {
            var t = this;

            t.item.clientStatus = 'ARCHIVE'

            let data = {}

            data.progressId = t.MIX_generateId()
            data.clientId = t.item.entityId 
            data.archiveFromDate = t.$moment(t.computedDate1, 'DD-MMM-YYYY').format('X')
            data.modifiedUserId = t.GET_FIREBASE_currentUser.entityId

            console.log('data = ' + JSON.stringify(data, null, 2))

            let result = await t.REDIS_archiveClient(data)
            console.log('result = ' + JSON.stringify(result, null, 2))
            // debugger
            if (result.success) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Client and Jobs Archived", show: true });
                this.checkProgress(data.progressId)
                this.progressDialog = true
            } else {
            }
            t.archiveDialog = false
            t.updateItem()

        },
        // Check Progress of Import
        checkProgress(progressId) {
            let t = this;
            this.checkProgressInterval = setInterval(async () => {
                try {
                    let progressResult = await t.REDIS_read("progress", progressId);
                    if (progressResult.status !== "404") {
                        console.log('progressResult = ' + JSON.stringify(progressResult, null, 2))
                        t.progressData = progressResult.data;
                        t.progressStatus = progressResult.data.status;
                        if (progressResult.data.count !== 0 && progressResult.data.totalItems !== 0) {
                            t.progress = (progressResult.data.count / progressResult.data.totalItems) * 100;
                        } else {
                            t.progress = 100;
                        }
                        console.log('progress = ' + t.progress)
                        if (progressResult.data.status === "Completed") {
                            clearInterval(t.checkProgressInterval);
                        }
                    } else if (progressResult.status === "404") {
                        clearInterval(t.checkProgressInterval);
                    }
                } catch (error) {
                    console.error(error);
                    clearInterval(t.checkProgressInterval);
                }
            }, 1000);
        },


        closeProgressDialog() {
            var t = this;
            t.progressDialog = false
            t.progress = 0,
            t.progressData = {
                id: "",
                totalItems: 0,
                count: 0,
                success: 0,
                error: 0,
                status: "Not Started",
                report: "",
            },
            t.progressStatus = "Not Started",
            t.checkProgressInterval = null

            t.date1 = moment().format("YYYY-MM-DD")
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        // activeitem: {
        //     handler: function () {
        //         let t = this;
        //         t.item = { ...t.activeitem };
        //     }, deep: true
        // },
        selectedPackageIcon: {
            handler: function () {
                this.item.packageIcon = this.selectedPackageIcon.icon;
            }, deep: true
        },
        dateMenu2: {
            handler: async function () {
                this.item.clientStartDateTime = this.MIX_formatDateTime(this.date2, 'YYYY-MM-DD', 'X');
            },
            deep: true,
        },

    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.package;
        t.loading = true
        t.initialise();

        t.loading = false
    },
    mounted() {},
};
</script>