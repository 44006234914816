<template>
    <div class="" style=" overflow: hidden !important' : $vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; overflow: scroll !important;'">
      
        <v-row class="apptext--text" dense style="z-index: 0 !important">

            <v-col cols="12" class="d-flex align-center">
                <v-form ref="form" lazy-validation>
                    <v-col cols="12" sm="12" class="mb-3 " fill-width>
                        <v-row dense>
                            <v-col cols="12" class="d-flex align-center">
                                <div class="text-left primary--text text-h6 font-weight-light">User Details</div>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6">
                                <v-text-field label="User Name" v-model="item.userName" :rules="rules.userName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('userName')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6">
                                <v-text-field label="User Email" v-model="item.userEmail" :rules="rules.userEmail" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('userEmail')"></v-text-field>
                            </v-col>
                            <v-col cols="12" class="d-flex align-center">
                                <div class="text-left primary--text text-h6 font-weight-light">User Permissions</div>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3">
                                <v-select hide-details="auto" label="User Level" filled v-model="item.userLevel" :rules="rules.userLevel" outlined dense :items="userLevels" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" ></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" sm="9" class="d-flex">
                                <v-select 
                                    hide-details="auto" 
                                    label="User Sites" 
                                    filled 
                                    v-model="selectedSites" 
                                    :rules="rules.userSites"
                                    outlined 
                                    dense 
                                    clearable 
                                    :items="clientSites" 
                                    background-color="white" 
                                    color="primary" 
                                    :menu-props="{ top: false, offsetY: true }" 
                                    multiple 
                                    item-text="siteName" 
                                    item-value="entityId"
                                    hint="Please Select the Sites this User will be able to add and edit Communications for."
                                    persistent-hint
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            ripple
                                            @mousedown.prevent
                                            @click="selectAll = !selectAll"
                                        >
                                        <v-list-item-action>
                                            <v-checkbox v-model="selectAll" label="" hide-details="auto" class="ma-0 pa-0"></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Select All
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index === 0">
                                            <span>{{ item.siteName }}</span>
                                        </v-chip>
                                        <span
                                            v-if="index === 1"
                                            class="grey--text text-caption"
                                            >
                                            (+{{ selectedSites.length - 1 }} others)
                                        </span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" class="d-flex align-center justify-center">
                                <v-checkbox v-model="item.allowTaskNotifications" label="Allow Task Notifications" hide-details="auto" class="pa-0 ma-0 pr-4" />
                                <v-checkbox v-model="item.allowCommunicationNotifications" label="Allow Communication Notifications" hide-details="auto" class="pa-0 ma-0 pl-4" />
                            </v-col>


                            <v-col cols="12" class="d-flex align-center">
                                <div class="text-left primary--text text-h6 font-weight-light">User Pin</div>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12">
                                <v-otp-input hide-details="auto" dense label="Pin" v-model="item.userPin" type="number" length="6" :rules="pinRules"></v-otp-input>
                            </v-col>
                            
                        </v-row>
                    </v-col>
                    <!-- <pre>User: {{ item }}</pre> -->
                    <!-- <pre>Selected Sites: {{ selectedSites }}</pre> -->
                    <!-- <pre>ItemNew: {{ itemNew }}</pre> -->
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="pl-1 pt-0 mt-0">
            <v-col cols="12" class="d-flex justify-start grey darken-1 py-4 px-4 mt-0 pr-7">
                <v-btn class="grey lighten-1 white--text font-weight-bold" depressed @click="cancelItem"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span></v-btn>
                <v-spacer />
                <v-btn class="primary white--text font-weight-bold" depressed @click="validateForm()"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span></v-btn>
            </v-col>
        </v-row>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
import schema from '@/schema.js';
import moment from 'moment';
import { mapGetters } from "vuex";

export default {
    props: {
        datadown: {
            Type: Object,
            Default: null,
        },
        clientId: {
            Type: String,
            Default: '',
        },
        // activeitem: {
        //     Type: Object,
        //     Default: null,
        // },
    },
    // * DATA
    data: () => ({
        key: 'action',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,


        // new address object
        item: {},
        loading: false,
        rules: {},
        pinRules: [
            (v) => !!v || 'User Pin is required.',
            (v) => (v && v.length == 6) || 'User Pin must be 6 digits.',
            (v) => (v && !isNaN(parseInt(v))) || 'User Pin must be a number.'
        ],

        archiveDialog: false,

        userLevels: [
            'Admin', 'User'
        ],

        clientSites: [],
        selectedSites: [],
        selectAll: false,


    }),
    computed: {  
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
    },
    methods: {
        initialise() {
            this.item = { ...this.datadown };
            console.log('item', JSON.stringify(this.item, null, 2));
            if (this.item.userName !== '') {
                this.itemNew = false;
                this.editmode = false;

                this.selectedSites = this.item.userSiteId;

            } else {
                this.itemNew = true;
                this.editmode = true;
            }
        },
        updateItem() {
            this.item.userSiteId = this.selectedSites;
            this.$emit('update', this.item);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {
            console.log('saveItem')
            this.item.userSiteId = this.selectedSites;
            this.$emit('save', this.item);
            this.editmode = false;
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        validateForm() {
            this.rules = {
                userName: [(v) => !!v &&  !!v.trim() || 'User Name Required'],
                userEmail: [(v) => !!v || 'User Email Required'],
                userLevel: [(v) => !!v || 'User Level Required'],
                userSites: [
                    (v) => !!v || 'User Site Required',
                    (v) => (v && v.length > 0) || 'User Site Required',
                ],
                // userPin: [(v) => !!v || 'User Pin Required'],
            }
            this.$nextTick(() => {
                if (this.$refs.form.validate()) {
                    if (this.itemNew) {
                        this.saveItem()
                    } else {
                        this.updateItem()
                    }
                } else {
                    this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Please fill in all required fields', show: true })
                }
            })
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        selectAll: {
            handler: function () {
                if (this.selectAll == true) {
                    this.selectedSites = []
                    this.clientSites.forEach((x) => {
                        this.selectedSites.push(x.entityId)
                    })
                } else {
                    this.selectedSites = [];
                }
            },
            deep: true,
        },

    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.package;
        t.loading = true
        t.initialise();

        // Get Client Sites
        let clientSites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', `@deleted:{false} @siteClientId:{${t.clientId}}`);
        if (clientSites.success) { t.clientSites = clientSites.data.documents; }

        t.clientSites = t.clientSites.map((x) => {
            return {
                entityId: x.entityId,
                siteName: x.siteName,
            };
        })

        t.loading = false
    },
    mounted() {},
};
</script>