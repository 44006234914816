<template>
    <div class="" 
    style="padding: 0px !important; margin: 0px !important; width: 100% !important" 
    :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>

                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="primary white--text" @click="addNewLeave()" ><v-icon class="mr-2">icons8-save</v-icon>Add</v-btn>
                </div>
            </v-col>
        </v-row>
        
        <div class="pa-3 apptext--text">
            <v-row class="pr-2 d-flex flex-row justify-end py-4">
			    <v-card-actions class="darkgrey--text caption pt-2">KEY:</v-card-actions>
                <v-chip color="success" medium class="font-weight-bold ml-1 mr-1"><h5>JOBS</h5></v-chip>
                <v-chip color="orange" medium class="font-weight-bold ml-1 mr-1"><h5 class="white--text">HOLIDAY</h5></v-chip>
                <v-chip color="pink" medium class="font-weight-bold ml-1 mr-1"><h5 class="white--text">ILLNESS</h5></v-chip>
            </v-row>

            <div>
                <v-sheet style="height: 60vh !important" class="mt-2" rounded width=100%>
                    <div class="d-flex grey--text font-weight-bold pa-3 pl-6 text-h6">
                        <div class="mt-1">
                            {{ $moment(currentWeekStartDate).format('MMMM') }}
                        </div>
                        <v-spacer />
                        <div class="mr-2">
                            <v-btn style="border: 1px solid grey !important;" color="white" depressed  @click="priorWeek" block>
                                <v-icon class="icons8-back"></v-icon>
                            </v-btn>
                        </div>
                        <div class="mr-2" v-if="$moment(currentWeekStartDate).format('X') !== $moment().startOf('week').add(1, 'days').format('X')">
                            <v-btn color="secondary" depressed  @click="currentWeek" block>
                                <v-icon class="icons8-date-to"></v-icon>
                            </v-btn>
                        </div>
                        <div class="mr-2">
                            <v-btn style="border: 1px solid grey !important;" color="white" @click="nextWeek" block depressed>
                                <v-icon class="icons8-forward"></v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <v-calendar
                        ref="calendar"  
                        v-model="value"
                        interval-height="55" 
                        event-overlap-mode="column" 
                        interval-width="50" 
                        :events="computedEvents" 
                        :event-color="getEventColor" 
                        :now="$moment().format('YYYY-MM-DD hh:mm:ss')" 
                        :weekdays="weekday" 
                        color="secondary white--text" 
                        :start="$moment(currentWeekStartDate).format('YYYY-MM-DD hh:mm:ss')" 
                        type="week"
                        @click:event="checkEvent" 
                        :key="calendarComponentKey"
                    >
                        <!-- @mousedown:event="startDrag"
                        @mousedown:time="startTime"
                        @mousemove:time="mouseMove"
                        @mouseup:time="endDrag"
                        @mouseleave.native="cancelDrag"
                        @change="getEvents" -->

                        <template v-slot:event="{ event, timed }">
                            <div v-if="event.client" class="font-weight-bold">{{ event.client }} </div>
                            <div v-if="event.site">{{ event.site }}</div>
                            <div v-if="event.type !== 'Job' && event.type !== 'Recurring Job'" class="font-weight-bold">{{ event.type }}</div>
                            <div v-if="event.allDay === false" class="text-wrap">{{ $moment(event.start).format('HH:mm') }} - {{ $moment(event.end).format('HH:mm') }}</div>
                            <!-- <div v-if="timed" class="v-event-drag-bottom" @mousedown.stop="extendBottom(event)" ></div> -->
                        </template>
                    </v-calendar>
                </v-sheet>
            </div>

        </div>

        <v-dialog persistent v-model="leaveDialog" width="600px" height="400px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 secondary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mr-3 mb-1">
                        <v-icon class="white--text">icons8-calendar</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Add Leave</strong>
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="cancelLeaveDialog()" depressed>
                            <v-icon>icons8-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 pa-0">         
                    <v-form ref="form" lazy-validation>      
                        <v-row v-if="leave.entityId" class="pa-3 pb-0 ma-0 justify-end d-flex" dense>
                            <v-btn class="primary white--text font-weight-bold" depressed @click="editLeave"><span>Edit</span> </v-btn>
                            <v-btn class="grey darken-1 white--text font-weight-bold ml-1" depressed @click="deleteLeave"><span>Delete</span> </v-btn>
                        </v-row>
                        <!-- <pre>{{ leave }}</pre> -->
                        
                        <v-row class="pa-2 ma-0 justify-center d-flex" fill-height dense>
                            <v-col cols="12" xs="12">
                                <v-select 
                                    label="Leave Type" 
                                    v-model="leave.leaveType" 
                                    :rules="rules.required" 
                                    required 
                                    :items="leaveTypes" 
                                    :menu-props="{ top: false, offsetY: true }" 
                                    auto-select-first 
                                    return-object 
                                    dense 
                                    outlined 
                                    hide-details="auto" 
                                    background-color="white" 
                                    color="primary" 
                                    class="" 
                                    :disabled="showClashes || (!editmode && !itemNew)" > 
                                </v-select> 
                            </v-col>
                            <v-col cols="12" xs="12" sm="6">
                                <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :disabled="showClashes || (!editmode && !itemNew)" label="Start Of Leave" v-model="computedDate1" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearFilter('jobsFrom')" :rules="rules.startDate" ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6">
                                <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :disabled="showClashes || (!editmode && !itemNew)" label="End Of Leave" v-model="computedDate2" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearFilter('jobsFrom')" :rules="rules.endDate"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date2" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu2 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12">
                                <v-textarea 
                                    label="Reason for Leave" 
                                    v-model="leave.leaveNotes" 
                                    :rules="rules.required"
                                    outlined 
                                    dense 
                                    hide-details="auto" 
                                    background-color="white" 
                                    color="primary" 
                                    clearable 
                                    @click:clear="clearItem('locationDescription')" 
                                    rows="2" 
                                    :disabled="showClashes || (!editmode && !itemNew)" >
                                </v-textarea>
                            </v-col>
                            <v-col class="d-flex justify-center align-center pr-0 pt-0" cols="12" xs="12">
                                <v-checkbox 
                                    :true-value="false" 
                                    :false-value="true" 
                                    v-model="leave.leaveAllDay" 
                                    label="Add Time" 
                                    :disabled="showClashes || (!editmode && !itemNew)" 
                                />
                            </v-col>

                            <v-col v-if="!leave.leaveAllDay" class="pa-1 py-0 pr-0 pt-0" cols="12">
                                <div class="caption">Start Time</div>
                                <div class="d-flex">
                                    <v-col class="pa-0 ma-0" cols="6">
                                        <v-select 
                                            class="" 
                                            hide-details="auto" 
                                            label="Hours" 
                                            :rules="rules.startTimeHour" 
                                            filled 
                                            v-model="startTimeHours" 
                                            required 
                                            outlined 
                                            dense 
                                            :items="hoursWith0" 
                                            background-color="white" 
                                            color="primary" 
                                            :menu-props="{ top: true, offsetY: true }" 
                                            :disabled="showClashes || (!editmode && !itemNew)" >
                                        </v-select>
                                    </v-col>
                                    <v-col class="pa-0 ma-0" cols="6">
                                        <v-select 
                                            class="px-1" 
                                            hide-details="auto" 
                                            label="Mins" 
                                            :rules="rules.startTimeMins" 
                                            filled 
                                            outlined 
                                            v-model="startTimeMins" 
                                            required 
                                            dense 
                                            :items="mins" 
                                            background-color="white" 
                                            color="primary" 
                                            :menu-props="{ top: true, offsetY: true }" 
                                            :disabled="showClashes || (!editmode && !itemNew)" >
                                        </v-select>
                                    </v-col>
                                </div>
                            </v-col>
                            <v-col v-if="!leave.leaveAllDay" class="pa-1 py-0 pr-0 pt-0" cols="12">
                                <div class="caption">End Time</div>
                                <div class="d-flex">
                                    <v-col class="pa-0 ma-0" cols="6">
                                        <v-select 
                                            class="" 
                                            hide-details="auto" 
                                            label="Hours" 
                                            :rules="rules.durationHours" 
                                            filled 
                                            v-model="endTimeHours" 
                                            required 
                                            outlined 
                                            dense 
                                            :items="hoursWith0" 
                                            background-color="white" 
                                            color="primary" 
                                            :menu-props="{ top: true, offsetY: true }" 
                                            :disabled="showClashes || (!editmode && !itemNew)" >
                                        </v-select>
                                    </v-col>
                                    <v-col class="pa-0 ma-0" cols="6">
                                        <v-select 
                                            class="px-1" 
                                            hide-details="auto" 
                                            label="Mins" 
                                            :rules="rules.durationMins" 
                                            filled 
                                            outlined 
                                            v-model="endTimeMins" 
                                            required 
                                            dense 
                                            :items="mins" 
                                            background-color="white" 
                                            color="primary" 
                                            :menu-props="{ top: true, offsetY: true }" 
                                            :disabled="showClashes || (!editmode && !itemNew)" >
                                        </v-select>
                                    </v-col>
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>

                    <v-row v-if="showClashes" class="pa-2 px-3 ma-0  d-flex align-center" fill-height dense>
                        <div class="d-flex  text-start align-center font-weight-bold body-2">
                            <div> <v-icon size="30" class="error--text">icons8-high-importance</v-icon> </div>
                            <div>
                                This Staff Member has 
                                <span v-if="clashes.job > 0"> {{ clashes.job }} Jobs </span>
                                <span v-if="clashes.job > 0 && clashes.holiday > 0"> and </span>
                                <span v-if="clashes.holiday > 0"> {{ clashes.holiday }} Holiday/Sickness </span> 
                                scheduled between {{ $moment(leave.leaveStartDateTime).format('DD-MM-YYYY') }} and {{ $moment(leave.leaveEndDateTime).format('DD-MM-YYYY') }}.</div>
                        </div>
                        <div class="d-flex text-start pl-1 align-center font-weight-medium body-2">
                            <div>Please Select whether these scheduled Events should be Unallocated upon saving the Leave.</div>
                            <v-checkbox class="ml-2" v-model="unallocateClashes" />
                        </div>
                    </v-row>

                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-0">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="cancelLeaveDialog"><span>Cancel</span> </v-btn>
                            <v-btn v-if="!showClashes" :disabled=" (!editmode && !itemNew)" class="primary white--text font-weight-bold ml-2" depressed @click="validateItem()"><span>Save</span> </v-btn>
                            <v-btn v-if="showClashes" class="primary white--text font-weight-bold ml-2" depressed @click="saveClashes"><span>Save</span> </v-btn>
                        </v-col>
                    </v-col>
                </v-card-actions>
            </div>
        </v-dialog>

    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
import schema from '@/schema.js';
import moment from 'moment'
import { mapGetters } from "vuex";

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
    },
    // * DATA
    data: () => ({
        key: 'action',
        itemNew: true,
        leaveSchema: {},
        instanceSchema: {},
        absenceSchema: {},
        org: {},
        editmode: true,

        // new address object
        item: {},
        loading: false,
        rules: {},
        searchQuery: '',


        value: '',
        weekday: [1, 2, 3, 4, 5, 6, 0],
        currentWeekStartDate: moment().startOf('week').add(1, 'days'),
        calendarComponentKey: 0,
        events: [],
        leaveDialog: false,

        leave: {},
        leaveTypes: ['Holidays', 'Sickness', 'Authorised Absense', 'Unauthorised Absense', 'Bereavement', 'Compasssionate Leave', 'Parental Leave', 'School Term Time Leave', 'Funeral', 'Maternity', 'Paternity'],

        date1: '',
        dateMenu1: false,
        date2: '',
        dateMenu2: false,


        startTimeHours: '', 
        startTimeMins: '',

        endTimeHours: '',
        endTimeMins: '',

        itemsTotal: 0,

        items: [],
        events: [],

        showClashes: false,
        clashes: {},

        unallocateClashes: false,



        dragEvent: null,
        dragStart: null,
        createEvent: null,
        createStart: null,
        extendOriginal: null,


    }),
    computed: {  
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
        computedDate1() {
            return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YYYY");
        },

        computedDate2() {
            return this.MIX_formatDateTime(this.date2, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
        hoursWith0() {
            var hours = []
            for (var i = 0; i < 24; i++) {
                if (i < 10) {
                    hours.push ('0' + i).toString()
                } else {
                    hours.push (i.toString())
                }
            }
            return hours
        },

        mins() {
            var mins = []
            for (var i = 0; i < 60; i++) {
                if (i < 10) {
                    mins.push ('0' + i).toString()
                } else {
                    mins.push (i.toString())
                }
                i = i + 4;
            }
            return mins
        }, 

        computedEvents() {
            var t = this; 
            console.log('here at computed events')
            var events = [] 
            events = this.events 

            events.forEach(function(item) {
                if (item.leaveAllDay) {
                    item.start = moment(item.leaveStartDateTime).format('YYYY-MM-DD')
                    item.end = moment(item.leaveEndDateTime).format('YYYY-MM-DD')
                } else {
                    item.start = moment(item.leaveStartDateTime).format('YYYY-MM-DD HH:mm')
                    item.end = moment(item.leaveEndDateTime).format('YYYY-MM-DD HH:mm')
                }
            })

            events = events.map(e => {
                return {
                    entityId: e.entityId,
                    start: e.start,
                    end: e.end,
                    type: e.leaveType,
                    allDay: e.leaveAllDay,
                    leaveGroupId: e.leaveGroupId
                }
            })

            var items = [] 
            items = this.items 

            items = items.map(e => {
                return {
                    entityId: e.entityId,
                    start: moment(e.jobStartDateTime).format('YYYY-MM-DD HH:mm'),
                    end: moment(e.jobEndDateTime).format('YYYY-MM-DD HH:mm'),
                    type: e.jobType,
                    allDay: false,
                    client: this.getClient(e.jobClientId),
                    site: this.getSite(e.jobSiteId),
                    duration: e.jobDuration
                }
            })

            items.forEach(function(item) {
                events.push(item)
            })

            console.log('events = ' + JSON.stringify(events, null, 2))
            return events
        },
        computedJobsSearchQuery() {
            let searchQuery = this.searchQuery;
            searchQuery = '@deleted:{false}';

            searchQuery = `${searchQuery} @jobPublishedStatus:{PUBLISHED | UNPUBLISHED} @jobAssignedUserId:{${this.item.entityId}}`
            var startOfWeek = this.$moment(this.currentWeekStartDate).format('X')
            searchQuery = `${searchQuery} @jobStartDateTime:[${startOfWeek} +inf]`
            var endOfWeek = this.$moment(this.currentWeekStartDate).add(7, 'days').format('X')
            searchQuery = `${searchQuery} @jobEndDateTime:[-inf ${endOfWeek}]`

            return searchQuery;
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;
            searchQuery = '@deleted:{false}';

            searchQuery = `${searchQuery} @leaveAssignedUserId:{${this.item.entityId}}`
            var startOfWeek = this.$moment(this.currentWeekStartDate).format('X')
            searchQuery = `${searchQuery} @leaveStartDateTime:[${startOfWeek} +inf]`
            var endOfWeek = this.$moment(this.currentWeekStartDate).add(7, 'days').format('X')
            searchQuery = `${searchQuery} @leaveEndDateTime:[-inf ${endOfWeek}]`

            return searchQuery;
        },
    },
    methods: {
        getEvents() {
            
            var t = this; 

            var events = [] 
            events = this.events 

            events.forEach(function(item) {
                if (item.leaveAllDay) {
                    item.start = moment(item.leaveStartDateTime).format('YYYY-MM-DD')
                    item.end = moment(item.leaveEndDateTime).format('YYYY-MM-DD')
                } else {
                    item.start = moment(item.leaveStartDateTime).format('YYYY-MM-DD HH:mm')
                    item.end = moment(item.leaveEndDateTime).format('YYYY-MM-DD HH:mm')
                }
            })

            events = events.map(e => {
                return {
                    entityId: e.entityId,
                    start: e.start,
                    end: e.end,
                    type: e.leaveType,
                    allDay: e.leaveAllDay,
                    leaveGroupId: e.leaveGroupId
                }
            })

            var items = [] 
            items = this.items 

            items = items.map(e => {
                return {
                    entityId: e.entityId,
                    start: moment(e.jobStartDateTime).format('YYYY-MM-DD HH:mm'),
                    end: moment(e.jobEndDateTime).format('YYYY-MM-DD HH:mm'),
                    type: e.jobType,
                    allDay: false,
                    client: this.getClient(e.jobClientId),
                    site: this.getSite(e.jobSiteId)
                }
            })

            items.forEach(function(item) {
                events.push(item)
            })


            return events
        },
        startDrag ({ event, timed }) {
            if (event && timed) {
                this.dragEvent = event
                this.dragTime = null
                this.extendOriginal = null
            }
        },
        startTime (tms) {
            const mouse = this.toTime(tms)

            if (this.dragEvent && this.dragTime === null) {
                console.log('dragevent start = ' + JSON.stringify(this.dragEvent, null, 2))
                console.log('mouse = ' + JSON.stringify(mouse, null, 2))
                const start = this.$moment(this.dragEvent.start).format('X')

                this.dragTime = mouse - start
                console.log('dragevent = ' + JSON.stringify(this.dragEvent, null, 2))

            } else {
                this.createStart = this.roundTime(mouse)
                this.createEvent = {
                    name: `Event #${this.events.length}`,
                    // color: this.rndElement(this.colors),
                    start: this.createStart,
                    end: this.createStart,
                    timed: true,
                }

                this.events.push(this.createEvent)
            }
        },
        extendBottom (event) {
            this.createEvent = event
            this.createStart = event.start
            this.extendOriginal = event.end
        },
        mouseMove (tms) {
            const mouse = this.toTime(tms)

            if (this.dragEvent && this.dragTime !== null) {
                const start = this.$moment(this.dragEvent.start).format('x')
                const end = this.$moment(this.dragEvent.end).format('x')
                const duration = end - start
                console.log('duration = ' + duration)

                console.log('mouse = ' + mouse)
                console.log('dragTime = ' + this.dragTime)

                const newStartTime = mouse - this.dragTime
                console.log('newStartTime = ' + newStartTime)

                const newStart = this.roundTime(newStartTime)
                console.log('newStart = ' + newStart)

                const newEnd = newStart + duration
                console.log('newEnd = ' + newEnd)

                this.dragEvent.start = newStart
                this.dragEvent.end = newEnd
                console.log('dragevent = ' + JSON.stringify(this.dragEvent, null, 2))
            } else if (this.createEvent && this.createStart !== null) {
                const mouseRounded = this.roundTime(mouse, false)
                const min = Math.min(mouseRounded, this.createStart)
                const max = Math.max(mouseRounded, this.createStart)

                this.createEvent.start = min
                this.createEvent.end = max
            }
        },
        endDrag () {
            this.dragTime = null
            this.dragEvent = null
            this.createEvent = null
            this.createStart = null
            this.extendOriginal = null
        },
        cancelDrag () {
            if (this.createEvent) {
                if (this.extendOriginal) {
                    this.createEvent.end = this.extendOriginal
                } else {
                    const i = this.events.indexOf(this.createEvent)
                    if (i !== -1) {
                        this.events.splice(i, 1)
                    }
                }
            }

            this.createEvent = null
            this.createStart = null
            this.dragTime = null
            this.dragEvent = null
        },
        roundTime (time, down = true) {
            const roundTo = 15 // minutes
            const roundDownTime = roundTo * 60 * 1000

            return down
            ? time - time % roundDownTime
            : time + (roundDownTime - (time % roundDownTime))
        },
        toTime (tms) {
            return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
        },




        cancelLeaveDialog() {
            var t = this; 

            t.leaveDialog = false 
            t.leave = {...t.leaveSchema }

            t.date1 = ''
            t.date2 = ''

            t.startTimeHours = ''
            t.startTimeMins = '' 
            t.endTimeHours = '' 
            t.endTimeMins = ''

            t.showClashes = false
            t.clashes = {}

            t.editmode = false

        },
        checkEvent(event) {
            var item = event.event
            var jobType = item.type;
            if (jobType === 'Job' || jobType === 'Recurring Job') {
                this.MIX_go('/job/' + item.entityId); 
            } else  {
                this.readLeaveItem(item)
            }
        },
        async readLeaveItem(item) {
			let t = this;

			let result = await t.REDIS_searchFor('leave', 0, 999, 'leaveStartDateTime', 'ASC', `@deleted:{false} @leaveGroupId:{${item.leaveGroupId}}`);

			if (result.success) {
				var leaveData = result.data;

                this.date1 = this.$moment(leaveData.documents[0].leaveStartDateTime).format('YYYY-MM-DD')
                this.date2 = this.$moment(leaveData.documents[leaveData.total - 1].leaveEndDateTime).format('YYYY-MM-DD')


                
                if (!leaveData.documents[0].leaveAllDay) {
                    this.startTimeHours = this.$moment(leaveData.documents[0].leaveStartDateTime).format('HH')
                    this.startTimeMins = this.$moment(leaveData.documents[0].leaveStartDateTime).format('mm')
                }


                if (!leaveData.documents[leaveData.total - 1].leaveAllDay) {
                    this.endTimeHours = this.$moment(leaveData.documents[leaveData.total - 1].leaveEndDateTime).format('HH')
                    this.endTimeMins = this.$moment(leaveData.documents[leaveData.total - 1].leaveEndDateTime).format('mm')
                } 

                this.leave = leaveData.documents[0]



                this.leaveDialog = true
				t.itemNew = false;
				t.loading = false;
			}
        },
        addNewLeave() {
            var t = this;

            t.leaveDialog = true
            t.itemNew = true
        },
        getClient(clientId) {
            var t = this;
            var client = []
            var clientName = ''

            client = t.clients.filter((item) => { return item.entityId == clientId })

            if (client.length !== 0) { clientName = client[0].clientName }
            return clientName
        },
        getSite(siteId) {
            var t = this;
            var site = []
            var siteName = ''

            site = t.sites.filter((item) => { return item.entityId == siteId })

            if (site.length !== 0) { siteName = site[0].siteName }

            return siteName
        },
        async checkClashes() {
            var t = this; 

            var leave = t.leave 

            var total = {} 
            total.holiday = 0
            total.job = 0

            var leaveStart = this.$moment(leave.leaveStartDateTime).format('X')
            var leaveEnd = ''

            if (leave.leaveAllDay) {
                leaveEnd = this.$moment(leave.leaveEndDateTime).endOf('day').format('X')
            } else {
                leaveEnd = this.$moment(leave.leaveEndDateTime).format('X')
            }

            let eventsResult = await this.REDIS_searchFor('leave', 0, 9999, 'leaveStartDateTime','ASC', `@deleted:{false} @leaveAssignedUserId:{${this.item.entityId}} @leaveStartDateTime:[${leaveStart} +inf] @leaveStartDateTime:[-inf ${leaveEnd}]` )
            if (eventsResult.success) {
                var eventClashes = eventsResult.data.documents;
                total.holiday = eventClashes.length
            }

            let itemsResult = await this.REDIS_searchFor('jobs', 0, 9999, 'jobStartDateTime','ASC', `@deleted:{false} @jobAssignedUserId:{${this.item.entityId}} @jobStartDateTime:[${leaveStart} +inf] @jobStartDateTime:[-inf ${leaveEnd}]` )
        
            if (itemsResult.success) {
                var jobClashes = itemsResult.data.documents;
                total.job = jobClashes.length

            }

            t.clashes = total
            return total


        },
        async deleteInstance(id) {
            var t = this; 

			let result = await t.REDIS_searchFor('instance', 0, 999, 'instanceStartDateTime', 'ASC', `@deleted:{false} @instanceLeaveId:{${id}}`);
            console.log('deleting instance = ' + JSON.stringify(result, null, 2))
			if (result.success) {
                for (var i = 0; i < result.data.total; i++) {
                    var item = result.data.documents[i]
                    item.deletedUserId = t.GET_FIREBASE_currentUser.entityId 
                    await this.REDIS_delete('instance', item.entityId, item)
                }
            }


        },
        async createProgress(jobId, type, status) {
            let t = this;
            let progress = {}
            
            progress.linkedId = jobId
            progress.type = type
            progress.status = status
            progress.totalItems = 1
            progress.count = 1
            progress.success = 1
            progress.error = 0
            progress.createdDateTime = t.$moment().toISOString()
            progress.createdUserId = t.GET_FIREBASE_currentUser.entityId
            console.log('progress = ' + JSON.stringify(progress, null, 2))
            await this.REDIS_create('progress', progress);
        },  
        editLeave() {
            this.editmode = true
        },
        async deleteLeave() {
            var t = this; 

            var leave = t.leave 

			let result = await t.REDIS_searchFor('leave', 0, 999, 'leaveStartDateTime', 'ASC', `@deleted:{false} @leaveGroupId:{${leave.leaveGroupId}}`);
            console.log('deleting leave = ' + JSON.stringify(result, null, 2))

			if (result.success) {
				var leaveData = result.data;
                for (var i = 0; i < result.data.total; i++) {
                    var item = leaveData.documents[i]
                    item.deletedUserId = t.GET_FIREBASE_currentUser.entityId 
                    await this.REDIS_delete('leave', item.entityId, item)
                }
                console.log('leaveData = ' + JSON.stringify(leaveData, null, 2))

                t.deleteInstance(leave.leaveGroupId)
                if (!this.editmode) {
                    t.MIX_alertBox({ color: "success", timeout: 2000, message: "Deleted", show: true });
                    console.log('leave deleted')
                    // t.createProgress(result.data.documents[0].leaveAssignedUserId, 'Delete User Leave', 'Completed')
                    t.cancelLeaveDialog()
                    t.getItems()
                }
            }
        },
        async saveClashes() {
            var t = this; 

            if (t.unallocateClashes) {
                
                var leave = t.leave 

                var leaveStart = this.$moment(leave.leaveStartDateTime).format('X')
                var leaveEnd = ''

                if (leave.leaveAllDay) {
                    leaveEnd = this.$moment(leave.leaveEndDateTime).add(1, 'days').format('X')
                } else {
                    leaveEnd = this.$moment(leave.leaveEndDateTime).format('X')
                }

                if (t.clashes.holiday > 0) {

                    let eventsResult = await this.REDIS_searchFor('leave', 0, 9999, 'leaveStartDateTime','ASC', `@deleted:{false} @leaveAssignedUserId:{${this.item.entityId}} @leaveStartDateTime:[${leaveStart} +inf] @leaveStartDateTime:[-inf ${leaveEnd}]` )
                    if (eventsResult.success) {
                        var eventClashes = eventsResult.data.documents;

                        for (var i = 0; i < eventClashes.length; i++) {
                            var event = eventClashes[i]
                            event.deletedUserId = t.GET_FIREBASE_currentUser.entityId

                            let eventDelete = await this.REDIS_delete('leave', event.entityId, event);
                            
                            if (eventDelete.success) {
                                t.deleteInstance(event.leaveGroupId)
                                t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                            } else {
                                t.MIX_alertBox({ color: "error", timeout: 2000, message: "Error Deleting Item", show: true });
                            }

                        }
                    }
                }

                if (t.clashes.job > 0) {
                    let itemsResult = await this.REDIS_searchFor('jobs', 0, 9999, 'jobStartDateTime','ASC', `@deleted:{false} @jobAssignedUserId:{${this.item.entityId}} @jobStartDateTime:[${leaveStart} +inf] @jobStartDateTime:[-inf ${leaveEnd}]` )
                
                    if (itemsResult.success) {
                        var jobClashes = itemsResult.data.documents;

                        for (var j = 0; j < jobClashes.length; j++) {
                            var job = jobClashes[j]
                            var absence = {...t.absenceSchema}

                            absence.absenceType = leave.leaveType 
                            absence.absenceDuration = job.jobDuration 
                            absence.absenceClientId = job.jobClientId 
                            absence.absenceUserId = job.jobAssignedUserId 
                            absence.absenceDate = job.jobStartDateTime
                            absence.absenceJobId = job.entityId 
                            absence.createdUserId = t.GET_FIREBASE_currentUser.entityId;
                            absence.createdUserId = t.GET_FIREBASE_currentUser.entityId;

                            let absenceResult = await this.REDIS_create('absence', absence)
                            if (absenceResult.success) {
                                // 
                            }

                            job.jobAssignedStatus = 'UNALLOCATED'
                            job.jobAssignedUserId = ''
                            job.jobPublishedStatus = 'UNPUBLISHED'
                            job.modifiedUserId = t.GET_FIREBASE_currentUser.entityId


                            let result = await this.REDIS_update('jobs', job.entityId, job);
                            
                            if (result.success) {
                                // t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                            } else {
                                // t.MIX_alertBox({ color: "error", timeout: 2000, message: "Error Saving Item", show: true });
                            }
                            
                        }
                    }
                }

            } 

            t.saveLeaveDays()
        },
        async saveLeaveDays() { 
			let t = this;


            // if !itemNew, need to delete the old leave
            if (!t.itemNew) {
                await this.deleteLeave()
            }

            var startDate = moment(t.leave.leaveStartDateTime)
            console.log('1 = '+ startDate)

            var endDate = moment(t.leave.leaveEndDateTime)
            console.log(endDate)

            var duration = moment.duration(endDate.diff(startDate))
            console.log(duration)
            console.log(duration.asDays())
            var days = Math.floor(duration.asDays())
            console.log(days)

            var start,end;

            var groupId = t.MIX_generateId()

            for (var i = 0; i <= days; i++ ) {
                var leave = {...t.leaveSchema }
                var leaveAllDay = false

                if (i == 0 && days > 0){
                    start = startDate;
                    end = moment(startDate).endOf("day").toISOString()
                    leaveAllDay = t.leave.leaveAllDay
                } else if (i == days && days > 0){
                    start = moment(endDate).startOf('day').toISOString()
                    end = endDate;
                    leaveAllDay = t.leave.leaveAllDay
                } else if (days > 0) {
                    start = moment(startDate).add(i, 'days').startOf('day').toISOString()
                    end = moment(startDate).add(i, 'days').endOf('day').toISOString()
                    leaveAllDay = true
                } else {
                    start = startDate;
                    end = endDate;
                    leaveAllDay = t.leave.leaveAllDay

                }

                leave.leaveAssignedUserId = t.leave.leaveAssignedUserId
                leave.leaveStartDateTime = start
                leave.leaveEndDateTime = end
                leave.leaveType = t.leave.leaveType
                leave.leaveAllDay = leaveAllDay
                leave.leaveNotes = t.leave.leaveNotes
                leave.createdDateTime = t.leave.createdDateTime
                leave.createdUserId = t.leave.createdUserId
                leave.leaveGroupId = groupId

                await this.REDIS_create('leave', leave);
            }

            var instance = {...t.instanceSchema}

            instance.instanceType = t.leave.leaveType
            instance.instanceStartDateTime = t.leave.leaveStartDateTime
            instance.instanceEndDateTime = t.leave.leaveEndDateTime
            instance.instanceAllDay = t.leave.leaveAllDay
            instance.instanceNotes = t.leave.leaveNotes
            instance.instanceAssignedUserId = t.leave.leaveAssignedUserId
            instance.instanceLeaveId = groupId

            instance.createdDateTime = t.leave.createdDateTime
            instance.createdUserId = t.leave.createdUserId

            console.log('instance = ' + JSON.stringify(instance, null, 2))

            await this.REDIS_create('instance', instance);

            
            t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
            t.cancelLeaveDialog()
            t.getItems()

        },
        validateItem() {
            var t = this;

            var startDate = moment(t.computedDate1).format('x')
            console.log(startDate)
            var endDate = moment(t.computedDate2).format('x')
            console.log(endDate)
            
            t.rules = {
                required: [(v) => !!v || 'Required'],
                
                startDate: [
                    (v) => !!v || 'Required',
                    // if startdate is after enddate, throw error
                    // (v) => t.$moment(startDate).isBefore(endDate) && startDate !== endDate || 'Start Date must be before End Date'

            
                ],
                endDate: [
                    (v) => !!v || 'Required'
                ],
            };
            t.$nextTick(() => {
                if (t.$refs.form.validate()) {
                    t.saveLeave()
                } else {
					this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
                }
            });
        },
        async saveLeave() { 
			let t = this;

            var dateTimeNow = t.$moment().toISOString()
            t.leave.createdDateTime = dateTimeNow;
            t.leave.createdUserId = t.GET_FIREBASE_currentUser.entityId;
            t.leave.leaveAssignedUserId = this.item.entityId

            if (!t.leave.leaveAllDay) {
                t.leave.leaveStartDateTime = this.$moment(this.date1, 'YYYY-MM-DD').add(this.startTimeHours, 'hours').add(this.startTimeMins, 'minutes').toISOString()
                t.leave.leaveEndDateTime = this.$moment(this.date2, 'YYYY-MM-DD').add(this.endTimeHours, 'hours').add(this.endTimeMins, 'minutes').toISOString()
            } else {
                t.leave.leaveStartDateTime = this.$moment(this.date1, 'YYYY-MM-DD').toISOString()
                t.leave.leaveEndDateTime = this.$moment(this.date2, 'YYYY-MM-DD').toISOString()
            }

            // check clashes 
            let clashes = await this.checkClashes()

            // if editing leave, only show if there are job clashes
            if (t.editmode) {
                if (clashes.job > 0) {
                    t.showClashes = true
                } else {
                    t.saveLeaveDays()
                }
            } else if (clashes.holiday > 0 || clashes.job > 0) {
                t.showClashes = true
            } else { 
                t.saveLeaveDays()
            }

        },
        nextWeek() {
            this.currentWeekStartDate = this.addDays(this.currentWeekStartDate, 7);
            this.getItems()
        },
        currentWeek() {
            this.currentWeekStartDate = moment().startOf('week').add(1, 'days');
            this.getItems()
        },
        priorWeek() {
            this.currentWeekStartDate = this.subtractDays(this.currentWeekStartDate, 7);
            this.getItems()
        },
        addDays(date, days) {
            let newDate = moment(date).add(days, 'days').format('DD-MMM-YYYY');
            return newDate;
        },
        subtractDays(date, days) {
            let newDate = moment(date).subtract(days, 'days').format('DD-MMM-YYYY');
            return newDate;
        },
        initialise() {
            this.item = { ...this.datadown };
            this.itemNew = false;
            this.editmode = false;
        },
        updateItem() {
            this.$emit('update', this.item);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {
            this.$emit('save', this.item);
            this.editmode = false;
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        getEventColor(event) {
            if (event.type === "Holidays") {
                return 'orange'
            } else if (event.type === 'Job' || event.type === 'Recurring Job') {
                return 'success'
            } else if (event.type !== '' && event.type !== 'UNKNOWN') {
                return 'pink'
            } else {
                return 'grey'
            }
        },
        async getItems() {
            var t = this;
            t.loading = true

            let eventsResult = await this.REDIS_searchFor('leave', 0, 9999, 'leaveStartDateTime','ASC', this.computedSearchQuery )
            if (eventsResult.success) {
                this.events = eventsResult.data.documents;
            }

            // ! NEED TO GET JOBS 
            let itemsResult = await this.REDIS_searchFor('jobs', 0, 9999, 'jobStartDateTime','ASC', this.computedJobsSearchQuery )
            if (itemsResult.success) {
                this.items = itemsResult.data.documents;
            }

            // t.getEvents()
            t.loading = false
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
                this.getItems()
            },
            deep: true,
        },
        selectedPackageIcon: {
            handler: function () {
                this.item.packageIcon = this.selectedPackageIcon.icon;
            }, deep: true
        },

    },
    // * CREATED
    async created() {
        let t = this;
        t.leaveSchema = schema.leave;
        t.absenceSchema = schema.absence
        t.instanceSchema = schema.instance
        console.log('instance schema = ' + JSON.stringify(t.instanceSchema, null, 2))

        t.leave = {...t.leaveSchema}

        t.initialise();
        let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false} -@clientStatus:{ARCHIVE}');
        if (clients.success) { t.clients = clients.data.documents; }

        t.clients = t.clients.map((x) => {
            return {
                entityId: x.entityId,
                clientName: x.clientName,
            };
        })
        let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
        if (sites.success) { t.sites = sites.data.documents; }

        t.sites = t.sites.map((x) => {
            return {
                entityId: x.entityId,
                siteName: x.siteName,
            };
        })

        t.getItems()

    },
    mounted() {},
};
</script>

<style lang="scss">


.v-event-draggable {
    padding-left: 6px;
}

.v-event-timed {
    user-select: none;
    -webkit-user-select: none;
}

.v-event-drag-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4px;
    height: 4px;
    cursor: ns-resize;
}

.v-event-drag-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4px;
    height: 4px;
    cursor: ns-resize;

    &::after {
        display: none;
        position: absolute;
        left: 50%;
        height: 4px;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        width: 16px;
        margin-left: -8px;
        opacity: 0.8;
        content: '';
    }

    &:hover::after {
        display: block;
    }
}
.v-event-timed:hover .v-event-drag-bottom::after {
    display: block;
}

.v-calendar .v-event-timed-container {
    margin-right: 0px !important
}

</style>