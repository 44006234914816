import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

// * Import modular stores
// import firebase_auth_store from "@/plugins/firebase/firebase_auth_store.js";
//qr code
import qr_code_reader_store from '@/components/qr-code-reader/qr_code_reader_store.js';
import qr_code_generator_store from '@/components/qr-code-generator/qr_code_generator_store.js';

// Persisted State Required for Maintaining Login State
import createPersistedState from "vuex-persistedstate";
// * FIREBASE AUTH STORE
import firebase_auth_store from '@/firebase/auth/firebase_auth_store.js';

export default new Vuex.Store({
	plugins: [ createPersistedState() ],
	state: {
		alertBox: { color: "success", timeout: 2000, message: "Success", show: false }, // alertBox state        
		appUpdate: { show: false },
		taskBar: false,
        jobFilters: {}
	},
	getters: {
        GET_currentUser(state) { return state.currentUser;},
        // USER DATA    
        GET_currentUserData(state) { return {
            userId: state.currentUser.id,
            userName: state.currentUser.userName,
            userEmail: state.currentUser.userEmail
        }},
        GET_alertBox(state) { return state.alertBox }, // alertBox getters        
		GET_appUpdate(state) { return state.appUpdate }, // appUpdate getters
		GET_taskBar(state) { return state.taskBar }, // taskBar getters
        currentAlert(state){
            return state.alert;
        },
        GET_jobFilters(state) { return state.jobFilters }, // jobFilters getters
	},
	mutations: {
		MUT_alertBox(state, payload) { state.alertBox =  payload }, // alertBox mutation        
		MUT_appUpdate(state, payload) { state.appUpdate =  payload }, // appUpdate mutation
		MUT_taskBar(state) { state.taskBar = !state.taskBar }, // taskBar mutations
        SET_currentUser(state, payload) {
          state.currentUser = payload;
        },
        setAlert(state,data){
            state.alert = data;
        },
        MUT_setJobFilters(state, payload) { state.jobFilters = payload }, // setJobFilters mutation
	},
	actions: {
		ACT_alertBox({commit}, payload) { commit('MUT_alertBox', payload) }, // alertBox action        
        ACT_appUpdate({commit}, payload) { commit('MUT_appUpdate', payload) }, // appUpdate action		
		ACT_taskBar({commit}) { commit('MUT_taskBar') }, // taskBar action
        ACT_setJobFilters({commit}, payload) { commit('MUT_setJobFilters', payload) }, // setJobFilters action
	},
	modules: {
        // alertbox_store: alertbox_store,
        firebase_auth_store: firebase_auth_store,
        qr_code_reader_store: qr_code_reader_store,
        qr_code_generator_store: qr_code_generator_store,
    },
});
