<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: hidden !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>

                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="primary white--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn text class="primary white--text" v-if="editmode" @click="validateItem()"><v-icon class="mr-2">oomph-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="apptext--text" dense style="z-index: 0 !important">
            <v-col v-if="!editmode && !loading" cols="12" class="d-flex pa-2 align-center">

                <!-- //* VIEW MODE -->
                <v-row style="width: 100% !important" no-gutters>
                    <!-- //* GENERAL DETAILS VIEW -->
                    <v-col cols="12" sm="12" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Course Details</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Name:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.courseName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Description:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.courseDescription }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Length (Hours):</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.courseLengthHours }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Validity (Months):</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.courseValidityYears }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Starter Pack:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.courseStarterPack }}</td>
                                            </tr>
                                            <!-- <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">User Category:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.courseUserCategory }}</td>
                                            </tr> -->
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="editmode && !loading" cols="12" class="d-flex pa-4 pt-4 align-center">
                <v-form ref="form" lazy-validation>
                    <v-row style="width: 100% !important" no-gutters>
                        <!-- DETAILS EDIT -->
                        <!-- <v-col cols="6">
                            <pre class="caption">{{ item }}</pre>
                        </v-col> -->
                        <!-- <v-col cols="6">
                            <pre class="caption">{{ selectedPackageIcon }}</pre>
                        </v-col> -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Course Name" :rules="rules.required" v-model="item.courseName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('areaName')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Course Description" v-model="item.courseDescription" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('areaDescription')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field 
                                    label="Course Length" 
                                    suffix="Hours" 
                                    type="number" 
                                    min=0 
                                    v-model="item.courseLengthHours" 
                                    :oninput="item.courseLengthHours < 0 ? item.courseLengthHours = 0 : ''"
                                    outlined 
                                    dense 
                                    hide-details="auto" 
                                    background-color="white" 
                                    color="primary" 
                                    clearable 
                                    @click:clear="clearItem('areaName')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field 
                                    label="Course Validity" 
                                    suffix="Months" 
                                    type="number" 
                                    min=0 
                                    v-model="item.courseValidityYears" 
                                    :oninput="item.courseValidityYears < 0 ? item.courseValidityYears = 0 : ''"
                                    outlined 
                                    dense 
                                    hide-details="auto" 
                                    background-color="white" 
                                    color="primary" 
                                    clearable
                                    @click:clear="clearItem('areaDescription')">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" class="pl-2 pt-0 mt-0 d-flex align-start justify-start ">
                                    <v-switch v-model="item.courseStarterPack" color="primary" label="Starter Pack" hide-details="auto" inset></v-switch>
                                </v-col>
                                <!-- <v-col cols="12" xs="12">
                                    <v-text-field label="Course User Category" v-model="item.courseUserCategory" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('areaDescription')"></v-text-field>
                                </v-col> -->
                                

                            </v-row>



                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
import schema from '@/schema.js';

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        // activeitem: {
        //     Type: Object,
        //     Default: null,
        // },
    },
    // * DATA
    data: () => ({
        key: 'course',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,
        // dropdowns
        packageTypes: [],
        billingCycles: [],
        packageStatus: [],
        selectedPackageIcon: {
            name: '',
            icon: ''
        },  
        months: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],


        // new address object
        item: {},
        loading: false,
        rules: {},

    }),
    computed: {  
    },
    methods: {
        initialise() {
            this.item = { ...this.datadown };
            //console.log('item', JSON.stringify(this.item, null, 2));
            // let iconResult = this.icons.find(icon => icon.icon === this.item.packageIcon);
            // if (iconResult !== undefined) {
            //     this.selectedPackageIcon = iconResult;
            // }
            if (this.item.courseName !== '') {
                this.itemNew = false;
                this.editmode = false;
            } else {
                this.itemNew = true;
                this.editmode = true;
            }
        },
        updateItem() {
            this.item.courseLengthHours = parseInt(this.item.courseLengthHours)
            this.item.courseValidityYears = parseInt(this.item.courseValidityYears)
            this.$emit('update', this.item);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        validateItem() {
            let t = this; 

            t.rules = {
				required: [(v) => !!v || "Required"],
            }; 
            t.$nextTick(() => {
                if (t.$refs.form.validate()) {
                    if (t.itemNew == true) {
					    this.saveItem();
                    } else {
                        this.updateItem();
                    }

                }
            
            })
        },
        // * CREATE ITEM
        async saveItem() {
            this.item.courseLengthHours = parseInt(this.item.courseLengthHours)
            this.item.courseValidityYears = parseInt(this.item.courseValidityYears)
            this.$emit('save', this.item);
            this.editmode = false;
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        // activeitem: {
        //     handler: function () {
        //         let t = this;
        //         t.item = { ...t.activeitem };
        //     }, deep: true
        // },
        selectedPackageIcon: {
            handler: function () {
                this.item.packageIcon = this.selectedPackageIcon.icon;
            }, deep: true
        },

    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.course;
        t.loading = true
        t.initialise();


        // let packageCategories = await t.MIX_redisReadSet('set:packageCategory', false);
        // if (packageCategories.code === 1) {
        //     t.packageCategories = packageCategories.data;
        // }
        // let packageStatus = await t.MIX_redisReadSet('set:packageStatus', false);
        // if (packageStatus.code === 1) {
        //     t.packageStatus = packageStatus.data;
        // }
        // let billingCycles = await t.MIX_redisReadSet('set:billingCycles', false);
        // if (billingCycles.code === 1) {
        //     t.billingCycles = billingCycles.data;
        // }
        t.loading = false
    },
    mounted() {},
};
</script>