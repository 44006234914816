<template>
	<v-row fluid fill-height class="d-flex flex-column align-start appbackground" style="height: 100% !important" no-gutters>
		
        <div class="text-center">
            <v-overlay :value="loading">
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
                <div v-if="creatingRule" class="font-weight-bold pt-4">Creating Jobs, please wait</div>
            </v-overlay>
        </div>

        <v-col class="align-center" cols="12">
            <v-row dense >
                <v-col  class="pa-0 ma-0 d-flex align-start d-flex flex-column" cols="4" >
                    <div style="width: 100% !important" class="pa-0 pb-0 align-center white--text d-flex secondary">
                        <v-btn color="white" class="mt-1" text @click="$router.go(-1)">
                            <v-icon>icons8-back</v-icon> Back 
                        </v-btn>
                    </div>
                <!-- v-if="['SA'].includes(GET_currentUser.userLevel)" -->
                
                    <div style="width: 100% !important" class="pa-0 pb-0 pl-3 pt-2  align-center d-flex">
                        <v-icon v-if="item.recurringRuleStatus == 'ARCHIVED'" class="error--text pr-1" large>icons8-cancel </v-icon>   
                        <div class="primary--text pr-1 page-title">RECURRING </div>       
                        <div class="secondary--text page-title"> JOB</div>
                        <v-spacer />
                        <v-btn color="primary" class="elevation-0 mr-2" v-if="!editMode && !itemNew && !showRules" @click="editMode = !editMode">Edit</v-btn>
                        <v-btn color="error" class="elevation-0 mr-2" v-if="editMode && !itemNew && item.recurringRuleStatus !== 'ARCHIVED'" @click="openArchiveDialog()">Archive</v-btn>
                        <v-btn color="primary" class="elevation-0 mr-2" v-if="itemNew" @click="refresh">Start New</v-btn>
                    </div>
                    <div v-if="!itemNew" style="width: 100% !important" class="pa-0 pb-0 pl-3 pt-2  align-center">
                        <div v-if="item.modifiedDateTime !== '' && item.modifiedDateTime !== null" class="pl-1 font-weight-medium caption grey--text text-darken-2">
                            Modified By: {{ getUser(item.modifiedUserId) }}, at {{ $moment(item.modifiedDateTime).format('HH:mm DD-MMM-YYYY') }}
                        </div>
                        <div class="pl-1 font-weight-medium caption grey--text text-darken-2">
                            Created By: {{ getUser(item.createdUserId) }}, at {{ $moment(item.createdDateTime).format('HH:mm DD-MMM-YYYY') }}
                        </div>
                    </div>
                    <div class="pl-3 pr-1" style="width: 100% !important">
                        <recurring-job-details 
                            :datadown="item"
                            v-on:ruleCreated="ruleCreated"
                            :users="users"
                            :clients="clients"
                            :services="services"
                            :sites="sites"
                            v-on:refreshitems="refreshItems"
                            :editmode="editMode"
                        />
                    </div>
                </v-col>
                <v-col cols='8' class="pa-0 ma-0 d-flex pt-2 flex-column lightgrey" v-if="$vuetify.breakpoint.mdAndUp">

                    <div style="width: 100% !important" class="pa-0 pb-0 pl-3 pr-1 pt-0  align-center d-flex page-title">
                        <!-- <div v-if="!itemNew" class="secondary--text pr-1">SCHEDULE</div> -->
                        <div v-if="showRules" class="secondary--text pr-1">RULES</div>
                    </div>
                    <div v-if="showRules" class="" style="width: 100% !important">
                        <div v-for="(r, index) in rules" :key="index">
                            <!-- <pre>Rule = {{ r }}</pre> -->
                            
                            <recurring-job-rules
                                :datadown="r"
                                :users="users"
                                :clients="clients"
                                :services="services"
                                :sites="sites"
                                v-on:duplicate="duplicateItem"
                                v-on:createRecurringRule="createItem"
                            />
                        </div>
                    </div>


                    <div v-if="!itemNew && !showRules" class="" style="width: 100% !important">
                        <v-row class="apptext--text pa-4 pt-0" dense style="z-index: 0 !important">
                            <JobsList :datadown="item" :refreshitems="refreshitems" :list="true" v-on:openItem="openItem" />
                        </v-row>
                    </div>

                </v-col>
            </v-row>
            
            <v-dialog persistent v-model="archiveDialog" width="500px" height="400px" style="overflow: hidden !important">
                <div class="d-flex flex-column ma-0 pa-0">
                    <div class="pa-3 py-8 ma-0 secondary d-flex align-center px-4" elevation="0" style="height: 50px;">
                        <div class="mr-3 mb-1">
                            <v-icon class="white--text">icons8-future</v-icon>
                        </div>
                        <div class="font-weight-bold white--text text-h6 d-flex align-center">
                            <span class="font-weight-light">
                                <strong>Archive Recurring Job?</strong>
                            </span>
                        </div>
                        <v-spacer />
                        <div>
                            <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeArchiveDialog" depressed>
                                <v-icon>oomph-multiply</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <v-card-text class="grey lighten-3 pa-0">               
                        
                        <v-row v-if="!archiveLoading && progressData.status == 'Not Started'" class="pa-0 pt-4 ma-0 justify-center d-flex" fill-height>
                            <v-col cols="12" class="align-center pt-0">
                                <v-menu v-model="dateMenu3" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field label="Edit From" v-model="computedDate3" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('contentAvailableFrom')"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date3" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu3 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="pa-0" fill-height cols="12" style="width: 100% !important">
                                <div class="font-weight-bold body-2 pa-3 text-h7 grey--text">
                                    This will archive Jobs relating to this Recurring Job from {{ computedDate3 }}
                                </div>
                            </v-col>
                        </v-row>

                    </v-card-text>

                    <v-card-text v-if="archiveLoading || progressData.status !== 'Not Started'" class="grey lighten-3 px-0 pa-3 d-flex flex-column align-center">    

                        <v-progress-circular v-if="progressData.status == 'In Progress' || progressData.status == 'Not Started'" indeterminate :size="60" :width="7"></v-progress-circular>
                        <div v-if="progressData.status == 'In Progress' || progressData.status == 'Not Started'" class=" grey--text font-weight-bold text--darken-2 d-flex align-center animate__animated animate__flash animate__infinite animate__slow">The Jobs linked to this client are being archived. Please wait...</div>
                        <div v-if="progressData.status == 'Completed'" class=" grey--text font-weight-bold text--darken-2 d-flex align-center animate__animated animate__flash animate__infinite animate__slow">The Jobs linked to this client have been successfully archived.</div>

                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-actions class="grey lighten-1 pa-0">
                        <v-col cols="12" class="d-flex">
                            <v-col cols="6" class="d-flex justify-start pa-0">
                            </v-col>
                            <v-col cols="6" class="d-flex justify-end pa-0">
                                <v-btn v-if="progressData.status == 'Not Started'" class="grey darken-1 white--text font-weight-bold" :disabled="archiveLoading" depressed @click="closeArchiveDialog"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                                <v-btn v-if="progressData.status !== 'Not Started'" class="grey darken-1 white--text font-weight-bold" :disabled="progressData.status !== 'Completed'" depressed @click="closeArchiveDialog"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Close</span> </v-btn>
                                <v-btn v-if="progressData.status == 'Not Started'" class="primary white--text font-weight-bold ml-2" depressed :disabled="archiveLoading" @click="archiveRule()"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
                            </v-col>
                        </v-col>
                    </v-card-actions>
                </div>
            </v-dialog>

            <v-dialog persistent v-model="progressDialog" width="500px" height="400px" style="overflow: hidden !important">
                <div class="d-flex flex-column ma-0 pa-0">
                    <div class="pa-3 py-8 ma-0 secondary d-flex align-center px-4" elevation="0" style="height: 50px;">
                        <div class="mr-3 mb-1">
                            <v-icon class="white--text">icons8-future</v-icon>
                        </div>
                        <div class="font-weight-bold white--text text-h6 d-flex align-center">
                            <span class="font-weight-light">
                                <strong>Creating Recurring Jobs</strong>
                            </span>
                        </div>
                        <v-spacer />
                        <div>
                            <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeProgressDialog" depressed>
                                <v-icon>oomph-multiply</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <v-card-text class="grey lighten-3 px-0 pa-3 d-flex flex-column align-center">    

                        <v-progress-circular v-if="progressData.status == 'In Progress' || progressData.status == 'Not Started'" indeterminate :size="60" :width="7"></v-progress-circular>
                        <div v-if="progressData.status == 'In Progress' || progressData.status == 'Not Started'" class=" grey--text font-weight-bold text--darken-2 d-flex align-center animate__animated animate__flash animate__infinite animate__slow">The Jobs are being Created. Please wait...</div>
                        <div v-if="progressData.status == 'Completed'" class=" grey--text font-weight-bold text--darken-2 d-flex align-center animate__animated animate__flash animate__infinite animate__slow">The Jobs have been successfully created.</div>

                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-actions class="grey lighten-1 pa-0">
                        <v-col cols="12" class="d-flex">
                            <v-col cols="6" class="d-flex justify-start pa-0">
                            </v-col>
                            <v-col cols="6" class="d-flex justify-end pa-0">
                                <v-btn class="grey darken-1 white--text font-weight-bold" :disabled="progressData.status == 'In Progress'" depressed @click="closeProgressDialog"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Close</span> </v-btn>
                                <!-- <v-btn class="primary white--text font-weight-bold ml-2" depressed :disabled="progressData.status == 'In Progress'" @click="archiveRule()"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn> -->
                            </v-col>
                        </v-col>
                    </v-card-actions>
                </div>
            </v-dialog>
            
        </v-col>
    </v-row>
</template>

<script>
	import { mapGetters } from "vuex";
    import recurringJobDetails from "@/views/recurringJobs/recurringJobDetails.vue"
    import recurringJobRules from "@/views/recurringJobs/recurringJobRules.vue"
	import schema from "@/schema.js";
    import JobsList from '@/views/jobs/jobList.vue'
    import moment from 'moment'
	export default {
		// * COMPONENTS
		components: {
			"recurring-job-details": recurringJobDetails,
			"recurring-job-rules": recurringJobRules,
            'JobsList': JobsList
        },
		// * PROPS
		props: {
			datadown: {
				type: Object,
				default: function () {
					return {};
				},
			},
		},
		// * DATA
		data: () => ({
			key: "recurring-job",
			tab: "details",
			schema: null,
			// Item
			item: {},
			itemNew: false,
			editMode: false,

            loading: false,
            creatingRule: false,

			// Form items
			accountTypes: [],
			addressTypes: [],
			industries: [],

            routeId: '',
        
            refreshitems: 0,

			// Tags
			computedOrgTags: [],
            userOpen: false,

			rules: [],

            rule: {},

			// Toggles
			addAddressToggle: false,
			addManualAddress: false,
			addOtherAddressToggle: false,
			addLeadStatusToggle: false,
			addOrgInfoToggle: false,
			addSocialInfoToggle: false,

            showRules: false,

            users: [],
            clients: [],
            services: [],
            sites: [],

            archiveDialog: false,
            date3: moment().format('YYYY-MM-DD'),
            dateMenu3: false,

            archiveLoading: false,

            progressDialog: false,
            progress: 0,
            progressData: {
                id: "",
                totalItems: 0,
                count: 0,
                success: 0,
                error: 0,
                status: "Not Started",
                report: "",
            },
		    progressStatus: "Not Started",
		    checkProgressInterval: null,

		}),
		// * COMPUTED
		computed: {
            ...mapGetters({
                GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
            }),
            computedDate3() {
                return this.MIX_formatDateTime(this.date3, "YYYY-MM-DD", "DD-MMM-YYYY");
            },
		},
		// * METHODS
		methods: {
            openArchiveDialog() {
                var t = this; 

                t.archiveDialog = true
            },
            closeArchiveDialog() {
                var t = this;

                t.archiveDialog = false
                t.refreshItems(t.item.entityId)
                t.editMode = false
            },
            closeProgressDialog() {
                var t = this;
                console.log('closeProgressDialog')
                t.progressDialog = false
                t.editMode = false
                t.refreshItems(t.item.entityId)
            },
            getUser(userId) {
                var t = this;
                var user = []
                var userName = ''

                user = t.users.filter((item) => { return item.entityId == userId })

                if (user.length !== 0) { userName = user[0].userName }

                return userName
            },
            async archiveRule() {
                var t = this;

                let data = {}
                t.archiveLoading = true
                data.recurringJobId = t.item.entityId 
                data.archiveFromDate = t.$moment(t.computedDate3, 'DD-MMM-YYYY').format('X')
                data.modifiedUserId = t.GET_FIREBASE_currentUser.entityId

                data.progressId = t.MIX_generateId()

                let result = await t.REDIS_archiveRecurringJob(data)
                console.log('result = ' + JSON.stringify(result, null, 2))
                if (result.success) {
                    t.MIX_alertBox({ color: "success", timeout: 2000, message: "Recurring Rule Archived", show: true });
                    // t.archiveLoading = false
                    this.checkProgress(data.progressId)
                    // t.refreshItems(t.item.entityId)
                } else {
                    t.MIX_alertBox({ color: "error", timeout: 2000, message: "Error Archiving Recurring Rule", show: true });
                    // t.archiveLoading = false
                }

            },
            duplicateItem(item) {
                console.log('duplicating item = ' + JSON.stringify(item, null, 2))
                this.rules.push(item)
            },
            refresh() {
                var t = this
				t.itemNew = true;
				t.editMode = true;
                t.showRules = false
				t.item = { ...t.schema };
				t.item.entityId = t.MIX_generateId();
                t.rules = []
            },
			// * INITIALISE ITEM
			initialiseItem() {
				let t = this;
				if (JSON.stringify(t.datadown) !== "{}") {
                    console.log('old item')
					this.readItem(t.datadown.entityId);
                    t.itemNew = false 
                    t.editMode = false
				} else {
                    console.log('new item')
					t.itemNew = true;
					t.editMode = true;
					t.item = { ...t.schema };
					t.item.entityId = t.MIX_generateId();

				}
			},
			// * CREATE ITEM
			async createItem(item) {
				let t = this;
                t.loading = true
                console.log('creating rule = ' + JSON.stringify(item, null, 2))
                
                let result = await this.REDIS_createRecurring(t.key, item);
                
				if (result.success) {
					t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                    this.checkProgress(item.progressId)
                    this.progressDialog = true
				}
                t.loading = false
			},

            // Check Progress of Import
            checkProgress(progressId) {
                let t = this;
                this.checkProgressInterval = setInterval(async () => {
                    try {
                        let progressResult = await t.REDIS_read("progress", progressId);
                        if (progressResult.status !== "404") {
                            console.log('progressResult = ' + JSON.stringify(progressResult, null, 2))
                            t.progressData = progressResult.data;
                            t.progressStatus = progressResult.data.status;
                            t.progress = (progressResult.data.count / progressResult.data.totalItems) * 100;
                            console.log('progress = ' + t.progress)
                            if (progressResult.data.status === "Completed") {
                                clearInterval(t.checkProgressInterval);
                                t.archiveLoading = false
                            }
                        } else if (progressResult.status === "404") {
                            clearInterval(t.checkProgressInterval);
                            t.archiveLoading = false
                        }
                    } catch (error) {
                        console.error(error);
                        clearInterval(t.checkProgressInterval);
                        t.archiveLoading = false
                    }
                }, 1000);
            },

			// * READ ITEM
			async readItem(itemId) {
				let t = this;
                t.loading = true
                console.log('reading item = ' + itemId)
				let result = await t.REDIS_read(t.key, itemId);
				if (result.success) {
					this.item = result.data;
					t.itemNew = false;
                    t.editMode = false
					t.loading = false;
				}
			},
            openItem(item) {
                this.MIX_go('/job/' + item.entityId)
            },
            ruleCreated(item) {
                var t = this; 

                t.rule = item,
                t.rules.push(item)
                t.showRules = true
                // t.itemNew = false
            },
            async refreshItems(itemId) {
                var t = this
                this.refreshitems++
                console.log('refreshing items = ' + itemId)
                let result = await t.REDIS_read(t.key, itemId);
                if (result.success) {
                    this.item = result.data;
                    this.editMode = false 
                    this.itemNew = false
                }
            }

		},
		// * WATCH
		watch: {
			// Datadown Changes
			datadown() {
				this.initialiseItem();
			},
		},
		// * CREATED
		async created() {
			let t = this;
			t.loading = true;
			t.schema = schema.recurringJob;
            
			t.initialiseItem();

            let users = await t.REDIS_searchFor('user', '0', '999', 'userName', 'ASC', '@deleted:{false} @userStatus:{APPROVED}');
            if (users.success) { t.users = users.data.documents; }

            t.users = t.users.map((x) => {
                return {
                    entityId: x.entityId,
                    userName: x.userName,
                    userCategory: x.userCategory
                };
            })

            let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false} -@clientStatus:{ARCHIVE}');
            if (clients.success) { t.clients = clients.data.documents; }

            t.clients = t.clients.map((x) => {
                return {
                    entityId: x.entityId,
                    clientName: x.clientName,
                };
            })

            
            let services = await t.REDIS_searchFor('service', '0', '9999', 'serviceName', 'ASC', '@deleted:{false}');
            if (services.success) { t.services = services.data.documents; }

            t.services = t.services.map((x) => {
                return {
                    entityId: x.entityId,
                    serviceName: x.serviceName,
                };
            })

            let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
            if (sites.success) { t.sites = sites.data.documents; }

            t.sites = t.sites.map((x) => {
                return {
                    entityId: x.entityId,
                    siteName: x.siteName,
                    siteClientId: x.siteClientId
                };
            })
            t.loading = false

		},
		// * MOUNTED
		async mounted() {
		    this.routeId = this.$route.params.id;
            if (this.routeId === "new") {
                // this.openItem({})
            } else if (this.routeId !== "new" && this.routeId !== "" && this.routeId !== undefined) {
				this.readItem(this.routeId);
            } 
        },
	};
</script>

<style scoped>
	.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
	.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
	.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
		height: 22px;
	}
</style>
