<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important">
        <v-row class="apptext--text" dense style="width: 100% !important; z-index: 0 !important">
            <v-col cols="12" class="pt-4 px-4">
                <v-card outlined style="border: 2px solid darkgrey; background-color: #E0E0E0">
                    <v-form ref="form" > 
                        <v-row>
                            <v-col cols="11 pb-0">
                                <div class="pa-2 pb-0">
                                    This Job is scheduled for {{ rule.recurringRuleToText }}
                                </div>
                            </v-col>
                        </v-row>


                        <v-row dense class="pt-2 px-2">
                            <v-col cols="6" class="pb-0 pr-1">
                                <v-autocomplete 
                                    label="Select Client" 
                                    v-model="selectedClient" 
                                    @change="getClientSites()" 
                                    :rules="rules.client" 
                                    required 
                                    :disabled="jobsCreated"
                                    :items="clients" 
                                    :menu-props="{ top: false, offsetY: true }" 
                                    :attach="true"
                                    auto-select-first 
                                    item-text="clientName" 
                                    return-object 
                                    dense 
                                    outlined
                                    hide-details="auto" 
                                    background-color="white" 
                                    color="primary"
                                    class="">
                                </v-autocomplete>
                            </v-col>
                            
                            <v-col cols="6" class="pb-0 pl-0">
                                <!-- //! GET SITES BASED ON CLIENT  -->
                                <v-autocomplete 
                                    label="Select Site" 
                                    v-model="selectedSite" 
                                    :rules="rules.site" 
                                    required 
                                    :items="computedSites" 
                                    :disabled="jobsCreated"
                                    :menu-props="{ top: false, offsetY: true }" 
                                    :attach="true"
                                    auto-select-first 
                                    no-data-text="Please Select Client"
                                    item-text="siteName" 
                                    return-object 
                                    dense 
                                    outlined
                                    hide-details="auto" 
                                    background-color="white" 
                                    color="primary"
                                    class="">
                                </v-autocomplete>
                            </v-col>
                            
                            <v-col cols="6" class="pr-1 pb-1">
                                
                                <v-autocomplete 
                                    label="Select Service" 
                                    v-model="selectedService" 
                                    :rules="rules.service" 
                                    required 
                                    :items="services" 
                                    :disabled="jobsCreated"
                                    :menu-props="{ top: false, offsetY: true }" 
                                    :attach="true"
                                    auto-select-first 
                                    item-text="serviceName" 
                                    return-object 
                                    dense 
                                    outlined
                                    hide-details="auto" 
                                    background-color="white" 
                                    color="primary"
                                    class="">
                                </v-autocomplete>
                            </v-col>
                            
                            <v-col cols="6" class="pl-0 pb-1">

                                <v-autocomplete 
                                    label="Select Staff Member" 
                                    v-model="selectedUser" 
                                    :rules="rules.staff"  
                                    :disabled="jobsCreated"
                                    :items="users" 
                                    :menu-props="{ top: false, offsetY: true }" 
                                    :attach="true"
                                    auto-select-first 
                                    item-text="userName" 
                                    return-object 
                                    dense 
                                    outlined
                                    hide-details="auto" 
                                    background-color="white" 
                                    color="primary"
                                    class="">
                                </v-autocomplete>
                                
                            </v-col>
                        </v-row>

                        <v-row dense class="pl-2 pb-3">
                            <v-col cols="6" class="pb-0">
                                <v-btn color="grey white--text" depressed class="" @click="duplicateItem">
                                    Duplicate
                                </v-btn>
                            </v-col>
                            <v-col cols="6" class="text-right pb-0 pr-3">
                                <v-btn color="secondary white--text" :disabled="jobsCreated" depressed class="" @click="validateRule">
                                    Create Jobs
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
import schema from '@/schema.js';

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        users: {
            type: Array,
            default: () => ({}),
        },
        clients: {
            type: Array,
            default: () => ({}),
        },
        services: {
            type: Array,
            default: () => ({}),
        },
        sites: {
            type: Array,
            default: () => ({}),
        },
    },
    // * DATA
    data: () => ({
        key: 'job',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,

        // new job object
        item: {},
        loading: false,
        rules: {},

        rule: {},

        selectedClient: {
            clientName: '',
            entityId: '',
            clientManager: '',
            clientTelephone: '',
            clientEmail: '',
            clientType: '',
        },
        selectedSite: {
            siteName: '',
            entityId: '',
            siteAddress: '',
        },
        selectedService: {
            serviceName: '',
            entityId: '',
        },
        selectedUser: {
            userName: '',
            entityId: '',
        },

        startTimeHours: '', 
        startTimeMins: '',

        durationHours: '',
        durationMins: '',

        jobsCreated: false,


        // dropdowns

        panel: [0],

        computedSites: [],

        frequency: ['Daily', 'Weekly', 'Monthly'],
        days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        recurringFrequency: "",
        recurringInstance: "1",

    }),
    computed: { 
        hours() {
            var hours = []
            for (var i = 0; i < 24; i++) { hours.push (i.toString()) }
            return hours
        },  

        hoursWith0() {
            var hours = []
            for (var i = 0; i < 24; i++) { if (i < 10) { hours.push ('0' + i).toString() } else { hours.push (i.toString()) } }
            return hours
        },

        mins() {
            var mins = []
            for (var i = 0; i < 60; i++) { if (i < 10) { mins.push ('0' + i).toString() } else { mins.push (i.toString()) } i = i + 4; }
            return mins
        }, 
 
    },
    methods: {
        getClientSites() {
            var id = this.selectedClient.entityId

            var sites = this.sites 

            sites = sites.filter(function (item) {
                return item.siteClientId == id
            })
            
            this.computedSites = sites
        },
        duplicateItem() {
            var t = this;

            var item = {...t.schema}

            if (t.selectedUser.entityId !== '') {
                item.recurringJobAssignedUserId = t.selectedUser.entityId
                item.recurringJobAssignedStatus = 'ALLOCATED'
            } else {
                item.recurringJobAssignedStatus = 'UNALLOCATED'
            }

            if (t.selectedClient.entityId !== '') { item.recurringJobClientId = t.selectedClient.entityId }
            if (t.selectedService.entityId !== '') { item.recurringJobServiceId = t.selectedService.entityId }
            if (t.selectedSite.entityId !== '') { item.recurringJobSiteId = t.selectedSite.entityId }

            item.recurringJobStartDateTime = t.rule.recurringJobStartDateTime
            item.recurringJobEndDateTime = t.rule.recurringJobEndDateTime
            item.recurringJobType = 'Recurring Job'
            item.recurringJobPublishedStatus = 'UNPUBLISHED'
            item.recurringJobNotes = t.rule.recurringJobNotes
            item.recurringRuleToText = t.rule.recurringRuleToText
            item.recurringRuleInterval = t.rule.recurringRuleInterval
            item.recurringRuleDays = t.rule.recurringRuleDays
            item.recurringRuleFrequency = t.rule.recurringRuleFrequency
            item.recurringRuleStartDate = t.rule.recurringRuleStartDate
            item.recurringRuleEndDate = t.rule.recurringRuleEndDate
            item.createdUserId = t.rule.createdUserId

            this.$emit('duplicate', item)
        },
        validateRule() {
            let t = this;
            t.rules = {
                client: [
                    (v) => !!v || "Please select a client",
                    (v) => t.selectedClient.entityId !== '' || 'Please select a Client'
                ],
                site: [
                    (v) => !!v || "Please select a site",
                    (v) => t.selectedSite.entityId !== '' || 'Please select a Site'
                ],
                service: [
                    (v) => !!v || "Please select a service",
                    (v) => t.selectedService.entityId !== '' || 'Please select a Service'
                ],
                // staff: [
                //     (v) => !!v || "Please select a staff member",
                // ],
            };
            t.$nextTick(() => {
                if (t.$refs.form.validate()) {
                    this.createJobs();
                    // this.editMode = false;
                    console.log('valid')
                } else {
                    this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
                }
            
            })
        },
        createJobs() {
            var t = this; 

            var item = {...t.schema}

            item.entityId = t.MIX_generateId()
            
            if (t.selectedUser.entityId !== '') {
                item.recurringJobAssignedUserId = t.selectedUser.entityId
                item.recurringJobAssignedStatus = 'ALLOCATED'
            } else {
                item.recurringJobAssignedStatus = 'UNALLOCATED'
            }

            item.recurringJobClientId = t.selectedClient.entityId
            item.recurringJobStartDateTime = t.rule.recurringJobStartDateTime
            console.log('t.rule.recurringJobStartDateTime = ' + t.rule.recurringJobStartDateTime)
            item.recurringJobEndDateTime = t.rule.recurringJobEndDateTime
            console.log('t.rule.recurringJobEndDateTime = ' + t.rule.recurringJobEndDateTime)
            item.recurringJobType = 'Recurring Job'
            item.recurringJobPublishedStatus = 'UNPUBLISHED'
            item.recurringJobServiceId = t.selectedService.entityId
            item.recurringJobSiteId = t.selectedSite.entityId
            item.recurringJobNotes = t.rule.recurringJobNotes
            item.recurringRuleToText = t.rule.recurringRuleToText
            item.recurringRuleInterval = t.rule.recurringRuleInterval
            item.recurringRuleDays = t.rule.recurringRuleDays
            item.recurringRuleFrequency = t.rule.recurringRuleFrequency
            item.recurringRuleStartDate = t.rule.recurringRuleStartDate
            item.recurringRuleEndDate = t.rule.recurringRuleEndDate
            item.createdUserId = t.rule.createdUserId

            item.progressId = t.MIX_generateId()

            this.$emit('createRecurringRule', item)
            this.jobsCreated = true
            
        },
        initialise() {
            this.rule = { ...this.datadown };

            if (this.rule.recurringJobAssignedUserId !== '') {
                this.selectedUser = this.users.filter((item) => { return item.entityId === this.rule.recurringJobAssignedUserId })
                this.selectedUser = this.selectedUser[0]
            }
            if (this.rule.recurringJobClientId !== '') {
                this.selectedClient = this.clients.filter((item) => { return item.entityId === this.rule.recurringJobClientId })
                this.selectedClient = this.selectedClient[0]
                this.getClientSites()
            }
            if (this.rule.recurringJobServiceId !== '') {
                this.selectedService = this.services.filter((item) => { return item.entityId === this.rule.recurringJobServiceId })
                this.selectedService = this.selectedService[0]
            }
            if (this.rule.recurringJobSiteId !== '') {
                this.selectedSite = this.sites.filter((item) => { return item.entityId === this.rule.recurringJobSiteId })
                this.selectedSite = this.selectedSite[0]
            }

        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },

    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.recurringJob;
        t.initialise();
    },
    mounted() {},
};
</script>

<style scoped>

.v-expansion-panel-content__wrap {
    padding: 0 0px 0px !important;
}
.v-expansion-panel::before {
    box-shadow: none;
}
.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
    padding: 0 0px 0px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    flex: 1 1 auto;
    max-width: 100%;
}
</style>