<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: hidden !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row style="height: 68px !important" class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <!-- <div class="ml-4" >
                    <v-btn text class="primary white--text" @click="showQR()" ><v-icon class="mr-2">icons8-qr-code</v-icon>QR Code</v-btn>
                </div> -->
                <v-spacer />
                <!-- <div class="mr-4" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" >
                    <v-btn text class="primary white--text" @click="addNewCommunication" ><v-icon class="mr-2">icons8-communication</v-icon>Add</v-btn>
                </div> -->
            </v-col>
        </v-row>
        <v-row class="apptext--text pa-5 pt-3" dense style="z-index: 0 !important">
            <!-- <div class="font-weight-bold grey--text py-2">Below are the Tasks that are currently assigned to this site. To view the Read and Completed Dates of the Tasks, please use your mouse to hover over the Icon.</div> -->
            <TaskList :listshrunk="false" :refreshitems="refreshItems" :site="true" :siteId="item.entityId"/>

        </v-row>


        <v-dialog v-model="taskDialog" max-width="612" :key="taskDialogKey">
            <v-card>
                <v-toolbar color="secondary" elevation="0">
                    <app-text category="text-medium" class="white--text ml-2">Create New Task</app-text>

                    <v-spacer />
                    
                    <v-btn @click="taskDialog = false" icon dark>
                        <v-icon class="icons8-multiply" color="white" />
                    </v-btn>
                </v-toolbar>
                <div class="grey lighten-4">
                    <v-row dense class="pa-4">
                        <v-col cols="12" xs="12">
                            <v-textarea 
                                label="Task" 
                                v-model="task.taskTitle" 
                                required 
                                dense 
                                outlined
                                hide-details="auto" 
                                background-color="white" 
                                color="primary"
                                rows="5"
                                class=""
                                clearable 
                                @click:clear="clearItem('taskDescription')">
                            </v-textarea> 
                        </v-col>
                    </v-row>
                    <!-- <pre> {{ task }} </pre> -->
                </div>
                <div class="d-flex grey lighten-1 pa-4">
                    <v-btn text class="primary white--text" @click="taskDialog = false" >Close</v-btn>
                    <v-spacer />
                    <!-- <app-button @click.native="saveItem" type="primary" label="Submit" /> -->
                    <v-btn text class="secondary white--text" @click="saveItem" >Save</v-btn>
                </div>

            </v-card>
        </v-dialog>
    </div>  
</template>

<script>
import TaskList from '@/views/hotlistTasks/hotlistTaskList.vue'
import schema from "@/schema.js"
import { mapGetters } from "vuex";

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
    },
    components: {
        'TaskList': TaskList
    },
    // * DATA
    data: () => ({
        key: 'task',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,
        // dropdowns
        packageTypes: [],
        billingCycles: [],
        packageStatus: [],
        selectedPackageIcon: {
            name: '',
            icon: ''
        },  
        months: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],

        // rules
        packageCustomerDescriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],
        packageInternalDescriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],

        icons: [
            {
                name: 'On Demand',
                icon: 'oomph-video-playlist'
            },
            {
                name: 'Training',
                icon: 'oomph-literature'
            },
            {
                name: 'Exercises',
                icon: 'oomph-dumbbell'
            },
            {
                name: 'Activities',
                icon: 'oomph-list'
            },
            {
                name: 'Assessment',
                icon: 'oomph-clipboard-list'
            },
            {
                name: 'Wellbeing',
                icon: 'oomph-lotus'
            }
        ],

        // new address object
        item: {},
        loading: false,
        rules: {},

        taskDialog: false, 
        taskDialogKey: 0,
        task: {},
        refreshItems: 0,

    }),
    computed: {  
		...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
		}),
    },
    methods: {
        initialise() {
            this.item = { ...this.datadown };
        },
        updateItem() {
            this.$emit('update', this.item);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        initialiseSchema() {
            var t = this; 
            t.task = {...t.schema}
            t.task.taskOrgId = t.item.siteClientId;
            t.task.taskSiteId = t.item.entityId;
            

        },
        // * CREATE ITEM
        async saveItem() {
            var t = this;

            var dateTimeNow = t.$moment().toISOString();
            console.log('dateTimeNow = ' + JSON.stringify(dateTimeNow, null, 2))

            var item = {...t.task}

            item.createdDateTime = dateTimeNow;
            item.createdUserId = t.GET_FIREBASE_currentUser.entityId;

            console.log('item = ' + JSON.stringify(item, null, 2))
            
            let result = await this.REDIS_create(t.key, item);
            
			if (result.success) {
                console.log('result = ' + JSON.stringify(result, null, 2))
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                t.taskDialog = false
                t.initialiseSchema()
                t.refreshItems++
			}

        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        openSite(item) {
            this.MIX_go('sites/' + item.entityId)
        },
        addNewCommunication() {
            var t = this; 

            t.taskDialog = true 
            t.taskDialogKey++
        },
        showQR() {

            this.MIX_generateCode(true, `https://arcms-comms.web.app/${this.item.entityId}`, 300, "H", "qrcode", "canvas", "#ffffff", "#000000", true, this.item.clientName, '', 'task');
        }
    },
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        taskDialog: {
            handler: function () {
                if (this.taskDialog == false) {
                    this.taskDialogKey++
                }
                this.initialiseSchema()
            }, deep: true
        },

    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.task;
        t.loading = true
        t.initialise();
        t.initialiseSchema()

        t.loading = false
    },
    mounted() {},
};
</script>