<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: auto !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row style="height: 68px !important" class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <!-- <div class="ml-4" >
                    <v-btn text class="primary white--text" @click="showQR()" ><v-icon class="mr-2">icons8-qr-code</v-icon>QR Code to Client Portal</v-btn>
                </div> -->
                <v-spacer />
                <!-- <div class="mr-4" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" >
                    <v-btn text class="primary white--text" @click="addNewUser" ><v-icon class="mr-2">icons8-user-account</v-icon>Add User</v-btn>
                </div> -->
            </v-col>
        </v-row>
        <v-row class="apptext--text pa-5 pt-3 " dense style="z-index: 0 !important">

            <ClientTaskList :client="true" :clientId="clientId" :site="siteId" :refreshitems="refreshItems" v-on:openItem="openItem"/>
            
        </v-row>



        <v-dialog v-model="userDialog" max-width="612" :key="userDialogKey">
            <v-card>
                <v-toolbar color="secondary" elevation="0">
                    <app-text category="text-medium" class="white--text ml-1">Add User</app-text>

                    <v-spacer />
                    
                    <v-btn @click="userDialog = false" icon dark>
                        <v-icon class="icons8-multiply" color="white" />
                    </v-btn>
                </v-toolbar>
                <div class="grey lighten-4">
                    <ClientUserDetails :refreshitems="refreshItems" :datadown="user" :clientId="clientId" v-on:save="saveUser" v-on:update="updateItem"/>
                </div>

            </v-card>
        </v-dialog>
    </div>  
</template>

<script>
import ClientUserDetails from '@/views/clients/clientUserDetails.vue'
import ClientTaskList from '@/views/hotlistTasks/hotlistTaskList.vue'
import schema from "@/schema.js"
import { mapGetters } from "vuex";

export default {
    props: {
        clientId: {
            Type: String,
            Default: '',
        },
        siteId: {
            Type: String,
            Default: '',
        },
    },
    components: {
        'ClientUserDetails': ClientUserDetails,
        'ClientTaskList': ClientTaskList
    },
    // * DATA
    data: () => ({
        key: 'hotlistTask',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,
        // dropdowns
        packageTypes: [],
        billingCycles: [],
        packageStatus: [],
        selectedPackageIcon: {
            name: '',
            icon: ''
        },  
        months: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],

        // rules
        packageCustomerDescriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],
        packageInternalDescriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],

        icons: [
            {
                name: 'On Demand',
                icon: 'oomph-video-playlist'
            },
            {
                name: 'Training',
                icon: 'oomph-literature'
            },
            {
                name: 'Exercises',
                icon: 'oomph-dumbbell'
            },
            {
                name: 'Activities',
                icon: 'oomph-list'
            },
            {
                name: 'Assessment',
                icon: 'oomph-clipboard-list'
            },
            {
                name: 'Wellbeing',
                icon: 'oomph-lotus'
            }
        ],

        // new address object
        item: {},
        loading: false,
        rules: {},

        userDialog: false, 
        userDialogKey: 0,
        user: {},
        refreshItems: 0,

    }),
    computed: {  
		...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
		}),
    },
    methods: {
        openItem(item) {
            console.log('openItem', JSON.stringify(item, null, 2))
            this.user = {...item};
            console.log('this.user', JSON.stringify(this.user, null, 2))
            this.userDialog = true 
            
        },
        initialise() {
            this.item = { ...this.clientId };
        },
        async updateItem(item) {
            var t = this;

            console.log('updateItem', item)

            var user = item

            var dateTimeNow = t.$moment().toISOString();

            user.modifiedDateTime = dateTimeNow;
            user.modifiedUserId = t.GET_FIREBASE_currentUser.entityId;

            let result = await this.REDIS_update('clientUser', user.entityId, user);

            if (result.success) {
                t.userDialog = false;
                t.userDialogKey++
                t.refreshItems++
                t.initialiseSchema()
                
                // t.$emit('updateItem', item);
            }
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        initialiseSchema() {
            var t = this; 
            t.user = {...t.schema}
            t.user.userOrgId = t.clientId;

        },
        async saveUser(item) {
            var t = this; 

            console.log('saveUser', item)
            var user = item

            var dateTimeNow = t.$moment().toISOString();
            user.createdDateTime = dateTimeNow;
            user.createdUserId = t.GET_FIREBASE_currentUser.entityId;

            console.log('user', JSON.stringify(user, null, 2))



            

            let result = await this.REDIS_create('clientUser', user);

            console.log('result', JSON.stringify(result, null, 2))

            if (result.success) {

                // t.$firebase.auth.createUserWithEmailAndPassword(user.userEmail, user.userPin).then(async (userCredential) => {
                //     var userForm = await t.setForm(userCredential.user.uid)
                //     console.log('userForm', JSON.stringify(userForm, null, 2))
                //     // * add to the users collection
                //     await t.MIX_createDocument('clientUsers', userForm);


				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                t.userDialog = false
                t.initialiseSchema()
                t.refreshItems++

            }
        },
        // Cancel Item
        cancelItem() {
            // this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            // this.item = this.schema;
            this.$emit('close');
        },
        openSite(item) {
            this.MIX_go('sites/' + item.entityId)
        },
        addNewUser() {
            var t = this; 

            t.userDialog = true 
            t.userDialogKey++
        },
        showQR() {

            this.MIX_generateCode(true, `https://arcms-comms.web.app/${this.item.entityId}`, 300, "H", "qrcode", "canvas", "#ffffff", "#000000", true, this.item.clientName, '', 'user');
        }
    },
    watch: {
        clientId: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        userDialog: {
            handler: function () {
                if (this.userDialog == false) {
                    this.userDialogKey++
                }
                // this.initialiseSchema()
            }, deep: true
        },

    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.clientUser;
        t.loading = true
        t.initialise();
        t.initialiseSchema()

        t.loading = false
    },
    mounted() {},
};
</script>