<template>
    <v-row fluid fill-height class="d-flex flex-column align-start appbackground" :style="$vuetify.breakpoint.mdAndUp ? 'width: calc(100vw - 56px) !important; position: fixed; height: 100vh !important; overflow-y: scroll !important' : 'width: 100% !important; position: fixed; height: 100vh !important; overflow-y: scroll !important'" no-gutters>
        
        <div v-if="exportingCSV">
            <v-overlay color="white" opacity="0.2">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-overlay>
        </div>
    <!-- <v-container fluid fill-height class="d-flex flex-column align-start appbackground pa-0 ma-0"> -->
		<v-col v-if="!userOpen" class="align-center pa-3 mt-1 mb-12 pb-12" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">
            
			<div class="d-flex align-center mb-1">
				<div class="d-flex align-center">
					<div><v-icon class="primary--text" x-large>icons8-business-building</v-icon></div>
					<div class="text-left primary--text pl-1 text-h4 font-weight-thin">Clients</div>
				</div>
				<v-spacer></v-spacer>
				<div class="ml-1">
                    <v-btn depressed block class="primary darken-2 white--text" @click="openItem({})" >
                        <v-icon>icons8-business-building</v-icon>
                        <span v-if="!listShrunk" class="ml-3">New</span>
                    </v-btn>
                </div>
				<div v-if="$vuetify.breakpoint.lgAndUp">
					<v-btn @click="exportCSV" class="primary--text ml-2" depressed v-if="!listShrunk">
						<v-icon color="primary">icons8-export-csv</v-icon>
					</v-btn>
				</div>
			</div>
            
            <ClientsList :listshrunk="listShrunk" :listexpanded="listExpanded" v-on:openItem="openItem" :refreshitems="refreshItems"/>
            
		
        </v-col>
		<!-- Service Component -->
		<transition v-if="activeItem" name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
			<v-col style="border-left: #239eaf solid 1px !important; height: 100% !important" v-if="!listExpanded" class="grey lighten-3 pa-0 ma-0" cols="12" :xs="itemCols.xs" :sm="itemCols.sm" :md="itemCols.md" :lg="itemCols.lg" :xl="itemCols.xl">
                <Client v-on:refreshItems="refreshItems" :listshrunk="listShrunk"  :datadown="activeItem" v-on:closeitem="closeItem" :orgtype="orgtype"/>
			</v-col>
		</transition>

    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import Client from '@/views/clients/client.vue';
import ClientsList from '@/views/clients/clientList.vue'
export default {
    name: 'Clients',
    components: {
        'Client': Client,
        'ClientsList': ClientsList
    },
    data: () => ({
        editMode: false,
        orgtype: "",
        orgIcon: "",
        search: '',
        show: false,
        tab: '',
        orgs: [],
        orgDialog: false,
        refreshItems: 0,
        activeItem: {},
        extendWidth: '',
        routeId: '',
		// Item Columns
		itemsCols: { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12,},
		itemCols: { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 },
		taskCols: {	cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2,},
		listExpanded: true,
		listShrunk: false,
        userOpen: false,
        exportingCSV: false,
    }),
    computed: {
        ...mapGetters({
            // GET_FIREBASE_userAuth: 'GET_FIREBASE_userAuth',
        }),
    },
    methods: {
		refreshCols() {
			switch (true) {
				// Expanded List with No Task Bar
				case this.listExpanded && !this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
					this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// Expanded List with Task Bar
				case this.listExpanded && !this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
					this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				// List with Product Page (No Task Bar)
				case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// List with Product Page and Task Bar
				case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 4
					this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				// Shrunk List with Product Page (No Task Bar)
				case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }; // 2
					this.itemCols = { cols: 12, xs: 9, sm: 9, md: 9, lg: 9, xl: 9 }; // 10
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// Shrunk List with Product Page and Task Bar)
				case !this.listExpanded && this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				default:
					this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 12
					this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
			}
		},
		openItem(item) {
			this.activeItem = item;
			this.listExpanded = false;
            if (this.$vuetify.breakpoint.smAndDown) {
                this.userOpen = true;
            }
		},
		closeItem() {
			this.listExpanded = true;
			this.listShrunk = false;
            if (this.$vuetify.breakpoint.smAndDown) {
                this.userOpen = false
            }
		},
        async exportCSV() {
            var t = this;
            t.exportingCSV = true
            
            let itemsResult = await this.REDIS_searchFor('client', 0, 999, 'clientName','ASC', "@deleted:{false}" )
            if (itemsResult.success) {
                
                // go through each item and if archived, find the archived date and add it to the item
                for (let i = 0; i < itemsResult.data.documents.length; i++) {
                    let item = itemsResult.data.documents[i];
                    if (item.clientStatus === 'ARCHIVE') {
                        var query = `@type:{'Archive Client'} @linkedId:{${item.entityId}} @status:{'COMPLETED'}`;
                        let archivedItem = await this.REDIS_searchFor('progress', 0, 999, 'createdDateTime', 'DESC', query);
                        if (archivedItem.success && archivedItem.data.total > 0) {
                            // console.log('archivedItem = ' + JSON.stringify(archivedItem, null, 2))
                            item.archivedDateTime = archivedItem.data.documents[0].createdDateTime;
                        }
                    }
                }

                var data = itemsResult.data.documents.map(e => {
                    return {
                        'Client Name' : e.clientName,
                        'Client Telephone' : e.clientTelephone,
                        'Client Email' : e.clientEmail,
                        'Client Contact Name' : e.clientContactName,
                        'Client Contact Email' : e.clientContactEmail,
                        'Client Contact Mobile' : e.clientContactMobile,
                        'Client Contact Position' : e.clientContactPosition,
                        'Client Contact Telephone' : e.clientContactTelephone,
                        'Client Address Line 1' : e.clientAddressLine1,
                        'Client Address Line 2' : e.clientAddressLine2,
                        'Client Address Line 3' : e.clientAddressLine3,
                        'Client Address County' : e.clientAddressCounty,

                        'Client Address Postcode' : e.clientAddressPostcode,
                        'Client Address Town' : e.clientAddressTown,
                        'Client Emergency Contact Name' : e.clientEmergencyContactName,
                        'Client Emergency Contact Email' : e.clientEmergencyContactEmail,
                        'Client Emergency Contact Mobile' : e.clientEmergencyContactMobile,
                        'Client Emergency Contact Position' : e.clientEmergencyContactPosition,
                        'Client Emergency Contact Telephone' : e.clientEmergencyContactTelephone,
                        'Client Notes' : e.clientNotes,
                        'Client Manager' : e.clientManagerUserId,
                        'Client Start Date' : e.clientStartDateTime ? t.$moment(e.clientStartDateTime).format('DD-MMM-YYYY') : '',
                        'Client End Date' : e.clientEndDateTime ? t.$moment(e.clientEndDateTime).format('DD-MMM-YYYY') : '',
                        'Client Type' : e.clientType,
                        'Client Status' : e.clientStatus,
                        'Archived Date' : e.archivedDateTime ? t.$moment(e.archivedDateTime).format('DD-MMM-YYYY') : '',
                        'Client Contracted Hours' : e.clientContractedHours,
                    }
                })

                console.log('data  = ' + JSON.stringify(data, null, 2))

                let csv = t.$papa.unparse(data)
                t.$papa.download(csv, 'Clients Report')
                t.exportingCSV = false
            }
        }
    },
	watch: {
		listExpanded() { this.refreshCols() },
		listShrunk() { this.refreshCols() },
		// GET_taskBar() { this.refreshCols() }
	},
    mounted() {
		// Dynamic Route for New or Existing Item
		this.routeId = this.$route.params.id;
		if (this.routeId === "new") {
			this.openItem({})
		} 
        else if (this.routeId !== "new" && this.routeId !== "" && this.routeId !== undefined && this.routeId !== ':id') {
			this.openItem({ entityId: this.routeId })
		} 
    }
};
</script>