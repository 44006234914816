<template>
	<v-row class="d-flex align-center justify-center pa-4">
		<v-col cols="8">
			<v-card class="pa-5 grey lighten-3 d-flex align-center text--secondary elevation-0">
                <div>
                    <v-icon size="100" color="red">mdi-alert-circle-outline</v-icon>
                </div>
                <div class="px-4">
                    <strong>NOT AUTHORISED</strong><br />
                    <i v-if="GET_FIREBASE_currentUser !== null && GET_FIREBASE_currentUser.userStatus == 'PENDING'">Your account is currently pending approval.</i>
                    <i v-else>You do not have the necessary authorisation to access our app at this time.</i><br />
                    <i>If you believe there has been an error, please contact the project admins.</i>
                </div>
			</v-card>
		</v-col>
	</v-row>
</template>


<script>
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),        
    },
}
</script>
