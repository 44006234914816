<template>
    <div 
    class="" 
    style="
        padding: 0px !important; 
        margin: 0px !important; 
        width: 100% !important; 
        height: 100% !important; 
        overflow: scroll !important'">
        <v-row class="apptext--text" dense no-gutters style="z-index: 0 !important">
            <v-col class="pa-0 ma-0" cols="12" xs="12" sm="12" md="12">
                <v-row class="d-flex align-center primary darken-2 pa-2" no-gutters>
                    <!-- //TODO Change To Translated Title -->
                    <app-text class="white--text pl-2" category="popup-title">{{ item.userName }}</app-text>
                    <v-spacer />
                    <v-btn icon class="white--text" @click="closePanel"><v-icon>icons8-multiply</v-icon></v-btn>
                </v-row>
            </v-col>
			<v-tabs class="border-bottom-grey" v-model="tabs" height="53" show-arrows>
				<v-tabs-slider color="primary" background-color="black"></v-tabs-slider>
				<v-tab href="#overview" class="white"><v-icon class="">icons8-form</v-icon> <span class="caption text-uppercase">Overview</span></v-tab>
				<v-tab href="#availability" class="white"><v-icon class="">icons8-address</v-icon> <span class="caption">Availability</span></v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabs" fill-height class="appbackground pa-0 ma-0">
                <v-tab-item value="availability" class="pa-0 ma-0">
                    <job-staff-availability :refreshItems="refreshItems" :job="job" :staff="item" v-on:update="updateItem" v-on:save="saveItem" />
                </v-tab-item>
                <v-tab-item value="overview" class="pa-0 ma-0">
                    <staff-details :datadown="item" />
                </v-tab-item>
            </v-tabs-items>
        </v-row>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
// import schema from '@/schema.js';
import jobStaffAvailability from "@/views/jobs/jobStaffAvailability.vue"
import staffDetails from "@/views/staffDirectory/staffDetails.vue"

export default {

	components: {
		"job-staff-availability": jobStaffAvailability,
		"staff-details": staffDetails,
	},
    props: {
        job: {
            Type: String,
            Default: '',
        },
        staff: {
            Type: String,
            Default: '',
        },
        refreshItems: {
            type: Number,
            default: 0,
        }
    },
    // * DATA
    data: () => ({
        key: 'job',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,

        // new job object
        item: {},
        jobItem: {},
        loading: false,
        rules: {},
        tabs: 'availability',


    }),
    computed: { 
 
    },
    methods: {
        initialise() {
            this.jobItem = { ...this.job };
            this.item = { ...this.staff };
            
            if (this.item.packageName !== '') {
                this.itemNew = false;
                this.editmode = false;
            } else {
                this.itemNew = true;
                this.editmode = true;
            }
        },
        updateItem(item) {
            console.log('updating item staff details = ' + JSON.stringify(item, null, 2))
            this.$emit('update', item);
        },
        closePanel() {
            this.$emit('closePanel')
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem(item) {
            this.$emit('save', item);
            this.editmode = false;
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
    },
    watch: {
        job: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        staff: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        

    },
    // * CREATED
    async created() {
        let t = this;
        // t.schema = schema.package;
        // t.loading = true
        t.initialise();


        // let packageCategories = await t.MIX_redisReadSet('set:packageCategory', false);
        // if (packageCategories.code === 1) {
        //     t.packageCategories = packageCategories.data;
        // }
        // let packageStatus = await t.MIX_redisReadSet('set:packageStatus', false);
        // if (packageStatus.code === 1) {
        //     t.packageStatus = packageStatus.data;
        // }
        // let billingCycles = await t.MIX_redisReadSet('set:billingCycles', false);
        // if (billingCycles.code === 1) {
        //     t.billingCycles = billingCycles.data;
        // }
        // t.loading = false
    },
    mounted() {},
};
</script>

<style scoped>

.v-expansion-panel-content__wrap {
    padding: 0 0px 0px !important;
}
.v-expansion-panel::before {
    box-shadow: none;
}
.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
    padding: 0 0px 0px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    flex: 1 1 auto;
    max-width: 100%;
}
</style>