<template>
    <div>
        <!-- NO ICON -->
        <v-btn v-if="icon === ''" depressed 
            :color="type" :class="[{ 'na': na }, buttonclass, labelclass]"
            class=""  dark
            :small="smallButton" :large="largeButton">
                <span>{{ label }}</span>
        </v-btn>
        <!-- WITH ICON -->
        <v-btn v-if="icon !== ''"  depressed
            :color="type" :class="[{ 'na': na }, buttonclass, labelclass]"
            class="mr-2" dark
            :small="smallButton" :large="largeButton">
                <v-icon :class="iconclass" class="mr-1">{{ icon }}</v-icon>
                <div v-if="showlabel !== ''">
                    <span :class="labelclass" v-if="$vuetify.breakpoint[showlabel]">
                        {{ label }}
                    </span>
                </div>
                <div v-if="showlabel === ''">
                    <span :class="labelclass">
                        {{ label }}
                    </span>
                </div>
        </v-btn>
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            required: false,
            default: '',
        },
        type: {
            type: String,
            required: false,
            default: 'grey',
        },
        showlabel: {
            type: String,
            required: false,
            default: '',
        },
        label: {
            type: String,
            required: false,
            default: '',
        },
        buttonclass: {
            type: String,
            required: false,
            default: ''
        },
        iconclass: {
            type: String,
            required: false,
            default: ''
        },        
        labelclass: {
            type: String,
            required: false,
            default: ''
        },
        sizeoverride: {
            type: String,
            required: false,
            default: ''
        },
        na: {
            type: Boolean,
            default: false
        }   
                   
    },
    computed: {
        smallButton() {
            switch (this.sizeoverride) {
                case 'small':
                    return true;
                    break;
                case 'medium':
                    return false;
                    break;
                case 'large':
                    return false;
                    break;
                default:
                    if (this.$vuetify.breakpoint.mdAndDown) {
                        return true;
                    } else {
                        return false;
                    }
                    break;
            }
        },
        largeButton() {
            switch (this.sizeoverride) {
                case 'small':
                    return false;
                    break;
                case 'medium':
                    return false;
                    break;
                case 'large':
                    return true;
                    break;
                default:
                    if (this.$vuetify.breakpoint.xlAndUp) {
                        return true;
                    } else {
                        return false;
                    }
                    break;
            }
        }

    }

            // :small="$vuetify.breakpoint.mdAndDown"
            // :large="$vuetify.breakpoint.xlAndUp"

}
</script>

<style>
.na {
    pointer-events:none;
    cursor: not-allowed;
    opacity: 50% !important;
}   
</style>