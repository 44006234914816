import { render, staticRenderFns } from "./staffDirectory.vue?vue&type=template&id=fa7d4992&"
import script from "./staffDirectory.vue?vue&type=script&lang=js&"
export * from "./staffDirectory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports