<template>
    <v-dialog v-model="qrcodeOptions.showPopup" fullscreen style="position: relative; z-index: 9999999">
        <v-card color="grey lighten-2">


			<v-card-actions class="text-right noprint">
				<v-spacer/>
				<v-btn color="grey lighten-1 elevation-0" @click="closeQrCode()">Close</v-btn>
				<v-btn color="black white--text elevation-0" @click="exportCode()">Print</v-btn>
			</v-card-actions>


            <!--Title-->
			<div v-if="qrcodeOptions.type == 'location'" class="grey darken-3 white--text noprint">
				Location {{ qrcodeOptions.locationName }} QR CODE
			</div>

            <!-- <pre>{{ qrcodeOptions }}</pre> -->

			<!--
			Printable QR Code
			  Renders a printable poster containing company information and the QR code
			-->
			<div class="d-flex flex-column align-center">
                <v-card height=100% style="width: 100% !important" color="secondary" rounded=0 class="elevation-0 align-center">
				    <v-img :src="require('@/assets/A&R_Services_FINAL_LOGO.svg')" contain width="100%" max-height="128" class="my-9"/>
                </v-card>

				<!--Instruction-->
				<p v-if="qrcodeOptions.type == 'location'" class="text-h4 primary white--text text-center font-weight-medium py-8" style="width: 100%">SWAPP In/Out</p>
				<p v-if="qrcodeOptions.type == 'communication'" class="text-h4 primary white--text text-center font-weight-medium py-8" style="width: 100%">Communication Book</p>

				<p v-if="qrcodeOptions.type == 'communication'" class="text-h6 mt-4 font-weight-bold text-center grey--text">Please scan the QR Code below to access the A&R Services Communication Book</p>
				<!--QR Code-->
				<v-card-text class="text-center" :class="qrcodeOptions.type == 'communication' ? 'mt-2' : 'mt-12'" id="downloadCode">
					<qrcode-vue :value="qrcodeOptions.value"
								:size="qrcodeOptions.size"
								:level="qrcodeOptions.level"
								:id="qrcodeOptions.id"
								:renderAs="qrcodeOptions.renderAs"
								:background="qrcodeOptions.background"
								:foreground="qrcodeOptions.foreground">
					</qrcode-vue>
				</v-card-text>

				<!--Site name-->
				<p class="text-h4 font-weight-bold mt-4">{{ qrcodeOptions.locationName }}</p>

				<!--Site telephone number-->
				<!-- <p v-if="qrcodeOptions.siteTelephone !== '' && qrcodeOptions.siteTelephone !== null && qrcodeOptions.siteTelephone" class="text-h5 mt-4">{{ qrcodeOptions.siteTelephone }}</p> -->

				<!--Portal domain-->
				<p v-if="qrcodeOptions.type == 'location'" class="text-h6 mt-4">To SWAPP In / Out download the App from</p>
				<p v-if="qrcodeOptions.type == 'location'" class="text-h6 font-weight-bold primary--text">arcms.swapp.work</p>

				<div style="height: 1px; background: lightgrey; width: 100%" class="my-8 mt-4"/>
				<!--Vindico branding-->
				<v-img :src="require('@/assets/vindico.png')" contain max-height="44"/>
				<p class="mt-1 grey--text">Created by Vindico</p>
			</div>

        </v-card>
    </v-dialog>
</template>


<script>
import QrcodeVue from "qrcode.vue";
import * as htmlToImage from "html-to-image";
import { saveAs } from "file-saver";
import { mapState } from "vuex";

export default {
    name: 'QrCodeGenerator',
    components: {
        QrcodeVue
    },
    computed: {
        ...mapState('qr_code_generator_store/', {
            qrcodeOptions: state => state.qrcode_generator,
        })
    },
    methods: {
        closeQrCode() {
            this.MIX_generateCode(false, '', 300, "H", "qrcode", "canvas", "#ffffff", "#000000", false, '', '', ''); 
        },
        exportCode() {
            console.log('exporting')
            window.print();
        },
        downloadWithVueResource() {
            htmlToImage.toBlob(document.getElementById("downloadCode")).then(function(blob) {
                window.saveAs(blob, "QRcode.png");
            });
        },
    }
}
</script>

<style>
@media print {
  .noprint {
    display: none !important;
    clear: both !important;
  }
}
</style>