import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css"; //material icons

Vue.use(Vuetify);

const opts = {
    options: {
      customProperties: true

    },
    theme: {
      themes: {
        light: {
          primary: '#308CCB',
          secondary: '#65348a',
          accent: '#545da8',
        //   info: '#E0E0E0',
          lightgrey: '#E0E0E0',
          notificationsBackground: '#EEEEEE',
          darkgrey: '#4f4f4f', //'#4f4f4f',
          appbackground: '#E5E5E5', //'#F2F2F2',
          success: '#00942A',
          warning: '#E04B0D',
          error: '#E10203',
          info: '#4492DA',
          vindicoPurple: '#582468'
        },
      },
    },
    breakpoint: {
        thresholds: {
            xs: 340,
            sm: 600, // 540,
            md: 800,
            lg: 1501,  // sets Ipad / surface book
        },
        scrollBarWidth: 24,
    },
  };

export default new Vuetify(opts);
