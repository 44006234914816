<template>
    <div class="" style="width: 100% !important">
        <v-card v-if="$vuetify.breakpoint.mdAndUp" class="pa-1" :color="getCardColour(day)" fixed flat>
            <v-col cols="12">
                <v-row class="justify-center">
                    <v-col cols="12" class="d-flex flex-row px-4">
                        <v-card-title :class="$moment(day).format('DD-MMM-YYYY') === $moment().format('DD-MMM-YYYY') ? 'white--text' : 'grey--text text--darken-2'" class="text-center font-weight-bold white--text justify-center body-1 pa-0 ma-0">{{ $moment(day).format('ddd DD-MMM') }}</v-card-title>
                        <v-spacer />
                        <!-- <div class="font-weight-bold d-flex align-center white--text"> -->
                            <!-- <v-icon class="secondary--text">icons8-filled-circle</v-icon> -->
                            <div :class="$moment(day).format('DD-MMM-YYYY') === $moment().format('DD-MMM-YYYY') ? 'white--text' : 'grey--text text--darken-2'" class="font-weight-bold d-flex align-center">
                                <!-- <v-chip class="secondary white--text">{{ itemsTotal }}</v-chip> -->
                            {{ computedStats.total }}
                            </div>
                        <!-- </div> -->
                    </v-col>
                </v-row>
                <v-row class="pb-2 justify-space-between px-4 align-start" >
                    <div class="font-weight-bold d-flex align-center white--text">
                        <v-chip class="grey">{{ computedStats.unallocated }}</v-chip>
                    </div>
                    <div class="font-weight-bold d-flex align-center white--text">
                        <v-chip class="success">{{ computedStats.allocated }}</v-chip>
                    </div>
                    <div class="font-weight-bold d-flex align-center white--text">
                        <v-chip class="error">{{ computedStats.cancelled }}</v-chip>
                    </div>
                </v-row>
            </v-col>
        </v-card>
        <v-col class="pa-0 ma-0 mt-2">
            <div 
                :style="$vuetify.breakpoint.mdAndUp ? 'height: 550px !important' : 'height: 350px !important'" 
                style="overflow-y: scroll !important; overflow-x: hidden !important" 
                class="">

                <div v-if="itemsFrom > 0">
                     
                    <v-btn block @click="goBack" class="elevation-0 darkgrey lighten-3 white--text  mb-2 font-weight-bold">
                    + GO BACK 
                    </v-btn>
                </div>
                <!-- <pre>computedItems: {{ computedItems }}</pre> -->
                <div v-for="job in computedItems" :key="job.id">
                    <job-card class="mb-2" style="" :job="job" :jobsPage=true></job-card>
                </div>
                <v-row v-if="loading === true" class="align-center pt-5 justify-center">
                    <v-progress-circular :size="60" :width="7" indeterminate color="primary" ></v-progress-circular>
                </v-row>
                <div v-else-if="computedItems.length == 0" class="font-weight-bold grey--text text--darken-1 body-1">
                    NO JOBS
                </div>
                <div v-else-if="computedItems.length !== itemsTotal && computedItems.length == datatableOptions.itemsPerPage">
                     
                    <v-btn block @click="loadMore" class="elevation-0 darkgrey lighten-3 white--text font-weight-bold">
                    + SHOW MORE 
                    </v-btn>
                </div>
                <div v-else-if="computedItems.length <= itemsTotal && computedItems.length > 0" class="font-weight-bold grey--text text--darken-1 body-1">
                    SHOWING ALL JOBS
                </div>
                
            </div>
        </v-col>
    </div>
</template>

<script>
import moment from 'moment';
import JobCard from '@/views/jobs/jobCard.vue';
export default {
    components: {
        'job-card': JobCard,
    },
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        day: {
            type: String, 
            Default: '',
        },
        searchQuery: {
            type: String,
            default: '',
        },
        clients: {
            type: Array,
            default: () => [],
        },
        sites: {
            type: Array,
            default: () => [],
        },
        services: {
            type: Array,
            default: () => [],
        },
        users: {
            type: Array,
            default: () => [],
        },
        startLoading: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        type: '',
        status: '',
        key: 'jobs',
        index: 'idx:jobs',
        job: {},
        items: [],
        itemsTotal: null,
        itemsFrom: 0,
        itemsTo: 9,
        loading: false,
        // activeItem: {},
        // Toggles
        toggleFeatures: [],

        // Headers to display in the Data Table
        headers: [
            { text: 'Assigned User', value: 'jobAssignedUserId', sortable: false, hidden: false, expanded: false },
            { text: 'Assigned Status', value: 'jobAssignedStatus', sortable: false, hidden: false, expanded: false },
            { text: 'Client', value: 'jobClientId', sortable: false, hidden: false, expanded: false },
            { text: 'Start Date Time', value: 'jobStartDateTime', sortable: false, hidden: false, expanded: false },
            { text: 'End Date Time', value: 'jobEndDateTime', sortable: false, hidden: false, expanded: false },
            { text: 'Job Type', value: 'jobType', sortable: false, hidden: false, expanded: false },
            { text: 'Published Status', value: 'jobPublishedStatus', sortable: false, hidden: false, expanded: false },
            { text: 'Recurring Rule ID', value: 'jobRecurringRuleId', sortable: false, hidden: false, expanded: false },
            { text: 'Service', value: 'jobServiceId', sortable: false, hidden: false, expanded: false },
            { text: 'Site', value: 'jobSiteId', sortable: false, hidden: false, expanded: false },


            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false },
        ],
        // Fields to Retrieve from Database
        // fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        sortBy: '@courseName',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['courseName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        computedStats: {
            total: 0,
            cancelled: 0,
            allocated: 0,
            unallocated: 0
        },


        filterAllocated: false,
        filterUnallocated: false,
        filterCancelled: false, 
        currentWeekStartDate: moment().startOf('week').add(1, 'days').format('DD-MMM-YYYY'),

    }),
    computed: {
        computedSearchQuery() {
            var t = this;
            let searchQuery = this.searchQuery;

            var day = t.day

            var startOfDay = t.$moment(day, 'DD-MMM-YYYY').startOf('day').format('X')
            var endOfDay = t.$moment(day, 'DD-MMM-YYYY').endOf('day').format('X')


            searchQuery = `${searchQuery} @jobStartDateTime:[${startOfDay} ${endOfDay}]`


            return searchQuery;
        },
        computedItems() {
            var items = this.items;
            // //console.log('items = ' + JSON.stringify(items, null, 2))

            items.forEach((item) => {
                item.jobClientName = this.getClient(item.jobClientId)
                item.jobServiceName = this.getService(item.jobServiceId)
                item.jobSiteName = this.getSite(item.jobSiteId)
                item.jobAssignedUserName = this.getUser(item.jobAssignedUserId)
            })
            
            // //console.log('items = ' + JSON.stringify(items, null, 2))
            // if (this.type) {
            //     items = this.items.filter((item) => item.actionType === this.type);
            // }
            // if (this.status) {
            //     items = this.items.filter((item) => item.actionStatus === this.status);
            // }
            
            return items;
        },
        computedType() {
            const types = [];
            this.items.filter((item) => {
                return types.push(item.actionType);
            });
            return types;
        },
        computedStatus() {
            const status = [];
            this.items.filter((item) => {
                return status.push(item.actionStatus);
            });
            return status;
        },
    },
    methods: {
        computedPublished(status) {
            var t = this
            var items = t.computedItems

            items = items.filter(function(item) {
                return item.jobPublishedStatus == status 
            })

            return items.length
        },
        computedAllocated(status) {
            var t = this
            var items = t.computedItems

            items = items.filter(function(item) {
                return item.jobAssignedStatus == status && item.jobPublishedStatus !== 'CANCELLED'
                
            })

            return items.length
        },
        async editItem(id) {
            // Get the item from the database
            let itemResult = await this.MIX_readItem(id, 'service');
            if (itemResult.code === 1) {
                this.$emit('edit', itemResult.data);
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Editing Component', show: true });
            }
        },
        getClient(clientId) {
            var t = this;
            var client = []
            var clientName = ''

            client = t.clients.filter((item) => { return item.entityId == clientId })

            if (client.length !== 0) { clientName = client[0].clientName }
            return clientName
        },
        getUser(userId) {
            var t = this;
            var user = []
            var userName = ''

            user = t.users.filter((item) => { return item.entityId == userId })

            if (user.length !== 0) { userName = user[0].userName }

            return userName
        },
        getService(serviceId) {
            var t = this;
            var service = []
            var serviceName = ''

            service = t.services.filter((item) => { return item.entityId == serviceId })

            if (service.length !== 0) { serviceName = service[0].serviceName }

            return serviceName
        },
        getSite(siteId) {
            var t = this;
            var site = []
            var siteName = ''

            site = t.sites.filter((item) => { return item.entityId == siteId })

            if (site.length !== 0) { siteName = site[0].siteName }

            return siteName
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        refreshItems: function () {
            this.getItems();
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        // * OPEN JOB ITEM
        openJob(item) {
            this.$emit('openJob', JSON.parse(JSON.stringify(item)));
        },
        openRecurringJob(item) {
            this.$emit('openRecurringJob', JSON.parse((JSON.stringify(item))))
        },
        async loadMore() {
            this.itemsFrom = this.itemsFrom + this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo + this.datatableOptions.itemsPerPage;
            this.$emit('notReady')
            this.getItems();
        },
        async goBack() {
            this.itemsFrom = this.itemsFrom - this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo - this.datatableOptions.itemsPerPage;
            this.$emit('notReady')
            this.getItems()
        },
        async getItems() {
            var t = this;
            t.loading = true
            t.items = []

            // console.log('searchQuery = ' + t.computedSearchQuery)

            let itemsResult = await this.REDIS_searchFor('jobs', this.itemsFrom, this.datatableOptions.itemsPerPage, 'jobStartDateTime','ASC', this.computedSearchQuery )
            // //console.log('itemsResult: ' + JSON.stringify(itemsResult));
            if (itemsResult.success) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.documents;
                t.loading = false
                t.$emit('ready')
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getStats()
            }
        },

        async getStats() {
            var t = this;

            var day = t.day
            var startOfDay = t.$moment(day, 'DD-MMM-YYYY').startOf('day').format('X')
            var endOfDay = t.$moment(day, 'DD-MMM-YYYY').endOf('day').format('X')


            let total = await this.REDIS_count(t.key, `@deleted:{false} @jobStartDateTime:[${startOfDay} ${endOfDay}] -@jobServiceId:{'cEjeAFJSMAdEfI6Gt3dJ'}`)
            if (total.success) {
                t.computedStats.total = total.total
            }

            let cancelled = await this.REDIS_count(t.key, `@deleted:{false} @jobStartDateTime:[${startOfDay} ${endOfDay}] @jobPublishedStatus:{'CANCELLED'} -@jobServiceId:{'cEjeAFJSMAdEfI6Gt3dJ'}`)
            if (cancelled.success) {
                t.computedStats.cancelled = cancelled.total
            }

            let allocated = await this.REDIS_count(t.key, `@deleted:{false} @jobStartDateTime:[${startOfDay} ${endOfDay}] @jobAssignedStatus:{'ALLOCATED'} @jobPublishedStatus:{'UNPUBLISHED' | 'PUBLISHED'} -@jobServiceId:{'cEjeAFJSMAdEfI6Gt3dJ'}`)
            if (allocated.success) {
                t.computedStats.allocated = allocated.total
            }

            let unallocated = await this.REDIS_count(t.key, `@deleted:{false} @jobStartDateTime:[${startOfDay} ${endOfDay}] @jobAssignedStatus:{'UNALLOCATED'} @jobPublishedStatus:{'UNPUBLISHED' | 'PUBLISHED'} -@jobServiceId:{'cEjeAFJSMAdEfI6Gt3dJ'}`)
            if (unallocated.success) {
                t.computedStats.unallocated = unallocated.total
            }
        },
        getCardColour(day) {
            if (moment(day).format('DD-MMM-YYYY') === moment().format('DD-MMM-YYYY')) {
                return 'primary'
            } else {
                return 'grey lighten-1'
            }
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.service = { ...this.datadown };
            },
            deep: true,
        },
        // day() {
        //     this.getItems()
        //     //console.log('triggering get items day')
        // },
        startLoading() {
            if (this.startLoading == true) {
                this.getItems()
            } 
        },
        refreshitems() {
            this.getItems();
            //console.log('triggering get items refreshitems')
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
            //console.log('triggering get items computedSearchQuery')
        },
        searchQuery() {
            this.getItems()
            //console.log('triggering get items searchQuery')
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        var t = this
        this.loading = true
        this.job = { ...this.datadown };

        // let users = await t.REDIS_searchFor('user', '0', '999', 'userStartDate', 'ASC', '@deleted:{false}');
        // if (users.success) { t.users = users.data.documents; }

        // t.users = t.users.map((x) => {
        //     return {
        //         entityId: x.entityId,
        //         userName: x.userName,
        //     };
        // })

        // let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false} -@clientStatus:{ARCHIVE}');
        // if (clients.success) { t.clients = clients.data.documents; }

        // t.clients = t.clients.map((x) => {
        //     return {
        //         entityId: x.entityId,
        //         clientName: x.clientName,
        //     };
        // })

        
        // let services = await t.REDIS_searchFor('service', '0', '9999', 'serviceName', 'ASC', '@deleted:{false}');
        // if (services.success) { t.services = services.data.documents; }

        // t.services = t.services.map((x) => {
        //     return {
        //         entityId: x.entityId,
        //         serviceName: x.serviceName,
        //     };
        // })

        // let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
        // if (sites.success) { t.sites = sites.data.documents; }

        // t.sites = t.sites.map((x) => {
        //     return {
        //         entityId: x.entityId,
        //         siteName: x.siteName,
        //     };
        // })
        if (this.$vuetify.breakpoint.mdAndDown) {
            this.getItems();
        }
        //console.log('triggering get items created')
    },
};
</script>

<style>

#customDisabled.v-btn--disabled {
    background-color: #308CCB !important;
    opacity: 0.7 !important;
}
</style>