<template>
    <v-menu offset-y left>
        <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
                <v-icon color="lighttext">icons8-menu</v-icon>
            </v-btn>
        </template>
        <v-list nav dense pa-0 pr-2 ma-0>
            
            <v-list-item class="ma-0 pa-0" @click="MIX_go('/home')">
                <v-btn text color="primary" width=100% height="40" class="justify-start">
                    <v-icon color="darkgrey">icons8-home</v-icon>
	                <v-list-item-title class="darkgrey--text">Home</v-list-item-title>
                </v-btn>
            </v-list-item>
            <v-list-item class="ma-0 pa-0">
                <v-btn text disabled color="grey" width=100% height="40" class="justify-start"> 
                    <v-icon color="grey">icons8-male-user</v-icon>
	                <v-list-item-title class="grey--text">Profile</v-list-item-title>
                </v-btn>
            </v-list-item>
            <v-list-item class="ma-0 pa-0">
                <v-btn text disabled color="grey" width=100% height="40" class="justify-start">
                    <v-icon color="darkgrey">icons8-online-support</v-icon>
	                <v-list-item-title class="grey--text">Support</v-list-item-title>
                </v-btn>
            </v-list-item>
            <v-list-item class="ma-0 pa-0"  v-if="showInstallBanner" @click="install()">
                <v-btn text color="primary" width=100% height="40" class="justify-start">
                    <v-icon color="darkgrey">icons8-download</v-icon>
	                <v-list-item-title class="darkgrey--text">Install App</v-list-item-title>
                </v-btn>
            </v-list-item>
            <v-list-item class="ma-0 pa-0"  v-if="$store.getters.GET_currentUser != null" @click="logout()">
                <v-btn text color="primary" width=100% height="40" class="justify-start">
                    <v-icon color="darkgrey">icons8-exit</v-icon>
	                <v-list-item-title class="darkgrey--text">Log Out</v-list-item-title>
                </v-btn>
            </v-list-item>
            <!-- <v-list-item class="ma-0 pa-0"  @click="privacyDialog = true">
                <v-btn text color="primary" width=100% height="40" class="justify-start">
                    <v-list-item-title class="darkgrey--text">Terms and Conditions</v-list-item-title>
                </v-btn>
            </v-list-item> -->
            <v-list-item>
                <v-list-item-title class="grey--text pl-2">App Version {{ LATEST_VERSION }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    props: {
        showInstallBanner: {
            type: Boolean,
            default: false,
        },
        LATEST_VERSION: {
            type: String,
            default: ''
        }
    },
	data() {
		return {
        }
    },
    computed: {

    },
    methods: {
        logout() {
            this.$emit('logout')
        },
        install() {
            this.$emit('install')
        }
    }

}
</script>