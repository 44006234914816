<template>
    <div class="" style="width: 100% !important">
        <div style="width: 100% !important" class="d-flex flex-row align-center">
            <div style="width: 100% !important" class="d-flex flex-column pr-2">
                <div class="d-flex align-start">
                    <div class="flex-grow-1">
                        <v-select class="" hide-details="auto" label="Service" filled v-model="filter.service" outlined dense clearable :items="services" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" item-text="serviceName" item-value="entityId" ></v-select>
                    </div>
                    <div class="flex-grow-1 ml-2">
                        <v-select class="" hide-details="auto" label="Clients" filled item-value="entityId" v-model="filter.client" outlined dense clearable :items="clients" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" item-text="clientName" ></v-select>
                    </div>
                    <div class="flex-grow-1 ml-2">
                        <v-select class="" hide-details="auto" label="Sites" filled v-model="filter.site" outlined dense clearable :items="sites" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" item-text="siteName" item-value="entityId" ></v-select>
                    </div>
                    <div class="flex-grow-1 ml-2">
                        <v-select class="" hide-details="auto" label="User Category" filled v-model="filter.userCategory" outlined dense clearable :items="userCategories" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" ></v-select>
                    </div>
                </div>
                <div class="pt-2 d-flex align-start">
                    <div class="flex-grow-1">
                        <v-select class="" hide-details="auto" label="Staff Member" filled v-model="filter.staffMember" outlined dense clearable item-value="entityId" :items="computedUsers" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" item-text="userName" ></v-select>
                    </div>
                    <div class="flex-grow-1 ml-2">
                        <v-select class="" hide-details="auto" label="Managers" filled v-model="filter.manager" outlined dense clearable item-value="entityId" :items="computedManagers" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" item-text="userName" ></v-select>
                    </div>
                    <div class="flex-grow-1 ml-2">
                        <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field label="Jobs From" v-model="computedDate1" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearFilter('jobsFrom')"></v-text-field>
                            </template>
                            <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                        </v-menu>
                    </div>
                    <div class="flex-grow-1 ml-2">
                        <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field label="Jobs To" v-model="computedDate2" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearFilter('jobsTo')" :rules="[(v) => $moment(computedDate1).isBefore(computedDate2) || 'Jobs To must be after Jobs From']"></v-text-field>
                            </template>
                            <v-date-picker v-model="date2" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu2 = false"></v-date-picker>
                        </v-menu>
                    </div>
                    <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="refreshItems">
                        <v-icon>icons8-available-updates</v-icon>
                    </v-btn>
                    <div class="ml-2">
                        <v-menu
                            offset-y
                            class="white"
                            style="z-index: 99999 !important; width: 200px !important; height: 200px !important"
                            :close-on-content-click="false"
                            origin="top right"
                            left
                            transition="scale-transition"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"
                                    ><v-icon>icons8-table-properties</v-icon></v-btn
                                >
                            </template>
                            <div class="white" style="width: 250px; max-height: 300px">
                                <div class="pa-5 white" style="width: 100% !important">
                                    <div class="body-2 font-weight-bold">Show / hide Columns</div>
                                    <v-checkbox
                                        v-model="headers[index].hidden"
                                        dense
                                        hide-details="auto"
                                        v-for="(item, index) in headers"
                                        :key="index"
                                        :label="item.text"
                                        :true-value="false"
                                        :false-value="true"
                                    ></v-checkbox>
                                </div>
                            </div>
                        </v-menu>
                    </div>
                    <div>
                        <v-btn @click="exportCSV()" style="height: 40px !important" class="primary ml-2 " depressed>
                            <v-icon color="white">icons8-export-csv</v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>
            <v-spacer />
            <div>
				<v-card flat height="90">
					<v-card-actions class="justify-center text-center grey--text" style="font-size: 12px">
                        TOTAL SELECTED
                    </v-card-actions>
					<v-card-title class="justify-center py-0">
						<app-text class="stats-grey grey--text font-weight-black" style="font-size: 30px">
                            <number v-if="selectedAll == false" ref="numberExample" :to="jobsSelected.length" :duration="0.0" />
                            <number v-else-if="selectedAll == true" ref="numberExample" :to="itemsTotal" :duration="0.0" />
                        </app-text>
                    </v-card-title>
				</v-card>
			</div>
        </div>


        <div class="mt-3 d-flex align-end">
            <div class="" v-if="jobsSelected.length < computedItems.length">
                <div class="d-flex flex-row pr-1">
                    <v-btn class="elevation-0" color="primary" medium @click="selectAll()">
                        <span class="white--text">SELECT ALL JOBS</span>
                    </v-btn>
                </div>
            </div>
            <div v-if="jobsSelected.length > 0" class="">
                <div class="d-flex flex-row pr-1">
                    <v-btn class="elevation-0" color="grey" medium @click="cancelAll()">
                        <span class="white--text">UNSELECT</span>
                    </v-btn>
                </div>
            </div>
            <div class="" v-if="jobsSelected.length > 0">
                <div class="d-flex flex-row pr-1 ">
                    <v-btn class="elevation-0" color="secondary" medium @click="openBulkActionDialog('Allocate')">
                        <span class="white--text">ALLOCATE</span>
                    </v-btn>
                </div>
            </div>
            <div class="" v-if="jobsSelected.length > 0">
                <div class="d-flex flex-row pr-1">
                    <v-btn value="add" class="elevation-0" color="secondary" medium @click.native="openBulkActionDialog('Unallocate')">
                        <span class="white--text">UNALLOCATE</span>
                    </v-btn>
                </div>
            </div>
            <div class="" v-if="jobsSelected.length > 0">
                <div class="d-flex flex-row pr-1">
                    <v-btn value="add" class="elevation-0" color="secondary" medium @click.native="openBulkActionDialog('Cancel')">
                        <span class="white--text">CANCEL</span>
                    </v-btn>
                </div>
            </div>
            <div class="" v-if="jobsSelected.length > 0">
                <div class="d-flex flex-row pr-1">
                    <v-btn value="add" class="elevation-0" color="secondary" medium @click.native="openBulkActionDialog('Draft')">
                        <span class="white--text">DRAFT</span>
                    </v-btn>
                </div>
            </div>
            <div class="" v-if="jobsSelected.length > 0">
                <div class="d-flex flex-row pr-1">
                    <v-btn value="add" class="elevation-0" color="secondary" medium @click.native="openBulkActionDialog('Publish') && totalPublish()">
                        <span class="white--text">PUBLISH</span>
                    </v-btn>
                </div>
            </div>
            <v-spacer />

            <div>
                <div class="caption font-weight-bold grey--text text--darken-1">ALLOCATED STATUS</div>
                <v-btn-toggle v-model="filter.allocatedStatus" multiple color="white" class="mr-1" background-color="white" dense>
                    <v-btn value="ALLOCATED" :class="filter.allocatedStatus.includes('ALLOCATED') ? 'success white--text' : 'success--text'" small>
                        <v-icon :class="filter.allocatedStatus.includes('ALLOCATED') ? 'white--text' : 'success--text'" class="text-h5 icons8-filled-circle"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">ALLOCATED</span>
                    </v-btn>
                    <v-btn value="UNALLOCATED" :class="filter.allocatedStatus.includes('UNALLOCATED') ? 'grey white--text' : 'grey--text'" small>
                        <v-icon :class="filter.allocatedStatus.includes('UNALLOCATED') ? 'white--text' : 'grey--text'" class="text-h5 icons8-filled-circle"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">UNALLOCATED</span>
                    </v-btn>
                </v-btn-toggle>
            </div>
            <div>
                <div class="caption font-weight-bold grey--text text--darken-1">PUBLISHED STATUS</div>
                <v-btn-toggle v-model="filter.publishedStatus" multiple color="white" class="mr-1"  background-color="white" dense>
                    <v-btn value="PUBLISHED" :class="filter.publishedStatus.includes('PUBLISHED') ? 'success white--text' : 'success--text'" small>
                        <v-icon :class="filter.publishedStatus.includes('PUBLISHED') ? 'white--text' : 'success--text'" class="text-h5 icons8-checkmark-yes"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">PUBLISHED</span>
                    </v-btn>
                    <v-btn value="UNPUBLISHED" :class="filter.publishedStatus.includes('UNPUBLISHED') ? 'grey white--text' : 'grey--text'" small>
                        <v-icon :class="filter.publishedStatus.includes('UNPUBLISHED') ? 'white--text' : 'grey--text'" class="text-h5 icons8-checkmark-yes"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">DRAFT</span>
                    </v-btn>
                    <v-btn value="CANCELLED" :class="filter.publishedStatus.includes('CANCELLED') ? 'error white--text' : 'error--text'" small>
                        <v-icon :class="filter.publishedStatus.includes('CANCELLED') ? 'white--text' : 'error--text'" class="text-h5 icons8-filled-circle"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">CANCELLED</span>
                    </v-btn>
                </v-btn-toggle>
            </div>
        </div>


        <v-row class="pa-3 mt-0">
            <v-data-table
                :item-class="itemRowBackground"
                sortable
                dense
                v-model="jobsSelected" 
                show-select 
                item-key="entityId" 
                class=""
                style="width: 100%"
                :headers="computedHeaders"
                :items="computedItems"
                :server-items-length="itemsTotal"
                :options.sync="datatableOptions"
                :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50]
                }"
                :loading="loading"
                loading-text="Loading Jobs... Please wait"
            >
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-spacer />
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn title="Permanently Delete" value="destroy" small>
                                <v-icon class="error--text">icons8-delete-trash</v-icon>
                            </v-btn>
                            <v-btn title="Delete" value="delete" small>
                                <v-icon class="grey--text text--darken-3">icons8-trash-can</v-icon>
                            </v-btn>
                            <v-btn title="Undelete" value="undelete" small>
                                <v-icon class="grey--text text--darken-3">icons8-trash-restore</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </template>
                <template v-slot:[`item.jobClientId`]="{ item }">
                    <div>{{ item.clientName }}</div>
                </template>
                <template v-slot:[`item.jobServiceId`]="{ item }">
                    <div>{{ item.serviceName }}</div>
                </template>
                <template v-slot:[`item.jobAssignedUserId`]="{ item }">
                    <div>{{ item.assignedUserName }}</div>
                </template>
                <template v-slot:[`item.jobSiteId`]="{ item }">
                    <div>{{ item.siteName }}</div>
                </template>
                <template v-slot:[`item.jobStartDateTime`]="{ item }">
                    <div>{{ $moment(item.jobStartDateTime).format('DD-MMM-YYYY HH:mm')}}</div>
                </template>
                <template v-slot:[`item.jobEndDateTime`]="{ item }">
                    <div>{{ $moment(item.jobEndDateTime).format('DD-MMM-YYYY HH:mm')}}</div>
                </template>
                <template v-slot:[`item.jobAssignedStatus`]="{ item }">
                    <v-icon 
                        class="pa-1" 
                        :class="
                            item.jobAssignedStatus == 'ALLOCATED' ? 'success--text' : 
                            item.jobAssignedStatus == 'UNALLOCATED' ? 'grey--text' : 
                            item.jobAssignedStatus == 'CANCELLED' ? 'error--text' : '' 
                        ">
                        icons8-filled-circle
                    </v-icon>
                </template>
                <template v-slot:[`item.jobPublishedStatus`]="{ item }">
                    <v-icon 
                        class="pa-1" 
                        :class="
                            item.jobPublishedStatus == 'PUBLISHED' ? 'success--text' : 
                            item.jobPublishedStatus == 'UNPUBLISHED' ? 'grey--text' : 
                            item.jobPublishedStatus == 'CANCELLED' ? 'error--text' : '' 
                        ">
                        icons8-checkmark-yes
                    </v-icon> 
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-icon class="error--text pa-1" v-if="item.deleted === '1' && toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">icons8-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">icons8-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">icons8-trash-restore</v-icon>
                    <v-icon class="primary--text ml-5" @click="openItem(item)">icons8-forward</v-icon>
                </template>
            </v-data-table>
        </v-row>



        <!-- //* BULK ACTION DIALOG -->
        <v-dialog v-model="bulkActionDialog" max-width="612" :key="dialogKey">
            <v-card>
                <v-toolbar color="secondary" elevation="0">
                    <app-text category="text-medium" class="white--text ml-2"> Bulk {{ bulkAction }}</app-text>

                    <v-spacer />
                    
                    <v-btn @click="cancelBulk()" icon dark>
                        <v-icon class="icons8-multiply" color="white" />
                    </v-btn>
                </v-toolbar>

                <div v-if="bulkAction === 'Allocate'" class="pa-4">

                    <div v-if="selectedAll || totalAllocate.allocatable > 0">
                        <app-text category="text-default-bold">Please select a Staff Member to ALLOCATE to</app-text>

                        <v-autocomplete label="Staff Members" v-model="allocatedStaffMember" class="my-2" filled hide-details background-color="white" dense auto-select-first :items="users" item-text="userName" return-object clearable> 
                            <template v-slot:item="data">
                                {{ data.item.userName }}
                            </template>
                            <template v-slot:selection="data">
                                {{ data.item.userName }}
                            </template>
                        </v-autocomplete>

                        <app-text v-if="allocatedStaffMember !== '' && !selectedAll" category="text-default-bold" class="pt-3 pb-2">You are ALLOCATING the following {{ allocateJobs.length }} Jobs to {{ allocatedStaffMember.userName }}:</app-text>
                        <app-text v-else-if="allocatedStaffMember !== '' && selectedAll" category="text-default-bold" class="pt-3 pb-2">You are ALLOCATING {{ itemsTotal }} Jobs to {{ allocatedStaffMember.userName }}</app-text>

                        <div v-if="allocatedStaffMember !== '' && !selectedAll" class="pa-2" style="max-height: 270px !important; overflow-y: auto !important; overflow-x: hidden !important">
                            <div v-for="job in allocateJobs" :key="job.id">
                                <v-row class="align-center pl-3">
                                    <div class="font-weight-bold darkgrey--text">{{ getClient(job.jobClientId) }}<span class="font-weight-regular">: {{ $moment(job.jobStartDateTime).format('DD-MMM-YYYY') }} at {{ $moment(job.jobStartDateTime).format('HH:mm') }}</span></div>
                                    <v-spacer />
                                    <v-btn @click="removeJob(job, allocateJobs)" icon dark>
                                        <v-icon class="icons8-delete" color="error" />
                                    </v-btn>
                                </v-row>
                            </div>
                        </div>
                    </div>
                    <div v-if="!selectedAll && totalAllocate.notAllocatable > 0" class="pb-6"> 
                        <app-text category="text-default-bold" class="pb-4">The following {{ totalAllocate.notAllocatable }} Jobs will not be Allocated as they have been Cancelled:</app-text>

                        <div v-for="job in notAllocateJobs" :key="job.entityId">
                            <v-row class="align-center pl-3">
                                <div>{{ job.jobClientId }}: {{ job.jobStartDateTime }} at {{ job.jobStartDateTime }}</div>
                            </v-row>
                        </div>
                    </div>

                </div>

                <div v-if="bulkAction === 'Unallocate'" class="pa-2">

                    <div v-if="selectedAll" class="pb-2 pt-3 pa-2 font-weight-bold text-h7 text-center">You are UNALLOCATING {{ itemsTotal }} Jobs</div>
                    <div v-if="!selectedAll" class="pb-2 pt-3 pa-2 font-weight-bold text-h7">You are UNALLOCATING the following {{ jobsSelected.length }} Jobs:</div>

                    <div v-if="!selectedAll" class="pa-2" style="max-height: 270px !important; overflow-y: auto !important; overflow-x: hidden !important">
                        <div v-for="job in jobsSelected" :key="job.id">
                            <v-row class="align-center pb-1 pl-3">
                                <div class="font-weight-bold darkgrey--text">{{ getClient(job.jobClientId) }}<span class="font-weight-regular">: {{ $moment(job.jobStartDateTime).format('DD-MMM-YYYY') }} at {{ $moment(job.jobStartDateTime).format('HH:mm') }}</span></div>
                                <v-icon v-if="job.jobAssignedStatus === 'ALLOCATED'" class="iconbackground text-h5 success--text icons8-filled-circle"></v-icon>
                                <v-icon v-if="job.jobAssignedStatus === 'ALLOCATED' && job.jobPublishedStatus === 'UNPUBLISHED'" class="iconbackground text-h5 grey--text icons8-in-progress"></v-icon>
                                <v-icon v-if="job.jobAssignedStatus === 'ALLOCATED' && job.jobPublishedStatus === 'PUBLISHED'" class="iconbackground text-h5 success--text icons8-checkmark-yes"></v-icon>
                                <v-icon v-else-if="job.jobAssignedStatus === 'UNALLOCATED'" class="iconbackground text-h5 grey--text icons8-filled-circle"></v-icon>
                                <v-icon v-else-if="job.jobPublishedStatus === 'CANCELLED'" class="iconbackground text-h5 error--text icons8-filled-circle"></v-icon>
                                <v-spacer />
                                <v-btn @click="removeJob(job, jobsSelected)" icon dark>
                                    <v-icon class="icons8-delete" color="error" />
                                </v-btn>
                            </v-row>
                        </div>
                    </div>
                </div>
                        
                <div v-if="bulkAction === 'Cancel'" class="pa-2">
                    <div v-if="selectedAll" class="pb-2 pt-3 pa-2 font-weight-bold text-h7 text-center">You are CANCELLING {{ itemsTotal }} Jobs</div>
                    <div v-if="!selectedAll" class="pb-2 pt-3 pa-2 font-weight-bold text-h7">You are CANCELLING the following {{ jobsSelected.length }} Jobs:</div>

                    <div v-if="!selectedAll" class="pa-2" style="max-height: 270px !important; overflow-y: auto !important; overflow-x: hidden !important">
                        <div v-for="job in jobsSelected" :key="job.id">
                            <v-row class="align-center pb-1 pl-3">
                                <div class="font-weight-bold darkgrey--text">{{ getClient(job.jobClientId) }}<span class="font-weight-regular">: {{ $moment(job.jobStartDateTime).format('DD-MMM-YYYY') }} at {{ $moment(job.jobStartDateTime).format('HH:mm') }}</span></div>
                                <v-icon v-if="job.jobAssignedStatus === 'ALLOCATED'" class="iconbackground text-h5 success--text icons8-filled-circle"></v-icon>
                                <v-icon v-if="job.jobAssignedStatus === 'ALLOCATED' && job.jobPublishedStatus === 'UNPUBLISHED'" class="iconbackground text-h5 grey--text icons8-in-progress"></v-icon>
                                <v-icon v-if="job.jobAssignedStatus === 'ALLOCATED' && job.jobPublishedStatus === 'PUBLISHED'" class="iconbackground text-h5 success--text icons8-checkmark-yes"></v-icon>
                                <v-icon v-else-if="job.jobAssignedStatus === 'UNALLOCATED'" class="iconbackground text-h5 grey--text icons8-filled-circle"></v-icon>
                                <v-icon v-else-if="job.jobPublishedStatus === 'CANCELLED'" class="iconbackground text-h5 error--text icons8-filled-circle"></v-icon>
                                <v-spacer />
                                <v-btn @click="removeJob(job, jobsSelected)" icon dark>
                                    <v-icon class="icons8-delete" color="error" />
                                </v-btn>
                            </v-row>
                        </div>
                    </div>
                </div>

                <div v-if="bulkAction === 'Draft'" class="pa-2">
                    <div v-if="selectedAll" class="pb-2 pt-3 pa-2 font-weight-bold text-h7 text-center">You are setting {{ itemsTotal }} Jobs to DRAFT</div>
                    <div v-if="!selectedAll" class="pb-2 pt-3 pa-2 font-weight-bold text-h7">You are setting the following {{ jobsSelected.length }} Jobs to DRAFT:</div>

                    <div v-if="!selectedAll" class="pa-2" style="max-height: 270px !important; overflow-y: auto !important; overflow-x: hidden !important">
                        <div v-for="job in jobsSelected" :key="job.id">
                            <v-row class="align-center pl-3">
                                <div class="font-weight-bold darkgrey--text">{{ getClient(job.jobClientId) }}<span class="font-weight-regular">: {{ $moment(job.jobStartDateTime).format('DD-MMM-YYYY') }} at {{ $moment(job.jobStartDateTime).format('HH:mm') }}</span></div>
                                <v-icon v-if="job.jobAssignedStatus === 'ALLOCATED'" class="iconbackground text-h5 success--text icons8-filled-circle"></v-icon>
                                <v-icon v-if="job.jobAssignedStatus === 'ALLOCATED' && job.jobPublishedStatus === 'UNPUBLISHED'" class="iconbackground text-h5 grey--text icons8-in-progress"></v-icon>
                                <v-icon v-if="job.jobAssignedStatus === 'ALLOCATED' && job.jobPublishedStatus === 'PUBLISHED'" class="iconbackground text-h5 success--text icons8-checkmark-yes"></v-icon>
                                <v-icon v-else-if="job.jobAssignedStatus === 'UNALLOCATED'" class="iconbackground text-h5 grey--text icons8-filled-circle"></v-icon>
                                <v-icon v-else-if="job.jobPublishedStatus === 'CANCELLED'" class="iconbackground text-h5 error--text icons8-filled-circle"></v-icon>
                                <v-spacer />
                                <v-btn @click="removeJob(job, jobsSelected)" icon dark>
                                    <v-icon class="icons8-delete" color="error" />
                                </v-btn>
                            </v-row>
                        </div>
                    </div>
                </div>

                <div v-if="bulkAction === 'Publish'" class="pa-2 pt-3">
                    <div v-if="totalPublish.notPublishable > 0 && !selectedAll" class="pb-2"> 
                        <app-text category="text-default-bold" class="px-2">The following {{ totalPublish.notPublishable }} Jobs will not be Published as they have not been Allocated:</app-text>

                        <div class="pt-2 px-2" style="max-height: 80px !important; overflow-y: auto !important; overflow-x: hidden !important">
                            <div v-for="job in notPublishableJobs" :key="job.id">
                                <div class="font-weight-bold darkgrey--text">{{ getClient(job.jobClientId) }}<span class="font-weight-regular">: {{ $moment(job.jobStartDateTime).format('DD-MMM-YYYY') }} at {{ $moment(job.jobStartDateTime).format('HH:mm') }}</span></div>
                            </div>
                        </div>
                    </div>
                    
                    <div v-if="totalPublish.cancelled > 0 && !selectedAll" class=""> 
                        <app-text category="text-default-bold" class="pb-1 px-2">The following {{ totalPublish.cancelled }} Jobs will not be Published as they have been Cancelled:</app-text>

                        <div class="pa-2" style="max-height: 120px !important; overflow-y: auto !important; overflow-x: hidden !important">
                            <div v-for="job in cancelledJobs" :key="job.id">
                                <v-row class="align-center pl-3 py-1">
                                    <div class="font-weight-bold darkgrey--text">{{ getClient(job.jobClientId) }}<span class="font-weight-regular">: {{ $moment(job.jobStartDateTime).format('DD-MMM-YYYY') }} at {{ $moment(job.jobStartDateTime).format('HH:mm') }}</span></div>
                                </v-row>
                            </div>
                        </div>
                    </div>
                    <div v-if="totalPublish.notPublishable > 0 && !selectedAll || totalPublish.cancelled > 0 && !selectedAll">
                        <v-divider class="mx-1 mt-2 mb-1" />
                    </div>
                    <div v-if="totalPublish.publishable > 0 || selectedAll">
                        <div v-if="selectedAll" class="pb-2 pt-3 pa-2 font-weight-bold text-h7 text-center">You are PUBLISHING {{ itemsTotal }} Jobs</div>   
                        <div v-if="!selectedAll" class="pb-2 pt-3 pa-2 font-weight-bold text-h7">You are PUBLISHING the following {{ publishableJobs.length }} Jobs:</div>
                        <div v-if="!selectedAll" class="pa-2" style="max-height: 150px !important; overflow-y: auto !important; overflow-x: hidden !important">
                            <div v-for="job in publishableJobs" :key="job.id">
                                <v-row class="align-center pl-3">
                                <div class="font-weight-bold darkgrey--text">{{ getClient(job.jobClientId) }}<span class="font-weight-regular">: {{ $moment(job.jobStartDateTime).format('DD-MMM-YYYY') }} at {{ $moment(job.jobStartDateTime).format('HH:mm') }}</span></div>
                                    <v-icon v-if="job.jobAssignedStatus === 'ALLOCATED'" class="iconbackground text-h5 success--text icons8-filled-circle"></v-icon>
                                    <v-icon v-if="job.jobAssignedStatus === 'ALLOCATED' && job.jobPublishedStatus === 'UNPUBLISHED'" class="iconbackground text-h5 grey--text icons8-in-progress"></v-icon>
                                    <v-icon v-if="job.jobAssignedStatus === 'ALLOCATED' && job.jobPublishedStatus === 'PUBLISHED'" class="iconbackground text-h5 success--text icons8-checkmark-yes"></v-icon>
                                    <v-icon v-else-if="job.jobAssignedStatus === 'UNALLOCATED'" class="iconbackground text-h5 grey--text icons8-filled-circle"></v-icon>
                                    <v-icon v-else-if="job.jobPublishedStatus === 'CANCELLED'" class="iconbackground text-h5 error--text icons8-filled-circle"></v-icon>
                                    <v-spacer />
                                    <v-btn @click="removeJob(job, publishableJobs)" icon dark>
                                        <v-icon class="icons8-delete" color="error" />
                                    </v-btn>
                                </v-row>
                            </div>
                        </div>
                    </div>
                    <div v-else class="d-flex justify-center pt-4">
                        <app-text category="text-default-bold" class="">YOU HAVE NO PUBLISHABLE JOBS</app-text>
                    </div>
                    <div class="d-flex pt-8 pb-2 px-2">
                        <appbutton @click.native="cancelBulk()" label="Close" />
                        <v-spacer />
                        <appbutton v-if="totalPublish.publishable > 0" @click.native="bulk(bulkAction)" type="primary" :label="'Confirm ' + bulkAction" />
                        <!-- <appbutton v-if="bulkAction === 'Publish' && totalPublish.publishable > 0" @click.native="bulkPublish()" type="primary" :label="'Confirm ' + bulkAction" /> -->
                    </div>
                </div>

                <div v-if="bulkAction !== 'Publish'" class="d-flex pt-4 pa-4">
                    <appbutton @click.native="cancelBulk()" label="Close" />
                    <v-spacer />
                    <appbutton v-if="allocatedStaffMember !== '' || bulkAction !== 'Allocate' || jobsSelected.length > 0" @click.native="bulk(bulkAction)" type="primary" :label="'Confirm ' + bulkAction" />
                    <!-- <appbutton v-if="bulkAction === 'Publish' && totalPublish.publishable > 0" @click.native="bulkPublish()" type="primary" :label="'Confirm ' + bulkAction" /> -->
                </div>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        type: '',
        status: '',
        key: 'jobs',
        service: {},
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        computedItems: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,


        jobsSelected: [],

        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'Assigned Status', align: 'center', value: 'jobAssignedStatus', sortable: false, hidden: false, expanded: false },
            { text: 'Published Status', align: 'center', value: 'jobPublishedStatus', sortable: false, hidden: false, expanded: false },
            { text: 'Client', value: 'jobClientId', sortable: false, hidden: false, expanded: false },
            { text: 'Site', value: 'jobSiteId', sortable: false, hidden: false, expanded: false },
            { text: 'Service', value: 'jobServiceId', sortable: false, hidden: false, expanded: false },
            { text: 'Assigned User', value: 'jobAssignedUserId', sortable: false, hidden: false, expanded: false },
            { text: 'Start Date Time', value: 'jobStartDateTime', sortable: false, hidden: false, expanded: false },
            { text: 'End Date Time', value: 'jobEndDateTime', sortable: false, hidden: false, expanded: false },
            { text: 'Job Type', value: 'jobType', sortable: false, hidden: true, expanded: false },
            { text: 'Recurring Rule ID', value: 'jobRecurringRuleId', sortable: false, hidden: true, expanded: false },


            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false },
        ],
        // Fields to Retrieve from Database
        // fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['courseName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        filter: {
            staffMember: '',
            userCategory: '',
            status: '',
            site: '',
            service: '',
            client: '',
            publishedStatus: [],
            allocatedStatus: [],
            jobsFrom: '',
            jobsTo: '',
            manager: '',
        },

        userCategories: [
            'Mobile Cleaners', 
            // 'Director', 
            // 'Commercial Manager', 
            'Office Staff', 
            'Security Staff', 
            'Cleaner', 
            'Supervisor',
            // 'Senior Manager'
        ],
        users: [],
        services: [],
        sites: [],
        clients: [],


        date1: '',
        dateMenu1: false,
        date2: '',
        dateMenu2: false,


        bulkAction: '',
        bulkActionDialog: false,

        dialogKey: 0,

        notAllocateJobs: [],
        allocateJobs: [],


        allocatedStaffMember: '',

        selectedAll: false,
        selectedSearchQuery: '',

        loading: false
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
        computedDate1() {
            return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YYYY");
        },

        computedDate2() {
            return this.MIX_formatDateTime(this.date2, "YYYY-MM-DD", "DD-MMM-YYYY");
        },

        totalAllocate() {
            if (this.bulkAction === 'Allocate') {
                var t = this;
                var jobsSelected = t.jobsSelected;
                t.notAllocateJobs = [];
                t.allocateJobs = [];
                const total = [];
                total.notAllocatable = 0
                total.allocatable = 0

                jobsSelected.forEach((job) => {
                    if (job.jobPublishedStatus === 'CANCELLED') {
                        total.notAllocatable++
                        t.notAllocateJobs.push(job)
                    } else {
                        total.allocatable++
                        t.allocateJobs.push(job)
                    }
                })

                return total
            }
        },
        totalPublish() {
            if (this.bulkAction === 'Publish') {
                var t = this;
                var jobsSelected = t.jobsSelected;
                t.notPublishableJobs = [];
                t.publishableJobs = [];
                const total = [];
                total.notPublishable = 0;
                total.publishable = 0;
                total.cancelled = 0;

                jobsSelected.forEach((job) => {
                    if (job.jobPublishedStatus === 'CANCELLED') {
                        total.cancelled++
                        t.cancelledJobs.push(job)
                    } else if (job.jobAssignedStatus === 'UNALLOCATED') {
                        total.notPublishable++
                        t.notPublishableJobs.push(job)
                    } else if (job.jobAssignedStatus === 'ALLOCATED') {
                        total.publishable++
                        t.publishableJobs.push(job)
                    } 
                })

                return total
            }

        },

        computedUsers() {
            var t = this;
            var users = t.users
            users = users.filter(function(item) {
                return item.userCategory != 'COMMERCIALMANAGER' && item.userCategory != 'SENIORMANAGER' && item.userCategory != 'DIRECTOR'
            })
            if (this.filter.userCategory !== "" && this.filter.userCategory !== null && this.filter.userCategory !== undefined) {

                var categories = []
                
                if (this.filter.userCategory == 'Mobile Cleaners') {
                    categories.push('MOBILECLEANER')
                }
                categories.push(this.filter.userCategory.replace(/\s/g, '').toUpperCase())

                //console.log('userCategory = ' + userCategory)
                users = users.filter(function(item) {
                    return categories.includes(item.userCategory)
                })
            }
            return users 
        },

        computedManagers() {
            var t = this; 

            var managers = t.users.filter(function(item) {
                return item.userCategory == 'COMMERCIALMANAGER' || item.userCategory == 'SENIORMANAGER' || item.userCategory == 'DIRECTOR'
            })

            return managers
        },
        
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '';
                } else {
                    searchQuery = '@deleted:{false}';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}`;
                    } else {
                        searchQuery = `${searchQuery}  @deleted:{false}`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}`;
                    } else {
                        searchQuery = `${searchQuery}  @deleted:{false}`;
                    }
                }
            }

            if (this.filter.service !== "" && this.filter.service !== null && this.filter.service !== undefined) {
                searchQuery = `${searchQuery} @jobServiceId:{${this.filter.service}}`
            }

            if (JSON.stringify(this.filter.publishedStatus) !== "[]") {
                //console.log('published status = ' + JSON.stringify(this.filter.publishedStatus, null, 2))
                searchQuery = `${searchQuery} @jobPublishedStatus:{${this.filter.publishedStatus.join("|")}}`
            }

            if (JSON.stringify(this.filter.allocatedStatus) !== "[]") {
                searchQuery = `${searchQuery} @jobAssignedStatus:{${this.filter.allocatedStatus.join("|")}}`
            }

            if (this.filter.userCategory !== "" && this.filter.userCategory !== null && this.filter.userCategory !== undefined) {
                var users = []
                this.computedUsers.forEach((item) => {
                    users.push(item.entityId)
                })
                //console.log('users = ' + JSON.stringify(users, null, 2))
                searchQuery = `${searchQuery} @jobAssignedUserId:{${users.join("|")}}`
            }

            if (this.filter.client !== "" && this.filter.client !== null && this.filter.client !== undefined) {
                searchQuery = `${searchQuery} @jobClientId:{${this.filter.client}}`
            }

            if (this.filter.site !== "" && this.filter.site !== null && this.filter.site !== undefined) {
                searchQuery = `${searchQuery} @jobSiteId:{${this.filter.site}}`
            }
            if (this.filter.staffMember !== "" && this.filter.staffMember !== null && this.filter.staffMember !== undefined) {
                searchQuery = `${searchQuery} @jobAssignedUserId:{${this.filter.staffMember}}`
            }
            if (this.filter.jobsFrom !== "" && this.filter.jobsFrom !== null && this.filter.jobsFrom !== undefined) {
                searchQuery = `${searchQuery} @jobStartDateTime:[${this.filter.jobsFrom} +inf]`
            }
            if (this.filter.jobsTo !== "" && this.filter.jobsTo !== null && this.filter.jobsTo !== undefined) {
                searchQuery = `${searchQuery} @jobStartDateTime:[-inf ${this.filter.jobsTo}]`
            }

            if (this.filter.manager !== "" && this.filter.manager !== null && this.filter.manager !== undefined) {
                var sites = []
                console.log(this.filter.manager)
                this.sites.forEach((item) => {
                    if (item.siteManagerUserId == this.filter.manager) {
                        sites.push(item.entityId)
                    }
                })
                
                if (sites.length > 0) {
                    searchQuery = `${searchQuery} @jobSiteId:{${sites.join("|")}}`
                }
            }

            console.log(searchQuery);

            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            // if (this.listshrunk) {
            //     headers = headers.filter((header) => header.expanded === false || header.expanded === true); // Return Headers based on Expanded State
            // } else {
            //     headers = headers.filter((header) => header.expanded === false); // Return Headers based on Expanded State
            // }
            return headers;
        },
        // computedItems() {
        //     var items = this.items;
        //     if (this.type) {
        //         items = this.items.filter((item) => item.actionType === this.type);
        //     }
        //     if (this.status) {
        //         items = this.items.filter((item) => item.actionStatus === this.status);
        //     }
        //     return items;
        // },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            // if (!computedFields.includes('@actionId')) {
            //     computedFields.push('@actionId');
            // }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedType() {
            const types = [];
            this.items.filter((item) => {
                return types.push(item.actionType);
            });
            return types;
        },
        computedStatus() {
            const status = [];
            this.items.filter((item) => {
                return status.push(item.actionStatus);
            });
            return status;
        },
    },
    methods: {


        async exportCSV() {
            var t = this;
            // t.exportingCSV = true
            
            let itemsResult = await this.REDIS_searchFor('jobs', 0, 9999, 'jobStartDateTime', 'ASC', this.computedSearchQuery);
            if (itemsResult.success) {
                // console.log('itemsResult: ' + JSON.stringify(itemsResult, null, 2))
            
                var data = itemsResult.data.documents.map(e => {
                    return {

                        'Assigned Status': e.jobAssignedStatus,
                        'Published Status': e.jobPublishedStatus,
                        'Client': t.getClient(e.jobClientId),
                        'Site': t.getSite(e.jobSiteId),
                        'Service': t.getService(e.jobServiceId),
                        'Assigned User': t.getUser(e.jobAssignedUserId),
                        'Start Date Time': t.$moment(e.jobStartDateTime).format('DD-MM-YYYY HH:mm'),
                        'End Date Time': t.$moment(e.jobEndDateTime).format('DD-MM-YYYY HH:mm'),

                    }
                })

                // console.log('data  = ' + JSON.stringify(data, null, 2))

                let csv = t.$papa.unparse(data)
                t.$papa.download(csv, 'Bulk Report: ' + t.$moment().format('DD-MM-YYYY'))
                // t.exportingCSV = false
            }
        },

        removeJob(job, collection) {
            var t = this;
            var array = collection

            const index = array.indexOf(job);
            if (index > -1) {
                array.splice(index, 1)
            }
            if (collection !== 'jobsSelected') {
                const index1 = t.jobsSelected.indexOf(job);
                if (index1 > -1) {
                    t.jobsSelected.splice(index1, 1)
                }

            }

        },

        selectAll() {
            var t = this;

            t.selectedAll = true 
            t.selectedSearchQuery = t.computedSearchQuery

            t.computedItems.forEach(function(item) {
                if (t.jobsSelected.indexOf(item) === -1) {
                    t.jobsSelected.push(item)
                }
            })
        },
        cancelAll() {
            var t = this;
            t.jobsSelected = []
            t.selectedAll = false

        },
        openBulkActionDialog(action) {
            var t = this;
            t.bulkActionDialog = true;
            t.bulkAction = action
        },
        cancelBulk() {
            var t = this;
            t.bulkActionDialog = false;
            t.allocatedStaffMember = '';
            t.jobsSelected = [];
            t.selectedAll = false
        },


        async bulk(bulkAction) {
            var t = this;
            var jobsSelected = t.jobsSelected;
            // var batch = firebase.db.batch()
            var userId = ''


            if (t.selectedAll) {
                // add 'publishedStatus !== cancelled to search query
                // if bulkAction = publish, add assignedStatus == allocated 

                if (bulkAction === 'Allocate') {
                    userId = t.allocatedStaffMember.entityId
                }
                bulkAction = bulkAction.toUpperCase()

                var redisData = {}
                redisData.action = bulkAction
                redisData.modifiedUserId = t.GET_FIREBASE_currentUser.entityId
                redisData.searchQuery = t.computedSearchQuery
                redisData.jobAssignedUserId = userId
                redisData.progressId = t.MIX_generateId()

                console.log('redisData  = ' + JSON.stringify(redisData, null, 2))

                let result = await this.REDIS_applyInBulk(redisData);
                
				if (result.success) {
					t.MIX_alertBox({ color: "success", timeout: 2000, message: "Bulk Job Started", show: true });
				}
            }
            else {
                // Start Progress

                let progressData = {
                    id: t.MIX_generateId(), 
                    type: "Bulk Action: " + bulkAction,
                    linkedId: null,
                    totalItems: 0,
                    count: 0, 
                    success: 0,
                    error: 0,
                    status: "In Progress",
                    createdDateTime: new Date(),
                    createdUserId: t.GET_FIREBASE_currentUser.entityId,
                }

                await this.REDIS_createWithId('progress', progressData, progressData.id);

                if (bulkAction === 'Publish') {
                    var publishJobs = t.publishableJobs;

                    progressData.totalItems = publishJobs.length;
                    progressData.modifiedUserId = t.GET_FIREBASE_currentUser.entityId
                    this.REDIS_update('progress', progressData.id, progressData);

                    for (var i = 0; i < publishJobs.length; i++) {
                        var job = publishJobs[i]
                        if (job.jobAssignedStatus === 'ALLOCATED' && job.jobPublishedStatus !== 'CANCELLED') {
                            job.jobPublishedStatus = 'PUBLISHED';
                            job.modifiedUserId = t.GET_FIREBASE_currentUser.entityId
                            let result = await this.REDIS_update(t.key, job.entityId, job);
                            progressData.count++
                            
                            if (result.success) {
                                t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                                progressData.success++;
                            } else {
                                t.MIX_alertBox({ color: "error", timeout: 2000, message: "Error Saving Item", show: true });
                                progressData.error++;
                            }

                            // save job
                            this.REDIS_update('progress', progressData.id, progressData);
                        }

                    }
                    progressData.status = "Completed";
                    this.REDIS_update('progress', progressData.id, progressData);

                } else if (bulkAction === 'Allocate') {
                    var allocateJobs = t.allocateJobs;
                    progressData.totalItems = allocateJobs.length;
                    progressData.modifiedUserId = t.GET_FIREBASE_currentUser.entityId
                    this.REDIS_update('progress', progressData.id, progressData);
                    for (var i = 0; i < allocateJobs.length; i++) {
                        var job = allocateJobs[i]
                        if (job.jobPublishedStatus !== 'CANCELLED') {
                            job.jobAssignedStatus = 'ALLOCATED'
                            job.jobPublishedStatus = 'UNPUBLISHED'
                            job.jobAssignedUserId = t.allocatedStaffMember.entityId
                            job.modifiedUserId = t.GET_FIREBASE_currentUser.entityId
                            // save job
                            let result = await this.REDIS_update(t.key, job.entityId, job);
                            progressData.count++
                            
                            if (result.success) {
                                t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                                progressData.success++;
                            } else {
                                t.MIX_alertBox({ color: "error", timeout: 2000, message: "Error Saving Item", show: true });
                                progressData.error++;
                            }
                            this.REDIS_update('progress', progressData.id, progressData);
                        }
                        progressData.status = "Completed";
                        this.REDIS_update('progress', progressData.id, progressData);
                    }
                } else {

                    progressData.totalItems = jobsSelected.length;
                    progressData.modifiedUserId = t.GET_FIREBASE_currentUser.entityId
                    this.REDIS_update('progress', progressData.id, progressData);

                    for (var i = 0; i < jobsSelected.length; i++) {
                        var job = jobsSelected[i]

                        if (bulkAction === 'Unallocate') {
                            if (job.jobPublishedStatus !== 'CANCELLED') {
                                job.jobAssignedStatus = 'UNALLOCATED'
                                job.jobAssignedUserId = ''
                                job.jobPublishedStatus = 'UNPUBLISHED'
                            }
                        }

                        if (bulkAction === 'Draft') {
                            job.jobPublishedStatus = 'UNPUBLISHED';
                        }

                        if (bulkAction === 'Cancel') {
                            if (job.jobPublishedStatus !== 'CANCELLED') {
                                job.jobPublishedStatus = 'CANCELLED';
                            }
                        }
                        
                        job.modifiedUserId = t.GET_FIREBASE_currentUser.entityId

                        let result = await this.REDIS_update(t.key, job.entityId, job);
                        progressData.count++
                        
                        if (result.success) {
                            t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                            progressData.success++;
                        } else {
                            t.MIX_alertBox({ color: "error", timeout: 2000, message: "Error Saving Item", show: true });
                            progressData.error++;
                        }
                        this.REDIS_update('progress', progressData.id, progressData);

                    }
                    progressData.status = "Completed";
                    this.REDIS_update('progress', progressData.id, progressData);
                }
            }

            t.bulkActionDialog = false
            t.jobsSelected = []
            t.selectedAll = false
            
        },




        
        // Clear Item Property
        clearFilter(property) {
            setTimeout(() => {
                this.filter[property] = '';
            }, 1);
        },
        
        async editItem(id) {
            // Get the item from the database
            let itemResult = await this.MIX_readItem(id, 'service');
            if (itemResult.code === 1) {
                this.$emit('edit', itemResult.data);
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Editing Component', show: true });
            }
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        refreshItems: function () {
            this.getItems();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        getClient(clientId) {
            var t = this;
            var client = []
            var clientName = ''

            client = t.clients.filter((item) => { return item.entityId == clientId })

            if (client.length !== 0) { 
                clientName = client[0].clientName 
                return clientName
            }
        },
        getUser(userId) {
            var t = this;
            var user = []
            var userName = ''

            user = t.users.filter((item) => { return item.entityId == userId })

            if (user.length !== 0) { userName = user[0].userName }

            return userName
        },
        getSite(siteId) {
            var t = this;
            var site = []
            var siteName = ''

            site = t.sites.filter((item) => { return item.entityId == siteId })

            if (site.length !== 0) { siteName = site[0].siteName }

            return siteName
        },
        getService(serviceId) {
            var t = this;
            var service = []
            var serviceName = ''

            service = t.services.filter((item) => { return item.entityId == serviceId })

            if (service.length !== 0) { serviceName = service[0].serviceName }

            return serviceName
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        async computeItems() {
            var t = this;
            var items = this.items;

            if (this.type) {
                items = this.items.filter((item) => item.actionType === this.type);
            }
            if (this.status) {
                items = this.items.filter((item) => item.actionStatus === this.status);
            }

            for (let i = 0; i < items.length; i++) {
                var item = items[i];
               
                item.clientName = t.getClient(item.jobClientId)
                item.siteName = t.getSite(item.jobSiteId)
                item.serviceName = t.getService(item.jobServiceId)
                item.assignedUserName = t.getUser(item.jobAssignedUserId)

            }

            this.computedItems = items;
            t.loading = false
            // return items;
        },
        async getItems() {
            var t = this;
            t.loading = true

            let itemsResult = await this.REDIS_searchFor('jobs', this.itemsFrom, this.datatableOptions.itemsPerPage, 'jobStartDateTime','ASC', this.computedSearchQuery )
            // //console.log('itemsResult: ' + JSON.stringify(itemsResult));
            if (itemsResult.success) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.documents;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                //console.log('itemsTo = ' + itemsTo)
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                // t.loading = false
                t.computeItems()
                //console.log('itemsTo total = ' + this.itemsTo)
            }
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.service = { ...this.datadown };
            },
            deep: true,
        },
        dateMenu1: {
            handler: async function () {
                this.filter.jobsFrom = this.MIX_formatDateTime(this.date1, 'YYYY-MM-DD', 'X');
            },
            deep: true,
        },
        dateMenu2: {
            handler: async function () {
                this.filter.jobsTo = this.$moment(this.date2, 'YYYY-MM-DD').endOf('day').format('X');
            },
            deep: true,
        },
        refreshitems() {
            // this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        computedFields() {
            // this.getItems();
        },
        toggleDeletedItems() {
            // this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                //console.log('itemsFrom datatableOptions = ' + this.itemsFrom)
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                //console.log('itemsTo datatableOptions = ' + itemsTo)
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }

                //console.log('itemsTo total datatableOptions = ' + this.itemsTo)
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        var t = this;
        this.service = { ...this.datadown };


        let users = await t.REDIS_searchFor('user', '0', '999', 'userName', 'ASC', '@deleted:{false} @userStatus:{APPROVED}');
        if (users.success) { t.users = users.data.documents; }

        t.users = t.users.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
                userCategory: x.userCategory
            };
        })


        let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false} -@clientStatus:{ARCHIVE}');
        if (clients.success) { t.clients = clients.data.documents; }

        t.clients = t.clients.map((x) => {
            return {
                entityId: x.entityId,
                clientName: x.clientName,
            };
        })

        
        let services = await t.REDIS_searchFor('service', '0', '9999', 'serviceName', 'ASC', '@deleted:{false}');
        if (services.success) { t.services = services.data.documents; }

        t.services = t.services.map((x) => {
            return {
                entityId: x.entityId,
                serviceName: x.serviceName,
            };
        })

        let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
        if (sites.success) { t.sites = sites.data.documents; }

        t.sites = t.sites.map((x) => {
            return {
                entityId: x.entityId,
                siteName: x.siteName,
                siteManagerUserId: x.siteManagerUserId,
            };
        })
        this.getItems();
    },
};
</script>