<template>
    <v-row fluid fill-height class="d-flex flex-column align-start " style="height: 100% !important" no-gutters>
    <!-- <v-container fluid fill-height class="d-flex flex-column align-start appbackground pa-0 ma-0"> -->
		<v-col class="align-center pa-3 mt-1" cols="12">
            
            <v-row class="align-start" style="height: 85.5vh !important">
                <v-col class="fill-height pa-0 pt-3" cols="12" style="height: 100% !important">
                    <v-card color="secondary" flat class="fill-height" >
                        <v-row class="align-start px-4 pb-0">
                            <v-col cols="8" class="pb-0">
                                <div v-if="$vuetify.breakpoint.xlOnly" class="white--text text-h5 align-start font-weight-regular">AUDIT TARGETS</div>
                                <div v-else class="white--text pt-0 text-h6 align-end font-weight-regular">AUDIT TARGETS</div>
                                <!--
                                    <div v-if="$vuetify.breakpoint.xlOnly" class="white--text text-h6 align-start font-weight-bold">PROJECTED</div>
                                    <div v-else class="white--text body-1 align-start font-weight-bold">PROJECTED</div> 
                                -->
                            </v-col>
                            <v-col cols="4" class="d-flex justify-end pb-0">
                                <v-icon v-if="$vuetify.breakpoint.xlOnly" class="white--text" size="60">icons8-data-sheet</v-icon>
                                <v-icon v-else class="white--text" size="40">icons8-data-sheet</v-icon>
                            </v-col>
                        </v-row>
                        <v-row class="align-start px-4 pt-0">
                            <v-col cols="12" class="pt-0">
                                <v-simple-table >
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td class="pl-0 secondary text-h6 font-weight-bold white--text">Manager</td>
                                                <td class="secondary text-h6 font-weight-bold white--text">Target</td>
                                                <td class="secondary text-h6 font-weight-bold white--text">Completed</td>
                                                <td class="secondary text-h6 font-weight-bold white--text">Target %</td>
                                                <td class="secondary text-h6 font-weight-bold white--text">Completed %</td>
                                            </tr>
                                            <tr v-for="staffMember in staff.slice(0,13)" :key="staffMember.id">
                                                <td class="pl-0 secondary text-h6 d-flex font-weight-normal white--text">
                                                    {{ staffMember.name}}
                                                    <div class="ml-2" v-if="staffMember.userStatus === 'ARCHIVED'">
                                                        <v-icon class="red--text" size="18">icons8-filled-circle</v-icon>
                                                    </div>
                                                </td>
                                                <td class="secondary text-h6 font-weight-normal white--text">
                                                    {{ staffMember.target}}
                                                </td>
                                                <td class="secondary text-h6 font-weight-normal white--text">
                                                    {{ staffMember.completed}}
                                                </td>
                                                <td class="secondary text-h6 font-weight-normal white--text">
                                                    <span v-if="staffMember.target !== 0">{{ staffMember.targetPercentage}}</span><span v-else>-</span>
                                                </td>
                                                <td class="secondary text-h6 font-weight-normal white--text">
                                                    <span v-if="staffMember.target !== 0">{{ staffMember.completedPercentage}}</span><span v-else>-</span>
                                                </td>
                                            </tr>
                                            
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                            <!-- <v-divider vertical class="white"/>
                            <v-col cols="6" class="pt-0">
                                <v-simple-table >
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td class="pl-0 secondary text-h6 font-weight-bold white--text">Manager</td>
                                                <td class="secondary text-h6 font-weight-bold white--text">Target</td>
                                                <td class="secondary text-h6 font-weight-bold white--text">Current</td>
                                            </tr>
                                            <tr v-for="staffMember in staff.slice(14, staff.length + 1)" :key="staffMember.id">
                                                <td class="pl-0 secondary text-h6 font-weight-normal white--text">
                                                    {{ staffMember.name}}
                                                </td>
                                                <td class="secondary text-h6 font-weight-normal white--text">
                                                    {{ staffMember.auditTarget}}
                                                </td>
                                                <td class="secondary text-h6 font-weight-normal white--text">
                                                    {{ staffMember.auditsCompleted}}
                                                </td>
                                            </tr>
                                            
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>  -->
                                            
                        </v-row>    

                    </v-card>
                </v-col>
                <!-- <v-col class="fill-height pa-0 pt-3 pr-4" cols="4" style="height: 50% !important">
                    <v-card color="primary" flat class="fill-height" >
                        <v-row class="align-start px-4 pb-0">
                            <v-col cols="8" class="pb-0">
                                <div v-if="$vuetify.breakpoint.xlOnly" class="white--text text-h5 align-start font-weight-regular">TRAINING PROGRAMME</div>
                                <div v-else class="white--text pt-0 text-h6 align-end font-weight-regular">TRAINING PROGRAMME</div>
                            </v-col>
                        </v-row>
                        <v-row class="pr-3 pl-1 pt-0 d-flex justify-start align-center">
                            <pie-chart :seriesdata="userTraining" :color="$vuetify.theme.themes.light.primary" seriestext="Training" />
                        </v-row>    

                    </v-card>
                </v-col> -->

            </v-row>
		
        </v-col>

    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import PieChart from "@/components/echarts/PieChart.vue";
export default {
    name: 'Services',
    components: {
        PieChart: PieChart,
    },
    data: () => ({
        today: '',
        todayTime: '',

        dashboardData: [],
        model: '',
        staff: [],
        userTraining: [],

        interval: null, 
        timeInterval: null,
    }),
    computed: {
        ...mapGetters({
            // GET_FIREBASE_userAuth: 'GET_FIREBASE_userAuth',
        }),
    },
    methods: {

        getNow: function() {
            this.today = moment().tz('Europe/London').format('ddd DD MMM YYYY')
            this.todayTime = moment().tz('Europe/London').format('HH:mm')
        },

        async getStaffAudits() {
            var t = this

			let result = await t.REDIS_getStaffAudits();

            // console.log('result = ' + JSON.stringify(result, null, 2))

			if (result.success) {
				var items = result.data; 
                t.staff = items
            }
        },

        async getTraining() {
            var t = this; 

            let result = await t.REDIS_getStaffTraining() 

			if (result.success) {
				var items = result.data; 
                // console.log('items = ' + JSON.stringify(items, null, 2))
                let arrResult = Object.keys(items).map(k => ({
                    name: k,
                    value: items[k]
                }));
                t.userTraining = arrResult
                
            }
        }
    },
	watch: {
	},
    mounted() {
        this.getNow()

        this.getStaffAudits()
        // this.getTraining()
		
        this.timeInterval = setInterval(() => { 
            this.getNow(); 
        }, 1000)
        this.interval = setInterval(() => { 
            // console.log('refreshing target data')
            this.getStaffAudits()
            // this.getTraining()
        }, 60000)
    },
    beforeDestroy() {
        clearInterval(this.interval)
        clearInterval(this.timeInterval)
    },
};
</script>

<style lang="scss" scoped>
.v-window {
    &-x-transition,
    &-x-reverse-transition,
    &-y-transition,
    &-y-reverse-transition {
        &-enter-active,
        &-leave-active {
        transition: 1.1s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
        }
    }
}
</style>