const qr_code_generator_store = {
    namespaced: true, //to be case sencitive
    state: {
        qrcode_generator: {
            value: 'aaa', // the value content of qrcode
            size: 0, //the size of qrcode element.
            // error code correction level - block size is chosen so that no attempt is made at correcting more than 15 errors per block; 
            //this limits the complexity of the decoding algorithm
            level: 'H',  // levels: 'L' - low; 'M' - medium; 'Q' - quartile; 'H' - high
            id: 'qrcode', // id for download option
            renderAs: 'canvas', // generate QRcode as canvas or svg.
            background: '#ffffff', // background color of qrcode
            foreground: '#000000', // foreground color of qrcode
            showValue: false, // display qr code value above qr code
            showPopup: false, // display qr code dialog,
            locationName: '',
        }
    },
    mutations:{
        MUT_setQrCode(state,data){
            state.qrcode_generator = data;
        },
    },
    getters:{
        GET_getQrCode(state){
            return state.qrcode_generator;
        }
    },
    actions: {
        ACT_qr_code_generator({commit}, payload){
            commit('MUT_setQrCode', payload)
        }
    }
};
export default qr_code_generator_store;