<template>
    <v-row fluid fill-height class="d-flex flex-column align-start appbackground" style="height: 100% !important" no-gutters>
    <!-- <v-container fluid fill-height class="d-flex flex-column align-start appbackground pa-0 ma-0"> -->
		<v-col class="align-center pa-3 mt-1" cols="12">
            
			<div class="d-flex flex-column align-start mb-1">
                <div style="width: 100% !important" class="d-flex align-center">
                    <div><v-icon class="primary--text" x-large>icons8-broom</v-icon></div>
                    <div class="text-left primary--text pl-1 text-h4 font-weight-thin"><span v-if="rota">Rota</span><span v-else>Jobs</span></div>
                    <v-btn v-if="!rota" @click="showInfo = !showInfo" class="secondary--text" icon><v-icon>icons8-help</v-icon></v-btn>

                    <v-spacer />

                    <v-btn v-if="!showFilters && !rota"  class="primary--text appbackground elevation-0 mr-1"  @click.native="openJob()">
                        <v-icon class="icons8-broom"></v-icon>
                        <span >NEW JOB</span>
                    </v-btn>

                    <v-btn v-if="showFilters && !rota" @click="showFilters = !showFilters" class="secondary--text elevation-0 appbackground">
                        <span v-if="!showFilters">Add Filters</span>
                        <span v-if="showFilters">Hide Filters</span>
                    </v-btn>



                </div>
                <div v-if="$vuetify.breakpoint.smAndDown && rota == true" class="text-h8 pa-0 pb-8">
                    View Jobs allocated to you for the next two weeks. Select on a Card to view more information about the job. SWAPP In to be able to start a job.
                </div>
                <div v-if="showInfo" class="body-2 grey--text text--darken-1 font-weight-medium pa-0 pr-2">
                    View Jobs by Card View, select a Job Card to View and Edit the Job. To add Filters, 
                    please select the 'Add Filters' button. If no Filters are added, all Jobs will show 
                    in their respective days in time order.
                </div>
			</div>
            
			<div v-if="showFilters" class="ma-0 mt-2" dense>
                <div class="">
                    <v-select 
                        class="" 
                        hide-details="auto" 
                        label="Service" 
                        filled 
                        v-model="filter.service"  
                        outlined
                        dense
                        item-value="entityId" 
                        clearable
                        :items="services" 
                        background-color="white" 
                        color="primary"
                        :menu-props="{ top: false, offsetY: true }" 
                        :attach="true"
                        item-text="serviceName" 
                    ></v-select>
                </div>
                <div class="mt-1">
                    <v-select 
                        class="" 
                        hide-details="auto" 
                        label="Clients" 
                        filled 
                        item-value="entityId" 
                        v-model="filter.client"  
                        outlined
                        dense
                        clearable
                        :items="clients" 
                        background-color="white" 
                        color="primary"
                        :menu-props="{ top: false, offsetY: true }" 
                        :attach="true"
                        item-text="clientName" 
                    ></v-select>
                </div>
                <div class="mt-1">
                    <v-select 
                        class="" 
                        hide-details="auto" 
                        label="Sites" 
                        filled 
                        v-model="filter.site"  
                        outlined
                        dense
                        clearable
                        :items="sites" 
                        background-color="white" 
                        color="primary"
                        :menu-props="{ top: false, offsetY: true }" 
                        :attach="true"
                        item-text="siteName" 
                        item-value="entityId" 
                    ></v-select>
                </div>
                <div class="mt-1">
                    <v-select 
                        class="" 
                        hide-details="auto" 
                        label="User Category" 
                        filled 
                        v-model="filter.userCategory"  
                        outlined
                        dense
                        clearable
                        :items="userCategories" 
                        background-color="white" 
                        color="primary"
                        :menu-props="{ top: false, offsetY: true }" 
                        :attach="true"
                    ></v-select>
                </div>
                <div class="mt-1">
                    <v-select 
                        class="" 
                        hide-details="auto" 
                        label="Staff Member" 
                        filled 
                        v-model="filter.staffMember"  
                        outlined
                        dense
                        @click:clear="clearFilter('staffMember')"
                        :items="computedUsers" 
                        background-color="white" 
                        color="primary"
                        :menu-props="{ top: false, offsetY: true }" 
                        :attach="true"
                        item-text="userName" 
                        item-value="entityId" 
                    ></v-select>
                </div>
                <div>
                    <v-btn @click="showFilters = !showFilters" class="secondary elevation-0 mt-3 pl-2" width="100%" medium>
                        <span>Apply</span>
                        <v-icon class="">icons8-forward</v-icon>
                    </v-btn>
                </div>


            </div>

            <div v-if="!showFilters && !rota" class="mt-1 mb-1 pl-1" dense>
                <div style="width: 100% important" class="pt-0 d-flex align-end">
                    <div>
                        <v-btn value="add" class="elevation-0 mr-2 mt-1" color="primary" icon @click="priorWeek" medium>
                            <v-icon class="icons8-back"></v-icon>
                        </v-btn>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn 
                            @click="currentWeek" 
                            id="customDisabled" 
                            :disabled="this.currentWeekStartDate === $moment().startOf('week').add(1, 'days').format('DD-MMM-YYYY')"
                            value="add" 
                            class="elevation-0 mr-2 mt-1" 
                            color="appbackground" 
                            medium>
                            <span class="primary--text">CURRENT WEEK</span>
                        </v-btn>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn value="columns" class="elevation-0 mr-2 mt-1" color="primary" icon @click="nextWeek" medium>
                            <v-icon class="icons8-forward"></v-icon>
                        </v-btn>
                    </div>
                </div>
                <div v-if="!rota" style="width: 100% important" class="pt-0 d-flex flex-column align-end">
                    <v-spacer />
                    <v-btn @click="showFilters = !showFilters" class="secondary--text elevation-0 appbackground">
                        <span v-if="!showFilters">Add Filters</span>
                        <span v-if="showFilters">Hide Filters</span>
                    </v-btn>
                </div>
            </div>

            <div v-if="!showFilters" class="pa-0 ma-0">
                <div>
                    <v-btn v-if="nextWeekShowing && rota" value="add" class="elevation-0 mr-2 mt-1" color="primary" icon block @click="priorWeek" medium>
                        <v-icon class="icons8-collapse-arrow"></v-icon>
                    </v-btn>
                </div>
                <v-expansion-panels class="elevation-0 appbackground pa-0 ma-0" >
                    <v-expansion-panel
                        v-for="(day, i) in mobileDays"
                        :key="i"
                        expand
                        v-model="panel[i]"
                        class="elevation-0 appbackground"
                    >
                        <v-expansion-panel-header class="px-0 pb-0 mx-0 d-flex align-start justify-center elevation-0">
                            <div class="body-2 font-weight-bold grey--text">
                                <v-icon 
                                    v-if="rota && ( $moment(day).format('DD-MMM-YYYY') === $moment().format('DD-MMM-YYYY') )" 
                                    class="secondary--text" small>icons8-circle</v-icon> 
                                {{ $moment(day.date).format('ddd DD-MMM') }} - {{ day.total }}
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-0 ma-0" >
                            <div class="px-1 text-center">
                                <job-list :searchQuery="computedSearchQuery" v-on:ready="ready++" v-on:notReady="ready--" :day="day.date" :clients="clients" :sites="sites" :users="users" :services="services" :startLoading="startLoading"/>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <div>
                    <v-btn v-if="!nextWeekShowing && rota" value="columns" class="elevation-0 mr-2 mt-1" color="primary" block icon @click="nextWeek" medium>
                        <v-icon class="icons8-expand-arrow"></v-icon>
                    </v-btn>
                </div>
            </div>
		
        </v-col>

    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import Job from '@/views/jobs/job.vue';
import JobList from '@/views/jobs/jobCardList.vue'
import moment from 'moment'
export default {
    name: 'Services',
    components: {
        'Job': Job,
        'JobList': JobList
    },
    data: () => ({
        editMode: false,
        orgtype: "",
        orgIcon: "",
        search: '',
        show: false,
        tab: '',
        key: 'jobs',
        orgs: [],
        orgDialog: false,
        refreshItems: 0,
        activeItem: {},
        extendWidth: '',
        routeId: '',
        openJobView: false,
		// Item Columns
		itemsCols: { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12,},
		itemCols: { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 },
		taskCols: {	cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2,},
		listExpanded: true,
		listShrunk: false,

        showInfo: false,
        showFilters: false,
        currentWeekStartDate: moment().startOf('week').add(1, 'days').format('DD-MMM-YYYY'),

        filter: {
            staffMember: '',
            userCategory: '',
            status: '',
            site: '',
            service: '',
            client: '',
            publishedStatus: [],
            allocatedStatus: [],
        },
        userCategories: [
            'Mobile Cleaners', 
            'Director', 
            'Commercial Manager', 
            'Office Staff', 
            'Security Staff', 
            'Cleaner', 
            'Supervisor',
            'Senior Manager'
        ],
        users: [],
        services: [],
        sites: [],
        clients: [],

        // panel: [[1], [0], [0], [0], [0], [0], [0]]
        panel: [[true], [false], [false], [false], [true]],

        rota: false,
        ready: 0,
        mobileDays: [],

        startLoading: false,

        nextWeekShowing: false

    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
        computedUsers() {
            var t = this;
            var users = t.users
            var userCategory = ''
            if (this.filter.userCategory !== "" && this.filter.userCategory !== null && this.filter.userCategory !== undefined) {
                userCategory = this.filter.userCategory.replace(/\s/g, '').toUpperCase()
                //console.log('userCategory = ' + userCategory)
                users = users.filter(function(item) {
                    return item.userCategory == userCategory
                })
            }

            return users 
        },
        days() {
            return [
                moment(this.currentWeekStartDate).format('DD-MMM-YYYY'),
                moment(this.currentWeekStartDate).add(1, 'days').format('DD-MMM-YYYY'),
                moment(this.currentWeekStartDate).add(2, 'days').format('DD-MMM-YYYY'),
                moment(this.currentWeekStartDate).add(3, 'days').format('DD-MMM-YYYY'),
                moment(this.currentWeekStartDate).add(4, 'days').format('DD-MMM-YYYY'),
                moment(this.currentWeekStartDate).add(5, 'days').format('DD-MMM-YYYY'),
                moment(this.currentWeekStartDate).add(6, 'days').format('DD-MMM-YYYY'),
            ]
        },
        computedSearchQuery() {
            var t = this
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                    searchQuery = '@deleted:{false}';
            } else {
                searchQuery = `${searchQuery}  @deleted:{false}`;
            }
            // searchQuery = `${searchQuery} @jobAssignedStatus:{${t.GET_FIREBASE_currentUser.entityId}} @jobPublishedStatus:{PUBLISHED}`

            if (this.filter.client !== "" && this.filter.client !== null && this.filter.client !== undefined) {
                searchQuery = `${searchQuery} @jobClientId:{${this.filter.client}}`
            }

            if (this.filter.service !== "" && this.filter.service !== null && this.filter.service !== undefined) {
                searchQuery = `${searchQuery} @jobServiceId:{${this.filter.service}}`
            }

            if (this.filter.userCategory !== "" && this.filter.userCategory !== null && this.filter.userCategory !== undefined) {
                var users = []
                this.computedUsers.forEach((item) => {
                    users.push(item.entityId)
                })
                //console.log('users = ' + JSON.stringify(users, null, 2))
                searchQuery = `${searchQuery} @jobAssignedUserId:{${users.join("|")}}`
            }

            if (this.filter.site !== "" && this.filter.site !== null && this.filter.site !== undefined) {
                searchQuery = `${searchQuery} @jobSiteId:{${this.filter.site}}`
            }

            if (this.filter.staffMember !== "" && this.filter.staffMember !== null && this.filter.staffMember !== undefined) {
                searchQuery = `${searchQuery} @jobAssignedUserId:{${this.filter.staffMember}}`
            }

            if (this.rota == true) {
                searchQuery = `${searchQuery} @jobAssignedUserId:{${this.GET_FIREBASE_currentUser.entityId}} @jobPublishedStatus:{PUBLISHED}`
            }

            // console.log(searchQuery);

            return searchQuery;
        },
    },
    methods: {
        async computedDays() {
            var t = this; 

            var days = t.days 

            var array = []

            // days.forEach(function(day) {
            for ( let i=0; i < 7; i++ ) {
                var day = days[i]
                var total = await t.getJobTotal(day) 
                var item = {}
                item.total = total
                item.date = day
                array.push(item)
            }

            // console.log('days  = ' + JSON.stringify(array, null, 2))

            t.mobileDays = array
        },
        async getJobTotal(date) {
            var t = this;

            var totalFig = ''

            var dayStart = t.$moment(date).startOf('day').format('X')
            var dayEnd = t.$moment(date).endOf('day').format('X')
            // console.log('computedSearchQuery = ' + t.computedSearchQuery)
            var computedSearchQuery = t.computedSearchQuery
            var searchQuery = computedSearchQuery + ` @jobStartDateTime:[${dayStart} ${dayEnd}]`

            if (this.rota == true) {
                searchQuery = searchQuery + `@jobAssignedUserId:{${t.GET_FIREBASE_currentUser.entityId}} @jobPublishedStatus:{PUBLISHED}`
            }

            let total = await this.REDIS_count(t.key, searchQuery)
            
            if (total.success) {
                totalFig = total.total
            }

            return totalFig
        },

        async getStats(date) {
            var t = this;
            var weekStart = t.$moment(date).startOf('week').add(1, 'days').format('X')
            var weekEnd = t.$moment(date).startOf('week').add(8, 'days').format('X')

            let total = await this.REDIS_count(t.key, `@deleted:{false} @jobStartDateTime:[${weekStart} ${weekEnd}]`)
            if (total.success) {
                t.computedStats.total = total.total
            }
        },
		openItem(item) {
			// this.activeItem = item;
			// this.listExpanded = false;
		},
        openJob(item) {
            this.MIX_go('job/new')
            // this.activeItem = item
            // this.listExpanded = false 
            // this.openJobView = true
            // this.refreshCols()
        },
        openRecurringJob(item) {
            this.MIX_go('recurringJob/new')

        },
		closeItem() {
			this.listExpanded = true;
			this.listShrunk = false;
		},
        addDays(date, days) {
            let newDate = moment(date).add(days, 'days').format('DD-MMM-YYYY');
            return newDate;
        },
        subtractDays(date, days) {
            let newDate = moment(date).subtract(days, 'days').format('DD-MMM-YYYY');
            return newDate;
        },
        nextWeek() {
            this.currentWeekStartDate = this.addDays(this.currentWeekStartDate, 7);
            this.ready = 0
            this.computedDays()
            this.nextWeekShowing = true
        },
        priorWeek() {
            this.currentWeekStartDate = this.subtractDays(this.currentWeekStartDate, 7);
            this.ready = 0
            this.computedDays()
            this.nextWeekShowing = false
        },
        currentWeek() {
            this.currentWeekStartDate = moment().startOf('week').add(1, 'days').format('DD-MMM-YYYY');
        },
    },
	watch: {
        computedSearchQuery: {
            handler: function () {
                this.computedDays()
            },
            deep: true
        },
	},
    async mounted() {
        var t = this;

        var today = moment().startOf('day').format('ddd')
        if (today == 'Sun') {
            this.currentWeekStartDate = moment().startOf('week').add(1, 'days').subtract(7, 'days').format('DD-MMM-YYYY');
        }

        var routeId = this.$route.name;
		// console.log('routeId = ' + JSON.stringify(routeId, null, 2))
        this.startLoading = true

        if (routeId == 'Rota') {
            this.rota = true
        } else {
            this.rota = false
        }
        t.computedDays()


        let users = await t.REDIS_searchFor('user', '0', '999', 'userName', 'ASC', '@deleted:{false} @userStatus:{APPROVED}');
        if (users.success) { t.users = users.data.documents; }

        t.users = t.users.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
                userCategory: x.userCategory
            };
        })


        let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false} -@clientStatus:{ARCHIVE}');
        if (clients.success) { t.clients = clients.data.documents; }

        t.clients = t.clients.map((x) => {
            return {
                entityId: x.entityId,
                clientName: x.clientName,
            };
        })

        
        let services = await t.REDIS_searchFor('service', '0', '9999', 'serviceName', 'ASC', '@deleted:{false}');
        if (services.success) { t.services = services.data.documents; }

        t.services = t.services.map((x) => {
            return {
                entityId: x.entityId,
                serviceName: x.serviceName,
            };
        })

        let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
        if (sites.success) { t.sites = sites.data.documents; }

        t.sites = t.sites.map((x) => {
            return {
                entityId: x.entityId,
                siteName: x.siteName,
            };
        })




    }
};
</script>

<style scoped>

#customDisabled.v-btn--disabled {
    background-color: #e5e5e5 !important;
    opacity: 0.7 !important;
}
.v-expansion-panel-content__wrap {
    padding: 0 0px 0px !important;
}
.v-expansion-panel::before {
    box-shadow: none;
}
.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
    padding: 0 0px 0px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    flex: 1 1 auto;
    max-width: 100%;
}

</style>