<template>
    <div class="d-flex align-center">
        <div><v-icon v-if="icon !== ''" :class="iconclass">{{ icon }}</v-icon></div>
        <div :class="dynamicClass"><slot></slot></div>
    </div>
</template>

<script>
export default {
    props: {
        category: {
            type: String,
            default: '', 
            required: false
        },
        icon: {
            type: String,
            default: '',
            required: false
        },
        iconclass: {
            type: String,
            default: '',
            required: false
        }
    },
    computed: {
        dynamicClass: function() {
            switch (this.category) {
                case 'content-title':
                    return 'content-title'
                    break;
                case 'content-help':
                    return 'content-help primary--text'
                    break;
                case 'tab-title':
                    return 'tab-title grey--text text--darken-3'
                    break;
                case 'tab-text':
                    return 'tab-text grey--text text--darken-3'
                    break;
                case 'popup-title':
                    return 'popup-title white--text'
                    break;
                case 'popup-text':
                    return 'popup-text grey--text text--darken-3'
                    break;
                case 'dialog-title':
                    return 'dialog-title white--text'
                    break;
                case 'page-title':
                    return 'page-title'
                    break;
                case 'stats-error':
                    return 'stats-error error--text font-weight-black'
                    break;
                case 'stats-secondary':
                    return 'stats-secondary secondary--text font-weight-black'
                    break;
                case 'stats-warning':
                    return 'stats-warning warning--text font-weight-black'
                    break;
                case 'stats-grey':
                    return 'stats-grey grey--text font-weight-black'
                    break;
                case 'bulkStats-grey':
                    return 'stats-grey grey--text font-weight-black'
                    break;
                case 'stats-secondary':
                    return 'stats-grey secondary--text font-weight-black'
                    break;
                case 'stats-darkgrey':
                    return 'stats-grey darkgrey--text font-weight-black'
                    break;
                case 'stats-success':
                    return 'stats-success success--text font-weight-black'
                    break;
                case 'stats-total':
                    return 'stats-total font-weight-black'
                    break;
                case 'text-medium':
                    return 'text-medium'
                    break;
                case 'text-medium-bold':
                    return 'text-medium-bold'
                    break;
				case 'text-default-bold':
					return 'text-default-bold'
					break;
                default:
                    return '';
                    break;
            }
        }
    }
}
</script>

<style lang="sass">
  @import '~vuetify/src/styles/styles.sass'

  @media #{map-get($display-breakpoints, 'md-and-up')}
    .content-help
        font-size: 18px   
        font-weight: thin 
    
    .text-default-bold
        font-size: 16px
        font-weight: bold
    
    .tab-title
        font-size: 16px   
        font-weight: bold  
    
    .tab-text
        font-size: 16px   
        font-weight: normal  
    
    .popup-title
        font-size: 16px   
        font-weight: normal  
    
    .popup-text
        font-size: 16px   
        font-weight: normal  
    
    .content-help
        font-size: 18px   
        font-weight: thin  
    
    .dialog-title
        font-size: 18px   
        font-weight: thin  

    .page-title
        font-size: 26px
        max-width: 600px

    .stats-error
        font-size: 32px
        max-width: 400px

    .stats-secondary
        font-size: 32px
        max-width: 400px

    .stats-grey
        font-size: 32px
        max-width: 400px

    .bulkStats-grey
        font-size: 80px
        max-width: 400px

    .stats-warning
        font-size: 40px
        max-width: 400px

    .stats-success
        font-size: 32px
        max-width: 400px
    
    .stats-total
        font-size: 40px
        max-width: 400px

    .text-medium
        font-size: 20px

    .text-medium-bold
        font-size: 20px
        font-weight: bold

 
  @media #{map-get($display-breakpoints, 'sm-and-down')}
    .content-title
        font-size: 20px
        max-width: 200px
    .page-title
        font-size: 28px
        max-width: 600px
    .tab-title
        font-size: 16px   
        font-weight: bold  
    
    .tab-text
        font-size: 16px   
        font-weight: normal  
    
</style>

