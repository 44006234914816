<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: hidden !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <v-spacer />
                <!-- <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" >
                    <v-btn text class="primary white--text" @click="locationKey++" ><v-icon class="mr-2">icons8-save</v-icon>Refresh</v-btn>
                </div> -->
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" >
                    <v-btn text class="primary white--text" @click="locationDialog = true" >Add</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="apptext--text pa-4 pt-2" dense style="z-index: 0 !important">

            <!-- <div class="font-weight-medium">Below is a list of Sites linked to the Client. To add a new Site click Add and follow the instructions. To edit or delete an existing Site, select the appropriate button from the Actions column.</div> -->

            <LocationsList :site="item" v-on:openItem="openItem" :refreshitems="refreshitems" :key="locationKey"/>

        </v-row>


        <v-dialog persistent v-model="locationDialog" width="600px" height="400px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 secondary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mr-3 mb-1">
                        <v-icon class="white--text">icons8-future</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Add Location</strong>
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="locationDialog = !locationDialog" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 pa-0">               
                    <v-form ref="form" lazy-validation>
                        <v-row class="pa-2 ma-0 justify-center d-flex" fill-height dense>
                            <v-col cols="12" xs="12">
                                <v-text-field 
                                    label="Location Name"
                                    v-model="location.locationName" 
                                    :rules="rules.required"
                                    outlined 
                                    dense 
                                    hide-details="auto" 
                                    background-color="white" 
                                    color="primary" 
                                    clearable 
                                    @click:clear="clearItem('locationName')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12">
                                <v-text-field 
                                    label="Location Description" 
                                    v-model="location.locationDescription" 
                                    outlined 
                                    dense 
                                    hide-details="auto" 
                                    background-color="white" 
                                    color="primary" 
                                    clearable 
                                    @click:clear="clearItem('locationDescription')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12">
                                <v-autocomplete 
                                    label="Location Site" 
                                    v-model="selectedSite" 
                                    :rules="rules.siteAreas" 
                                    required 
                                    :items="sites" 
                                    :menu-props="{ top: false, offsetY: true }" 
                                    :attach="true"
                                    auto-select-first 
                                    return-object 
                                    dense 
                                    item-text="siteName"
                                    outlined
                                    hide-details="auto" 
                                    background-color="white" 
                                    color="primary"
                                    class=""
                                    disabled>
                                    <!-- <template v-slot:item="data"> {{ data.item.areaName }} </template>
                                    <template v-slot:selection="data"> {{ data.item.areaName }} </template>    -->
                                </v-autocomplete> 
                            </v-col>
                        </v-row>
                    </v-form>   
                    <!-- <pre>{{ location }}</pre> -->

                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-0">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="locationDialog = !locationDialog"><span>Cancel</span> </v-btn>
                            <v-btn class="primary white--text font-weight-bold ml-2" depressed @click="validateItem"><span>Save</span> </v-btn>
                        </v-col>
                    </v-col>
                </v-card-actions>
            </div>
        </v-dialog>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
import schema from '@/schema.js';
import LocationsList from '@/views/sites/locationList.vue'
import { mapGetters } from "vuex";

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        // activeitem: {
        //     Type: Object,
        //     Default: null,
        // },
    },
    components: {
        'LocationsList': LocationsList
    },
    // * DATA
    data: () => ({
        key: 'action',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,
        // dropdowns
        packageTypes: [],
        billingCycles: [],
        packageStatus: [],
        selectedSite: {
            entityId: '',
            siteName: ''
        },  
        months: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],

        // rules
        packageCustomerDescriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],
        packageInternalDescriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],

        icons: [
            {
                name: 'On Demand',
                icon: 'oomph-video-playlist'
            },
            {
                name: 'Training',
                icon: 'oomph-literature'
            },
            {
                name: 'Exercises',
                icon: 'oomph-dumbbell'
            },
            {
                name: 'Activities',
                icon: 'oomph-list'
            },
            {
                name: 'Assessment',
                icon: 'oomph-clipboard-list'
            },
            {
                name: 'Wellbeing',
                icon: 'oomph-lotus'
            }
        ],

        sites: [],

        // new address object
        item: {},
        loading: false,
        rules: {},

        location: {},

        locationDialog: false,

        refreshitems: 0,
        locationKey: 0

    }),
    computed: {  
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
    },
    methods: {
        validateItem() {
            let t = this; 

            t.rules = {
				required: [(v) => !!v || "Required"],
            }; 
            t.$nextTick(() => {
                if (t.$refs.form.validate()) {
                    t.saveLocation()
                }
            })
        },
        async saveLocation() { 
			let t = this;

            var item = t.location
            var dateTimeNow = t.$moment().toISOString()
            item.createdDateTime = dateTimeNow;
            item.createdUserId = t.GET_FIREBASE_currentUser.entityId;
            item.locationSiteId = this.item.entityId
            
            let result = await this.REDIS_create('location', item);
            
			if (result.success) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
				// t.readItem(item.entityId);
                t.locationDialog = false
                t.location = {...t.schema}
				t.refreshitems++

			}
        },
        openItem() {

        },
        initialise() {
            this.item = { ...this.datadown };
            this.itemNew = false;
            this.editmode = false;
        },
        updateItem() {
            this.$emit('update', this.item);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {
            this.$emit('save', this.item);
            this.editmode = false;
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        // activeitem: {
        //     handler: function () {
        //         let t = this;
        //         t.item = { ...t.activeitem };
        //     }, deep: true
        // },
        selectedPackageIcon: {
            handler: function () {
                this.item.packageIcon = this.selectedPackageIcon.icon;
            }, deep: true
        },

    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.location;

        t.location = {...t.schema}
        // t.loading = true
        t.initialise();
        
        let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
        if (sites.success) { t.sites = sites.data.documents; }

        t.sites = t.sites.map((x) => {
            return {
                entityId: x.entityId,
                siteName: x.siteName,
            };
        })

        t.selectedSite = {
            entityId: t.item.entityId,
            siteName: t.item.siteName
        }


    },
    mounted() {},
};
</script>