<template>
    <v-row justify="center" class="my-0 py-0 mt-0">
        <v-col cols="12" xs="12" sm="8" md="6" class="pt-0 mt-0"> 
            <v-card flat>
                <qrcode-stream @decode="onDecode" @init="onInit" />
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
// TODO - detect browser and device and show message
export default {
    components: { QrcodeStream },
    name: "QrCodeReader",
    methods: {
        onDecode(result) {
            this.MIX_decodeResult(result);
        },
        async onInit(promise) {
            try {
                await promise;
            } catch (error) {
                this.MIX_onInitError(error);
            }
        },
    }
}
</script>