<template>
    <div class="" style="width: 100% !important">
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field
                    label="Search Location History"
                    v-model="searchQuery"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    color="primary"
                    clearable
                    prepend-inner-icon="icons8-search"
                    @click:clear="clearSearchQuery()"
                ></v-text-field>
            </div>
            <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="refreshItems">
                <v-icon>icons8-available-updates</v-icon>
            </v-btn>
        </div>
        <v-row class="pa-3 mt-1">
            <v-data-table
                :item-class="itemRowBackground"
                sortable
                dense
                class=""
                style="width: 100%"
                :headers="computedHeaders"
                :items="items"
                :server-items-length="itemsTotal"
                :options.sync="datatableOptions"
                :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50]
                }"
            >
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-spacer />
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <!-- <v-btn title="Permanently Delete" value="destroy" small>
                                <v-icon class="error--text">icons8-delete-trash</v-icon>
                            </v-btn> -->
                            <!-- <v-btn title="Delete" value="delete" small>
                                <v-icon class="grey--text text--darken-3">icons8-trash-can</v-icon>
                            </v-btn> -->
                            <!-- <v-btn title="Undelete" value="undelete" small>
                                <v-icon class="grey--text text--darken-3">icons8-trash-restore</v-icon>
                            </v-btn> -->
                        </v-btn-toggle>
                    </div>
                </template>
                <template v-slot:[`item.swappLocationId`]="{ item }">
					<span>{{ getLocation(item.swappLocationId) }}</span>
				</template>
                <template v-slot:[`item.swappSiteId`]="{ item }">
					<span>{{ getSite(item.swappSiteId) }}</span>
				</template>
                <template v-slot:[`item.createdDateTime`]="{ item }">
					<span>{{ $moment(item.createdDateTime).format("DD-MMM-YY HH:mm") }}</span>
				</template>
				<template v-slot:[`item.swappMethod`]="{ item }">
					<span>{{ MIX_capitalizeText(item.swappMethod) }}</span>
				</template>
				<template v-slot:[`item.swappStatus`]="{ item }">
					<v-chip class="success" small v-if="item.swappStatus === '1'">IN</v-chip>
					<v-chip class="lightgrey" small v-else>OUT</v-chip>
				</template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-icon class="error--text pa-1" v-if="item.deleted === '1' && toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">icons8-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.entityId)" v-if="item.deleted === false && toggleFeatures.includes('delete')">icons8-trash-can</v-icon>
                    <!-- <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === true && toggleFeatures.includes('undelete')">icons8-trash-restore</v-icon> -->
                    <!-- <v-icon class="primary--text ml-5" @click="openItem(item)">icons8-forward</v-icon> -->
                </template>
            </v-data-table>
        </v-row>
        
    </div>
</template>

<script>
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        type: '',
        status: '',
        key: 'swapp-log',
        index: 'idx:courses',
        service: {},
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
			{ text: "Status", value: "swappStatus", align: "center", sortable: false, hidden: false, expanded: false },
			{ text: "Site", value: "swappSiteId", align: "left", sortable: false, hidden: false, expanded: false },
			{ text: "Location", value: "swappLocationId", align: "left", sortable: false, hidden: false, expanded: false },
			{ text: "Method", value: "swappMethod", align: "center", sortable: false, hidden: false, expanded: false },
			{ text: "Swapped at", value: "createdDateTime", align: "center", sortable: false, hidden: false, expanded: false },


            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false },
        ],
        // Fields to Retrieve from Database
        // fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        sortBy: '@courseName',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['courseName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        // productCategories: ['Chair', 'Table', 'Sofa', 'Bed', 'Wardrobe', 'Cabinet', 'Dresser', 'Other'],
        filter: {
            productCategories: [],
        },

        sites: [],
        locations: [],
    }),
    computed: {
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '';
                } else {
                    searchQuery = '@deleted:{false}';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:{false}`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:{false}`;
                    }
                }
            }
            console.log('datadown = ' + JSON.stringify(this.datadown, null, 2))

            searchQuery = `${searchQuery} @swappUserId:{${this.datadown.entityId}}`

            console.log(searchQuery);

            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (this.listshrunk) {
                headers = headers.filter((header) => header.expanded === false || header.expanded === true); // Return Headers based on Expanded State
            } else {
                headers = headers.filter((header) => header.expanded === false); // Return Headers based on Expanded State
            }
            return headers;
        },
        computedItems() {
            var items = this.items;
            if (this.type) {
                items = this.items.filter((item) => item.actionType === this.type);
            }
            if (this.status) {
                items = this.items.filter((item) => item.actionStatus === this.status);
            }
            return items;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            // if (!computedFields.includes('@actionId')) {
            //     computedFields.push('@actionId');
            // }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedType() {
            const types = [];
            this.items.filter((item) => {
                return types.push(item.actionType);
            });
            return types;
        },
        computedStatus() {
            const status = [];
            this.items.filter((item) => {
                return status.push(item.actionStatus);
            });
            return status;
        },
    },
    methods: {
        getSite(siteId) {
            var t = this;
            var site = []
            var siteName = ''

            site = t.sites.filter((item) => { return item.entityId == siteId })

            if (site.length !== 0) { siteName = site[0].siteName }

            return siteName
        },
        getLocation(locationId) {
            var t = this;
            var location = []
            var locationName = ''

            location = t.locations.filter((item) => { return item.entityId == locationId })

            if (location.length !== 0) { locationName = location[0].locationName }

            return locationName
        },
        async editItem(id) {
            // Get the item from the database
            let itemResult = await this.MIX_readItem(id, 'service');
            if (itemResult.code === 1) {
                this.$emit('edit', itemResult.data);
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Editing Component', show: true });
            }
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        refreshItems: function () {
            // this.getItems();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        async createProgress(jobId, type, status) {
            let t = this;
            let progress = {}
            
            progress.linkedId = jobId
            progress.type = type
            progress.status = status
            progress.totalItems = 1
            progress.count = 1
            progress.success = 1
            progress.error = 0
            progress.createdDateTime = t.$moment().toISOString()
            progress.createdUserId = t.GET_FIREBASE_currentUser.entityId
            console.log('progress = ' + JSON.stringify(progress, null, 2))
            await this.REDIS_create('progress', progress);
        },  
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            var deleteData = {}

            deleteData.deletedUserId = this.GET_FIREBASE_currentUser.entityId
            deleteData.deletedDateTime = this.$moment().format('X')

            console.log('deleteData: ', JSON.stringify(deleteData, null, 2))

			let result = await this.REDIS_delete(this.key, itemId, deleteData);
            console.log('result: ', JSON.stringify(result, null, 2))
			if (result.success) {
                t.createProgress(result.data.swappUserId, 'Delete User Location History' , 'Completed')
                this.getItems()
			}
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        // * GET ITEMS
        async getItems() {
            var t = this
            let itemsResult = await this.REDIS_searchFor('swapp-log', this.itemsFrom, this.datatableOptions.itemsPerPage, 'createdDateTime', 'desc', this.computedSearchQuery );
            if (itemsResult.success) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.documents;

                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                console.log('items = ' + JSON.stringify(this.items, null, 2))
                t.loading = false

            }
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.getItems()
            },
            deep: true,
        },
        refreshitems() {
            this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        computedFields() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        var t = this
        this.service = { ...this.datadown };
        let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
        if (sites.success) { t.sites = sites.data.documents; }

        t.sites = t.sites.map((x) => {
            return {
                entityId: x.entityId,
                siteName: x.siteName,
            };
        })
        let locations = await t.REDIS_searchFor('location', '0', '9999', 'locationName', 'ASC', '@deleted:{false}');
        if (locations.success) { t.locations = locations.data.documents; }

        t.locations = t.locations.map((x) => {
            return {
                entityId: x.entityId,
                locationName: x.locationName,
            };
        })
        this.getItems();
    },
};
</script>