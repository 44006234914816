<template>
	<v-card flat outlined :style="cardStyle" class="pa-0 ma-0"  >
        <v-col cols="12" class="align-start pb-0">
		    <div class="">
                <div class="d-flex align-start">

                    <div class="d-flex flex-column">
                        <div class="d-flex font-weight-bold body-2 text-start primary--text">
                            {{ task.taskTitle }}  
                        </div>
                        <div class="font-weight-regular body-2 text-start grey--text">
                            <span v-if="task.taskType == 'User'"> {{ task.taskUserName }}</span>
                            <span v-if="task.taskType == 'Client'"> {{ task.taskClientName }}</span>
                            <span v-if="task.taskType == 'Site'"> {{ task.taskSiteName }}</span>
                        </div>
                    </div>
                    <v-spacer />
                    <v-icon v-if="task.taskPriority == 'Urgent' && task.taskStatus !== 'COMPLETED'" class="icons8-high-importance" :class="priorityClass" size="25" />
                    <v-icon v-else-if="task.taskPriority !== 'Low' && task.taskStatus !== 'COMPLETED'" class="icons8-filled-circle" :class="priorityClass" size="25" />
                </div>
                <div class="font-weight-bold body-2 text-start grey--text d-flex pt-2">
                    <v-icon class="icons8-person-calendar" size="20" />
                    <span>: {{ task.taskAssignedUserName }}</span>
                    <v-spacer />
                    <span :class="dueText">{{ $moment(task.taskDueDateTime).format('DD MMM YYYY ') }}</span>

                </div>
                <div class="d-flex justify-end">
                    <v-btn
                        icon
                        @click="show = !show"
                    >
                        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                </div>
		    	<!-- <div v-if="task.createdDateTime" class="font-weight-bold body-2 text-start grey--text">
		    		Created: <span class="font-weight-regular">{{ $moment(task.createdDateTime).format('HH:ss, DD MMM YYYY ') }}  </span>
		    	</div> -->
                <!-- <pre>{{ task }}</pre> -->
		    </div>
        </v-col>
        <v-expand-transition>
            <div v-show="show">
                <v-divider></v-divider>

                <v-card-text>
                    <div class="d-flex align-start grey--text font-weight-bold">Created: {{ $moment(task.createdDateTime).format('HH:ss, DD MMM YYYY ') }}</div>
                    <div class="d-flex align-start grey--text font-weight-bold" v-if="task.modifiedDateTime">Last Modified: {{ $moment(task.modifiedDateTime).format('HH:ss, DD MMM YYYY ') }}</div>
                    <div class="d-flex align-start grey--text font-weight-bold" v-if="task.taskAddDate">Date Added for Staff Member: {{ $moment(task.taskAddDate).format('DD MMM YYYY ') }}</div>
                    <!-- <div v-html="task.taskNotesHTML"></div> -->
                    <div class="d-flex justify-end grey--text font-weight-bold">
                        <v-btn v-if="GET_FIREBASE_currentUser.userCategory == 'DIRECTOR' || GET_FIREBASE_currentUser.userCategory == 'SENIORMANAGER' || GET_FIREBASE_currentUser.userLevel == 'DA'" class="secondary elevation-0 mr-2" @click="deleteItem">
                            <span>Delete</span>
                        </v-btn>
                        <v-btn class="elevation-0" @click="openItem">
                            <span v-if="GET_FIREBASE_currentUser.userCategory == 'DIRECTOR'">Edit</span>
                            <span v-else>View</span>
                        </v-btn>
                    </div>
                </v-card-text>
            </div>
        </v-expand-transition>
	</v-card>
</template>

<script>
    import { mapActions } from 'vuex'; // Map the Getters from the Vuex Store
    import { mapGetters } from 'vuex';
	export default {
        data: () => ({
            reveal: false,
            publishedDialog: false,
            show: false,
        }),
		props: {
            task: { type: Object, default: '-'},
		},
        computed: {
            ...mapGetters({
                GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
            }),
            cardStyle() {
                console.log('cardStyle')
                var today = this.$moment().format('X');
                console.log('today = ' + today)
                console.log('taskAddDate = ' + this.task.taskAddDate)
                if (this.task.taskStatus == 'OVERDUE') {
                    return 'border-left: 8px solid #E10203'
                } 
                else if (this.task.taskStatus == 'COMPLETED') {
                    return 'border-left: 8px solid #21942a'
                } 
                else if (this.task.taskAddDate > today) {
                    return 'background: repeating-linear-gradient(45deg, #ffffff, #ffffff 6px, #f8f8f8 2px, #f8f8f8 8px )'
                }
                // else if (this.task.taskStatus == 'INPROGRESS') {
                //     return 'yellow lighten-5';
                // } 
                // else if (this.task.taskStatus == 'NEW') {
                //     return 'blue lighten-5';
                // }
            },
            dueText() {
                // check if due date is less than a day away

                if (this.task.taskStatus !== 'COMPLETED' && (this.$moment(this.task.taskDueDateTime).isBefore(this.$moment()) || this.$moment(this.task.taskDueDateTime).format('X') < this.$moment().endOf('day').add(1, 'days').format('X'))) {
                    return 'red--text'
                } 
                else {
                    return 'grey--text'
                }
            },
            priorityClass() {
                if (this.task.taskPriority == 'Urgent') {
                    return 'red--text'
                } 
                else if (this.task.taskPriority == 'High') {
                    return 'red--text'
                } 
                else if (this.task.taskPriority == 'Medium') {
                    return 'orange--text'
                } 
            },
            dueStyle() {
                if (this.task.taskDueDateTime == 'OVERDUE') {
                    return 'red--text'
                } 
                else if (this.task.taskDueDateTime == 'COMPLETED') {
                    return 'green--text'
                }
            }
        },
        methods: {
            ...mapActions({'ACT_openItem': 'ACT_openJob'}),
            openItem() {
                this.$emit('openItem', this.task)
            },
            deleteItem() {
                this.$emit('deleteItem', this.task)
            }
        }
	};
</script>

<style scoped>
</style>
