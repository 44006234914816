<template>
    <v-list v-if="$vuetify.breakpoint.mdAndUp" nav dense pa-0 ma-0 class="secondary">
        <v-list-item v-for="(item, index) in computedTabs" :key="index" link @click="go(item.path, item.name)" active-class="darkgrey">
            <v-list-item-icon class="white--text">
                <v-icon class="white--text">{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white--text text-left">{{ item.name }}</v-list-item-title>
        </v-list-item>
        <!-- <div v-if="computedPhaseTwoTabs.length > 0" class="white--text font-weight-bold caption">Phase 2</div> -->
        <v-list-item v-for="(item, index) in computedPhaseTwoTabs" :key="index" link @click="go(item.path, item.name)" active-class="darkgrey">
            <v-list-item-icon class="white--text">
                <v-icon class="white--text">{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white--text text-left">{{ item.name }}</v-list-item-title>
        </v-list-item>
    </v-list>
    <v-list v-else-if="$vuetify.breakpoint.smAndDown" nav dense pa-0 ma-0 class="secondary">
        <v-list-item v-for="(item, index) in computedMobileTabs" :key="index" link @click="go(item.path, item.name)" active-class="darkgrey">
            <v-list-item-icon class="white--text">
                <v-icon class="navicons white--text">{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white--text text-left">{{ item.name }}</v-list-item-title>
        </v-list-item>
    </v-list>
</template>

<script>
    import { mapGetters } from 'vuex'; // Map the Getters from the Vuex Store
    export default {
        name: "MenuList",
        data: () => ({
            tabs: [
                // USERS
                { name: 'Home', path: "/home", icon: "icons8-home", userLevels: ['SA','CU', 'DA'] },
                { name: 'Jobs', path: "/jobs", icon: "icons8-broom", userLevels: ['SA', 'DA'] },
                // { name: 'Rota', path: "/rota", icon: "icons8-broom", userLevels: ['CU'] },
                { name: 'Staff Directory', path: "/staffDirectory/:id", icon: "icons8-people", userLevels: ['SA', 'DA'] },
                { name: 'Training Courses', path: "/courses", icon: "icons8-course-assign", userLevels: ['SA', 'DA'] },
                { name: 'Areas', path: "/areas", icon: "icons8-map-marker", userLevels: ['SA', 'DA'] },
                { name: 'Services', path: "/services", icon: "icons8-vacuum-cleaner", userLevels: ['SA', 'DA'] },
                { name: 'Clients', path: "/clients/:id", icon: "icons8-business-building", userLevels: ['SA', 'DA'] },
                { name: 'Sites', path: "/sites/:id?", icon: "icons8-skyscrapers", userLevels: ['SA', 'DA'] },
                { name: 'Progress', path: "/progress", icon: "icons8-progress-indicator", userLevels: ['SA', 'DA'] },
                // { name: 'Task Templates', path: "/tasks", icon: "icons8-list", userLevels: ['SA'] },
                // { name: 'Communications', path: "/communications", icon: "icons8-communication", userLevels: ['SA'] },
                { name: 'Reports', path: "/reports", icon: "icons8-export-csv", userLevels: ['SA', 'DA'] },
                // { name: 'Admin', path: "/admin", icon: "icons8-settings", userLevels: ['SA'] },
                // { name: 'Settings', path: "/settings", icon: "icons8-settings", userLevels: ['SA'] },
            ],
            phaseTwoTabs: [
                { name: 'Communications', path: "/communications/:id?", icon: "icons8-communication", userLevels: ['SA', 'DA'] },
                { name: 'Site Tasks', path: "/siteTasks", icon: "icons8-list", userLevels: ['SA', 'DA'] },
                { name: 'Task Templates', path: "/taskTemplates", icon: "icons8-task-planning", userLevels: ['SA', 'DA'] },
                { name: 'Hotlist Tasks', path: "/tasks", icon: "icons8-task-completed", userLevels: ['SA', 'DA'] },
            ],
            mobileTabs: [
                // USERS
                { name: 'Home', path: "/home", icon: "icons8-home", userLevels: ['SA','CU', 'DA'] },
                { name: 'Jobs', path: "/mobileJobs", icon: "icons8-broom", userLevels: ['SA', 'DA'] },
                // { name: 'Rota', path: "/rota", icon: "icons8-broom", userLevels: ['CU'] },
                { name: 'Staff Directory', path: "/staffDirectory/:id", icon: "icons8-people", userLevels: ['SA', 'DA'] },
                { name: 'Training Courses', path: "/courses", icon: "icons8-course-assign", userLevels: ['SA', 'DA'] },
                { name: 'Areas', path: "/areas", icon: "icons8-map-marker", userLevels: ['SA', 'DA'] },
                { name: 'Services', path: "/services", icon: "icons8-vacuum-cleaner", userLevels: ['SA', 'DA'] },
                { name: 'Clients', path: "/clients/:id", icon: "icons8-business-building", userLevels: ['SA', 'DA'] },
                { name: 'Sites', path: "/sites/:id", icon: "icons8-skyscrapers", userLevels: ['SA', 'DA'] },
                { name: 'Communications', path: "/communications/:id?", icon: "icons8-communication", userLevels: ['SA', 'DA'] },
                { name: 'Site Tasks', path: "/siteTasks", icon: "icons8-list", userLevels: ['SA', 'DA'] },
                // { name: 'Reports', path: "/reports", icon: "icons8-export-csv", userLevels: ['SA'] },
                // { name: 'Admin', path: "/admin", icon: "icons8-export-csv", userLevels: ['SA'] },
                // { name: 'Settings', path: "/settings", icon: "icons8-settings", userLevels: ['SA'] },
            ],
            user: {},
            langPath: 'menuItems'
        }),
        computed: {
            ...mapGetters({
                GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
                GET_mobileNav: 'GET_mobileNav',
                GET_currentUser: 'GET_currentUser'
            }),
            computedTabs() {
                var t = this;
                var tabs = this.tabs;
                var newTabs = []
                tabs.forEach(function(tab) {
                    if (tab.userLevels.includes(t.user.userLevel)) {
                        newTabs.push (tab)
                    }
                })
                return newTabs
            },
            computedPhaseTwoTabs() {
                var t = this;
                var tabs = this.phaseTwoTabs;
                var newTabs = []
                tabs.forEach(function(tab) {
                    if (tab.userLevels.includes(t.user.userLevel)) {
                        newTabs.push (tab)
                    }
                })
                return newTabs
            },
            computedMobileTabs() {
                var t = this;
                var tabs = this.mobileTabs;
                var newTabs = []
                tabs.forEach(function(tab) {
                    if (tab.userLevels.includes(t.user.userLevel)) {
                        newTabs.push (tab)
                    }
                })
                return newTabs
            }
    
        },
        methods: {
            // // updateBreadcrumbs(name, path) {
            // //     var t = this;
            // //     var breadcrumbs = [];
            // //     var uperrcaseName = t.MIX_capitalizeText(name);
            // //     breadcrumbs.push ({ name: uperrcaseName, path: path}) // Set the Main Path
            // //     t.MIX_breadcrumbs(breadcrumbs);
            // // },
            go(path, name) {
                this.$router.push(path).catch((err) => {
                    // //console.log("Route error: " + err);
                });
                this.$emit('closeMenu')
                // this.MIX_mobileNav(false);
               //// this.updateBreadcrumbs(name, path);
            },  
        }, 
        async mounted() {
            let t = this;
            var itemId = t.GET_FIREBASE_currentUser.entityId
			let result = await t.REDIS_read('user', itemId);
			if (result.success) {
				this.user = result.data;
			}
        }
    }
</script>

<style scoped>
.navicons {
  font-size: 24px !important;
  font-weight: bold !important;
}

.v-tab--active {
  background-color: var(--v-primary-base) !important;
}
</style>
