<template>
    <v-row fluid fill-height class="d-flex flex-column align-start app-bar-background" style="height: 100% !important" no-gutters>
    <!-- <v-container fluid fill-height class="d-flex flex-column align-start appbackground pa-0 ma-0"> -->
		<v-col class="align-center pa-3 mt-1" cols="12">
            
            <v-row class="align-end">
                <v-col cols="4" class="pl-8 pt-4">
                    <v-img v-if="$vuetify.breakpoint.xlOnly" @click="MIX_go('/home')" width="200" position="left" contain :src="require('@/assets/A&R_Services_FINAL_LOGO.svg')" />
                    <v-img v-else @click="MIX_go('/home')" width="180" position="left" contain :src="require('@/assets/A&R_Services_FINAL_LOGO.svg')" />
                </v-col>
                <v-col cols="8" class=" d-flex flex-column justify-end align-end">
                    <!-- <div> -->
                        <!-- <pre>Interval: {{ interval }}</pre> -->
                        <div class="d-flex">
                            <div v-if="$vuetify.breakpoint.xlOnly" class="text-h4 white--text">{{ today }} <span class="font-weight-bold text-h3"> {{ todayTime }}</span> </div><div v-else class="text-h5 white--text">{{ today }} <span class="font-weight-bold text-h4"> {{ todayTime }}</span></div>
                            <v-icon v-if="$vuetify.breakpoint.xlOnly" size="40" class="ml-2 white--text">icons8-partly-cloudy-day</v-icon><v-icon v-else size="70" class="ml-2 white--text">icons8-partly-cloudy-day</v-icon>
                        </div>
                        <div class="text-body-2 font-weight-bold white--text text-end">v {{ appVersion }}</div>
                    <!-- </div> -->
                </v-col>
            </v-row>
            <v-row class="align-center">
                <v-col cols="12" class="">
                    <v-carousel :cycle="cycle" :interval="interval" height="100%" :show-arrows=false hide-delimiters  class="" v-model="model">
                        <!-- <template v-slot:prev="{ on, attrs }">
                            <v-icon
                                x-large
                                color="white"
                                v-bind="attrs"
                                v-on="on"
                            >icons8-back</v-icon>
                        </template>
                        <template v-slot:next="{ on, attrs }">
                            <v-icon
                                x-large
                                color="white"
                                v-bind="attrs"
                                v-on="on"
                            >icons8-forward</v-icon>
                        </template> -->
                        <v-carousel-item :key="1">
                            <dashboardHours 
                            />
                        </v-carousel-item>
                        <v-carousel-item :key="2">
                            <dashboardTargets 
                            />
                        </v-carousel-item>
                        <v-carousel-item :key="3">
                            <dashboardTraining 
                            />
                        </v-carousel-item>
                    </v-carousel>

                </v-col>
            </v-row>
		
        </v-col>

    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import Job from '@/views/jobs/job.vue';
import JobList from '@/views/jobs/jobList.vue'
import moment from 'moment';
import dashboardHours from "@/views/dashboard/dashboardHours.vue"
import dashboardTraining from "@/views/dashboard/dashboardTraining.vue"
import dashboardTargets from "@/views/dashboard/dashboardTargets.vue"

import { version } from "../../../package.json"
export default {
    name: 'Services',
    components: {
        'Job': Job,
        'JobList': JobList,
        'dashboardHours': dashboardHours,
        'dashboardTraining': dashboardTraining,
        'dashboardTargets': dashboardTargets
    },
    data: () => ({
        appVersion: version,
        today: '',
        todayTime: '',

        dashboardData: [],
        model: 'first',

        timeInterval: null,

        cycle: false,

        interval: 180000
    }),
    computed: {
        ...mapGetters({
            // GET_FIREBASE_userAuth: 'GET_FIREBASE_userAuth',
        }),
    },
    methods: {

        getNow: function() {
            this.today = moment().tz('Europe/London').format('ddd DD MMM YYYY')
            this.todayTime = moment().tz('Europe/London').format('HH:mm')
        },

    },
	watch: {
        model(val) {
            this.cycle = false
            if (val == 0) {
                this.interval = 60000
            } else {
                this.interval = 30000
            }
            this.cycle = true
            console.log('interval', this.interval)
            console.log('val', val)
        }
	},
    mounted() {
        this.getNow()
		
        this.timeInterval = setInterval(() => { 
            this.getNow(); 
        }, 1000)
    },
    beforeDestroy() {
        clearInterval(this.timeInterval)
    },
};
</script>

<style lang="scss" scoped>
.v-window {
    &-x-transition,
    &-x-reverse-transition,
    &-y-transition,
    &-y-reverse-transition {
        &-enter-active,
        &-leave-active {
        transition: 1.1s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
        }
    }
}
</style>