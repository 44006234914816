<template>
    <div class="" style="width: 100% !important">
        <div v-if="exportingCSV">
            <v-overlay color="white" opacity="0.2">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-overlay>
        </div>
        <div class="mt-2 d-flex align-start">
            
            <div class="flex-grow-1 d-flex align-center">
                <v-text-field
                    v-if="user === ''"
                    label="Search"
                    v-model="searchQuery"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    color="primary"
                    clearable
                    prepend-inner-icon="icons8-search"
                    @click:clear="clearSearchQuery()"
                    v-on:keyup.enter="getItems()"
                ></v-text-field>
            </div>
            <v-btn v-if="!userOpen && user === '' && $vuetify.breakpoint.lgAndUp" depressed style=" height: 40px !important" class="ml-2 secondary white--text" @click="getItems">
                <v-icon>icons8-search</v-icon>
            </v-btn>
            <div v-if="!userOpen && user === '' && $vuetify.breakpoint.lgAndUp && listexpanded" class=" ml-2">
                <v-select 
                    class="" 
                    hide-details="auto" 
                    label="Areas" 
                    filled 
                    v-model="filter.areas"  
                    outlined
                    multiple
                    dense
                    clearable
                    :items="areas" 
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="areaName" 
                    item-value="entityId" 
                ></v-select>
            </div>
            <div v-if="!userOpen && user === '' && $vuetify.breakpoint.lgAndUp && listexpanded" class=" ml-2">
                <v-select 
                    class="" 
                    hide-details="auto" 
                    label="User Category" 
                    filled 
                    v-model="filter.userCategory"  
                    outlined
                    dense
                    clearable
                    multiple
                    :items="userCategories" 
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                ></v-select>
            </div>
            <div v-if="!userOpen && user === '' && $vuetify.breakpoint.lgAndUp && listexpanded" class=" ml-2">
                <v-select 
                    class="" 
                    hide-details="auto" 
                    label="Service" 
                    filled 
                    v-model="filter.service"  
                    outlined
                    dense
                    multiple
                    clearable
                    @click:clear="clearFilter('service')"
                    :items="services" 
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="serviceName" 
                    item-value="entityId"
                ></v-select>
            </div>
            <v-btn v-if="!userOpen && user === '' && $vuetify.breakpoint.lgAndUp" depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="refreshItems">
                <v-icon>icons8-available-updates</v-icon>
            </v-btn>
            <div v-if="!userOpen && user === '' && $vuetify.breakpoint.lgAndUp" class="ml-2">
                <v-menu
                    offset-y
                    class="white"
                    style="z-index: 99999 !important; width: 200px !important; height: 200px !important"
                    :close-on-content-click="false"
                    origin="top right"
                    left
                    transition="scale-transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"
                            ><v-icon>icons8-table-properties</v-icon></v-btn
                        >
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox
                                v-model="headers[index].hidden"
                                dense
                                hide-details="auto"
                                v-for="(item, index) in headers"
                                :key="index"
                                :label="item.text"
                                :true-value="false"
                                :false-value="true"
                            ></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>
        <!-- <pre>filter.areas: {{ filter.areas }}</pre>
        <pre>filter.userCategory: {{ filter.userCategory }}</pre>
        <pre>filter.service: {{ filter.service }}</pre> -->

		<v-row v-if="!job" class="mt-0" dense>
			<v-col cols="12" class="pl-1 pr-0 pt-3 d-flex align-end">
				<div>
                    <div class="caption font-weight-bold grey--text text--darken-1">STATUS</div>
                    <v-btn-toggle v-model="filter.status" multiple color="white" class="mr-2" active-class="white" background-color="lightgrey" dense>
                        <!-- <v-btn :value="1" small>
                            <v-icon class="subtitle secondary--text icons8-filled-circle"></v-icon>
                            <span v-if="!listshrunk && listexpanded" class="subtitle grey--text">In</span>
                        </v-btn> -->
                        <v-btn :value="1" :class="filter.status.includes(1) ? 'success white--text' : 'success--text'" small>
                            <v-icon :class="filter.status.includes(1) ? 'white--text' : 'success--text'" class="text-h5 icons8-filled-circle"></v-icon>
                            <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">In</span>
                        </v-btn>
                        <v-btn :value="0" :class="filter.status.includes(0) ? 'grey white--text' : 'grey--text'" small>
                            <v-icon :class="filter.status.includes(0) ? 'white--text' : 'grey--text'" class="text-h5 icons8-filled-circle"></v-icon>
                            <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Out</span>
                        </v-btn>
                        <!-- <v-btn :value="0" small>
                            <v-icon class="subtitle grey--text icons8-filled-circle"></v-icon>
                            <span v-if="!listshrunk && listexpanded" class="subtitle grey--text">Out</span>
                        </v-btn> -->
                    </v-btn-toggle>
                </div>
                <div v-if="$vuetify.breakpoint.mdAndUp">
                    <div class="caption font-weight-bold grey--text text--darken-1">ACCESS</div>
                    <v-btn-toggle v-model="filter.userStatus" multiple color="white" class="mr-2" active-class="white" background-color="lightgrey" dense>
                        <v-btn value="APPROVED" :class="filter.userStatus.includes('APPROVED') ? 'success white--text' : 'success--text'" small>
                            <v-icon :class="filter.userStatus.includes('APPROVED') ? 'white--text' : 'success--text'" class="text-h5 icons8-checkmark-yes"></v-icon>
                            <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Approved</span>
                        </v-btn>
                        <v-btn value="PENDING" :class="filter.userStatus.includes('PENDING') ? 'secondary white--text' : 'secondary--text'" small>
                            <v-icon :class="filter.userStatus.includes('PENDING') ? 'white--text' : 'secondary--text'" class="text-h5 icons8-inactive-state"></v-icon>
                            <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Pending</span>
                        </v-btn>
                        <v-btn value="REJECTED" :class="filter.userStatus.includes('REJECTED') ? 'warning white--text' : 'warning--text'" small>
                            <v-icon :class="filter.userStatus.includes('REJECTED') ? 'white--text' : 'warning--text'" class="text-h5 icons8-cancel"></v-icon>
                            <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">REJECTED</span>
                        </v-btn>
                        <v-btn value="SUSPENDED" :class="filter.userStatus.includes('SUSPENDED') ? 'warning white--text' : 'warning--text'" small>
                            <v-icon :class="filter.userStatus.includes('SUSPENDED') ? 'white--text' : 'warning--text'" class="text-h5 icons8-private"></v-icon>
                            <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Suspended</span>
                        </v-btn>

                        <v-btn value="ARCHIVED" :class="filter.userStatus.includes('ARCHIVED') ? 'grey white--text' : 'grey--text'" small>
                            <v-icon :class="filter.userStatus.includes('ARCHIVED') ? 'white--text' : 'grey--text'" class="text-h5 icons8-xbox-a"></v-icon>
                            <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">ARCHIVED</span>
                        </v-btn>
                    </v-btn-toggle>
                </div>
                <!-- <div v-if="$vuetify.breakpoint.mdAndUp">
                    <div class="caption font-weight-bold grey--text text--darken-1">TRAINING</div>
                    <v-btn-toggle v-model="filter.training" multiple color="white" class="mr-2" active-class="white" background-color="lightgrey" dense>
                        <v-btn value="valid" small>
                            <v-icon class="text-h5 success--text icons8-course-assign"></v-icon>
                            <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption success--text">Valid</span>
                        </v-btn>
                        <v-btn value="required" small>
                            <v-icon class="text-h5 error--text icons8-course"></v-icon>
                            <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption error--text">Required</span>
                        </v-btn>
                        <v-btn value="none" small>
                            <v-icon class="text-h5 grey--text icons8-course"></v-icon>
                            <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption grey--text">None</span>
                        </v-btn>
                    </v-btn-toggle>
                </div>
                <div v-if="$vuetify.breakpoint.mdAndUp">
                    <div class="caption font-weight-bold grey--text text--darken-1">REGISTERED</div>
                    <v-btn-toggle v-model="filter.registered" multiple color="white" class="mr-2" active-class="white" background-color="lightgrey" dense>
                        <v-btn value="1" small>
                            <v-icon class="text-h5 success--text icons8-verified-account"></v-icon>
                        </v-btn>
                        <v-btn value="0" small>
                            <v-icon class="text-h5 grey--text icons8-customer"></v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </div> -->

				<v-spacer />

                <div class="d-flex">

                    <div class="ml-1 mr-1">
                        <v-btn v-if="listexpanded" depressed block class="primary darken-2 white--text" @click="openItem({})" >
                            <v-icon>icons8-people</v-icon>
                            <span class="ml-3">New</span>
                        </v-btn>
                    </div>
                    <div v-if="!job && listexpanded && $vuetify.breakpoint.lgAndUp">
                        <v-btn @click="exportCSV()" class="primary--text ml-2 mr-1" depressed v-if="!listshrunk">
                            <v-icon color="primary">icons8-export-csv</v-icon>
                        </v-btn>
                    </div>
                </div>
			</v-col>
		</v-row>

        <v-row :class="user === '' ? 'pt-3' : 'pt-0'" class="pa-3 mt-0">
            <!-- TABLE BASED LIST -->
            <v-data-table
                :item-class="itemRowBackground"
                dense
                class=""
                style="width: 100%"
                :headers="computedHeaders"
                :items="computedItems"
                :server-items-length="itemsTotal" 
                :options.sync="datatableOptions"
                v-if="$vuetify.breakpoint.mdAndUp"
                :loading="loading"
                :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50]
                }"
                loading-text="Loading Staff Members... Please wait"
            >
                <!-- :server-items-length="itemsTotal" -->
                <template v-if="!userOpen" v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-spacer />
                        <!-- <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn title="Permanently Delete" value="destroy" small>
                                <v-icon class="error--text">icons8-delete-trash</v-icon>
                            </v-btn>
                            <v-btn title="Delete" value="delete" small>
                                <v-icon class="grey--text text--darken-3">icons8-trash-can</v-icon>
                            </v-btn>
                            <v-btn title="Undelete" value="undelete" small>
                                <v-icon class="grey--text text--darken-3">icons8-trash-restore</v-icon>
                            </v-btn>
                        </v-btn-toggle> -->
                        <!-- <div class="mb-5 mx-5"><v-switch v-model="showImages" color="primary" label="Images" hide-details="auto" inset></v-switch></div>
					<div class="mb-5"><v-switch v-model="toggleDeletedItems" color="error" label="Deleted" hide-details="auto" inset></v-switch></div>-->
                    </div>
                </template>
				<template v-slot:[`item.userName`]="{ item }">
					<span>
                        <v-icon v-if="item.delete !== undefined || item.userStatus === 'ARCHIVED'" class=" mb-1 error--text icons8-xbox-a"></v-icon> 
                        {{ item.userName }}
                    </span>
				</template>
				<!-- REGISTERED (CUSTOM) -->
				<template v-slot:[`item.registered`]="{ item }">
					<v-icon v-if="item.authId !== null && item.authId !== ''" class="iconbackground text-h5 success--text icons8-verified-account"></v-icon>
					<v-icon v-else class="iconbackground text-h5 grey--text icons8-customer"></v-icon>
				</template>
				<!-- REGISTERED (CUSTOM) -->
				<template v-slot:[`item.createdDateTime`]="{ item }">
					<span class="d-flex subtitle align-center grey--text font-weight-bold text--darken-2">{{ $moment(item.createdDateTime).format('DD-MM-YYYY HH:mm') }}</span>
				</template>
				<template v-slot:[`item.userDOB`]="{ item }">
					<span class="d-flex subtitle align-center grey--text font-weight-bold text--darken-2">{{ $moment(item.userDOB).format('DD-MM-YYYY') }}</span>
				</template>
				<template v-slot:[`item.modifiedDateTime`]="{ item }">
					<span class="d-flex subtitle align-center grey--text font-weight-bold text--darken-2">{{ $moment(item.modifiedDateTime).format('DD-MM-YYYY') }}</span>
				</template>
				<!-- SWAPP STATUS (CUSTOM) -->
				<template v-slot:[`item.swappStatus`]="{ item }">
					<span v-if="item.swappStatus === '1'" class="d-flex subtitle align-center grey--text font-weight-bold text--darken-2"><v-icon class="iconbackground subtitle secondary--text icons8-filled-circle"></v-icon> IN </span>
					<span v-else class="d-flex subtitle align-center grey--text font-weight-bold"><v-icon class="iconbackground subtitle grey--text text--lighten-2 icons8-filled-circle"></v-icon> OUT </span>
					<!-- <span v-else-if="item.swappStatus === '2'" class="d-flex subtitle align-center"><v-icon class="iconbackground subtitle warning--text icons8-filled-circle"></v-icon> SICK </span>
					<span v-else-if="item.swappStatus === '3'" class="d-flex subtitle align-center"><v-icon class="iconbackground subtitle primary--text icons8-filled-circle"></v-icon> LEAVE </span> -->
				</template>

				<!-- TRAINING STATUS (CUSTOM) -->
				<template v-slot:[`item.trainingStatus`]="{ item }">
                    <div class="d-flex justify-center align-center">
                        <v-icon v-if="item.trainingStatus === 'ACTIVE'" class="iconbackground text-h5 success--text icons8-course-assign"></v-icon>
                        <v-icon v-else-if="item.trainingStatus === 'NONE'" class="grey--text text-h5 icons8-course"></v-icon>
                        <v-icon v-else-if="item.trainingStatus == 'DUE'" class="error--text text-h5 icons8-course"></v-icon>
                        <v-icon v-else-if="item.trainingStatus == 'REQUIRED'" class="warning--text text-h5 icons8-course"></v-icon>
                        <div class="font-weight-bold pl-1 grey--text">{{ item.trainingStatus }}</div>
                    </div>
				</template>

				<!-- USER STATUS (CUSTOM) -->
				<template v-slot:[`item.userStatus`]="{ item }">
					<span v-if="item.userStatus === 'PENDING'"><v-icon class="iconbackground text-h4 secondary--text icons8-inactive-state"></v-icon></span>
					<span v-else-if="item.userStatus === 'REJECTED'"><v-icon class="iconbackground text-h4 warning--text icons8-cancel"></v-icon></span>
					<span v-else-if="item.userStatus === 'APPROVED'"><v-icon class="iconbackground text-h4 success--text icons8-checkmark-yes"></v-icon></span>
					<span v-else-if="item.userStatus === 'SUSPENDED'"><v-icon class="iconbackground text-h4 error--text icons8-private"></v-icon></span>
					<span v-else-if="item.userStatus === 'ARCHIVED'"><v-icon class="iconbackground text-h4 grey--text icons8-xbox-a"></v-icon></span>
                    <!-- {{ item.userStatus }} -->
				</template>
                

                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon class="primary--text ml-5" @click="openItem(item)">icons8-forward</v-icon>
                </template>
            </v-data-table>

            <!-- // * MOBILE CARDS / Instead of Table -->
            <v-col v-if="$vuetify.breakpoint.smAndDown" class="tabledata pa-0 ma-0 pb-12" cols="12" xs="12" sm="12" md="12" lg="12" xl="12" style="height: 1200px !important">
                <v-row class="d-flex flex-column align-center " no-gutters>
                    <div v-if="itemsFrom > 0">
                        
                        <v-btn block @click="goBack" class="elevation-0 darkgrey lighten-3 white--text  mb-2 font-weight-bold">
                        + GO BACK 
                        </v-btn>
                    </div>
                    <v-col cols="12" xs="12" class="my-1 pa-1 white tablecard" v-for="item in items" :key="item.id" @click="openItem(item)">
                        <v-row class="d-flex align-center justify-center pa-3 fill-height" no-gutters >
                            <v-col cols="2">
                                <v-icon v-if="item.swappStatus === '1'" class="iconbackground display-1 secondary--text icons8-filled-circle"></v-icon>
                                <v-icon v-if="item.swappStatus === '0'" class="iconbackground display-1 grey--text icons8-filled-circle"></v-icon>
                                <v-icon v-if="item.swappStatus === '2'" class="iconbackground display-1 warning--text icons8-filled-circle"></v-icon>
                                <v-icon v-if="item.swappStatus === '3'" class="iconbackground display-1 primary--text icons8-filled-circle"></v-icon>
                            </v-col>
                            <v-col cols="8" class="d-flex flex-column pt-1">
                                <div style="margin: 0px !important; line-height: 20px !important;" class="noselect"><span class="d-inline-block text-truncate" style="max-width: 200px;">{{ item.userName }}</span></div>
                                <div style="" class="font-weight-bold caption grey--text text--darken-1 noselect">{{ item.userTelephone }}</div>
                                <div v-if="item.lastLocationId" class="font-weight-bold caption grey--text text--darken-1 noselect">Last Location: {{ getLocation(item.lastLocationId) }}</div>
                            </v-col>
                            <v-col cols="2" class="text-right"><v-icon @click="openItem(item)">mdi-chevron-right</v-icon></v-col>
                        </v-row>
                    </v-col>
                    <v-row v-if="loading === true" class="align-center pt-5 justify-center">
                        <v-progress-circular :size="60" :width="7" indeterminate color="primary" ></v-progress-circular>
                    </v-row>
                    <!-- <pre>items: {{ items }}</pre> -->
                    <div v-else-if="items.length == 0" class="font-weight-bold grey--text text--darken-1 body-1">
                        NO JOBS
                    </div>
                    <div v-else-if="items.length !== itemsTotal && items.length == datatableOptions.itemsPerPage">
                        
                        <v-btn block @click="loadMore" class="elevation-0 darkgrey lighten-3 white--text font-weight-bold">
                        + SHOW MORE 
                        </v-btn>
                    </div>
                    <div v-else-if="items.length <= itemsTotal && items.length > 0" class="font-weight-bold grey--text text--darken-1 body-1">
                        SHOWING ALL JOBS
                    </div>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
        job: {
            type: Boolean, 
            default: false,
        },
        userOpen: {
            type: Boolean,
            default: false
        },
        user: {
            type: String, 
            default: ''
        }
    },
    data: () => ({
        type: '',
        status: '',
        key: 'user',
        service: {},
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,


        jobsSelected: [{}, {}],

        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
				{ text: "ID", value: "entityId", align: "start", sortable: true, hidden: true, expanded: false, userOpen: true },

				{ text: "Name", value: "userName", align: "start", sortable: true, hidden: false, expanded: false, userOpen: true },
				{ text: "Telephone Number", value: "userTelephone", align: "center", sortable: true, hidden: true, expanded: true, userOpen: true },
				{ text: "User Category", value: "userCategory", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "Access", value: "userStatus", align: "center", sortable: true, hidden: false, expanded: false, userOpen: false },
				{ text: "Areas", value: "userAreas", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "Registered", value: "registered", align: "center", sortable: true, hidden: false, expanded: false, userOpen: false },
				{ text: "Primary Line Manager", value: "primaryLineManagerId", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "Training", value: "trainingStatus", align: "center", sortable: true, hidden: false, expanded: false, userOpen: false },
				{ text: "Status", value: "swappStatus", align: "start", sortable: true, hidden: false, expanded: false, userOpen: false },
				{ text: "Last Location", value: "lastLocationId", align: "start", sortable: true, hidden: true, expanded: true, userOpen: false },
				// { text: "Training", value: "userTrainingStatus", align: "start", sortable: true, hidden: false, expanded: true, userOpen: false },
				{ text: "Last Site", value: "lastSiteId", align: "start", sortable: true, hidden: true, expanded: true, userOpen: false },


				{ text: "Contract Hours", value: "contractHours", align: "start", sortable: true, hidden: true, expanded: false, userOpen: true },
				{ text: "Financial Access", value: "financialAccess", align: "center", sortable: true, hidden: true, expanded: true, userOpen: true },
				{ text: "Auth Id", value: "authId", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "NOK Name", value: "NOKName", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "NOK Relationship", value: "NOKRelationship", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "NOK Tell No", value: "NOKTellNo", align: "center", sortable: true, hidden: true, expanded: true, userOpen: false },
				{ text: "Last Client", value: "lastClientId", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "Privacy Policy Consent", value: "privacyPolicyConsent", align: "start", sortable: true, hidden: true, expanded: true, userOpen: false },
				{ text: "SWAPP Method", value: "swappMethod", align: "center", sortable: true, hidden: true, expanded: true, userOpen: true },
				{ text: "Address Line 1", value: "userAddressLine1", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "Address Line 2", value: "userAddressLine2", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "Address Line 3", value: "userAddressLine3", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "County", value: "userAddressCounty", align: "center", sortable: true, hidden: true, expanded: true, userOpen: false },
				{ text: "User Postcode", value: "userAddressPostcode", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "User Town", value: "userAddressTown", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "User DOB", value: "userDOB", align: "start", sortable: true, hidden: true, expanded: true, userOpen: false },
				{ text: "User Email", value: "userEmail", align: "center", sortable: true, hidden: true, expanded: true, userOpen: true },
				{ text: "User Gender", value: "userGender", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "User Job Title", value: "userJobTitle", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "User Level", value: "userLevel", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "User Role", value: "userRole", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "User Start Date", value: "userStartDate", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "User Type", value: "userType", align: "start", sortable: true, hidden: true, expanded: true, userOpen: false },
				{ text: "Audit Target", value: "auditTarget", align: "center", sortable: true, hidden: true, expanded: true, userOpen: true },
				{ text: "Offline Staff Member", value: "offlineStaffMember", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "Offline Staff Member Reason", value: "offlineStaffMemberReason", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "Secondary Line Manager", value: "secondaryLineManagerId", align: "center", sortable: true, hidden: true, expanded: true, userOpen: false },


                { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
                { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
                { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
                { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
                { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
                { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
                { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },






				{ text: "", value: "actions", align: "end", sortable: true, hidden: false, expanded: false, width: "100px", userOpen: false },
        ],
        
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['userName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        filter: {
            areas: [],
            service: [],
            userCategory: [],
            status: [],
            userStatus: ['APPROVED', 'PENDING'],
            training: ['1','0'],
            registered: ['1','0'],
        },

        areas: [],
        services: [],
        userCategories: [
            'Mobile Cleaners', 
            'Director', 
            'Commercial Manager', 
            'Office Staff', 
            'Security Staff', 
            'Cleaner', 
            'Supervisor',
            'Senior Manager'
        ],
        
        loading: false,
        computedItems: [],

        locations: [],

        exportingCSV: false,
    }),
    computed: {
        computedSortBy() {
            return this.datatableOptions.sortBy[0];
        },
        computedSortOrder() {
            return this.datatableOptions.sortDesc[0] ? 'DESC' : 'ASC'
        },
        computedSearchQuery() {
            let searchQuery = '';

            // if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
            //     if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
            //         searchQuery = '';
            //     } else {
            //         searchQuery = '';
            //     }
            // } else {
            //     if (searchQuery.startsWith('@')) {
            //         if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
            //             searchQuery = `${searchQuery}*`;
            //         } else {
            //             searchQuery = `${searchQuery}*`;
            //         }
            //     } else {
            //         if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
            //             searchQuery = `${searchQuery}*`;
            //         } else {
            //             searchQuery = `${searchQuery}*`;
            //         }
            //     }
            // }

            searchQuery = `${searchQuery} @deleted:{false}`;

            if (JSON.stringify(this.filter.areas) !== "[]") {
                searchQuery = `${searchQuery} @userAreas:{${this.filter.areas.join("|")}}`
            }

            if (JSON.stringify(this.filter.service) !== "[]") {
                searchQuery = `${searchQuery} @services:{${this.filter.service.join("|")}}`
            }

            if (JSON.stringify(this.filter.userCategory) !== "[]") {
                var categories = []
                // console.log(this.filter.userCategory)
                this.filter.userCategory.forEach((item) => {
                    if (item == 'Mobile Cleaners') {
                        categories.push('MOBILECLEANER')
                    }
                    categories.push(item.replace(/\s/g, '').toUpperCase())
                })
                searchQuery = `${searchQuery} @userCategory:{${categories.join("|")}}`
            }

            if (JSON.stringify(this.filter.status) !== "[]") {
                searchQuery = `${searchQuery} @swappStatus:{${this.filter.status.join("|")}}`
            }

            if (JSON.stringify(this.filter.userStatus) !== "[]") {
                searchQuery = `${searchQuery} @userStatus:{${this.filter.userStatus.join("|")}}`
            }

            // if (JSON.stringify(this.filter.registered) !== "[]") {
            //     if (this.filter.registered.includes('1')) {
            //         searchQuery = `${searchQuery} @authId:{}`
            //     } else {
            //         searchQuery = `${searchQuery} @authId:{}`
            //     }
            // }



            //! TODO - TRAINING STATUS

            // console.log(searchQuery);

            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            // if (this.userOpen) {
            //     headers = headers.filter((header) => header.userOpen === true); // Return Headers based on Expanded State
            // } else if (this.listshrunk) {
            //     headers = headers.filter((header) => header.expanded === false || header.expanded === true); // Return Headers based on Expanded State
            // } else {
            //     headers = headers.filter((header) => header.expanded === false); // Return Headers based on Expanded State
            // }
            return headers;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedType() {
            const types = [];
            this.items.filter((item) => {
                return types.push(item.actionType);
            });
            return types;
        },
        computedStatus() {
            const status = [];
            this.items.filter((item) => {
                return status.push(item.actionStatus);
            });
            return status;
        },
    },
    methods: {
        // Clear Item Property
        clearFilter(property) {
            setTimeout(() => {
                this.filter[property] = [];
            }, 1);
        },
        async computeItems() {
            var t = this;
            var items = []
            items = this.items

            // console.log('items = ' + JSON.stringify(items, null, 2))
            var now = new Date().getTime();
            var thirtyDays = new Date().getTime() + (30 * 24 * 60 * 60 * 1000);

            for (let i = 0; i < items.length; i++) {
                var item = items[i];
                var required = 0;
                var due = 0;
                var valid = 0;


                var training = await t.getUsersTraining(item.entityId);
                // console.log('training = ' + JSON.stringify(training, null, 2))

                training.forEach((trainingItem) => {

                    if (trainingItem.trainingStatus === 'REQUIRED' || trainingItem.trainingStatus === 'EXPIRED') {
                        required++
                    } else if (trainingItem.trainingStatus === 'DUE' || (trainingItem.expiryDate > now && trainingItem.expiryDate < thirtyDays)) {
                        due++;
                    } else if (trainingItem.trainingStatus === 'ACTIVE' || trainingItem.trainingStatus === 'CURRENT') {
                        valid++;
                    }
                })
                // console.log('required = ' + required)
                // console.log('due = ' + due)
                // console.log('valid = ' + valid)

                if (required > 0) {
                    item.trainingStatus = 'REQUIRED'
                } else if (due > 0) {
                    item.trainingStatus = 'DUE'
                } else if (valid > 0) {
                    item.trainingStatus = 'ACTIVE'
                } else {
                    item.trainingStatus = 'NONE'
                }
            }

            t.loading = false
            this.computedItems = []
            this.computedItems = items;
            // return items;
        },
        async getUsersTraining(userId) {
            var t = this; 
            var training = []
            let itemsResult = await this.REDIS_searchFor('user-training', 0, 999, 'trainingName', this.computedSortOrder, `@deleted:{false} @trainingUserId:{${userId}} @trainingStatus:{CURRENT|EXPIRED|REQUIRED|DUE|ACTIVE}` );
            // console.log('itemsResult = ' + JSON.stringify(itemsResult, null, 2))
            if (itemsResult.success) {
                training = itemsResult.data.documents;
            }
            return training
        },
        async loadMore() {
            this.itemsFrom = this.itemsFrom + this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo + this.datatableOptions.itemsPerPage;
            this.getItems();
        },
        async goBack() {
            this.itemsFrom = this.itemsFrom - this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo - this.datatableOptions.itemsPerPage;
            this.getItems()
        },
        async editItem(id) {
            // Get the item from the database
            let itemResult = await this.MIX_readItem(id, 'service');
            if (itemResult.code === 1) {
                this.$emit('edit', itemResult.data);
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Editing Component', show: true });
            }
        },
        clearSearchQuery() {
            this.searchQuery = ''
            this.getItems()
        },
        refreshItems: function () {
            this.getItems();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        getLocation(locationId) {
            var t = this;
            var location = []
            var locationName = ''

            location = t.locations.filter((item) => { return item.entityId == locationId })
            // console.log('location = ' + JSON.stringify(location, null, 2))

            if (location.length !== 0) { locationName = location[0].locationName }
            return locationName
        },
        async getUser() {
			let t = this;
            var itemId = this.user
			let result = await t.REDIS_read(t.key, itemId);
            // console.log('getting user')
			if (result.success) {
				var items = result.data;
                //console.log('this.items = ' + JSON.stringify(items, null, 2))
                this.items = []
                this.items.push(items)
                this.itemsTotal = result.data.length
                this.computeItems()
                //console.log('this.items = ' + JSON.stringify(this.items, null, 2))
			}
        },
        // * GET ITEMS
        async getItems() {
            var t = this
            t.loading = true
            // console.log('getting staff items')
            // console.log('datatableOptions = ' + JSON.stringify(this.datatableOptions, null, 2))

            var itemsPerPage = this.datatableOptions.itemsPerPage;
            if (itemsPerPage === -1) {
                itemsPerPage = this.itemsTotal;
            }

            var searchQuery = this.searchQuery;
            
            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '';
                } else {
                    searchQuery = '';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*`;
                    }
                }
            }
            // add searchQuery to start of computedSearchQuery
            searchQuery = `${searchQuery} ${this.computedSearchQuery}`;
            let itemsResult = await this.REDIS_searchFor('user', this.itemsFrom, itemsPerPage, this.computedSortBy, this.computedSortOrder, searchQuery );
            // console.log('itemsResult = ' + JSON.stringify(itemsResult.data.total, null, 2))
            if (itemsResult.success) {
                this.itemsTotal = itemsResult.data.total;
                var items = []
                items = itemsResult.data.documents;
                // console.log('this.items = ' + JSON.stringify(this.items, null, 2))
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                // this.computeItems()
                for (let i = 0; i < items.length; i++) {
                    var item = items[i];
                    console.log('item = ' + JSON.stringify(item.userName, null, 2))
                    var required = 0;
                    var due = 0;
                    var valid = 0;


                    var training = await t.getUsersTraining(item.entityId);

                    // console.log('training = ' + item.entityId + ' = ' + JSON.stringify(training, null, 2))
                    training.forEach((trainingItem) => {
                        if (trainingItem.trainingStatus === 'REQUIRED' || trainingItem.trainingStatus === 'EXPIRED') {
                            required++
                        } else if (trainingItem.trainingStatus === 'DUE') {
                            due++;
                        } else if (trainingItem.trainingStatus === 'ACTIVE' || trainingItem.trainingStatus === 'CURRENT') {
                            valid++;
                        }
                    })
                    // console.log('required = ' + required)
                    // console.log('due = ' + due)
                    // console.log('valid = ' + valid)
                    if (required > 0) {
                        item.trainingStatus = 'REQUIRED'
                    } else if (due > 0) {
                        item.trainingStatus = 'DUE'
                    } else if (valid > 0) {
                        item.trainingStatus = 'ACTIVE'
                    } else {
                        item.trainingStatus = 'NONE'
                    }
                    console.log('item.trainingStatus = ' + item.trainingStatus)
                }

                this.computedItems = []
                this.computedItems = items;

                t.loading = false
                // t.loading = false

            }
        },

        async exportCSV() {
            var t = this;
            t.exportingCSV = true
            
            var searchQuery = this.searchQuery;
            
            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '';
                } else {
                    searchQuery = '';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*`;
                    }
                }
            }
            // add searchQuery to start of computedSearchQuery
            searchQuery = `${searchQuery} ${this.computedSearchQuery}`;
            let itemsResult = await this.REDIS_searchFor('user', 0, 999, 'userName', 'ASC', searchQuery);
            if (itemsResult.success) {
                
                // go through each item and if archived, find the archived date and add it to the item
                for (let i = 0; i < itemsResult.data.documents.length; i++) {
                    let item = itemsResult.data.documents[i];
                    if (item.userStatus === 'ARCHIVED') {
                        var query = `@type:{'Archive User'} @linkedId:{${item.entityId}} @status:{'COMPLETED'}`;
                        let archivedItem = await this.REDIS_searchFor('progress', 0, 999, 'createdDateTime', 'DESC', query);
                        if (archivedItem.success && archivedItem.data.total > 0) {
                            // console.log('archivedItem = ' + JSON.stringify(archivedItem, null, 2))
                            item.archivedDateTime = archivedItem.data.documents[0].createdDateTime;
                        }
                    }
                }
            
                var data = itemsResult.data.documents.map(e => {
                    return {
                        'Name' : e.userName,
                        'Status' : e.userStatus,
                        'Archived Date' : e.archivedDateTime ? t.$moment(e.archivedDateTime).format('DD-MMM-YYYY') : '',
                        'Email' : e.userEmail,
                        'Telephone' : e.userTelephone,
                        'Contracted Hours' : e.contractHours,
                        'Gender' : e.userGender,
                        'DOB' : (e.userDOB && e.userDOB !== 'Invalid date') ? t.$moment(e.userDOB).format('DD-MMM-YYYY') : '',
                        'Start Date' : e.userStartDate ? t.$moment(e.userStartDate).format('DD-MMM-YYYY') : '',
                        'Primary Line Manager' : e.primaryLineManagerId,
                        'Address Line 1' : e.userAddressLine1,
                        'Address Line 2' : e.userAddressLine2,
                        'Postcode' : e.userAddressPostcode,
                    }
                })

                // console.log('data  = ' + JSON.stringify(data, null, 2))

                let csv = t.$papa.unparse(data)
                t.$papa.download(csv, 'Staff Report')
                t.exportingCSV = false
            }
        },
        
    },
    watch: {
        datadown: {
            handler: function () {
                this.service = { ...this.datadown };
            },
            deep: true,
        },
        refreshitems() {
            // this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.itemsFrom = 0;
            this.itemsTo = 9;
            this.datatableOptions.page = 1;
            this.getItems();
        },
        computedFields() {
            // this.getItems();
        },
        toggleDeletedItems() {
            // this.getItems();
        },
        searchQuery() {
            this.itemsFrom = 0;
            this.itemsTo = 9;
            this.datatableOptions.page = 1;
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                if (this.user == '') {
                    this.getItems();
                } 
                // this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        var t = this;
        this.service = { ...this.datadown };
        
        let services = await t.REDIS_searchFor('service', '0', '9999', 'serviceName', 'ASC', '@deleted:{false}');
        if (services.success) { t.services = services.data.documents; }

        t.services = t.services.map((x) => {
            return {
                entityId: x.entityId,
                serviceName: x.serviceName,
            };
        })

        
        let areas = await t.REDIS_searchFor('area', '0', '9999', 'areaName', 'ASC', '@deleted:{false}');
        if (areas.success) { t.areas = areas.data.documents; }

        t.areas = t.areas.map((x) => {
            return {
                entityId: x.entityId,
                areaName: x.areaName,
            };
        })

        
        let locations = await t.REDIS_searchFor('location', '0', '9999', 'locationName', 'ASC', '@deleted:{false}');
        if (locations.success) { t.locations = locations.data.documents; }
        // console.log('t.locations = ' + JSON.stringify(t.locations, null, 2))

        t.locations = t.locations.map((x) => {
            return {
                entityId: x.entityId,
                locationName: x.locationName,
            };
        })

        // console.log('this.user = ' + JSON.stringify(this.user, null, 2))
        if (this.user !== '') {
            this.getUser()
        } else {
            this.getItems();
        }
    },
};
</script>