<template>
	<v-card @click="openTicket" flat class="rounded-lg pa-0 ma-0">
        <v-col cols="12" class="align-start ">
            <div class="d-flex" style="width: 100% !important">
                <div class="d-flex">
                    <v-icon v-if="item.ticketStatus == 'Open' || item.ticketStatus == 'open'" size="40" class="orange--text">icons8-open-message</v-icon>
                    <v-icon v-else-if="item.ticketStatus == 'On Hold'" x-large class="grey--text icons8-pause-button"></v-icon>
                    <v-icon v-else-if="item.ticketStatus == 'Pending'" x-large class="orange--text icons8-clock"></v-icon>
                    <v-icon v-else-if="item.ticketStatus == 'Solved'" x-large class="green--text icons8-checkmark-yes"></v-icon>
                    <v-icon v-else-if="item.ticketStatus == 'Closed'" x-large class="green--text icons8-cancel"></v-icon>
                    <v-icon v-else-if="item.ticketStatus == 'Archived'" x-large class="error--text icons8-archive"></v-icon>
                </div>
                <div class="align-center" style="width: 100% !important">
                    <div class="d-flex flex-row">
                        <div class="font-weight-bold body-2 pl-2 d-flex flex-column primary--text">
                            {{ item.ticketSubject }}
                            <span class="font-weight-regular body-2 text-start grey--text">{{ item.ticketMessage }}</span>
                        </div>
                    </div>
                    <div class="d-flex justify-end">
                        <div v-if="item.modifiedDateTime !== null">
                            <div class="grey--text body-2 font-weight-bold" v-if="$moment(item.modifiedDateTime).format('DD-MM-YYYY') == $moment().format('DD-MM-YYYY')">{{ $moment(item.modifiedDateTime).format('HH:mm') }}</div>
                            <div class="grey--text body-2 font-weight-bold" v-else>{{ $moment(item.modifiedDateTime).format('DD MMM YY') }}</div>
                        </div>
                        <div v-else>
                            <div class="grey--text body-2 font-weight-bold" v-if="$moment(item.createdDateTime).format('DD-MM-YYYY') == $moment().format('DD-MM-YYYY')">{{ $moment(item.createdDateTime).format('HH:mm') }}</div>
                            <div class="grey--text body-2 font-weight-bold" v-else>{{ $moment(item.createdDateTime).format('DD MMM YY') }}</div>
                        </div>
                    </div>
                </div>
            </div>
                
            <!-- <pre>{{ item }}</pre> -->
        </v-col>
    </v-card>
</template>

<script>
	export default {
        data: () => ({
        }),
		props: {
            item: { 
                // eslint-disable-next-line vue/require-valid-default-prop
                type: Object, default: '-'
            },
		},
        computed: {
        },
        methods: {
            openTicket() {
                this.$emit('openTicket', this.item.entityId);
            }
        }
	};
</script>

<style scoped>
</style>
