<template>
    <div class="" style="width: 100% !important">
        <!-- <pre>{{ ready }}</pre> -->
        <div class="mt-2 d-flex align-start">
            <!-- <div v-if="list" class="flex-grow-1">
                <v-text-field
                    label="Search"
                    v-model="searchQuery"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    color="primary"
                    clearable
                    prepend-inner-icon="icons8-search"
                    @click:clear="clearSearchQuery()"
                ></v-text-field>
            </div> -->
            <!-- //! TODO - CHANGE TO DATE PICKER  -->
            <div v-if="list && !isRecurringRule && $vuetify.breakpoint.mdAndUp" class="flex-grow-1">
                <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field label="Jobs From" v-model="computedDate1" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearFilter('jobsFrom')"></v-text-field>
                    </template>
                    <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                </v-menu>
            </div>
            <!-- //! TODO - CHANGE TO DATE PICKER  -->
            <div v-if="list && !isRecurringRule && $vuetify.breakpoint.mdAndUp" class="flex-grow-1 ml-2">
                <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field label="Jobs To" v-model="computedDate2" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearFilter('jobsTo')"></v-text-field>
                    </template>
                    <v-date-picker v-model="date2" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu2 = false"></v-date-picker>
                </v-menu>
            </div>
            <div v-if="!list && !isRecurringRule && $vuetify.breakpoint.mdAndUp" class="flex-grow-1">
                <v-select 
                    :disabled="ready < 7"
                    class="" 
                    hide-details="auto" 
                    label="Service" 
                    filled 
                    v-model="filter.service"  
                    outlined
                    dense
                    item-value="entityId" 
                    clearable
                    :items="services" 
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="serviceName" 
                ></v-select>
            </div>
            <div v-if="!list && $vuetify.breakpoint.mdAndUp" class="flex-grow-1 ml-2">
                <v-select 
                    :disabled="ready < 7"
                    class="" 
                    hide-details="auto" 
                    label="Clients" 
                    filled 
                    item-value="entityId" 
                    v-model="filter.client"  
                    outlined
                    dense
                    clearable
                    :items="clients" 
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="clientName" 
                ></v-select>
            </div>
            <div v-if="!list && $vuetify.breakpoint.mdAndUp" class="flex-grow-1 ml-2">
                <v-select 
                    :disabled="ready < 7"
                    class="" 
                    hide-details="auto" 
                    label="Sites" 
                    filled 
                    v-model="filter.site"  
                    outlined
                    dense
                    clearable
                    :items="sites" 
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="siteName" 
                    item-value="entityId" 
                ></v-select>
            </div>
            <div v-if="!list && $vuetify.breakpoint.mdAndUp" class="flex-grow-1 ml-2">
                <v-select 
                    :disabled="ready < 7"
                    class="" 
                    hide-details="auto" 
                    label="User Category" 
                    filled 
                    v-model="filter.userCategory"  
                    outlined
                    dense
                    clearable
                    :items="userCategories" 
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                ></v-select>
            </div>
            <div v-if="!list && $vuetify.breakpoint.mdAndUp" class="flex-grow-1 ml-2">
                <v-select 
                    :disabled="ready < 7"
                    class="" 
                    hide-details="auto" 
                    label="Staff Member" 
                    filled 
                    v-model="filter.staffMember"  
                    outlined
                    dense
                    clearable
                    @click:clear="clearFilter('staffMember')"
                    :items="computedUsers" 
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="userName" 
                    item-value="entityId" 
                ></v-select>
            </div>
            <v-btn v-if="!isRecurringRule && $vuetify.breakpoint.mdAndUp" depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="refreshItems">
                <v-icon>icons8-available-updates</v-icon>
            </v-btn>
            
        </div>
        <v-row v-if="$vuetify.breakpoint.mdAndUp" class="d-flex align-end px-3 pt-3 pb-2">
            <div class="d-flex flex-column">

                <div v-if="isRecurringRule" class="pb-3 page-title secondary--text pr-1">SCHEDULE</div>
                <v-spacer />
                <div class="d-flex">
                    <div v-if="!list || isRecurringRule" class="mr-2">
                        <v-btn style="border: 1px solid grey !important; height: 40px !important" color="white" depressed  @click="priorWeek" block>
                            <v-icon class="icons8-back"></v-icon>
                        </v-btn>
                    </div>
                    <!-- <div class="mr-2 mt-1">
                        <v-btn style="border: 1px solid grey !important;" @click="currentWeek" :disabled="this.currentWeekStartDate === $moment().startOf('week').add(1, 'days').format('DD-MMM-YYYY')" color="white" block depressed>
                            <span class="white--text">CURRENT WEEK</span>
                        </v-btn>
                    </div> -->
                    <div v-if="!list || isRecurringRule" class="mr-2">
                        <v-btn style="border: 1px solid grey !important; height: 40px !important" color="white" @click="nextWeek" block depressed>
                            <v-icon class="icons8-forward"></v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>
            <div v-if="isRecurringRule" class="flex-grow-1 ml-2">
                <v-menu v-model="dateMenu3" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field label="Go To" v-model="computedDate3" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date3" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu3 = false"></v-date-picker>
                </v-menu>
            </div>
            
            <v-spacer v-if="!list || isRecurringRule" />


			<!-- // * NOT ALLOCATED - WEEK -->
            <div v-if="isRecurringRule">
				<v-card flat width="110" class="mr-2" height="92">
					<v-card-actions class="justify-center text-center caption grey--text">UNALLOCATED</v-card-actions>
					<v-card-title class="justify-center py-1">
						<app-text category="stats-grey">
                            <number ref="numberExample" :to="computedRecurringStats.unallocated" :duration="0.0" />
                        </app-text>
                    </v-card-title>
					<!-- <v-card-actions class="justify-center text-center caption grey--text">RULE IN VIEW</v-card-actions> -->
				</v-card>
			</div>
			<!-- // * ALLOCATED - WEEK -->
            <div v-if="isRecurringRule">
				<v-card flat width="110" class="mr-2" height="92">
					<v-card-actions class="justify-center text-center caption success--text">ALLOCATED</v-card-actions>
					<v-card-title class="justify-center py-1">
						<app-text category="stats-success">
                               <number ref="numberExample" :to="computedRecurringStats.allocated" :duration="0.0" />
                           </app-text>
                       </v-card-title>
					<!-- <v-card-actions class="justify-center text-center caption success--text"></v-card-actions> -->
				</v-card>
		    </div>
		    <!-- // * Cancelled - WEEK -->
            <div v-if="isRecurringRule">
				<v-card flat width="110" class="mr-2" height="92">
		       		<v-card-actions class="justify-center text-center caption error--text">CANCELLED</v-card-actions>
		       		<v-card-title class="justify-center py-1">
		       			<app-text category="stats-error">
                               <number ref="numberExample" :to="computedRecurringStats.cancelled" :duration="0.0" />
                           </app-text>
                       </v-card-title>
		       		<!-- <v-card-actions class="justify-center text-center caption error--text">WEEK IN VIEW</v-card-actions> -->
		       	</v-card>
		    </div>
		    <!-- // * ALL - WEEK -->
            <div v-if="isRecurringRule">
				<v-card flat width="110" class="" height="92">
		    		<v-card-actions class="justify-center text-center caption secondary--text">TOTAL JOBS</v-card-actions>
		    		<v-card-title class="justify-center py-1">
		    			<app-text category="stats-secondary">
                            <number ref="numberExample" :to="computedRecurringStats.total" :duration="0.0" />
                        </app-text>
                    </v-card-title>
		    		<!-- <v-card-actions class="justify-center text-center caption secondary--text">WEEK IN VIEW</v-card-actions> -->
			    </v-card>
			</div>


            <div v-if="list && !isRecurringRule">
                <div class="caption font-weight-bold grey--text text--darken-1">ALLOCATED STATUS</div>
                <v-btn-toggle v-model="filter.allocatedStatus" multiple color="white" class="mr-1" background-color="white" dense>
                    <v-btn value="ALLOCATED" :class="filter.allocatedStatus.includes('ALLOCATED') ? 'success white--text' : 'success--text'" small>
                        <v-icon :class="filter.allocatedStatus.includes('ALLOCATED') ? 'white--text' : 'success--text'" class="text-h5 icons8-filled-circle"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">ALLOCATED</span>
                    </v-btn>
                    <v-btn value="UNALLOCATED" :class="filter.allocatedStatus.includes('UNALLOCATED') ? 'grey white--text' : 'grey--text'" small>
                        <v-icon :class="filter.allocatedStatus.includes('UNALLOCATED') ? 'white--text' : 'grey--text'" class="text-h5 icons8-filled-circle"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">UNALLOCATED</span>
                    </v-btn>
                </v-btn-toggle>
            </div>
            <v-spacer v-if="list && !isRecurringRule" />
            <div v-if="!isRecurringRule">
                <div class="caption font-weight-bold grey--text text--darken-1">PUBLISHED STATUS</div>
                <v-btn-toggle :disabled="ready < 7" v-model="filter.publishedStatus" multiple color="white" class="" active-class="white" background-color="lightgrey" dense>
                    <v-btn value="PUBLISHED" :class="filter.publishedStatus.includes('PUBLISHED') ? 'success white--text' : 'success--text'" small>
                        <v-icon :class="filter.publishedStatus.includes('PUBLISHED') ? 'white--text' : 'success--text'" class="text-h5 icons8-checkmark-yes"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">PUBLISHED</span>
                    </v-btn>
                    <v-btn value="UNPUBLISHED" :class="filter.publishedStatus.includes('UNPUBLISHED') ? 'grey white--text' : 'grey--text'" small>
                        <v-icon :class="filter.publishedStatus.includes('UNPUBLISHED') ? 'white--text' : 'grey--text'" class="text-h5 icons8-checkmark-yes"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">DRAFT</span>
                    </v-btn>
                    <v-btn value="CANCELLED" :class="filter.publishedStatus.includes('CANCELLED') ? 'error white--text' : 'error--text'" small>
                        <v-icon :class="filter.publishedStatus.includes('CANCELLED') ? 'white--text' : 'error--text'" class="text-h5 icons8-filled-circle"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">CANCELLED</span>
                    </v-btn>
                </v-btn-toggle>
            </div>

			<div v-if="!list && !isRecurringRule" class="ml-1">
                <v-btn depressed block class="secondary white--text" @click="openRecurringJob({})" >
                    <v-icon>icons8-future</v-icon>
                    <span v-if="!listshrunk" class="ml-3">New Recurring</span>
                </v-btn>
            </div>
			<div v-if="!list && !isRecurringRule" class="ml-1">
                <v-btn depressed block class="secondary white--text" @click="openJob({})" >
                    <v-icon>icons8-broom</v-icon>
                    <span v-if="!listshrunk" class="ml-3">New Job</span>
                </v-btn>
            </div>
        </v-row>
        <!-- <pre>GET_jobFilters: {{ GET_jobFilters }}</pre> -->

        <v-row v-if="list && $vuetify.breakpoint.mdAndUp" class="pa-3 pb-0 mt-0">
            <v-data-table
                :item-class="itemRowBackground"
                sortable
                dense
                class=""
                style="width: 100%"
                :headers="computedHeaders"
                :items="computedItems"
                :server-items-length="itemsTotal"
                :options.sync="datatableOptions"
            
            >
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.jobClientId`]="{ item }">
                    <div>{{ getClient(item.jobClientId)}}</div>
                </template>
                <template v-slot:[`item.swappStatus`]="{ item }">
                    <div v-if="item.swappStatus == null || item.swappStatus == 'UNKNOWN'">-</div>
                    <div v-else>{{ format(item.swappStatus) }}</div>
                </template>
                <template v-slot:[`item.jobServiceId`]="{ item }">
                    <div>{{ getService(item.jobServiceId)}}</div>
                </template>
                <template v-slot:[`item.jobAssignedUserId`]="{ item }">
                    <div>{{ getUser(item.jobAssignedUserId)}}</div>
                </template>
                <template v-slot:[`item.jobSiteId`]="{ item }">
                    <div>{{ getSite(item.jobSiteId)}}</div>
                </template>
                <template v-slot:[`item.jobDate`]="{ item }">
                    <div>{{ $moment(item.jobStartDateTime).format('DD-MMM-YYYY')}}</div>
                </template>
                <template v-slot:[`item.jobStartTime`]="{ item }">
                    <div>{{ $moment(item.jobStartDateTime).format('HH:mm')}}</div>
                </template>
                <template v-slot:[`item.swappStartTime`]="{ item }">
                    <div v-if="item.swappStatus == null || item.swappStatus == 'UNKNOWN'">-</div>
                    <div v-else>{{ $moment(item.swappStartTime).format('HH:mm') }}</div>
                </template>
                <template v-slot:[`item.jobEndTime`]="{ item }">
                    <div>{{ $moment(item.jobEndDateTime).format('HH:mm')}}</div>
                </template>
                <template v-slot:[`item.swappEndTime`]="{ item }">
                    <div v-if="item.swappStatus == null || item.swappStatus == 'UNKNOWN' || item.swappStatus !== 'finished'">-</div>
                    <div v-else>{{ $moment(item.swappEndTime).format('HH:mm') }}</div>
                </template>
                <template v-slot:[`item.jobAssignedStatus`]="{ item }">
                    <v-icon 
                    class="pa-1" 
                    :class="
                        item.jobAssignedStatus == 'ALLOCATED' ? 'success--text' : 
                        item.jobAssignedStatus == 'UNALLOCATED' ? 'grey--text' : 
                        item.jobAssignedStatus == 'CANCELLED' ? 'error--text' : '' 
                    ">
                        icons8-filled-circle
                    </v-icon>
                </template>
                <template v-slot:[`item.jobPublishedStatus`]="{ item }">
                    <v-icon 
                    class="pa-1" 
                    :class="
                        item.jobPublishedStatus == 'PUBLISHED' ? 'success--text' : 
                        item.jobPublishedStatus == 'UNPUBLISHED' ? 'grey--text' : 
                        item.jobPublishedStatus == 'CANCELLED' ? 'error--text' : '' 
                    ">
                        icons8-checkmark-yes
                    </v-icon> 
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-icon class="error--text pa-1" v-if="item.deleted === '1' && toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">icons8-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2" @click="deleteItem(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">icons8-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2" @click="restoreItem(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">icons8-trash-restore</v-icon>
                    <v-icon class="primary--text" @click="openItem(item)">icons8-forward</v-icon>
                </template>
            </v-data-table>
        </v-row>

        <v-row v-if="!list && $vuetify.breakpoint.mdAndUp" class="pa-2 pb-0 pt-0 mt-0">
            <v-col v-for="(day, index) in days" :key="index" class="px-1 text-center d-flex flex-column">
                <job-list :searchQuery="computedSearchQuery" v-on:ready="ready++" v-on:notReady="ready--" :day="day" :clients="clients" :sites="sites" :users="users" :services="services" :startLoading="startLoading"/>
            </v-col>
        </v-row>


        <v-col cols="12" class="pa-0 ma-0 " v-if="$vuetify.breakpoint.smAndDown">
            <div v-for="job in computedMobileItems" :key="job.entityId">
                <job-card class="mb-2" :job="job" :jobsPage="true"></job-card>
            </div>
        </v-col>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import JobCard from '@/views/jobs/jobCard.vue';
import JobList from '@/views/jobs/jobCardList.vue';
export default {
    components: {
        'job-card': JobCard,
        'job-list': JobList,
    },
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
        list: {
            type: Boolean, 
            default: false
        },
        client: {
            type: Object,
            default: () => ({}),
        },
        user: {
            type: Object,
            default: () => ({}),
        },
        site: {
            type: Object,
            default: () => ({}),
        },
        filters: {
            type: Object, 
            default: () => ({})
        }
    },
    data: () => ({
        type: '',
        status: '',
        key: 'jobs',
        index: 'idx:jobs',
        service: {},
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'Allocated Status', align: 'center', width: '20px', value: 'jobAssignedStatus', sortable: false, user: false, client: true, site: true, recurring: true },
            { text: 'Published Status', align: 'center', width: '20px', value: 'jobPublishedStatus', sortable: false, user: true, client: true, site: true, recurring: true },
            { text: 'Status', value: 'swappStatus', sortable: false, user: true, client: false, site: false, recurring: false },
            { text: 'Assigned User', value: 'jobAssignedUserId', sortable: false, user: false, client: true, site: true, recurring: true },
            { text: 'Client Name', value: 'jobClientId', sortable: false, user: true, client: false, site: false, recurring: false },
            { text: 'Site Name', value: 'jobSiteId', sortable: false, user: true, client: true, site: false, recurring: false },
            { text: 'Service', value: 'jobServiceId', sortable: false, user: false, client: true, site: true, recurring: false },
            { text: 'Date', value: 'jobDate', sortable: false, user: true, client: true, site: true, recurring: true },
            { text: 'Allocated Start', value: 'jobStartTime', sortable: false, user: true, client: false, site: false, recurring: false },
            { text: "SWAPP'd Start", value: 'swappStartTime', sortable: false, user: true, client: false, site: false, recurring: false },
            { text: 'Start Time', value: 'jobStartTime', sortable: false, user: false, client: true, site: true, recurring: true },
            { text: 'Allocated End', value: 'jobEndTime', sortable: false, user: true, client: false, site: false, recurring: false },
            { text: "SWAPP'd End", value: 'swappEndTime', sortable: false, user: true, client: false, site: false, recurring: false },
            { text: 'End Time', value: 'jobEndTime', sortable: false, user: false, client: true, site: true, recurring: true },
            { text: 'Job Type', value: 'jobType', sortable: false, user: false, client: false, site: false, recurring: false },

            { text: '', value: 'action', align: 'end', sortable: false, user: true, client: true, site: true, recurring: true },
        ],
        // Fields to Retrieve from Database
        // fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        sortBy: '@courseName',
        datatableOptions: {
            page: 1,
            itemsPerPage: 5,
            sortBy: ['courseName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        filter: {
            staffMember: '',
            userCategory: '',
            status: '',
            site: '',
            service: '',
            client: '',
            publishedStatus: [],
            allocatedStatus: [],
            jobsFrom: '',
            jobsTo: '',
        },

        userCategories: [
            'Mobile Cleaner', 
            'Director', 
            'Commercial Manager', 
            'Office Staff', 
            'Security Staff', 
            'Cleaner', 
            'Supervisor',
            'Senior Manager'
        ],
        users: [],
        services: [],
        sites: [],
        clients: [],

        currentWeekStartDate: moment().startOf('week').add(1, 'days').format('DD-MMM-YYYY'),

        jobs: [],

        isRecurringRule: false, 
        recurringRule: {},


        date1: '',
        dateMenu1: false,
        date2: '',
        dateMenu2: false,
        date3: '',
        dateMenu3: false,

        showAll: false,


        computedRecurringStats: {
            total: 0,
            cancelled: 0,
            allocated: 0,
            unallocated: 0
        },

        ready: 0,
        startLoading: false
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
            GET_jobFilters: 'GET_jobFilters'
        }),
        ...mapActions({
            ACT_setJobFilters: 'ACT_setJobFilters'
        }),
        computedMobileItems() {
            var t = this;

            var items = t.items

            items.forEach((item) => {
                item.jobClientName = this.getClient(item.jobClientId)
                item.jobServiceName = this.getService(item.jobServiceId)
                item.jobSiteName = this.getSite(item.jobSiteId)
                item.jobAssignedUserName = this.getUser(item.jobAssignedUserId)
            })

            return items
        },
        computedUsers() {
            var t = this;
            var users = t.users
            var userCategory = ''
            if (this.filter.userCategory !== "" && this.filter.userCategory !== null && this.filter.userCategory !== undefined) {
                userCategory = this.filter.userCategory.replace(/\s/g, '').toUpperCase()
                console.log('userCategory = ' + userCategory)
                users = users.filter(function(item) {
                    return item.userCategory == userCategory
                })
            }

            return users 
        },
        computedDate1() {
            return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YYYY");
        },

        computedDate2() {
            return this.MIX_formatDateTime(this.date2, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
        computedDate3() {
            return this.MIX_formatDateTime(this.date3, "YYYY-MM-DD", "DD-MMM-YYYY");
        },

        computedSearchQuery() {
            var t = this
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '';
                } else {
                    searchQuery = '@deleted:{false}';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}`;
                    } else {
                        searchQuery = `${searchQuery}  @deleted:{false}`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}`;
                    } else {
                        searchQuery = `${searchQuery}  @deleted:{false}`;
                    }
                }
            }

            if (this.filter.client !== "" && this.filter.client !== null && this.filter.client !== undefined) {
                searchQuery = `${searchQuery} @jobClientId:{${this.filter.client}}`
            }


            if (this.filter.userCategory !== "" && this.filter.userCategory !== null && this.filter.userCategory !== undefined) {
                var users = []
                this.computedUsers.forEach((item) => {
                    users.push(item.entityId)
                })
                if (this.computedUsers.length > 0) {
                    searchQuery = `${searchQuery} @jobAssignedUserId:{${users.join("|")}}`
                }
                //console.log('users = ' + JSON.stringify(users, null, 2))
            }

            if (JSON.stringify(this.filter.publishedStatus) !== "[]") {
                searchQuery = `${searchQuery} @jobPublishedStatus:{${this.filter.publishedStatus.join("|")}}`
            }

            if (JSON.stringify(this.filter.allocatedStatus) !== "[]") {
                searchQuery = `${searchQuery} @jobAssignedStatus:{${this.filter.allocatedStatus.join("|")}}`
            }
            if (this.filter.service !== "" && this.filter.service !== null && this.filter.service !== undefined) {
                searchQuery = `${searchQuery} @jobServiceId:{${this.filter.service}}`
            }

            if (this.filter.site !== "" && this.filter.site !== null && this.filter.site !== undefined) {
                searchQuery = `${searchQuery} @jobSiteId:{${this.filter.site}}`
            }

            if (this.filter.staffMember !== "" && this.filter.staffMember !== null && this.filter.staffMember !== undefined) {
                searchQuery = `${searchQuery} @jobAssignedUserId:{${this.filter.staffMember}}`
            }

            if (this.filter.jobsFrom !== "" && this.filter.jobsFrom !== null && this.filter.jobsFrom !== undefined) {
                searchQuery = `${searchQuery} @jobStartDateTime:[${this.filter.jobsFrom} +inf]`
            }
            else if (this.list) {
                searchQuery = `${searchQuery} @jobStartDateTime:[${moment(this.currentWeekStartDate).format('X')} +inf]`
            }

            if (this.filter.jobsTo !== "" && this.filter.jobsTo !== null && this.filter.jobsTo !== undefined) {
                searchQuery = `${searchQuery} @jobStartDateTime:[-inf ${this.filter.jobsTo}]`
            }

            if (this.isRecurringRule) {
                searchQuery = `${searchQuery} @jobRecurringRuleId:{${this.recurringRule.entityId}}`
                searchQuery = `${searchQuery} @jobStartDateTime:[${moment(this.currentWeekStartDate).format('X')} ${moment(this.currentWeekStartDate).add(7, 'days').format('X')}]`
            }

            if (this.client.entityId) {
                searchQuery = `${searchQuery} @jobClientId:{${this.client.entityId}}`
            }

            if (this.user.entityId) {
                searchQuery = `${searchQuery} @jobAssignedUserId:{${this.user.entityId}}`
            }

            if (this.site.entityId) {
                searchQuery = `${searchQuery} @jobSiteId:{${this.site.entityId}}`
            }

            if (t.filters.showAllocated === true && t.filters.showAll === false) {
                searchQuery = `${searchQuery} @jobAssignedStatus:{ALLOCATED} @jobPublishedStatus:{UNPUBLISHED | PUBLISHED}`
            }

            if (t.filters.showUnallocated === true && t.filters.showAll === false) {
                searchQuery = `${searchQuery} @jobAssignedStatus:{UNALLOCATED} @jobPublishedStatus:{ UNPUBLISHED | PUBLISHED}`
            }

            if (t.filters.showCancelled === true && t.filters.showAll === false) {
                searchQuery = `${searchQuery} @jobPublishedStatus:{CANCELLED}`
            }

            console.log('searchQuery = ' + searchQuery)

            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;

            if (this.client.entityId) {
                headers = headers.filter((header) => header.client)
            }
            else if (this.user.entityId) {
                headers = headers.filter((header) => header.user)
            }
            else if (this.site.entityId) {
                headers = headers.filter((header) => header.site)
            }
            else if (this.isRecurringRule) {
                headers = headers.filter((header) => header.recurring)
            }
            return headers;
        },
        days() {
            return [
                moment(this.currentWeekStartDate).format('DD-MMM-YYYY'),
                moment(this.currentWeekStartDate).add(1, 'days').format('DD-MMM-YYYY'),
                moment(this.currentWeekStartDate).add(2, 'days').format('DD-MMM-YYYY'),
                moment(this.currentWeekStartDate).add(3, 'days').format('DD-MMM-YYYY'),
                moment(this.currentWeekStartDate).add(4, 'days').format('DD-MMM-YYYY'),
                moment(this.currentWeekStartDate).add(5, 'days').format('DD-MMM-YYYY'),
                moment(this.currentWeekStartDate).add(6, 'days').format('DD-MMM-YYYY'),
            ]
        },
        
        computedItems() {
            var items = this.items;
            if (this.type) {
                items = this.items.filter((item) => item.actionType === this.type);
            }
            if (this.status) {
                items = this.items.filter((item) => item.actionStatus === this.status);
            }
            return items;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            // if (!computedFields.includes('@actionId')) {
            //     computedFields.push('@actionId');
            // }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedType() {
            const types = [];
            this.items.filter((item) => {
                return types.push(item.actionType);
            });
            return types;
        },
        computedStatus() {
            const status = [];
            this.items.filter((item) => {
                return status.push(item.actionStatus);
            });
            return status;
        },
    },
    methods: {
        format(str) {
            const lower = str.toLowerCase()
            return str.charAt(0).toUpperCase() + lower.slice(1)
        },
        async getJobDetailsMobile() {
            var t = this; 

            t.items = []

            var searchQuery = `@deleted:{false} @jobAssignedUserId:{ ${t.GET_FIREBASE_currentUser.entityId }} @jobStartDateTime:[${moment(t.currentWeekStartDate).format('X')} ${moment(t.currentWeekStartDate).add(7, 'days').format('X')}] @jobPublishedStatus:{PUBLISHED}`

            // console.log(searchQuery)

            let itemsResult = await this.REDIS_searchFor('jobs', this.itemsFrom, this.datatableOptions.itemsPerPage, 'jobStartDateTime', 'ASC', searchQuery)

            if (itemsResult.success) {
                this.itemsTotal = itemsResult.data.total
                this.items = itemsResult.data.documents
                this.loading = false
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo
                } else {
                    this.itemsTo = this.itemsTotal
                }

            }
        },
        nextWeek() {
            this.currentWeekStartDate = this.addDays(this.currentWeekStartDate, 7);
            this.$emit('dateChanged', this.currentWeekStartDate)
            this.ready = 0
            // this.getWeekData()
        },
        priorWeek() {
            this.currentWeekStartDate = this.subtractDays(this.currentWeekStartDate, 7);
            this.$emit('dateChanged', this.currentWeekStartDate)
            this.ready = 0
            // this.getWeekData()
        },
        addDays(date, days) {
            let newDate = moment(date).add(days, 'days').format('DD-MMM-YYYY');
            return newDate;
        },
        subtractDays(date, days) {
            let newDate = moment(date).subtract(days, 'days').format('DD-MMM-YYYY');
            return newDate;
        },
        computedJobs(day) {
            var t = this
            var items = {}

            var i = 0 
            if (i == 0) {
                // t.getJobItems(day)
                i++
            }

            return items
        },
        // Clear Item Property
        clearFilter(property) {
            setTimeout(() => {
                this.filter[property] = '';
            }, 1);
        },
        async editItem(id) {
            // Get the item from the database
            let itemResult = await this.MIX_readItem(id, 'service');
            if (itemResult.code === 1) {
                this.$emit('edit', itemResult.data);
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Editing Component', show: true });
            }
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        refreshItems: function () {
            this.getItems();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            // this.ACT_setJobFilters(this.filter)
            this.MIX_go('/job/' + item.entityId)
        },
        // * OPEN JOB ITEM
        openJob(item) {
            this.$emit('openJob', JSON.parse(JSON.stringify(item)));
        },
        openRecurringJob(item) {
            this.$emit('openRecurringJob', JSON.parse((JSON.stringify(item))))
        },
        async getJobItems(day) {
            var t = this;

            var startOfDay = t.$moment(day, 'DD-MMM-YYYY').startOf('day').format('X')
            var endOfDay = t.$moment(day, 'DD-MMM-YYYY').endOf('day').format('X')

            let itemsResult = await this.REDIS_searchFor('jobs', 0, 9999, 'jobStartDateTime','ASC', `@deleted:{false} @jobStartDateTime:[${startOfDay} ${endOfDay} ]` )
            if (itemsResult.success) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.documents;
                // let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                // if (itemsTo < this.itemsTotal) {
                //     this.itemsTo = itemsTo;
                // } else {
                //     this.itemsTo = this.itemsTotal;
                // }
            }
        },
        getClient(clientId) {
            var t = this;
            var client = []
            var clientName = ''

            client = t.clients.filter((item) => { return item.entityId == clientId })

            if (client.length !== 0) { clientName = client[0].clientName }
            return clientName
        },
        getUser(userId) {
            var t = this;
            var user = []
            var userName = ''

            user = t.users.filter((item) => { return item.entityId == userId })

            if (user.length !== 0) { userName = user[0].userName }

            return userName
        },
        getSite(siteId) {
            var t = this;
            var site = []
            var siteName = ''

            site = t.sites.filter((item) => { return item.entityId == siteId })

            if (site.length !== 0) { siteName = site[0].siteName }

            return siteName
        },
        getService(serviceId) {
            var t = this;
            var service = []
            var serviceName = ''

            service = t.services.filter((item) => { return item.entityId == serviceId })

            if (service.length !== 0) { serviceName = service[0].serviceName }

            return serviceName
        },
        // * GET ITEMS
        async getItems() {
            if (this.list && this.$vuetify.breakpoint.mdAndUp) {
                let itemsResult = await this.REDIS_searchFor('jobs', this.itemsFrom, this.datatableOptions.itemsPerPage, 'jobStartDateTime','ASC', this.computedSearchQuery )
                if (itemsResult.success) {
                    this.itemsTotal = itemsResult.data.total;
                    this.items = itemsResult.data.documents;
                    let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                    if (itemsTo < this.itemsTotal) {
                        this.itemsTo = itemsTo;
                    } else {
                        this.itemsTo = this.itemsTotal;
                    }
                }
            }
        },
        getCardColour(day) {
            if (moment(day).format('DD-MMM-YYYY') === moment().format('DD-MMM-YYYY')) {
                return 'primary'
            } else {
                return 'grey lighten-1'
            }
        },
        async getStats() {
            var t = this;
            
            var recurringRule = this.recurringRule.entityId

            // console.log(recurringRule)

            let total = await this.REDIS_count(t.key, `@deleted:{false} @jobRecurringRuleId:{${recurringRule}}`)
            // console.log('total = ' + JSON.stringify(total, null, 2))
            if (total.success) {
                t.computedRecurringStats.total = total.total
            }

            let cancelled = await this.REDIS_count(t.key, `@deleted:{false} @jobRecurringRuleId:{${recurringRule}} @jobPublishedStatus:{'CANCELLED'}`)
            if (cancelled.success) {
                t.computedRecurringStats.cancelled = cancelled.total
            }

            let allocated = await this.REDIS_count(t.key, `@deleted:{false} @jobRecurringRuleId:{${recurringRule}} @jobAssignedStatus:{'ALLOCATED'} @jobPublishedStatus:{'UNPUBLISHED' | 'PUBLISHED'}`)
            if (allocated.success) {
                t.computedRecurringStats.allocated = allocated.total
            }

            let unallocated = await this.REDIS_count(t.key, `@deleted:{false} @jobRecurringRuleId:{${recurringRule}} @jobAssignedStatus:{'UNALLOCATED'} @jobPublishedStatus:{'UNPUBLISHED' | 'PUBLISHED'}`)
            if (unallocated.success) {
                t.computedRecurringStats.unallocated = unallocated.total
            }
        },
    },
    watch: {
        filter: {
            handler() {
                // console.log('filter changed = ' + JSON.stringify(this.filter, null, 2))
                var filters = this.filter
                // this.ACT_setJobFilters(JSON.stringify(filters))
                localStorage.setItem('jobFilters', JSON.stringify(filters))
            }, 
            deep: true
        },
        dateMenu1: {
            handler: async function () {
                this.filter.jobsFrom = this.MIX_formatDateTime(this.date1, 'YYYY-MM-DD', 'X');
            },
            deep: true,
        },
        dateMenu2: {
            handler: async function () {
                this.filter.jobsTo = this.MIX_formatDateTime(this.date2, 'YYYY-MM-DD', 'X');
            },
            deep: true,
        },
        dateMenu3: {
            handler: async function () {
                this.currentWeekStartDate = this.$moment(this.date3, 'YYYY-MM-DD').startOf('week').add(1, 'days').format('DD-MMM-YYYY');
            },
            deep: true,
        },
        datadown: {
            handler: function () {
                this.recurringRule = { ...this.datadown };
                if (JSON.stringify(this.recurringRule) !== '{}') {
                    this.isRecurringRule = true
                } else {
                    this.isRecurringRule = false
                }
                //console.log('this.recurringRule = ' + JSON.stringify(this.recurringRule, null, 2))
            },
            deep: true,
        },
        refreshitems() {
            this.getItems();
            if (this.isRecurringRule) {
                setTimeout(() => {
                    this.getStats()
                }, 1000)
            }
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        computedFields() {
            // this.getItems();
        },
        toggleDeletedItems() {
            // this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },

    },
    async created() {
        var t = this;
        this.recurringRule = { ...this.datadown };
        if (JSON.stringify(this.recurringRule) !== '{}') {
            this.isRecurringRule = true
            this.getStats()
        } else {
            this.isRecurringRule = false
        }
        // //console.log('this.recurringRule = ' + JSON.stringify(this.recurringRule, null, 2))
        
        let users = await t.REDIS_searchFor('user', '0', '999', 'userName', 'ASC', '@deleted:{false} @userStatus:{APPROVED}');
        if (users.success) { t.users = users.data.documents; }

        t.users = t.users.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
                userCategory: x.userCategory
            };
        })


        let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false} -@clientStatus:{ARCHIVE}');
        if (clients.success) { t.clients = clients.data.documents; }

        t.clients = t.clients.map((x) => {
            return {
                entityId: x.entityId,
                clientName: x.clientName,
            };
        })

        
        let services = await t.REDIS_searchFor('service', '0', '9999', 'serviceName', 'ASC', '@deleted:{false}');
        if (services.success) { t.services = services.data.documents; }

        t.services = t.services.map((x) => {
            return {
                entityId: x.entityId,
                serviceName: x.serviceName,
            };
        })

        let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
        if (sites.success) { t.sites = sites.data.documents; }

        t.sites = t.sites.map((x) => {
            return {
                entityId: x.entityId,
                siteName: x.siteName,
            };
        })

        this.startLoading = true

        if (this.list && this.$vuetify.breakpoint.mdAndUp) {
            this.getItems();
        } else if (this.$vuetify.breakpoint.smAndDown) {
            this.getJobDetailsMobile()
        }

        if (JSON.stringify(this.user) == '{}' && JSON.stringify(this.client) == '{}' && JSON.stringify(this.site) == '{}' && this.isRecurringRule == false) {
            var filter = JSON.parse(localStorage.getItem('jobFilters'))
            filter.jobsFrom = ''
            filter.jobsTo = ''
        }


        if (filter !== null && filter !== undefined) {
            this.filter = filter
        }

    },
};
</script>

<style>

#customDisabled.v-btn--disabled {
    background-color: #308CCB !important;
    opacity: 0.7 !important;
}
</style>