<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: auto !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" >
                    <v-btn text class="primary white--text" @click="newSite" ><v-icon class="mr-2">oomph-save</v-icon>Add</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="apptext--text pa-5 pt-3" dense style="z-index: 0 !important">

            <!-- <div class="font-weight-medium">Below is a list of Sites linked to the Client. To add a new Site click Add and follow the instructions. To edit or delete an existing Site, select the appropriate button from the Actions column.</div> -->

            <!--//  TODO CHANGE TO SITE LIST -->
            <SitesList :client="item" v-on:openItem="openSite"/>

        </v-row>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
// import schema from '@/schema.js';
import SitesList from '@/views/sites/siteList.vue'

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        // activeitem: {
        //     Type: Object,
        //     Default: null,
        // },
    },
    components: {
        'SitesList': SitesList
    },
    // * DATA
    data: () => ({
        key: 'action',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,
        // dropdowns
        packageTypes: [],
        billingCycles: [],
        packageStatus: [],
        selectedPackageIcon: {
            name: '',
            icon: ''
        },  
        months: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],

        // rules
        packageCustomerDescriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],
        packageInternalDescriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],

        icons: [
            {
                name: 'On Demand',
                icon: 'oomph-video-playlist'
            },
            {
                name: 'Training',
                icon: 'oomph-literature'
            },
            {
                name: 'Exercises',
                icon: 'oomph-dumbbell'
            },
            {
                name: 'Activities',
                icon: 'oomph-list'
            },
            {
                name: 'Assessment',
                icon: 'oomph-clipboard-list'
            },
            {
                name: 'Wellbeing',
                icon: 'oomph-lotus'
            }
        ],

        // new address object
        item: {},
        loading: false,
        rules: {},

    }),
    computed: {  
    },
    methods: {
        initialise() {
            this.item = { ...this.datadown };
            //console.log('item', JSON.stringify(this.item, null, 2));
        },
        updateItem() {
            this.$emit('update', this.item);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {
            this.$emit('save', this.item);
            this.editmode = false;
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        openSite(item) {
            this.MIX_go('/sites/' + item.entityId)

        },
        newSite() {
            this.MIX_go('/sites/new')
        }
    },
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        // activeitem: {
        //     handler: function () {
        //         let t = this;
        //         t.item = { ...t.activeitem };
        //     }, deep: true
        // },
        selectedPackageIcon: {
            handler: function () {
                this.item.packageIcon = this.selectedPackageIcon.icon;
            }, deep: true
        },

    },
    // * CREATED
    async created() {
        let t = this;
        // t.schema = schema.package;
        t.loading = true
        t.initialise();

        t.loading = false
    },
    mounted() {},
};
</script>