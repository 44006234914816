<template>
    <div class="" style="width: 100% !important">
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field
                    label="Search Communications"
                    v-model="searchQuery"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    color="primary"
                    clearable
                    prepend-inner-icon="icons8-search"
                    @click:clear="clearSearchQuery()"
                ></v-text-field>
            </div>
            <div v-if="$vuetify.breakpoint.mdAndUp" class="flex-grow-1 ml-2">
                <v-select 
                    class="" 
                    hide-details="auto" 
                    label="Clients" 
                    filled 
                    item-value="entityId" 
                    v-model="filter.client"  
                    outlined
                    dense
                    clearable
                    :items="clients" 
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="clientName" 
                ></v-select>
            </div>
            <div v-if="$vuetify.breakpoint.mdAndUp" class="flex-grow-1 ml-2">
                <v-select 
                    class="" 
                    hide-details="auto" 
                    label="Sites" 
                    filled 
                    v-model="filter.site"  
                    outlined
                    dense
                    clearable
                    :items="filteredSites" 
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="siteName" 
                    item-value="entityId" 
                ></v-select>
            </div>
            <div v-if="$vuetify.breakpoint.mdAndUp" class="flex-grow-1 ml-2">
                <v-select 
                    class="" 
                    hide-details="auto" 
                    label="Assigned To" 
                    filled 
                    v-model="filter.owner"  
                    outlined
                    dense
                    clearable
                    :items="managers" 
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="userName" 
                    item-value="entityId" 
                ></v-select>
            </div>
            <v-btn v-if="$vuetify.breakpoint.lgAndUp" depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="refreshItems">
                <v-icon>icons8-available-updates</v-icon>
            </v-btn>
            <div v-if="$vuetify.breakpoint.lgAndUp" class="ml-2">
                <v-menu
                    offset-y
                    class="white"
                    style="z-index: 99999 !important; width: 200px !important; height: 200px !important"
                    :close-on-content-click="false"
                    origin="top right"
                    left
                    transition="scale-transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"
                            ><v-icon>icons8-table-properties</v-icon></v-btn
                        >
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox
                                v-model="headers[index].hidden"
                                dense
                                hide-details="auto"
                                v-for="(item, index) in headers"
                                :key="index"
                                :label="item.text"
                                :true-value="false"
                                :false-value="true"
                            ></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>
        <div class="d-flex pt-2">
            <div v-if="$vuetify.breakpoint.mdAndUp">
                <div class="caption font-weight-bold grey--text text--darken-1">STATUS</div>
                <v-btn-toggle v-model="filter.ticketStatus" multiple color="white" class="mr-2" active-class="white" background-color="lightgrey" dense>
                    <v-btn value="Open" :class="filter.ticketStatus.includes('Open') ? 'orange white--text' : 'orange--text'" small>
                        <v-icon :class="filter.ticketStatus.includes('Open') ? 'white--text' : 'orange--text'" class="text-h5 icons8-open-message"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Open</span>
                    </v-btn>
                    <v-btn value="Pending" :class="filter.ticketStatus.includes('Pending') ? 'orange white--text' : 'orange--text'" small>
                        <v-icon :class="filter.ticketStatus.includes('Pending') ? 'white--text' : 'orange--text'" class="text-h5 icons8-clock"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Pending</span>
                    </v-btn>
                    <v-btn value="Solved" :class="filter.ticketStatus.includes('Solved') ? 'success white--text' : 'success--text'" small>
                        <v-icon :class="filter.ticketStatus.includes('Solved') ? 'white--text' : 'success--text'" class="text-h5 icons8-checkmark-yes"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Solved</span>
                    </v-btn>
                    <v-btn value="Closed" :class="filter.ticketStatus.includes('Closed') ? 'success white--text' : 'success--text'" small>
                        <v-icon :class="filter.ticketStatus.includes('Closed') ? 'white--text' : 'success--text'" class="text-h5 icons8-cancel"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Closed</span>
                    </v-btn>
                    <v-btn value="Archived" :class="filter.ticketStatus.includes('Archived') ? 'error white--text' : 'error--text'" small>
                        <v-icon :class="filter.ticketStatus.includes('Archived') ? 'white--text' : 'error--text'" class="text-h5 icons8-archive"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Archived</span>
                    </v-btn>
                </v-btn-toggle>
            </div>
            <div v-if="$vuetify.breakpoint.mdAndUp && !listshrunk && listexpanded">
                <div class="caption font-weight-bold grey--text text--darken-1">TYPE</div>
                <v-btn-toggle v-model="filter.ticketType" multiple color="white" class="mr-2" active-class="white" background-color="lightgrey" dense>
                    <v-btn value="Complaint" small>
                        <span class="caption font-weight-bold grey--text">COMPLAINT</span>
                    </v-btn>
                    <v-btn value="Security Issue" small>
                        <span  class="caption font-weight-bold grey--text">SECURITY ISSUE</span>
                    </v-btn>
                    <v-btn value="General Enquiry" small>
                        <span class="caption font-weight-bold grey--text">GENERAL ENQUIRY</span>
                    </v-btn>
                    <v-btn value="Maintenance" small>
                        <span class="caption font-weight-bold grey--text">MAINTENANCE</span>
                    </v-btn>
                    <v-btn value="Stock Request" small>
                        <span class="caption font-weight-bold grey--text">STOCK REQUEST</span>
                    </v-btn>
                    <v-btn value="Other" small>
                        <span class="caption font-weight-bold grey--text">OTHER</span>
                    </v-btn>
                </v-btn-toggle>
            </div>
        </div>
        <!-- <pre>listexpanded: {{ listexpanded }}</pre> -->
        <v-row class="pa-3 pt-6">
            <!-- TABLE BASED LIST -->
            <v-data-table
                :item-class="itemRowBackground"
                dense
                class=""
                style="width: 100%"
                :headers="computedHeaders"
                :items="items"
                :options.sync="datatableOptions"
                v-if="$vuetify.breakpoint.mdAndUp"
                :server-items-length="itemsTotal"
                :loading="loading"
                :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50]
                }"
            >
                <!-- :server-items-length="itemsTotal" -->
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn title="Delete" value="delete" small>
                                <v-icon class="grey--text text--darken-3">icons8-trash-can</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </template>

                <template v-slot:[`item.ticketStatus`]="{ item }">
                    <v-icon v-if="item.ticketStatus == 'Open'" class="text-h5 orange--text icons8-open-message"></v-icon>
                    <v-icon v-if="item.ticketStatus == 'On Hold'" class="text-h5 grey--text icons8-pause-button"></v-icon>
                    <v-icon v-if="item.ticketStatus == 'Pending'" class="text-h5 orange--text icons8-clock"></v-icon>
                    <v-icon v-if="item.ticketStatus == 'Solved'" class="text-h5 green--text icons8-checkmark-yes"></v-icon>
                    <v-icon v-if="item.ticketStatus == 'Closed'" class="text-h5 green--text icons8-cancel"></v-icon>
                    <v-icon v-if="item.ticketStatus == 'Archived'" class="text-h5 error--text icons8-archive"></v-icon>
                </template>

                <template v-slot:[`item.ticketOwnerId`]="{ item }">
                    {{ getUser(item.ticketOwnerId) }}
                </template>

                <template v-slot:[`item.ticketReadDateTime`]="{ item }">
                    <div v-if="item.ticketReadDateTime && item.ticketReadDateTime !== ''">{{ $moment(item.ticketReadDateTime).format('HH:mm DD-MMM-YYYY') }}</div>
                </template>

                <template v-slot:[`item.ticketCompleted`]="{ item }">
                    <v-icon 
                    class="pa-1" 
                    :class="
                        item.ticketCompleted ? 'success--text' : '' 
                    ">
                        icons8-checkmark-yes
                    </v-icon>
                </template>

                <template v-slot:[`item.ticketCompletedDateTime`]="{ item }">
                    <div v-if="item.ticketCompletedDateTime && item.ticketCompletedDateTime !== ''">{{ $moment(item.ticketCompletedDateTime).format('HH:mm DD-MMM-YYYY') }}</div>
                </template>

                <template v-slot:[`item.ticketOrgId`]="{ item }">
                    {{ getClient(item.ticketOrgId) }}
                </template>
                <template v-slot:[`item.ticketSiteId`]="{ item }">
                    {{ getClient(item.ticketOrgId) }} - 
                    {{ getSite(item.ticketSiteId) }}
                </template>

                <template v-slot:[`item.modifiedDateTime`]="{ item }">
                    <div v-if="item.modifiedDateTime && item.modifiedDateTime !== null">{{ $moment(item.modifiedDateTime).format('HH:mm DD-MMM-YYYY') }}</div>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                    <!-- <v-icon class="error--text pa-1" v-if="item.deleted === true && toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">icons8-delete-trash</v-icon> -->
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.entityId)" v-if="item.deleted === false && toggleFeatures.includes('delete')">icons8-trash-can</v-icon>
                    <!-- <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === true && toggleFeatures.includes('undelete')">icons8-trash-restore</v-icon> -->
                    <v-icon class="primary--text ml-5" @click="openItem(item)">icons8-forward</v-icon>
                </template>
            </v-data-table>
            <!-- <pre>{{ items }}</pre> -->


            <v-col class="tabledata pa-0 ma-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-row v-if="$vuetify.breakpoint.smAndDown" class="d-flex flex-column align-center " no-gutters>
                    <div v-if="itemsFrom > 0">
                        
                        <v-btn block @click="goBack" class="elevation-0 darkgrey lighten-3 white--text  mb-2 font-weight-bold">
                        + GO BACK 
                        </v-btn>
                    </div>
                    <v-col cols="12" xs="12" class="my-1 pa-1 white tablecard" v-for="(item, index) in computedItems" :key="index">
                        <div>
                            <ticketCard :item="item" v-on:openTicket="openItem" />
                        </div>
                    </v-col>
                    <v-row v-if="loading === true" class="align-center pt-5 justify-center">
                        <v-progress-circular :size="60" :width="7" indeterminate color="primary" ></v-progress-circular>
                    </v-row>
                    <div v-else-if="computedItems.length == 0" class="font-weight-bold grey--text text--darken-1 body-1">
                        NO COMMUNICATIONS
                    </div>
                    <div v-else-if="computedItems.length !== itemsTotal && computedItems.length == datatableOptions.itemsPerPage">
                        
                        <v-btn block @click="loadMore" class="elevation-0 darkgrey lighten-3 white--text font-weight-bold">
                        + SHOW MORE 
                        </v-btn>
                    </div>
                    <div v-else-if="computedItems.length <= itemsTotal && computedItems.length > 0" class="font-weight-bold grey--text text--darken-1 pt-2 body-1">
                        SHOWING ALL COMMUNICATIONS
                    </div>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'; // Map the Getters from the Vuex Store
import ticketCard from '@/views/communications/ticketCard.vue'
export default {
    components: {
        'ticketCard': ticketCard
    },
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        type: '',
        status: '',
        key: 'ticket',
        service: {},
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'ID', value: 'entityId', sortable: false, hidden: true, expanded: false },
            { text: 'Status', value: 'ticketStatus', sortable: false, hidden: false, expanded: true },
            { text: 'Type', value: 'ticketType', sortable: false, hidden: false, expanded: false },
            { text: 'Subject', value: 'ticketSubject', sortable: false, hidden: false, expanded: true },
            { text: 'Message', value: 'ticketMessage', sortable: false, hidden: false, expanded: false },
            { text: 'Client', value: 'ticketOrgId', sortable: false, hidden: true, expanded: true },
            { text: 'Site', value: 'ticketSiteId', sortable: false, hidden: false, expanded: true },
            { text: 'Assigned To', value: 'ticketOwnerId', sortable: false, hidden: false, expanded: true },

            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: false, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: true },
        ],
        sortBy: '@ticketSubject',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['modifiedDateTime'],
            sortDesc: [true],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        filter: {
            site: '',
            client: '',
            ticketStatus: [],
            ticketType: [],
            owner: ''
        },

        loading: true,

        users: [], 
        clients: [],
        sites: [],
        managers: [],
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
        filteredSites() {
            if (this.filter.client === '' || this.filter.client === null || this.filter.client === undefined) {
                return this.sites;
            } else {
                return this.sites.filter((site) => {
                    return site.siteClientId === this.filter.client;
                });
            }
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '*';
                } else {
                    searchQuery = '@deleted:{false}';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:{false}`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:{false}`;
                    }
                }
            }
            if (this.filter.site !== "" && this.filter.site !== null && this.filter.site !== undefined) {
                searchQuery = `${searchQuery} @ticketSiteId:${this.filter.site}`
            }
            if (this.filter.client !== "" && this.filter.client !== null && this.filter.client !== undefined) {
                searchQuery = `${searchQuery} @ticketOrgId:${this.filter.client}`
            }
            if (this.filter.owner !== "" && this.filter.owner !== null && this.filter.owner !== undefined) {
                searchQuery = `${searchQuery} @ticketOwnerId:{${this.filter.owner}}`
            }
            if (this.filter.ticketType.length > 0) {
                searchQuery = `${searchQuery}  @ticketType:{${this.filter.ticketType.join('|')}}`;
            }
            if (this.filter.ticketStatus.length > 0) {
                searchQuery = `${searchQuery}  @ticketStatus:{${this.filter.ticketStatus.join('|')}}`;
            }

            if (this.GET_FIREBASE_currentUser.userLevel !== 'SA' && this.GET_FIREBASE_currentUser.userLevel !== 'DA') {
                searchQuery = `${searchQuery} @ticketSiteId:${this.GET_FIREBASE_currentUser.lastSiteId} -@ticketType:{'COMPLAINT'}` 
            }

            console.log(searchQuery)
            
            return searchQuery;
        },
        // Comp
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (!this.listexpanded) {
                headers = headers.filter((header) => header.expanded === true); // Return Headers based on Expanded State
            } 
            // else {
            //     headers = headers.filter((header) => header.expanded === false); // Return Headers based on Expanded State
            // }
            return headers;
        },
        computedItems() {
            var items = this.items;
            
            return items;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            // if (!computedFields.includes('@actionId')) {
            //     computedFields.push('@actionId');
            // }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedType() {
            const types = [];
            this.items.filter((item) => {
                return types.push(item.actionType);
            });
            return types;
        },
        computedStatus() {
            const status = [];
            this.items.filter((item) => {
                return status.push(item.actionStatus);
            });
            return status;
        },
    },
    methods: {
        getClient(clientId) {
            var t = this;
            var client = []
            var clientName = ''

            client = t.clients.filter((item) => { return item.entityId == clientId })

            if (client.length !== 0) { clientName = client[0].clientName }
            return clientName
        },
        getSite(siteId) {
            var t = this;

            var site = []
            var siteName = ''

            site = t.sites.filter((item) => { return item.entityId == siteId })

            if (site.length !== 0) { siteName = site[0].siteName }
            return siteName
        },
        getUser(userId) {
            var t = this;
            var user = []
            var userName = ''

            user = t.users.filter((item) => { return item.entityId == userId })

            if (user.length !== 0) { userName = user[0].userName }

            return userName
        },
        async loadMore() {
            this.itemsFrom = this.itemsFrom + this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo + this.datatableOptions.itemsPerPage;
            this.getItems();
        },
        async goBack() {
            this.itemsFrom = this.itemsFrom - this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo - this.datatableOptions.itemsPerPage;
            this.getItems()
        },
        async editItem(id) {
            // Get the item from the database
            let itemResult = await this.MIX_readItem(id, 'service');
            if (itemResult.code === 1) {
                this.$emit('edit', itemResult.data);
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Editing Component', show: true });
            }
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        refreshItems: function () {
            this.getItems();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        async createProgress(jobId, type, status) {
            let t = this;
            let progress = {}
            
            progress.linkedId = jobId
            progress.type = type
            progress.status = status
            progress.totalItems = 1
            progress.count = 1
            progress.success = 1
            progress.error = 0
            progress.createdDateTime = t.$moment().toISOString()
            progress.createdUserId = t.GET_FIREBASE_currentUser.entityId
            console.log('progress = ' + JSON.stringify(progress, null, 2))
            await this.REDIS_create('progress', progress);
        },  
		// * DELETE ITEM
		async deleteItem(itemId) {
            var deleteData = {}

            deleteData.deletedUserId = this.GET_FIREBASE_currentUser.entityId
            deleteData.deletedDateTime = this.$moment().format('X')

            console.log('deleteData: ', JSON.stringify(deleteData, null, 2))

			let result = await this.REDIS_delete(this.key, itemId, deleteData);
            console.log('result: ', JSON.stringify(result, null, 2))
			if (result.success) {
                this.getItems()
                this.MIX_alertBox({ color: 'success', timeout: 4000, message: 'Item Deleted', show: true });
                this.createProgress(result.data.entityId, 'Delete Communication', 'Completed')
			}
		},
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            console.log('openItem: ', JSON.stringify(item, null, 2))
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$emit('openItem', item);
            } else {
                this.$emit('openItem', item.entityId);
            }
        },
        // * GET ITEMS
        async getItems() {
            var t = this
            t.loading = true
            let itemsResult = await this.REDIS_searchFor(
                'ticket', 
                this.itemsFrom, 
                this.datatableOptions.itemsPerPage, 
                'modifiedDateTime',
                'DESC', 
                this.computedSearchQuery 
            )
            // console.log('itemsResult: ' + JSON.stringify(itemsResult));
            if (itemsResult.success) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.documents
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                t.loading = false
            }
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.service = { ...this.datadown };
            },
            deep: true,
        },
        refreshitems() {
            this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        computedFields() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        var t = this;
        this.service = { ...this.datadown };

        let users = await t.REDIS_searchFor('user', '0', '999', 'userStartDate', 'ASC', '@deleted:{false}');
        if (users.success) { t.users = users.data.documents; }

        t.users = t.users.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
            };
        })
        let managers = await t.REDIS_searchFor('user', '0', '999', 'userName', 'ASC', `@deleted:{false} @userCategory:{'COMMERCIALMANAGER' | 'SENIORMANAGER' | 'DIRECTOR'} -@userStatus:{ARCHIVED}`);
        if (managers.success) { t.managers = managers.data.documents; }

        t.managers = t.managers.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
            };
        })

        let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false} -@clientStatus:{ARCHIVE}');
        if (clients.success) { t.clients = clients.data.documents; }

        t.clients = t.clients.map((x) => {
            return {
                entityId: x.entityId,
                clientName: x.clientName,
            };
        })
        let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
        if (sites.success) { t.sites = sites.data.documents; }

        t.sites = t.sites.map((x) => {
            return {
                entityId: x.entityId,
                siteName: x.siteName,
                siteClientId: x.siteClientId
            };
        })

        this.getItems();
    },
};
</script>