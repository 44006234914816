<template>
    <div class="" style="width: 100% !important">
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field
                    label="Search Training"
                    v-model="searchQuery"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    color="primary"
                    clearable
                    prepend-inner-icon="icons8-search"
                    @click:clear="clearSearchQuery()"
                ></v-text-field>
            </div>
            <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="refreshItems">
                <v-icon>icons8-available-updates</v-icon>
            </v-btn>
            <div class="ml-2">
                <v-menu
                    offset-y
                    class="white"
                    style="z-index: 99999 !important; width: 200px !important; height: 200px !important"
                    :close-on-content-click="false"
                    origin="top right"
                    left
                    transition="scale-transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"
                            ><v-icon>icons8-table-properties</v-icon></v-btn
                        >
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox
                                v-model="headers[index].hidden"
                                dense
                                hide-details="auto"
                                v-for="(item, index) in headers"
                                :key="index"
                                :label="item.text"
                                :true-value="false"
                                :false-value="true"
                            ></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>

        <div>
            <v-btn-toggle v-model="filter.trainingStatus" multiple color="white" class="mr-1 mt-3" active-class="white" background-color="lightgrey" dense>
                <v-btn value="CURRENT" :class="filter.trainingStatus.includes('CURRENT') ? 'success white--text' : 'success--text'" small>
                    <v-icon :class="filter.trainingStatus.includes('CURRENT') ? 'white--text' : 'success--text'" class="text-h5 icons8-course-assign"></v-icon>
                    <span class="caption">Current</span>
                </v-btn>
                <v-btn value="REQUIRED" :class="filter.trainingStatus.includes('REQUIRED') ? 'warning white--text' : 'warning--text'" small>
                    <v-icon :class="filter.trainingStatus.includes('REQUIRED') ? 'white--text' : 'warning--text'" class="text-h5 icons8-course"></v-icon>
                    <span class="caption">REQUIRED</span>
                </v-btn>
                <v-btn value="DUE" :class="filter.trainingStatus.includes('DUE') ? 'warning white--text' : 'warning--text'" small>
                    <v-icon :class="filter.trainingStatus.includes('DUE') ? 'white--text' : 'warning--text'" class="text-h5 icons8-course"></v-icon>
                    <span class="caption">DUE</span>
                </v-btn>
                <v-btn value="EXPIRED" :class="filter.trainingStatus.includes('EXPIRED') ? 'error white--text' : 'error--text'" small>
                    <v-icon :class="filter.trainingStatus.includes('EXPIRED') ? 'white--text' : 'error--text'" class="text-h5 icons8-course"></v-icon>
                    <span class="caption">EXPIRED</span>
                </v-btn>
                <v-btn value="ARCHIVED" :class="filter.trainingStatus.includes('ARCHIVED') ? 'grey white--text' : 'grey--text'" small>
                    <v-icon :class="filter.trainingStatus.includes('ARCHIVED') ? 'white--text' : 'grey--text'" class="text-h5 icons8-course"></v-icon>
                    <span class="caption">ARCHIVED</span>
                </v-btn>
            </v-btn-toggle>
        </div>

        <v-row class="pa-3 pt-6">
            <!-- TABLE BASED LIST -->
            <v-data-table
                :item-class="itemRowBackground"
                sortable
                dense
                class=""
                style="width: 100%"
                :headers="computedHeaders"
                :items="computedItems"
                :server-items-length="itemsTotal"
                :options.sync="datatableOptions"
            >
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                    </div>
                </template>
                <template v-slot:[`item.expiryDate`]="{item}">
                    <span v-if="item.expiryDate">{{ $moment(item.expiryDate).format('DD-MM-YYYY')}}</span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-icon v-if="item.trainingStatus == 'REQUIRED'" class="grey--text ml-5 text--darken-2" @click="openItem(item)">icons8-task-completed</v-icon>
					<v-icon v-if="item.trainingStatus === 'EXPIRED'" title="Archive Training" class="grey--text text--darken-2" @click="archiveTraining(item)">icons8-archive</v-icon>
                    <v-icon class="grey--text text--darken-2 " @click="refreshTraining(item)" v-if="item.deleted === false && item.trainingStatus === 'EXPIRED'">icons8-reset</v-icon>
                    <v-icon class="grey--text text--darken-2 " @click="deleteItem(item.entityId)" v-if="item.deleted === false">icons8-trash-can</v-icon>
                </template>
            </v-data-table>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import schema from "@/schema.js";
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        type: '',
        status: '',
        key: 'user-training',
        user: {},
		schema: null,
        
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'ID', value: 'entityId', sortable: false, hidden: true, expanded: true },
            { text: 'Training State', value: 'trainingState', sortable: false, hidden: true, expanded: false },
            { text: 'Training Status', value: 'trainingStatus', sortable: false, hidden: false, expanded: false },
            { text: 'Training Name', value: 'trainingName', sortable: false, hidden: false, expanded: false },
            { text: 'Training Hours', value: 'trainingHours', sortable: false, hidden: false, expanded: false },
            { text: 'Training Course Id', value: 'courseId', sortable: false, hidden: true, expanded: false },
            { text: 'Training Validity', value: 'trainingValidity', sortable: false, hidden: false, expanded: false },
            { text: 'Assessment Date', value: 'assessmentDate', sortable: false, hidden: true, expanded: false },
            { text: 'Training Expiry', value: 'expiryDate', sortable: false, hidden: false, expanded: false },
            { text: 'Training User', value: 'trainingUserId', sortable: false, hidden: true, expanded: false },

            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Actions', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false },
        ],
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['trainingName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        filter: {
            trainingStatus: ['CURRENT', 'EXPIRED', 'REQUIRED', 'DUE'],
        }

    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser'
        }),
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                searchQuery = '@deleted:{false}';
            } else {
                searchQuery = `${searchQuery} @deleted:{false}`;
            }

            searchQuery = `${searchQuery} @trainingUserId:{${this.datadown.entityId}}`

            if (JSON.stringify(this.filter.trainingStatus) !== '[]') {
                searchQuery = `${searchQuery} @trainingStatus:{${this.filter.trainingStatus.join('|')}}`;
            }

            console.log('searchQuery', searchQuery);

            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            headers = headers.filter((header) => header.expanded === false); // Return Headers based on Expanded State
            return headers;
        },
        computedItems() {
            var items = this.items;
            if (this.type) {
                items = this.items.filter((item) => item.actionType === this.type);
            }
            if (this.status) {
                items = this.items.filter((item) => item.actionStatus === this.status);
            }
            return items;
        },
    },
    methods: {
        async refreshTraining(item) {
            var t = this; 
            var trainingItem = {...item}

            // duplicate item
            var newItem = {...t.schema}
            newItem.entityId = t.MIX_generateId()
            newItem.trainingUserId = this.datadown.entityId
            newItem.trainingName = trainingItem.trainingName
            newItem.trainingHours = trainingItem.trainingHours
            newItem.courseId = trainingItem.courseId
            newItem.trainingValidity = trainingItem.trainingValidity
            newItem.createdUserId = this.GET_FIREBASE_currentUser.entityId

            // archive trainingItem
            this.archiveTraining(trainingItem)

            // save newItem
            this.saveTraining(newItem)

            this.getItems()
        },
        async saveTraining(item) {
            var t = this;

            item.expiryDate = t.$moment(item.assessmentDate).add(item.trainingValidity, 'months').toISOString()

            if (item.expiryDate === null ) {
                item.trainingStatus = 'REQUIRED'
            } else if (t.$moment(item.expiryDate).isAfter(t.$moment().toISOString())) {
                item.trainingStatus = 'CURRENT'
            } else {
                item.trainingStatus = 'EXPIRED'
            }


            console.log('training = ' + JSON.stringify(item, null, 2))
            
            let result = await this.REDIS_create('user-training', item);
            console.log('result = ' + JSON.stringify(result, null, 2))
            
			if (result.success) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                // t.closeTrainingDialog()
                // t.refreshItems++
                // item = { ...t.schema }
			}

        },
        async archiveTraining(item) {
            var t = this; 

            item.trainingStatus = 'ARCHIVED';


            var dateTimeNow = t.$moment().toISOString()
            item.modifiedDateTime = dateTimeNow;
            item.modifiedUserId = t.GET_FIREBASE_currentUser.entityId;
            
            let result = await this.REDIS_update(t.key, item.entityId, item);
            
			if (result.success) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Archived Training", show: true });
			}
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        refreshItems: function () {
            this.getItems();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        async createProgress(jobId, type, status) {
            let t = this;
            let progress = {}
            
            progress.linkedId = jobId
            progress.type = type
            progress.status = status
            progress.totalItems = 1
            progress.count = 1
            progress.success = 1
            progress.error = 0
            progress.createdDateTime = t.$moment().toISOString()
            progress.createdUserId = t.GET_FIREBASE_currentUser.entityId
            console.log('progress = ' + JSON.stringify(progress, null, 2))
            await this.REDIS_create('progress', progress);
        },  
		// * DELETE ITEM
		async deleteItem(itemId) {
            var deleteData = {}

            deleteData.deletedUserId = this.GET_FIREBASE_currentUser.entityId
            deleteData.deletedDateTime = this.$moment().format('X')

            console.log('deleteData: ', JSON.stringify(deleteData, null, 2))

			let result = await this.REDIS_delete(this.key, itemId, deleteData);
            console.log('result: ', JSON.stringify(result, null, 2))
			if (result.success) {
                this.createProgress(result.data.trainingUserId, 'Delete User Training' , 'Completed')
                this.getItems()
			}
		},
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        // * GET ITEMS
        async getItems() {
            let itemsResult = await this.REDIS_searchFor(this.key, this.itemsFrom, this.datatableOptions.itemsPerPage, 'trainingName','ASC', this.computedSearchQuery)
            // console.log('itemsResult: ' + JSON.stringify(itemsResult));
            if (itemsResult.success) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.documents
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            }
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.user = { ...this.datadown };
            },
            deep: true,
        },
        refreshitems() {
            this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        this.getItems();
		this.schema = schema.userTraining;
    },
};
</script>