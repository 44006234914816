<template>
    <div class="" style="width: 100% !important">
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field
                    label="Search Users"
                    v-model="searchQuery"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    color="primary"
                    clearable
                    prepend-inner-icon="icons8-search"
                    @click:clear="clearSearchQuery()"
                ></v-text-field>
            </div>
            <v-btn v-if="$vuetify.breakpoint.lgAndUp" depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="refreshItems">
                <v-icon>icons8-available-updates</v-icon>
            </v-btn>
            <div v-if="$vuetify.breakpoint.lgAndUp" class="ml-2">
                <v-menu
                    offset-y
                    class="white"
                    style="z-index: 99999 !important; width: 200px !important; height: 200px !important"
                    :close-on-content-click="false"
                    origin="top right"
                    left
                    transition="scale-transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"
                            ><v-icon>icons8-table-properties</v-icon></v-btn
                        >
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox
                                v-model="headers[index].hidden"
                                dense
                                hide-details="auto"
                                v-for="(item, index) in headers"
                                :key="index"
                                :label="item.text"
                                :true-value="false"
                                :false-value="true"
                            ></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>
        <v-row class="pa-3 pt-0 mt-2">
            <!-- TABLE BASED LIST -->
            <v-data-table
                :item-class="itemRowBackground"
                sortable
                dense
                class=""
                style="width: 100%"
                :headers="computedHeaders"
                :items="computedItems"
                :server-items-length="itemsTotal"
                :options.sync="datatableOptions"
                v-if="$vuetify.breakpoint.lgAndUp"
                :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50]
                }"
            >
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn title="Delete" value="delete" small>
                                <v-icon class="grey--text text--darken-3">icons8-trash-can</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </template>
                <template v-slot:[`item.userSiteId`]="{ item }">
                    <v-chip class="caption">{{ getSite(item.userSiteId[0]) }}</v-chip>
                    <span v-if="item.userSiteId.length > 1" class="caption grey--text noselect">+ {{ item.userSiteId.length - 1 }}</span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <div class="d-flex align-end">
                        <v-icon class="grey--text text--darken-2 " @click="deleteItem(item.entityId)" v-if="item.deleted === false && toggleFeatures.includes('delete')">icons8-trash-can</v-icon>
                        <v-icon class="primary--text " @click="openItem(item)">icons8-forward</v-icon>
                    </div>
                </template>
            </v-data-table>
            <v-col class="tabledata pa-0 ma-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-row v-if="$vuetify.breakpoint.smAndDown" class="d-flex flex-column align-center " no-gutters>
                    <div v-if="itemsFrom > 0">
                        
                        <v-btn block @click="goBack" class="elevation-0 darkgrey lighten-3 white--text  mb-2 font-weight-bold">
                        + GO BACK 
                        </v-btn>
                    </div>
                    <v-col cols="12" xs="12" class="my-1 pa-1 white tablecard" v-for="item in computedItems" :key="item.id" @click="openItem(item)">
                        <v-row class="d-flex align-center justify-start pa-3 fill-height" no-gutters>
                            <v-col cols="10" class="d-flex pl-1 flex-column">
                                <div class="primary--text noselect">{{ item.clientName }}</div>
                                <div class="caption grey--text noselect">Contact Name: {{ item.clientContactName }}</div>
                                <div class="caption grey--text noselect">Contact Telephone: {{ item.clientContactTelephone }}</div>
                                <div class="caption grey--text noselect">Client Type: {{ item.clientType }}</div>
                            </v-col>
                            <v-col cols="2" class="text-right"><v-icon @click="openItem(item)">mdi-chevron-right</v-icon></v-col>
                        </v-row>
                    </v-col>
                    <v-row v-if="loading === true" class="align-center pt-5 justify-center">
                        <v-progress-circular :size="60" :width="7" indeterminate color="primary" ></v-progress-circular>
                    </v-row>
                    <!-- <pre>computedItems: {{ computedItems }}</pre> -->
                    <div v-else-if="computedItems.length == 0" class="font-weight-bold grey--text text--darken-1 body-1">
                        NO JOBS
                    </div>
                    <div v-else-if="computedItems.length !== itemsTotal && computedItems.length == datatableOptions.itemsPerPage">
                        
                        <v-btn block @click="loadMore" class="elevation-0 darkgrey lighten-3 white--text font-weight-bold">
                        + SHOW MORE 
                        </v-btn>
                    </div>
                    <div v-else-if="computedItems.length <= itemsTotal && computedItems.length > 0" class="font-weight-bold grey--text text--darken-1 body-1">
                        SHOWING ALL JOBS
                    </div>
                </v-row>
            </v-col>
            <!-- <pre>{{ toggleFeatures }}</pre> -->
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'; // Map the Getters from the Vuex Store
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
        client: {
            type: String,
            default: '',
        },
        site: {
            type: String,
            default: '',
        }
    },
    data: () => ({
        type: '',
        status: '',
        key: 'clientUser',
        index: 'idx:userClients',
        service: {},
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'ID', value: 'entityId', sortable: false, hidden: true, expanded: false },
            { text: 'Name', value: 'userName', sortable: false, hidden: false, expanded: false },
            { text: 'User Level', value: 'userLevel', sortable: false, hidden: false, expanded: false },
            { text: 'Site', value: 'userSiteId', sortable: false, hidden: false, expanded: false },
            { text: 'Email', value: 'userEmail', sortable: false, hidden: false, expanded: false },
            { text: 'Portal Pin', value: 'userPin', sortable: false, hidden: false, expanded: false },
            { text: 'Allow Task Notifications', value: 'allowTaskNotifications', sortable: false, hidden: true, expanded: false },
            { text: 'Allow Communications Notifications', value: 'allowCommunicationNotifications', sortable: false, hidden: true, expanded: false },

            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false },
        ],
        // Fields to Retrieve from Database
        // fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        sortBy: '@serviceName',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['serviceName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        // productCategories: ['Chair', 'Table', 'Sofa', 'Bed', 'Wardrobe', 'Cabinet', 'Dresser', 'Other'],
        filter: {
            clientStatus: ['ACTIVE'],
            clientType: []
        },
        loading: false,

        sites: [],
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '';
                } else {
                    searchQuery = '@deleted:{false}';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:{false}`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:{false}`;
                    }
                }
            }
            if (this.client) {
                searchQuery = `${searchQuery} @userOrgId:${this.client}`;
            }
            if (this.site) {
                searchQuery = `${searchQuery} @userSiteId:${this.site}`;
            }

            console.log('computedSearchQuery: ', JSON.stringify(searchQuery, null, 2))

            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (this.listshrunk) {
                headers = headers.filter((header) => header.expanded === false || header.expanded === true); // Return Headers based on Expanded State
            } else {
                headers = headers.filter((header) => header.expanded === false); // Return Headers based on Expanded State
            }
            return headers;
        },
        computedItems() {
            var items = this.items;
            if (this.type) {
                items = this.items.filter((item) => item.actionType === this.type);
            }
            if (this.status) {
                items = this.items.filter((item) => item.actionStatus === this.status);
            }
            return items;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            // if (!computedFields.includes('@actionId')) {
            //     computedFields.push('@actionId');
            // }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedType() {
            const types = [];
            this.items.filter((item) => {
                return types.push(item.actionType);
            });
            return types;
        },
        computedStatus() {
            const status = [];
            this.items.filter((item) => {
                return status.push(item.actionStatus);
            });
            return status;
        },
    },
    methods: {

        getSite(siteId) {
            var t = this;
            var site = []
            var siteName = ''

            site = t.sites.filter((item) => { return item.entityId == siteId })

            if (site.length !== 0) { siteName = site[0].siteName }

            return siteName
        },
        titleCase(str) {
            const lower = str.toLowerCase()
            return str.charAt(0).toUpperCase() + lower.slice(1)
        },
        async loadMore() {
            this.itemsFrom = this.itemsFrom + this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo + this.datatableOptions.itemsPerPage;
            this.getItems();
        },
        async goBack() {
            this.itemsFrom = this.itemsFrom - this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo - this.datatableOptions.itemsPerPage;
            this.getItems()
        },
        async editItem(id) {
            // Get the item from the database
            let itemResult = await this.MIX_readItem(id, 'service');
            if (itemResult.code === 1) {
                this.$emit('edit', itemResult.data);
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Editing Component', show: true });
            }
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        refreshItems: function () {
            this.getItems();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        async createProgress(jobId, type, status) {
            let t = this;
            let progress = {}
            
            progress.linkedId = jobId
            progress.type = type
            progress.status = status
            progress.totalItems = 1
            progress.count = 1
            progress.success = 1
            progress.error = 0
            progress.createdDateTime = t.$moment().toISOString()
            progress.createdUserId = t.GET_FIREBASE_currentUser.entityId
            console.log('progress = ' + JSON.stringify(progress, null, 2))
            await this.REDIS_create('progress', progress);
        },  
		// * DELETE ITEM
		async deleteItem(itemId) {
            var deleteData = {}

            deleteData.deletedUserId = this.GET_FIREBASE_currentUser.entityId
            deleteData.deletedDateTime = this.$moment().format('X')

            console.log('deleteData: ', JSON.stringify(deleteData, null, 2))

			let result = await this.REDIS_delete(this.key, itemId, deleteData);
            console.log('result: ', JSON.stringify(result, null, 2))
			if (result.success) {
                this.getItems()
                this.createProgress(result.data.entityId, 'Delete Client User', 'Completed')
			}
		},
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        // * GET ITEMS
        async getItems() {
            var t = this
            t.loading = true
            let itemsResult = await this.REDIS_searchFor('clientUser', this.itemsFrom, this.datatableOptions.itemsPerPage, 'userName','ASC', this.computedSearchQuery )
            //console.log('itemsResult: ' + JSON.stringify(itemsResult));
                if (itemsResult.success) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.documents;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                t.loading = false
            }
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.service = { ...this.datadown };
            },
            deep: true,
        },
        refreshitems() {
            this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        computedFields() {
            // this.getItems();
        },
        toggleDeletedItems() {
            // this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        var t = this;
        this.service = { ...this.datadown };
        let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', `@deleted:{false} @siteClientId:{${this.client}}`);
        if (sites.success) { t.sites = sites.data.documents; }

        t.sites = t.sites.map((x) => {
            return {
                entityId: x.entityId,
                siteName: x.siteName,
            };
        })
        this.getItems();
    },
};
</script>