<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: hidden !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>

                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="primary white--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn text class="primary white--text" v-if="editmode && itemNew" @click="saveItem()"><v-icon class="mr-2">oomph-save</v-icon>Save</v-btn>
                    <v-btn text class="primary white--text" v-if="editmode && !itemNew" @click="updateItem()"><v-icon class="mr-2">oomph-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="apptext--text" dense style="z-index: 0 !important">
            <v-col v-if="!editmode && !loading" cols="12" class="d-flex pa-2 align-center">

                <!-- //* VIEW MODE -->
                <v-row style="width: 100% !important" no-gutters>
                    <!-- //* GENERAL DETAILS VIEW -->
                    <v-col cols="12" sm="12" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Task Template Details</span>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Name:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.taskTemplateTitle }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Description:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.taskTemplateDescription }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Category:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.taskTemplateCategory }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="editmode && !loading" cols="12" class="pa-4 pt-4 align-center">
                <v-form ref="form" lazy-validation>
                    <v-row style="width: 100% !important" no-gutters>
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12">
                                    <v-text-field 
                                        label="Template Name" 
                                        v-model="item.taskTemplateTitle" 
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        @click:clear="clearItem('taskTemplateTitle')">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12">
                                    <v-textarea 
                                        label="Template Description" 
                                        v-model="item.taskTemplateDescription" 
                                        required 
                                        dense 
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        rows="3"
                                        class=""
                                        clearable 
                                        @click:clear="clearItem('taskTemplateDescription')">
                                    </v-textarea> 
                                </v-col>
                                <v-col cols="12">
                                    <v-select 
                                        class="" 
                                        hide-details="auto" 
                                        label="Template Type" 
                                        filled 
                                        v-model="item.taskTemplateCategory"  
                                        outlined
                                        dense
                                        :items="categories" 
                                        background-color="white" 
                                        color="primary"
                                        :menu-props="{ top: false, offsetY: true }"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
            <!-- <pre>{{ item }}</pre> -->
        </v-row>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
import schema from '@/schema.js';

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
    },
    // * DATA
    data: () => ({
        key: 'taskTemplate',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,
        // dropdowns
        categories: ['Client', 'Site', 'User'],


        // new address object
        item: {},
        loading: false,
        rules: {},

    }),
    computed: {  
    },
    methods: {
        initialise() {
            this.item = { ...this.datadown };
            if (this.item.taskTemplateTitle !== '') {
                this.itemNew = false;
                this.editmode = false;
            } else {
                this.itemNew = true;
                this.editmode = true;
            }
        },
        updateItem() {
            this.$emit('update', this.item);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {
            this.$emit('save', this.item);
            this.editmode = false;
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },

    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.taskTemplate;
        t.loading = true
        t.initialise();
        t.loading = false
    },
    mounted() {},
};
</script>