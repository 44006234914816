<template>
	<v-row class="pa-0 ma-0" fill-height>
        <v-row no-gutters class="pb-3 px-3 pt-3 primary darken-2">
            <v-col class="font-weight-bold white--text text-h6 d-flex align-center" v-if="JSON.stringify(item) !== '{}'">
                <div class="mx-3 mb-1"><v-icon class="white--text">icons8-skyscrapers</v-icon></div>
				<div>
					<span class="font-weight-light"><strong>Site: </strong>{{ item.siteName }}</span>
				</div>
				<div class="ml-2 font-weight-light" v-if="item.siteName === ''">New</div>
				<v-spacer></v-spacer>
				<div>
					<v-btn class="ml-2 white--text" plain @click="closeItem" depressed>
						<v-icon>icons8-multiply</v-icon>
					</v-btn>
				</div>
            </v-col>
        </v-row>
		<v-col class="pa-0" fill-height cols="auto" style="width: 100% !important">
			<v-tabs background-color="white" v-model="tab" show-arrows>
				<v-tab href="#details">Details</v-tab>
				<v-tab v-if="!itemNew" href="#locations">Locations</v-tab>
				<v-tab v-if="!itemNew" href="#jobs">Jobs</v-tab>
				<!-- <v-tab v-if="!itemNew" href="#users">Users</v-tab> -->
				<v-tab v-if="!itemNew" href="#tasks">Tasks</v-tab>
				<v-tab v-if="!itemNew" href="#hotlistTasks">Hotlist Tasks</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab" fill-height>
				<!-- Details Tab -->
				<v-tab-item value="details" class="grey lighten-4">
					<site-details 
                        :datadown="item" 
                        :itemnew="itemNew" 
                        v-on:update="updateItem" 
                        v-on:save="createItem" 
                        v-on:delete="deleteItem"
                    />
				</v-tab-item>
				<v-tab-item value="locations" class="grey lighten-4">
					<site-locations :datadown="item" v-on:save="createItem" />
				</v-tab-item>
				<v-tab-item value="jobs" class="grey lighten-4">
					<site-jobs :datadown="item" v-on:save="createItem" />
				</v-tab-item>
				<!-- <v-tab-item value="users" class="grey lighten-4">
					<site-users 
                        :clientId="item.siteClientId" 
                        :siteId="item.entityId"
                        v-on:save="createItem" 
                    />
				</v-tab-item> -->
				<v-tab-item value="tasks" class="grey lighten-4">
					<site-tasks :datadown="item" v-on:save="createItem" />
				</v-tab-item>
				<v-tab-item value="hotlistTasks" class="grey lighten-4">
					<site-hotlist-tasks :datadown="item" v-on:save="createItem" />
				</v-tab-item>
			</v-tabs-items>
		</v-col>
	</v-row>
</template>

<script>
	import { mapGetters } from "vuex";
	import siteDetails from "@/views/sites/siteDetails.vue";
	import siteLocations from "@/views/sites/siteLocations.vue";
	import siteJobs from "@/views/sites/siteJobs.vue";
	import siteTasks from "@/views/sites/siteCommunications.vue";
	import siteHotlistTasks from "@/views/sites/siteHotlistTasks.vue";
	import siteUsers from "@/views/clients/clientUsers.vue";
	import schema from "@/schema.js";
	export default {
		// * COMPONENTS
		components: {
			"site-details": siteDetails,
			"site-locations": siteLocations,
			"site-jobs": siteJobs,
			"site-tasks": siteTasks,
			"site-users": siteUsers,
			"site-hotlist-tasks": siteHotlistTasks,
		},
		// * PROPS
		props: {
			datadown: {
				type: Object,
				default: function () {
					return {};
				},
			},
            refreshitems: {
                type: Number,
                default: 0
            }
		},
		// * DATA
		data: () => ({
			key: "site",
			tab: "details",
			schema: null,
			// Item
			item: {},
			itemNew: false,
			editMode: false,

			// Form items
			accountTypes: [],
			addressTypes: [],
			industries: [],

			// Tags
			computedOrgTags: [],

			// Validation
			rules: {},

			// Toggles
			addAddressToggle: false,
			addManualAddress: false,
			addOtherAddressToggle: false,
			addLeadStatusToggle: false,
			addOrgInfoToggle: false,
			addSocialInfoToggle: false,
		}),
		// * COMPUTED
		computed: {
            ...mapGetters({
                GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
            }),
			computedFullName() {
				return this.item.contactFirstName + " " + this.item.contactLastName;
			},
		},
		// * METHODS
		methods: {
			// * INITIALISE ITEM
			initialiseItem() {
				let t = this;
				if (JSON.stringify(t.datadown) !== "{}") {
					this.readItem(t.datadown.entityId);
				} else {
					t.itemNew = true;
					t.editMode = true;
					t.item = { ...t.schema };
					t.item.entityId = t.MIX_generateId();
				}
			},
			// * VALIDATION RULES
			// TODO - RG - I think this needs to be moved to contactDetails.vue
			validateItem() {
				let t = this;
				t.rules = {
					// GENERAL DETAILS
					orgName: [(v) => !!v || "Organisation Name Required"],
					// orgRegisteredName: [ v => !!v || 'Registered Name Required'],
					// orgRegistrationNumber: [ v => !!v || 'Registration Number Required'],
					// orgTelephone: [ v => !!v || 'Telephone Required'],
					// orgWebsite: [ v => !!v || 'Website Required'],
					// orgEmail: [ v => !!v || 'Email Required'],
					// orgInvoiceEmail: [ v => !!v || 'Invoice Email Required'],
					// orgPurchasingEmail: [ v => !!v || 'Purchasing Email Required'],

					// ADDRESS DETAILS
					// orgAddressType: [ v => !!v || 'Address Type Required'],
					// orgAddressLine1: [ v => !!v || 'Address Line 1 Required'],
					// orgAddressLine2: [ v => !!v || 'Address Line 2 Required'],
					// orgAddressLine3: [ v => !!v || 'Address Line 3 Required'],
					// orgTown: [ v => !!v || 'Town Required'],
					// orgCounty: [ v => !!v || 'County Required'],
					// orgPostcode: [ v => !!v || 'Postcode Required'],

					// OTHER ADDRESS DETAILS
					// orgOtherAddressType: [ v => !!v || 'Address Type Required'],
					// orgOtherAddressLine1: [ v => !!v || 'Address Line 1 Required'],
					// orgOtherAddressLine2: [ v => !!v || 'Address Line 2 Required'],
					// orgOtherAddressLine3: [ v => !!v || 'Address Line 3 Required'],
					// orgOtherTown: [ v => !!v || 'Town Required'],
					// orgOtherCounty: [ v => !!v || 'County Required'],
					// orgOtherPostcode: [ v => !!v || 'Postcode Required'],

					// ORG INFO
					// orgEmployees: [ v => !!v || 'No of Employees Required'],
					// orgIndustry: [ v => !!v || 'Industry Required'],
					// orgSector: [ v => !!v || 'Sector Required'],
					// orgAccountNumber: [ v => !!v || 'Account Number Required'],
					//orgAccountTypes: [ v => !!v || 'Account Type Required'],
					// orgLocalAuthority: [ v => !!v || 'Local Authority Required'],
					// orgTurnover: [ v => !!v || 'Turnover Required'],

					// OWNER / SOURCE INFO
					// orgAccountOwner: [ v => !!v || 'Account Owner Required'],
					// orgSource: [ v => !!v || 'Source Required'],
					// orgSourceName: [ v => !!v || 'Source Name Required'],

					// SOCIAL INFO
					// orgTwitter: [ v => !!v || 'Twitter Required'],
					// orgFacebook: [ v => !!v || 'Facebook Required'],
					// orgLinkedIn: [ v => !!v || 'LinkedIn Required'],
					// orgInstagram: [ v => !!v || 'Instagram Required'],
				};
				t.$nextTick(() => {
					if (t.$refs.form.validate()) {
						this.createItem();
						this.editMode = false;
					} else {
						this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
					}
				});
			},
			// * CREATE ITEM
			async createItem(item) {
				let t = this;
                var dateTimeNow = t.$moment().toISOString()

                item.createdDateTime = dateTimeNow;
                item.createdUserId = t.GET_FIREBASE_currentUser.entityId;

                console.log("item = " + JSON.stringify(item, null, 2))
                
                let result = await this.REDIS_create(t.key, item);

                console.log('created item = ' + JSON.stringify(result, null, 2))
                
				if (result.success) {
					t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
					t.readItem(result.data.entityId);
					t.$emit("refreshitems");
                    t.itemNew = false
				}
			},
            // * UPDATE ITEM 
            async updateItem(item) {
				let t = this;
                var dateTimeNow = t.$moment().toISOString()

                item.modifiedDateTime = dateTimeNow;
                item.modifiedUserId = t.GET_FIREBASE_currentUser.entityId;
                
                let result = await this.REDIS_update(t.key, item.entityId, item);
                
				if (result.success) {
					t.MIX_alertBox({ color: "success", timeout: 2000, message: "Updated", show: true });
					t.readItem(item.entityId);
					t.$emit("refreshitems");
                    t.itemNew = false
				}

            },
			// * READ ITEM
			async readItem(itemId) {
				let t = this;
				let result = await t.REDIS_read(t.key, itemId);
				if (result.success) {
					this.item = result.data;
					t.itemNew = false;
					t.loading = false;
				}
			},
			// * DESTROY ITEM
			async destroyItem() {
				let result = await this.MIX_destroyItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// * DELETE ITEM
			async deleteItem(item) {





                
                console.log('archiving site = ')
                item.deletedUserId = this.GET_FIREBASE_currentUser.entityId 
				let result = await this.REDIS_delete(this.key, item.entityId, item);
                console.log('result = ' + JSON.stringify(result, null, 2))
				if (result.success) {
					this.MIX_alertBox({ color: "error", timeout: 2000, message: "Archived", show: true });
					this.readItem(item.entityId);
                    this.refreshItems++
				}
			},
			// * RESTORE ITEM
			async restoreItem() {
				let result = await this.MIX_restoreItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// Close Item
			closeItem() {
				this.$emit("closeitem");
			},
			cancelItem() {
				this.readItem(this.item.id);
			},
            
		},
		// * WATCH
		watch: {
			// Datadown Changes
			datadown() {
				this.initialiseItem();
			},
			computedOrgTags(val) {
				var t = this;
				var splitVal = [];
				splitVal = t.item.orgTags.split(",");

				return splitVal;
			},
		},
		// * CREATED
		async created() {
			let t = this;
			t.loading = true;
			t.schema = schema.site;
			t.initialiseItem();
            t.loading = false
		},
		// * MOUNTED
		async mounted() {},
	};
</script>

<style scoped>
	.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
	.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
	.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
		height: 22px;
	}
</style>
