<template>
    <div class="" 
    style="padding: 0px !important; margin: 0px !important; width: 100% !important" 
    :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>

                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <!-- //! TODO - ACTUALLY ADD TRAINING  -->
                    <v-btn text class="primary white--text" @click="trainingDialog = true"><v-icon class="mr-2">icons8-duplicate</v-icon>Add</v-btn>
                    <!-- <v-btn text class="primary white--text" v-if="editmode" @click="saveItem()"><v-icon class="mr-2">oomph-save</v-icon>Save</v-btn> -->
                </div>
            </v-col>
        </v-row>
        <v-row class="apptext--text" dense style="z-index: 0 !important">
            <v-col cols="12" class="d-flex pa-2 align-center">
                <v-row style="width: 100% !important" class="px-2" no-gutters>
                    <!-- //! TODO - ADD FILTER, AND FILTER BY USER  -->
                    <training-list :refreshitems="refreshItems" :datadown="item" v-on:openItem="openItem" />
                </v-row>
            </v-col>
        </v-row>


        <v-dialog persistent v-model="trainingDialog" width="600px" height="400px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 secondary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mr-3 mb-1">
                        <v-icon class="white--text">icons8-future</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Add Training</strong>
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeTrainingDialog()" depressed>
                            <v-icon>icons8-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 pa-0">               
                    
                    <v-form ref="form" lazy-validation>
                        <v-row class="pa-2 ma-0 justify-center d-flex" fill-height dense>
                            <v-col cols="12" xs="12">
                                <v-select 
                                    class="" 
                                    hide-details="auto" 
                                    label="Training" 
                                    filled 
                                    v-model="selectedTraining"  
                                    :rules="rules.selectedTraining"
                                    outlined
                                    dense
                                    clearable
                                    :items="courses" 
                                    required
                                    background-color="white" 
                                    color="primary"
                                    :menu-props="{ top: false, offsetY: true }" 
                                    >
                                    <template v-slot:item="data"> {{ data.item.courseName }} </template>
                                    <template v-slot:selection="data"> {{ data.item.courseName }} </template>   
                                </v-select> 
                            </v-col>
                            <v-col cols="12" xs="12">
                                <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field label="Assessment Date" v-model="computedDate1" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('assessmentDate')"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-form>
                    <!-- <pre>{{ courses }}</pre> -->
                    <!-- <pre>{{ selectedTraining }}</pre> -->

                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-0">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="closeTrainingDialog()"><span>Cancel</span> </v-btn>
                            <v-btn class="primary white--text font-weight-bold ml-2" depressed @click="validateItem()"><span>Save</span> </v-btn>
                            <!-- <v-btn v-else-if="training.entityId !== ''" class="primary white--text font-weight-bold ml-2" depressed @click="updateTraining"><span>Save</span> </v-btn> -->
                        </v-col>
                    </v-col>
                </v-card-actions>
            </div>
        </v-dialog>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
import { mapGetters } from "vuex";
import schema from '@/schema.js';
import trainingList from '@/views/staffDirectory/userTrainingList.vue'

export default {
	components: {
		"training-list": trainingList,
    },
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
    },
    // * DATA
    data: () => ({
        key: 'action',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,
        
        // new address object
        item: {},
        loading: false,
        rules: {},


        courses: [],
        training: {},

        trainingDialog: false,

        date1: '',
        dateMenu1: false,

        selectedTraining: {},

        refreshItems: 0,

        rules: {},
    }),
    computed: { 
            ...mapGetters({
                GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
            }),
        computedDate1() {
            return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
 
    },
    methods: {
        openItem(item) {
            var t = this; 
            console.log('opening item = ' + JSON.stringify(item, null, 2))
            t.training = item

            t.selectedTraining = t.courses.filter(function(item) {
                return item.entityId === t.training.courseId
            })
            console.log('selectedTraining = ' + JSON.stringify(t.selectedTraining, null, 2))

            t.selectedTraining = t.selectedTraining[0]

            t.trainingDialog = true
        },
        initialise() {
            this.item = { ...this.datadown };
        },
        closeTrainingDialog() {
            var t = this; 

            t.trainingDialog = false 
            t.training = {...t.schema}
            t.date1 = ''
        },
        updateItem() {
            this.$emit('update', this.item);
        },
        // * CREATE ITEM
        async saveItem() {
            this.$emit('save', this.item);
            this.editmode = false;
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        validateItem() {
            var t = this; 
            
			t.rules = {
				required: [(v) => !!v || "Required"],
				selectedTraining: [(v) => !!v || "Required"],
			};
			t.$nextTick(() => {
				if (t.$refs.form.validate()) {
                    if (t.selectedTraining.courseName && t.selectedTraining.courseName == 'Starter Pack') {
                        this.saveStarterPack()
                    } else if (t.training.entityId == '') {
					    this.saveTraining();
                    } else {
                        this.updateTraining();
                    }
				} else {
					this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
				}
			});
        },
        async updateTraining() {
            var t = this; 

            console.log('updating training')


            t.training.expiryDate = t.$moment(t.training.assessmentDate).add(t.training.trainingValidity, 'months').toISOString()

            if (t.training.expiryDate === null ) {
                t.training.trainingStatus = 'REQUIRED'
            } else if (t.$moment(t.training.expiryDate).isAfter(t.$moment().toISOString())) {
                t.training.trainingStatus = 'CURRENT'
            } else {
                t.training.trainingStatus = 'EXPIRED'
            }
            var item = t.training

            var dateTimeNow = t.$moment().toISOString()
            item.modifiedDateTime = dateTimeNow;
            item.modifiedUserId = t.GET_FIREBASE_currentUser.entityId;

            let result = await this.REDIS_update('user-training', item.entityId, item)

			if (result.success) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Updated", show: true });
                t.closeTrainingDialog()
                t.refreshItems++
                t.training = { ...t.schema }
			}

        },
        async saveStarterPack() {
            console.log('saving starter pack')
            var t = this;

            // get all training courses included in starter pack 
            let courses = await t.REDIS_searchFor('course', '0', '9999', 'courseName', 'ASC', '@deleted:{false} @courseStarterPack:{true}');
            if (courses.success) { courses = courses.data.documents; }
            console.log('courses = ' + JSON.stringify(courses, null, 2))

            for (let i=0; i<courses.length; i++) {
                var course = courses[i]
                var training = {...t.schema}

                training.courseId = course.entityId
                training.trainingHours = course.courseLengthHours
                training.trainingName = course.courseName
                training.trainingValidity = course.courseValidityYears
                training.trainingUserId = this.item.entityId
                training.createdUserId = this.GET_FIREBASE_currentUser.entityId

                training.expiryDate = t.$moment(t.training.assessmentDate).add(training.trainingValidity, 'months').toISOString()

                if (training.expiryDate === null ) {
                    training.trainingStatus = 'REQUIRED'
                } else if (t.$moment(training.expiryDate).isAfter(t.$moment().toISOString())) {
                    training.trainingStatus = 'CURRENT'
                } else {
                    training.trainingStatus = 'EXPIRED'
                }

                await this.REDIS_create('user-training', training);
                

            }
                    
            t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
            t.closeTrainingDialog()
            t.refreshItems++
            t.training = { ...t.schema }
        },
        async saveTraining() {
            var t = this;

            t.training.expiryDate = t.$moment(t.training.assessmentDate).add(t.training.trainingValidity, 'months').toISOString()

            if (t.training.expiryDate === null ) {
                t.training.trainingStatus = 'REQUIRED'
            } else if (t.$moment(t.training.expiryDate).isAfter(t.$moment().toISOString())) {
                t.training.trainingStatus = 'CURRENT'
            } else {
                t.training.trainingStatus = 'EXPIRED'
            }

            var item = t.training

            console.log('training = ' + JSON.stringify(item, null, 2))
            
            let result = await this.REDIS_create('user-training', item);
            console.log('result = ' + JSON.stringify(result, null, 2))
            
			if (result.success) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                t.closeTrainingDialog()
                t.refreshItems++
                t.training = { ...t.schema }
			}

        },

        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.training[property] = '';
            }, 1);
        },
    },
    watch: {
        selectedTraining: {
            handler: async function () {
                console.log('selected training = ' + JSON.stringify(this.selectedTraining, null, 2))
                if (this.selectedTraining !== null) {
                    this.training.courseId = this.selectedTraining.entityId
                    this.training.trainingHours = this.selectedTraining.courseLengthHours
                    this.training.trainingName = this.selectedTraining.courseName
                    this.training.trainingValidity = this.selectedTraining.courseValidityYears
                    this.training.trainingUserId = this.item.entityId
                    this.training.createdUserId = this.GET_FIREBASE_currentUser.entityId
                }
            },  
            deep: true,
        },
        dateMenu1: {
            handler: async function () {
                this.training.assessmentDate = this.$moment(this.date1, 'YYYY-MM-DD').toISOString();
            },
            deep: true,
        },
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        selectedPackageIcon: {
            handler: function () {
                this.item.packageIcon = this.selectedPackageIcon.icon;
            }, deep: true
        },

    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.userTraining;
        t.training = {...t.schema}
        t.initialise();

        let courses = await t.REDIS_searchFor('course', '0', '9999', 'courseName', 'ASC', '@deleted:{false}');
        if (courses.success) { t.courses = courses.data.documents; }

        t.courses.push({
            entityId: '',
            courseName: 'Starter Pack',
        })

        

        // t.courses = courses.map((x) => {
        //     return {
        //         entityId: x.entityId,
        //         courseName: x.courseName,
        //     };
        // })
    },
    mounted() {},
};
</script>