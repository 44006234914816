<template>
    <div class="" style="width: 100% !important">

            <v-data-table
                :item-class="itemRowBackground"
                sortable
                dense
                class=""
                style="width: 100%"
                :headers="computedHeaders"
                :items="items"
                :server-items-length="itemsTotal" 
                :options.sync="datatableOptions"
                v-if="$vuetify.breakpoint.mdAndUp"
                :loading="loading"
                loading-text="Loading Staff Members... Please wait"
            >
                <!-- :server-items-length="itemsTotal" -->
                <template v-if="!userOpen" v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                    </div>
                </template>
				<template v-slot:[`item.userName`]="{ item }">
					<span>
                        <v-icon v-if="item.delete !== undefined || item.userStatus === 'ARCHIVED'" class=" mb-1 error--text icons8-xbox-a"></v-icon> 
                        {{ item.userName }}
                    </span>
				</template>
				<template v-slot:[`item.registered`]="{ item }">
					<v-icon v-if="item.authId !== null && item.authId !== ''" class="iconbackground text-h5 success--text icons8-verified-account"></v-icon>
					<v-icon v-else class="iconbackground text-h5 grey--text icons8-customer"></v-icon>
				</template>
				<!-- SWAPP STATUS (CUSTOM) -->
				<template v-slot:[`item.swappStatus`]="{ item }">
					<span v-if="item.swappStatus === '1'" class="d-flex subtitle align-center grey--text font-weight-bold text--darken-2"><v-icon class="iconbackground subtitle secondary--text icons8-filled-circle"></v-icon> IN </span>
					<span v-else-if="item.swappStatus === '0'" class="d-flex subtitle align-center grey--text font-weight-bold"><v-icon class="iconbackground subtitle grey--text text--lighten-2 icons8-filled-circle"></v-icon> OUT </span>
					<span v-else-if="item.swappStatus === '2'" class="d-flex subtitle align-center"><v-icon class="iconbackground subtitle warning--text icons8-filled-circle"></v-icon> SICK </span>
					<span v-else-if="item.swappStatus === '3'" class="d-flex subtitle align-center"><v-icon class="iconbackground subtitle primary--text icons8-filled-circle"></v-icon> LEAVE </span>
				</template>

				<!-- USER STATUS (CUSTOM) -->
				<template v-slot:[`item.userStatus`]="{ item }">
					<span v-if="item.userStatus === 'PENDING'"><v-icon class="iconbackground text-h4 secondary--text icons8-inactive-state"></v-icon></span>
					<span v-else-if="item.userStatus === 'REJECTED'"><v-icon class="iconbackground text-h4 warning--text icons8-cancel"></v-icon></span>
					<span v-else-if="item.userStatus === 'APPROVED'"><v-icon class="iconbackground text-h4 success--text icons8-checkmark-yes"></v-icon></span>
					<span v-else-if="item.userStatus === 'SUSPENDED'"><v-icon class="iconbackground text-h4 error--text icons8-private"></v-icon></span>
					<span v-else-if="item.userStatus === 'ARCHIVED'"><v-icon class="iconbackground text-h4 grey--text icons8-xbox-a"></v-icon></span>
				</template>
                
				<!-- TRAINING STATUS (CUSTOM) -->
				<template v-slot:[`item.userTrainingStatus`]="{ item }">
					<v-icon v-if="item.userTrainingStatus === 'ACTIVE'" class="iconbackground text-h5 success--text icons8-course-assign"></v-icon>
					<v-icon v-else-if="item.userTrainingStatus === 'NONE'" class="grey--text text-h5 icons8-course"></v-icon>
					<v-icon v-else-if="item.userTrainingStatus === 'REQUIRED'" class="warning--text text-h5 icons8-course"></v-icon>
					<v-icon v-else class="error--text text-h5 icons8-course"></v-icon>
				</template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon class="primary--text ml-5" @click="openItem(item)">icons8-forward</v-icon>
                </template>
            </v-data-table>

    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
        job: {
            type: Boolean, 
            default: false,
        },
        userOpen: {
            type: Boolean,
            default: false
        },
        user: {
            type: String, 
            default: ''
        }
    },
    data: () => ({
        type: '',
        status: '',
        key: 'user',
        service: {},
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,


        jobsSelected: [{}, {}],

        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
				{ text: "ID", value: "entityId", align: "start", sortable: true, hidden: false, expanded: false, userOpen: true },

				{ text: "Name", value: "userName", align: "start", sortable: true, hidden: false, expanded: false, userOpen: true },
				{ text: "Telephone Number", value: "userTelephone", align: "center", sortable: true, hidden: false, expanded: true, userOpen: true },
				{ text: "User Category", value: "userCategory", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "Access", value: "userStatus", align: "center", sortable: true, hidden: false, expanded: false, userOpen: false },
				{ text: "Areas", value: "userAreas", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "Registered", value: "registered", align: "center", sortable: true, hidden: false, expanded: false, userOpen: false },
				{ text: "Primary Line Manager", value: "primaryLineManagerId", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "Training", value: "trainingStatus", align: "center", sortable: true, hidden: false, expanded: false, userOpen: false },
				{ text: "Status", value: "swappStatus", align: "start", sortable: true, hidden: false, expanded: false, userOpen: false },
				{ text: "Last Location", value: "lastLocationId", align: "start", sortable: true, hidden: false, expanded: true, userOpen: false },
				{ text: "Last Site", value: "lastSiteId", align: "start", sortable: true, hidden: false, expanded: true, userOpen: false },


				{ text: "Contract Hours", value: "contractHours", align: "start", sortable: true, hidden: true, expanded: false, userOpen: true },
				{ text: "Financial Access", value: "financialAccess", align: "center", sortable: true, hidden: true, expanded: true, userOpen: true },
				{ text: "Auth Id", value: "authId", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "NOK Name", value: "NOKName", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "NOK Relationship", value: "NOKRelationship", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "NOK Tell No", value: "NOKTellNo", align: "center", sortable: true, hidden: true, expanded: true, userOpen: false },
				{ text: "Last Client", value: "lastClientId", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "Privacy Policy Consent", value: "privacyPolicyConsent", align: "start", sortable: true, hidden: true, expanded: true, userOpen: false },
				{ text: "SWAPP Method", value: "swappMethod", align: "center", sortable: true, hidden: true, expanded: true, userOpen: true },
				{ text: "Address Line 1", value: "userAddressLine1", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "Address Line 2", value: "userAddressLine2", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "Address Line 3", value: "userAddressLine3", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "County", value: "userAddressCounty", align: "center", sortable: true, hidden: true, expanded: true, userOpen: false },
				{ text: "User Postcode", value: "userAddressPostcode", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "User Town", value: "userAddressTown", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "User DOB", value: "userDOB", align: "start", sortable: true, hidden: true, expanded: true, userOpen: false },
				{ text: "User Email", value: "userEmail", align: "center", sortable: true, hidden: true, expanded: true, userOpen: true },
				{ text: "User Gender", value: "userGender", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "User Job Title", value: "userJobTitle", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "User Level", value: "userLevel", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "User Role", value: "userRole", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "User Start Date", value: "userStartDate", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "User Type", value: "userType", align: "start", sortable: true, hidden: true, expanded: true, userOpen: false },
				{ text: "Audit Target", value: "auditTarget", align: "center", sortable: true, hidden: true, expanded: true, userOpen: true },
				{ text: "Offline Staff Member", value: "offlineStaffMember", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "Offline Staff Member Reason", value: "offlineStaffMemberReason", align: "center", sortable: true, hidden: true, expanded: false, userOpen: false },
				{ text: "Secondary Line Manager", value: "secondaryLineManagerId", align: "center", sortable: true, hidden: true, expanded: true, userOpen: false },























				{ text: "", value: "actions", align: "end", sortable: true, hidden: false, expanded: false, width: "100px", userOpen: false },
        ],
        
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['userName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        filter: {
            areas: [],
            service: [],
            userCategory: [],
            status: [],
            userStatus: ['APPROVED', 'PENDING'],
            training: ['1','0']
        },

        areas: [],
        services: [],
        userCategories: [
            'Mobile Cleaners', 
            'Director', 
            'Commercial Manager', 
            'Office Staff', 
            'Security Staff', 
            'Cleaner', 
            'Supervisor',
            'Senior Manager'
        ],
        
        loading: false,
        computedItems: []
    }),
    computed: {
		...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
		}),
        computedSortBy() {
            return this.datatableOptions.sortBy[0];
        },
        computedSortOrder() {
            return this.datatableOptions.sortDesc[0] ? 'DESC' : 'ASC'
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '';
                } else {
                    searchQuery = '@deleted:{false}';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:{false}`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:{false}`;
                    }
                }
            }


            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (this.userOpen) {
                headers = headers.filter((header) => header.userOpen === true); // Return Headers based on Expanded State
            } else if (this.listshrunk) {
                headers = headers.filter((header) => header.expanded === false || header.expanded === true); // Return Headers based on Expanded State
            } else {
                headers = headers.filter((header) => header.expanded === false); // Return Headers based on Expanded State
            }
            return headers;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedType() {
            const types = [];
            this.items.filter((item) => {
                return types.push(item.actionType);
            });
            return types;
        },
        computedStatus() {
            const status = [];
            this.items.filter((item) => {
                return status.push(item.actionStatus);
            });
            return status;
        },
    },
    methods: {
        async computeItems() {
            var t = this;
            var items = [];
            items = this.items;

            console.log('items length = ' + JSON.stringify(items.length, null, 2))

            for (let i = 0; i < items.length; i++) {
                var item = items[i];
                console.log('item = ' + JSON.stringify(item.entityId, null, 2))
                var required = 0;
                var due = 0;
                var valid = 0;


                var training = await t.getUsersTraining(item.entityId);
                // console.log('training = ' + JSON.stringify(training, null, 2))


                training.forEach((trainingItem) => {
                    if (trainingItem.trainingStatus === 'REQUIRED') {
                        required++
                    } else if (trainingItem.trainingStatus === 'DUE') {
                        due++;
                    } else if (trainingItem.trainingStatus === 'ACTIVE') {
                        valid++;
                    }
                })
                // console.log('required = ' + required)
                // console.log('due = ' + due)
                // console.log('valid = ' + valid)

                if (required > 0) {
                    item.userTrainingStatus = 'REQUIRED'
                } else if (due > 0) {
                    item.userTrainingStatus = 'DUE'
                } else if (valid > 0) {
                    item.userTrainingStatus = 'ACTIVE'
                } else {
                    item.userTrainingStatus = 'NONE'
                }

                console.log('item.userTrainingStatus = ' + item.userTrainingStatus)

                this.saveStaff(item)

            }

            t.loading = false
            this.computedItems = items;
            // return items;
        },
        async saveStaff(item) {
            var t = this; 
            item.modifiedUserId = t.GET_FIREBASE_currentUser.entityId

            let result = await this.REDIS_update(t.key, item.entityId, item);

            if (result.success) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "User Saved", show: true });
            }
        },
        async getUsersTraining(userId) {
            var t = this; 
            var training = []
            let itemsResult = await this.REDIS_searchFor('user-training', 0, 999, 'trainingName', this.computedSortOrder, `@deleted:{false} @trainingUserId:{${userId}}` );
            // console.log('itemsResult = ' + JSON.stringify(itemsResult, null, 2))
            if (itemsResult.success) {
                training = itemsResult.data.documents;
            }
            return training
        },
        async loadMore() {
            this.itemsFrom = this.itemsFrom + this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo + this.datatableOptions.itemsPerPage;
            this.getItems();
        },
        async goBack() {
            this.itemsFrom = this.itemsFrom - this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo - this.datatableOptions.itemsPerPage;
            this.getItems()
        },
        async editItem(id) {
            // Get the item from the database
            let itemResult = await this.MIX_readItem(id, 'service');
            if (itemResult.code === 1) {
                this.$emit('edit', itemResult.data);
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Editing Component', show: true });
            }
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        refreshItems: function () {
            this.getItems();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        async getUser() {
			let t = this;
            var itemId = this.user
			let result = await t.REDIS_read(t.key, itemId);
			if (result.success) {
				var items = result.data;
                //console.log('this.items = ' + JSON.stringify(items, null, 2))
                this.items = []
                this.items.push(items)
                this.itemsTotal = result.data.length
                //console.log('this.items = ' + JSON.stringify(this.items, null, 2))
			}
        },
        // * GET ITEMS
        async getItems() {
            var t = this
            t.loading = true
            let itemsResult = await this.REDIS_searchFor('user', this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedSortBy, this.computedSortOrder, this.computedSearchQuery );
            if (itemsResult.success) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.documents;

                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.computeItems()
                // t.loading = false

            }
        },

        async exportCSV() {
            var t = this;
            // t.exportingCSV = true
            
            let itemsResult = await this.REDIS_searchFor('user', 0, 999, 'userName', 'ASC', this.computedSearchQuery);
            if (itemsResult.success) {
                console.log('itemsResult: ' + JSON.stringify(itemsResult, null, 2))
            
                var data = itemsResult.data.documents.map(e => {
                    return {
                        'Name' : e.userName,
                        'Status' : e.userStatus,
                        'Email' : e.userEmail,
                        'Telephone' : e.userTelephone,
                        'Contracted Hours' : e.contractHours,
                        'Gender' : e.userGender,
                        'DOB' : (e.userDOB && e.userDOB !== 'Invalid date') ? t.MIX_formatDateTime(e.userDOB, 'X', 'DD-MMM-YYYY') : '',
                        'Start Date' : e.userStartDate ? t.$moment(e.userStartDate).format('DD-MMM-YYYY') : '',
                        'Primary Line Manager' : e.primaryLineManagerId,
                        'Address Line 1' : e.userAddressLine1,
                        'Address Line 2' : e.userAddressLine2,
                        'Postcode' : e.userAddressPostcode,
                    }
                })

                console.log('data  = ' + JSON.stringify(data, null, 2))

                let csv = t.$papa.unparse(data)
                t.$papa.download(csv, 'Staff Report')
                // t.exportingCSV = false
            }
        },
        
    },
    watch: {
        datadown: {
            handler: function () {
                this.service = { ...this.datadown };
            },
            deep: true,
        },
        refreshitems() {
            // this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        computedFields() {
            // this.getItems();
        },
        toggleDeletedItems() {
            // this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        var t = this;
        this.service = { ...this.datadown };
        
        let services = await t.REDIS_searchFor('service', '0', '9999', 'serviceName', 'ASC', '@deleted:{false}');
        if (services.success) { t.services = services.data.documents; }

        t.services = t.services.map((x) => {
            return {
                entityId: x.entityId,
                serviceName: x.serviceName,
            };
        })

        
        let areas = await t.REDIS_searchFor('area', '0', '9999', 'areaName', 'ASC', '@deleted:{false}');
        if (areas.success) { t.areas = areas.data.documents; }

        t.areas = t.areas.map((x) => {
            return {
                entityId: x.entityId,
                areaName: x.areaName,
            };
        })
        //console.log('this.user = ' + JSON.stringify(this.user, null, 2))
        if (this.user !== '') {
            this.getUser()
        } else {
            this.getItems();
        }
    },
};
</script>