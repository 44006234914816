<template>
    <div class="" style="width: 100% !important">
        <div class=" align-start">
			<div class="d-flex align-center">
				<div><v-icon class="primary--text" x-large>icons8-progress-indicator</v-icon></div>
				<div class="text-left primary--text pl-1 text-h4 font-weight-thin">Progress Updates</div>
			</div>
        </div>

        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-select 
                    class="" 
                    hide-details="auto" 
                    label="Type" 
                    filled 
                    v-model="filter.type"  
                    outlined
                    dense
                    clearable
                    :items="types" 
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="serviceName" 
                    item-value="entityId"
                ></v-select>
            </div>
            <div v-if="$vuetify.breakpoint.mdAndUp" class="flex-grow-1 ml-2">
                <v-select 
                    class="" 
                    hide-details="auto" 
                    label="Clients" 
                    filled 
                    item-value="entityId" 
                    v-model="filter.client"  
                    outlined
                    dense
                    clearable
                    :items="clients" 
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="clientName" 
                ></v-select>
            </div>
            <div v-if="$vuetify.breakpoint.mdAndUp" class="flex-grow-1 ml-2">
                <v-select 
                    class="" 
                    hide-details="auto" 
                    label="Sites" 
                    filled 
                    v-model="filter.site"  
                    outlined
                    dense
                    clearable
                    :items="sites" 
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="siteName" 
                    item-value="entityId" 
                ></v-select>
            </div>
            <v-btn v-if="$vuetify.breakpoint.mdAndUp" depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="refreshItems">
                <v-icon>icons8-available-updates</v-icon>
            </v-btn>
            <div v-if="$vuetify.breakpoint.mdAndUp" class="ml-2">
                <v-menu
                    offset-y
                    class="white"
                    style="z-index: 99999 !important; width: 200px !important; height: 200px !important"
                    :close-on-content-click="false"
                    origin="top right"
                    left
                    transition="scale-transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"
                            ><v-icon>icons8-table-properties</v-icon></v-btn
                        >
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox
                                v-model="headers[index].hidden"
                                dense
                                hide-details="auto"
                                v-for="(item, index) in headers"
                                :key="index"
                                :label="item.text"
                                :true-value="false"
                                :false-value="true"
                            ></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>
        <div class="mt-2 d-flex align-start">
            <div v-if="$vuetify.breakpoint.mdAndUp" class="flex-grow-1">
                <v-select 
                    class="" 
                    hide-details="auto" 
                    label="Users" 
                    filled 
                    v-model="filter.user"  
                    outlined
                    dense
                    clearable
                    :items="users" 
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="userName" 
                    item-value="entityId" 
                ></v-select>
            </div>
            <div v-if="$vuetify.breakpoint.mdAndUp" class="flex-grow-1 ml-2">
                <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field label="Progress From" v-model="computedDate1" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable></v-text-field>
                    </template>
                    <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                </v-menu>
            </div>
            <!-- //! TODO - CHANGE TO DATE PICKER  -->
            <div v-if="$vuetify.breakpoint.mdAndUp" class="flex-grow-1 ml-2">
                <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field label="Progress To" v-model="computedDate2" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable ></v-text-field>
                    </template>
                    <v-date-picker v-model="date2" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu2 = false"></v-date-picker>
                </v-menu>
            </div>
        </div>

        <v-row class="pa-3 mt-1">
            <v-data-table
                :item-class="itemRowBackground"
                sortable
                dense
                class=""
                style="width: 100%"
                :headers="computedHeaders"
                :items="computedItems"
                :server-items-length="itemsTotal"
                :options.sync="datatableOptions"
                v-if="$vuetify.breakpoint.mdAndUp"
                :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50]
                }"
                :loading="loading"
            >
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                    </div>
                </template>
                <template v-slot:[`item.type`]="{ item }">
                    <span class="text-capitalize">{{ titleCase(item.type) }}</span>
                </template>
                <template v-slot:[`item.item`]="{ item }">
                    <span class="text-capitalize">{{ getLinkedItem(item) }}</span>
                </template>
                <template v-slot:[`item.success`]="{ item }">
                    <div class="success--text font-weight-bold">{{ item.success }}</div>
                </template>
                <template v-slot:[`item.error`]="{ item }">
                    <div class="error--text font-weight-bold">{{ item.error }}</div>
                </template>
                <template v-slot:[`item.createdDateTime`]="{ item }">
                    <div v-if="item.createdDateTime">{{ $moment(item.createdDateTime).format('HH:mm DD-MMM-YYYY') }}</div>
                </template>
                <template v-slot:[`item.createdUserId`]="{ item }">
                    <div v-if="item.createdUserId">{{ getUser(item.createdUserId) }}</div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-icon v-if="item.linkedId && item.linkedId !== '' && item.type !== 'Delete Task Template' && item.type !== 'Delete Task Template Task' " class="primary--text ml-5" @click="goTo(item)">icons8-external-link</v-icon>
                </template>
            </v-data-table>

        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'; // Map the Getters from the Vuex Store
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        type: '',
        status: '',
        key: 'progress',
        index: 'idx:progress',
        
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'id', value: 'entityId', sortable: false, hidden: true, expanded: true },
            // { text: 'Linked Id', value: 'linkedId', sortable: false, hidden: false, expanded: false },
            { text: 'Type', value: 'type', sortable: false, hidden: false, expanded: false },
            { text: 'Item', value: 'item', sortable: false, hidden: false, expanded: false },
            { text: 'Status', value: 'status', sortable: false, hidden: false, expanded: false },
            { text: 'Total', value: 'totalItems', align: 'center', sortable: false, hidden: false, expanded: false },
            { text: 'Count', value: 'count', align: 'center', sortable: false, hidden: false, expanded: false },
            { text: 'Success', value: 'success', align: 'center', sortable: false, hidden: false, expanded: false },
            { text: 'Errors', value: 'error', align: 'center', sortable: false, hidden: false, expanded: false },
            { text: 'linked', value: 'linkedId', sortable: false, hidden: true, expanded: false },
            { text: 'recurring', value: 'recurringId', sortable: false, hidden: true, expanded: false },
            { text: 'Created Date Time', value: 'createdDateTime', sortable: false, hidden: false, expanded: false },
            { text: 'Created By', value: 'createdUserId', sortable: false, hidden: false, expanded: false },
            { text: '', value: 'action', width:'180px', align: 'right', sortable: false, hidden: false, expanded: false },
        ],

        // Fields to Retrieve from Database
        sortBy: '@createdDateTime',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['createdDateTime'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        filter: {
            type: [],
            client: [],
            site: [],
            user: [],
            progressFrom: '',
            progressTo: '',
        },

        types: [
            'Recurring Job',
            'Archive Recurring Job',
            'Edit Recurring Job',
            'Archive User',
            'Archive Site',
            'Archive Client',
            'Bulk Action',
            'Delete Job', 
            'Edit Job',
            'Delete User Leave',
            'Delete Communication'
        ],
        loading: false,

        users: [],
        sites: [],
        clients: [],

        date1: '',
        dateMenu1: false,
        date2: '',
        dateMenu2: false,
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
        computedDate1() {
            return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
        computedDate2() {
            return this.MIX_formatDateTime(this.date2, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;
            searchQuery = ''

            if (this.filter.type !== null && this.filter.type.length > 0) {
                console.log('this.filter.type: ', JSON.stringify(this.filter.type, null, 2))
                searchQuery = `${searchQuery} @type:{${this.filter.type}}`
            } 
            if (this.filter.client !== null && this.filter.client.length > 0) {
                searchQuery = `${searchQuery} @linkedId:{${this.filter.client}}`
            }

            if (this.filter.site !== null && this.filter.site.length > 0) {
                searchQuery = `${searchQuery} @linkedId:{${this.filter.site}}`
            }

            if (this.filter.user !== null && this.filter.user.length > 0) {
                searchQuery = `${searchQuery} @linkedId:{${this.filter.user}}`
            }

            if (this.filter.progressFrom !== "" && this.filter.progressFrom !== null && this.filter.progressFrom !== undefined) {
                searchQuery = `${searchQuery} @createdDateTime:[${this.filter.progressFrom} +inf]`
            }

            if (this.filter.progressTo !== "" && this.filter.progressTo !== null && this.filter.progressTo !== undefined) {
                searchQuery = `${searchQuery} @createdDateTime:[-inf ${this.filter.progressTo}]`
            }

            if (searchQuery === '') {
                searchQuery = '*'
            }
            
            // console.log('searchQuery: ', JSON.stringify(searchQuery, null, 2))

            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            return headers;
        },
        computedItems() {
            var items = this.items;
            return items;
        },
    },
    methods: {
        getLinkedItem(item) {
            var t = this; 

            var linkedItemName = ''
            var itemType = item.type
            // console.log('itemType = ' + itemType)

            switch (true) {
				case itemType == 'Archive Site' || itemType == 'Recurring Job' ||  itemType == 'Delete Job' || itemType == 'Edit Job':
					linkedItemName = t.getSite(item.linkedId);
					break;
				case itemType == 'Archive Recurring Job' || itemType == 'Edit Recurring Job':
					linkedItemName = t.getSite(item.linkedId);
					break;
                case itemType == 'Archive User' || itemType == 'Delete User Leave':
                    linkedItemName = t.getUser(item.linkedId);
                    break;
                case itemType == 'Archive Client':
                    linkedItemName = t.getClient(item.linkedId);
                    break;
                // case itemType == 'Delete Communication':
                //     linkedItemName = t.getCommunication(item.linkedId);
                //     break;
                // case itemType == 'Delete Task Template':
                //     linkedItemName = t.getTaskTemplate(item.linkedId);
                //     break;
                // case itemType == 'Delete Task Template Task':
                //     linkedItemName = t.getTaskTemplateTask(item.linkedId);
                //     break;
                default:
                    linkedItemName = '';
                    break;
            }

            return linkedItemName
        },
        goTo(item) {
            var t = this;

            var route = ''

            switch(item.type) {
                case 'Recurring Job': route = '/recurringJob/' + item.recurringId; break;
                case 'Edit Recurring Job': route = '/recurringJob/' + item.recurringId; break;
                case 'Archive Recurring Job': route = '/recurringJob/' + item.recurringId; break;
                case 'Archive Site': route = '/sites/' + item.linkedId; break;
                case 'Archive User': route = '/staffDirectory/' + item.linkedId; break;
                case 'Archive Client': route = '/clients/' + item.linkedId; break;
                case 'Delete Job': route = '/job/' + item.linkedId; break;
                case 'Edit Job': route = '/job/' + item.linkedId; break;
                default: route = ''; break;
            } 

            t.MIX_go(route)

        },
        titleCase(str) {
            var lower = ''
            var string = ''
            if (str !== null) {
                lower = str.toLowerCase()
                string = str.charAt(0).toUpperCase() + lower.slice(1)
            }
            return string
        },
        getUser(userId) {
            var t = this;
            var user = []
            var userName = ''

            user = t.users.filter((item) => { return item.entityId == userId })
            // console.log('user = ' + userId + JSON.stringify(user, null, 2) )
            if (user.length !== 0) { userName = user[0].userName }
            return userName
        },
        getSite(siteId) {
            var t = this;
            var site = []
            var siteName = ''
            
            site = t.sites.filter((item) => { return item.entityId == siteId })

            if (site.length !== 0) { siteName = site[0].siteName }

            return siteName
        },
        getClient(clientId) {
            var t = this;
            var client = []
            var clientName = ''

            client = t.clients.filter((item) => { return item.entityId == clientId })

            if (client.length !== 0) { clientName = client[0].clientName }

            return clientName
        },
        async loadMore() {
            this.itemsFrom = this.itemsFrom + this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo + this.datatableOptions.itemsPerPage;
            this.getItems();
        },
        async goBack() {
            this.itemsFrom = this.itemsFrom - this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo - this.datatableOptions.itemsPerPage;
            this.getItems()
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        refreshItems: function () {
            this.getItems();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // GET ITEMS
        async getItems() {
            var t = this
            t.loading = true
            var sortOrder = 'DESC'
            if (this.filter.progressFrom !== '' && this.filter.progressFrom !== null && this.filter.progressFrom !== undefined || this.filter.progressTo !== '' && this.filter.progressTo !== null && this.filter.progressTo !== undefined) {
                sortOrder = 'ASC'
            }
            // console.log('sortOrder: ', sortOrder)
            let itemsResult = await this.REDIS_searchFor('progress', this.itemsFrom, this.datatableOptions.itemsPerPage, 'createdDateTime', sortOrder, this.computedSearchQuery )
            if (itemsResult.success) {
                this.itemsTotal = itemsResult.data.total;
                var items = itemsResult.data.documents

                // loop through items, and if itemType == 'Archive Recurring Job' or 'Edit Recurring Job' then get the recurringId
                for (let i=0; i < items.length; i++) {
                    if (items[i].type == 'Archive Recurring Job' || items[i].type == 'Edit Recurring Job') {
                        // console.log('item = ' + JSON.stringify(items[i], null, 2))
                        let recurringId = await this.REDIS_read('recurring-job', items[i].linkedId)
                        // console.log('recurringId = ' + JSON.stringify(recurringId, null, 2))
                        if (recurringId.success) {
                            items[i].recurringId = recurringId.data.entityId
                            items[i].linkedId = recurringId.data.recurringJobSiteId
                        }
                        // console.log('item = ' + JSON.stringify(items[i], null, 2))
                    }
                }

                this.items = items
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }

                t.loading = false
            }
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.service = { ...this.datadown };
            },
            deep: true,
        },
        dateMenu1: {
            handler: async function () {
                this.filter.progressFrom = this.MIX_formatDateTime(this.date1, 'YYYY-MM-DD', 'X');
            },
            deep: true,
        },
        dateMenu2: {
            handler: async function () {
                this.filter.progressTo = this.MIX_formatDateTime(this.date2, 'YYYY-MM-DD', 'X');
            },
            deep: true,
        },
        refreshitems() {
            this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        computedHeaders() {
            this.getItems();
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        var t = this
        this.service = { ...this.datadown };
        let users = await t.REDIS_searchFor('user', '0', '9999', 'userName', 'ASC', '*');
        if (users.success) { t.users = users.data.documents; }

        t.users = t.users.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
                userCategory: x.userCategory
            };
        })

        let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '*');
        if (clients.success) { t.clients = clients.data.documents; }

        t.clients = t.clients.map((x) => {
            return {
                entityId: x.entityId,
                clientName: x.clientName,
            };
        })

        let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '*');
        if (sites.success) { t.sites = sites.data.documents; }

        t.sites = t.sites.map((x) => {
            return {
                entityId: x.entityId,
                siteName: x.siteName,
            };
        })

        this.getItems();

    },
};
</script>