<template>
    <div class="" style="width: 100% !important">
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-select 
                    class="" 
                    hide-details="auto" 
                    label="Service" 
                    filled 
                    v-model="filter.service"  
                    outlined
                    dense
                    clearable
                    @click:clear="clearFilter('service')"
                    :items="services" 
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="serviceName" 
                    item-value="entityId"
                ></v-select>
            </div>
            <div class="flex-grow-1 ml-2">
                <v-select 
                    class="" 
                    hide-details="auto" 
                    label="Staff Member" 
                    filled 
                    v-model="filter.staffMember"  
                    outlined
                    dense
                    clearable
                    @click:clear="clearFilter('staffMember')"
                    :items="users" 
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="userName" 
                    item-value="entityId"
                ></v-select>
            </div>
            <div class="flex-grow-1 ml-2">
                <v-select 
                    class="" 
                    hide-details="auto" 
                    label="Sites" 
                    filled 
                    v-model="filter.site"  
                    outlined
                    dense
                    clearable
                    :items="sites" 
                    background-color="white" 
                    color="primary"
                    :menu-props="{ top: false, offsetY: true }" 
                    :attach="true"
                    item-text="siteName" 
                    item-value="entityId" 
                ></v-select>
            </div>
            <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="refreshItems">
                <v-icon>icons8-available-updates</v-icon>
            </v-btn>
            <div class="ml-2">
                <v-menu
                    offset-y
                    class="white"
                    style="z-index: 99999 !important; width: 200px !important; height: 200px !important"
                    :close-on-content-click="false"
                    origin="top right"
                    left
                    transition="scale-transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"
                            ><v-icon>icons8-table-properties</v-icon></v-btn
                        >
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox
                                v-model="headers[index].hidden"
                                dense
                                hide-details="auto"
                                v-for="(item, index) in headers"
                                :key="index"
                                :label="item.text"
                                :true-value="false"
                                :false-value="true"
                            ></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>
		<v-row class="mt-0" dense>
			<v-col cols="12" class="pl-1 pr-0  d-flex align-end">
				<div>
                    <div class="caption font-weight-bold grey--text text--darken-1">STATUS</div>
                    <v-btn-toggle v-model="filter.status" color="white" class="mr-2" active-class="white" background-color="lightgrey" dense>
                        <v-btn value="active" :class="filter.status.includes('active') ? 'success white--text' : 'success--text'" small>
                            <v-icon :class="filter.status.includes('active') ? 'white--text' : 'success--text'" class="text-h5 icons8-checkmark-yes"></v-icon>
                            <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">Active</span>
                        </v-btn>
                        <v-btn value="expiring" :class="filter.status.includes('expiring') ? 'orange white--text' : 'orange--text'" small>
                            <v-icon :class="filter.status.includes('expiring') ? 'white--text' : 'orange--text'"  class="subtitle icons8-filled-circle"></v-icon>
                            <span v-if="!listshrunk && listexpanded" class="caption">Expiring</span>
                        </v-btn>
                        <v-btn value="expired" :class="filter.status.includes('expired') ? 'error white--text' : 'error--text'" small>
                            <v-icon :class="filter.status.includes('expired') ? 'white--text' : 'error--text'"  class="subtitle icons8-filled-circle"></v-icon>
                            <span v-if="!listshrunk && listexpanded" class="caption">EXPIRED</span>
                        </v-btn>
                        <v-btn value="archived" :class="filter.status.includes('archived') ? 'error white--text' : 'error--text'" small>
                            <v-icon :class="filter.status.includes('archived') ? 'white--text' : 'error--text'"  class="subtitle icons8-cancel"></v-icon>
                            <span v-if="!listshrunk && listexpanded" class="caption">Archived</span>
                        </v-btn>
                    </v-btn-toggle>
                </div>
            </v-col>
        </v-row>


        <v-row class="pa-3 pt-1 mt-0">
            <v-data-table
                :item-class="itemRowBackground"
                sortable
                dense
                class=""
                style="width: 100%"
                :headers="computedHeaders"
                :items="computedItems"
                :server-items-length="itemsTotal"
                :options.sync="datatableOptions"
                :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50]
                }"
            >
                <template v-slot:top="{ pagination, updateOptions }">
                    <!-- <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                    </div> -->
                    <v-data-footer :pagination="pagination" :options="datatableOptions" @update:options="updateOptions" :items-per-page-options="[5, 10, 15, 25, 50, 100]" items-per-page-text="$vuetify.dataTable.itemsPerPageText" />
                    
                </template>
				<template v-slot:[`item.recurringRuleStatus`]="{ item }">
					<v-icon v-if="item.recurringRuleStatus === 'ACTIVE'" class="iconbackground text-h5 success--text icons8-filled-circle"></v-icon>
					<v-icon v-else-if="item.recurringRuleStatus === 'EXPIRING'" class="iconbackground text-h5 orange--text icons8-filled-circle"></v-icon>
					<v-icon v-else-if="item.recurringRuleStatus === 'EXPIRED'" class="iconbackground text-h5 error--text icons8-filled-circle"></v-icon>
					<v-icon v-else-if="item.recurringRuleStatus === 'ARCHIVED'" class="iconbackground text-h5 error--text icons8-cancel"></v-icon>
				</template>
                <template v-slot:[`item.recurringJobClientId`]="{ item }">
                    <div>{{ getClient(item.recurringJobClientId)}}</div>
                </template>
                <template v-slot:[`item.recurringJobServiceId`]="{ item }">
                    <div>{{ getService(item.recurringJobServiceId)}}</div>
                </template>
                <template v-slot:[`item.recurringJobAssignedUserId`]="{ item }">
                    <div>{{ getUser(item.recurringJobAssignedUserId)}}</div>
                </template>
                <template v-slot:[`item.recurringJobSiteId`]="{ item }">
                    <div>{{ getSite(item.recurringJobSiteId)}}</div>
                </template>
                <template v-slot:[`item.recurringRuleStartDate`]="{ item }">
                    <div>{{ $moment(item.recurringRuleStartDate).format('DD-MMM-YYYY')}}</div>
                </template>
                <template v-slot:[`item.recurringRuleEndDate`]="{ item }">
                    <div>{{ $moment(item.recurringRuleEndDate).format('DD-MMM-YYYY')}}</div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-icon class="primary--text ml-5" @click="openItem(item)">icons8-forward</v-icon>
                </template>
            </v-data-table>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        type: '',
        status: '',
        key: 'course',
        index: 'idx:courses',
        service: {},
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,


        jobsSelected: [{}, {}],

        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
			{ text: "ID", value: "entityId", align: "start", sortable: true, hidden: true, expanded: false },
			{ text: "Status", value: "recurringRuleStatus", align: "start", sortable: true, hidden: false, expanded: false },
			{ text: "Client", value: "recurringJobClientId", align: "start", sortable: true, hidden: false, expanded: false },
			{ text: "Assigned User", value: "recurringJobAssignedUserId", align: "center", sortable: true, hidden: false, expanded: false, width: "100px" },
			// { text: "ID", value: "id", align: "start", sortable: false, hidden: false, expanded: false },
			{ text: "Assigned Status", value: "recurringJobAssignedStatus", align: "start", sortable: true, hidden: false, expanded: false },
			{ text: "Start Date", value: "recurringRuleStartDate", align: "start", sortable: true, hidden: false, expanded: false },
			{ text: "End Date", value: "recurringRuleEndDate", align: "center", sortable: false, hidden: false, expanded: false },
			{ text: "Type", value: "recurringJobType", align: "center", sortable: false, hidden: false, expanded: false },
			{ text: "Published Status", value: "recurringJobPublishedStatus", align: "center", sortable: false, hidden: false, expanded: false },
			{ text: "Service", value: "recurringJobServiceId", align: "start", sortable: true, hidden: false, expanded: true },
			{ text: "Site", value: "recurringJobSiteId", align: "start", sortable: true, hidden: false, expanded: false },
			{ text: "Notes", value: "recurringJobNotes", align: "start", sortable: true, hidden: false, expanded: false },
			{ text: "Rule", value: "recurringRuleToText", align: "center", sortable: false, hidden: false, expanded: true },
			{ text: "", value: "action", align: "end", sortable: true, hidden: false, expanded: false, width: "100px" },
        ],
        // Fields to Retrieve from Database
        // fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        sortBy: '@courseName',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['courseName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        users: [],
        services: [],
        sites: [],
        clients: [],

        filter: {
            staffMember: '',
            userCategory: '',
            status: 'active',
            site: '',
            service: '',
            client: '',
        },
    }),
    computed: {
        computedSearchQuery() {
            var t = this;
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '';
                } else {
                    searchQuery = '@deleted:{false}';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}`;
                    } else {
                        searchQuery = `${searchQuery}  @deleted:{false}`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}`;
                    } else {
                        searchQuery = `${searchQuery}  @deleted:{false}`;
                    }
                }
            }


            if (this.filter.service !== "" && this.filter.service !== null && this.filter.service !== undefined) {
                searchQuery = `${searchQuery} @recurringJobServiceId:{${this.filter.service}}`
            }

            if (this.filter.site !== "" && this.filter.site !== null && this.filter.site !== undefined) {
                searchQuery = `${searchQuery} @recurringJobSiteId:{${this.filter.site}}`
            }

            if (this.filter.staffMember !== "" && this.filter.staffMember !== null && this.filter.staffMember !== undefined) {
                searchQuery = `${searchQuery} @recurringJobAssignedUserId:{${this.filter.staffMember}}`
            }

            if (this.filter.status !== "" && this.filter.status !== null && this.filter.status !== undefined) {
                const monthAway = this.$moment().startOf('day').add(1, 'month').format('X')
                const current = this.$moment().startOf('day').format('X')
                if (this.filter.status.includes('active')) {
                    // end date more than 30 days in the future
                    searchQuery = `${searchQuery} @recurringRuleEndDate:[ (${monthAway} +inf]`
                } 
                if (this.filter.status.includes('expiring')) {
                    // end date less than 30 days in the future
                    searchQuery = `${searchQuery} @recurringRuleEndDate:[${current} ${monthAway}]`
                } 
                if (this.filter.status.includes('expired')) {
                    // end date less than today
                    searchQuery = `${searchQuery} @recurringRuleEndDate:[-inf (${current}]`
                }
                
                if (this.filter.status.includes('archived')) {
                    // recurring is archived
                    searchQuery = `${searchQuery} @recurringRuleStatus:{'ARCHIVED'}`
                } else {
                    searchQuery = `${searchQuery} -@recurringRuleStatus:{'ARCHIVED'}`
                }
            }
            
            
            console.log(searchQuery)
            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (this.listshrunk) {
                headers = headers.filter((header) => header.expanded === false || header.expanded === true); // Return Headers based on Expanded State
            } else {
                headers = headers.filter((header) => header.expanded === false); // Return Headers based on Expanded State
            }
            return headers;
        },
        computedItems() {
            var items = this.items;

            const monthAway = this.$moment().startOf('day').add(1, 'month').format('X')
            const current = this.$moment().startOf('day').format('X')

            items.forEach((recurring) => {
                var ruleEndDate = this.$moment(recurring.recurringRuleEndDate).format('X')
                if (recurring.recurringRuleStatus == null || recurring.recurringRuleStatus !== 'ARCHIVED') {
                    if (ruleEndDate > monthAway) {
                        recurring.recurringRuleStatus = 'ACTIVE'
                    } else if (ruleEndDate <= monthAway && ruleEndDate > current) {
                        recurring.recurringRuleStatus = 'EXPIRING' 
                    } else if (ruleEndDate <= current) {
                        recurring.recurringRuleStatus = 'EXPIRED'
                    }
                }
            })


            if (this.type) {
                items = this.items.filter((item) => item.actionType === this.type);
            }
            if (this.status) {
                items = this.items.filter((item) => item.actionStatus === this.status);
            }

        

            return items;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            // if (!computedFields.includes('@actionId')) {
            //     computedFields.push('@actionId');
            // }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedType() {
            const types = [];
            this.items.filter((item) => {
                return types.push(item.actionType);
            });
            return types;
        },
        computedStatus() {
            const status = [];
            this.items.filter((item) => {
                return status.push(item.actionStatus);
            });
            return status;
        },
    },
    methods: {
        // Clear Item Property
        clearFilter(property) {
            setTimeout(() => {
                this.filter[property] = '';
            }, 1);
        },
        getClient(clientId) {
            var t = this;
            var client = []
            var clientName = ''

            client = t.clients.filter((item) => { return item.entityId == clientId })

            if (client.length !== 0) { clientName = client[0].clientName }
            return clientName
        },
        getUser(userId) {
            var t = this;
            var user = []
            var userName = ''

            user = t.users.filter((item) => { return item.entityId == userId })

            if (user.length !== 0) { userName = user[0].userName }

            return userName
        },
        getSite(siteId) {
            var t = this;
            var site = []
            var siteName = ''

            site = t.sites.filter((item) => { return item.entityId == siteId })

            if (site.length !== 0) { siteName = site[0].siteName }

            return siteName
        },
        getService(serviceId) {
            var t = this;
            var service = []
            var serviceName = ''

            service = t.services.filter((item) => { return item.entityId == serviceId })

            if (service.length !== 0) { serviceName = service[0].serviceName }

            return serviceName
        },
        async editItem(id) {
            // Get the item from the database
            let itemResult = await this.MIX_readItem(id, 'service');
            if (itemResult.code === 1) {
                this.$emit('edit', itemResult.data);
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Editing Component', show: true });
            }
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        refreshItems: function () {
            // this.getItems();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        async getItems() {
            var t = this;
            t.loading = true

            //console.log('computedSearchQuery = ' + t.computedSearchQuery)
            //console.log('itemsFrom = ' + t.itemsFrom)
            //console.log('itemsTo = ' + t.itemsTo)


            let itemsResult = await this.REDIS_searchFor('recurring-job', this.itemsFrom, this.datatableOptions.itemsPerPage, 'recurringJobStartDateTime','ASC', this.computedSearchQuery )
            // //console.log('itemsResult: ' + JSON.stringify(itemsResult));
            if (itemsResult.success) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.documents;
                t.loading = false
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            }
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.service = { ...this.datadown };
            },
            deep: true,
        },
        refreshitems() {
            // this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        computedFields() {
            // this.getItems();
        },
        toggleDeletedItems() {
            // this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        var t = this;
        this.service = { ...this.datadown };

        let users = await t.REDIS_searchFor('user', '0', '999', 'userName', 'ASC', '@deleted:{false} @userStatus:{APPROVED}');
        if (users.success) { t.users = users.data.documents; }

        t.users = t.users.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
                userCategory: x.userCategory
            };
        })

        let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false} -@clientStatus:{ARCHIVE}');
        if (clients.success) { t.clients = clients.data.documents; }

        t.clients = t.clients.map((x) => {
            return {
                entityId: x.entityId,
                clientName: x.clientName,
            };
        })
        
        let services = await t.REDIS_searchFor('service', '0', '9999', 'serviceName', 'ASC', '@deleted:{false}');
        if (services.success) { t.services = services.data.documents; }

        t.services = t.services.map((x) => {
            return {
                entityId: x.entityId,
                serviceName: x.serviceName,
            };
        })

        let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
        if (sites.success) { t.sites = sites.data.documents; }

        t.sites = t.sites.map((x) => {
            return {
                entityId: x.entityId,
                siteName: x.siteName,
            };
        })

        this.getItems();
    },
};
</script>