import Vue from "vue";

const qr_code_reader_mixins = {       
    data: () => ({
     
    }),
    methods: {
        MIX_decodeResult(result) {
            this.$store.dispatch("qr_code_reader_store/ACT_result", result);
            //this.result = result;
            var path = require("./assets/swapp_signin.mp3");
            var audio = new Audio(path); // path to file
            audio.play();
            //scroll issue
            window.scrollTo(0, 0);
        },
        MIX_setQrCode(result){
            var payloadD = {};
            payloadD.result = result;
            //console.log("THe decion in mixins " + payloadD.answer);
            this.$store.dispatch("qr_code_reader_store/ACT_result", result);
        },
        MIX_onInitError(error){
            if (error.name === "NotAllowedError") {
                this.$store.dispatch("qr_code_reader_store/ACT_error", "ERROR: you need to grant camera access permisson");
            } else if (error.name === "NotFoundError") {
                this.$store.dispatch("qr_code_reader_store/ACT_error", "ERROR: no camera on this device");
            } else if (error.name === "NotSupportedError") {
                this.$store.dispatch("qr_code_reader_store/ACT_error", "ERROR: secure context required (HTTPS, localhost)");
            } else if (error.name === "NotReadableError") {
                this.$store.dispatch("qr_code_reader_store/ACT_error", "ERROR: is the camera already in use?");
            } else if (error.name === "OverconstrainedError") {
                this.$store.dispatch("qr_code_reader_store/ACT_error", "ERROR: installed cameras are not suitable");
            } else if (error.name === "StreamApiNotSupportedError") {
                this.$store.dispatch("qr_code_reader_store/ACT_error", "ERROR: Stream API is not supported in this browser");
            }
        }
    }
}
  
export default {
    install (Vue, options) {
        Vue.mixin(qr_code_reader_mixins)
    }
};
  
   
  