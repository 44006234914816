<template>
	<v-row class=" pr-3 pl-3 pt-3 pb-0">
		<v-col cols="12" xs="12" sm="12">
			<v-row no-gutters class="">
				<v-col class="px-1" cols="12">
					<app-text category="page-title">
                        <span class="primary--text">WELCOME </span> 
                        <span class="secondary--text text-uppercase"> {{ GET_FIREBASE_currentUser.userName.split(" ")[0] }}</span>
                    </app-text>
				</v-col>
			</v-row>
		</v-col>
        <!-- <pre>{{ GET_FIREBASE_currentUser }}</pre> -->

		<v-col cols="12" xs="12" sm="12" md="6" class="pt-0 mt-0">
            <v-row dense class="px-1">
                <v-card style="width: 100% !important" flat class="pa-4 grey lighten-4">
                    <div class="text-h7 primary--text font-weight-bold">Getting Started</div>
                    <div class="body-2 grey--text text--darken-1">
                        <p>Welcome to the A&R Cleaning Safe Workplace App (SWAPP). We are just getting started, so you can expect more features soon including the ability to order stock and tools.</p>
                        <p>To begin, SWAPP In at your place of work, using the button below, and don't forget to SWAPP Out when you leave.</p>
                    </div>
                </v-card>
            </v-row>
            <v-row dense class="pb-5">
                <v-col v-for="(item, index) in computedDashCards" :key="index"  cols="12" sm="12" md="6">
                    <dashCard :dashTitle="item.title" :dashText="item.text" :dashIcon="item.icon" :dashTo="item.to" />
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-card :style="$vuetify.breakpoint.mdAndUp ? 'height: 120px' : 'height:90px'" flat class="d-flex pa-2 mt-2 py-3 py-sm-4 grey lighten-4" :href="'https://www.adcockexpress.co.uk/myaccount/login'" target="_blank">
                        <v-row class="d-flex align-center py-1" no-gutters>
                            <v-col cols="3" xs="3" sm="4" class="text-center">
                                <v-icon class="grey--text text--darken-2 display-3">icons8-procurement</v-icon>
                            </v-col>
                            <v-col cols="9" xs="9" sm="8" class=" font-weight-bold secondary--text px-3">
                                <div class="text-uppercase">Stock Ordering</div>
                                <div class="body-2">Order Stock</div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="$vuetify.breakpoint.mdAndUp && ['SA'].includes(GET_FIREBASE_currentUser.userLevel)" dense>
                <v-col cols="12">
                    <div class="primary--text">DASHBOARD ADMIN</div>
                </v-col>
                <v-col cols="6">
                    <v-card style="height: 120px" flat class="d-flex pa-2 mb-3 py-3 py-sm-4 grey lighten-4" @click="openSalesTargets">
                        <v-row class="d-flex align-center py-1" no-gutters>
                            <v-col cols="3" xs="3" sm="4" class="text-center"><v-icon class="grey--text text--darken-2 display-3">icons8-presentation</v-icon></v-col>
                            <v-col cols="9" xs="5" sm="8" class=" font-weight-bold secondary--text px-3">
                                <div class="text-uppercase">SALES TARGETS</div>
                                <div class="body-2">Edit the Sales Targets for the Dashboard</div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
		</v-col>
        <!-- <pre>{{ GET_FIREBASE_currentUser }}</pre> -->
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
		<v-col v-if="$vuetify.breakpoint.mdAndUp && (GET_FIREBASE_currentUser.userLevel == 'SA' || GET_FIREBASE_currentUser.userLevel == 'DA')" class="mt-0 pt-0 mb-5" cols="12" xs="12" sm="12" md="3">
			<div>
				<div class="text-h7 mb-2 font-weight-bold secondary--text text-decoration-none">OUTSTANDING HOTLIST TASKS ({{ hotlistTasks.length }})</div>
				<v-row>
					<v-col cols="12" xs="12" class="pa-1 px-3" v-for="task in computedHotlistTasks" :key="task.id">
                        <hotlist-task-card class="mb-1" style="" :task="task"></hotlist-task-card>
					</v-col>
				</v-row>
			</div>
		</v-col>
		<v-col v-if="$vuetify.breakpoint.mdAndUp" class="mt-0 pt-0 mb-5" cols="12" xs="12" sm="12" md="3">
			<div v-if="GET_FIREBASE_currentUser.userLevel == 'SU'">
				<div class="text-h7 mb-2 font-weight-bold secondary--text text-decoration-none">JOBS ({{ items.length }})</div>
				<v-row>
					<v-col cols="12" xs="12" class="pa-1 px-3" v-for="job in computedItems" :key="job.id">
                        <job-card class="mb-2" style="" :job="job" :jobsPage=true></job-card>
					</v-col>
				</v-row>
			</div>
			<div v-else-if="GET_FIREBASE_currentUser.userLevel == 'SA' || GET_FIREBASE_currentUser.userLevel == 'DA'">
				<div class="text-h7 mb-2 font-weight-bold secondary--text text-decoration-none">OUTSTANDING SITE TASKS ({{ items.length }})</div>
				<v-row>
					<v-col cols="12" xs="12" class="pa-1 px-3" v-for="task in computedTaskItems" :key="task.id">
                        <task-card class="mb-2" style="" :task="task"></task-card>
					</v-col>
				</v-row>
			</div>
		</v-col>

        <v-dialog persistent v-model="salesTargetDialog" width="800px" height="600px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 secondary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mr-3 mb-1">
                        <v-icon class="white--text">icons8-future</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Sales Monthly Targets</strong>
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="salesTargetDialog = !salesTargetDialog" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 pa-0">               
                    
                    <v-row class="pa-0 pt-4 ma-0 justify-center d-flex" fill-height style="height: 100% !important" >
                        <v-col cols="12" class="align-center pt-0">
                            Please select a month to view and add sales targets for that month:
                            <v-select 
                                class="" 
                                hide-details="auto" 
                                label="Month" 
                                filled 
                                v-model="selectedMonth"  
                                outlined
                                dense
                                clearable
                                :items="months" 
                                background-color="white" 
                                color="primary"
                                :menu-props="{ top: false, offsetY: true }" 
                            ></v-select>
                        </v-col>
                        <v-col v-if="targetExists && selectedMonth !== null" class="" fill-height cols="12" style="width: 100% !important">
                            <div class="d-flex ">
                                <v-text-field class="mr-1" label="Target" disabled v-model="monthTarget.targetValue" outlined dense hide-details="auto" background-color="white" color="primary" clearable type="number"></v-text-field>
                                <v-text-field class="ml-4" label="Net" disabled v-model="targetNet" outlined dense hide-details="auto" background-color="white" color="primary" clearable type="number"></v-text-field>
                            </div>

                            <v-row class="d-flex pt-3">
                                <v-col cols="6" class="d-flex flex-column">
                                    <div class="font-weight-bold text-h6 grey--text text--darken-2">Won
                                        <v-icon class="success--text ml-1" @click="addWin()" small>+</v-icon>
                                    </div>
                                    <div v-for="(won, index) in targetWonItems" :key="index">
                                        <span class="body-1 grey--text text--darken-1">{{ won.targetName }} ({{ won.targetValue }}) </span>
                                        <v-icon class="error--text" @click="removeWin(won)" small>icons8-delete</v-icon>
                                    </div>
                                </v-col>
                                <v-col cols="6" class="d-flex flex-column">
                                    <div class="font-weight-bold text-h6 grey--text text--darken-2">Lost
                                        <v-icon class="success--text ml-1" @click="addLoss()" small>+</v-icon>
                                    </div>
                                    <div v-for="(loss, index) in targetLostItems" :key="index">
                                        <span class="body-1 grey--text text--darken-1">{{ loss.targetName }} ({{ loss.targetValue }}) </span>
                                        <v-icon class="error--text" @click="removeWin(loss)" small>icons8-delete</v-icon>
                                    </div>

                                </v-col>
                            </v-row>

                            <v-row v-if="addNew" class="d-flex">
                                <v-col cols="12" class="pb-0 mb-0">
                                    <div class="font-weight-bold pb-0 mb-0 text-h6 grey--text text--darken-2">Adding New
                                    </div>
                                </v-col>

                                <v-col cols="6" class="pt-0 mt-0">
                                    <v-text-field class="" label="Name" v-model="targetItem.targetName" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field>
                                </v-col>
                                <v-col cols="6" class="d-flex pt-0 mt-0">
                                    <v-text-field class="" label="Target" v-model="targetItem.targetValue" outlined dense hide-details="auto" background-color="white" color="primary" clearable type="number"></v-text-field>
                                    <v-icon class="success--text ml-2" @click="saveTarget()" small>icons8-checkmark</v-icon>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col v-else-if="!targetExists && selectedMonth !== null" class="" fill-height cols="12" style="width: 100% !important">
                            Please add a Target for this month:
                            <v-text-field label="Target" v-model="targetItem.targetValue" outlined dense hide-details="auto" background-color="white" color="primary" clearable type="number"></v-text-field>
                            

                        </v-col>
                    </v-row>
                    <!-- <pre>Target Item: {{ targetItem }}</pre> -->
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-0">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="closeTrainingDialog"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                            <v-btn :disabled="targetExists" class="primary white--text font-weight-bold ml-2" depressed @click="saveTarget"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
                        </v-col>
                    </v-col>
                </v-card-actions>
            </div>
        </v-dialog>
	</v-row>
</template>

<script>
	import { mapGetters, mapActions } from "vuex";
	import schema from "@/schema.js";
    import JobCard from '@/views/jobs/jobCard.vue';
    import TaskCard from '@/views/taskTemplates/taskCard.vue'
    import HotlistTaskCard from '@/views/hotlistTasks/hotlistTaskCard.vue'

    const DashCard = {
        props: ['dashTitle', 'dashText', 'dashIcon', 'dashTo', 'dashIf'],
        template: `
            <v-card :disabled="dashTo == 'disabled'" :style="$vuetify.breakpoint.mdAndUp ? 'height: 120px' : 'height:90px'" flat class="d-flex pa-2 mt-2 py-3 py-sm-4 grey lighten-4" @click="MIX_go(dashTo)">
                <v-row class="d-flex align-center py-1" no-gutters>
                    <v-col cols="3" xs="3" sm="4" class="text-center">
                        <v-icon class="grey--text text--darken-2 display-3">{{ dashIcon }}</v-icon>
                    </v-col>
                    <v-col cols="9" xs="9" sm="8" class=" font-weight-bold secondary--text px-3">
                        <div class="text-uppercase">{{ dashTitle }}</div>
                        <div class="body-2">{{ dashText }}</div>
                    </v-col>
                </v-row>
            </v-card>
        `
    }

	export default {
		name: "Home",
        components : {
            DashCard: DashCard,
            'job-card': JobCard,
            'task-card': TaskCard,
            'hotlist-task-card': HotlistTaskCard,
        },
        data: () => ({
            jobDetails: [],
            sortBy: 'date',
            jobs: {},
            user: {},
            key: 'user',
            salesTargetDialog: false,

            months: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
            selectedMonth: null,
            targetExists: false,

            targetItem: {},
            monthTarget: {},
            targetWonItems: [],
            targetLostItems: [],
            targetNet: 0,
			schema: null,

            addNew: false,

            items: [],
            itemsTotal: null,
            itemsFrom: 0,
            itemsTo: 9,
            
            hotlistTasks: [],

            datatableOptions: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['courseName'],
                sortDesc: [false],
                groupBy: [],
                groupDesc: [],
                mustSort: false,
                multiSort: false,
            },

            dashCards: [
                { 
                    title: 'SWAPP IN', 
                    text: 'When you arrive at Site you must SWAPP In at a Location.', 
                    icon: 'icons8-qr-code', 
                    to: '/swapp/in',
                    screensize: 'mobile',
                    userLevel: 'all',
                    swappStatus: 0,
                },
                // { 
                //     title: 'SWAPP IN', 
                //     text: 'When you arrive at Site you must SWAPP In at a Location. This must be done on a mobile device', 
                //     icon: 'icons8-qr-code', 
                //     to: 'disabled', 
                //     screensize: 'desktop',
                //     userLevel: 'all',
                //     swappStatus: 0,
                // },

                { 
                    title: 'SWAPP OUT', 
                    text: 'When you leave a Location you must SWAPP Out of the Location.', 
                    icon: 'icons8-qr-code', 
                    to: '/swapp/out', 
                    screensize: 'mobile',
                    userLevel: 'all',
                    swappStatus: 1,
                },
                { 
                    title: 'SWAPP OUT', 
                    text: 'When you leave a Location you must SWAPP Out of the Location. This must be done on a mobile device', 
                    icon: 'icons8-qr-code', 
                    to: 'disabled', 
                    screensize: 'desktop',
                    userLevel: 'all',
                    swappStatus: 1,
                },

                // Staff Directory
                // { 
                //     title: 'STAFF DIRECTORY', 
                //     text: 'Add or Edit a Staff Member', 
                //     icon: 'icons8-people', 
                //     to: '/staffDirectory/:id', 
                //     screensize: 'desktop',
                //     userLevel: `'SA', 'DA'`,
                //     swappStatus: '-',
                // },
                // Manager Comms

                // { 
                //     title: 'COMMUNICATIONS', 
                //     text: 'View or Add Communications', 
                //     icon: 'icons8-communication', 
                //     to: '/communications/:id', 
                //     screensize: 'desktop',
                //     userLevel: `'SA', 'DA'`,
                //     swappStatus: '-',
                // },
                { 
                    title: 'TASKS', 
                    text: 'View or Add Tasks', 
                    icon: 'icons8-list', 
                    to: '/siteTasks', 
                    screensize: 'mobile',
                    userLevel: `'SA', 'DA'`,
                    swappStatus: '-',
                },


                // Desktop Jobs
                // { 
                //     title: 'JOBS - Admin', 
                //     text: 'Add, View and Allocate Jobs', 
                //     icon: 'icons8-broom', 
                //     to: '/jobs', 
                //     screensize: 'desktop',
                //     userLevel: `'SA', 'DA'` ,
                //     swappStatus: '-',
                // },

                // Mobile Jobs
                { 
                    title: 'ROTA', 
                    text: 'View Allocated Jobs', 
                    icon: 'icons8-event-accepted-tentatively', 
                    to: '/rota', 
                    screensize: 'mobile',
                    userLevel: 'all',
                    swappStatus: '-',
                },
                { 
                    title: 'JOBS', 
                    text: 'View and Start Allocated Jobs', 
                    icon: 'icons8-broom', 
                    to: '/swappedJobs', 
                    screensize: 'mobile',
                    userLevel: 'all',
                    swappStatus: 1,
                },
                { 
                    title: 'COMMUNICATIONS', 
                    text: 'View Communications for current Site', 
                    icon: 'icons8-communication', 
                    to: '/communications/:id?', 
                    screensize: 'mobile',
                    userLevel: 'all',
                    swappStatus: 1,
                },
                { 
                    title: 'JOBS - Admin', 
                    text: 'Add, View and Allocate Jobs', 
                    icon: 'icons8-broom', 
                    to: '/mobileJobs', 
                    screensize: 'mobile',
                    userLevel: `['SA', 'DA']`,
                    swappStatus: '-',
                },

                // Dashboard
                { 
                    title: 'DASHBOARD', 
                    text: 'View Dashboard', 
                    icon: 'icons8-presentation', 
                    to: '/dashboard', 
                    screensize: 'desktop',
                    userLevel: `'SA', 'DA'`,
                    swappStatus: '-',
                },

                // Audits
                { 
                    title: 'AUDIT', 
                    text: 'Add Audit', 
                    icon: 'icons8-audit', 
                    to: '/job/audit', 
                    screensize: 'all',
                    userLevel: `['SA', 'DA']`,
                    swappStatus: '-',
                },
            ]
        }),
		props: {
			msg: String,
		},
		computed: {
            ...mapGetters({
                GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
            }),
            computedTaskItems() {
                var items = this.items;

                // console.log('items = ', JSON.stringify(items, null, 2))

                items.forEach((item) => {
                    item.taskClientName = this.getClient(item.taskOrgId)
                    item.taskSiteName = this.getSite(item.taskSiteId)
                    item.taskAssignedUserName = this.getUser(item.jobAssignedUserId)
                })
                
                return items;
            },
            computedItems() {
                var items = this.items;

                items.forEach((item) => {
                    item.jobClientName = this.getClient(item.jobClientId)
                    item.jobServiceName = this.getService(item.jobServiceId)
                    item.jobSiteName = this.getSite(item.jobSiteId)
                    item.jobAssignedUserName = this.getUser(item.jobAssignedUserId)
                })
                
                return items;
            },
            computedHotlistTasks() {
                var t = this;
                var items = this.hotlistTasks

                items.forEach((item) => {
                    item.taskClientName = t.getClient(item.taskClientId)
                    item.taskSiteName = t.getSite(item.taskSiteId)
                    item.taskUserName = t.getUser(item.taskUserId)
                    item.taskAssignedUserName = t.getUser(item.taskAllocatedUserId)
                })
                
                return items;
            },
            computedDashCards() {
                var t = this;

                var cards = t.dashCards
                var swappStatus = t.GET_FIREBASE_currentUser.swappStatus

                // console.log('swappStatus = ' + swappStatus)

                cards = cards.filter(function (item) {
                    // console.log('item.swappStatus = ' + item.swappStatus)
                    return item.swappStatus == swappStatus || item.swappStatus == '-'
                })

                var desktop = this.$vuetify.breakpoint.mdAndUp

                cards = cards.filter(function (item) {
                    if (desktop) {
                        return item.screensize == 'desktop' || item.screensize == 'all'
                    } else {
                        return item.screensize == 'mobile' || item.screensize == 'all'
                    }
                })

                cards =  cards.filter(function (item) {
                    // console.log('item.userLevel = ' + item.userLevel)
                    // console.log('t.GET_FIREBASE_currentUser.userLevel = ' + t.GET_FIREBASE_currentUser.userLevel)
                    if (t.GET_FIREBASE_currentUser.userLevel !== '') {
                        return item.userLevel.includes(t.GET_FIREBASE_currentUser.userLevel) || item.userLevel == 'all'
                    } else {
                        return item.userLevel == 'all'
                    }
                })

                return cards 
            },
		},
        watch: {
            selectedMonth: {
                handler: function () {
                    // console.log('selectedMonth changed = ' + this.selectedMonth)
                    if (this.selectedMonth !== null ) {
                        this.checkMonth()
                    }
                },
                deep: true,
            },
        },
        methods: {
            ...mapActions({ 'ACT_openItem': 'ACT_openJob', 'ACT_services': 'ACT_services', 'ACT_staff': 'ACT_staff', 'ACT_clients': 'ACT_clients', 'ACT_sites': 'ACT_sites'}),  
            addWin() {
                var t = this;

                var monthStartDateTime = this.$moment(this.selectedMonth, 'MMM').format('X')
                // console.log('monthStartDateTime = ' + monthStartDateTime)
                t.targetItem = {...t.schema}
                t.targetItem.targetStartDateTime = this.$moment(monthStartDateTime, 'X').format()
                t.targetItem.targetMonthTarget = false
                t.targetItem.targetOutcome = 'Win'

                t.addNew = true

            },
            addLoss() {
                var t = this;

                var monthStartDateTime = this.$moment(this.selectedMonth, 'MMM').format('X')
                // console.log('monthStartDateTime = ' + monthStartDateTime)
                t.targetItem = {...t.schema}
                t.targetItem.targetStartDateTime = this.$moment(monthStartDateTime, 'X').format()
                t.targetItem.targetMonthTarget = false
                t.targetItem.targetOutcome = 'Loss'

                t.addNew = true

            },
            getClient(clientId) {
                var t = this;
                var client = []
                var clientName = ''

                client = t.clients.filter((item) => { return item.entityId == clientId })

                if (client.length !== 0) { clientName = client[0].clientName }
                return clientName
            },
            getUser(userId) {
                var t = this;
                var user = []
                var userName = ''

                user = t.users.filter((item) => { return item.entityId == userId })

                if (user.length !== 0) { userName = user[0].userName }

                return userName
            },
            getService(serviceId) {
                var t = this;
                var service = []
                var serviceName = ''

                service = t.services.filter((item) => { return item.entityId == serviceId })

                if (service.length !== 0) { serviceName = service[0].serviceName }

                return serviceName
            },
            getSite(siteId) {
                var t = this;
                var site = []
                var siteName = ''

                site = t.sites.filter((item) => { return item.entityId == siteId })

                if (site.length !== 0) { siteName = site[0].siteName }

                return siteName
            },
            closeTrainingDialog() {
                var t = this;

                t.addNew = false 
                t.targetItem = {...t.schema}
                t.selectedMonth = null
                t.salesTargetDialog = false
            },
			// * DELETE ITEM
			async removeWin(item) {
                item.deletedUserId = this.GET_FIREBASE_currentUser.entityId
				let result = await this.REDIS_delete('targets', item.entityId, item);
				if (result.success) {
					t.MIX_alertBox({ color: "success", timeout: 2000, message: "Deleted", show: true });
                    this.getMonthItems()
				}
			},
            openAudit() {
                this.MIX_go('/job/audit')
            },
            openSalesTargets() {
                var t = this; 
                t.salesTargetDialog = true
            },
            closeSalesTargets() {
                var t = this; 

                t.salesTargetDialog = false,
                t.selectedMonth = null
            },
            async saveTarget() {
                var t = this;
                
                var item = t.targetItem

                item.targetValue = parseInt(item.targetValue)
                
                var dateTimeNow = t.$moment().toISOString()

                item.createdDateTime = dateTimeNow;
                item.createdUserId = t.GET_FIREBASE_currentUser.entityId;
                
                let result = await this.REDIS_create('targets', item);
                
				if (result.success) {
					t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                    t.getMonthItems()
                    t.addNew = false
                    t.targetItem = schema
				}

            },
            async getMonthItems() {
                var t = this; 

                var monthStartDateTime = this.$moment(this.selectedMonth, 'MMM').format('X')

                let itemsResult = await this.REDIS_searchFor('targets', 0, 9999, 'targetStartDateTime','ASC', `@deleted:{false} @targetStartDateTime:[${monthStartDateTime} ${monthStartDateTime}] @targetMonthTarget:{false}`)

                var net = 0
                if (itemsResult.success) {
                    var items = itemsResult.data.documents
                    this.targetWonItems = []
                    this.targetLostItems = []

                    var net = 0
                    for( var i = 0; i < items.length; i++ ) {
                        var item = items[i]


                        if (item.targetOutcome == 'Win') {
                            this.targetWonItems.push(item)
                            net = net + item.targetValue
                        }
                        else if (item.targetOutcome == 'Loss') {
                            this.targetLostItems.push(item)
                            net = net - item.targetValue
                        }

                    }

                }
                t.targetNet = net

            },
            async checkMonth() {
                var t = this;

                var monthStartDateTime = this.$moment(this.selectedMonth, 'MMM').format('X')

                let itemsResult = await this.REDIS_searchFor('targets', 0, 9999, 'targetStartDateTime','ASC', `@deleted:{false} @targetStartDateTime:[${monthStartDateTime} ${monthStartDateTime}] @targetMonthTarget:{true}`)

                if (itemsResult.success && itemsResult.data.total !== 0) {
                    // already exists
                    // console.log('already exists')

                    t.targetExists = true

                    t.monthTarget = itemsResult.data.documents[0]
                    t.getMonthItems()

                    t.targetItem = {...t.schema}
                    t.targetItem.targetStartDateTime = this.$moment(monthStartDateTime, 'X').format()
                } else if (itemsResult.success && itemsResult.data.total == 0 ) {
                    // doesnt exist 
                    // console.log('doesnt exist')
                    t.targetExists = false
                    t.targetItem = {...t.schema}
                    t.monthTarget = {}

                    t.targetItem.targetStartDateTime = this.$moment(monthStartDateTime, 'X').format()
                    t.targetItem.targetMonthTarget = true


                } else {
                    // error
                    // console.log('error')
                }

                
            },

            async getJobItems() {

                var searchQuery = `@deleted:{false} @jobAssignedUserId:{${this.GET_FIREBASE_currentUser.entityId}} @jobPublishedStatus:{PUBLISHED} @jobStartDateTime:[${this.$moment().format('X')} +inf]`


                let itemsResult = await this.REDIS_searchFor(
                    'jobs', 
                    this.itemsFrom, 
                    this.datatableOptions.itemsPerPage, 
                    'jobStartDateTime',
                    'ASC', 
                    searchQuery 
                )

                if (itemsResult.success) {
                    this.itemsTotal = itemsResult.data.total;
                    this.items = itemsResult.data.documents;
                    let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                    if (itemsTo < this.itemsTotal) {
                        this.itemsTo = itemsTo;
                    } else {
                        this.itemsTo = this.itemsTotal;
                    }
                }

            },

            async getHotlistTaskItems() {

                if (this.GET_FIREBASE_currentUser.userCategory == 'DIRECTOR' || this.GET_FIREBASE_currentUser.userLevel == 'DA') {
                    var searchQuery = `@deleted:{false} @taskStatus:OVERDUE`
                } else {
                    var searchQuery = `@deleted:{false} @taskAllocatedUserId:{${this.GET_FIREBASE_currentUser.entityId}} @taskStatus:OVERDUE`
                }

                let itemsResult = await this.REDIS_searchFor(
                    'hotlist-task', 
                    this.itemsFrom, 
                    this.datatableOptions.itemsPerPage, 
                    'createdDateTime',
                    'ASC', 
                    searchQuery 
                )

                if (itemsResult.success) {
                    this.itemsTotal = itemsResult.data.total;
                    this.hotlistTasks = itemsResult.data.documents;
                    let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                    if (itemsTo < this.itemsTotal) {
                        this.itemsTo = itemsTo;
                    } else {
                        this.itemsTo = this.itemsTotal;
                    }
                }
            },

            async getTaskItems() {
                
                if (this.GET_FIREBASE_currentUser.userCategory == 'DIRECTOR' || this.GET_FIREBASE_currentUser.userLevel == 'DA') {
                    var searchQuery = `@deleted:{false} @taskCompleted:{false} -@taskTitle:'Note from Cleaner'`
                } else {
                    var searchQuery = `@deleted:{false} @taskManagerId:{${this.GET_FIREBASE_currentUser.entityId}} @taskCompleted:{false} -@taskTitle:'Note from Cleaner'`
                }

                let itemsResult = await this.REDIS_searchFor(
                    'task', 
                    this.itemsFrom, 
                    this.datatableOptions.itemsPerPage, 
                    'createdDateTime',
                    'ASC', 
                    searchQuery 
                )

                if (itemsResult.success) {
                    this.itemsTotal = itemsResult.data.total;
                    this.items = itemsResult.data.documents;
                    let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                    if (itemsTo < this.itemsTotal) {
                        this.itemsTo = itemsTo;
                    } else {
                        this.itemsTo = this.itemsTotal;
                    }
                }
            }

        },
        async mounted() {
            var t = this;

			t.schema = schema.targets;

            let users = await t.REDIS_searchFor('user', '0', '999', 'userStartDate', 'ASC', '@deleted:{false}');
            if (users.success) { t.users = users.data.documents; }

            t.users = t.users.map((x) => {
                return {
                    entityId: x.entityId,
                    userName: x.userName,
                };
            })

            let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false} -@clientStatus:{ARCHIVE}');
            if (clients.success) { t.clients = clients.data.documents; }

            t.clients = t.clients.map((x) => {
                return {
                    entityId: x.entityId,
                    clientName: x.clientName,
                };
            })

            
            let services = await t.REDIS_searchFor('service', '0', '9999', 'serviceName', 'ASC', '@deleted:{false}');
            if (services.success) { t.services = services.data.documents; }

            t.services = t.services.map((x) => {
                return {
                    entityId: x.entityId,
                    serviceName: x.serviceName,
                };
            })

            let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
            if (sites.success) { t.sites = sites.data.documents; }

            t.sites = t.sites.map((x) => {
                return {
                    entityId: x.entityId,
                    siteName: x.siteName,
                };
            })
            if (t.GET_FIREBASE_currentUser.userLevel == 'SU') {
                t.getJobItems()
            } else {
                t.getTaskItems()
                t.getHotlistTaskItems()
            }
        }
	};
</script>