<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: auto !important' : 'height: calc(100vh - 120px) !important; overflow: auto !important '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>

                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''">
                    <v-btn text class="primary white--text" @click="addNewCommunication" ><v-icon class="mr-2">icons8-communication</v-icon>Add</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="apptext--text pa-5 pt-3" dense style="z-index: 0 !important">
            <div class="font-weight-bold grey--text py-2">Below are the Tasks for this Template. When this Template is activated, all the Active Tasks below will be allocated to the appropriate users.</div>
            <TaskList :refreshitems="refreshItems" v-on:openItem="openItem" :datadown="item"/>

        </v-row>


        <v-dialog persistent v-model="taskDialog" max-width="612" :key="taskDialogKey">
            <v-card>
                <v-toolbar color="secondary" elevation="0">
                    <app-text category="text-medium" class="white--text ml-2">Create New Task</app-text>

                    <v-spacer />
                    
                    <v-btn @click="taskDialog = false" icon dark>
                        <v-icon class="icons8-multiply" color="white" />
                    </v-btn>
                </v-toolbar>
                <div class="grey lighten-4 pa-4">
                    <v-form ref="form" lazy-validation>
                        <v-row dense class="">
                            <v-col cols="12" class="py-0 font-weight-bold grey--text text-h6">
                                Task Details
                            </v-col>
                            <v-col cols="12" xs="12">
                                <v-textarea 
                                    label="Task" 
                                    v-model="task.taskTitle" 
                                    required 
                                    dense 
                                    outlined
                                    hide-details="auto" 
                                    background-color="white" 
                                    color="primary"
                                    rows="3"
                                    :rules="rules.required"
                                    class=""
                                    clearable 
                                    @click:clear="clearItem('taskDescription')">
                                </v-textarea> 
                            </v-col>
                        </v-row>
                        <v-row dense class="">
                            <v-col cols="6" xs="6">
                                <v-select 
                                    class="" 
                                    hide-details="auto" 
                                    label="Assigned Admin" 
                                    filled 
                                    v-model="task.taskUserId"  
                                    outlined
                                    dense
                                    :items="managers" 
                                    background-color="white" 
                                    :rules="rules.required"
                                    color="primary"
                                    :menu-props="{ top: false, offsetY: true }"
                                    item-text="userName"
                                    item-value="entityId"
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="6" xs="6">
                                <v-select 
                                    class="" 
                                    hide-details="auto" 
                                    label="Priority" 
                                    filled 
                                    v-model="task.taskPriority"  
                                    outlined
                                    dense
                                    :items="priorities" 
                                    :rules="rules.required"
                                    :menu-props="{ top: false, offsetY: true }" 
                                    background-color="white" 
                                    color="primary"
                                    item-text="userName"
                                    item-value="entityId"
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="6" xs="6">
                                <v-select 
                                    class="" 
                                    hide-details="auto" 
                                    label="Category" 
                                    filled 
                                    v-model="task.taskCategory"  
                                    outlined
                                    dense
                                    :items="categories" 
                                    background-color="white" 
                                    :rules="rules.required"
                                    color="primary"
                                    :menu-props="{ top: false, offsetY: true }"
                                    item-text="userName"
                                    item-value="entityId"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row dense class="pt-2 align-start">
                            <v-col cols="12" class="py-0 d-flex font-weight-bold grey--text text-h6">
                                Schedule Details
                            </v-col>
                            <v-col cols="12" class="d-flex flex-column align-start justify-center">

                                <div class="d-flex align-center">
                                    <div class="">
                                        <v-text-field 
                                            label="" 
                                            v-model="task.taskDays"
                                            type="number" 
                                            :oninput="task.taskDays < 0 ? task.taskDays = 0 : ''"
                                            outlined 
                                            dense 
                                            style="width: 50px !important"
                                            hide-details="auto" 
                                            background-color="white" 
                                            :rules="rules.required"
                                            color="primary" 
                                            >
                                        </v-text-field>
                                    </div>
                                    <div class="grey--text px-2 font-weight-bold">Days</div>
                                    <div class="d-flex">
                                        <v-select 
                                            class="" 
                                            hide-details="auto" 
                                            label="" 
                                            filled 
                                            v-model="task.taskWhen"  
                                            outlined
                                            dense
                                            :items="when" 
                                            background-color="white" 
                                            :rules="rules.required"
                                            color="primary"
                                            :menu-props="{ top: false, offsetY: true }"
                                            item-text="userName"
                                            item-value="entityId"
                                        >
                                        </v-select>
                                    </div>
                                </div>

                                <div class="caption grey--text font-weight-bold">When would you like the Task to be added for the Allocated User?</div>
                                <!-- <div v-if="showInfo" class="font-weight-bold caption grey--text">This is the number of days after the Task Template is activated that this Task will need to be Completed.</div> -->
                            </v-col>
                            <v-col cols="12" class="d-flex align-center pt-4">
                                <div class="">
                                    <v-text-field 
                                        label="Due Date" 
                                        v-model="task.taskDueNumber"
                                        type="number" 
                                        :oninput="task.taskDueNumber < 0 ? task.taskDueNumber = 0 : ''"
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        :rules="rules.required"
                                        color="primary" 
                                        >
                                    </v-text-field>
                                    <div class="caption grey--text font-weight-bold">How long (number of days) after the Task is added does it need to be completed?</div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row dense class="">
                            <v-col cols="12" class="py-0 d-flex font-weight-bold grey--text text-h6">
                                Notes
                            </v-col>
                            <v-col cols="12">
                                <VueEditor id="simpleEditor" style="max-height: 370px; min-height: 200px; overflow-y: scroll" v-model="task.taskNotesHTML" :editorToolbar="customToolbar" class="white"></VueEditor>
                            </v-col>
                        </v-row>
                    </v-form>
                    <!-- <pre> {{ task }} </pre> -->
                </div>
                <div class="d-flex grey lighten-1 pa-4">
                    <v-btn text class="primary white--text" @click="taskDialog = false" >Close</v-btn>
                    <v-spacer />
                    <!-- <app-button @click.native="saveItem" type="primary" label="Submit" /> -->
                    <v-btn text class="secondary white--text" @click="validateItem" >Save</v-btn>
                </div>

            </v-card>
        </v-dialog>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
import { mapGetters } from "vuex";
import schema from '@/schema.js';
import { VueEditor } from 'vue2-editor';
import TaskList from "@/views/taskTemplates/taskTemplateTaskList.vue";

export default {
	// * COMPONENTS
	components: {
        VueEditor,
		"TaskList": TaskList,
	},
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
    },
    // * DATA
    data: () => ({
        key: 'taskTemplate',
        itemNew: true,
        schema: {},
        taskSchema: {},
        org: {},
        editmode: false,
        
        tasks: [
            {
                taskTitle: '2 days before',
                taskDescription: 'srj rsrtjsrjy',
                taskDaysOut: 2,
                taskType: ''
            }, {
                taskTitle: '1 day before',
                taskDescription: 'srt swrswrjuw 6u',
                taskDaysOut: 1,
                taskType: ''
            }, {
                taskTitle: '10 days before',
                taskDescription: '46uastrjajr wjt',
                taskDaysOut: 10,
                taskType: ''
            }, {
                taskTitle: '30 days before',
                taskDescription: 'aet35u aq3ujyrjsa',
                taskDaysOut: 30,
                taskType: ''
            }, {
                taskTitle: '15 days before',
                taskDescription: 'aq5tu ws4juw46u aq3t',
                taskDaysOut: 15,
                taskType: ''
            }
        ],

        customToolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'align': ''},{ 'align': 'center'},{ 'align': 'justify'}, { 'align': 'right' }],
            [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],  
            // [{ 'color': [] }, { 'background': [] }],
            ['link'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            // [{ 'indent': '-1'}, { 'indent': '+1' }],
        ],

        taskDialog: false, 
        taskDialogKey: 0,
        task: {},

        managers: [],
        showInfo: false,
        categories: ['Email', 'Call', 'Appointment', 'Notes', 'Follow up','Document', 'Diary'],
        when: ['Before Start Date', 'After Start Date'],

        // new address object
        item: {},
        loading: false,
        rules: {},

        priorities: ['Urgent', 'High', 'Medium', 'Low'],

        refreshItems: 0,

    }),
    computed: {  
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
        computedTasks() {
            let t = this;
            let tasks = [];

            tasks = t.tasks

            tasks.sort((a, b) => b.taskDaysOut - a.taskDaysOut)
            // if (t.item.tasks !== undefined) {
            //     tasks = t.item.tasks;
            // }
            return tasks;
        },
    },
    methods: {
        openItem(item) {
            // console.log('item = ' + JSON.stringify(item, null, 2))
            this.itemNew = false
            this.task = item
            this.taskDialog = true
        },
        initialise() {
            this.item = { ...this.datadown };
            // console.log('item = ' + JSON.stringify(this.item, null, 2));
            // let iconResult = this.icons.find(icon => icon.icon === this.item.packageIcon);
            // if (iconResult !== undefined) {
            //     this.selectedPackageIcon = iconResult;
            // }
            if (this.item.taskTemplateTitle !== '') {
                this.itemNew = false;
                this.editmode = false;
            } else {
                this.itemNew = true;
                this.editmode = true;
            }
        },
        initialiseSchema() {
            var t = this; 
            t.task = {...t.taskSchema}
            t.task.taskTemplateId = t.item.entityId;
        
        },
        updateItem() {
            this.$emit('update', this.item);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        // async saveItem() {
        //     this.$emit('save', this.item);
        //     this.editmode = false;
        // },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
		validateItem() {
			let t = this;
			t.rules = {
				// GENERAL DETAILS
				required: [(v) => !!v || "Required"],
			};
			t.$nextTick(() => {
				if (t.$refs.form.validate()) {
                    if (this.itemNew) {
                        this.saveItem();
                    } else {
                        this.updateItem();
                    }
					this.editMode = false;
				} else {
					this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
				}
			});
		},
        // * CREATE ITEM
        async updateItem() {
			let t = this;
            var item = {...t.task}


            var dateTimeNow = t.$moment().toISOString()
            item.modifiedDateTime = dateTimeNow;
            item.modifiedUserId = t.GET_FIREBASE_currentUser.entityId;
            
            let result = await this.REDIS_update('template-task', item.entityId, item);
            
			if (result.success) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Updated", show: true });
				t.$emit("refreshitems");
                t.taskDialog = false
                t.initialiseSchema()
                t.refreshItems++
			}
        },
        // * CREATE ITEM
        async saveItem() {
            var t = this;

            var dateTimeNow = t.$moment().toISOString();
            // console.log('dateTimeNow = ' + JSON.stringify(dateTimeNow, null, 2))

            var item = {...t.task}

            item.createdDateTime = dateTimeNow;
            item.createdUserId = t.GET_FIREBASE_currentUser.entityId;

            // console.log('item = ' + JSON.stringify(item, null, 2))
            
            let result = await this.REDIS_create('template-task', item);
            
			if (result.success) {
                // console.log('result = ' + JSON.stringify(result, null, 2))
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                t.taskDialog = false
                t.initialiseSchema()
                t.refreshItems++
			}

        },
        addNewCommunication() {
            var t = this; 
            t.itemNew = true
            t.taskDialog = true 
            t.taskDialogKey++
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        taskDialog: {
            handler: function () {
                if (this.taskDialog == false) {
                    this.taskDialogKey++
                    this.initialiseSchema()
                }
                if (this.itemNew) {
                    this.initialiseSchema()
                }
            }, deep: true
        },

    },
    // * CREATED
    async created() {
        let t = this;

        let managers = await t.REDIS_searchFor('user', '0', '999', 'userName', 'ASC', `@deleted:{false} @userLevel:{SA} -@userStatus:{ARCHIVED}`);
        if (managers.success) { t.managers = managers.data.documents; }

        t.managers = t.managers.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
            };
        })

        t.schema = schema.taskTemplate;
        t.taskSchema = schema.templateTask;
        t.loading = true
        t.initialise();
        t.initialiseSchema()
        t.loading = false
    },
    mounted() {},
};
</script>