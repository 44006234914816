<template>
    <div class="" style="width: 100% !important">
        <div style="width: 100% !important" class="d-flex flex-row align-center">
            
            <div style="width: 100% !important" class="d-flex flex-column ">
                <div class="d-flex align-start">
                    <div class="flex-grow-1">
                        <v-select 
                            class="" 
                            hide-details="auto" 
                            label="Service" 
                            filled 
                            item-value="entityId" 
                            v-model="filter.service"  
                            outlined
                            dense
                            clearable
                            :items="services" 
                            background-color="white" 
                            color="primary"
                            :menu-props="{ top: false, offsetY: true }" 
                            :attach="true"
                            item-text="serviceName" 
                        ></v-select>
                    </div>
                    <div class="flex-grow-1 ml-2">
                        <v-select 
                            class="" 
                            hide-details="auto" 
                            label="Clients" 
                            filled 
                            item-value="entityId" 
                            v-model="filter.client"  
                            outlined
                            dense
                            clearable
                            :items="clients" 
                            background-color="white" 
                            color="primary"
                            :menu-props="{ top: false, offsetY: true }" 
                            :attach="true"
                            item-text="clientName" 
                        ></v-select>
                    </div>
                    <div class="flex-grow-1 ml-2">
                        <v-select 
                            class="" 
                            hide-details="auto" 
                            label="Sites" 
                            filled 
                            v-model="filter.site"  
                            outlined
                            dense
                            clearable
                            :items="sites" 
                            background-color="white" 
                            color="primary"
                            :menu-props="{ top: false, offsetY: true }" 
                            :attach="true"
                            item-text="siteName" 
                            item-value="entityId" 
                        ></v-select>
                    </div>
                </div>
                <div class="pt-2 d-flex align-start">
                    <div class="flex-grow-1">
                        <v-select 
                            class="" 
                            hide-details="auto" 
                            label="User Category" 
                            filled 
                            v-model="filter.userCategory"  
                            outlined
                            dense
                            clearable
                            :items="userCategories" 
                            background-color="white" 
                            color="primary"
                            :menu-props="{ top: false, offsetY: true }" 
                            :attach="true"
                        ></v-select>
                    </div>
                    <div class="flex-grow-1 ml-2">
                        <v-select 
                            class="" 
                            hide-details="auto" 
                            label="Staff Member" 
                            filled 
                            v-model="filter.staffMember"  
                            outlined
                            item-value="entityId" 
                            dense
                            clearable
                            :items="computedUsers" 
                            background-color="white" 
                            color="primary"
                            :menu-props="{ top: false, offsetY: true }" 
                            :attach="true"
                            item-text="userName" 
                        ></v-select>
                    </div>
                    <div class="flex-grow-1 ml-2">
                        <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field label="Jobs From" v-model="computedDate1" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearFilter('jobsFrom')"></v-text-field>
                            </template>
                            <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                        </v-menu>
                    </div>
                    <div class="flex-grow-1 ml-2">
                        <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field label="Jobs To" v-model="computedDate2" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearFilter('jobsTo')"></v-text-field>
                            </template>
                            <v-date-picker v-model="date2" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu2 = false"></v-date-picker>
                        </v-menu>
                    </div>
                    <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="refreshItems">
                        <v-icon>icons8-available-updates</v-icon>
                    </v-btn>
                    <div class="ml-2">
                        <v-menu
                            offset-y
                            class="white"
                            style="z-index: 99999 !important; width: 200px !important; height: 200px !important"
                            :close-on-content-click="false"
                            origin="top right"
                            left
                            transition="scale-transition"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"
                                    ><v-icon>icons8-table-properties</v-icon></v-btn
                                >
                            </template>
                            <div class="white" style="width: 250px; max-height: 300px">
                                <div class="pa-5 white" style="width: 100% !important">
                                    <div class="body-2 font-weight-bold">Show / hide Columns</div>
                                    <v-checkbox
                                        v-model="headers[index].hidden"
                                        dense
                                        hide-details="auto"
                                        v-for="(item, index) in headers"
                                        :key="index"
                                        :label="item.text"
                                        :true-value="false"
                                        :false-value="true"
                                    ></v-checkbox>
                                </div>
                            </div>
                        </v-menu>
                    </div>
                </div>
            </div>
        </div>


        <div class="mt-3 d-flex align-end">
            <div v-for="(report, index) in reportArray" :key="index" class="">
                <div class="d-flex flex-row pr-2">
                    <v-btn class="elevation-0" :color="report == 'Full Report' ? 'primary' : 'secondary'" medium @click="openDialog(report)">
                        <span class="white--text">{{ report }}</span>
                    </v-btn>
                </div>
            </div>


            <!-- <div  class="">
                <div class="d-flex flex-row pr-2">
                    <v-btn class="elevation-0" color="primary" medium @click="fullReportDialog = true">
                        <span class="white--text">FULL REPORT</span>
                    </v-btn>
                </div>
            </div>
            <div  class="">
                <div class="d-flex flex-row pr-2">
                    <v-btn class="elevation-0" color="secondary" medium @click="clientHoursDialog = true">
                        <span class="white--text">CLIENT HOURS</span>
                    </v-btn>
                </div>
            </div>
            <div class="">
                <div class="d-flex flex-row pr-2">
                    <v-btn class="elevation-0" color="secondary" medium @click="siteHoursDialog = true">
                        <span class="white--text">SITE HOURS</span>
                    </v-btn>
                </div>
            </div>
            <div class="">
                <div class="d-flex flex-row pr-2">
                    <v-btn value="add" class="elevation-0" color="secondary" medium @click="userHoursDialog = true">
                        <span class="white--text">USER HOURS</span>
                    </v-btn>
                </div>
            </div>
            <div class="">
                <div class="d-flex flex-row pr-2">
                    <v-btn value="add" class="elevation-0" color="secondary" medium @click.native="userAbsenceDialog = true">
                        <span class="white--text">ABSENCE</span>
                    </v-btn>
                </div>
            </div>
            <div class="">
                <div class="d-flex flex-row pr-2">
                    <v-btn value="add" class="elevation-0" color="secondary" medium @click.native="userTrainingDialog = true">
                        <span class="white--text">TRAINING</span>
                    </v-btn>
                </div>
            </div>
            <div class="">
                <div class="d-flex flex-row pr-2">
                    <v-btn value="add" class="elevation-0" color="secondary" medium @click.native="userLeaveDialog = true">
                        <span class="white--text">LEAVE</span>
                    </v-btn>
                </div>
            </div> -->

            <v-spacer />

            <div>
                <div class="caption font-weight-bold grey--text text--darken-1">ALLOCATED STATUS</div>
                <v-btn-toggle v-model="filter.allocatedStatus" multiple color="white" class="mr-1" active-class="white" background-color="lightgrey" dense>
                    <v-btn value="ALLOCATED" :class="filter.allocatedStatus.includes('ALLOCATED') ? 'success white--text' : 'success--text'" small>
                        <v-icon :class="filter.allocatedStatus.includes('ALLOCATED') ? 'white--text' : 'success--text'" class="text-h5 icons8-filled-circle"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">ALLOCATED</span>
                    </v-btn>
                    <v-btn value="UNALLOCATED" :class="filter.allocatedStatus.includes('UNALLOCATED') ? 'grey white--text' : 'grey--text'" small>
                        <v-icon :class="filter.allocatedStatus.includes('UNALLOCATED') ? 'white--text' : 'grey--text'" class="text-h5 icons8-filled-circle"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">UNALLOCATED</span>
                    </v-btn>
                </v-btn-toggle>
            </div>
            <div>
                <div class="caption font-weight-bold grey--text text--darken-1">PUBLISHED STATUS</div>
                <v-btn-toggle v-model="filter.publishedStatus" multiple color="white" class="" active-class="white" background-color="lightgrey" dense>
                    <!-- <v-btn value="PUBLISHED" small>
                        <v-icon class="text-h5 success--text icons8-checkmark-yes"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption success--text">PUBLISHED</span>
                    </v-btn> -->
                    <v-btn value="PUBLISHED" :class="filter.publishedStatus.includes('PUBLISHED') ? 'success white--text' : 'success--text'" small>
                        <v-icon :class="filter.publishedStatus.includes('PUBLISHED') ? 'white--text' : 'success--text'" class="text-h5 icons8-checkmark-yes"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">PUBLISHED</span>
                    </v-btn>
                    <v-btn value="UNPUBLISHED" :class="filter.publishedStatus.includes('UNPUBLISHED') ? 'grey white--text' : 'grey--text'" small>
                        <v-icon :class="filter.publishedStatus.includes('UNPUBLISHED') ? 'white--text' : 'grey--text'" class="text-h5 icons8-checkmark-yes"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">UNPUBLISHED</span>
                    </v-btn>
                    <v-btn value="CANCELLED" :class="filter.publishedStatus.includes('CANCELLED') ? 'error white--text' : 'error--text'" small>
                        <v-icon :class="filter.publishedStatus.includes('CANCELLED') ? 'white--text' : 'error--text'" class="text-h5 icons8-filled-circle"></v-icon>
                        <span v-if="!listshrunk && listexpanded && $vuetify.breakpoint.xlOnly" class="caption">CANCELLED</span>
                    </v-btn>
                </v-btn-toggle>
            </div>
        </div>


        <v-row class="pa-3 mt-0">
            <v-data-table
                :item-class="itemRowBackground"
                sortable
                dense
                class=""
                style="width: 100%"
                :headers="computedHeaders"
                :items="computedItems"
                :server-items-length="itemsTotal"
                :options.sync="datatableOptions"
                :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50]
                }"
            >
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                    </div>
                </template>
                <template v-slot:[`item.jobClientId`]="{ item }">
                    <div>{{ getClient(item.jobClientId)}}</div>
                </template>
                <template v-slot:[`item.jobServiceId`]="{ item }">
                    <div>{{ getService(item.jobServiceId)}}</div>
                </template>
                <template v-slot:[`item.jobAssignedUserId`]="{ item }">
                    <div>{{ getUser(item.jobAssignedUserId)}}</div>
                </template>
                <template v-slot:[`item.jobSiteId`]="{ item }">
                    <div>{{ getSite(item.jobSiteId)}}</div>
                </template>
                <template v-slot:[`item.jobStartDateTime`]="{ item }">
                    <div>{{ $moment(item.jobStartDateTime).format('DD-MMM-YYYY HH:mm')}}</div>
                </template>
                <template v-slot:[`item.jobEndDateTime`]="{ item }">
                    <div>{{ $moment(item.jobEndDateTime).format('DD-MMM-YYYY HH:mm')}}</div>
                </template>
                <template v-slot:[`item.jobAssignedStatus`]="{ item }">
                    <v-icon 
                    class="pa-1" 
                    :class="
                        item.jobAssignedStatus == 'ALLOCATED' ? 'success--text' : 
                        item.jobAssignedStatus == 'UNALLOCATED' ? 'grey--text' : 
                        item.jobAssignedStatus == 'CANCELLED' ? 'error--text' : '' 
                    ">
                        icons8-filled-circle
                    </v-icon>
                </template>
                <template v-slot:[`item.jobPublishedStatus`]="{ item }">
                    <v-icon 
                    class="pa-1" 
                    :class="
                        item.jobPublishedStatus == 'PUBLISHED' ? 'success--text' : 
                        item.jobPublishedStatus == 'UNPUBLISHED' ? 'grey--text' : 
                        item.jobPublishedStatus == 'CANCELLED' ? 'error--text' : '' 
                    ">
                        icons8-checkmark-yes
                    </v-icon> 
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-icon class="error--text pa-1" v-if="item.deleted === '1' && toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">icons8-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">icons8-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">icons8-trash-restore</v-icon>
                    <v-icon class="primary--text ml-5" @click="openItem(item)">icons8-forward</v-icon>
                </template>
            </v-data-table>
        </v-row>
        
        <v-dialog persistent v-model="reportDialog" width="600px" height="400px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 secondary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mr-3 mb-1">
                        <v-icon class="white--text">icons8-future</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Export {{ reportType }} Report</strong>
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="reportDialog = !reportDialog" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 pa-0">               
                    
                    <v-row v-if="!exporting" class="pa-0 ma-0 justify-center d-flex" fill-height>
                        <v-col cols="6" class="align-center pr-2">
                            <v-menu v-model="dateMenu3" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field label="Date From" v-model="computedDate3" :rules="[(v) => $moment(computedDate3).isBefore(computedDate4) || 'Date From must be before Date To']" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('contentAvailableFrom')"></v-text-field>
                                </template>
                                <v-date-picker v-model="date3" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu3 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="6" class="align-center pl-2">
                            <v-menu v-model="dateMenu4" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field label="Date To" v-model="computedDate4" :rules="[(v) => $moment(computedDate3).isBefore(computedDate4) || 'Date To must be after Date From']" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('contentAvailableFrom')"></v-text-field>
                                </template>
                                <v-date-picker v-model="date4" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu4 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="pa-0" fill-height cols="12" style="width: 100% !important">
                            <div v-if="reportType == 'User Hours'" class="font-weight-bold body-2 pa-3 text-h7 grey--text">
                                This will create a report compiling all Allocated User Hours for the selected Date Range, compared to the Contracted Hours of each User
                            </div>
                            <div v-else-if="reportType == 'Full Report'" class="pa-0 d-flex flex-column justify-center align-center" fill-height cols="12" style="width: 100% !important">
                                <div class="font-weight-bold body-2 pa-3 text-h7 grey--text">
                                    This will create a report compiling all Jobs that match the filters selected on the previous screen.
                                </div>
                                <div class="font-weight-bold body-2 pa-3 text-h7 grey--text">
                                    This will be sent to the below email address, if this is incorrect, please change the email linked to the currently logged in user.
                                </div>
                                <div class="font-weight-bold body-1 pa-3 text-h7 grey--text text--darken-2">
                                    {{ GET_FIREBASE_currentUser.userEmail }}
                                </div>
                            </div>
                            <div v-else-if="reportType == 'Leave'" class="font-weight-bold body-2 pa-3 text-h7 grey--text">
                                This will create a report compiling all Leave during the selected Date Range
                            </div>
                            <div v-else-if="reportType == 'User Training'" class="font-weight-bold body-2 pa-3 text-h7 grey--text">
                                This will create a report compiling all Training due to expire during the selected Date Range
                            </div>
                            <div v-else-if="reportType == 'Absence'" class="font-weight-bold body-2 pa-3 text-h7 grey--text">
                                This will create a report compiling all Absences for the selected Date Range
                            </div>
                            <div v-else-if="reportType == 'Client Hours'" class="font-weight-bold body-2 pa-3 text-h7 grey--text">
                                This will create a report compiling all Allocated Client Hours for the selected Date Range, compared to the Contracted Hours of each Client
                            </div>
                            <div v-else-if="reportType == 'Site Hours'" class="font-weight-bold body-2 pa-3 text-h7 grey--text">
                                This will create a report compiling all Allocated Site Hours for the selected Date Range, compared to the Contracted Hours of each Site
                            </div>

                        </v-col>
                    </v-row>
                    <v-row v-else-if="exporting" class="pa-0 ma-0 pb-4 justify-center align-center d-flex flex-column" fill-height>
                        <div class="font-weight-bold body-2 pa-3 text-h7 grey--text">
                            Exporting {{ reportType }} Report, please wait
                        </div>
                        <v-progress-circular
                            indeterminate
                            color="primary"
                        ></v-progress-circular>
                    </v-row>

                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-0">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn :disabled="exporting" class="grey darken-1 white--text font-weight-bold" depressed @click="reportDialog = !reportDialog"><span>Cancel</span> </v-btn>
                            <v-btn :disabled="exporting" class="primary white--text font-weight-bold ml-2" depressed @click="exportReport()"><span>Export</span> </v-btn>
                        </v-col>
                    </v-col>
                </v-card-actions>
            </div>
        </v-dialog> 
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import moment from 'moment'
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        type: '',
        status: '',
        key: 'course',
        index: 'idx:courses',
        service: {},
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,


        jobsSelected: [{}, {}],

        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'Assigned Status', align: 'center', value: 'jobAssignedStatus', sortable: false, hidden: false, expanded: false },
            { text: 'Published Status', align: 'center', value: 'jobPublishedStatus', sortable: false, hidden: false, expanded: false },
            { text: 'Client', value: 'jobClientId', sortable: false, hidden: false, expanded: false },
            { text: 'Site', value: 'jobSiteId', sortable: false, hidden: false, expanded: false },
            { text: 'Service', value: 'jobServiceId', sortable: false, hidden: false, expanded: false },
            { text: 'Assigned User', value: 'jobAssignedUserId', sortable: false, hidden: false, expanded: false },
            { text: 'Start Date Time', value: 'jobStartDateTime', sortable: false, hidden: false, expanded: false },
            { text: 'End Date Time', value: 'jobEndDateTime', sortable: false, hidden: false, expanded: false },
            { text: 'Job Type', value: 'jobType', sortable: false, hidden: true, expanded: false },
            { text: 'Recurring Rule ID', value: 'jobRecurringRuleId', sortable: false, hidden: true, expanded: false },
            { text: 'Job Duration', value: 'jobDuration', sortable: false, hidden: true, expanded: false },


            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false },
        ],
        
        // Fields to Retrieve from Database
        sortBy: '@courseName',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['courseName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        filter: {
            staffMember: '',
            userCategory: '',
            status: '',
            site: '',
            service: '',
            client: '',
            publishedStatus: [],
            allocatedStatus: [],
            jobsFrom: '',
            jobsTo: ''
        },

        userCategories: [ 'Mobile Cleaners', 'Director', 'Commercial Manager', 'Office Staff', 'Security Staff', 'Cleaner', 'Supervisor', 'Senior Manager' ],
        users: [],
        services: [],
        sites: [],
        clients: [],


        reportArray: ['Full Report', 'Client Hours', 'Site Hours', 'User Hours', 'User Training', 'Absence', 'Leave'],
        reportDialog: false,
        reportType: '',
        exporting: false,

        date1: '',
        dateMenu1: false,
        date2: '',
        dateMenu2: false,
        date3: moment().startOf('month').format('YYYY-MM-DD'),
        dateMenu3: false,
        date4: moment().endOf('month').format('YYYY-MM-DD'),
        dateMenu4: false,

        clientHoursDialog: false,
        exportingClientHours: false,

        userLeaveDialog: false,
        exportingLeaveTraining: false,

        userAbsenceDialog: false,
        exportingAbsenceHours: false,

        userTrainingDialog: false,
        exportingUserTraining: false,

        fullReportDialog: false,
        exportingFullReport: false,

        siteHoursDialog: false,
        exportingSiteHours: false,

        userHoursDialog: false,
        exportingUserHours: false,
    }),
    computed: {
	    ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
	    }),
        computedDate1() {
            return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YYYY");
        },

        computedDate2() {
            return this.MIX_formatDateTime(this.date2, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
        
        computedDate3() {
            return this.MIX_formatDateTime(this.date3, "YYYY-MM-DD", "DD-MMM-YYYY");
        },

        computedDate4() {
            return this.MIX_formatDateTime(this.date4, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '';
                } else {
                    searchQuery = '@deleted:{false}';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}`;
                    } else {
                        searchQuery = `${searchQuery}  @deleted:{false}`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}`;
                    } else {
                        searchQuery = `${searchQuery}  @deleted:{false}`;
                    }
                }
            }

            if (this.filter.service !== "" && this.filter.service !== null && this.filter.service !== undefined) {
                searchQuery = `${searchQuery} @jobServiceId:{${this.filter.service}}`
            }

            if (JSON.stringify(this.filter.publishedStatus) !== "[]") {
                //console.log('published status = ' + JSON.stringify(this.filter.publishedStatus, null, 2))
                searchQuery = `${searchQuery} @jobPublishedStatus:{${this.filter.publishedStatus.join("|")}}`
            }

            if (JSON.stringify(this.filter.allocatedStatus) !== "[]") {
                searchQuery = `${searchQuery} @jobAssignedStatus:{${this.filter.allocatedStatus.join("|")}}`
            }

            if (this.filter.userCategory !== "" && this.filter.userCategory !== null && this.filter.userCategory !== undefined) {
                var users = []
                this.computedUsers.forEach((item) => {
                    users.push(item.entityId)
                })
                //console.log('users = ' + JSON.stringify(users, null, 2))
                searchQuery = `${searchQuery} @jobAssignedUserId:{${users.join("|")}}`
            }

            if (this.filter.client !== "" && this.filter.client !== null && this.filter.client !== undefined) {
                searchQuery = `${searchQuery} @jobClientId:{${this.filter.client}}`
            }

            if (this.filter.site !== "" && this.filter.site !== null && this.filter.site !== undefined) {
                searchQuery = `${searchQuery} @jobSiteId:{${this.filter.site}}`
            }
            if (this.filter.staffMember !== "" && this.filter.staffMember !== null && this.filter.staffMember !== undefined) {
                searchQuery = `${searchQuery} @jobAssignedUserId:{${this.filter.staffMember}}`
            }
            if (this.filter.jobsFrom !== "" && this.filter.jobsFrom !== null && this.filter.jobsFrom !== undefined) {
                searchQuery = `${searchQuery} @jobStartDateTime:[${this.filter.jobsFrom} +inf]`
            }
            if (this.filter.jobsTo !== "" && this.filter.jobsTo !== null && this.filter.jobsTo !== undefined && this.filter.jobsTo !== 'Invalid date') {
                searchQuery = `${searchQuery} @jobStartDateTime:[-inf ${this.filter.jobsTo}]`
            }
            console.log(searchQuery);

            return searchQuery;
        },
        computedUsers() {
            var t = this;
            var users = t.users
            var userCategory = ''
            if (this.filter.userCategory !== "" && this.filter.userCategory !== null && this.filter.userCategory !== undefined) {
                userCategory = this.filter.userCategory.replace(/\s/g, '').toUpperCase()
                //console.log('userCategory = ' + userCategory)
                users = users.filter(function(item) {
                    return item.userCategory == userCategory
                })
            }
            return users 
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            // if (this.listshrunk) {
            //     headers = headers.filter((header) => header.expanded === false || header.expanded === true); // Return Headers based on Expanded State
            // } else {
            //     headers = headers.filter((header) => header.expanded === false); // Return Headers based on Expanded State
            // }
            return headers;
        },
        computedItems() {
            var items = this.items;
            if (this.type) {
                items = this.items.filter((item) => item.actionType === this.type);
            }
            if (this.status) {
                items = this.items.filter((item) => item.actionStatus === this.status);
            }
            return items;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            // if (!computedFields.includes('@actionId')) {
            //     computedFields.push('@actionId');
            // }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedType() {
            const types = [];
            this.items.filter((item) => {
                return types.push(item.actionType);
            });
            return types;
        },
        computedStatus() {
            const status = [];
            this.items.filter((item) => {
                return status.push(item.actionStatus);
            });
            return status;
        },
    },
    methods: {
        openDialog(item) {
            this.reportType = item
            this.reportDialog = true
        },
        exportReport() {
            var t = this;
            t.exporting = true
            
            switch (t.reportType) {
                case 'Client Hours':
                    t.exportClientHours()
                    break;
                case 'Leave':
                    t.exportLeaveReport()
                    break;
                case 'Absence':
                    t.exportAbsences()
                    break;
                case 'User Training':
                    t.exportUserTraining()
                    break;
                case 'Site Hours':
                    t.exportSiteHours()
                    break;
                case 'User Hours':
                    t.exportUserHours()
                    break;
                case 'Full Report':
                    t.exportFullReport()
                    break;
                default:
                    break;
            }
        },
        async exportFullReport() {
            var t = this; 
            
            var reportData = {}
            reportData.searchQuery = t.computedSearchQuery
            reportData.emailedUserId = t.GET_FIREBASE_currentUser.entityId

            let data = await this.REDIS_getFullReport(reportData)
            if (data.success) {
                t.reportDialog = false
                t.exporting = false
            }

        },
        async exportLeaveReport() {
            var t = this;
            
            var date1 = this.$moment(this.date3, 'YYYY-MM-DD').format('X')
            var date2 = this.$moment(this.date4, 'YYYY-MM-DD').endOf('day').format('X')

            let data = await this.REDIS_getLeaveReport(date1, date2)
            if (data.success) {

                let csv = t.$papa.unparse(data.data)
                t.$papa.download(csv, 'Leave Report' + '-' + t.computedDate3 + '-' + t.computedDate4)
                t.reportDialog = false
                t.exporting = false
            }
        },
        async exportClientHours() {
            var t = this;
            
            var date1 = this.$moment(this.date3, 'YYYY-MM-DD').format('X')
            var date2 = this.$moment(this.date4, 'YYYY-MM-DD').endOf('day').format('X')

            let data = await this.REDIS_getClientHours(date1, date2)
            if (data.success) {

                let csv = t.$papa.unparse(data.data)
                t.$papa.download(csv, 'Client Hours Report' + '-' + t.computedDate3 + '-' + t.computedDate4)
                t.reportDialog = false
                t.exporting = false
            }
        },
        async exportUserTraining() {
            var t = this;       

            var date1 = this.$moment(this.date3, 'YYYY-MM-DD').format('X')
            var date2 = this.$moment(this.date4, 'YYYY-MM-DD').endOf('day').format('X')

            let data = await this.REDIS_getUserTraining(date1, date2)
            if (data.success) {
                let reportData = data.data

                reportData.forEach(function(item) {
                    var manager = item['Commercial Manager']
                    
                    manager = t.getUser(manager)
                    if (item['Training Status'] == 'Total') {
                        item['Name'] = ''
                    } else {
                        item['Commercial Manager'] = manager
                    }
                })

                let csv = t.$papa.unparse(reportData)
                t.$papa.download(csv, 'User Training Report.csv')
                t.reportDialog = false
                t.exporting = false
            }
        },
        async exportAbsences() {
            var t = this;
            
            var date1 = this.$moment(this.date3, 'YYYY-MM-DD').format('X')
            var date2 = this.$moment(this.date4, 'YYYY-MM-DD').endOf('day').format('X')

            let data = await this.REDIS_getAbsenceReport(date1, date2)
            if (data.success) {

                let csv = t.$papa.unparse(data.data)
                t.$papa.download(csv, 'Absence Report' + '-' + t.computedDate3 + '-' + t.computedDate4)
                t.reportDialog = false
                t.exporting = false
            }
        },

        async exportSiteHours() {
            var t = this;
            
            var date1 = this.$moment(this.date3, 'YYYY-MM-DD').format('X')
            var date2 = this.$moment(this.date4, 'YYYY-MM-DD').endOf('day').format('X')

            let data = await this.REDIS_getSiteHours(date1, date2)
            if (data.success) {

                let csv = t.$papa.unparse(data.data)
                t.$papa.download(csv, 'Site Hours Report' + '-' + t.computedDate3 + '-' + t.computedDate4)
                t.reportDialog = false
                t.exporting = false
            }
        },

        async exportUserHours() {
            var t = this;
            
            var date1 = this.$moment(this.date3, 'YYYY-MM-DD').format('X')
            var date2 = this.$moment(this.date4, 'YYYY-MM-DD').endOf('day').format('X')

            let data = await this.REDIS_getUserHours(date1, date2)
            if (data.success) {

                let csv = t.$papa.unparse(data.data)
                t.$papa.download(csv, 'User Hours Report' + '-' + t.computedDate3 + '-' + t.computedDate4)
                t.reportDialog = false
                t.exporting = false
            }
        },

        getClient(clientId) {
            var t = this;
            var client = []
            var clientName = ''

            client = t.clients.filter((item) => { return item.entityId == clientId })

            if (client.length !== 0) { clientName = client[0].clientName }
            return clientName
        },
        getUser(userId) {
            var t = this;
            var user = []
            var userName = ''

            // console.log('getUser = ' + userId)

            user = t.users.filter((item) => { return item.entityId == userId })

            if (user.length !== 0) { userName = user[0].userName }
            else { userName = 'Not found or Deleted' }

            return userName
        },
        getSite(siteId) {
            var t = this;
            var site = []
            var siteName = ''

            site = t.sites.filter((item) => { return item.entityId == siteId })

            if (site.length !== 0) { siteName = site[0].siteName }

            return siteName
        },
        getService(serviceId) {
            var t = this;
            var service = []
            var serviceName = ''

            service = t.services.filter((item) => { return item.entityId == serviceId })

            if (service.length !== 0) { serviceName = service[0].serviceName }

            return serviceName
        },
        async editItem(id) {
            // Get the item from the database
            let itemResult = await this.MIX_readItem(id, 'service');
            if (itemResult.code === 1) {
                this.$emit('edit', itemResult.data);
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Editing Component', show: true });
            }
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        refreshItems: function () {
            // this.getItems();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                // this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            // this.$emit('openItem', JSON.parse(JSON.stringify(item)));
            this.MIX_go('/job/' + item.entityId)
        },
        // * GET ITEMS
        async getItems() {
            var t = this;
            t.loading = true

            let itemsResult = await this.REDIS_searchFor('jobs', this.itemsFrom, this.datatableOptions.itemsPerPage, 'jobStartDateTime','ASC', this.computedSearchQuery )
            // //console.log('itemsResult: ' + JSON.stringify(itemsResult));
            if (itemsResult.success) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.documents;
                // t.loading = false
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                //console.log('itemsTo = ' + itemsTo)
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                //console.log('itemsTo total = ' + this.itemsTo)
            }
        },
        // Clear Item Property
        clearFilter(property) {
            setTimeout(() => {
                this.filter[property] = '';
            }, 1);
        },
        
    },
    watch: {
        dateMenu1: {
            handler: async function () {
                this.filter.jobsFrom = this.MIX_formatDateTime(this.date1, 'YYYY-MM-DD', 'X');
            },
            deep: true,
        },
        dateMenu2: {
            handler: async function () {
                console.log('dateMenu2 = ' + this.date2)
                var date = this.$moment(this.date2, 'YYYY-MM-DD').endOf('day').format('X')
                console.log('date = ' + date)
                this.filter.jobsTo = date
            },
            deep: true,
        },
        datadown: {
            handler: function () {
                this.service = { ...this.datadown };
            },
            deep: true,
        },
        refreshitems() {
            // this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        computedFields() {
            // this.getItems();
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        var t = this;

        let users = await t.REDIS_searchFor('user', '0', '999', 'userName', 'ASC', '@deleted:{false} @userStatus:{APPROVED}');
        if (users.success) { t.users = users.data.documents; }

        t.users = t.users.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
                userCategory: x.userCategory
            };
        })


        let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false} -@clientStatus:{ARCHIVE}');
        if (clients.success) { t.clients = clients.data.documents; }

        t.clients = t.clients.map((x) => {
            return {
                entityId: x.entityId,
                clientName: x.clientName,
            };
        })

        
        let services = await t.REDIS_searchFor('service', '0', '9999', 'serviceName', 'ASC', '@deleted:{false}');
        if (services.success) { t.services = services.data.documents; }

        t.services = t.services.map((x) => {
            return {
                entityId: x.entityId,
                serviceName: x.serviceName,
            };
        })

        let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
        if (sites.success) { t.sites = sites.data.documents; }

        t.sites = t.sites.map((x) => {
            return {
                entityId: x.entityId,
                siteName: x.siteName,
            };
        })
        this.getItems();
    },
};
</script>