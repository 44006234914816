<template>
    <v-row fluid fill-height class="d-flex flex-column align-start appbackground" :style="$vuetify.breakpoint.mdAndUp ? 'width: calc(100vw - 56px) !important; position: fixed; height: 100vh !important; overflow-y: scroll !important' : 'width: 100% !important; position: fixed; height: 100vh !important; overflow-y: scroll !important'" no-gutters>
    <!-- <v-container fluid fill-height class="d-flex flex-column align-start appbackground pa-0 ma-0"> -->
		
        <div v-if="exportingCSV">
            <v-overlay color="white" opacity="0.2">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-overlay>
        </div>
        <v-col v-if="!userOpen" class="align-center pa-3 mt-1 mb-12 pb-12" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">
            
			<div class="d-flex align-center mb-1">
				<div class="d-flex align-center">
					<div><v-icon class="primary--text" x-large>icons8-skyscrapers</v-icon></div>
					<div class="text-left primary--text pl-1 text-h4 font-weight-thin">Sites</div>
				</div>
				<v-spacer></v-spacer>
				<div class="ml-1">
                    <v-btn depressed block class="primary darken-2 white--text" @click="openItem({})" >
                        <v-icon>icons8-skyscrapers</v-icon>
                        <span v-if="!listShrunk" class="ml-3">New</span>
                    </v-btn>
                </div>
				<div v-if="$vuetify.breakpoint.lgAndUp">
					<v-btn @click="exportCSV" class="primary--text ml-2" depressed v-if="!listShrunk">
						<v-icon color="primary">icons8-export-csv</v-icon>
					</v-btn>
				</div>
			</div>
            
            <SitesList :listshrunk="listShrunk" :listexpanded="listExpanded" v-on:openItem="openItem" :refreshitems="refreshItems"/>
            
		
        </v-col>
		<!-- Service Component -->
		<transition v-if="activeItem" name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
			<v-col style="border-left: #239eaf solid 1px !important; height: 100% !important" v-if="!listExpanded" class="grey lighten-3 pa-0 ma-0" cols="12" :xs="itemCols.xs" :sm="itemCols.sm" :md="itemCols.md" :lg="itemCols.lg" :xl="itemCols.xl">
                <Site v-on:refreshItems="refreshitems" :refreshitems="refreshItems" :listshrunk="listShrunk"  :datadown="activeItem" v-on:closeitem="closeItem" :orgtype="orgtype"/>
			</v-col>
		</transition>

    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import Site from '@/views/sites/site.vue';
import SitesList from '@/views/sites/siteList.vue'
export default {
    name: 'Sites',
    components: {
        'Site': Site,
        'SitesList': SitesList
    },
    data: () => ({
        editMode: false,
        orgtype: "",
        orgIcon: "",
        search: '',
        show: false,
        tab: '',
        orgs: [],
        orgDialog: false,
        refreshItems: 0,
        activeItem: {},
        extendWidth: '',
        routeId: '',
		// Item Columns
		itemsCols: { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12,},
		itemCols: { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 },
		taskCols: {	cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2,},
		listExpanded: true,
		listShrunk: false,

        clients: [],
        areas: [],
        managers: [],
        exportingCSV: false,
        userOpen: false
    }),
    computed: {
        ...mapGetters({
            // GET_FIREBASE_userAuth: 'GET_FIREBASE_userAuth',
        }),
    },
    methods: {
		refreshCols() {
			switch (true) {
				// Expanded List with No Task Bar
				case this.listExpanded && !this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
					this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// Expanded List with Task Bar
				case this.listExpanded && !this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
					this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				// List with Product Page (No Task Bar)
				case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// List with Product Page and Task Bar
				case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 4
					this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				// Shrunk List with Product Page (No Task Bar)
				case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }; // 2
					this.itemCols = { cols: 12, xs: 9, sm: 9, md: 9, lg: 9, xl: 9 }; // 10
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// Shrunk List with Product Page and Task Bar)
				case !this.listExpanded && this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				default:
					this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 12
					this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
			}
		},
		openItem(item) {
			this.activeItem = item;
			this.listExpanded = false;
            if (this.$vuetify.breakpoint.smAndDown) {
                this.userOpen = true;
            }
		},
		closeItem() {
			this.listExpanded = true;
			this.listShrunk = false;
            if (this.$vuetify.breakpoint.smAndDown) {
                this.userOpen = false
            }
		},
		refreshitems() {
			this.refreshItems++
		},

        getClient(clientId) {
            var t = this;
            var client = []
            var clientName = ''

            client = t.clients.filter((item) => { return item.entityId == clientId })

            if (client.length !== 0) { clientName = client[0].clientName }
            return clientName
        },

        getArea(areaId) {
            var t = this; 

            var area = []
            var areaName = ''

            area = t.areas.filter((item) => {
                return item.entityId == areaId
            })

            if (area.length !== 0) { areaName = area[0].areaName }

            return areaName
        },

        getManager(managerId) {
            var t = this; 

            var manager = []
            var managerName = ''

            manager = t.managers.filter((item) => {
                return item.entityId == managerId
            })
            console.log('manager: ' + JSON.stringify(manager, null, 2))

            if (manager.length !== 0) { managerName = manager[0].userName }

            return managerName
        },

        async exportCSV() {
            var t = this;
            t.exportingCSV = true
            
            let itemsResult = await this.REDIS_searchFor('site', 0, 999, 'siteName','ASC', "*" )
            if (itemsResult.success) {

                // go through each item and if archived, find the archived date and add it to the item
                for (let i = 0; i < itemsResult.data.documents.length; i++) {
                    let item = itemsResult.data.documents[i];
                    if (item.deleted == true) {
                        var query = `@type:{'Archive Site'} @linkedId:{${item.entityId}} @status:{'COMPLETED'}`;
                        let archivedItem = await this.REDIS_searchFor('progress', 0, 999, 'createdDateTime', 'DESC', query);
                        if (archivedItem.success && archivedItem.data.total > 0) {
                            // console.log('archivedItem = ' + JSON.stringify(archivedItem, null, 2))
                            item.archivedDateTime = archivedItem.data.documents[0].createdDateTime;
                        } else if (item.siteClientId && item.siteClientId != '') {
                            query = `@type:{'Archive Client'} @linkedId:{${item.siteClientId}} @status:{'ARCHIVED'}`;
                            archivedItem = await this.REDIS_searchFor('progress', 0, 999, 'createdDateTime', 'DESC', query);
                            if (archivedItem.success && archivedItem.data.total > 0) {
                                // console.log('archivedItem = ' + JSON.stringify(archivedItem, null, 2))
                                item.archivedDateTime = archivedItem.data.documents[0].createdDateTime;
                            }
                        }
                    }
                }
            
                let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false} -@clientStatus:{ARCHIVE}');
                if (clients.success) { t.clients = clients.data.documents; }

                t.clients = t.clients.map((x) => {
                    return {
                        entityId: x.entityId,
                        clientName: x.clientName,
                    };

                })

                let areas = await t.REDIS_searchFor('area', '0', '9999', 'areaName', 'ASC', '@deleted:{false}');
                if (areas.success) { t.areas = areas.data.documents; }

                t.areas = t.areas.map((x) => {
                    return {
                        entityId: x.entityId,
                        areaName: x.areaName,
                    };
                })

                let managers = await t.REDIS_searchFor('user', '0', '9999', 'userName', 'ASC', `@deleted:{false}`);
                if (managers.success) { t.managers = managers.data.documents; }

                console.log('managers: ' + JSON.stringify(t.managers, null, 2))

                t.managers = t.managers.map((x) => {
                    return {
                        entityId: x.entityId,
                        userName: x.userName,
                    };
                })

                var data = itemsResult.data.documents.map(e => {
                    return {
                        'Site Name' : e.siteName,
                        'Site Sector' : e.siteSector,
                        'Site Notes' : e.siteNotes,
                        'Site Telephone' : e.siteTelephone,
                        'Site Start Date' : e.siteStartDateTime ? t.$moment(e.siteStartDateTime).format('DD-MMM-YYYY') : '',
                        'Site Area' : t.getArea(e.siteAreaId),
                        'Site Offline' : e.siteOffline,
                        'Site Client' : t.getClient(e.siteClientId),
                        'Site DBS' : e.siteDBSCheck,
                        'Site PPE' : e.sitePPERequired,
                        'Site Address Line 1' : e.siteAddressLine1,
                        'Site Address Line 2' : e.siteAddressLine2,
                        'Site Address Line 3' : e.siteAddressLine3,
                        'Site Address Town' : e.siteAddressTown,
                        'Site Address County' : e.siteAddressCounty,
                        'Site Address Postcode' : e.siteAddressPostcode,
                        'Site Manager' : t.getManager(e.siteManagerUserId),
                        'Site Contact Name' : e.siteContactName,
                        'Site Contact Email' : e.siteContactEmail,
                        'Site Contact Mobile' : e.siteContactMobile,
                        'Site Contact Position' : e.siteContactPosition,
                        'Site Contact Telephone' : e.siteContactTelephone,
                        'Site Contracted Hours' : e.siteContractedHours,
                        'Site Status': e.deleted ? 'Archived' : 'Active',
                        'Archived Date' : e.archivedDateTime ? t.$moment(e.archivedDateTime).format('DD-MMM-YYYY') : '',
                    }
                })

                let csv = t.$papa.unparse(data)
                t.$papa.download(csv, 'Site Report')
                t.exportingCSV = false
            }
        }
    },
	watch: {
		listExpanded() { this.refreshCols() },
		listShrunk() { this.refreshCols() },
		// GET_taskBar() { this.refreshCols() }
	},
    mounted() {
		// Dynamic Route for New or Existing Item
		this.routeId = this.$route.params.id;
        //console.log(this.routeId)
		if (this.routeId === "new") {
			this.openItem({})
		} 
        else if (this.routeId !== "new" && this.routeId !== "" && this.routeId !== undefined && this.routeId !== ':id') {
			this.openItem({ entityId: this.routeId })
		} 
    }
};
</script>