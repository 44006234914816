<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>

                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="secondary white--text mr-2" v-if="editmode && !itemNew && item.deleted == false" @click="archiveDialog = true"><v-icon class="mr-2">icons8-xbox-a</v-icon>Archive</v-btn>
                    <v-btn text class="primary white--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
                    <v-btn text class="primary white--text" v-if="editmode" @click="validateItem()"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="apptext--text" dense style="z-index: 0 !important">
            <!-- <pre>{{ item }}</pre> -->
            <v-col v-if="!editmode && !loading" cols="12" class="d-flex pa-2 align-center">

                <!-- //* VIEW MODE -->
                <v-row style="width: 100% !important" no-gutters>
                    <!-- //* GENERAL DETAILS VIEW -->
                    <v-col cols="12" sm="12" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Site Details</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">icons8-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Name:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.siteName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Telephone:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.siteTelephone }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Site Area:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ selectedAreas.areaName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Site Sector:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2" style="text-transform: capitalize">{{ item.siteSector }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Site Client:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ selectedClient.clientName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Site Start Date:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ computedDate1 }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Site Commercial Manager:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-1">
                                                    <span class="d-flex align-center">    
                                                        <span v-if="getUser(item.siteManagerUserId).userStatus == 'ARCHIVED'">
                                                            <v-icon class="error--text mb-1">icons8-filled-circle</v-icon>
                                                        </span>
                                                        <span class="pl-1">{{ getUser(item.siteManagerUserId).userName }}</span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Site Contract Hours:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.siteContractedHours }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">PPE Required:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.sitePPERequired }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">DBS Check:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.siteDBSCheck }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Offline Site:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.siteOffline }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Site Address</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">icons8-map-marker</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Address:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">
                                                    <a :href="`https://www.google.com/maps/search/?api=1&query=${item.siteName},${item.siteAddressLine1},${item.siteAddressLine2},${item.siteAddressLine3},${item.siteAddressTown},${item.siteAddressCounty},${item.siteAddressPostcode}`" target="_blank">
                                                        <span v-if="item.siteAddressLine1"
                                                            >{{ item.siteAddressLine1 }}
                                                            <span v-if="item.siteAddressLine2">, {{ item.siteAddressLine2 }}</span
                                                            ><br
                                                        /></span>
                                                        <span v-if="item.siteAddressLine3">{{ item.siteAddressLine3 }}<br /></span>
                                                        <span v-if="item.siteAddressTown"
                                                            >{{ item.siteAddressTown }}<span v-if="item.siteAddressCounty">, {{ item.siteAddressCounty }}</span
                                                            ><br
                                                        /></span>
                                                        <span v-if="item.siteAddressPostcode">{{ item.siteAddressPostcode }}<br /></span>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Site Contact</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">icons8-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Name:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.siteContactName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Position:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.siteContactTelephone }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Telephone:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.siteContactTelephone }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Mobile:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.siteContactMobile }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Email:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.siteContactEmail }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Site Emergency Contact</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">icons8-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Name:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.siteEmergencyContactName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Position:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.siteEmergencyContactTelephone }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Telephone:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.siteEmergencyContactTelephone }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Mobile:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.siteEmergencyContactMobile }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Email:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.siteEmergencyContactEmail }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Site Notes</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">icons8-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Notes:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.siteNotes }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="editmode && !loading" cols="12" class="d-flex pa-4 pt-4 align-center">
                <v-form ref="form" lazy-validation>
                    <v-row style="width: 100% !important" no-gutters>
                        <!-- DETAILS EDIT -->
                        <!-- <v-col cols="6">
                            <pre class="caption">{{ item }}</pre>
                        </v-col> -->
                        <!-- <v-col cols="6">
                            <pre class="caption">{{ selectedPackageIcon }}</pre>
                        </v-col> -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field 
                                        label="Site Name"
                                        v-model="item.siteName" 
                                        :rules="rules.required"
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        @click:clear="clearItem('siteName')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field 
                                        label="Site Telephone" 
                                        v-model="item.siteTelephone" 
                                        outlined 
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        @click:clear="clearItem('siteTelephone')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-autocomplete 
                                        label="Site Area" 
                                        v-model="selectedAreas" 
                                        :rules="rules.siteAreas" 
                                        required 
                                        :items="areas" 
                                        :menu-props="{ top: false, offsetY: true }" 
                                        :attach="true"
                                        auto-select-first 
                                        return-object 
                                        dense 
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        class="">
                                        <template v-slot:item="data"> {{ data.item.areaName }} </template>
                                        <template v-slot:selection="data"> {{ data.item.areaName }} </template>   
                                    </v-autocomplete> 
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    
                                    <v-autocomplete 
                                        label="Site Sector" 
                                        v-model="item.siteSector" 
                                        :rules="rules.siteSector" 
                                        required 
                                        :items="siteSectors" 
                                        :menu-props="{ top: false, offsetY: true }" 
                                        :attach="true"
                                        auto-select-first 
                                        dense 
                                        item-text="text"
                                        item-value="id"
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        class="">
                                        <!-- <template v-slot:item="data"> {{ data.item.siteName }} </template>
                                        <template v-slot:selection="data"> {{ data.item.siteName }} </template>    -->
                                    </v-autocomplete> 
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-autocomplete 
                                        label="Client" 
                                        v-model="selectedClient" 
                                        :rules="rules.siteClient" 
                                        required 
                                        :items="clients" 
                                        :menu-props="{ top: false, offsetY: true }" 
                                        :attach="true"
                                        auto-select-first 
                                        return-object 
                                        dense 
                                        outlined
                                        item-text="clientName" 
                                        item-value="id"
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        class=""
                                        clearable @click:clear="clearSelectedClient()"
                                        >
                                        <template v-slot:item="data"> {{ data.item.clientName }} </template>
                                        <template v-slot:selection="data"> {{ data.item.clientName }} </template>   
                                    </v-autocomplete> 
                                </v-col>
                                <!-- <v-col cols="6">
                                    <pre>{{ selectedClient }}</pre>
                                </v-col> -->
                                <!-- //! TODO CHANGE TO DATEPICKER -->
                                <v-col cols="12" xs="12" sm="6">
                                    <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field label="Site Start Date" v-model="computedDate1" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('siteStartDateTime')"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-autocomplete 
                                        label="Commercial Manager" 
                                        v-model="selectedCommercialManager" 
                                        :rules="rules.siteCommercialManager" 
                                        required 
                                        :items="managers" 
                                        :menu-props="{ top: false, offsetY: true }" 
                                        :attach="true"
                                        auto-select-first 
                                        return-object 
                                        dense 
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        class="">
                                        <template v-slot:item="data"> {{ data.item.userName }} </template>
                                        <template v-slot:selection="data"> {{ data.item.userName }} </template>   
                                    </v-autocomplete> 
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field 
                                        label="Site Contract Hours" 
                                        v-model="item.siteContractedHours" 
                                        min=0
                                        outlined 
                                        type="number"
                                        :oninput="item.siteContractedHours < 0 ? item.siteContractedHours = 0 : ''"
                                        dense 
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary" 
                                        clearable 
                                        @click:clear="clearItem('siteTelephone')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-select 
                                        class="" 
                                        hide-details="auto" 
                                        label="Site Status" 
                                        :rules="rules.clientStatus" 
                                        filled 
                                        v-model="itemStatus" 
                                        required 
                                        outlined
                                        dense
                                        @click:clear="clearItem('clientStatus')"
                                        :items="siteStatus" 
                                        background-color="white" 
                                        color="primary"
                                        :menu-props="{ top: false, offsetY: true }" 
                                        :attach="true"
                                        item-text="text"
                                        item-value="id"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" class="d-flex align-start">
                                    <v-checkbox v-model="item.sitePPERequired" label="PPE Required" hide-details="auto" class="ma-0 pa-0"></v-checkbox>
                                <!-- </v-col>
                                <v-col cols="12" xs="12" sm="4" class="d-flex align-center"> -->
                                    <v-checkbox v-model="item.siteDBSCheck" label="DBS Check" hide-details="auto" class="ml-3 ma-0 pa-0"></v-checkbox>
                                <!-- </v-col>
                                <v-col cols="12" xs="12" sm="4" class="d-flex align-center"> -->
                                    <v-checkbox v-model="item.siteOffline" label="Offline Site" hide-details="auto" class="ml-3 ma-0 pa-0"></v-checkbox>
                                </v-col>
                                
                            </v-row>


                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Address</div>
                                    <v-spacer />
                                    <v-btn v-if="selectedClient.entityId !== ''" text title="Select to Copy Client Address" class="mb-0" @click="copyClientAddress()">
                                        <v-icon class="secondary--text mr-1 inputradius">icons8-drag-and-drop</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Site Address Line 1" v-model="item.siteAddressLine1" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceName')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Site Address Line 2" v-model="item.siteAddressLine2" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceDescription')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Site Address Line 3" v-model="item.siteAddressLine3" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceDescription')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Site Town" v-model="item.siteAddressTown" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceDescription')"></v-text-field>
                                </v-col>    
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Site County" v-model="item.siteAddressCounty" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceDescription')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Site Postcode" v-model="item.siteAddressPostcode" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('serviceDescription')"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Contact Details</div>
                                    <v-spacer />
                                    <v-btn v-if="selectedClient.entityId !== ''" text title="Select to Copy Client Contact Details" @click="copyClientContact()">
                                        <v-icon class="secondary--text mr-1 inputradius">icons8-drag-and-drop</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Site Contact Name" v-model="item.siteContactName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('siteContactName')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Site Contact Position" v-model="item.siteContactPosition" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('siteContactPosition')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Site Contact Telephone" v-model="item.siteContactTelephone" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('siteContactTelephone')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Site Contact Mobile" v-model="item.siteContactMobile" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('siteContactMobile')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Site Contact Email" v-model="item.siteContactEmail" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('siteContactEmail')"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Emergency Contact Details</div>
                                    <v-spacer />
                                    <v-btn v-if="selectedClient.entityId !== ''" text title="Select to Copy Client Emergency Contact Details" @click="copyClientEmergencyContact()">
                                        <v-icon class="secondary--text mr-1 inputradius">icons8-drag-and-drop</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Site Emergency Contact Name" v-model="item.siteEmergencyContactName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('siteEmergencyContactName')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Site Emergency Contact Position" v-model="item.siteEmergencyContactPosition" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('siteEmergencyContactPosition')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Site Emergency Contact Telephone" v-model="item.siteEmergencyContactTelephone" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('siteEmergencyContactTelephone')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Site Emergency Contact Mobile" v-model="item.siteEmergencyContactMobile" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('siteEmergencyContactMobile')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Site Emergency Contact Email" v-model="item.siteEmergencyContactEmail" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('siteEmergencyContactEmail')"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Notes</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <!-- // TODO CHANGE TO TEXT AREA  -->
                                <v-col cols="12" xs="12">
                                    <v-textarea label="" v-model="item.siteNotes" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('siteNotes')"></v-textarea>
                                </v-col>
                            </v-row>




                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
        <v-dialog persistent v-model="archiveDialog" width="500px" height="400px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 secondary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mr-3 mb-1">
                        <v-icon class="white--text">icons8-xbox-a</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Archiving Site</strong>
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="archiveDialog = !archiveDialog" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey align-center lighten-3 pa-0">               
                    
                    <v-row class="pa-0 pt-4 ma-0 justify-center d-flex" fill-height>
                        <v-col cols="12" class="align-center pt-0">
                            <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field label="Archive From" v-model="computedDate2" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('contentAvailableFrom')"></v-text-field>
                                </template>
                                <v-date-picker v-model="date2" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu2 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="pa-0" fill-height cols="12" style="width: 100% !important">
                            <div class="font-weight-bold body-2 pa-3 text-h7 grey--text">
                                This will archive any Jobs relating to this Site from {{ computedDate2 }}
                            </div>
                        </v-col>
                    </v-row>
                    
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-0">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="archiveDialog = !archiveDialog"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                            <v-btn class="primary white--text font-weight-bold ml-2" depressed @click="archiveSite"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
                        </v-col>
                    </v-col>
                </v-card-actions>
            </div>
        </v-dialog>
        <v-dialog persistent v-model="progressDialog" width="500px" height="400px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 secondary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mr-3 mb-1">
                        <v-icon class="white--text">icons8-future</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Archive Jobs</strong>
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeProgressDialog" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 px-0 pa-3 d-flex flex-column align-center">    

                    <v-progress-circular v-if="progressData.status == 'In Progress' || progressData.status == 'Not Started'" indeterminate :size="60" :width="7"></v-progress-circular>
                    <div v-if="progressData.status == 'In Progress' || progressData.status == 'Not Started'" class=" grey--text font-weight-bold text--darken-2 d-flex align-center animate__animated animate__flash animate__infinite animate__slow">The Jobs linked to this Site are being archived. Please wait...</div>
                    <div v-if="progressData.status == 'Completed'" class=" grey--text font-weight-bold text--darken-2 d-flex align-center animate__animated animate__flash animate__infinite animate__slow">The Jobs linked to this Site have been successfully archived.</div>

                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-0">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn class="grey darken-1 white--text font-weight-bold" :disabled="progressData.status == 'In Progress'" depressed @click="closeProgressDialog"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Close</span> </v-btn>
                        </v-col>
                    </v-col>
                </v-card-actions>
            </div>
        </v-dialog>

    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
import schema from '@/schema.js';
import { mapGetters } from "vuex";
import moment from 'moment';

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        // activeitem: {
        //     Type: Object,
        //     Default: null,
        // },
    },
    // * DATA
    data: () => ({
        key: 'action',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,
        // dropdowns
        areas: [],
        clients: [],
        managers: [],
        users: [],
        selectedCommercialManager: {
            entityId: "",
            userName: ""
        },  
        selectedClient: {
            entityId: "",
            clientName: "",
            clientAddressLine1: "",
            clientAddressLine2: "",
            clientAddressLine3:  "",
            clientAddressCounty: "",
            clientAddressPostcode: "",
            clientAddressTown: "",

            clientContactName: "",
            clientContactEmail: "",
            clientContactMobile: "",
            clientContactPosition: "",
            clientContactTelephone: "",
            
            clientEmergencyContactName: "",
            clientEmergencyContactEmail: "",
            clientEmergencyContactMobile: "",
            clientEmergencyContactPosition: "",
            clientEmergencyContactTelephone: "",
        },
        siteStatus: [
            {
                text: 'Active', 
                id: 'ACTIVE', 
                disabled: false
            },{
                text: 'Archive', 
                id: 'ARCHIVE', 
                disabled: true
            }
        ],
        itemStatus: '',

        selectedAreas: {
            entityId: "",
            areaName: ""
        },

        siteSectors: [
            {   
                text: 'Medical',
                id: 'MEDICAL'
            }, {
                text: 'Offices', 
                id: 'OFFICES'
            }, {
                text: 'Hospitality', 
                id: 'HOSPITALITY'
            }, {
                text: 'Education', 
                id: 'EDUCATION'
            }, {
                text: 'Security', 
                id: 'SECURITY'
            }, {
                text: 'Factory', 
                id: 'FACTORY'
            }, {
                text: 'Retail',
                id: 'RETAIL'
            },
        ],

        // new address object
        item: {},
        loading: false,
        rules: {},


        date1: '',
        dateMenu1: false,
        date2: moment().format('YYYY-MM-DD'),
        dateMenu2: false,


        archiveDialog: false,

        progressDialog: false,
        progress: 0,
        progressData: {
            id: "",
            totalItems: 0,
            count: 0,
            success: 0,
            error: 0,
            status: "Not Started",
            report: "",
        },
		progressStatus: "Not Started",
		checkProgressInterval: null,

    }),
    computed: {  
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
        computedDate1() {
            return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
        computedDate2() {
            return this.MIX_formatDateTime(this.date2, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
    },
    methods: {

        getUser(userId) {
            var t = this;
            var user = []
            var userName = ''
            console.log('getting user = ' + JSON.stringify(userId, null, 2) + ' : ' + t.users.length)
            user = t.users.filter((item) => { return item.entityId == userId })

            if (user.length !== 0) { userName = user[0] }

            return userName
        },
        validateItem() {
			let t = this;
			t.rules = {
				required: [(v) => !!v || "Required"],
			};
			t.$nextTick(() => {
				if (t.$refs.form.validate()) {
                    if (t.itemNew) {
					    this.saveItem();
                    } else {
                        this.updateItem();
                    }
					this.editMode = false;
				} else {
					this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
				}
			});
        },
        initialise() {
            this.item = { ...this.datadown };
            console.log('item', JSON.stringify(this.item, null, 2));

            if (this.item.siteName !== '') {
                this.itemNew = false;
                this.editmode = false;

            } else {
                this.itemNew = true;
                this.editmode = true;
            }

            if (this.item.siteClientId !== '') {
                var selectedClient = this.clients.filter((item) => {
                    return item.entityId === this.item.siteClientId
                })
                this.selectedClient = selectedClient[0]
            }

            if (this.item.siteManagerUserId !== '') {
                this.selectedCommercialManager = this.managers.filter((item) => {
                    return item.entityId === this.item.siteManagerUserId
                })
                if (this.selectedCommercialManager.length > 0) {
                    this.selectedCommercialManager = this.selectedCommercialManager[0]
                } else {
                    this.selectedCommercialManager = {
                        entityId: "",
                        userName: ""
                    }
                }
            }

            if (this.item.siteStartDateTime !== '' && this.item.siteStartDateTime !== null) {
                this.date1 = this.$moment(this.item.siteStartDateTime).format('YYYY-MM-DD')
            }

            if (this.item.siteAreaId && this.item.siteAreaId !== null && this.item.siteAreaId !== '') {
                this.selectedAreas = this.areas.filter((item) => {
                    return item.entityId === this.item.siteAreaId
                })
                if (this.selectedAreas.length > 0) {
                    this.selectedAreas = this.selectedAreas[0]
                } else {
                    this.selectedAreas = {
                        entityId: "",
                        areaName: ""
                    }
                }
            } else {
                this.selectedAreas = {
                    entityId: "",
                    areaName: ""
                }
            }

            if (this.item.deleted == true) {
                this.itemStatus = 'ARCHIVE'
            } else {
                this.itemStatus = 'ACTIVE'
            }

        },
        updateItem() {
            if (this.itemStatus == 'ACTIVE') {
                this.item.deleted = false
                this.item.deletedUserId = ''
                this.item.deletedDateTime = ''
            } 
            this.item.siteContractedHours = Number(this.item.siteContractedHours)
            this.$emit('update', this.item);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {

            this.item.siteContractedHours = Number(this.item.siteContractedHours)
            this.$emit('save', this.item);
            this.editmode = false;
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        clearSelectedClient() {
            setTimeout(() => {
                this.selectedClient = { 
                    entityId: '', 
                    clientName: '' ,
                    clientAddressLine1: "",
                    clientAddressLine2: "",
                    clientAddressLine3:  "",
                    clientAddressCounty: "",
                    clientAddressPostcode: "",
                    clientAddressTown: "",

                    clientContactName: "",
                    clientContactEmail: "",
                    clientContactMobile: "",
                    clientContactPosition: "",
                    clientContactTelephone: "",
                    
                    clientEmergencyContactName: "",
                    clientEmergencyContactEmail: "",
                    clientEmergencyContactMobile: "",
                    clientEmergencyContactPosition: "",
                    clientEmergencyContactTelephone: "",
                };
            }, 1);
        },
        copyClientAddress() {
            var t = this

            t.item.siteAddressLine1 = t.selectedClient.clientAddressLine1
            t.item.siteAddressLine2 = t.selectedClient.clientAddressLine1
            t.item.siteAddressLine3 = t.selectedClient.clientAddressLine2
            t.item.siteAddressCounty = t.selectedClient.clientAddressCounty
            t.item.siteAddressTown = t.selectedClient.clientAddressTown
            t.item.siteAddressPostcode = t.selectedClient.clientAddressPostcode
        },
        copyClientContact() {
            var t = this; 

            t.item.siteContactName = t.selectedClient.clientContactName
            t.item.siteContactEmail = t.selectedClient.clientContactEmail
            t.item.siteContactMobile = t.selectedClient.clientContactMobile
            t.item.siteContactPosition = t.selectedClient.clientContactPosition
            t.item.siteContactTelephone = t.selectedClient.clientContactTelephone
        },
        copyClientEmergencyContact() {
            var t = this; 

            t.item.siteEmergencyContactName = t.selectedClient.clientEmergencyContactName
            t.item.siteEmergencyContactEmail = t.selectedClient.clientEmergencyContactEmail
            t.item.siteEmergencyContactMobile = t.selectedClient.clientEmergencyContactMobile
            t.item.siteEmergencyContactPosition = t.selectedClient.clientEmergencyContactPosition
            t.item.siteEmergencyContactTelephone = t.selectedClient.clientEmergencyContactTelephone
        },
        async archiveSite() {
            var t = this;

            let data = {}

            data.progressId = t.MIX_generateId()
            data.siteId = t.item.entityId 
            data.archiveFromDate = t.$moment(t.computedDate2, 'DD-MMM-YYYY').format('X')
            data.modifiedUserId = t.GET_FIREBASE_currentUser.entityId

            let result = await t.REDIS_archiveSite(data)
            console.log('result = ' + JSON.stringify(result, null, 2))
            // debugger
            if (result.success) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Client and Jobs Archived", show: true });
                this.checkProgress(data.progressId)
                this.progressDialog = true
            } else {
            }
            t.archiveDialog = false
            this.$emit('delete', this.item)
        },
        // Check Progress of Import
        checkProgress(progressId) {
            let t = this;
            this.checkProgressInterval = setInterval(async () => {
                try {
                    let progressResult = await t.REDIS_read("progress", progressId);
                    if (progressResult.status !== "404") {
                        console.log('progressResult = ' + JSON.stringify(progressResult, null, 2))
                        t.progressData = progressResult.data;
                        t.progressStatus = progressResult.data.status;
                        if (progressResult.data.count !== 0 && progressResult.data.totalItems !== 0) {
                            t.progress = (progressResult.data.count / progressResult.data.totalItems) * 100;
                        } else {
                            t.progress = 100;
                        }
                        console.log('progress = ' + t.progress)
                        if (progressResult.data.status === "Completed") {
                            clearInterval(t.checkProgressInterval);
                        }
                    } else if (progressResult.status === "404") {
                        clearInterval(t.checkProgressInterval);
                    }
                } catch (error) {
                    console.error(error);
                    clearInterval(t.checkProgressInterval);
                }
            }, 1000);
        },
        closeProgressDialog() {
            var t = this;
            t.progressDialog = false
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        selectedClient: {
            handler: function () {
                this.item.siteClientId = this.selectedClient.entityId;
            },
            deep: true,
        },
        selectedCommercialManager: {
            handler: function () {
                if (this.selectedCommercialManager !== undefined && this.selectedCommercialManager.entityId !== '') {
                    this.item.siteManagerUserId = this.selectedCommercialManager.entityId
                }
            },
            deep: true
        }, 
        selectedAreas: {
            handler: function () {
                this.item.siteAreaId = this.selectedAreas.entityId;
            }, 
            deep: true
        },
        dateMenu1: {
            handler: async function () {
                this.item.siteStartDateTime = this.$moment(this.date1, 'YYYY-MM-DD').toISOString();
            },
            deep: true,
        },

    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.site;
        t.loading = true


        let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false}');
        if (clients.success) {
            t.clients = clients.data.documents;
        }

        t.clients = t.clients.map((x) => {
            return {
                entityId: x.entityId,
                clientName: x.clientName,
                clientAddressLine1: x.clientAddressLine1, 
                clientAddressLine2: x.clientAddressLine2, 
                clientAddressLine3: x.clientAddressLine3, 
                clientAddressCounty: x.clientAddressCounty, 
                clientAddressPostcode: x.clientAddressPostcode, 
                clientAddressTown: x.clientAddressTown,

                clientContactName: x.clientContactName, 
                clientContactEmail: x.clientContactEmail, 
                clientContactMobile: x.clientContactMobile, 
                clientContactPosition: x.clientContactPosition, 
                clientContactTelephone: x.clientContactTelephone,

                clientEmergencyContactName: x.clientEmergencyContactName, 
                clientEmergencyContactEmail: x.clientEmergencyContactEmail, 
                clientEmergencyContactMobile: x.clientEmergencyContactMobile, 
                clientEmergencyContactPosition: x.clientEmergencyContactPosition, 
                clientEmergencyContactTelephone: x.clientEmergencyContactTelephone,

            };
        })
        let areas = await t.REDIS_searchFor('area', '0', '9999', 'areaName', 'ASC', '@deleted:{false}');
        if (areas.success) {
            t.areas = areas.data.documents;
        }

        let users = await t.REDIS_searchFor('user', '0', '999', 'userStartDate', 'ASC', '@deleted:{false}');
        if (users.success) { t.users = users.data.documents; }

        t.users = t.users.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
                userStatus: x.userStatus,
            };
        })

        let managers = await t.REDIS_searchFor('user', '0', '9999', 'userName', 'ASC', `@deleted:{false} @userCategory:{'COMMERCIALMANAGER' | 'DIRECTOR' | 'SENIORMANAGER'} @userStatus:{'APPROVED'}`)
        if (managers.success) { t.managers = managers.data.documents; }

        t.managers = t.managers.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
            };
        })
        
        t.initialise();

        t.loading = false
    },
    mounted() {},
};
</script>