<template>
    <v-row fluid fill-height class="d-flex flex-column align-start appbackground" style="height: 100% !important" no-gutters>
    <!-- <v-container fluid fill-height class="d-flex flex-column align-start appbackground pa-0 ma-0"> -->
		<v-col class="align-center pa-3 mt-1" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">
            <div class="d-flex align-center">
                <div><v-icon class="primary--text" x-large>icons8-future</v-icon></div>
                <div class="text-left primary--text pl-1 text-h4 font-weight-thin">Recurring Jobs</div>
                <v-btn v-if="$vuetify.breakpoint.mdAndUp" color="secondary" text @click="MIX_go('/jobs')">
                    <v-icon>icons8-back</v-icon> Back to Jobs 
                </v-btn>
                <v-spacer />
                <div class="ml-1">
                    <v-btn depressed block class="secondary white--text" @click="openItem({})" >
                        <v-icon>icons8-future</v-icon>
                        <span v-if="!listShrunk" class="ml-3">New Recurring</span>
                    </v-btn>
                </div>
				<div v-if="$vuetify.breakpoint.lgAndUp">
					<v-btn @click="exportCSV" class="primary--text ml-2" depressed v-if="!listShrunk">
						<v-icon color="primary">icons8-export-csv</v-icon>
					</v-btn>
				</div>
            
            </div>
            
            <RecurringJobList :listshrunk="listShrunk" v-on:openRecurringJob="openRecurringJob" :listexpanded="listExpanded" v-on:openItem="openItem" v-on:openJob="openJob" :refreshitems="refreshItems"/>
            
		
        </v-col>

        <div class="text-center">
            <v-overlay :value="exportingCSV">
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
                <!-- <div v-if="creatingRule" class="font-weight-bold pt-4">Creating Jobs, please wait</div> -->
            </v-overlay>
        </div>

    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import RecurringJobList from '@/views/recurringJobs/recurringJobsList.vue'
export default {
    name: 'RecurringJobs',
    components: {
        'RecurringJobList': RecurringJobList
    },
    data: () => ({
        editMode: false,
        orgtype: "",
        orgIcon: "",
        search: '',
        show: false,
        tab: '',
        orgs: [],
        orgDialog: false,
        refreshItems: 0,
        activeItem: {},
        extendWidth: '',
        routeId: '',
        openJobView: false,
		// Item Columns
		itemsCols: { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12,},
		itemCols: { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 },
		taskCols: {	cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2,},
		listExpanded: true,
		listShrunk: false,

        exportingCSV: false,

        users: [],
        services: [],
        sites: [],
        clients: [],
    }),
    computed: {
        ...mapGetters({
            // GET_FIREBASE_userAuth: 'GET_FIREBASE_userAuth',
        }),
    },
    methods: {
		refreshCols() {
			switch (true) {
				// Expanded List with No Task Bar
				case this.openJobView:
					this.itemsCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 12
					this.itemCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 0
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// Expanded List with Task Bar
				case this.listExpanded && !this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
					this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				// List with Product Page (No Task Bar)
				case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// List with Product Page and Task Bar
				case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 4
					this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				// Shrunk List with Product Page (No Task Bar)
				case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }; // 2
					this.itemCols = { cols: 12, xs: 9, sm: 9, md: 9, lg: 9, xl: 9 }; // 10
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// Shrunk List with Product Page and Task Bar)
				case !this.listExpanded && this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				default:
					this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 12
					this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
			}
		},
		openItem(item) {
            if (JSON.stringify(item) !== '{}') {
                this.MIX_go('recurringJob/' + item.entityId)
            } else {
                this.MIX_go('recurringJob/new')
            }
		},
        openJob(item) {
            //console.log('item = ' + item)
            // if (item)
            // this.MIX_go('job/new')
            // this.activeItem = item
            // this.listExpanded = false 
            // this.openJobView = true
            // this.refreshCols()
        },
		closeItem() {
			this.listExpanded = true;
			this.listShrunk = false;
		},
        openRecurringJob(item) {
            this.MIX_go('recurringJob/new')

        },
        getClient(clientId) {
            var t = this;
            var client = []
            var clientName = ''

            client = t.clients.filter((item) => { return item.entityId == clientId })

            if (client.length !== 0) { clientName = client[0].clientName }
            return clientName
        },
        getUser(userId) {
            var t = this;
            var user = []
            var userName = ''

            user = t.users.filter((item) => { return item.entityId == userId })

            if (user.length !== 0) { userName = user[0].userName }

            return userName
        },
        getSite(siteId) {
            var t = this;
            var site = []
            var siteName = ''

            site = t.sites.filter((item) => { return item.entityId == siteId })

            if (site.length !== 0) { siteName = site[0].siteName }

            return siteName
        },
        getStatus(recurring) {

            const monthAway = this.$moment().startOf('day').add(1, 'month').format('X')
            const current = this.$moment().startOf('day').format('X')

            var ruleEndDate = this.$moment(recurring.recurringRuleEndDate).format('X')
            if (recurring.recurringRuleStatus == null || recurring.recurringRuleStatus !== 'ARCHIVED') {
                if (ruleEndDate > monthAway) {
                    recurring.recurringRuleStatus = 'ACTIVE'
                } else if (ruleEndDate <= monthAway && ruleEndDate > current) {
                    recurring.recurringRuleStatus = 'EXPIRING' 
                } else if (ruleEndDate <= current) {
                    recurring.recurringRuleStatus = 'EXPIRED'
                }
            }

            return recurring.recurringRuleStatus
        },
        async exportCSV() {
            var t = this;
            t.exportingCSV = true
            let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
            if (sites.success) { t.sites = sites.data.documents; }

            t.sites = t.sites.map((x) => {
                return {
                    entityId: x.entityId,
                    siteName: x.siteName,
                };
            })
            let users = await t.REDIS_searchFor('user', '0', '999', 'userName', 'ASC', '@deleted:{false} @userStatus:{APPROVED}');
            if (users.success) { t.users = users.data.documents; }

            t.users = t.users.map((x) => {
                return {
                    entityId: x.entityId,
                    userName: x.userName,
                    userCategory: x.userCategory
                };
            })

            let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false} -@clientStatus:{ARCHIVE}');
            if (clients.success) { t.clients = clients.data.documents; }

            t.clients = t.clients.map((x) => {
                return {
                    entityId: x.entityId,
                    clientName: x.clientName,
                };
            })
            
            let itemsResult = await this.REDIS_searchFor('recurring-job', 0, 999, 'recurringJobStartDateTime','ASC', "@deleted:{false}" )
            if (itemsResult.success) {
                console.log('itemsResult: ' + JSON.stringify(itemsResult, null, 2))
            
                var data = itemsResult.data.documents.map(e => {
                    return {
                        'Status' : this.getStatus(e),
                        'Client' : this.getClient(e.recurringJobClientId),
                        'Assigned Users' : this.getUser(e.recurringJobAssignedUserId),
                        'Start Date' : this.$moment(e.recurringRuleStartDate).format('DD-MMM-YYYY'),
                        'End Date' : this.$moment(e.recurringRuleEndDate).format('DD-MMM-YYYY'),
                        'Type' : e.recurringJobType,
                        'Published Status' : e.recurringJobPublishedStatus,
                        'Site' : this.getSite(e.recurringJobSiteId),
                    }
                })

                console.log('data  = ' + JSON.stringify(data, null, 2))

                let csv = t.$papa.unparse(data)
                t.$papa.download(csv, 'Recurring Job Report')
                t.exportingCSV = false
            }
        }
    },
	watch: {
		listExpanded() { this.refreshCols() },
		listShrunk() { this.refreshCols() },
		// GET_taskBar() { this.refreshCols() }
	},
    mounted() {
		// Dynamic Route for New or Existing Item
		this.routeId = this.$route.params.id;
		if (this.routeId === "new") {
			this.openItem({})
		} else if (this.routeId !== "new" && this.routeId !== "" && this.routeId !== undefined) {
			this.openItem({ id: this.routeId })
		} 
    }
};
</script>