<template>
    <v-chart class="chart" :option="option" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { GaugeChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
use([CanvasRenderer, GaugeChart, TitleComponent, TooltipComponent, LegendComponent]);

export default {
    name: "GaugeChart",
    components: {
        VChart,
    },
    provide: {
        [THEME_KEY]: "light",
    },
    props: {
        seriesdata: {
            type: Number,
            default: 0,
        },
        xaxisdata: {
            type: Array,
            default: () => [],
        },
        color: {
            type: String,
        },
        seriestext: {
            type: String,
        },
    },
    data: () => ({
        colors: [],
    }),
    computed: {
        option() {
            // console.log("computedOption running");
            return {
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b} : {c} ({d}%)",
                },
                // legend: {
                //     orient: "vertical",
                //     left: "left",
                //     data: ["Direct", "Email", "Ad Networks", "Video Ads", "Search Engines"],
                // },
                series: [
                {
                    type: 'gauge',
                    startAngle: 180,
                    endAngle: 0,
                    min: 0,
                    radius: '100%',
                    max: 100,
                    itemStyle: {
                        color: '#65348a',
                    },
                    progress: {
                        show: true,
                        roundCap: false,
                        width: 58
                    },
                    pointer: {
                        show: false,
                    },
                    axisLine: {
                        roundCap: false,
                        lineStyle: {
                        width: 58
                        }
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    title: {
                        show: false
                    },
                    detail: {
                        show: false,
                        // backgroundColor: '#fff',
                        width: '60%',
                        // lineHeight: 40,
                        // height: 40,
                        offsetCenter: [0, 0],
                        valueAnimation: true,
                        formatter: function (value) {
                            return value.toFixed(0);
                        },
                        // rich: {
                        //     value: {
                        //         fontSize: 50,
                        //         fontWeight: 'bolder',
                        //         color: '#777'
                        //     },
                        //     unit: {
                        //         fontSize: 0,
                        //         color: '#999',
                        //         padding: [0, 0, -20, 10]
                        //     }
                        // }
                    },
                    data: [
                        {
                            value: this.seriesdata
                        },
                    ]
                }
                ],
            };
        }
    },
    methods: {
        LightenColor(color, percent) {
            var num = parseInt(color, 16),
                amt = Math.round(2.55 * percent),
                R = (num >> 16) + amt,
                B = ((num >> 8) & 0x00ff) + amt,
                G = (num & 0x0000ff) + amt;
            return (0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 + (G < 255 ? (G < 1 ? 0 : G) : 255)).toString(16).slice(1);
        
        },
        consoleOption() {
            // console.log('option = ' + JSON.stringify(this.option , null, 2))
        }

    },
    created() {
        this.colors = [];
        // console.log('color = ' + this.color)
        let color = this.color.slice(1, 10)
        let seriesLength = this.seriesdata.length
        for (let i = 0; i < seriesLength; i++) {
            let newColour = this.LightenColor(color, i*5)
            this.colors.push('#'+newColour);
        }
        this.consoleOption()
    },
};
</script>
<style scoped>
.chart {
    width: 100% !important;
    height: 300px !important;
    margin-bottom: -122px;
    /* margin-top: -50px; */
    /* height: 100vh; */
}
</style>