<template>
    <v-row fluid fill-height class="d-flex flex-column align-start appbackground" style="height: 100% !important" no-gutters>
    <!-- <v-container fluid fill-height class="d-flex flex-column align-start appbackground pa-0 ma-0"> -->
		<v-col class="align-center pa-3 mt-1" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">
            
			<div class="d-flex align-start mb-1">
				<div class="">
                    <div class="d-flex align-center">
                        <div><v-icon class="primary--text" x-large>icons8-broom</v-icon></div>
                        <div class="text-left primary--text pl-1 text-h4 font-weight-thin">Jobs</div>
                    </div>
                    <div class="text-h8 pa-0 pr-2">
                        View Jobs allocated to you for the day based on your recenty SWAPP'd location {{ getLocation(lastLocation) }}, {{ getSite(lastSite) }}. 
                        Click 'Start Job' button to record the Job. To View all Jobs allocated to you for the day, select 'View All Jobs'
                    </div>
                                    
				</div>
			</div>
            <!-- <pre>{{ GET_FIREBASE_currentUser }}</pre> -->
            <div class="d-flex align-end justify-end pb-2">
                <v-switch v-model="viewAll" color="primary" label="View All" class="pa-0 ma-0" hide-details="auto" inset></v-switch>
            </div>
            <div class="d-flex align-center flex-column pt-2" style="width: 100% !important">
            
                <div v-if="itemsFrom > 0">
                     
                    <v-btn block @click="goBack" class="elevation-0 darkgrey lighten-3 white--text  mb-2 font-weight-bold">
                        + GO BACK 
                    </v-btn>
                </div>
                <div class="mt-2" style="width: 100% !important" v-for="job in computedItems" :key="job.id">
                    <swapp-job-card style="" :job="job" :swappLocationId="lastLocation" :jobsPage=true v-on:openCommunications="openCommunications" v-on:addNotes="addNotes"></swapp-job-card>
                </div>
                <v-row v-if="loading === true" class="align-center pt-5 justify-center">
                    <v-progress-circular :size="60" :width="7" indeterminate color="primary" ></v-progress-circular>
                </v-row>
                <!-- <pre>computedItems: {{ computedItems }}</pre> -->
                <div v-else-if="computedItems.length == 0" class="font-weight-bold grey--text text--darken-1 body-1">
                    NO JOBS
                </div>
                <div v-else-if="computedItems.length !== itemsTotal && computedItems.length == datatableOptions.itemsPerPage">
                    <v-btn block @click="loadMore" class="elevation-0 darkgrey lighten-3 white--text font-weight-bold">
                        + SHOW MORE 
                    </v-btn>
                </div>
                <div v-else-if="computedItems.length <= itemsTotal && computedItems.length > 0" class="font-weight-bold grey--text text--darken-1 body-1">
                    SHOWING ALL JOBS
                </div>
            </div>
            
        </v-col>

        <v-dialog v-model="taskDialog" max-width="612" persistent height="900" :key="taskDialogKey">
            <v-card>
                <v-toolbar color="secondary" elevation="0">
                    <div class="white--text text-h6">Client Communications</div>
                </v-toolbar>
                <div class="grey lighten-4 px-4">
                    <div style="max-height: 350px !important; min-height: 350px !important; overflow: scroll !important" class="d-flex justify-center px-0">
                        <ul v-if="activeLevel == 'start'" class="py-4">
                            <li 
                                v-for="(task, index) in tasks" 
                                :key="index" 
                                class="font-weight-bold grey--text "
                                >
                                {{ task.taskTitle }}
                            </li>
                        </ul>

                        <div class="py-4" v-if="activeLevel == 'end'">
                            <div class="d-flex align-center justify-start grey--text pb-2 font-weight-bold text--darken-1">
                                Please select which tasks have been completed:
                            </div>
                            <div v-for="(task, index) in tasks" :key="index" class="d-flex align-center justify-start grey--text text--darken-2">
                                <v-checkbox v-model="task.taskCompleted" :label="task.taskTitle" hide-details="auto" class="pa-0 ma-0" />
                                <!-- {{ task.taskTitle }} -->
                            </div>
                        </div>
                        <div class="py-4" v-if="activeLevel == 'note'">
                            <div class="d-flex align-center justify-start grey--text pb-2 font-weight-bold text--darken-1">
                                Some Tasks have not been marked as complete. Please add a note to explain why:
                            </div>

                            <v-form ref="form" lazy-validation>
                                <v-textarea
                                    v-model="completedNote"
                                    :rules="[v => !!v || 'Note is required']"
                                    :counter="1000"
                                    label="Add Note"
                                    hint="Max 1000 characters"
                                    persistent-hint
                                    rows="5"
                                    class="elevation-0"
                                    clearable
                                    outlined
                                    auto-grow
                                    hide-details="auto"
                                ></v-textarea>
                            </v-form>
                            <!-- <v-spacer />
                            <v-btn text small class="ml-0 pl-0 secondary--text" @click="activeLevel = 'end'" ><v-icon>icons8-back</v-icon>Back to Tasks</v-btn> -->
                        </div>
                    </div>
                    <div v-if="activeLevel == 'start'" class="d-flex align-center justify-center">
                        <v-checkbox v-model="confirmRead" />
                        <app-text category="" class="pl-2 grey--text font-weight-bold">I confirm that I have read all tasks that need to be complete within my shift</app-text>
                    </div>
                </div>
                <div class="d-flex grey align-center justify-end lighten-1 pa-4">
                    <v-btn v-if="activeLevel == 'note'" class="elevation-0 secondary" @click="activeLevel = 'end'">Back to Tasks</v-btn>
                    <v-spacer />
                    <v-btn v-if="activeLevel == 'start'" :disabled="!confirmRead" class="elevation-0 primary" width="120" @click="startJob">Start Job</v-btn>
                    <v-btn v-if="activeLevel == 'end'" class="elevation-0 primary" width="120" @click="checkCompletedTasks">End Job</v-btn>
                    <v-btn v-if="activeLevel == 'note'" class="elevation-0 primary" width="120" @click="checkCompletedNote">Confirm</v-btn>
                </div>

            </v-card>
        </v-dialog>

        <v-dialog v-model="addNotesDialog" max-width="612" persistent height="900" :key="addNotesDialogKey">
            <v-card>
                <v-toolbar color="secondary" elevation="0">
                    <div class="white--text text-h6">Client Communications</div>
                </v-toolbar>
                <div class="grey lighten-4 px-4">
                    <div style="max-height: 350px !important; min-height: 450px !important; overflow: scroll !important" class="d-flex justify-center">

                        <div class="py-4" style="width: 100% !important">
                            <div class="d-flex align-center justify-start grey--text pb-2 font-weight-bold text--darken-1">
                                Please select which task to add a Note to:
                            </div>
                            <div>
                                <v-select 
                                    class="" 
                                    hide-details="auto" 
                                    label="Task" 
                                    filled 
                                    v-model="selectedTask"  
                                    outlined
                                    dense
                                    :items="tasks" 
                                    background-color="white" 
                                    color="primary"
                                    :menu-props="{ top: false, offsetY: true }" 
                                    :attach="true"
                                    item-text="taskTitle"
                                    return-object
                                ></v-select>
                            </div>
                            <div class="d-flex align-center justify-center pt-2">
                                <v-checkbox v-model="note" label="Or, if your Note is not regarding a task, please check this box" hide-details="auto" class="pa-0 ma-0 mr-1" />
                            </div>
                            

                            <div v-if="selectedTask.taskTitle !== '' || note" class="align-center justify-start">

                                <div class="d-flex align-center justify-start grey--text py-2 font-weight-bold text--darken-1">
                                    Please add your Notes here:
                                </div>
                                <div class="">
                                    <v-textarea 
                                        label="Note" 
                                        v-model="selectedTask.taskComments" 
                                        required 
                                        dense 
                                        outlined
                                        hide-details="auto" 
                                        background-color="white" 
                                        color="primary"
                                        rows="5"
                                        class=""
                                        >
                                    </v-textarea> 
                                </div>

                            </div>
                            <!-- <pre>{{ GET_FIREBASE_currentUser }}</pre> -->
                        </div>
                    </div>
                </div>
                <div class="d-flex grey align-end justify-end lighten-1 pa-4">
                    <v-btn class="elevation-0 secondary" width="120" @click="addNotesDialog = !addNotesDialog">Back</v-btn>
                    <v-spacer />
                    <!-- <v-btn v-if="!addCommunications" class="elevation-0 primary" width="120" @click="addCommunications = !addCommunications">Next</v-btn> -->
                    <v-btn class="elevation-0 primary" width="120" @click="saveNotes">Save Notes</v-btn>
                </div>

            </v-card>
        </v-dialog>

    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import SwappJobCard from '@/views/jobs/swappJobCard.vue';
import schema from "@/schema.js"
export default {
    name: 'Services',
    components: {
        'swapp-job-card': SwappJobCard,
    },
    data: () => ({
        key: 'jobs',

        activeItem: {},
        activeLevel: '',
        
        schema: {},

		// Item Columns
		itemsCols: { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12,},

        items: [],
        itemsTotal: null,
        itemsFrom: 0,
        itemsTo: 9,

        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['courseName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        taskDialog: false,
        taskDialogKey: 0,

        addNotesDialog: false,
        addNotesDialogKey: 0,

        tasks: [],
        confirmRead: false,
        addCommunications: false,
        newCommunication: '',
        newCommunications: [],

        lastSite: null,
        lastLocation: null,
        lastClient: null,

        selectedTask: {},

        note: false,

        viewAll: false,
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_currentUser: 'firebase_auth_store/GET_FIREBASE_currentUser',
        }),
        computedSearchQuery() {
            var t = this;
            let searchQuery = this.searchQuery;

            var day = t.$moment().format('X')

            var startOfDay = t.$moment(day, 'X').startOf('day').format('X')
            var endOfDay = t.$moment(day, 'X').endOf('day').format('X')

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                searchQuery = '@deleted:{false}';
            } else {
                if (searchQuery.startsWith('@')) {
                    searchQuery = `${searchQuery}  @deleted:{false}`;
                } else {
                    searchQuery = `${searchQuery}  @deleted:{false}`;
                }
            }

            searchQuery = `${searchQuery} @jobStartDateTime:[${startOfDay} ${endOfDay}]`
            if (t.viewAll == false) {
                searchQuery = `${searchQuery} @jobSiteId:{${t.lastSite}}`
            }

            searchQuery = `${searchQuery} @jobAssignedUserId:{${t.GET_FIREBASE_currentUser.entityId}}`

            searchQuery = `${searchQuery} @jobPublishedStatus:{PUBLISHED}`

            console.log(searchQuery);

            return searchQuery;
        },
        computedItems() {
            var t = this;
            var items = this.items;
            // console.log('items = ' + JSON.stringify(items, null, 2))

            items.forEach((item) => {
                if (t.viewAll == false) {
                    t.lastClient = item.jobClientId
                }
                item.jobClientName = this.getClient(item.jobClientId)
                item.jobServiceName = this.getService(item.jobServiceId)
                item.jobSiteName = this.getSite(item.jobSiteId)
                item.jobAssignedUserName = this.getUser(item.jobAssignedUserId)
            })


            // console.log('items = ' + JSON.stringify(items, null, 2))
            
            return items;
        },
    },
    methods: {
        addNotes(tasks) {
            var t = this;
            this.tasks = tasks.data
            console.log('tasks = ' + JSON.stringify(tasks, null, 2))
            this.addNotesDialog = true
            this.selectedTask = {...t.schema}
        },
        async saveNotes() {
            var t = this;

            var item = t.selectedTask

            if (t.note) {
                // add a new note
                var dateTimeNow = t.$moment().toISOString();
                item.taskTitle = 'Note from Cleaner'

                item.taskOrgId = t.lastClient;
                item.taskSiteId = t.lastSite;
                item.taskLocationId = t.lastLocation;
                
                item.createdDateTime = dateTimeNow;
                item.createdUserId = t.GET_FIREBASE_currentUser.entityId;

                console.log('item = ' + JSON.stringify(item, null, 2))

                // create note (as task)
                await this.REDIS_create('task', item);
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Note Created", show: true });

            } else {
                // update an existing task
                item.modifiedDateTime = t.$moment().toISOString()
                item.modifiedUserId = t.GET_FIREBASE_currentUser.entityId;
                console.log('item = ' + JSON.stringify(item, null, 2))

                // save task
                await this.REDIS_update('task', item.entityId, item);
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Task Updated", show: true });

            }


            this.addNotesDialog = false
            this.selectedTask = {...t.schema}
            this.addNotesDialogKey++
            this.note = false

            // var tasks = t.tasks

            // tasks = tasks.filter(function(item) {
            //     return item.taskAddNote && item.taskAddNote == true;
            // });

            // for (let i=0; i < tasks.length; i++) {
            //     var item = tasks[i]

            //     delete item.taskAddNote;
            //     item.taskComments = t.newCommunication;

            //     console.log('result = ' + JSON.stringify(result, null, 2))

            // }
            // this.addNotesDialog = false
            // this.addNotesDialogKey++

            // console.log('tasks = ' + JSON.stringify(tasks, null, 2))
        },
        async addCommunication() {
            var t = this; 

            var dateTimeNow = t.$moment().toISOString();

            var item = {...t.newCommunication}

            item.createdDateTime = dateTimeNow;
            item.createdUserId = t.GET_FIREBASE_currentUser.entityId;
            
            let result = await this.REDIS_create('task', item);
            
			if (result.success) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                t.initialiseSchema()
                t.newCommunications.push(item)
			}

        },
        openCommunications(level, tasks, job) {
            var t = this; 

            t.tasks = tasks
            t.taskDialog = true
            t.activeItem = job
            t.activeLevel = level

            t.initialiseSchema()
        },
        checkCompletedTasks() {
            var t = this;

            var tasks = t.tasks

            var completedTasks = tasks.filter(function(item) {
                return item.taskCompleted && item.taskCompleted == true;
            });

            if (completedTasks.length !== tasks.length) {
                t.activeLevel = 'note'
            } else {
                t.endJob()
            }
        },
        checkCompletedNote() {
            console.log('checkCompletedNote')
            if (this.$refs.form.validate()) {
                this.saveNote()
                this.endJob()
            }
        },
        async saveNote() {
            var t = this; 

            // go through tasks and add note to each one
            var tasks = t.tasks
            console.log('tasks = ' + JSON.stringify(tasks, null, 2))

            for (let i=0; i < tasks.length; i++) {
                var item = tasks[i]

                item.taskComments = item.taskComments + ' Reason for no completion ('+ t.$moment().format('HH:mm DD-MMM-YYYY') + '): ' + t.completedNote;

                item.modifiedDateTime = t.$moment().toISOString()
                item.modifiedUserId = t.GET_FIREBASE_currentUser.entityId;


                // save task
                await this.REDIS_update('task', item.entityId, item);
            }

            // console.log('tasks = ' + JSON.stringify(tasks, null, 2))
        },
        async endJob() {
            var t = this; 

            t.activeItem.swappStatus = 'finished';
            t.activeItem.swappEndTime = t.$moment().toISOString();

            var endTime = t.$moment(t.activeItem.jobEndDateTime).format('X')

            var minusThirty = t.$moment().subtract(15, 'minutes').format('X')
            var plusThirty = t.$moment().add(15, 'minutes').format('X')

            if ((endTime < minusThirty) || (endTime > plusThirty)) {
                // WithinEndTime = false
                if (endTime < minusThirty) {
                    // endInPast = true
                    // t.sendLateNotification()

                } else if ( endTime > plusThirty) {
                    // endInFuture = true
                    // t.sendLateNotification()
                }
            }

            var startTime = t.activeItem.swappStartTime
            var endTime = t.activeItem.swappEndTime

            // work out duration completed

            var jobDuration = t.$moment(endTime).diff(t.$moment(startTime));
            var actualJobDuration = Math.floor(t.$moment.duration(jobDuration).asHours()) + t.$moment.utc(jobDuration).format(":mm")
            console.log('actualJobDuration = ' + actualJobDuration)


            // CHECKING IF JOB DURATION IS AS EXPECTED DURATION
            var minusThirtyDuration = t.$moment(jobDuration, 'HH:mm').subtract(15, 'minutes').format('X')
            var plusThirtyDuration = t.$moment(jobDuration, 'HH:mm').add(15, 'minutes').format('X')


            if (actualJobDuration < minusThirtyDuration) {
                //! run too little notif
                console.log("too quick")
                // t.sendDurationNotification()
            } else if (actualJobDuration > plusThirtyDuration) {
                //! run too much notif
                console.log("too slow")
                // t.sendDurationNotification()
            }

            delete t.activeItem.jobClientName
            delete t.activeItem.jobServiceName
            delete t.activeItem.jobSiteName
            delete t.activeItem.jobAssignedUserName

            t.activeItem.modifiedUserId = t.GET_FIREBASE_currentUser.entityId

            let result = await this.REDIS_update(t.key, t.activeItem.entityId, t.activeItem);

            if (result.success) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Job Ended", show: true });
            }

            var tasks = t.tasks 

            tasks = tasks.filter(function(item) {
                return item.taskCompleted && item.taskCompleted == true;
            });
            // save tasks as completed
            for (let i=0; i < tasks.length; i++) {
                var item = tasks[i]
                console.log('item = ' + JSON.stringify(item, null, 2))
                item.taskCompletedUserId = t.GET_FIREBASE_currentUser.entityId
                item.taskCompletedDateTime = t.$moment().toISOString()
                item.modifiedDateTime = t.$moment().toISOString()
                item.modifiedUserId = t.GET_FIREBASE_currentUser.entityId;

                // save task
                let result = await this.REDIS_update('task', item.entityId, item);
            }

            t.taskDialog = false
            t.taskDialogKey++
            t.confirmRead = false
            t.getItems()

        },
        async startJob() {
            var t = this;


            t.activeItem.swappStatus = 'started';
            t.activeItem.swappStartTime = t.$moment().toISOString();

            console.log('t.activeItem = ' + JSON.stringify(t.activeItem, null, 2))

            // save job 
            t.activeItem.modifiedDateTime = t.$moment().toISOString();
            t.activeItem.modifiedUserId = t.GET_FIREBASE_currentUser.entityId;
            
            let result = await this.REDIS_update(t.key, t.activeItem.entityId, t.activeItem);

            if (result.success) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Job Started", show: true });
            }

            console.log('tasks startJob = ' + JSON.stringify(t.tasks, null, 2))
            // save tasks as read
            for (let i=0; i < t.tasks.length; i++) {
                var item = t.tasks[i]
                console.log('item = ' + JSON.stringify(item, null, 2))
                item.taskRead = true
                item.taskReadUserId = t.GET_FIREBASE_currentUser.entityId
                item.taskReadDateTime = t.$moment().toISOString()
                item.modifiedDateTime = t.$moment().toISOString()
                item.modifiedUserId = t.GET_FIREBASE_currentUser.entityId;

                console.log('item = ' + JSON.stringify(item, null, 2))

                // save task
                let result = await this.REDIS_update('task', item.entityId, item);

                console.log('result = ' + JSON.stringify(result, null, 2))
            }

            t.taskDialog = false
            t.taskDialogKey++
            t.confirmRead = false
            t.getItems()
        },
        getClient(clientId) {
            var t = this;
            var client = []
            var clientName = ''

            client = t.clients.filter((item) => { return item.entityId == clientId })

            if (client.length !== 0) { clientName = client[0].clientName }
            return clientName
        },
        getUser(userId) {
            var t = this;
            var user = []
            var userName = ''

            console.log('userId = ' + userId)

            user = t.users.filter((item) => { return item.entityId == userId })

            if (user.length !== 0) { userName = user[0].userName }
            console.log('userName = ' + userName)

            return userName
        },
        getService(serviceId) {
            var t = this;
            var service = []
            var serviceName = ''

            service = t.services.filter((item) => { return item.entityId == serviceId })

            if (service.length !== 0) { serviceName = service[0].serviceName }

            return serviceName
        },
        getSite(siteId) {
            var t = this;
            var site = []
            var siteName = ''

            site = t.sites.filter((item) => { return item.entityId == siteId })

            if (site.length !== 0) { siteName = site[0].siteName }

            return siteName
        },
        getLocation(locationId) {
            var t = this;
            var location = []
            var locationName = ''

            location = t.locations.filter((item) => { return item.entityId == locationId })

            if (location.length !== 0) { locationName = location[0].locationName }

            return locationName

        },
        openJob(item) {
            this.MIX_go('job/new')
        },
        openRecurringJob(item) {
            this.MIX_go('recurringJob/new')
        },
		closeItem() {
			this.listExpanded = true;
			this.listShrunk = false;
		},
        async getItems() {
            var t = this;
            t.loading = true
            t.items = []

            //console.log('searchQuery = ' + t.searchQuery)

            let itemsResult = await this.REDIS_searchFor('jobs', this.itemsFrom, this.datatableOptions.itemsPerPage, 'jobStartDateTime','ASC', this.computedSearchQuery )
            // //console.log('itemsResult: ' + JSON.stringify(itemsResult));
            if (itemsResult.success) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.documents;
                t.loading = false
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            }
        },
        async loadMore() {
            this.itemsFrom = this.itemsFrom + this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo + this.datatableOptions.itemsPerPage;
            this.getItems();
        },
        async goBack() {
            this.itemsFrom = this.itemsFrom - this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo - this.datatableOptions.itemsPerPage;
            this.getItems()
        },
    },
	watch: {
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
        computedSearchQuery: {
            handler() {
                this.getItems();
            },
            deep: true,
        },
	},
    async created() {
        var t = this;
        let users = await t.REDIS_searchFor('user', '0', '999', 'userStartDate', 'ASC', '@deleted:{false}');
        if (users.success) { t.users = users.data.documents; }

        t.users = t.users.map((x) => {
            return {
                entityId: x.entityId,
                userName: x.userName,
            };
        })

        let clients = await t.REDIS_searchFor('client', '0', '9999', 'clientName', 'ASC', '@deleted:{false} -@clientStatus:{ARCHIVE}');
        if (clients.success) { t.clients = clients.data.documents; }

        t.clients = t.clients.map((x) => {
            return {
                entityId: x.entityId,
                clientName: x.clientName,
            };
        })

        
        let services = await t.REDIS_searchFor('service', '0', '9999', 'serviceName', 'ASC', '@deleted:{false}');
        if (services.success) { t.services = services.data.documents; }

        t.services = t.services.map((x) => {
            return {
                entityId: x.entityId,
                serviceName: x.serviceName,
            };
        })

        let sites = await t.REDIS_searchFor('site', '0', '9999', 'siteName', 'ASC', '@deleted:{false}');
        if (sites.success) { t.sites = sites.data.documents; }

        t.sites = t.sites.map((x) => {
            return {
                entityId: x.entityId,
                siteName: x.siteName,
            };
        })

        let locations = await t.REDIS_searchFor('location', '0', '9999', 'locationName', 'ASC', '@deleted:{false}');
        if (locations.success) { t.locations = locations.data.documents; }

        t.locations = t.locations.map((x) => {
            return {
                entityId: x.entityId,
                locationName: x.locationName,
            };
        })

        t.lastSite = t.GET_FIREBASE_currentUser.lastSiteId
        t.lastLocation = t.GET_FIREBASE_currentUser.lastLocationId
        t.schema = schema.task;
        t.selectedTask = {...t.schema};
        this.getItems();
    }
};
</script>